import { motion } from 'framer-motion';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';

import { LoginSignUpAndReleaseNotes } from './login_sign_up_release_notes';
import SsoLoginCheckForm from './sso_login_check_form';

import './index.scss';

export const SsoLoginCheckPage = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 14 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: 'spring', delay: 0.3 }}
        className="login"
      >
        <LoginSignUpAndReleaseNotes />

        <div className="login__form">
          <h3 className="login__title">Sign in with Single Sign-On</h3>

          <SsoLoginCheckForm />

          <LpLink className="login__form-link-lp-login" to={frontend.login.url({})}>
            Go back to LiquidPlanner Login Page
          </LpLink>
        </div>
      </motion.div>

      <LpLegalTermsFooter />
    </>
  );
};
