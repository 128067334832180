import { ASAPLabel } from 'containers/shared/notice_icons/asap_label';
import { DependencyLabel } from 'containers/shared/notice_icons/dependency_label';
import { IterationNoticeLabelRenderer } from 'containers/shared/notice_icons/iteration_label';
import { RiskLabel } from 'containers/shared/notice_icons/risk_label';
import { SchedulingLimitsLabelRenderer } from 'containers/shared/notice_icons/scheduling_limits_label';
import { ScheduleDirective } from 'daos/enums';
import { Iteration } from 'daos/model_types';

export const SchedulingLabelsRenderer = ({
  showSchedulingLimitsLabel,
  showDependencyLabel,
  showRiskLabel,
  asapStatus,
  activeIteration,
}: {
  showSchedulingLimitsLabel: boolean;
  showDependencyLabel: boolean;
  showRiskLabel: boolean;
  asapStatus: ScheduleDirective;
  activeIteration?: Iteration;
}) => (
  <>
    {showSchedulingLimitsLabel && <SchedulingLimitsLabelRenderer />}
    {showDependencyLabel && <DependencyLabel />}
    {showRiskLabel && <RiskLabel />}
    {asapStatus !== ScheduleDirective.NORMAL && <ASAPLabel scheduleDirective={asapStatus} />}
    {activeIteration && <IterationNoticeLabelRenderer iteration={activeIteration} />}
  </>
);
