import { ReadonlyRecord } from 'lib/readonly_record';
import { EntityState } from 'redux/entities/types';
import { deleteBulkEntityFinished, deleteEntityFinished } from 'redux/sagas/api_saga/delete_actions';

export const API_REQUEST = 'DO_API_REQUEST';
export const AWAIT_REQUEST_FINISH = 'AWAIT_REQUEST_FINISH';

interface MetaOptions {
  normalizeIncludedEntitiesOnly?: boolean;
  skipReduce?: boolean;
}

interface ApiOptions {
  body?: ApiBody;
  headers?: {
    'Content-Type'?: string;
    Accept?: string;
  };
  meta?: MetaOptions;
  method: HttpMethod;
}
export interface AwaitFinishAction {
  payload: { requestUuid: string; methods: { onSuccess: Function; onError?: Function; onFinish: Function } };
  type: 'AWAIT_REQUEST_FINISH';
}

export interface ApiRequest {
  type: string;
  payload: {
    body: BodyInit | undefined;
    endpoint: string;
    finishAction?: FinishAction;
    headers: ApiOptions['headers'];
    method: HttpMethod;
    payloadActions: PayloadActions;
    uuid: string;
  };
  uuid: string;
  meta?: MetaOptions;
}

export interface ApiError {
  readonly title: string;
  readonly detail?: string;
  readonly meta?: ReadonlyRecord<string, string>;
  readonly status: number;
  readonly code: ErrorCodes;
}

interface PayloadPageData {
  readonly continuationToken?: string;
  readonly recordCount?: number;
  readonly recordLimit?: number;
}

export interface SuccessPayload<T> {
  readonly data: T;
  readonly entities: Partial<EntityState>;
  readonly result: ReadonlyArray<number>;
  readonly pageData?: PayloadPageData;
}

export interface ErrorPayload {
  readonly errors: ReadonlyArray<ApiError>;
}

export enum HttpMethod {
  GET = 'GET',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export enum ErrorCodes {
  ServerError = 'server_error',
  RecordNotFound = 'record_not_found',
  LimitExceeded = 'limitExceeded',
  AccountLocked = 'account_locked',
  InvalidInvitationExistingLpAuth = 'invalid_invitation_existing_lp_auth',
  InvalidInvitationExistingSso = 'invalid_invitation_existing_sso',
  InvalidInvitationDisconnectedUser = 'invalid_invitation_disconnected_user',
  InvalidInvitationDisconnectedGuestUser = 'invite_invalid_guest_disconnected',
  InvalidInvitationActiveGuestUser = 'invalid_invitation_active_guest_user',
  InvalidInvitationGuestHasPendingDashboardInvitation = 'invalid_invitation_guest_has_pending_dashboard_invitation',
  InvalidInvitationGuestHasPendingWorkspaceMemberInvitation = 'invalid_invitation_guest_has_pending_workspace_member_invitation',
  GuestAlreadyWorkspaceMember = 'guest_user_already_a_workspace_member',
  GuestAlreadyDisconnectedWorkspaceMember = 'guest_user_already_a_disconnected_workspace_member',
  WorkspaceMemberAlreadyGuestUser = 'workspace_member_already_a_guest_user',
  JiraProjectNotFound = 'jira_project_not_found',
  JiraInvalidResource = 'invalid_resource',
}

export interface BodyObject {
  attributes?: object;
  id?: number;
  relationships?: object;
  type: string;
}

export type ApiBody = string | FormData | BodyObject | Array<BodyObject>;

interface BasePayloadAction<P = any> {
  payload?: (res: Response) => Promise<P> | undefined;
  type: string;
}

interface SuccessActionHandler<P = any> extends BasePayloadAction<P> {
  payload: (res: Response) => Promise<P> | undefined;
}
export type PayloadActions = [BasePayloadAction, SuccessActionHandler, BasePayloadAction];
export type FinishAction =
  | ReturnType<typeof deleteEntityFinished>
  | ReturnType<typeof deleteBulkEntityFinished>
  | undefined;
