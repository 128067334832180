import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Widget } from 'daos/model_types';
import { WidgetDao, WidgetIncludes } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { getDashboardIdForItemId } from 'features/dashboards/selectors';
import { awaitRequestFinish } from 'lib/api';
import { mergeEntities } from 'redux/entities/slice';

import { IntakeWidgetData } from './types';

export function useFetchIntakeWidget(includes?: WidgetIncludes) {
  const dispatch = useDispatch();

  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const widgetParams = useWidgetParams();
  const { widgetId, itemId } = widgetParams;
  const dashboardId = useSelector((state) => {
    return widgetParams.dashboardId ?? getDashboardIdForItemId(state, String(itemId));
  });

  const [currentWidget, setCurrentWidget] = useState<Widget<IntakeWidgetData> | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const fetchWidget = useCallback(() => {
    if (dashboardId) {
      const { uuid } = dispatch(
        WidgetDao.fetch(
          {
            organizationId,
            workspaceId,
            dashboardId,
            widgetId,
          },
          { include: includes }
        )
      );

      dispatch(
        awaitRequestFinish<Widget<IntakeWidgetData>>(uuid, {
          onSuccess: ({ data, entities }) => {
            dispatch(mergeEntities(entities));
            setCurrentWidget(data);
          },
          onFinish: () => setIsLoading(false),
        })
      );
    }
  }, [dashboardId, dispatch, includes, organizationId, widgetId, workspaceId]);

  useEffect(() => {
    fetchWidget();
  }, [fetchWidget]);

  return { currentWidget, isLoading };
}
