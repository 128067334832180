import { ReactElement } from 'react';
import { GridChildComponentProps } from 'react-window';

const MIN_NAME_COLUMN_WIDTH = 500;
const MAX_NAME_COLUMN_WIDTH = 1000;
export const REMAINING_COLUMN_WIDTH = 80;
export const AVAILABILITY_USED_COLUMN_WIDTH = 100;
export const ROW_HEIGHT_PIXELS = 38;
export const BASE_NUBS_COLUMN_WIDTH = 12;
export const ADDITIONAL_COLUMN_COUNT = 3;

export function getColumnWidthAndSetNameColumnWidth(
  columnIndex: number,
  dayRange: number,
  columnCount: number,
  width: number
) {
  // calculate how much width in pixels the nubs column should take up
  const nubsColumnWidth = BASE_NUBS_COLUMN_WIDTH * dayRange;
  // sum the combined widths so that the name column will take up exactly how much is left
  const totalOtherWidths = nubsColumnWidth + REMAINING_COLUMN_WIDTH + AVAILABILITY_USED_COLUMN_WIDTH;
  const nameColumnWidth = Math.min(Math.max(width - totalOtherWidths, MIN_NAME_COLUMN_WIDTH), MAX_NAME_COLUMN_WIDTH);

  document.documentElement.style.setProperty('--lp-workload-name-column-width', `${nameColumnWidth}px`);

  const { renderNameColumn, renderNubColumn, renderRemainingColumn, renderAvailabilityUsedColumn } =
    getColumnRenderBooleans(columnIndex, columnCount);

  if (renderNameColumn) {
    return nameColumnWidth;
  } else if (renderNubColumn) {
    return BASE_NUBS_COLUMN_WIDTH;
  } else if (renderRemainingColumn) {
    return REMAINING_COLUMN_WIDTH;
  } else if (renderAvailabilityUsedColumn) {
    return AVAILABILITY_USED_COLUMN_WIDTH;
  }

  return 0;
}

export function getColumnRenderBooleans(columnIndex: number, columnCount: number) {
  return {
    renderNameColumn: columnIndex === 0,
    renderNubColumn: columnIndex > 0 && columnIndex <= columnCount - ADDITIONAL_COLUMN_COUNT,
    renderRemainingColumn: columnIndex === columnCount - 2,
    renderAvailabilityUsedColumn: columnIndex === columnCount - 1,
  };
}

export const getRenderedRowRange = (children: Array<ReactElement<GridChildComponentProps>>) =>
  children.reduce(
    ({ minRow, maxRow }, { props: { rowIndex } }) => {
      if (rowIndex < minRow) {
        minRow = rowIndex;
      }
      if (rowIndex > maxRow) {
        maxRow = rowIndex;
      }

      return { minRow, maxRow };
    },
    {
      minRow: Number.POSITIVE_INFINITY,
      maxRow: Number.NEGATIVE_INFINITY,
    }
  );
