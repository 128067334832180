import { LpSvg, LpSvgs } from 'features/common/lp_svg';

import './index.scss';

export const JiraFieldsDisplayText = ({ name }: { name: string }) => {
  return (
    <span className="jira-fields-display-text">
      <LpSvg icon={LpSvgs.JIRA} size={12} />
      {name.replace('(Jira)', '')}
    </span>
  );
};
