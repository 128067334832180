import { PRIVACY_POLICY_URL, TOS_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';

export const TermsOfServiceLabel = () => {
  return (
    <label htmlFor="tosCheckbox">
      I agree to the <NewWindowLink to={TOS_URL}>Terms of Service</NewWindowLink> and{' '}
      <NewWindowLink to={PRIVACY_POLICY_URL}>Privacy Policy</NewWindowLink>
    </label>
  );
};
