import classNames from 'classnames';
import { AnimatePresence, motion, Transition } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import LpLink from 'containers/shared/lp_link';
import { trackViewUrl } from 'features/academy/helpers';
import { AnimationDirection } from 'features/academy/outpost/types';
import { getLessonForId, getTrackForId, getTrackLessonForId } from 'features/academy/selectors';
import { AcademyImageCard } from 'features/academy/track/track_card';
import { graduationCapSolid, angleLeftSolid, angleRightSolid, LpIcon } from 'features/common/lp_icon';

const OUTPOST_IMAGE_ANIMATION_OFFSET = 200;

const DefaultOutpostSliderImage = () => {
  return (
    <div className="default-outpost-slider-image">
      <LpIcon icon={graduationCapSolid} size="3x" />
    </div>
  );
};

const outpostSliderAnimationVariants = {
  initial: (direction: AnimationDirection) => {
    return {
      x: direction === AnimationDirection.Right ? OUTPOST_IMAGE_ANIMATION_OFFSET : -OUTPOST_IMAGE_ANIMATION_OFFSET,
      opacity: 0,
    };
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: AnimationDirection) => {
    return {
      x: direction === AnimationDirection.Left ? OUTPOST_IMAGE_ANIMATION_OFFSET : -OUTPOST_IMAGE_ANIMATION_OFFSET,
      opacity: 0.5,
    };
  },
};

const animationTransition: Transition = {
  type: 'tween',
  duration: 0.25,
  ease: [0, 0, 0.2, 1],
};

interface OutpostSliderProps {
  currentLessonId: string;
  enableControls: boolean;
  getNextLesson: () => void;
  getPreviousLesson: () => void;
  animationDirection: AnimationDirection;
}

const Controls = ({
  enableControls,
  getNextLesson,
  getPreviousLesson,
}: {
  enableControls: boolean;
  getNextLesson: () => void;
  getPreviousLesson: () => void;
}) => {
  const getLessonIfEnabledControls = (getLesson: () => void) => () => enableControls && getLesson();
  const handleGetNextLesson = getLessonIfEnabledControls(getNextLesson);
  const handleGetPreviousLesson = getLessonIfEnabledControls(getPreviousLesson);

  return (
    <div className="outpost-slider__controls">
      <LpIcon
        className={classNames(
          'outpost-slider__controls-icon',
          !enableControls && 'outpost-slider__controls-icon--disabled'
        )}
        icon={angleRightSolid}
        size="lg"
        onClick={handleGetNextLesson}
      />
      <LpIcon
        className={classNames(
          'outpost-slider__controls-icon',
          !enableControls && 'outpost-slider__controls-icon--disabled'
        )}
        icon={angleLeftSolid}
        size="lg"
        onClick={handleGetPreviousLesson}
      />
    </div>
  );
};

export const OutpostSlider = ({
  currentLessonId,
  enableControls,
  getNextLesson,
  getPreviousLesson,
  animationDirection,
}: OutpostSliderProps) => {
  const trackLesson = useSelector((state) => getTrackLessonForId(state, currentLessonId));
  const lesson = useSelector((state) => (trackLesson ? getLessonForId(state, trackLesson.academyLessonId) : undefined));
  const location = useLocation();
  const track = useSelector((state) => (trackLesson ? getTrackForId(state, trackLesson.academyTrackId) : undefined));

  if (!trackLesson || !lesson || !track) {
    return null;
  }

  const courseId = track.academyCourseId;
  const trackId = track.id;
  const lessonId = lesson.id;

  return (
    <>
      <div className="outpost-header">
        <div className="outpost-header__name">
          <LpIcon className="outpost-header__name-icon" icon={graduationCapSolid} />
          <LpLink
            className="outpost-header__name-link"
            to={trackViewUrl({ courseId, trackId, lessonId, path: location.pathname })}
          >
            {lesson.title}
          </LpLink>
        </div>
      </div>
      <motion.div layout className="outpost-slider">
        <div className="outpost-slider__animation-container">
          <AnimatePresence initial={false} mode="wait" custom={animationDirection}>
            <motion.div
              key={currentLessonId}
              custom={animationDirection}
              variants={outpostSliderAnimationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={animationTransition}
            >
              <LpLink to={trackViewUrl({ courseId, trackId, lessonId, path: location.pathname })}>
                {lesson.lessonImageId ? (
                  <AcademyImageCard imageId={lesson.lessonImageId} />
                ) : (
                  <DefaultOutpostSliderImage />
                )}
              </LpLink>
            </motion.div>
          </AnimatePresence>
        </div>
        <Controls enableControls={enableControls} getNextLesson={getNextLesson} getPreviousLesson={getPreviousLesson} />
      </motion.div>
    </>
  );
};
