import { useSelector } from 'react-redux';

import { StoryPointDropdown } from 'features/common/inputs/dropdowns/story_point_dropdown';
import { buildStoryPointOptions } from 'features/common/inputs/dropdowns/story_point_dropdown/helpers';
import {
  getActiveAndInUseStoryPointsForTaskId,
  getStoryPointSchemeIdForTaskId,
} from 'redux/entities/selectors/story_points';

interface TaskStoryPointDropdownProps {
  onChange: (storyPointId: number | null) => void;
  open?: boolean;
  selectedStoryPointId: number;
  taskId: number;
  usePortal?: boolean;
}

export const TaskStoryPointDropdown = ({
  onChange,
  open,
  selectedStoryPointId,
  taskId,
  usePortal = false,
}: TaskStoryPointDropdownProps) => {
  const activeAndInUseStoryPoints = useSelector((state) => getActiveAndInUseStoryPointsForTaskId(state, taskId));
  const supportedSchemeId = useSelector((state) => getStoryPointSchemeIdForTaskId(state, taskId));

  const options = buildStoryPointOptions(activeAndInUseStoryPoints, supportedSchemeId);

  return (
    <StoryPointDropdown
      onChange={onChange}
      open={open}
      options={options}
      selectedStoryPointId={selectedStoryPointId}
      usePortal={usePortal}
    />
  );
};
