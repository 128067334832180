import { useState, useEffect, SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { OrganizationDao } from 'daos/organization';
import AddOrganizationFormModal from 'features/administration/organization_switch/add_organization_form_modal';
import OrganizationContextButton from 'features/administration/organization_switch/organization_context_button';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { useFrontloadData } from 'features/authentication/hooks/use_frontload_data';
import { getCurrentOrganizationId, getCurrentUser } from 'features/common/current/selectors';
import { clearGridColumns } from 'features/common/data_grid/slice';
import { plusSolid, LpIcon, cloudCheckSolid } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { clearPortfolioData } from 'features/ppp/slice';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getOrganizationsById } from 'redux/entities/selectors/organization';
import { purgeEntities } from 'redux/entities/slice';
import 'features/administration/organization_switch/organization_picker_modal.scss';

interface OrganizationPickerModalProps {
  handleCloseModal: () => void;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}

export const OrganizationPickerModal = ({ handleCloseModal, setEntityNotFound }: OrganizationPickerModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const frontloadData = useFrontloadData({ setEntityNotFound });

  const currentOrgId = useSelector(getCurrentOrganizationId);
  const orgsById = useSelector(getOrganizationsById);
  const currentUser = useSelector(getCurrentUser);

  const [openAddOrg, setOpenAddOrg] = useState(false);

  const handleAddOrgForm = () => setOpenAddOrg(true);
  const handleCloseAddOrgForm = () => setOpenAddOrg(false);

  useEffect(() => {
    const { uuid } = dispatch(OrganizationDao.fetchAll());
    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch]);

  const handleOrgSwitch = (e: SyntheticEvent | null, organizationId: number) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    dispatch(purgeEntities());
    dispatch(clearGridColumns());
    dispatch(clearPortfolioData());
    handleCloseModal();
    frontloadData({
      providedOrgId: organizationId,
      providedWsId: undefined,
      callbackOnFrontloadDataComplete: (workspaceId) => {
        history.push(frontend.workspace.url({ organizationId, workspaceId }));
      },
    });
  };

  const organizationRows = () => {
    const alphaSortedOrgs = Object.values(orgsById).sort((orgA, orgB) => orgA.name.localeCompare(orgB.name));

    return alphaSortedOrgs.map((org) => {
      return (
        <Table.Row key={org.id}>
          <Table.Cell
            content={
              <span>
                <LpIcon className="icon" icon={cloudCheckSolid} size="sm" />

                <LpLink
                  className="organization-picker__links"
                  key={org.id}
                  to={frontend.organization.url({ organizationId: org.id })}
                  onClick={(e: SyntheticEvent) => handleOrgSwitch(e, org.id)}
                >
                  {org.name}
                </LpLink>

                {currentOrgId === org.id && ' (Logged In)'}

                <OrganizationContextButton
                  onClose={handleCloseModal}
                  organizationId={org.id}
                  handleOrgSwitch={handleOrgSwitch}
                />
              </span>
            }
          />
        </Table.Row>
      );
    });
  };

  return (
    <LpModal
      className="organization-picker"
      onClose={handleCloseModal}
      header={'Switch Organizations'}
      content={
        <div className="organization-picker__table-scroll">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Organization</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{organizationRows()}</Table.Body>
            {openAddOrg && (
              <AddOrganizationFormModal handleOrgSwitch={handleOrgSwitch} onClose={handleCloseAddOrgForm} />
            )}
          </Table>
        </div>
      }
      actions={
        <>
          {currentUser?.lpSupportStaff && (
            <Button
              floated="left"
              icon={<LpIcon icon={plusSolid} size="sm" />}
              content={'New Organization'}
              onClick={handleAddOrgForm}
            />
          )}
          <Button content="Close" onClick={handleCloseModal} primary />
        </>
      }
    />
  );
};
