import { Dispatch, RefObject, SetStateAction } from 'react';

import { ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { ClickLocation } from 'hooks/use_click_location_for_context_menu';

export interface SetItemDeletionParams {
  id: number;
  name: string;
  child?: ReadonlyArray<number>;
  deleteItemClick: () => void;
}

export enum EllipsisActionViewLocation {
  ArchivedPackageCollectionView = 'archived-package-collection-view',
  GridView = 'grid-view',
  ItemListRow = 'item-list-row',
  PackageView = 'package-view',
  PendingPackageCollectionView = 'pending-package-collection-view',
  ProjectAncestorRow = 'project-ancestor-row',
  ScheduledPackageCollectionView = 'scheduled-package-collection-view',
  PlanningPackageCollectionView = 'planning-package-collection-view',
  TemplatePackageView = 'template-package-view',
  WorkloadTaskRow = 'workload-task-row',
}

export interface EllipsisActionDropdownProps {
  afterDelete?: ({ item }: { item: Item }) => void;
  clickLocation?: ClickLocation;
  expandSubFolders?: boolean;
  fetchItems?: () => void;
  handleHideContextMenu?: () => void;
  isTemplateGrid: boolean;
  item: Item;
  setDeletionData: Dispatch<SetStateAction<SetItemDeletionParams | undefined>>;
  setShowProjectPowerFeatureModal?: Dispatch<SetStateAction<boolean>>;
  setShowJiraProjectModal?: Dispatch<SetStateAction<boolean>>;
  setPackageId?: Dispatch<SetStateAction<number>>;
  setEstimateModalAssignmentId?: Dispatch<SetStateAction<number | undefined>>;
  setMoveItemId?: Dispatch<SetStateAction<number | undefined>>;
  showActionTriggerIcon?: boolean;
  triggerRef?: RefObject<HTMLSpanElement>;
  viewLocation?: EllipsisActionViewLocation;
}

export interface EllipsisActionHandlers {
  handleAddItem: (addItemType: ItemType, expandSubFolders: boolean) => () => void | undefined;
  handleAddJiraProject: (hasManageAccess: boolean | undefined) => () => void;
  handleCopyLink: () => void;
  handleEditItem: () => void;
  handleItemDelete: () => void;
  handleMemberAccess: () => void;
  handleMoveItem: () => void;
  handleOpenItem: () => void;
  handleOpenTemplatePicker: () => void;
  handleOpenPackageToTemplateModal: () => void;
  handleOpenProjectToTemplateModal: () => void;
  handleOpenTemplateToPackageModal: () => void;
  handleOpenTemplateToProjectModal: () => void;
}
