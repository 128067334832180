import classNames from 'classnames';
import { SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Label } from 'semantic-ui-react';

import { ItemType } from 'daos/enums';
import { splitLight, LpIcon } from 'features/common/lp_icon';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { getItemForId } from 'redux/entities/selectors/item';
import { getSchedulingLimitsForItemId } from 'redux/entities/selectors/scheduling_limit';
import { slate900 } from 'style/variables';

interface WorkLimitLabelProps {
  className?: string;
  itemId: number;
}

export const SchedulingLimitsLabelRenderer = ({
  className = '',
  onClick,
}: {
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
}) => {
  return (
    <Label
      className={classNames(className, 'icon notice scheduling-limits', onClick && 'clickable')}
      content={<LpIcon hoverText={'Schedule Limit'} size="sm" icon={splitLight} color={slate900} />}
      onClick={onClick}
    />
  );
};

export const SchedulingLimitsLabel = ({ className, itemId }: WorkLimitLabelProps) => {
  const history = useHistory();
  const item = useSelector((state) => getItemForId(state, itemId));
  const schedulingLimits = useSelector((state) => getSchedulingLimitsForItemId(state, itemId));
  const panelTab = item?.itemType === ItemType.TASKS ? TabNames.Assignments : TabNames.Planning;

  const openSchedulingLimitsTab = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      history.push(`#panelId=${itemId}&panelSection=${panelTab}Tab`);
    },
    [history, itemId, panelTab]
  );

  if (!item || schedulingLimits.length === 0) {
    return null;
  }

  return <SchedulingLimitsLabelRenderer className={className} onClick={openSchedulingLimitsTab} />;
};
