import { all, select, takeEvery } from 'typed-redux-saga';

import { PlanFamily, UserType } from 'daos/enums';
import { User } from 'daos/model_types';
import { getCurrentUser } from 'features/common/current/selectors';
import { setCurrentOrganizationId } from 'features/common/current/slice';
import { FeatureFlag } from 'lib/feature_flags';
import { currentBrowserDateTime } from 'lib/localization';
import { getCurrentOrganization, getCurrentOrganizationProjectTypes } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getSystemFeatureFlagDefaults } from 'redux/entities/selectors/system_settings';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

const { pendo, pendoKey } = window as any;
let pendoUser: User | undefined = undefined;

function* handleSetOrganizationId() {
  const currentUser = yield* select(getCurrentUser);
  const currentOrgUser = yield* select(getCurrentOrganizationUser);
  const currentOrganization = yield* select(getCurrentOrganization);
  const currentOrganizationPlan = yield* select(getCurrentOrganizationPlan);
  const currentOrganizationProjectTypes = yield* select(getCurrentOrganizationProjectTypes);

  const isOrgUserCreatedAtWithinLast30Days =
    !!currentOrgUser &&
    currentBrowserDateTime(currentOrgUser.timezone).subtract(30, 'days').isSameOrBefore(currentOrgUser.createdAt);
  const featureFlagDefaults = yield* select(getSystemFeatureFlagDefaults);
  const featureFlags = currentOrganization?.featureFlags ?? featureFlagDefaults;
  const disablePendoFeatureFlag = featureFlags[FeatureFlag.disablePendo];

  if (disablePendoFeatureFlag) {
    return;
  }

  if (
    pendo &&
    pendoKey &&
    currentUser &&
    currentOrganization &&
    currentOrgUser &&
    currentOrganizationPlan?.family === PlanFamily.UltimateTrial &&
    isOrgUserCreatedAtWithinLast30Days
  ) {
    let userAccess = 'Member';

    if (currentOrgUser.disconnectedAt !== null) {
      userAccess = 'Disconnected';
    } else if (currentOrgUser.admin) {
      userAccess = 'Org Admin';
    } else if (currentUser.userType === UserType.Resource) {
      userAccess = 'Resource';
    } else if (currentUser.userType === UserType.Placeholder) {
      userAccess = 'Placeholder';
    }

    const pendoVisitorIdPayloadPortion = {
      id: currentUser.id,
    };

    const pendoAccountPayload = {
      id: currentOrganization.id,
      orgId: currentOrganization.id,
      planFamily: currentOrganizationPlan.family,
      lpxOrganization: !!currentOrganization.lpxWorkspaceId,
      projectTypes: currentOrganizationProjectTypes.join(', '),
    };

    if (!pendoUser) {
      pendoUser = currentUser;
      pendo.initialize({
        visitor: {
          ...pendoVisitorIdPayloadPortion,
          email: currentUser.email,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          userAccess,
        },
        account: pendoAccountPayload,
      });
    } else {
      pendo.identify({
        visitor: pendoVisitorIdPayloadPortion,
        account: pendoAccountPayload,
      });
    }
  }
}

export default function* rootSaga() {
  yield* all([takeEvery(setCurrentOrganizationId, handleSetOrganizationId)]);
}
