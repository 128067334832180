import { capitalize } from 'lodash/fp';

import { UserNameData } from 'redux/entities/selectors/user';

type DisplayNameParts = Pick<UserNameData, 'firstName' | 'lastName' | 'userType' | 'username'>;

export const displayName = ({ firstName, lastName, userType, username }: DisplayNameParts) => {
  const fullName = [firstName.trim(), lastName.trim()].join(' ').trim();
  const fallbackName = `${capitalize(userType)} - ${username}`;

  return fullName || fallbackName;
};
