import { KeyboardEvent, SyntheticEvent, useState, useEffect } from 'react';
import { Input, InputProps } from 'semantic-ui-react';

const KEY_ENTER = 13;
const KEY_ESCAPE = 27;

interface LpInputProps {
  autoFocus?: boolean;
  className?: string;
  data?: object;
  disabled?: InputProps['disabled'];
  fluid?: InputProps['fluid'];
  loading?: InputProps['loading'];
  onChange?: (value: string) => any;
  onFocus?: (event: Event) => void;
  placeholder?: string;
  transparent?: InputProps['transparent'];
  value?: string;
  hasError?: boolean;
}

const LpInput = ({
  autoFocus,
  className,
  disabled,
  loading,
  fluid,
  placeholder,
  transparent,
  value,
  onFocus,
  onChange,
  hasError,
}: LpInputProps) => {
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [inputInitialValue, setInputInitialValue] = useState<string>(value || '');

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  const handleInputBlur = () => {
    if (inputInitialValue !== inputValue) {
      setInputInitialValue(inputValue);
      onChange && onChange(inputValue);
    }
  };

  const handleInputFocus = (event: FocusEvent) => {
    onFocus && onFocus(event);
  };

  const handleInputChange = ({ currentTarget: { value: currentValue } }: SyntheticEvent<HTMLInputElement>) =>
    setInputValue(currentValue);

  const handleInputKeyDown = (evt: KeyboardEvent) => {
    const { keyCode } = evt;

    if (keyCode === KEY_ESCAPE) {
      evt.preventDefault();
      evt.nativeEvent && evt.nativeEvent.stopImmediatePropagation();
      evt.stopPropagation();

      setInputValue(inputInitialValue);
    }
  };

  const handleInputKeyUp = ({ keyCode }: KeyboardEvent) => {
    if (keyCode === KEY_ENTER) {
      handleInputBlur();
    }
  };

  return (
    <Input
      error={hasError}
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      loading={loading}
      fluid={fluid}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      onKeyUp={handleInputKeyUp}
      placeholder={placeholder}
      transparent={transparent}
      value={inputValue}
      data-testid="lp-input"
    />
  );
};

export default LpInput;
