import { SyntheticEvent } from 'react';
import { Checkbox, CheckboxProps, List } from 'semantic-ui-react';

import { FolderStatusFilter } from 'daos/enums';
import { FolderFilterStatusDisplay } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import { LpIcon, folderClosedSolid } from 'features/common/lp_icon';
import { slate600 } from 'style/variables';

import './item_status_list.scss';

interface FolderStatusOptionsTableProps {
  onChange: (value: FolderStatusFilter) => void;
  value: FolderStatusFilter;
}

const options = [
  { text: FolderFilterStatusDisplay.All, value: FolderStatusFilter.All },
  { text: FolderFilterStatusDisplay.ActiveOnHold, value: FolderStatusFilter.ActiveOnHold },
  { text: FolderFilterStatusDisplay.Scheduled, value: FolderStatusFilter.Scheduled },
  { text: FolderFilterStatusDisplay.OnHold, value: FolderStatusFilter.OnHold },
  { text: FolderFilterStatusDisplay.Done, value: FolderStatusFilter.Done },
];

const FolderStatusOptionsTable = ({ onChange, value }: FolderStatusOptionsTableProps) => {
  const handleProjectStatusCheckboxChange = (_: SyntheticEvent, { value }: CheckboxProps) => {
    onChange(value as FolderStatusFilter);
  };

  const folderStatusFilterOptions = options.map((option) => (
    <List.Item key={option.text} className="status-options-list__row">
      <List.Content className="status-options-list__row-cell">
        <Checkbox
          className="status-options-list__row-cell-checkbox"
          checked={value === option.value}
          onChange={handleProjectStatusCheckboxChange}
          value={option.value}
        />
        <label className="task-status-options-list__row-cell-name">
          <LpIcon className="icon" color={slate600} icon={folderClosedSolid} /> {option.text}
        </label>
      </List.Content>
    </List.Item>
  ));

  return (
    <div className="status-options-list">
      <span className="status-options-list__header">
        <div className="status-options-list__header-name">Project Status</div>
      </span>
      <div className="status-options-list__scroll-container">
        <List divided relaxed>
          <List.Item className="status-options-list__row-cell-info">Status Groups</List.Item>
          {folderStatusFilterOptions}
        </List>
      </div>
    </div>
  );
};

export default FolderStatusOptionsTable;
