import classnames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

import { timesRegular, LpIcon } from 'features/common/lp_icon';
import './banners.scss';

interface BannerProps {
  children?: ReactNode;
  className?: string;
  dismissable?: boolean;
  onDismiss?: () => void;
  style?: CSSProperties;
}

const Banner = ({ children, className, dismissable, onDismiss, style }: BannerProps) => (
  <div style={style} className={classnames(className, 'lp-banner')}>
    <span className="lp-banner__content">{children}</span>
    {dismissable && <LpIcon className="lp-banner__close-icon" icon={timesRegular} onClick={onDismiss} />}
  </div>
);

export default Banner;
