import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useRef } from 'react';
import { Table } from 'semantic-ui-react';

import { CustomFieldType } from 'daos/enums';
import RichTextEditor from 'features/common/inputs/rich_text_editor';
import {
  ItemIntakeRowSetting,
  ItemIntakeSettableField,
} from 'features/dashboards_v2/widget_click_through/intake/enums';
import {
  emptyCustomFieldValues,
  isCustomFieldEmpty,
  isDisplayOnlyField,
  isRequiredField,
} from 'features/dashboards_v2/widget_click_through/intake/form/helpers';
import { rowHeaderTitle } from 'features/dashboards_v2/widget_click_through/intake/form/section/table/display_helpers';
import { FieldClearButton } from 'features/dashboards_v2/widget_click_through/intake/form/section/table/field_clear_button';
import {
  IntakeCustomFieldUserWidgetData,
  IntakeCustomFieldWidgetData,
  WidgetIntakeFormUserInput,
} from 'features/dashboards_v2/widget_click_through/intake/types';

import { CustomFieldValueInputCell } from './custom_field_value_cell';

const NoteFieldRow = ({
  fieldId,
  headerTitle,
  disabled,
}: {
  fieldId: number;
  headerTitle: string;
  disabled: boolean;
}) => {
  const formikKey = fieldId.toString();
  const { setFieldValue, getFieldMeta, setFieldError, getFieldProps } = useFormikContext();
  const { value } = getFieldProps<string>(formikKey);
  const initialValue = useRef(value);
  const { error, touched } = getFieldMeta(formikKey);

  const onDashboardNoteChange = (newValue: string) => {
    setFieldError(formikKey, undefined);
    setFieldValue(formikKey, newValue);
  };

  return (
    <>
      <Table.Row>
        <Table.Cell colSpan="4" content={headerTitle} />
      </Table.Row>

      <Table.Row>
        <Table.Cell
          disabled={disabled}
          collapsing
          className={classNames('intake-widget-user-form-section__sub-section-table-tinymce', {
            'intake-widget-user-form-section__sub-section-table-tinymce--error': touched && error,
            'intake-widget-user-form-section__sub-section-table-tinymce--disabled': disabled,
          })}
          colSpan="4"
          content={
            <RichTextEditor
              uniqueTinymceId={formikKey}
              content={initialValue.current}
              handleEditorChange={onDashboardNoteChange}
              height={'350px'}
            />
          }
        />
      </Table.Row>
    </>
  );
};

export const CustomFieldRow = ({
  intakeCustomField,
  intakeUserChoices,
  fieldId,
  rowSetting,
  hasAutoFocus,
}: {
  intakeCustomField: IntakeCustomFieldWidgetData | undefined;
  intakeUserChoices: Record<string, IntakeCustomFieldUserWidgetData>;
  fieldId: number;
  rowSetting: ItemIntakeRowSetting;
  hasAutoFocus: boolean;
}) => {
  const formikKey = fieldId.toString();
  const { getFieldProps, setFieldValue } = useFormikContext<WidgetIntakeFormUserInput>();
  const { value } = getFieldProps(formikKey);

  const headerTitle = rowHeaderTitle({
    itemField: ItemIntakeSettableField.CustomField,
    customFieldName: intakeCustomField?.name,
    isRequired: isRequiredField(rowSetting),
  });

  if (!intakeCustomField) {
    return null;
  }

  const isDisabled = isCustomFieldEmpty(value, intakeCustomField.fieldType);
  const isDisplayOnly = isDisplayOnlyField(rowSetting);

  const handleClear = () => {
    const emptyFieldValue = emptyCustomFieldValues(intakeCustomField.fieldType);
    setFieldValue(formikKey, emptyFieldValue);
  };

  if (intakeCustomField.fieldType === CustomFieldType.NOTE) {
    return <NoteFieldRow fieldId={fieldId} headerTitle={headerTitle} disabled={isDisplayOnly} />;
  }

  return (
    <Table.Row>
      <Table.Cell collapsing content={headerTitle} />
      <Table.Cell
        collapsing
        colSpan="2"
        disabled={isDisplayOnly}
        content={
          <CustomFieldValueInputCell
            intakeCustomField={intakeCustomField}
            intakeUserChoices={intakeUserChoices}
            customFieldType={intakeCustomField.fieldType}
            fieldId={fieldId}
            isDisplayOnly={isDisplayOnly}
            hasAutoFocus={hasAutoFocus}
          />
        }
      />
      <Table.Cell
        className="intake-widget-user-form-section__sub-section-table-clear"
        collapsing
        content={<FieldClearButton disabled={isDisabled || isDisplayOnly} handleClear={handleClear} />}
      />
    </Table.Row>
  );
};
