import { FieldInputProps, FieldMetaProps } from 'formik';
import { Button, Form } from 'semantic-ui-react';

import { PasswordError } from 'features/authentication/unauthenticated/token/password_form/errors';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormInput from 'features/common/forms/lp_form_input';
import { ApiError } from 'lib/api/types';

import 'features/authentication/unauthenticated/token/password_form/index.scss';

export const PasswordForm = ({
  expired,
  isSubmitting,
  handleSubmit,
  handleDismissApiError,
  status,
  getFieldMeta,
  getFieldProps,
  claimedAt,
}: {
  expired: boolean;
  isSubmitting: boolean;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleDismissApiError: () => void;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  status: ApiError;
  claimedAt: string | null;
}) => {
  return (
    <Form className="password-form__form" loading={isSubmitting} onSubmit={handleSubmit}>
      <PasswordError claimedAt={claimedAt} expired={expired} />

      {status && (
        <LpErrorMessage className="password-form__api-error" error={status} onDismiss={handleDismissApiError} />
      )}

      <LpFormInput
        label="Password"
        fieldKey="password"
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        type="password"
        icon="lock"
      />

      <LpFormInput
        label="Confirm Password"
        fieldKey="passwordConfirmation"
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        type="password"
        icon="lock"
      />

      <Button
        fluid
        primary
        content={'Save new password and Sign In'}
        disabled={isSubmitting || expired}
        type="submit"
      />
    </Form>
  );
};
