// heavily inspired by https://github.com/erikras/react-redux-universal-hot-example/blob/master/src/redux/create.js
import { configureStore } from '@reduxjs/toolkit';

import { rootReducer, RootState } from 'redux/root_reducer';

import { createMiddleware } from './middleware';
import sagas from './sagas';

export default function createStore(initialState?: RootState) {
  const [middleware, sagaMiddleware] = createMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: location.origin.includes('localhost'),
    middleware,
  });

  sagaMiddleware.run(sagas);

  return store;
}
