import { StoryPointSchemeActions } from 'daos/external_integration_enums';
import { StoryPoints } from 'daos/model_types';
import { StoryPointSchemesSortColumn } from 'features/administration/settings/settings_page/story_point_schemes/enums';
import { StoryPointSchemesWithStoryPoints } from 'features/administration/settings/settings_page/story_point_schemes/type';

export const sortSchemesByDefault = (schemes: ReadonlyArray<StoryPointSchemesWithStoryPoints>) => {
  if (!schemes || schemes.length === 0) {
    return [];
  }

  const defaultScheme = schemes.find((scheme) => scheme.default);
  const otherSchemes = schemes.filter((scheme) => !scheme.default);

  otherSchemes.sort((a, b) => a.schemeName.localeCompare(b.schemeName));

  return defaultScheme ? [defaultScheme, ...otherSchemes] : otherSchemes;
};

export const getSchemeModifiedName = (scheme: StoryPointSchemesWithStoryPoints) => {
  return scheme.default ? `${scheme.schemeName} (default)` : scheme.schemeName;
};

export const getSchemeValue = (scheme: StoryPointSchemesWithStoryPoints) => {
  return scheme.default ? StoryPointSchemeActions.UseDefault : StoryPointSchemeActions.UseExisting;
};

export const getSchemeSelectionDropdownOptions = (schemes: ReadonlyArray<StoryPointSchemesWithStoryPoints>) => {
  return schemes.map((scheme) => ({
    key: scheme.id,
    text: getSchemeModifiedName(scheme),
    value: scheme.id,
  }));
};

export const getCopySchemeName = (selectedOption: StoryPointSchemesWithStoryPoints | undefined) => {
  const schemeName = selectedOption?.[StoryPointSchemesSortColumn.SchemeName];
  return schemeName ? `${schemeName} (copy)` : '';
};

export const getSchemeFromId = (schemes: ReadonlyArray<StoryPointSchemesWithStoryPoints>, id: number) => {
  return schemes.find((scheme) => scheme.id === id);
};

export const isDefaultStoryPointScheme = (
  schemes: ReadonlyArray<StoryPointSchemesWithStoryPoints>,
  schemeId: number | null
): boolean => {
  const scheme = schemes.find((scheme) => scheme.id === schemeId);
  return !!scheme?.default;
};

export const isValidScheme = (scheme: StoryPointSchemesWithStoryPoints) => {
  return schemeHasRowsWithValidEstimates(scheme.storyPoints) && isSchemeInAscendingOrder(scheme.storyPoints);
};

export const isSchemeInAscendingOrder = (storyPoints: ReadonlyArray<StoryPoints>) => {
  const values = storyPoints.map((sp) => sp.value);
  const lowEfforts = storyPoints.map((sp) => sp.lowEffort || 0);
  const highEfforts = storyPoints.map((sp) => sp.highEffort || 0);

  return isAscendingOrder(values) && isAscendingOrder(lowEfforts) && isAscendingOrder(highEfforts);
};

export const isAscendingOrder = (values: ReadonlyArray<number>) => {
  for (let i = 1; i < values.length; i++) {
    const a = values[i];
    const b = values[i - 1];
    if (a && b && a < b) {
      return false;
    }
  }
  return true;
};

export const schemeHasRowsWithValidEstimates = (storyPoints: ReadonlyArray<StoryPoints>) => {
  if (storyPoints.length === 0) {
    return false;
  }

  return storyPoints.every((storyPoint) => {
    return (
      isValidValue(storyPoint.value) && isValidValue(storyPoint.lowEffort) && isValidValue(storyPoint.highEffort) !== 0
    );
  });
};

export const isValidValue = (value: number | null) => {
  return value && value !== null && value !== 0;
};
