import { Button, Segment } from 'semantic-ui-react';

import LpModal from 'features/common/modals/lp_modal';

import 'containers/shared/file_upload_screen.scss';

interface FileUploaderScreenProps {
  hasErrors: boolean;
  onClose: () => void;
}

const FileUploaderScreen = ({ hasErrors, onClose }: FileUploaderScreenProps) => {
  return (
    <LpModal
      className="file-upload-modal"
      onClose={onClose}
      size="tiny"
      header={'File Upload'}
      content={
        <div className="file-upload-content">
          {hasErrors ? <span>Error Uploading File</span> : <Segment basic loading size="massive" />}
        </div>
      }
      actions={hasErrors && <Button primary content="Close" onClick={onClose} />}
    />
  );
};

export default FileUploaderScreen;
