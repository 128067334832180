import ItemLink from 'containers/shared/item_link';
import { ItemType } from 'daos/enums';
import { ItemAncestryBreadcrumb } from 'features/common/item_ancestry_breadcrumb';

export const IntakeWidgetSelectedItem = ({ itemId, disableLinking }: { itemId: number; disableLinking?: boolean }) => {
  return (
    <div className="intake-widget-item-picker__breadcrumb">
      <ItemLink
        className={'intake-widget-item-picker__breadcrumb-item-name'}
        itemId={itemId}
        openScheduleTab={false}
        showIcon={true}
        disabled={disableLinking}
      />

      <ItemAncestryBreadcrumb
        includeCollection={true}
        buildAncestryUpToItemType={ItemType.WORKSPACE_ROOTS}
        itemId={itemId}
        includeLink={!disableLinking}
      />
    </div>
  );
};
