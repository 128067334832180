import { useState, SyntheticEvent, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { DashboardGuestDao } from 'daos/dashboard_guests';
import { Dashboard, DashboardGuest, WorkspaceUser } from 'daos/model_types';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { awaitRequestFinish } from 'lib/api';
import { ApiError, ErrorPayload } from 'lib/api/types';

export const useDisconnectWorkspaceGuestModal = ({
  fetchOrgUser,
  organizationId,
  workspaceUsersForUserId,
}: {
  fetchOrgUser: (afterOnFinish: () => void) => void;
  organizationId: number;
  workspaceUsersForUserId: ReadonlyArray<WorkspaceUser>;
}) => {
  const dispatch = useDispatch();
  const [confirmDisconnectWorkspace, setConfirmDisconnectWorkspace] = useState<
    { workspaceId: number; workspaceName: string } | undefined
  >();
  const closeDisconnectConfirmModal = () => setConfirmDisconnectWorkspace(undefined);
  const openDisconnectConfirmModal = (id: number, name: string) =>
    setConfirmDisconnectWorkspace({ workspaceId: id, workspaceName: name });

  const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
  const [disconnectError, setDisconnectError] = useState<ApiError | undefined>(undefined);
  const [reconnectError, setReconnectError] = useState<ApiError | undefined>(undefined);

  const onSuccess = () => fetchOrgUser(() => setApiRequestInProgress(false));

  const onConfirmDisconnectClick = () => {
    closeDisconnectConfirmModal();

    const workspaceUserIdToDisconnect =
      workspaceUsersForUserId.find(
        (workspaceuser) => workspaceuser.workspace.id === confirmDisconnectWorkspace?.workspaceId
      )?.id ?? 0;

    if (workspaceUserIdToDisconnect && confirmDisconnectWorkspace) {
      setDisconnectError(undefined);
      setApiRequestInProgress(true);

      const { uuid } = dispatch(
        WorkspaceUserDao.update(
          {
            organizationId,
            workspaceId: confirmDisconnectWorkspace.workspaceId,
            workspaceUserId: workspaceUserIdToDisconnect,
          },
          { id: workspaceUserIdToDisconnect, disconnected: true }
        )
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }: ErrorPayload) => {
            setDisconnectError(errors[0]);
            setApiRequestInProgress(false);
          },
          onSuccess,
        })
      );
    }
  };

  const handleReconnectClick = (workspaceId: number, workspaceUserId: number) => (_: SyntheticEvent) => {
    setReconnectError(undefined);
    setApiRequestInProgress(true);

    const { uuid } = dispatch(
      WorkspaceUserDao.update(
        { organizationId, workspaceId, workspaceUserId },
        { id: workspaceUserId, disconnected: false }
      )
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }: ErrorPayload) => {
          setReconnectError(errors[0]);
          setApiRequestInProgress(false);
        },
        onSuccess,
      })
    );
  };

  return {
    apiRequestInProgress,
    closeDisconnectConfirmModal,
    confirmDisconnectWorkspace,
    disconnectError,
    reconnectError,
    setReconnectError,
    handleReconnectClick,
    onConfirmDisconnectClick,
    openDisconnectConfirmModal,
  };
};

export const DisconnectWorkspaceGuestModal = ({
  onClose,
  onConfirm,
  username,
  workspaceName,
}: {
  onClose: () => void;
  onConfirm: () => void;
  username?: string;
  workspaceName: string;
}) => {
  return (
    <BaseConfirmModal
      confirmButtonText="Disconnect"
      content={
        <p>
          You are about to disconnect {username ? <>{username}&apos;s</> : 'your'} guest access to all dashboards in{' '}
          {workspaceName}.
        </p>
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

export const useDisconnectDashboardModal = ({
  fetchWorkspaceUser,
  guestDashboards,
  organizationId,
  setReconnectComplete,
  setReconnectError,
  workspaceId,
}: {
  fetchWorkspaceUser: (afterOnFinish: () => void) => void;
  guestDashboards: {
    dashboards: Array<Dashboard>;
    dashboardGuestsByDashboardId: { [dashboardId: number]: DashboardGuest };
  };
  organizationId: number;
  setReconnectComplete: Dispatch<SetStateAction<boolean>>;
  setReconnectError: Dispatch<SetStateAction<ApiError | undefined>>;
  workspaceId: number;
}) => {
  const dispatch = useDispatch();
  const [confirmDisconnectDashboardPassport, setConfirmDisconnectDashboardPassport] = useState<
    { dashboardId: number; dashboardName: string; dashboardGuestId: number } | undefined
  >();
  const closeDisconnectConfirmModal = () => setConfirmDisconnectDashboardPassport(undefined);
  const openDisconnectConfirmModal = (id: number, name: string, dashboardGuestId: number) =>
    setConfirmDisconnectDashboardPassport({ dashboardId: id, dashboardName: name, dashboardGuestId });

  const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
  const [disconnectError, setDisconnectError] = useState(false);

  const onConfirmDisconnectClick = () => {
    closeDisconnectConfirmModal();

    if (confirmDisconnectDashboardPassport) {
      setDisconnectError(false);
      setApiRequestInProgress(true);

      const { uuid } = dispatch(
        DashboardGuestDao.update(
          {
            organizationId,
            workspaceId,
            dashboardId: confirmDisconnectDashboardPassport.dashboardId,
            dashboardGuestId: confirmDisconnectDashboardPassport.dashboardGuestId,
          },
          { id: confirmDisconnectDashboardPassport.dashboardGuestId, disconnected: true }
        )
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onError: () => {
            setDisconnectError(true);
            setApiRequestInProgress(false);
          },
          onSuccess: () =>
            fetchWorkspaceUser(() => {
              setApiRequestInProgress(false);
            }),
        })
      );
    }
  };

  const onReconnectClick = (dashboardId: number) => (_: SyntheticEvent) => {
    const dashboardGuestId = guestDashboards.dashboardGuestsByDashboardId[dashboardId]?.id ?? 0;

    if (dashboardGuestId) {
      setReconnectError(undefined);
      setReconnectComplete(false);

      const { uuid } = dispatch(
        DashboardGuestDao.update(
          {
            organizationId,
            workspaceId,
            dashboardId,
            dashboardGuestId,
          },
          { id: dashboardGuestId, disconnected: false }
        )
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            setReconnectError(errors[0]);
            setReconnectComplete(true);
          },
          onSuccess: () => fetchWorkspaceUser(() => setReconnectComplete(true)),
        })
      );
    }
  };

  return {
    apiRequestInProgress,
    closeDisconnectConfirmModal,
    confirmDisconnectDashboardPassport,
    disconnectError,
    onConfirmDisconnectClick,
    onReconnectClick,
    openDisconnectConfirmModal,
  };
};

export const DisconnectDashboardModal = ({
  dashboardName,
  guestOrgUserName,
  isLastDashboard,
  onClose,
  onConfirm,
  workspaceName,
}: {
  dashboardName: string;
  guestOrgUserName?: string;
  isLastDashboard: boolean;
  onClose: () => void;
  onConfirm: () => void;
  workspaceName: string;
}) => {
  return (
    <BaseConfirmModal
      confirmButtonText="Disconnect"
      content={
        isLastDashboard ? (
          <p>
            You are about to remove {guestOrgUserName ? <>{guestOrgUserName}&apos;s</> : 'your'} guest access to all
            Dashboards in {workspaceName}.
          </p>
        ) : (
          <p>
            You are about to remove {guestOrgUserName ? <>{guestOrgUserName}&apos;s</> : 'your'} guest access to{' '}
            {dashboardName}.
          </p>
        )
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
