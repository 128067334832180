import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';

export const useWindowResize = (callback: () => void, timeout = 250) => {
  const debouncedCallback = useMemo(() => debounce(callback, timeout), [callback, timeout]);

  useEffect(() => {
    window.addEventListener('resize', debouncedCallback);

    return () => window.removeEventListener('resize', debouncedCallback);
  }, [debouncedCallback]);
};
