import { Button } from 'semantic-ui-react';

import LpModal from 'features/common/modals/lp_modal';

interface BaseConfirmModalProps {
  confirmButtonText: string;
  content: JSX.Element | string;
  error?: React.ReactNode | null;
  onClose: () => void;
  onConfirm: () => void;
  primary?: boolean;
}

export const BaseConfirmModal = ({
  confirmButtonText,
  content,
  error,
  onClose,
  onConfirm,
  primary,
}: BaseConfirmModalProps) => {
  return (
    <LpModal
      onClose={onClose}
      size="mini"
      header={'Are you sure?'}
      content={content}
      actions={
        <>
          {error}
          <Button content="Cancel" onClick={onClose} />
          <Button content={confirmButtonText} onClick={onConfirm} negative={!primary} primary={primary} />
        </>
      }
    />
  );
};
