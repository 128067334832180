import { motion } from 'framer-motion';

import { RelativePriorityType } from 'daos/item_enums';
import { Item, Dashboard } from 'daos/model_types';
import { AddWidgetGroupButton } from 'features/dashboards_v2/common/add_group_button';
import { ConfirmDeleteWidgetOrGroupModal } from 'features/dashboards_v2/common/confirm_delete_modal_for_dashboard_section';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { DashboardV2Header } from 'features/dashboards_v2/dashboard/header';
import { AddWidgetModal } from 'features/dashboards_v2/gallery/add_widget_modal';

import { WidgetGroups } from './widget_groups';

import './index.scss';

interface DashboardRendererProps {
  dashboardId: string;
  header?: JSX.Element | null;
  footer?: JSX.Element | null;
}

interface DashboardProps {
  item: Item;
  dashboard: Dashboard;
}

export const DashboardRenderer = ({ dashboardId, header, footer }: DashboardRendererProps) => {
  const { inDesign, showAddWidgetModal, showDeleteModal } = useDashboardContext();
  return (
    <motion.div layoutScroll layoutRoot className="v2-dashboards-view">
      {header}
      {showAddWidgetModal && <AddWidgetModal dashboardId={dashboardId} />}
      {showDeleteModal && <ConfirmDeleteWidgetOrGroupModal />}
      <div className="v2-dashboards-view__body">
        <WidgetGroups dashboardId={dashboardId} />
      </div>
      {inDesign && <div className="v2-dashboards-view__footer">{footer}</div>}
    </motion.div>
  );
};

export function DashboardV2({ item, dashboard }: DashboardProps) {
  const resolvedDashboardId = String(dashboard.id);

  return (
    <DashboardRenderer
      dashboardId={resolvedDashboardId}
      header={<DashboardV2Header dashboardId={resolvedDashboardId} itemId={item.id} />}
      footer={
        <AddWidgetGroupButton dashboardId={String(dashboard.id)} relativePriorityType={RelativePriorityType.AFTER} />
      }
    />
  );
}
