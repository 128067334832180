import { Radio } from 'semantic-ui-react';

import { TaskGroupHierarchy } from 'daos/external_integration_enums';
import { isOptionDisabled } from 'features/jira_project/modal/sections/sync_settings_section/helpers';
import { JiraProjectModalFormFields, SubFolderOrganizationValues } from 'features/jira_project/modal/types';

export const HierarchyLevelRadioButton = ({
  option,
  taskGroupHierarchy,
  setFieldValue,
  canSelectLowerHierarchicalLevel,
}: {
  option: SubFolderOrganizationValues;
  taskGroupHierarchy: TaskGroupHierarchy;
  setFieldValue: (field: string, value: any) => void;
  canSelectLowerHierarchicalLevel: { isFlatListDisabled: boolean; isEpicBasedDisabled: boolean };
}) => {
  const handleChange = () => setFieldValue(JiraProjectModalFormFields.TaskGroupHierarchy, option.id);

  return (
    <Radio
      onChange={handleChange}
      value={option.name}
      checked={taskGroupHierarchy === option.id}
      disabled={isOptionDisabled(option.id, canSelectLowerHierarchicalLevel)}
    />
  );
};
