import {
  bellLight as bell,
  boldLight as bolt,
  briefcaseLight as briefcase,
  coffeeLight as coffeeLight,
  fileLight as faFile,
  flagLight as flagLight,
  flaskLight as flask,
  giftLight as gift,
  imageLight as image,
  infoCircleLight as infoCircleLight,
  keyLight as keyLight,
  locationCircleLight as locationCircle,
  mapLight as map,
  doNotEnterLight as doNotEnter,
  paperclipLight as paperclipLight,
  paperPlaneLight as itemLink,
  planeLight as plane,
  presentationLight as presentation,
  shuttleVanLight as shuttleVan,
  spaceShuttleLight as spaceShuttle,
  tagLight as tag,
  wrenchLight as wrench,
  scrollLight as scrollLight,
  barsSolid,
  folderTreeLight as folderTree,
  seedlingLight as seedling,
  usersRegular,
  robotLight as robotLight,
  userClockLight as userClockLight,
  mapMarkedAltLight as mapMarkerAltLight,
  cactusLight as cactusLight,
  rabbitFastLight as rabbitFastLight,
  bullseyePointerLight as bullseyePointerLight,
  cogsLight as cogsLight,
  boxAltLight as boxAltLight,
  radarLight as radarLight,
  folderLight as folderLight,
  magicLight as magicLight,
  hexagonLight as hexagonLight,
  idCardLight as idCardLight,
  sensorAlertLight as sensorAlertLight,
  abacusLight,
  sortAmountAltLight as sortAmountAltLight,
  exclamationTriangleLight as exclamationTriangleLight,
  userHeadsetLight as userHeadset,
  flagCheckeredLight as flagCheckeredLight,
  shippingFastLight as shippingFastLight,
  starsLight as starsLight,
  usersClassLight as usersClassLight,
  streamRegular,
  cloudDownloadRegular,
  shieldAltRegular,
  globeSolid as workspaceIcon,
  pollSolid as dashboardIcon,
  dotCircleSolid,
  calculatorSolid,
  homeSolid,
  noteLight,
} from 'features/common/lp_icon';

export enum CustomIcon {
  ABACUS = 'abacus',
  BARS = 'bars',
  BELL = 'bell',
  BOLT = 'bolt',
  BOX_ALT = 'box-alt',
  BRIEFCASE = 'briefcase',
  BULLSEYE_POINTER = 'bullseye-pointer',
  CACTUS = 'cactus',
  CALCULATOR = 'calculator',
  CLOUD_DOWNLOAD_ALT = 'cloud-download-alt',
  COFFEE = 'coffee',
  COGS = 'cogs',
  DOT_CIRCLE = 'dot-circle',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  FILE = 'file',
  FLAG = 'flag',
  FLAG_CHECKERED = 'flag-checkered',
  FLASK = 'flask',
  FOLDER = 'folder',
  FOLDER_TREE = 'folder-tree',
  GIFT = 'gift',
  HEXAGON = 'hexagon',
  HOME = 'home',
  ID_CARD = 'id-card',
  IMAGE = 'image',
  INFO_CIRCLE = 'info-circle',
  KEY = 'key',
  LOCATION_CIRCLE = 'location-circle',
  MAGIC = 'magic',
  MAP = 'map',
  MAP_MARKED_ALT = 'map-marked-alt',
  NOT_ALLOWED = 'do-not-enter',
  PAPER_PLANE = 'paper-plane',
  PAPERCLIP = 'paperclip',
  PLANE = 'plane',
  POLL = 'poll',
  PRESENTATION = 'presentation',
  RABBIT_FAST = 'rabbit-fast',
  RADAR = 'radar',
  ROBOT = 'robot',
  SCROLL = 'scroll',
  SEEDLING = 'seedling',
  SENSOR_ALERT = 'sensor-alert',
  SHIELD_ALT = 'shield-alt',
  SHIPPING_FAST = 'shipping-fast',
  SHUTTLE_VAN = 'shuttle-van',
  SORT_AMOUNT_DOWN_ALT = 'sort-amount-down-alt',
  SPACE_SHUTTLE = 'space-shuttle',
  STARS = 'stars',
  STICKY_NOTE = 'sticky-note',
  STREAM = 'stream',
  TAG = 'tag',
  TH_LARGE = 'th-large',
  USER_CLOCK = 'user-clock',
  USER_HEADSET = 'user-headset',
  USERS = 'users',
  USERS_CLASS = 'user-class',
  WRENCH = 'wrench',
}

export const customIcon = (icon: CustomIcon) => {
  switch (icon) {
    case CustomIcon.ABACUS:
      return abacusLight;
    case CustomIcon.BARS:
      return barsSolid;
    case CustomIcon.BELL:
      return bell;
    case CustomIcon.BOLT:
      return bolt;
    case CustomIcon.BOX_ALT:
      return boxAltLight;
    case CustomIcon.BRIEFCASE:
      return briefcase;
    case CustomIcon.BULLSEYE_POINTER:
      return bullseyePointerLight;
    case CustomIcon.CACTUS:
      return cactusLight;
    case CustomIcon.CALCULATOR:
      return calculatorSolid;
    case CustomIcon.CLOUD_DOWNLOAD_ALT:
      return cloudDownloadRegular;
    case CustomIcon.COFFEE:
      return coffeeLight;
    case CustomIcon.COGS:
      return cogsLight;
    case CustomIcon.DOT_CIRCLE:
      return dotCircleSolid;
    case CustomIcon.EXCLAMATION_TRIANGLE:
      return exclamationTriangleLight;
    case CustomIcon.FILE:
      return faFile;
    case CustomIcon.FLAG:
      return flagLight;
    case CustomIcon.FLAG_CHECKERED:
      return flagCheckeredLight;
    case CustomIcon.FLASK:
      return flask;
    case CustomIcon.FOLDER:
      return folderLight;
    case CustomIcon.FOLDER_TREE:
      return folderTree;
    case CustomIcon.GIFT:
      return gift;
    case CustomIcon.HEXAGON:
      return hexagonLight;
    case CustomIcon.HOME:
      return homeSolid;
    case CustomIcon.ID_CARD:
      return idCardLight;
    case CustomIcon.IMAGE:
      return image;
    case CustomIcon.INFO_CIRCLE:
      return infoCircleLight;
    case CustomIcon.KEY:
      return keyLight;
    case CustomIcon.LOCATION_CIRCLE:
      return locationCircle;
    case CustomIcon.MAGIC:
      return magicLight;
    case CustomIcon.MAP:
      return map;
    case CustomIcon.MAP_MARKED_ALT:
      return mapMarkerAltLight;
    case CustomIcon.NOT_ALLOWED:
      return doNotEnter;
    case CustomIcon.PAPER_PLANE:
      return itemLink;
    case CustomIcon.PAPERCLIP:
      return paperclipLight;
    case CustomIcon.PLANE:
      return plane;
    case CustomIcon.POLL:
      return dashboardIcon;
    case CustomIcon.PRESENTATION:
      return presentation;
    case CustomIcon.RABBIT_FAST:
      return rabbitFastLight;
    case CustomIcon.RADAR:
      return radarLight;
    case CustomIcon.ROBOT:
      return robotLight;
    case CustomIcon.SCROLL:
      return scrollLight;
    case CustomIcon.SEEDLING:
      return seedling;
    case CustomIcon.SENSOR_ALERT:
      return sensorAlertLight;
    case CustomIcon.SHIELD_ALT:
      return shieldAltRegular;
    case CustomIcon.SHIPPING_FAST:
      return shippingFastLight;
    case CustomIcon.SHUTTLE_VAN:
      return shuttleVan;
    case CustomIcon.SORT_AMOUNT_DOWN_ALT:
      return sortAmountAltLight;
    case CustomIcon.SPACE_SHUTTLE:
      return spaceShuttle;
    case CustomIcon.STARS:
      return starsLight;
    case CustomIcon.STICKY_NOTE:
      return noteLight;
    case CustomIcon.STREAM:
      return streamRegular;
    case CustomIcon.TAG:
      return tag;
    case CustomIcon.TH_LARGE:
      return workspaceIcon;
    case CustomIcon.USER_CLOCK:
      return userClockLight;
    case CustomIcon.USER_HEADSET:
      return userHeadset;
    case CustomIcon.USERS:
      return usersRegular;
    case CustomIcon.USERS_CLASS:
      return usersClassLight;
    case CustomIcon.WRENCH:
      return wrench;
    default:
      return null;
  }
};
