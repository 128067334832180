import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserWorkspaceSettingFieldName } from 'daos/enums';
import { LocationFilterAssigneeSettings } from 'daos/types';
import { UserWorkspaceSettingsDao } from 'daos/user_workspace_settings';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { NoContentLocation } from 'features/common/no_content/no_content_helpers';

export const getWorkloadFilterGroupAndUserFilter = ({
  location,
  organizationUserIdFilter,
  groupIdFilter,
}: {
  location: NoContentLocation;
  organizationUserIdFilter: number | null;
  groupIdFilter: number | null;
}) => {
  switch (location) {
    case NoContentLocation.PackageWorkload:
      return {
        [UserWorkspaceSettingFieldName.PackageWorkloadOrganizationUserIdFilter]: organizationUserIdFilter,
        [UserWorkspaceSettingFieldName.PackageWorkloadGroupIdFilter]: groupIdFilter,
      };
    case NoContentLocation.ProjectWorkload:
      return {
        [UserWorkspaceSettingFieldName.ProjectWorkloadOrganizationUserIdFilter]: organizationUserIdFilter,
        [UserWorkspaceSettingFieldName.ProjectWorkloadGroupIdFilter]: groupIdFilter,
      };
    case NoContentLocation.PortfolioWorkload:
      return {
        [UserWorkspaceSettingFieldName.PortfolioWorkloadOrganizationUserIdFilter]: organizationUserIdFilter,
        [UserWorkspaceSettingFieldName.PortfolioWorkloadGroupIdFilter]: groupIdFilter,
      };
    default:
      return null;
  }
};

export const useHandleAssignedFilterValueChange = (location: NoContentLocation) => {
  const dispatch = useDispatch();

  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  return useCallback(
    (newValue: Partial<LocationFilterAssigneeSettings>) => {
      const settingsToUpdate = getWorkloadFilterGroupAndUserFilter({
        location,
        organizationUserIdFilter: newValue.organizationUserIdFilter ?? null,
        groupIdFilter: newValue.groupIdFilter ?? null,
      });

      if (!settingsToUpdate) {
        return;
      }

      dispatch(
        UserWorkspaceSettingsDao.updateAll(
          {
            organizationId,
            workspaceId,
          },
          settingsToUpdate
        )
      );
    },
    [dispatch, location, organizationId, workspaceId]
  );
};

export const isWorkloadFilterFiltered = (filterValue: LocationFilterAssigneeSettings) => {
  if (filterValue.groupIdFilter === null && filterValue.organizationUserIdFilter === null) {
    return false;
  } else {
    return true;
  }
};
