import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import ForgotPasswordForm from 'features/authentication/unauthenticated/forgot_password/form';
import { ReturnToSignIn } from 'features/authentication/unauthenticated/token/return_to_signin';
import { LpMotionFadeInAndUp } from 'features/common/animated_divs';
import { LpHeader } from 'features/common/as_components';
import 'features/authentication/unauthenticated/forgot_password/index.scss';

const ForgotPassword = () => (
  <>
    <LpMotionFadeInAndUp className="forgot-password">
      <div className="forgot-password__header-block">
        <LpHeader headerType="h2" className="forgot-password__header">
          Forgot Password? No Problem!
        </LpHeader>
        <LpHeader headerType="h3" className="forgot-password__sub-header">
          {"We'll send a recovery link."}
        </LpHeader>
      </div>

      <ForgotPasswordForm />

      <ReturnToSignIn />
    </LpMotionFadeInAndUp>

    <LpLegalTermsFooter />
  </>
);

export default ForgotPassword;
