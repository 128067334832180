import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { areEqual } from 'react-window';

import { LpIcon, caretRightSolid, exclamationSolid } from 'features/common/lp_icon';
import { LpNub } from 'features/common/lp_nub';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getContrast } from 'lib/color';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';
import { gray500, gray700, lpBrandWhite } from 'style/variables';

import './workload_date_range.scss';

interface WorkloadNubProps {
  dailyWorkSeconds: number | undefined;
  date: string;
  isParentTaskLate: boolean;
  isRollup?: boolean;
  late: boolean;
  nubColor?: string;
  username: string;
  timeOff: boolean;
  unavailable: boolean;
  workScheduledBeyondRange: boolean;
  taskName?: string | null;
}

interface NubPopUpHoverText {
  displayName?: string;
  taskName?: string;
  defaultValue: string;
}

export const getWorkloadNubPopHoverText = ({
  date,
  dailyWorkSeconds,
  displayName,
  itemName,
  formatLocalDate,
  hasTimeOff,
  unavailable,
  workScheduledBeyondRange,
}: {
  date: string;
  dailyWorkSeconds: number | undefined;
  displayName: string;
  itemName: string;
  formatLocalDate: (date: string) => string;
  hasTimeOff: boolean;
  unavailable: boolean;
  workScheduledBeyondRange: boolean;
}): NubPopUpHoverText => {
  const dailyWorkHours = formatAsHours(convertSecondsToHours(dailyWorkSeconds ?? 0));

  const popUpData = {} as NubPopUpHoverText;

  if (hasTimeOff) {
    popUpData.defaultValue = `${displayName} has time off on ${formatLocalDate(date)}`;
    return popUpData;
  } else if (unavailable) {
    popUpData.defaultValue = `${displayName} is unavailable on ${formatLocalDate(date)}`;
    return popUpData;
  }

  if (workScheduledBeyondRange) {
    popUpData.defaultValue = `Beyond ${formatLocalDate(date)}`;
    return popUpData;
  }

  popUpData.defaultValue = `${formatLocalDate(date)} (${dailyWorkHours})`;
  popUpData.taskName = itemName ?? '';
  popUpData.displayName = displayName;
  return popUpData;
};

export const getNubClassNames = ({
  dailyWorkSeconds,
  hasWorkScheduledOnDate,
  workScheduledBeyondRange,
  isRollup,
  renderAsLate,
  isEmptyWorkDate,
  timeOff,
  isParentTaskLate,
  unavailable,
}: {
  dailyWorkSeconds: number | undefined;
  hasWorkScheduledOnDate: boolean;
  workScheduledBeyondRange: boolean;
  isRollup: boolean;
  renderAsLate: boolean;
  isEmptyWorkDate: boolean;
  timeOff: boolean;
  isParentTaskLate: boolean;
  unavailable: boolean;
}) => {
  const noWorkScheduled = dailyWorkSeconds === 0;
  return classNames('workload-date-range__nub', {
    'workload-date-range__nub--zero-work-scheduled': noWorkScheduled,
    'workload-date-range__nub--work-scheduled': hasWorkScheduledOnDate,
    'workload-date-range__nub--work-scheduled-beyond-range': workScheduledBeyondRange,
    'workload-date-range__nub--roll-up-rendered-as-late': isRollup && renderAsLate,
    'workload-date-range__nub--roll-up': isRollup && !timeOff,
    'workload-date-range__nub--unavailable-with-late-parent unavailable':
      isEmptyWorkDate && !timeOff && isParentTaskLate && unavailable,
    'workload-date-range__nub--empty-with-late-parent': isEmptyWorkDate && !timeOff && isParentTaskLate,
    'workload-date-range__nub--unavailable unavailable': isEmptyWorkDate && !timeOff && unavailable,
    'workload-date-range__nub--empty': isEmptyWorkDate && !timeOff,
    'workload-date-range__nub--time-off': timeOff,
  });
};

export const nubSymbol = (renderAsLate: boolean) => {
  if (renderAsLate) {
    return <LpIcon className="workload-date-range__nub-risk-icon" icon={exclamationSolid} size="xs" />;
  } else {
    return '';
  }
};

const WorkloadNub = memo(
  ({
    date,
    isRollup = false,
    late,
    nubColor,
    timeOff,
    workScheduledBeyondRange,
    dailyWorkSeconds,
    username,
    isParentTaskLate,
    unavailable,
    taskName,
  }: WorkloadNubProps) => {
    const { formatLocalDate } = useLocalizedFormats();

    const hasWorkScheduledOnDate = !!dailyWorkSeconds;
    const renderAsLate = hasWorkScheduledOnDate && late;

    const isEmptyWorkDate = !hasWorkScheduledOnDate && !workScheduledBeyondRange;
    const applyNubColor = !isRollup && (hasWorkScheduledOnDate || workScheduledBeyondRange);

    const nubStyleColor = useMemo(() => {
      return applyNubColor ? { backgroundColor: `#${nubColor}` } : {};
    }, [nubColor, applyNubColor]);

    const alertIconStyleColor = useMemo(() => {
      if (applyNubColor) {
        return nubColor ? { color: `${getContrast(nubColor)}` } : {};
      }
      if (isRollup) {
        return { color: getContrast(gray700) };
      }
      return { color: getContrast(gray500) };
    }, [applyNubColor, isRollup, nubColor]);

    const computedNubStyles = useMemo(() => {
      return { ...alertIconStyleColor, ...nubStyleColor };
    }, [alertIconStyleColor, nubStyleColor]);

    const nubClassNames = getNubClassNames({
      dailyWorkSeconds,
      hasWorkScheduledOnDate,
      workScheduledBeyondRange,
      isRollup,
      renderAsLate,
      isEmptyWorkDate,
      timeOff,
      isParentTaskLate,
      unavailable,
    });

    const popupInfo = getWorkloadNubPopHoverText({
      date,
      dailyWorkSeconds,
      displayName: username,
      itemName: isRollup ? 'Rollup' : taskName ?? '',
      formatLocalDate,
      hasTimeOff: timeOff,
      unavailable,
      workScheduledBeyondRange,
    });

    const nubPopUpHoverText = () => {
      if (popupInfo.taskName) {
        return (
          <>
            <strong>{popupInfo.displayName}</strong>
            <br />
            <i>{popupInfo.taskName}</i>
            <br />
            {popupInfo.defaultValue}
          </>
        );
      }

      return popupInfo.defaultValue;
    };

    const symbol = nubSymbol(renderAsLate);

    return (
      <LpNub
        hoverContent={nubPopUpHoverText()}
        nubPopupClassName="workload-date-range__nub-hover"
        nubClassName={nubClassNames}
        nubStyle={workScheduledBeyondRange ? nubStyleColor : computedNubStyles}
      >
        {workScheduledBeyondRange ? (
          <LpIcon
            icon={caretRightSolid}
            color={lpBrandWhite}
            className="workload-date-range__nub--work-scheduled-beyond-range-icon"
            size="1x"
          />
        ) : (
          symbol
        )}
      </LpNub>
    );
  },
  areEqual
);

export default WorkloadNub;
