import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { LpIcon, starExclamationSolid } from 'features/common/lp_icon';

export const InvitationExpiredOrRevoked = () => (
  <div className="invitation-expired-or-revoked__wrapper">
    <div className="invitation-expired-or-revoked__content">
      <div className="invitation-expired-or-revoked">
        <LpIcon className="invitation-expired-or-revoked__icon" icon={starExclamationSolid} />
        <h1>Whoops!</h1>
        <span className="invitation-expired-or-revoked__text">
          {`Your email invitation to LiquidPlanner is no longer valid. You'll just need to be invited again to start
        planning!`}
        </span>
      </div>
    </div>

    <LpLegalTermsFooter />
  </div>
);
