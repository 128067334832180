import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FileFilter } from 'daos/filter_properties';
import { ItemFileDao } from 'daos/item_file';
import { Item, ItemFile } from 'daos/model_types';
import { filterIn, filterOr } from 'daos/shared';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';

export const useItemsHaveFiles = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  return useCallback(
    (items: ReadonlyArray<Item>, { onSuccess }: { onSuccess: (bool: boolean) => void }) => {
      const { uuid } = dispatch(
        ItemFileDao.fetchAll(
          { organizationId, workspaceId },
          {
            filter: filterOr(
              filterIn(
                FileFilter.BelongsToItemIdsOrChildren,
                items.map((item) => item.id)
              )
            ),
            query: { limit: 1 },
          }
        )
      );

      dispatch(
        awaitRequestFinish<ReadonlyArray<ItemFile>>(uuid, {
          onSuccess: ({ data }) => {
            onSuccess(data?.length > 0);
          },
        })
      );
    },
    [dispatch, organizationId, workspaceId]
  );
};
