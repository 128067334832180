import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { ColumnIndex } from 'containers/shared/custom_column/enum';
import { UserColumn } from 'containers/shared/custom_column/types';
import { ItemType, PackageStatus, StatusFilterGroups } from 'daos/enums';
import { Item } from 'daos/model_types';
import { resetStateExtraReducer } from 'redux/root_actions';

export interface ProjectHubState {
  readonly containerId: number | undefined;
  readonly currentItemIds: ReadonlyArray<number>;
  readonly currentPackageStatus?: PackageStatus | undefined;
  readonly projectId: number | undefined;
  readonly selectedBulkItemIds: ReadonlyArray<number>;
  readonly selectedBulkTaskStatus?: StatusFilterGroups | undefined;
  readonly userSelectableColumns: ReadonlyArray<UserColumn>;
}

export const initialState: ProjectHubState = {
  containerId: undefined,
  currentItemIds: [],
  currentPackageStatus: undefined,
  projectId: undefined,
  selectedBulkItemIds: [],
  userSelectableColumns: [],
};

const projectHubSlice = createSlice({
  name: 'projectHub',
  initialState,
  reducers: {
    clearProjectHubItemIds: (state) => {
      state.currentItemIds = [];
    },
    deleteProjectHubCurrentItemIds: (state, action: PayloadAction<Set<number>>) => {
      state.currentItemIds = state.currentItemIds.filter((id) => !action.payload.has(id));
    },
    moveProjectHubCurrentItemId: (state, action: PayloadAction<{ newParentId: number; itemId: number }>) => {
      const isDifferentParent = state.containerId !== action.payload.newParentId;

      if (isDifferentParent) {
        state.currentItemIds = state.currentItemIds.filter((itemId) => itemId !== action.payload.itemId);
      }
    },
    setProjectHubCurrentItemIds: (state, action: PayloadAction<ReadonlyArray<number>>) => {
      state.currentItemIds = castDraft(action.payload);
    },
    setProjectHubContainerItem: (
      state,
      action: PayloadAction<{ containerId: number; ancestorItems: ReadonlyArray<Item> }>
    ) => {
      const projectId = action.payload.ancestorItems.find((item) => item.itemType === ItemType.PROJECTS)?.id;
      state.containerId = action.payload.containerId;
      state.projectId = projectId;
    },
    setProjectHubCurrentPackageStatus: (state, action: PayloadAction<PackageStatus>) => {
      state.currentPackageStatus = action.payload;
    },
    setUserSelectableColumns: (state, action: PayloadAction<Array<UserColumn>>) => {
      state.userSelectableColumns = action.payload;
    },
    setUserSelectableColumnForIndex: (state, action: PayloadAction<{ column: UserColumn; index: ColumnIndex }>) => {
      state.userSelectableColumns[action.payload.index] = action.payload.column;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const {
  clearProjectHubItemIds,
  deleteProjectHubCurrentItemIds,
  moveProjectHubCurrentItemId,
  setProjectHubContainerItem,
  setProjectHubCurrentItemIds,
  setProjectHubCurrentPackageStatus,
  setUserSelectableColumns,
  setUserSelectableColumnForIndex,
} = projectHubSlice.actions;
export default projectHubSlice.reducer;
