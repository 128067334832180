import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getCurrentUser } from 'features/common/current/selectors';
import { startView } from 'lib/datadog/datadog_start_view';

const globalIdRegex = /\d+/g;

interface Props {
  wsId: number | undefined;
  orgId: number | undefined;
}

export const RouteLogger = ({ wsId, orgId }: Props) => {
  const { pathname } = useLocation();
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    if (window.datadogApplicationId && window.DD_RUM) {
      const currentUrl = pathname.replace(globalIdRegex, ':id');
      startView({
        page_title: document.title,
        page_location: `${currentUrl}`,
        user_id: currentUser?.id,
        ws_id: wsId,
        org_id: orgId,
      });
    }
    /*
       disabled b/c we only want this to trigger for path change.    If we added extra variables
       (WS_Id, Org_id, user_id) it would trigger extra "page views" in sending the information to DataDog
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
};
