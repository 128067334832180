import { useFormikContext } from 'formik';
import { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps, Table } from 'semantic-ui-react';

import { StoryPointSchemesWithStoryPoints } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import {
  getCopySchemeName,
  getSchemeFromId,
  getSchemeSelectionDropdownOptions,
} from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';

export const SelectSchemeRow = ({
  schemes,
  selectedSchemeActionDisplayName,
}: {
  schemes: ReadonlyArray<StoryPointSchemesWithStoryPoints>;
  selectedSchemeActionDisplayName: SchemeDisplayNames;
}) => {
  const { setFieldError, setFieldValue, errors, values } = useFormikContext<JiraProjectModalFormValues>();
  const options = getSchemeSelectionDropdownOptions(schemes);
  const error =
    selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme &&
    errors[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];
  const selectedExistingSchemeToCopyValue = values[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];

  if (options.length === 0) {
    return null;
  }

  const handleSelectedSchemeChange = (_e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const selectedOption = getSchemeFromId(schemes, data.value as number);
    const selectedSchemeValue = getCopySchemeName(selectedOption);
    setFieldError(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPoints, undefined);

    setFieldValue(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, data.value);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, selectedSchemeValue);
    setFieldValue(JiraProjectModalFormFields.StoryPoints, selectedOption?.storyPoints);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={<span>{'Select Scheme'}</span>} />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-dropdown">
        <Dropdown
          closeOnChange
          name={JiraProjectModalFormFields.SelectedExistingSchemeToCopy}
          onChange={handleSelectedSchemeChange}
          options={options}
          placeholder="Select"
          selection
          error={!!error}
          value={selectedExistingSchemeToCopyValue ?? 'Select'}
        />
      </Table.Cell>
    </JiraProjectModalTableRow>
  );
};
