import { StandardColumns } from 'containers/shared/custom_column/enum';
import { HoursColumnFormatter } from 'features/common/data_grid/formatters';
import { ItemFormatterProps } from 'features/common/data_grid/types';
import { DisplayValueWithOptionalWarningIcon } from 'lib/display_helpers/warning_icon_with_value';

export const WorkLimitDeltaColumnFormatter = (props: ItemFormatterProps) => {
  const workLimit = props.row[StandardColumns.WorkLimit];
  const workLimitDelta = props.row[StandardColumns.WorkLimitDelta] ?? 0;

  const showAlertIcon = workLimitDelta > 0;

  return workLimit ? (
    <DisplayValueWithOptionalWarningIcon
      value={<HoursColumnFormatter seconds={workLimitDelta} />}
      showWarningIcon={showAlertIcon}
    />
  ) : null;
};

export const WorkLimitColumnFormatter = (props: ItemFormatterProps) => {
  const workLimit = props.row[StandardColumns.WorkLimit];
  const workLimitDelta = props.row[StandardColumns.WorkLimitDelta] ?? 0;

  const showAlertIcon = workLimitDelta > 0;
  return workLimit ? (
    <DisplayValueWithOptionalWarningIcon
      value={<HoursColumnFormatter seconds={workLimit ?? 0} />}
      showWarningIcon={showAlertIcon}
    />
  ) : null;
};
