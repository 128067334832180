const joinInitials = (initials: Array<string>) => {
  if (initials.length > 2) {
    const firstInitial = initials[0];
    const secondInitial = initials[1];
    const lastInitial = initials[initials.length - 1];

    return `${firstInitial}${secondInitial}${lastInitial}`.toUpperCase();
  }
  return initials.join('').toUpperCase();
};

const initials = (name: string) => {
  if (!name) {
    return '';
  }

  const match = name.match(/\b\w/g);
  return match?.length ? joinInitials(match) : name;
};

export default initials;
