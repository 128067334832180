import { FieldMetaProps } from 'formik';
import { ReactNode } from 'react';
import { StrictLabelProps } from 'semantic-ui-react';

export const formErrorLabel = ({
  fieldMeta,
  hideErrorMessage = false,
  errorLabelDirection = 'above',
}: {
  fieldMeta: FieldMetaProps<ReactNode>;
  hideErrorMessage?: boolean;
  errorLabelDirection?: StrictLabelProps['pointing'];
}) => {
  const isErrorPresent = fieldMeta.touched && !!fieldMeta.error;
  const fieldErrorMessage = fieldMeta.error;

  if (isErrorPresent) {
    if (hideErrorMessage) {
      return true;
    }

    return { content: fieldErrorMessage, pointing: errorLabelDirection };
  }

  return false;
};
