import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ItemType } from 'daos/enums';
import { isAncestorItemScheduleBar as isAncestorItem } from 'features/common/schedule_bar/helpers';
import { ScheduleBarRendererProps } from 'features/common/schedule_bar/types';
import { useItemDataForScheduleBar } from 'features/common/schedule_bar/use_item_data_for_schedule_bar';
import { useItemDataForScheduleTooltip, ScheduleHoverStatus } from 'hooks/use_item_data_for_schedule_hover';
import { useItemDataForStatus } from 'hooks/use_item_data_for_status';
import { ScheduleViewType } from 'hooks/use_items_date_range';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getHasPredecessors, getHasSuccessors } from 'redux/entities/selectors/dependency';
import { DateRange, getItemForId } from 'redux/entities/selectors/item';
import { getItemMetricsForId } from 'redux/entities/selectors/item_metric';

export const useGetScheduleBarRendererProps = ({
  dateRange,
  hasAssignmentPredecessor,
  hasAssignmentSuccessor,
  isAssignmentPlaceholder = false,
  isAssignmentTrackingOnly = false,
  isDateTimeMode = false,
  isDebugMode = false,
  itemId,
  showDateLabels,
  viewType,
}: {
  dateRange: DateRange;
  hasAssignmentPredecessor?: boolean;
  hasAssignmentSuccessor?: boolean;
  isAssignmentPlaceholder?: boolean;
  isAssignmentTrackingOnly?: boolean;
  isDateTimeMode?: boolean;
  isDebugMode?: boolean;
  itemId: number;
  showDateLabels: boolean;
  viewType: ScheduleViewType;
}) => {
  const { pathname } = useLocation();
  const { formatLocalDate, formatLocalDateWithTime } = useLocalizedFormats();
  const formatDateForDisplay = (day: string) => (isDateTimeMode ? formatLocalDateWithTime(day) : formatLocalDate(day));

  const item = useSelector((state) => getItemForId(state, itemId));
  const itemMetrics = useSelector((state) => getItemMetricsForId(state, itemId));
  const hasPredecessors = useSelector((state) => getHasPredecessors(state, itemId));
  const hasSuccessors = useSelector((state) => getHasSuccessors(state, itemId));

  const statusBooleans = useItemDataForStatus(itemId);
  const isIncomplete = useItemDataForScheduleTooltip(itemId) === ScheduleHoverStatus.NotComplete;

  const { isOnHold: isParentOnHold, isEffectivelyOnHold: isParentEffectivelyOnHold } = useItemDataForStatus(
    item?.parent?.id
  );

  const offsetPercentsStylesAndShowRollUps = useItemDataForScheduleBar({ itemId, dateRange, viewType });

  if (!item) {
    return null;
  }

  const showPredecessor =
    (hasAssignmentPredecessor || hasPredecessors) &&
    !statusBooleans.isDone &&
    !statusBooleans.isOnHold &&
    !statusBooleans.isEffectivelyOnHold;
  const showSuccessor =
    (hasAssignmentSuccessor &&
      !statusBooleans.isDone &&
      !statusBooleans.isOnHold &&
      !statusBooleans.isEffectivelyOnHold) ||
    hasSuccessors;
  const showRollupDates = viewType === ScheduleViewType.ItemPanel;

  const { itemType, organizationUser } = item;

  const isAncestorItemScheduleBar = isAncestorItem({ itemType, pathname });
  const isAssignment = itemType === ItemType.ASSIGNMENTS;
  const isTask = itemType === ItemType.TASKS;
  const isAssignmentNeedsAttention = !organizationUser;

  const scheduleBarRendererProps: ScheduleBarRendererProps = {
    ...statusBooleans,
    ...offsetPercentsStylesAndShowRollUps,
    ...item,
    effectiveTargetFinish: itemMetrics?.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics?.effectiveTargetStart,
    formatDateForDisplay,
    highEffort: item.highEffort ?? undefined,
    isAncestorItemScheduleBar,
    isAssignment,
    isAssignmentNeedsAttention,
    isAssignmentPlaceholder,
    isAssignmentTrackingOnly,
    isDebugMode,
    isIncomplete,
    isParentEffectivelyOnHold,
    isParentOnHold,
    isTask,
    itemId,
    rollupEarliestActiveTargetFinish: itemMetrics?.rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish: itemMetrics?.rollupLatestTargetFinish,
    showDateLabels,
    showPredecessor,
    showRollupDates,
    showSuccessor,
    viewType,
  };

  return scheduleBarRendererProps;
};
