import { AccessType, Permission } from 'daos/enums';
import { SubjectAccessForItemGrid } from 'daos/model_types';

export function getAllMembersAccessControlPermission(subjectAccess: SubjectAccessForItemGrid | undefined): Permission {
  if (subjectAccess?.everyoneRow.manage && subjectAccess.everyoneRow.manage !== AccessType.None) {
    return Permission.MANAGE;
  }

  if (subjectAccess?.everyoneRow.edit && subjectAccess.everyoneRow.edit !== AccessType.None) {
    return Permission.EDIT;
  }

  if (subjectAccess?.everyoneRow.observe && subjectAccess.everyoneRow.observe !== AccessType.None) {
    return Permission.OBSERVE;
  }

  return Permission.NONE;
}
