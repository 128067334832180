import classNames from 'classnames';
import { Formik, FormikConfig, FormikValues, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { Button } from 'semantic-ui-react';

import { LpFormikForm } from 'features/common/forms/formik/form';
import WidgetModal, {
  WidgetModalActions,
  WidgetModalContent,
  WidgetModalHeader,
} from 'features/dashboards/dashboard/widget_modal';
import { useDashboardContext } from 'features/dashboards_v2/context';
import './index.scss';

type FormikProps<T> = Omit<FormikConfig<T>, 'children' | 'component'>;
interface WidgetSettingsV2Props {
  className?: string;
  content: ReactNode;
}
export function WidgetSettingsV2<T extends FormikValues>({
  className,
  content,
  ...rest
}: WidgetSettingsV2Props & FormikProps<T>) {
  const { setShowSettingsForWidget } = useDashboardContext();

  function onClose() {
    setShowSettingsForWidget(null);
  }

  return (
    <Formik<T> {...rest}>
      <WidgetModal className={classNames('base-v2-widget-settings', className)} size="tiny">
        {content}
        <WidgetSettingsV2Actions<T> onClose={onClose} />
      </WidgetModal>
    </Formik>
  );
}

interface WidgetSettingsModalContentProps {
  header?: ReactNode;
  content?: ReactNode;
  error?: boolean;
}
export function WidgetSettingsModalContent({ header, content, error = false }: WidgetSettingsModalContentProps) {
  if (error) {
    return (
      <>
        <WidgetModalContent>Widget settings failed to render.</WidgetModalContent>
      </>
    );
  }

  return (
    <>
      <WidgetModalHeader>{header}</WidgetModalHeader>
      <WidgetModalContent>
        <LpFormikForm className="base-v2-widget-settings__content">{content}</LpFormikForm>
      </WidgetModalContent>
    </>
  );
}

interface WidgetSettingsV2ActionsProps {
  onClose: () => void;
}
function WidgetSettingsV2Actions<T>({ onClose }: WidgetSettingsV2ActionsProps) {
  const { submitForm, isSubmitting } = useFormikContext<T>();

  return (
    <WidgetModalActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button disabled={isSubmitting} onClick={submitForm} primary type="submit">
        Save
      </Button>
    </WidgetModalActions>
  );
}
