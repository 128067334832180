import { Widget } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { WidgetGridDownloadXLS } from 'features/common/data_grid/grids/top_controls/grid_download_xls';
import { getSafeListWidgetFilterQueryString } from 'features/guest/dashboard/expanded_widget/grid/quick_filter';
import { readonlyArray } from 'lib/readonly_record';

interface GuestWidgetGridDownloadProps {
  inputValue: string;
  widget: Widget;
}
export const GuestWidgetGridDownload = ({ inputValue, widget }: GuestWidgetGridDownloadProps) => {
  const columnKeys = getWidgetColumnKeys(widget.config.columns ?? []);
  const filter = getSafeListWidgetFilterQueryString(widget, inputValue);

  return <WidgetGridDownloadXLS columnKeys={columnKeys} filter={filter} widget={widget} />;
};

function getWidgetColumnKeys(columns: ReadonlyArray<WidgetConfigColumn>): ReadonlyArray<string | number> {
  return readonlyArray(columns.map((col) => col.customFieldId ?? col.column));
}
