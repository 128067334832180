import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import { OrganizationUserOrderBy, UserType } from 'daos/enums';
import { OrganizationUserFilter } from 'daos/filter_properties';
import { OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { filterAnd, filterContainsIgnoreCase, filterEq, filterNull, filterOr } from 'daos/shared';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import {
  setFilteredOrgUsers,
  setOrganizationHubHasError,
  setOrganizationHubUsersFetchComplete,
} from 'features/organization_directory/slice';
import { useDebouncedInput } from 'hooks/use_debounced_input';
import { awaitRequestFinish } from 'lib/api';

export enum OrgUsersQuickFilterType {
  MEMBERS = 'members',
  DISCONNECTED = 'disconnected',
  DASHBOARD_GUESTS = 'dashboard_guests',
}

export const getOrgUserFilterQueryString = ({
  filterType,
  inputValue,
}: {
  filterType: OrgUsersQuickFilterType;
  inputValue: string;
}) => {
  const filters = [filterEq(OrganizationUserFilter.UserType, UserType.Member)];

  const trimmedInputValue = inputValue.trim();
  if (trimmedInputValue) {
    filters.push(
      filterOr(
        filterContainsIgnoreCase(OrganizationUserFilter.UserFullName, trimmedInputValue),
        filterContainsIgnoreCase(OrganizationUserFilter.Username, trimmedInputValue)
      )
    );
  }

  filters.push(filterNull(OrganizationUserFilter.DisconnectedAt, filterType !== OrgUsersQuickFilterType.DISCONNECTED));

  if (filterType === OrgUsersQuickFilterType.DASHBOARD_GUESTS) {
    filters.push(filterEq(OrganizationUserFilter.ActiveDashboardGuest, true));
  }

  if (filterType === OrgUsersQuickFilterType.MEMBERS) {
    filters.push(filterEq(OrganizationUserFilter.ActiveDashboardGuest, false));
  }

  return filterAnd(...filters);
};

export const OrgUsersQuickFilter = ({ filterType }: { filterType: OrgUsersQuickFilterType }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const { inputValue, setDebouncedValue } = useDebouncedInput();

  const filterQueryString = useMemo(
    () => getOrgUserFilterQueryString({ filterType, inputValue }),
    [filterType, inputValue]
  );

  const fetchOrgUsers = useCallback(() => {
    dispatch(setOrganizationHubUsersFetchComplete(false));
    const { uuid } = dispatch(
      OrganizationUserDao.fetchAll(
        { organizationId },
        { filter: filterQueryString, query: { order: OrganizationUserOrderBy.Username } }
      )
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<OrganizationUser>>(uuid, {
        onError: () => {
          dispatch(setOrganizationHubHasError(true));
        },
        onSuccess: ({ data }) => {
          dispatch(setFilteredOrgUsers(data));
          dispatch(setOrganizationHubHasError(false));
        },
        onFinish: () => dispatch(setOrganizationHubUsersFetchComplete(true)),
      })
    );
  }, [dispatch, filterQueryString, organizationId]);

  useEffect(() => {
    fetchOrgUsers();
  }, [fetchOrgUsers]);

  const onChange = (newValue: string) => {
    setDebouncedValue.current(newValue);
  };

  return (
    <>
      <Menu borderless secondary>
        <Menu.Item fitted>
          <LpSearchInputUncontrolled onChange={onChange} />
        </Menu.Item>
      </Menu>
    </>
  );
};
