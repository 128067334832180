import { UniqueIdentifier } from '@dnd-kit/core';
import { noop } from 'lodash';
import { createContext, Dispatch, MutableRefObject, SetStateAction, useContext } from 'react';

import { PackageStatus } from 'daos/enums';
import { RelativePriorityType } from 'daos/item_enums';
import { Dashboard, LibraryResource, Widget } from 'daos/model_types';
import { DashboardType } from 'features/dashboards/dashboard_types';
import { DeleteModalProps } from 'features/dashboards_v2/common/confirm_delete_modal_types';
import { LibraryLocation } from 'features/library/types';

export enum DashboardsDraggableType {
  Widget = 'widget',
  Group = 'group',
}
export interface WidgetGroupIdAndIndex {
  id: UniqueIdentifier;
  widgetGroupId: UniqueIdentifier;
  index: number;
  widgetIds: Array<WidgetIdAndIndex>;
  type: DashboardsDraggableType;
}
export interface WidgetIdAndIndex {
  id: UniqueIdentifier;
  widgetId: UniqueIdentifier;
  widgetGroupId: string;
  WidgetGroupIndex: number;
  index: number;
  type: DashboardsDraggableType;
}
export interface AddWidgetModalProps {
  widgetGroupId: string | null;
  relativePriority?: RelativePriorityType;
}

interface DashboardContextProps {
  activeGroupId: WidgetGroupIdAndIndex | null;
  activeWidgetId: WidgetIdAndIndex | null;
  dashboard: Dashboard | null;
  inDesign: boolean;
  isGuest: boolean;
  isLoading: boolean;
  setActiveGroupId: Dispatch<SetStateAction<WidgetGroupIdAndIndex | null>>;
  setActiveWidgetId: Dispatch<SetStateAction<WidgetIdAndIndex | null>>;
  setShowAddWidgetModal: Dispatch<SetStateAction<boolean>>;
  setFilterModalWidgetGroupId: Dispatch<SetStateAction<string | null>>;
  setShowSettingsForWidget: Dispatch<SetStateAction<string | null>>;
  addWidgetModalProps: AddWidgetModalProps;
  setAddWidgetModalProps: Dispatch<SetStateAction<AddWidgetModalProps>>;
  showAddWidgetModal: boolean;
  showSettingsForWidget: string | null;
  isGroupLayoutTransitioning: MutableRefObject<boolean> | null;
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  deleteModalProps: DeleteModalProps;
  setDeleteModalProps: Dispatch<SetStateAction<DeleteModalProps>>;
  packageStatus?: PackageStatus | null;
  dashboardType: DashboardType | undefined;
  libraryLocation: LibraryLocation | undefined;
  libraryResource: LibraryResource | undefined;
}
export const defaultDashboardContextValues: DashboardContextProps = {
  activeGroupId: null,
  activeWidgetId: null,
  dashboard: null,
  inDesign: false,
  isGuest: false,
  isLoading: false,
  setActiveGroupId: noop,
  setActiveWidgetId: noop,
  setShowAddWidgetModal: noop,
  setFilterModalWidgetGroupId: noop,
  showAddWidgetModal: false,
  showSettingsForWidget: null,
  setShowSettingsForWidget: noop,
  addWidgetModalProps: { widgetGroupId: null, relativePriority: undefined },
  setAddWidgetModalProps: noop,
  isGroupLayoutTransitioning: null,
  showDeleteModal: false,
  setShowDeleteModal: noop,
  deleteModalProps: { deleteContent: null, deleteAction: noop },
  setDeleteModalProps: noop,
  packageStatus: undefined,
  dashboardType: undefined,
  libraryLocation: undefined,
  libraryResource: undefined,
};

export const DashboardContext = createContext<DashboardContextProps>(defaultDashboardContextValues);

export function useDashboardContext() {
  return useContext(DashboardContext);
}

interface WidgetContextProps {
  widget: Widget | null;
  widgetBodyRef: (node: HTMLDivElement) => void;
}

export const WidgetContext = createContext<WidgetContextProps>({
  widget: null,
  widgetBodyRef: noop,
});

export function useWidgetContext() {
  return useContext(WidgetContext);
}
