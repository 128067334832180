import { createSelector } from 'reselect';

import { StoryPointSchemeOwner } from 'daos/model_types';
import { groupRecordBy, readonlyArray, readonlyRecord, ReadonlyRecordKey } from 'lib/readonly_record';
import { RootState } from 'redux/root_reducer';

const emptyStoryPointSchemeOwners = readonlyArray<StoryPointSchemeOwner>([]);

export const getStoryPointSchemeOwnersById = (state: RootState) => state.entities.storyPointSchemeOwners;

const getStoryPointSchemeOwnersGroupedBySchemeId = createSelector(
  getStoryPointSchemeOwnersById,
  (storyPointSchemesById) => {
    return groupRecordBy(storyPointSchemesById, (storyPointScheme) => storyPointScheme.storyPointScheme.id);
  }
);

export const getFilteredStoryPointSchemeOwnersBySchemeIds = createSelector(
  getStoryPointSchemeOwnersGroupedBySchemeId,
  (_: RootState, storyPointSchemeIds: ReadonlyArray<number>) => storyPointSchemeIds,
  (storyPointSchemeOwnersGroupedBySchemeId, schemeIds) => {
    const filteredOwners: Record<ReadonlyRecordKey, Array<StoryPointSchemeOwner>> = {};
    schemeIds.forEach((id) => {
      if (storyPointSchemeOwnersGroupedBySchemeId[id]) {
        filteredOwners[id] = [
          ...(storyPointSchemeOwnersGroupedBySchemeId[id] || emptyStoryPointSchemeOwners),
        ] as Array<StoryPointSchemeOwner>;
      }
    });
    return readonlyRecord(filteredOwners);
  }
);

export const getIsWorkspaceDefaultStoryPointOwnerForSchemeId = createSelector(
  getStoryPointSchemeOwnersGroupedBySchemeId,
  (_: RootState, schemeId: number) => schemeId,
  (schemeOwnersBySchemeId, schemeId) => {
    const schemeOwners = schemeOwnersBySchemeId[schemeId] ?? [];
    if (!schemeOwners.length) {
      return false;
    }

    return schemeOwners.some((owner) => owner.item === null);
  }
);

export const getSchemeOwnerOrDefaultForProjectId = createSelector(
  getStoryPointSchemeOwnersById,
  (_: RootState, itemId: number) => itemId,
  (storyPointSchemeOwnersById, itemId) => {
    const schemeOwner = Object.values(storyPointSchemeOwnersById).find((owner) => owner.item?.id === itemId);
    if (!schemeOwner) {
      return Object.values(storyPointSchemeOwnersById).find((owner) => owner.item === null);
    }
    return schemeOwner;
  }
);

export const getSchemeOwnerForExternalIntegrationId = createSelector(
  getStoryPointSchemeOwnersById,
  (_: RootState, itemId: number) => itemId,
  (storyPointSchemeOwnersById, itemId) => {
    return Object.values(storyPointSchemeOwnersById).find((owner) => owner.item?.id === itemId);
  }
);
