import classNames from 'classnames';
import { FieldInputProps, FieldMetaProps, FormikHelpers } from 'formik';
import { SyntheticEvent } from 'react';
import { DropdownItemProps, DropdownProps, Form, StrictDropdownProps } from 'semantic-ui-react';

import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';

import { formErrorLabel } from './form_field_helpers/form_error_label';

type DropdownValue = StrictDropdownProps['value'];
type SetFieldValue = FormikHelpers<DropdownValue>['setFieldValue'];

export type LpFormDropdownOptionsProps = DropdownItemProps & { search: string };

export interface LpFormDropdownFormikProps {
  getFieldMeta: (name: string) => FieldMetaProps<DropdownValue>;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<DropdownValue>;
  setFieldValue: SetFieldValue;
}
interface LpFormDropdownProps extends LpFormDropdownFormikProps {
  fieldKey: string;
  clearable?: boolean;
  className?: string;
  disabled?: boolean;
  customOnBlur?: () => void;
  placeholder?: string;
  label?: string;
  options: Array<LpFormDropdownOptionsProps>;
  shouldValidate?: boolean;
  hideErrorMessage?: boolean;
  e2eTestId?: string;
}

export function LpFormDropdown({
  fieldKey,
  clearable,
  disabled,
  className,
  getFieldMeta,
  getFieldProps,
  setFieldValue,
  customOnBlur,
  placeholder,
  label,
  options,
  shouldValidate = true,
  hideErrorMessage = false,
  e2eTestId,
}: LpFormDropdownProps) {
  const fieldMeta = getFieldMeta(fieldKey);
  const fieldProps = getFieldProps(fieldKey);

  const handleOnBlur = (_: SyntheticEvent, { value }: DropdownProps) => {
    customOnBlur?.();
    setFieldValue(fieldKey, value, shouldValidate);
  };

  const handleOnChange = (_: SyntheticEvent, { value }: DropdownProps) => {
    customOnBlur?.();
    setFieldValue(fieldKey, value, shouldValidate);
  };

  return (
    <Form.Select
      id={fieldKey}
      data-testid={`lp-form-dropdown-${fieldKey}`}
      lazyLoad
      label={label}
      className={classNames(className, 'lp-form__dropdown')}
      disabled={disabled}
      error={formErrorLabel({ fieldMeta, hideErrorMessage })}
      onChange={handleOnChange}
      value={fieldProps.value}
      placeholder={placeholder}
      onBlur={handleOnBlur}
      options={options}
      selection
      clearable={clearable}
      search={lpDropdownSearch}
      data-e2e-test-id={e2eTestId}
    />
  );
}
