import { ReactNode } from 'react';
import { Menu } from 'semantic-ui-react';

import UserMenu from 'containers/shared/user_menu';
import { LpMenuItemAsLink } from 'features/common/as_components';
import { TopNav } from 'features/organization/top_nav/top_nav_context';

export interface TopNavMenuTab {
  content: string;
  active: boolean;
  to: string;
}

export const mapTabsToMenuItems = (menuItem: TopNavMenuTab) => (
  <LpMenuItemAsLink key={menuItem.content} fitted active={menuItem.active} to={menuItem.to}>
    {menuItem.content}
  </LpMenuItemAsLink>
);

interface TopNavHeaderProps {
  avatar?: ReactNode;
  breadcrumb: ReactNode;
  favorite?: ReactNode;
  menuTabs?: ReadonlyArray<TopNavMenuTab>;
}

export const TopNavHeader = ({ avatar, breadcrumb, favorite, menuTabs }: TopNavHeaderProps) => (
  <TopNav>
    <div className="lp-top-nav__avatar">{avatar}</div>
    <div className="lp-top-nav__favorite">{favorite}</div>
    <div className="lp-top-nav__breadcrumb">{breadcrumb}</div>
    <div className="lp-top-nav__user-menu">
      <UserMenu />
    </div>
    {menuTabs && menuTabs.length > 0 && (
      <Menu pointing secondary className="lp-top-nav__menu">
        {menuTabs.map(mapTabsToMenuItems)}
      </Menu>
    )}
  </TopNav>
);
