import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { Workload } from 'daos/model_types';
import { SetItemDeletionParams } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { FlattenedWorkloadData } from 'features/common/workload/workload_table/types';
const BASE_WINDOW_MULTIPLE = 30;

interface WorkloadTableContextProps {
  fetchWorkload: () => void;
  setDeletionData: Dispatch<SetStateAction<SetItemDeletionParams | undefined>>;
  setMoveItemId: Dispatch<SetStateAction<number | undefined>>;
  handleToggleByOrgUserId: (orgUserId: number) => void;
  workloadId: number | undefined;
  dayRange: number;
  workload?: Workload;
  workloadFlattenedData: Array<FlattenedWorkloadData>;
  setExpandedOrgUserIds: Dispatch<SetStateAction<Set<number>>>;
  boundingBox?: DOMRect;
}

export const WorkloadTableContext = createContext<WorkloadTableContextProps>({
  fetchWorkload: noop,
  setDeletionData: noop,
  setMoveItemId: noop,
  handleToggleByOrgUserId: noop,
  workloadId: undefined,
  dayRange: BASE_WINDOW_MULTIPLE,
  workload: undefined,
  workloadFlattenedData: [],
  setExpandedOrgUserIds: noop,
  boundingBox: undefined,
});

export function useWorkloadTableContext() {
  return useContext(WorkloadTableContext);
}
