import { useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import LpLink from 'containers/shared/lp_link';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { LpIcon, passportSolid } from 'features/common/lp_icon';
import { GuestDashboard } from 'features/guest/dashboard';
import { GuestDashboardExpandedWidget } from 'features/guest/dashboard/expanded_widget';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { frontend } from 'lib/urls';
import { getCurrentUserHasGuestAccessToDashboard } from 'redux/entities/selectors/dashboard_guest';
import { getOrganizationNameForId } from 'redux/entities/selectors/organization';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';
import { getWorkspaceNameForId } from 'redux/entities/selectors/workspace';
import { slate600 } from 'style/variables';

const getBreadcrumb = (orgName: string, wsName: string, hasAccess: boolean) => {
  const baseCrumb = [
    {
      key: 'dashboard-passports',
      content: (
        <span>
          <LpIcon color={slate600} icon={passportSolid} />{' '}
          <LpLink to={frontend.dashboardPassports.url({})}>Dashboard Passports</LpLink>
        </span>
      ),
    },
  ];

  if (!hasAccess) {
    return baseCrumb;
  }

  return baseCrumb.concat([
    {
      key: 'organization',
      content: <>{orgName}</>,
    },
    {
      key: 'workspace',
      content: <>{wsName}</>,
    },
  ]);
};

export const DashboardGuestRoutes = () => {
  const { organizationId, workspaceId, dashboardId } = useParams<{
    organizationId: string;
    workspaceId: string;
    dashboardId: string;
  }>();

  const organizationName =
    useSelector((state) => getOrganizationNameForId(state, Number(organizationId))) ?? 'Organization';
  const workspaceName = useSelector((state) => getWorkspaceNameForId(state, Number(workspaceId))) ?? 'Workspace';

  const isCurrentWorkspaceUserGuest = useSelector(getIsGuestCurrentWorkspaceUser);
  const hasGuestAccessToDashboard = useSelector((state) =>
    getCurrentUserHasGuestAccessToDashboard(state, organizationId, workspaceId, dashboardId)
  );

  const breadcrumbSections = getBreadcrumb(
    organizationName,
    workspaceName,
    isCurrentWorkspaceUserGuest && hasGuestAccessToDashboard
  );

  return (
    <>
      <TopNavHeader breadcrumb={<LpBreadcrumb sections={breadcrumbSections} />} />

      <Switch>
        <Route path={frontend.dashboardGuestWidget.pattern} component={GuestDashboardExpandedWidget} />
        <Route path={frontend.dashboardGuest.pattern} component={GuestDashboard} />
      </Switch>
    </>
  );
};
