import { Realtime as AblyRealtime } from 'ably';

import { backend } from 'daos/urls';

const isTestEnv = process.env.NODE_ENV === 'test';

const client = !isTestEnv
  ? new AblyRealtime({
      authUrl: backend.ablyAuth.url({}),
    })
  : undefined;

export default client;
