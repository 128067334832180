import { useFormikContext } from 'formik';
import { Form, FormInputProps, InputOnChangeData } from 'semantic-ui-react';

import { formErrorLabel } from 'features/common/forms/form_field_helpers/form_error_label';

type LpFormikInputProps = {
  id?: string;
  name: string;
  hideErrorMessage?: boolean;
  restFieldErrorOnChange?: boolean;
  autoFocus?: boolean;
} & Omit<FormInputProps, 'checked' | 'id' | 'name' | 'multiple' | 'onBlur' | 'onChange' | 'value'>;

export const LpFormikInput = ({
  id,
  name,
  autoFocus = false,
  hideErrorMessage = false,
  restFieldErrorOnChange = false,
  ...inputProps
}: LpFormikInputProps) => {
  const { getFieldMeta, getFieldProps, setFieldValue, setFieldError } = useFormikContext();
  const fieldMeta = getFieldMeta<React.ReactNode>(name);
  const fieldProps = getFieldProps(name);

  const handleChange = (_e: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    if (restFieldErrorOnChange) {
      setFieldError(name, undefined);
    }
    setFieldValue(name, value);
  };

  return (
    <Form.Input
      autoFocus={autoFocus}
      autoComplete={'off'}
      id={id ?? name}
      error={formErrorLabel({ fieldMeta, hideErrorMessage })}
      {...fieldProps}
      {...inputProps}
      onChange={handleChange}
    />
  );
};
