import { useSortable } from '@dnd-kit/sortable';
import { memo } from 'react';
import { areEqual } from 'react-window';

import { DashboardsDraggableType } from 'features/dashboards_v2/context';
import { WidgetRenderer } from 'features/dashboards_v2/widget/renderer';

interface SortableWidgetProps {
  dashboardId: string;
  widgetGroupId: string;
  widgetId: string;
  index: number;
  widgetGroupIndex: number;
}
export const SortableWidget = memo(
  ({ dashboardId, widgetGroupId, widgetId, index, widgetGroupIndex }: SortableWidgetProps) => {
    const { attributes, listeners, setNodeRef, isDragging, setActivatorNodeRef } = useSortable({
      id: `w-${widgetId}`,
      data: {
        type: DashboardsDraggableType.Widget,
        widgetId,
        widgetGroupId,
        widgetGroupIndex,
        index,
      },
      transition: null,
    });

    return (
      <WidgetRenderer
        key={widgetId}
        dashboardId={dashboardId}
        widgetId={widgetId.toString()}
        isDragging={isDragging}
        layoutId={`widget-${widgetId}`}
        ref={setNodeRef}
        draggableRef={setActivatorNodeRef}
        attributes={attributes}
        listeners={listeners}
      />
    );
  },
  areEqual
);
