import { Table } from 'semantic-ui-react';

import OrganizationDirectoryAdminsRow from './row';

const OrganizationDirectoryAdminsTable = () => {
  return (
    <div className="organization-directory__admins-table-area">
      <Table className="organization-directory__admins-table">
        <Table.Header className="organization-directory__admins-table-header">
          <Table.Row>
            <Table.HeaderCell>Admins</Table.HeaderCell>
            <Table.HeaderCell>First & Last Name</Table.HeaderCell>
            <Table.HeaderCell>Last Access</Table.HeaderCell>
            <Table.HeaderCell>Joined On</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <OrganizationDirectoryAdminsRow />
        </Table.Body>
      </Table>
    </div>
  );
};

export default OrganizationDirectoryAdminsTable;
