import { useFormikContext } from 'formik';
import { Table } from 'semantic-ui-react';

import {
  ItemIntakeRowSetting,
  ItemIntakeSettableField,
} from 'features/dashboards_v2/widget_click_through/intake/enums';
import { isDisplayOnlyField, isRequiredField } from 'features/dashboards_v2/widget_click_through/intake/form/helpers';
import { rowHeaderTitle } from 'features/dashboards_v2/widget_click_through/intake/form/section/table/display_helpers';
import { FieldClearButton } from 'features/dashboards_v2/widget_click_through/intake/form/section/table/field_clear_button';

import { ItemFieldValueInputCell } from './item_field_value_cell';

export const ItemFieldRow = ({
  itemField,
  rowSetting,
  fieldId,
  hasAutoFocus,
  organizationId,
  workspaceId,
}: {
  itemField: ItemIntakeSettableField;
  rowSetting: ItemIntakeRowSetting;
  fieldId: number;
  hasAutoFocus: boolean;
  organizationId: string;
  workspaceId: string;
}) => {
  const formikKey = fieldId.toString();
  const { getFieldProps, setFieldValue, setFieldError } = useFormikContext();
  const { value } = getFieldProps(formikKey);

  const headerTitle = rowHeaderTitle({ itemField, isRequired: isRequiredField(rowSetting) });

  const isMultiValueFormField = Array.isArray(value);
  const isClearFieldDisabled = isMultiValueFormField ? !value.length : !value;
  const resetValue = isMultiValueFormField ? [] : '';

  const isDisplayOnly = isDisplayOnlyField(rowSetting);

  const handleClear = () => {
    setFieldValue(formikKey, resetValue);
    setFieldError(formikKey, undefined);
  };

  return (
    <Table.Row>
      <Table.Cell collapsing content={headerTitle} />
      <Table.Cell
        collapsing
        colSpan="2"
        content={
          <ItemFieldValueInputCell
            itemField={itemField}
            fieldId={fieldId}
            disabled={isDisplayOnly}
            hasAutoFocus={hasAutoFocus}
            organizationId={organizationId}
            workspaceId={workspaceId}
          />
        }
      />
      <Table.Cell
        className="intake-widget-user-form-section__sub-section-table-clear"
        collapsing
        content={<FieldClearButton disabled={isClearFieldDisabled || isDisplayOnly} handleClear={handleClear} />}
      />
    </Table.Row>
  );
};
