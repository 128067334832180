import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

export const JiraProjectModalTablePicklistCell = ({
  picklist,
  checkbox,
  checkboxText,
  secondaryElement,
}: {
  picklist: ReactNode;
  checkbox?: ReactNode;
  secondaryElement?: ReactNode;
  checkboxText?: string;
}) => (
  <Table.Cell className="field-value picklist">
    <div
      className={classNames(
        'jira-project-modal__content-table-picklist',
        secondaryElement && 'jira-project-modal__content-table-picklist-column'
      )}
    >
      <span className="custom-field-input">{picklist}</span>
      <div className="jira-project-modal__content-table-picklist-checkbox">
        {checkbox}
        {checkboxText}
      </div>
      {secondaryElement}
    </div>
  </Table.Cell>
);
