import { useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import { FavoriteListItem } from 'features/organization/left_nav/favorites/favorite_list_item';
import { getCurrentWorkspaceFavoritesByPriority } from 'redux/entities/selectors/favorite';

import 'features/organization/left_nav/favorites/favorites.scss';

interface FavoritesListProps {
  expanded: boolean;
}

const FavoritesList = ({ expanded }: FavoritesListProps) => {
  const favorites = useSelector(getCurrentWorkspaceFavoritesByPriority);

  if (!expanded) {
    return null;
  }

  return (
    <Menu className="icon-left lp-favorites-list" inverted secondary vertical>
      <div className="lp-favorites-list__body">
        {favorites.map((favorite) => (
          <FavoriteListItem key={favorite.id} favoriteId={favorite.id} />
        ))}
      </div>
    </Menu>
  );
};

export default FavoritesList;
