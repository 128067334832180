import { debounce } from 'lodash';
import { useRef, useState, useEffect } from 'react';

import { PackageStatus } from 'daos/enums';
import { KEYDOWN_DEBOUNCE_MILLIS } from 'lib/constants';

interface UseDebounceInputReturn {
  inputValue: string;
  setDebouncedValue: React.MutableRefObject<(value: React.SetStateAction<string>) => void>;
}

type ClearInputDependency = string | number | PackageStatus;

export const useDebouncedInput = (clearInputDependency?: ClearInputDependency): UseDebounceInputReturn => {
  const [inputValue, setInputValue] = useState<string>('');

  const setDebouncedValue = useRef<(value: React.SetStateAction<string>) => void>(
    debounce((value: React.SetStateAction<string>) => setInputValue(value), KEYDOWN_DEBOUNCE_MILLIS)
  );

  useEffect(() => {
    return () => {
      if (clearInputDependency) {
        setInputValue('');
      }
    };
  }, [clearInputDependency]);

  return { inputValue, setDebouncedValue };
};
