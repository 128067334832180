import { noop } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { OrganizationUser } from 'daos/model_types';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { setCurrentWorkspaceId } from 'features/common/current/slice';
import { LpIcon, globeSolid } from 'features/common/lp_icon';
import { DisconnectWorkspaceGuestModal, useDisconnectWorkspaceGuestModal } from 'features/guest/disconnect_modals';
import { useFetchUserForProfile } from 'features/guest/profile/use_fetch_users_for_profile';
import { InviteErrorModal } from 'features/organization_directory/users/invite/invite_errors';
import { frontend } from 'lib/urls';
import { getWorkspaceUsersByUserId } from 'redux/entities/selectors/user';
import { slate600 } from 'style/variables';

export const OrgUserGuestPassportsTable = ({
  guestOrgUser,
  guestWorkspacePassports,
}: {
  guestOrgUser: OrganizationUser;
  guestWorkspacePassports: Array<{
    id: number;
    name: string;
    disconnected: boolean;
    workspaceUserId: any;
  }>;
}) => {
  const dispatch = useDispatch();

  const { fetchOrgUser } = useFetchUserForProfile({
    organizationUserId: guestOrgUser.id,
  });

  useEffect(() => {
    fetchOrgUser(noop);
  }, [fetchOrgUser]);

  const userId = guestOrgUser.user.id;

  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceUsersByUserId = useSelector(getWorkspaceUsersByUserId);

  const handleWorkspaceChange = (wsId: number) => () => dispatch(setCurrentWorkspaceId(wsId));

  const {
    apiRequestInProgress,
    closeDisconnectConfirmModal,
    confirmDisconnectWorkspace,
    disconnectError,
    reconnectError,
    setReconnectError,
    handleReconnectClick,
    onConfirmDisconnectClick,
    openDisconnectConfirmModal,
  } = useDisconnectWorkspaceGuestModal({
    fetchOrgUser,
    organizationId,
    workspaceUsersForUserId: workspaceUsersByUserId[userId] ?? [],
  });

  const closeErrorModal = () => setReconnectError(undefined);

  if (!guestWorkspacePassports.length) {
    return null;
  }

  return (
    <>
      {!!reconnectError && <InviteErrorModal error={reconnectError} onClose={closeErrorModal} isReconnect={true} />}
      <Table
        compact
        className="organization-directory__users-profile-membership-table organization-directory__users-profile-membership-table--workspace-passports"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="organization-directory__users-profile-membership-table-header">
              Workspaces with Dashboard Passports (click for details)
              {!!disconnectError && (
                <span className="organization-directory__users-profile-membership-table-header__error">
                  Disconnect Failed
                </span>
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {guestWorkspacePassports.map((workspace) => (
            <Table.Row key={workspace.id}>
              <Table.Cell className="organization-directory__users-profile-workspace-memberships-cell">
                <LpIcon icon={globeSolid} color={slate600} />
                &nbsp;
                <LpLink
                  to={frontend.workspaceGuestProfile.url({ organizationId, workspaceId: workspace.id, userId })}
                  onClick={handleWorkspaceChange(workspace.id)}
                >
                  {workspace.disconnected ? (
                    <>
                      <span className="organization-directory__users-profile-workspace-memberships-name--disconnected">
                        {workspace.name}
                      </span>
                      <span className="organization-directory__users-profile-workspace-memberships-name--disconnected-meta">
                        &nbsp; (Disconnected)
                      </span>
                    </>
                  ) : (
                    <>{workspace.name}</>
                  )}
                </LpLink>
                {workspace.disconnected ? (
                  <Button
                    primary
                    disabled={apiRequestInProgress}
                    onClick={handleReconnectClick(workspace.id, workspace.workspaceUserId)}
                    className="organization-directory__users-profile-workspace-memberships-tri-state-buttons"
                  >
                    Reconnect
                  </Button>
                ) : (
                  <Button
                    negative
                    disabled={apiRequestInProgress}
                    onClick={() => openDisconnectConfirmModal(workspace.id, workspace.name)}
                    className="organization-directory__users-profile-workspace-memberships-tri-state-buttons"
                  >
                    Disconnect
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {!!confirmDisconnectWorkspace && (
        <DisconnectWorkspaceGuestModal
          onClose={closeDisconnectConfirmModal}
          onConfirm={onConfirmDisconnectClick}
          username={guestOrgUser.username}
          workspaceName={confirmDisconnectWorkspace.workspaceName}
        />
      )}
    </>
  );
};
