import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, passportSolid, shieldMinusLight } from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { frontend } from 'lib/urls';
import { slate600 } from 'style/variables';

import './guest_users_table.scss';

export interface GuestUser {
  id: number;
  userId: number;
  userName: string;
  email: string;
  lastAccess: string;
  type: 'organizationUsers' | 'workspaceUsers';
}

export const GuestUsersTable = ({
  guestUsers,
  disconnectButtonOnClick,
}: {
  guestUsers: Array<GuestUser>;
  disconnectButtonOnClick?: (id: number) => (_: SyntheticEvent) => void;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { formatLocalDate } = useLocalizedFormats();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Guests</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Last Access</Table.HeaderCell>
          {!!disconnectButtonOnClick && <Table.HeaderCell textAlign="center">Guest Access</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {guestUsers.map((guestUser) => (
          <Table.Row key={guestUser.id}>
            <Table.Cell>
              <div className="guest-username">
                <LpIcon icon={passportSolid} color={slate600} size="2xl" />
                <LpLink
                  to={
                    guestUser.type === 'organizationUsers'
                      ? frontend.organizationHubUser.url({
                          organizationId,
                          organizationUserId: guestUser.id,
                        })
                      : frontend.workspaceGuestProfile.url({ organizationId, workspaceId, userId: guestUser.userId })
                  }
                >
                  {guestUser.userName}
                </LpLink>
              </div>
            </Table.Cell>
            <Table.Cell width={1}>{guestUser.email}</Table.Cell>
            <Table.Cell textAlign="center">{formatLocalDate(guestUser.lastAccess)}</Table.Cell>
            {!!disconnectButtonOnClick && (
              <Table.Cell textAlign="right" collapsing>
                <Button
                  icon={<LpIcon icon={shieldMinusLight} />}
                  content="Disconnect"
                  negative
                  onClick={disconnectButtonOnClick(guestUser.id)}
                />
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
