import { useFormik } from 'formik';
import { SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import LpFormInput from 'features/common/forms/lp_form_input';
import { useYupEmailValidator } from 'lib/helpers/yup/lp_email_validation';
import { frontend } from 'lib/urls';

export const UserForm = ({
  isAccountConfirmed,
  setInvitationSent,
  setNewUserEmail,
}: {
  isAccountConfirmed: boolean;
  setInvitationSent: (value: SetStateAction<boolean>) => void;
  setNewUserEmail: (email: SetStateAction<string>) => void;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);

  const schema = yup.object().shape({
    email: useYupEmailValidator(),
  });

  const { getFieldProps, getFieldMeta, isSubmitting, errors, handleSubmit } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: ({ email }) => {
      setInvitationSent(false);
      setNewUserEmail(email);
    },
  });

  return (
    <Form loading={isSubmitting} onSubmit={handleSubmit}>
      <LpFormInput
        autoFocus={true}
        fieldKey="email"
        className="users-invite__input"
        label={'Email'}
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        e2eTestId={'invite-user-email'}
      />
      <div className="users-invite__footer">
        <LpButtonAsLink to={frontend.organizationHubUsers.url({ organizationId })}>Cancel</LpButtonAsLink>
        <Button
          disabled={!!errors.email || !isAccountConfirmed}
          content="Next"
          primary
          type="submit"
          data-e2e-test-id={'invite-user-email-next'}
        />
      </div>
    </Form>
  );
};
