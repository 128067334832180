import useUsageLimitsForOrg from 'hooks/use_usage_limit_for_org';

import useOrganizationFetchOnOrganizationModified from './use_organization_fetch_on_organization_modified';

const useOrgUsageLimitWithOrgFetchOnModified = () => {
  useOrganizationFetchOnOrganizationModified();
  const orgLimits = useUsageLimitsForOrg();

  return { ...orgLimits };
};

export default useOrgUsageLimitWithOrgFetchOnModified;
