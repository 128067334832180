import { FieldInputProps, FormikHelpers } from 'formik';
import { Form } from 'semantic-ui-react';

export const LpFormRadio = ({
  fieldKey,
  disabled = false,
  label,
  value,
  getFieldProps,
  setFieldValue,
  className = '',
}: {
  fieldKey: string;
  disabled?: boolean;
  label?: string;
  value: string | number;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  className?: string;
}) => {
  const fieldProps = getFieldProps(fieldKey);

  const handleChange = () => {
    setFieldValue(fieldKey, value);
  };

  return (
    <Form.Radio
      className={className}
      label={label}
      disabled={disabled}
      onClick={handleChange}
      value={value}
      checked={fieldProps.value === value}
    />
  );
};
