import { useFormikContext } from 'formik';
import { SyntheticEvent, useEffect } from 'react';

import { renderDeleteIcon } from 'features/jira_project/modal/helpers';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { JiraProjectModalPicklist } from 'features/jira_project/modal/sections/jira_project_modal_picklist';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { getAvailableFieldValueOptions } from 'features/jira_project/modal/utils';
import { SELECT_ALL } from 'lib/constants';

export const JiraProjectFiltersMultiPicklist = ({
  options,
  formValueType,
}: {
  options: ReadonlyArray<{ id: string; name: string }>;
  formValueType: JiraProjectModalFormFields.IssueStatusIds | JiraProjectModalFormFields.IssueTypeIds;
}) => {
  const { values, setFieldValue, validateField } = useFormikContext<JiraProjectModalFormValues>();
  const { canModify, isLoadingContent, isSubmitting } = useJiraProjectModalContext();
  const selectedIds = values[formValueType];
  const selectedJiraProjectId = values[JiraProjectModalFormFields.JiraProjectId];

  const allOptions = options.map((option) => option.id);
  const availablePicklistOptions = getAvailableFieldValueOptions(new Set(selectedIds), options);
  const unSelectedOptions = availablePicklistOptions(undefined);
  const shouldDisplayNewDropdown = selectedIds.length === 0 || unSelectedOptions.length > 0;

  const handleChangeValue =
    (selectedId: string) =>
    (_: SyntheticEvent, { value: picklistChoiceId }: { value: string | undefined }) => {
      if (picklistChoiceId === SELECT_ALL) {
        return setFieldValue(formValueType, allOptions);
      }
      if (!picklistChoiceId) {
        return setFieldValue(
          formValueType,
          selectedIds.filter((id) => id !== selectedId)
        );
      }
      setFieldValue(formValueType, [...selectedIds.filter((id) => id !== selectedId), picklistChoiceId]);
    };

  const handleAddValue = (_: SyntheticEvent<HTMLElement, Event>, data: { value?: string }) => {
    if (data.value) {
      if (data.value === SELECT_ALL) {
        return setFieldValue(formValueType, allOptions);
      }
      const newValues = [...selectedIds, data.value];
      setFieldValue(formValueType, newValues);
    }
  };

  const handleDeleteValue = (selectedId: string) => {
    setFieldValue(
      formValueType,
      selectedIds.filter((itemId) => itemId !== selectedId)
    );
  };

  useEffect(() => {
    if (selectedIds.length) {
      validateField(formValueType);
    }
  }, [formValueType, selectedIds.length, validateField]);

  const shouldDisable = !canModify || isLoadingContent || isSubmitting;

  return (
    <>
      {selectedIds.map((selectedId) => (
        <span className="custom-field-input" key={selectedId}>
          <JiraProjectModalPicklist
            disabled={shouldDisable}
            picklist={availablePicklistOptions(selectedId)}
            onChange={handleChangeValue(selectedId)}
            renderIcon={false}
            name={formValueType}
            fieldChoiceId={selectedId}
            canSelectAll
          />
          {renderDeleteIcon({
            onClick: () => handleDeleteValue(selectedId),
            className: 'remove-custom-field',
            enabled: canModify,
          })}
        </span>
      ))}

      {shouldDisplayNewDropdown && (
        <span className="custom-field-input">
          <JiraProjectModalPicklist
            picklist={unSelectedOptions}
            onChange={handleAddValue}
            disabled={shouldDisable && !selectedJiraProjectId}
            renderIcon={false}
            name={formValueType}
            canSelectAll
          />
        </span>
      )}
    </>
  );
};
