import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { FavoriteViewType } from 'daos/enums';
import { Favorite } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { FavoriteProps } from 'features/favorite/types';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { readonlyArray, groupRecordBy } from 'lib/readonly_record';
import { findFavoriteFromCriteriaInFavoriteAndProps, memoizeSelectorProps } from 'redux/entities/selectors/helpers';
import { EntityLookupById } from 'redux/entities/types';
import { RootState } from 'redux/root_reducer';

const libraryViews = [
  FavoriteViewType.PackageLibrary,
  FavoriteViewType.ProjectLibrary,
  FavoriteViewType.PortfolioPendingLibrary,
  FavoriteViewType.PortfolioArchivedLibrary,
  FavoriteViewType.PortfolioScheduledLibrary,
];

const emptyFavoriteArray = readonlyArray<Favorite>([]);

const getFavoritesById = (state: RootState): EntityLookupById<Favorite> => state.entities.favorites;

export const getFavoriteForId = (state: RootState, id: number) => getFavoritesById(state)[id];

const getFavoritesByWorkspaceId = createSelector(getFavoritesById, (favoritesById) => {
  return groupRecordBy(favoritesById, (favorite) => favorite.workspace.id);
});

export const getFavoritesByLibraryResourceId = createSelector(getFavoritesById, (favoritesById) => {
  return Object.values(favoritesById).reduce<Record<number, Favorite>>((acc, favorite) => {
    const libraryResourceId = favorite.libraryResource?.id;
    if (libraryResourceId) {
      acc[libraryResourceId] = favorite;
    }
    return acc;
  }, {});
});

export const getLibraryViewFavoritesByLibraryResourceItemId = createSelector(getFavoritesById, (favoritesById) => {
  return Object.values(favoritesById).reduce<Record<number, Favorite>>((acc, favorite) => {
    const libraryResourceItemId = favorite.item?.id;
    const view = favorite.view;
    const isLibraryView = libraryViews.includes(view);

    if (isLibraryView && libraryResourceItemId) {
      acc[libraryResourceItemId] = favorite;
    }

    return acc;
  }, {});
});

export const getLibraryFavoritesByPackageStatus = createSelector(getFavoritesById, (favoritesById) => {
  return Object.values(favoritesById).reduce<Record<string, Favorite>>((acc, favorite) => {
    const packageStatus = favorite.packageStatus;
    const view = favorite.view;
    const isLibraryView = [
      FavoriteViewType.PortfolioScheduledLibrary,
      FavoriteViewType.PortfolioPendingLibrary,
      FavoriteViewType.PortfolioArchivedLibrary,
    ].includes(view);

    if (packageStatus && isLibraryView) {
      acc[packageStatus] = favorite;
    }

    return acc;
  }, {});
});

const getCurrentWorkspaceFavorites = createSelector(
  getFavoritesByWorkspaceId,
  getCurrentWorkspaceId,
  (favoritesByWorkspace, currentWorkspaceId) => {
    return favoritesByWorkspace[currentWorkspaceId] ?? emptyFavoriteArray;
  }
);

export const getCurrentWorkspaceFavoritesByPriority = createSelector(getCurrentWorkspaceFavorites, (favorites) => {
  return readonlyArray([...favorites].sort(compareByPriority));
});

const memoizeFavoriteProps = memoizeSelectorProps<FavoriteProps>();

const favoritePropsCacheKey = (favoriteProps: FavoriteProps): string => {
  const { view, groupId, itemId, userId, libraryResourceId } = favoriteProps;
  return `${view}:${groupId ?? ''}:${itemId ?? ''}:${userId ?? ''}:${libraryResourceId ?? ''}`;
};

export const getFavoriteForCriteriaInCurrentWorkspace = createCachedSelector(
  getCurrentWorkspaceFavorites,
  (_state: RootState, favoriteProps: FavoriteProps) => memoizeFavoriteProps(favoriteProps),
  (favorites, favoriteProps): Favorite | undefined => {
    return findFavoriteFromCriteriaInFavoriteAndProps(favorites, favoriteProps);
  }
)((_state, favoriteProps) => favoritePropsCacheKey(favoriteProps));
