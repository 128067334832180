import { FavoriteViewType, ItemType, UserType } from 'daos/enums';
import { Favorite, LibraryResource } from 'daos/model_types';
import { UnreachableCaseError } from 'lib/unreachable_case_error';

const favoriteViewDisplayName = ({
  favorite,
  itemType,
  userType,
  libraryResource,
}: {
  favorite: Favorite | undefined;
  itemType: ItemType | undefined;
  userType: UserType | undefined;
  libraryResource: LibraryResource | undefined;
}): string | undefined => {
  if (!favorite) {
    return '';
  }

  const isPeopleView = !favorite.group && !favorite.item && !favorite.user && !favorite.libraryResource;
  const TimesheetReviewAndExportText = isPeopleView ? 'People' : 'Time';

  switch (favorite.view) {
    case FavoriteViewType.PortfolioAll:
    case FavoriteViewType.PortfolioScheduled:
    case FavoriteViewType.PortfolioPending:
    case FavoriteViewType.PortfolioArchived:
    case FavoriteViewType.PortfolioCapacity:
      return 'Portfolio';
    case FavoriteViewType.PortfolioCapacityAllocation:
    case FavoriteViewType.ProjectAllocation:
    case FavoriteViewType.PackageAllocation:
      return 'Allocation';
    case FavoriteViewType.Package:
      return 'Package';
    case FavoriteViewType.Project:
      if (itemType === ItemType.PROJECTS) {
        return 'Project';
      } else if (itemType === ItemType.FOLDERS) {
        return 'Sub-Folder';
      } else {
        return undefined;
      }

    case FavoriteViewType.PortfolioScheduledSearch:
    case FavoriteViewType.PortfolioPendingSearch:
    case FavoriteViewType.PortfolioArchivedSearch:
    case FavoriteViewType.PackageSearch:
    case FavoriteViewType.ProjectSearch:
    case FavoriteViewType.MemberSearch:
      return 'Search';
    case FavoriteViewType.PackageWorkload:
    case FavoriteViewType.MemberTasks:
    case FavoriteViewType.PortfolioScheduledWorkload:
    case FavoriteViewType.GroupTasks:
    case FavoriteViewType.ProjectWorkload:
      return 'Workload';
    case FavoriteViewType.PackageBoard:
    case FavoriteViewType.ProjectBoard:
    case FavoriteViewType.MemberBoard:
    case FavoriteViewType.GroupBoard:
      return 'Board';
    case FavoriteViewType.PackageLibrary:
    case FavoriteViewType.ProjectLibrary:
    case FavoriteViewType.PortfolioArchivedLibrary:
    case FavoriteViewType.PortfolioPendingLibrary:
    case FavoriteViewType.PortfolioScheduledLibrary:
      return 'Library';
    case FavoriteViewType.PortfolioScheduledGrid:
    case FavoriteViewType.PortfolioPendingGrid:
    case FavoriteViewType.PortfolioArchivedGrid:
    case FavoriteViewType.PackageGrid:
    case FavoriteViewType.ProjectGrid:
      return 'Grid';
    case FavoriteViewType.PortfolioScheduledChanges:
    case FavoriteViewType.PortfolioPendingChanges:
    case FavoriteViewType.PortfolioArchivedChanges:
    case FavoriteViewType.PackageChanges:
    case FavoriteViewType.ProjectChanges:
    case FavoriteViewType.MemberChanges:
      return 'Changes';

    case FavoriteViewType.Profiles:
    case FavoriteViewType.Groups:
    case FavoriteViewType.MembersAvailability:
      return 'People';

    case FavoriteViewType.TimesheetReview:
    case FavoriteViewType.TimesheetExport:
      return TimesheetReviewAndExportText;

    case FavoriteViewType.MemberAvailability:
      return 'Availability';
    case FavoriteViewType.MemberAccess:
    case FavoriteViewType.GroupAccess:
      return 'Access';
    case FavoriteViewType.MemberProfile:
      return userType === UserType.Resource ? 'Settings' : 'Profile';
    case FavoriteViewType.Group:
      return 'Group';
    case FavoriteViewType.MemberTimesheet:
      return 'Timesheet';

    case FavoriteViewType.MyWorkload:
    case FavoriteViewType.MyBoard:
    case FavoriteViewType.MyTimesheet:
    case FavoriteViewType.MyAvailability:
    case FavoriteViewType.MySearch:
    case FavoriteViewType.MyChanges:
    case FavoriteViewType.MyAccess:
    case FavoriteViewType.MyProfile:
      return 'My Work';

    case FavoriteViewType.LibraryResource: {
      if (libraryResource?.dashboardId) {
        return 'Dashboard';
      }
      if (libraryResource?.widgetId) {
        return 'Report';
      }
      return 'Library Item';
    }

    default:
      throw new UnreachableCaseError(favorite.view);
  }
};

export default favoriteViewDisplayName;
