import classNames from 'classnames';

import 'containers/shared/notice_icons/icons.scss';
import { LpIcon, circleSolid } from 'features/common/lp_icon';
import { lpBrandWhite } from 'style/variables';

interface NewItemLabelProps {
  className?: string;
}

const NewItemLabel = ({ className }: NewItemLabelProps) => {
  return (
    <LpIcon
      className={classNames(className, 'icon notice new-item')}
      size="sm"
      icon={circleSolid}
      color={lpBrandWhite}
    />
  );
};

export default NewItemLabel;
