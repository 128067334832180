import { FormatterProps, HeaderRendererProps, headerRenderer, useRowSelection } from 'react-data-grid';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import { getBulkSelectedItemIds } from 'features/common/bulk_selection/selectors';
import { NameCustomHeader } from 'features/common/data_grid/formatters/name_custom_header';
import { EntityRow } from 'features/common/data_grid/types';

export function SelectColumnFormatter<R, S>(props: FormatterProps<EntityRow<R, S>, S> & { disabled?: boolean }) {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  const handleChange = (e: React.FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    const isChecked = !!checked;
    const isShiftClick = (e.nativeEvent as MouseEvent).shiftKey;
    onRowSelectionChange({ row: props.row, checked: isChecked, isShiftClick });
  };

  return <Checkbox checked={isRowSelected} disabled={props.disabled} onChange={handleChange} />;
}

export const SortableHeaderCellWithSelectedCount = <R, S>(props: HeaderRendererProps<EntityRow<R, S>, S>) => {
  const bulkSelectedIds = useSelector(getBulkSelectedItemIds);
  const name = NameCustomHeader({
    columnName: props.column.name,
    selectedItemsCount: bulkSelectedIds.length,
  });

  const column = { ...props.column, name };
  const customProps = { ...props, column };
  return headerRenderer(customProps);
};
