import { createPopper, Instance, PositioningStrategy, Placement } from '@popperjs/core';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import {
  cloneElement,
  DetailedHTMLProps,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Portal } from 'semantic-ui-react';

import { generateGetBoundingClientRect } from 'features/common/inputs/dropdowns/portal_action_dropdown/use_popper';

import './lp_popup.scss';

export type LpPopupTrigger = ReactElement<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>>;

const LpPopUp = ({
  trigger,
  content,
  className,
  disablePopup = false,
  trackMouseMovement,
  positionStrategy = 'fixed',
  placement,
  useDefaultStyles = true,
}: {
  trigger: LpPopupTrigger;
  content: ReactNode;
  className?: string;
  disablePopup?: boolean;
  trackMouseMovement?: boolean;
  positionStrategy?: PositioningStrategy;
  placement?: Placement;
  useDefaultStyles?: boolean;
}) => {
  const popperInstancePlacement = trackMouseMovement ? 'bottom-start' : 'bottom';

  const setPopperInstance = useCallback(
    (triggerRefElement: HTMLElement, node: HTMLElement) => {
      const popperInstance = createPopper(triggerRefElement, node, {
        strategy: positionStrategy,
        placement: placement ?? popperInstancePlacement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
          {
            name: 'arrow',
            options: {
              padding: 5,
            },
          },
        ],
      });

      setPopper(popperInstance);
    },
    [placement, popperInstancePlacement, positionStrategy]
  );

  const toolTipRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node) {
        return null;
      }

      if (!triggerRef.current) {
        return;
      }

      setPopperInstance(triggerRef.current, node);
    },
    [setPopperInstance]
  );

  const triggerRef = useRef<HTMLDivElement>(null);

  const [popper, setPopper] = useState<Instance | undefined>(undefined);

  const [showToolTip, setShowToolTip] = useState(false);

  useEffect(() => {
    if (!showToolTip || !trackMouseMovement) {
      return;
    }

    const move = (event: MouseEvent) => {
      if (!popper) {
        return;
      }

      popper.state.elements.reference.getBoundingClientRect = generateGetBoundingClientRect(
        event.clientX,
        event.clientY
      );
      popper.update();
    };

    document.addEventListener('mousemove', move);

    return () => {
      document.removeEventListener('mousemove', move);
    };
  }, [popper, showToolTip, trackMouseMovement]);

  const triggerElement = useMemo(() => {
    if (isValidElement(trigger)) {
      const show = () => {
        setShowToolTip(true);

        if (popper) {
          popper.update();
        }
      };

      const hide = () => {
        setShowToolTip(false);
      };

      return cloneElement(
        trigger,
        {
          onMouseEnter: show,
          onMouseLeave: hide,
          ref: triggerRef,
        },
        trigger.props.children
      );
    }

    return trigger;
  }, [popper, trigger]);

  const classNameFromContentToArrow = popper?.state.elements.popper.firstElementChild?.className;

  const toolTipElement = disablePopup
    ? null
    : showToolTip && (
        <Portal open={true}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
            className={classNames('lp-popup', className, {
              'lp-popup__default-styles': useDefaultStyles,
            })}
            ref={toolTipRef}
          >
            {content}
            <div className={classNames('lp-popup__arrow', classNameFromContentToArrow)} data-popper-arrow />
          </motion.div>
        </Portal>
      );

  return (
    <>
      {triggerElement}
      {toolTipElement}
    </>
  );
};

export default LpPopUp;
