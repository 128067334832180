import { SECONDS_IN_HOUR } from 'lib/constants';

import { timeUnitLookup, timeInputInHoursUSA } from './time_input_in_hours_usa';

const trimLastCharacter = (value: string) => value.slice(0, -1);

export const convertEstimateInputsToNumbers = ({
  low,
  high,
  logged,
}: {
  low: string;
  high: string;
  logged?: string;
}) => {
  let lowInput = low;
  let highInput = high;
  let loggedInput = logged;

  const loggedTimeUnit = logged ? timeUnitLookup[logged.charAt(logged.length - 1)] : undefined;

  const lowTimeUnit = timeUnitLookup[low.charAt(low.length - 1)];

  const highTimeUnit = timeUnitLookup[high.charAt(high.length - 1)];

  if (logged !== undefined && loggedTimeUnit) {
    loggedInput = timeInputInHoursUSA({
      timeUnit: loggedTimeUnit,
      valueWithoutUnit: trimLastCharacter(logged),
    });
  }

  if (lowTimeUnit) {
    lowInput = timeInputInHoursUSA({ timeUnit: lowTimeUnit, valueWithoutUnit: trimLastCharacter(low) });
  }

  if (highTimeUnit) {
    highInput = timeInputInHoursUSA({ timeUnit: highTimeUnit, valueWithoutUnit: trimLastCharacter(high) });
  }

  return {
    lowNumber: Number(lowInput),
    highNumber: Number(highInput),
    loggedNumber: loggedInput ? Number(loggedInput) : undefined,
  };
};

export const getHighAndLowEfforts = (highHours: number, lowHours: number) => {
  const highAndLowAreBothZero = highHours === 0 && lowHours === 0;
  if (highAndLowAreBothZero) {
    // Do not default to 0, this could create an estimate dao that should not exist.
    return { highEffort: null, lowEffort: null };
  }
  const highEffort = highHours ? highHours * SECONDS_IN_HOUR : 0;
  const lowEffort = lowHours ? lowHours * SECONDS_IN_HOUR : 0;
  return { highEffort, lowEffort };
};
