import { useSignalEffect } from '@preact/signals-react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'semantic-ui-react';

import { LibraryResourceOrderBy } from 'daos/enums';
import { LibraryResourceDao } from 'daos/library_resource';
import { LibraryResource } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useSearchPageContext } from 'features/common/search/context';
import { UseItemSearchProps } from 'features/common/search/hooks/use_item_search';
import { ResultLibraryResource } from 'features/common/search/result_dashboard';
import { awaitRequestFinish } from 'lib/api';
import { searchLibraryResourceFilter } from 'lib/quick_filter_and_search_helpers/search';

interface SearchDashboardsAndReportsTabProps {
  emptyState: ReactNode;
  searchProps: UseItemSearchProps;
}
export function SearchDashboardsAndReportsTab({ emptyState, searchProps }: SearchDashboardsAndReportsTabProps) {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const [searchResults, setSearchResults] = useState<ReadonlyArray<LibraryResource> | undefined>(undefined);
  const { searchEvent } = useSearchPageContext();

  const includes = {
    includeItem: true,
    includeItemAncestors: true,
  };

  const dashboardSearch = (searchValue: string) => {
    const { uuid } = dispatch(
      LibraryResourceDao.fetchAll(
        {
          organizationId,
          workspaceId,
        },
        {
          filter: searchLibraryResourceFilter({ ...searchProps, searchValue }),
          query: { limit: 500, order: LibraryResourceOrderBy.Name },
          include: includes,
        }
      )
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<LibraryResource>>(uuid, {
        onSuccess: ({ data }) => {
          setSearchResults(data);
        },
      })
    );
  };

  useSignalEffect(() => {
    const searchEventValue = searchEvent.value;
    if (!searchEventValue) {
      setSearchResults(undefined);
      return;
    }

    dashboardSearch(searchEventValue.searchTerm);
  });

  return (
    <AnimatePresence>
      {searchResults?.length ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="lp-search__results"
        >
          <List divided className="lp-search__results-files">
            {searchResults.map((libraryResource, index) => (
              <ResultLibraryResource
                key={libraryResource.id}
                resultNumber={index + 1}
                libraryResource={libraryResource}
              />
            ))}
          </List>
        </motion.div>
      ) : (
        emptyState
      )}
    </AnimatePresence>
  );
}
