import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ItemType, ListWidgetType } from 'daos/enums';
import { StartAndEndDate } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import {
  assignmentQuickFilterQueryString,
  ItemQuickFilterProps,
  packageInputQuickFilter,
  projectInputQuickFilter,
  taskInputQuickFilter,
  userInputQuickFilter,
} from 'lib/quick_filter_and_search_helpers/quick_filter';

export const getListWidgetFilterQueryString = ({
  columns,
  includeContainsAncestorName,
  inputValue,
  listType,
}: {
  columns: ItemQuickFilterProps['columns'];
  includeContainsAncestorName: boolean;
  inputValue: string;
  listType: ListWidgetType | null;
}) => {
  const commonQuickFilterProps = { columns: columns ?? [], inputValue };
  switch (listType) {
    case ListWidgetType.Assignment:
      return assignmentQuickFilterQueryString({ ...commonQuickFilterProps, options: [] });
    case ListWidgetType.Tasks:
      return taskInputQuickFilter({ ...commonQuickFilterProps, includeContainsAncestorName });
    case ListWidgetType.Folders:
    case ListWidgetType.Projects:
      return projectInputQuickFilter({ ...commonQuickFilterProps, includeContainsAncestorName });
    case ListWidgetType.Packages:
      return packageInputQuickFilter(commonQuickFilterProps);
    case ListWidgetType.Users:
      return userInputQuickFilter(commonQuickFilterProps);
    case ListWidgetType.Picklists:
      throw new Error(`List widget type "${listType}" is not supported for item filter`);
  }
};

export const getItemTypeForListWidgetType = (listType: ListWidgetType | null) => {
  switch (listType) {
    case ListWidgetType.Folders:
      return ItemType.FOLDERS;
    case ListWidgetType.Packages:
      return ItemType.PACKAGES;
    case ListWidgetType.Projects:
      return ItemType.PROJECTS;
    case ListWidgetType.Tasks:
      return ItemType.TASKS;
    case ListWidgetType.Assignment:
      return ItemType.ASSIGNMENTS;
    case ListWidgetType.Picklists:
    case ListWidgetType.Users:
    default:
      throw new Error(`List widget type "${listType}" is not supported for item filter`);
  }
};

export const DATE_RANGE_WIDGET_COLUMN = {
  column: StandardColumns.DateRange,
  customFieldId: null,
  customFieldName: null,
  direction: null,
  taskStatusId: null,
};

export function getWidgetGridColumns(
  columns: ReadonlyArray<WidgetConfigColumn>,
  appliedDateRanges: ReadonlyArray<StartAndEndDate>
) {
  const shouldAutoAppendDateRangeColumn =
    (appliedDateRanges.length ?? 0) > 0 && !columns.find((c) => c.column === StandardColumns.DateRange);

  if (shouldAutoAppendDateRangeColumn) {
    columns = [...columns, DATE_RANGE_WIDGET_COLUMN];
  }
  return columns;
}

export const getSummaryRowHelpText = ({
  hasWidgetShowTotals,
  numberRowsDisplayed,
  totalRowsAmount,
}: {
  hasWidgetShowTotals: boolean;
  numberRowsDisplayed: number;
  totalRowsAmount: number;
}) => {
  return hasWidgetShowTotals && numberRowsDisplayed
    ? `Showing ${numberRowsDisplayed} of ${totalRowsAmount} rows, totals include all rows`
    : '';
};
