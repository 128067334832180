import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Permission } from 'daos/enums';
import { Model, ResourceId } from 'daos/model_types';
import { permissionCompare } from 'daos/permission';
import { getEntitiesForType } from 'redux/entities/selectors/entity';
import { EntityState } from 'redux/entities/types';

export const hasAccess = (permission: Permission, entity: { permission: Permission } | undefined) => {
  if (!entity) {
    return false;
  }

  return permissionCompare(permission, entity.permission) >= 0;
};

export const useHasAccess = (permission: Permission, resource: ResourceId<Model<keyof EntityState>> | undefined) => {
  const resourceArray = resource ? [resource] : [];
  const entityType = resource?.type;
  return useHasAccessToMultipleResources(permission, resourceArray, entityType);
};

export const useHasAccessToMultipleResources = (
  permission: Permission,
  resources: ReadonlyArray<ResourceId<Model<string>> | undefined>,
  entityType: keyof EntityState | undefined
) => {
  const resourceEntities = useSelector((state) => (entityType ? getEntitiesForType(state, entityType) : undefined));
  return useMemo(() => {
    if (resources.length === 0 || !resourceEntities || typeof resourceEntities === 'boolean') {
      return false;
    }
    for (const resource of resources) {
      if (!resource) {
        return false;
      }
      const entity = resourceEntities[resource.id];
      if (!entity || !hasAccess(permission, entity)) {
        return false;
      }
    }
    return true;
  }, [permission, resourceEntities, resources]);
};
