import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import { TermsOfServiceLabel } from 'containers/shared/terms_of_service_label';
import { MarketingConsentStatus } from 'daos/enums';
import { InvitationDao } from 'daos/invitation';
import { Invitation } from 'daos/model_types';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormCheckbox from 'features/common/forms/lp_form_checkbox';
import LpFormInput from 'features/common/forms/lp_form_input';
import LpFormLabel, { LpFormLabelStyleModifier } from 'features/common/forms/lp_form_label';
import { awaitRequestFinish } from 'lib/api';
import { MAX_EMAIL_LENGTH, MIN_PASSWORD_LENGTH } from 'lib/constants';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { currentBrowserLocale, currentBrowserTimezone } from 'lib/localization';

import 'features/authentication/unauthenticated/token/invitation/form/index.scss';

interface InvitationFormProps {
  inviteeEmail: string;
  invitationCreatorEmail?: string;
  invitationCreatorFirstAndLastName: string;
  hasExistingAccount: boolean;
  onSuccess: (data: Invitation) => void;
  invitationId: number;
  token: string;
  isSsoInvite: boolean;
}

const MIN_NAME_LENGTH = 2;

const baseSchemaProperties = {
  email: yup.string().trim().max(MAX_EMAIL_LENGTH).email().required(),
  tosCheckbox: yup
    .boolean()
    .required()
    .default(false)
    .test('tos checkbox is false', lpErrorText.termsOfService, (tosCheckbox) => tosCheckbox === true),
  hasExistingAccount: yup.boolean().required(),
  firstName: yup.string().when('hasExistingAccount', {
    is: false,
    then: (schema) => schema.required(lpErrorText.firstName).min(MIN_NAME_LENGTH),
  }),
  lastName: yup.string().when('hasExistingAccount', {
    is: false,
    then: (schema) => schema.required(lpErrorText.lastName).min(MIN_NAME_LENGTH),
  }),
};

const passwordSchemaProperties = {
  password: yup.string().when('hasExistingAccount', {
    is: false,
    then: (schema) => schema.default('').required(lpErrorText.passwordLength).min(MIN_PASSWORD_LENGTH),
  }),
  passwordConfirmation: yup.string().when('hasExistingAccount', {
    is: false,
    then: (schema) =>
      schema
        .default('')
        .required(lpErrorText.passwordConfirmation)
        .test('passwords-do-not-match', lpErrorText.passwordMatch, function (passwordConfirmation) {
          const { password } = this.parent;
          return password === passwordConfirmation;
        }),
  }),
};

const lpAuthSchema = yup.object().shape({ ...baseSchemaProperties, ...passwordSchemaProperties });
const ssoAuthSchema = yup.object().shape({ ...baseSchemaProperties });

const InvitationForm = ({
  inviteeEmail,
  hasExistingAccount = false,
  onSuccess,
  invitationCreatorEmail,
  invitationCreatorFirstAndLastName,
  invitationId,
  token,
  isSsoInvite,
}: InvitationFormProps) => {
  const dispatch = useDispatch();
  const dismissApiError = () => setFormApiError(undefined);

  useEffect(() => {
    try {
      localStorage.clear();
      // eslint-disable-next-line no-empty
    } catch (_) {
      // empty catch to handle users who block localStorage use on their browsers
    }
  });

  const {
    handleSubmit,
    setStatus: setFormApiError,
    status: formApiError,
    getFieldProps,
    getFieldMeta,

    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email: inviteeEmail,
      hasExistingAccount,
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      tosCheckbox: false,
    },
    validationSchema: isSsoInvite ? ssoAuthSchema : lpAuthSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ tosCheckbox, password, firstName, lastName }) => {
      const lpAuthPassword = isSsoInvite ? undefined : password;

      const { uuid } = dispatch(
        InvitationDao.accept(
          { token },
          {
            email: inviteeEmail,
            firstName,
            invitationId,
            lastName,
            password: lpAuthPassword,
            locale: currentBrowserLocale(),
            timezone: currentBrowserTimezone(),
            marketingConsent: MarketingConsentStatus['Opt-In'],
            tosAccepted: tosCheckbox,
          }
        )
      );

      dispatch(
        awaitRequestFinish<Invitation>(uuid, {
          onError: ({ errors }) => {
            setSubmitting(false);

            const error = errors[0];

            if (error) {
              if (error.code === 'limitExceeded') {
                const productErrorMessage = `There are insufficient licenses at this time. Please contact ${invitationCreatorFirstAndLastName}${
                  !!invitationCreatorEmail && ` (${invitationCreatorEmail})`
                } for assistance.`;

                setFormApiError({
                  title: undefined,
                  detail: productErrorMessage,
                });
                return;
              }

              setFormApiError(error);
            }
          },
          onSuccess: ({ data }) => {
            onSuccess(data);
          },
        })
      );
    },
  });

  return (
    <Form className="lp-registration__form" autoComplete="on" onSubmit={handleSubmit} loading={isSubmitting}>
      {formApiError && (
        <LpErrorMessage className="lp-invitation__api-error" error={formApiError} onDismiss={dismissApiError} />
      )}

      <LpFormLabel text={'Business Email'} style={LpFormLabelStyleModifier.Bold} />
      <LpFormInput
        disableLastPass={false}
        fieldKey="email"
        className="lp-registration__input-field"
        disabled={true}
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        e2eTestId="invitation-email"
      />

      {!hasExistingAccount && (
        <>
          <LpFormInput
            fieldKey="firstName"
            label="First Name"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            e2eTestId="invitation-firstName"
          />

          <LpFormInput
            fieldKey="lastName"
            label="Last Name"
            getFieldProps={getFieldProps}
            getFieldMeta={getFieldMeta}
            e2eTestId="invitation-lastName"
          />

          {!isSsoInvite && (
            <>
              <LpFormInput
                disableLastPass={false}
                fieldKey="password"
                label="Password "
                type="password"
                getFieldProps={getFieldProps}
                getFieldMeta={getFieldMeta}
                e2eTestId="invitation-password"
              />

              <LpFormInput
                disableLastPass={false}
                fieldKey="passwordConfirmation"
                label="Confirm Password "
                type="password"
                getFieldProps={getFieldProps}
                getFieldMeta={getFieldMeta}
                e2eTestId="invitation-confirmPassword"
              />
            </>
          )}
        </>
      )}

      <LpFormCheckbox
        fieldKey="tosCheckbox"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
        label={<TermsOfServiceLabel />}
        e2eTestId="invitation-tosCheckbox"
      />

      <Button
        className="lp-invitation__submit-button"
        content="Continue"
        type="submit"
        disabled={isSubmitting}
        data-e2e-test-id="invitation-continue-button"
      />
    </Form>
  );
};

export default InvitationForm;
