import classnames from 'classnames';

import './clickable_text.scss';

const ClickableText = ({
  className = '',
  onClick,
  text,
  isBlue = true,
}: {
  className?: string;
  onClick?: () => void;
  isBlue?: boolean;
  text: string | JSX.Element;
}) => {
  return (
    <p className={classnames('clickable-text', className, { 'clickable-text--blue': isBlue })} onClick={onClick}>
      {text}
    </p>
  );
};

export default ClickableText;
