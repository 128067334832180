import { ReactNode } from 'react';
import { Message } from 'semantic-ui-react';

import { ApiError } from 'lib/api/types';

interface FormErrorProps {
  error: ApiError;
  className?: string;
  customError?: ReactNode | string;
  onDismiss?: () => void;
}

const LpErrorMessage = ({ error, className, customError, onDismiss }: FormErrorProps) => {
  const getErrorMessage = () => {
    if (customError) {
      return customError;
    }

    if (error.detail && error.title) {
      return `${error.title}: ${error.detail}`;
    }
    if (!error.title && error.detail) {
      return error.detail;
    }
    if (error.title && !error.detail) {
      return error.title;
    }
    if (!error.title && !error.detail) {
      return 'Something went wrong. Please contact support';
    }
  };

  return (
    <Message error className={className} onDismiss={onDismiss} visible>
      <div>{getErrorMessage()}</div>
    </Message>
  );
};

export default LpErrorMessage;
