import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { getOrganizationHubFilteredAdminOrgUsers } from 'features/organization_directory/selectors';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { frontend } from 'lib/urls';

import OrganizationDirectoryAdminsNameCell from './name_cell';

const OrganizationDirectoryAdminsRow = () => {
  const { formatLocalDate } = useLocalizedFormats();
  const organizationAdmins = useSelector(getOrganizationHubFilteredAdminOrgUsers);
  const organizationId = useSelector(getCurrentOrganizationId);

  return (
    <>
      {organizationAdmins.map((orgAdmin) => (
        <Table.Row key={orgAdmin.id}>
          <Table.Cell>
            <span>
              <LpLink to={frontend.organizationHubUser.url({ organizationId, organizationUserId: orgAdmin.id })}>
                <UserAvatar orgUserId={orgAdmin.id} size={AvatarSize.Medium} />
                <span className={classNames({ 'evicted-user': !!orgAdmin.disconnectedAt })}>{orgAdmin.username}</span>
              </LpLink>
            </span>
          </Table.Cell>
          <OrganizationDirectoryAdminsNameCell userId={orgAdmin.user.id} isEvicted={!!orgAdmin.disconnectedAt} />
          <Table.Cell>{formatLocalDate(orgAdmin.lastAccessedAt ?? orgAdmin.createdAt)}</Table.Cell>
          <Table.Cell>{formatLocalDate(orgAdmin.createdAt)}</Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default OrganizationDirectoryAdminsRow;
