import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { Label } from 'semantic-ui-react';

import { LpIcon, exclamationTriangleSolid as riskIcon } from 'features/common/lp_icon';
import { lpBrandWhite } from 'style/variables';

import 'containers/shared/notice_icons/icons.scss';

interface RiskLabelProps {
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
}

export const RiskLabel = ({ className, onClick }: RiskLabelProps) => (
  <Label
    className={classNames(className, 'icon notice alert', onClick && 'clickable')}
    content={<LpIcon hoverText={'Has Risk'} size="sm" icon={riskIcon} color={lpBrandWhite} />}
    color={'red'}
    onClick={onClick}
  />
);
