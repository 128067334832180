import { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';

import { ItemColorName, LpColorHex } from 'daos/item_enums';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { LpIcon, squareSolid } from 'features/common/lp_icon';

interface ColorPickerDropdownProps {
  disabled?: DropdownProps['disabled'];
  hex?: string;
  onChange?: ({ color }: { color: LpColorHex }) => void;
  onClose?: () => void;
  isOpen?: boolean;
  fluid?: boolean;
  useOnGrid?: boolean;
}

export const colors = [
  { name: ItemColorName.RELIGHT_BLUE, hex: LpColorHex.RELIGHT_BLUE },
  { name: ItemColorName.TRICKY_TEAL, hex: LpColorHex.TRICKY_TEAL },
  { name: ItemColorName.SONIC_GREEN, hex: LpColorHex.SONIC_GREEN },
  { name: ItemColorName.GOLD_METAL, hex: LpColorHex.GOLD_METAL },
  { name: ItemColorName.TIGER_ORANGE, hex: LpColorHex.TIGER_ORANGE },
  { name: ItemColorName.RISKY_RED, hex: LpColorHex.RISKY_RED },
  { name: ItemColorName.PINK_DRINK, hex: LpColorHex.PINK_DRINK },
  { name: ItemColorName.POWER_PURPLE, hex: LpColorHex.POWER_PURPLE },
  { name: ItemColorName.SMART_SLATE, hex: LpColorHex.SMART_SLATE },
  { name: ItemColorName.EARL_GRAY, hex: LpColorHex.EARL_GRAY },
  { name: ItemColorName.NIGHTSHADE, hex: LpColorHex.NIGHTSHADE },
];

const ColorPickerDropdown = ({
  disabled,
  hex,
  onChange,
  onClose,
  isOpen,
  fluid,
  useOnGrid = false,
}: ColorPickerDropdownProps) => {
  const dropdownOptions: Array<DropdownItemProps> = colors.map((color) => {
    return {
      text: (
        <span>
          <LpIcon icon={squareSolid} color={`#${color.hex}`} transform="left-5" />
          {color.name}
        </span>
      ),
      value: color.hex,
    };
  });

  const handleChange = (_: SyntheticEvent, { value }: DropdownProps) => {
    onChange?.({ color: value as LpColorHex });
  };

  const colorPickerDropdown = (
    <Dropdown
      className={'lp-color-picker-dropdown'}
      closeOnChange
      data-testid="color-picker-dropdown"
      disabled={disabled}
      onChange={handleChange}
      onClose={onClose}
      options={dropdownOptions}
      placeholder="Select"
      selection
      selectOnNavigation={false}
      value={hex}
      open={isOpen}
      fluid={fluid}
    />
  );

  return useOnGrid ? <PortalDropdown>{colorPickerDropdown}</PortalDropdown> : colorPickerDropdown;
};

export default ColorPickerDropdown;
