import { PropertyChange } from 'daos/types';
import NewWindowLink from 'lib/display_helpers/url_links';

const ChangeCardUrlProperty = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  if (!propertyChangeData.value) {
    return null;
  }

  return <NewWindowLink to={propertyChangeData.value}>{propertyChangeData.value}</NewWindowLink>;
};

export default ChangeCardUrlProperty;
