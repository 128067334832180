import { getIn, useFormikContext } from 'formik';
import { Input, Table } from 'semantic-ui-react';

import { circleMinusLight, LpIcon, rightArrowRegular } from 'features/common/lp_icon';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { StoryPointsFieldsProps } from 'features/story_points/editable_story_points_list/types';

type JiraStoryPointsSchemeFieldsProps = StoryPointsFieldsProps & {
  isReadOnly?: boolean;
  disableClear?: boolean;
  disableDelete?: boolean;
};

export const StoryPointsSchemeFields = ({
  index,
  id,
  value,
  lowEffort,
  highEffort,
  onStoryPointsChange,
  onRemoveStoryPoints,
  errors,
  isReadOnly,
  disableClear = false,
  disableDelete = false,
}: JiraStoryPointsSchemeFieldsProps) => {
  const { errors: formErrors, setFieldError } = useFormikContext<JiraProjectModalFormValues>();
  const lowEffortError = (index: number) => getIn(formErrors, `storyPoints[${index}].lowEffort`);
  const highEffortError = (index: number) => getIn(formErrors, `storyPoints[${index}].highEffort`);
  const valueError = (index: number) => getIn(formErrors, `storyPoints[${index}].value`);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onStoryPointsChange(index, { id, value: e.target.value, lowEffort, highEffort });
    setFieldError(`storyPoints[${index}].value`, undefined);
  };

  const onLowEffortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onStoryPointsChange(index, { id, value, lowEffort: e.target.value, highEffort });
    setFieldError(`storyPoints[${index}].lowEffort`, undefined);
  };

  const onHighEffortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onStoryPointsChange(index, { id, value, lowEffort, highEffort: e.target.value });
    setFieldError(`storyPoints[${index}].highEffort`, undefined);
  };

  const onClearButtonClick = () => {
    onStoryPointsChange(index, {
      id,
      value,
      lowEffort: '',
      highEffort: '',
    });
  };

  const onRemoveButtonClick = () => {
    onRemoveStoryPoints(index);
  };

  return (
    <JiraProjectModalTableRow key={`${id ?? 0}_${index}`}>
      <JiraProjectModalTableNameCell
        hasMultipleRows={true}
        content={
          <Input
            disabled={isReadOnly}
            value={value}
            onChange={onValueChange}
            error={!!errors?.value || !!valueError(index)}
            className="jira-project-modal__content-estimate-mapping-editable-story-points__list-value"
          />
        }
        rightIcon={<LpIcon icon={rightArrowRegular} />}
      />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-editable-story-points__list">
        <div className="jira-project-modal__content-estimate-mapping-editable-story-points__list-high-low-effort">
          <Input
            value={lowEffort}
            onChange={onLowEffortChange}
            disabled={isReadOnly}
            error={!!errors?.lowEffort || !!lowEffortError(index)}
          />
          {' – '}
          <Input
            value={highEffort}
            onChange={onHighEffortChange}
            disabled={isReadOnly}
            error={!!errors?.highEffort || !!highEffortError(index)}
          />
        </div>
      </Table.Cell>
      <JiraProjectModalTableDeleteCell enabled={!isReadOnly && !disableClear} onClick={onClearButtonClick} />
      <JiraProjectModalTableDeleteCell
        enabled={!isReadOnly && !disableDelete}
        onClick={onRemoveButtonClick}
        icon={circleMinusLight}
        hoverText="Delete row"
      />
    </JiraProjectModalTableRow>
  );
};
