export interface AddWidgetParams {
  organizationId: string;
  workspaceId: string;
  dashboardId: string;
}

export enum WidgetGalleryOptionType {
  AssignmentList = 'assignmentList',
  BoardSummary = 'boardSummary',
  Changes = 'changes',
  DashboardNote = 'dashboardNote',
  FolderList = 'folderList',
  Image = 'image',
  Insights = 'insights',
  IntakeProject = 'intakeProject',
  IntakeTask = 'intakeTask',
  LinkedNote = 'linkedNote',
  MetricsTally = 'metricsTally',
  PackageList = 'packageList',
  PeopleList = 'peopleList',
  ProjectList = 'projectList',
  PropertiesSummary = 'propertiesSummary',
  ScheduleSummary = 'scheduleSummary',
  TaskList = 'taskList',
  Workload = 'workload',
}
