import { ReactNode } from 'react';

import { exclamationTriangleSolid, LpIcon } from 'features/common/lp_icon';
import { lpRed } from 'style/variables';

import './warning_icon_with_value.scss';

export const DisplayValueWithOptionalWarningIcon = ({
  value,
  showWarningIcon,
}: {
  value: ReactNode;
  showWarningIcon: boolean;
}) => (
  <>
    {showWarningIcon && <LpIcon className="warning-icon" color={lpRed} icon={exclamationTriangleSolid} size="sm" />}
    {value}
  </>
);
