import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { Organization, OrganizationUser } from 'daos/model_types';
import { backend } from 'daos/urls';
import { LpIcon, slack } from 'features/common/lp_icon';

import useSlackIntegrationForOrgUser from './use_slack_integration_for_org_user';

import './slack_integration_configuration.scss';

interface OrganizationUserConfigurationProps {
  organization: Organization;
  organizationUser: OrganizationUser;
  createdAt?: string;
  slackTeamName?: string;
}

const OrganizationUserConfiguration = ({ organization, organizationUser }: OrganizationUserConfigurationProps) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const {
    createdAt,
    isLoading,
    slackTeamName,
    disconnect: onDisconnectFromSlack,
  } = useSlackIntegrationForOrgUser(organization.id, organizationUser.id);

  const onAuthorizeWithSlack = () => {
    window.location.assign(
      backend.organizationUserSlackIntegrationAuth.url({
        organizationId: organization.id,
        organizationUserId: organizationUser.id,
      })
    );
  };

  const hasSlackIntegration = organization?.flags.hasSlackIntegration;

  const onOpenConfirmDisconnectionModal = () => setShowConfirmationDialog(true);
  const onCloseConfirmDisconnectionModal = () => setShowConfirmationDialog(false);
  const onConfirmDisconnection = () => {
    setShowConfirmationDialog(false);
    onDisconnectFromSlack();
  };

  return (
    <div>
      <AnimatePresence>
        {!isLoading && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <>
              <h3>
                Manage your Slack integration ({organizationUser.username} + {organization.name})
              </h3>
              <div className="slack-integration-configuration__connect-to-slack-section">
                {createdAt && slackTeamName ? (
                  <>
                    <Button
                      className="slack-integration-configuration__connect-to-slack-button"
                      negative
                      onClick={onOpenConfirmDisconnectionModal}
                    >
                      Disconnect me from Slack
                    </Button>
                    <em>
                      Your LiquidPlanner user account was connected to the <strong>{slackTeamName}</strong> Slack
                      Workspace on {createdAt}
                    </em>
                  </>
                ) : (
                  <>
                    <Button
                      className="slack-integration-configuration__connect-to-slack-button"
                      primary
                      onClick={onAuthorizeWithSlack}
                      disabled={!hasSlackIntegration || !organization.slackApproved}
                      icon={
                        <LpIcon
                          className="slack-integration-configuration__connect-to-slack-button__icon"
                          icon={slack}
                          size="3x"
                        />
                      }
                      content="Connect me to Slack"
                    />
                    {!organization.slackApproved && (
                      <em>
                        An Org Admin needs to enable the Slack Integration in <strong>{organization.name}</strong>{' '}
                        before users can connect.
                      </em>
                    )}
                  </>
                )}
              </div>
            </>
          </motion.div>
        )}
      </AnimatePresence>

      {showConfirmationDialog && (
        <BaseConfirmModal
          confirmButtonText={'Disconnect'}
          content={
            <p>
              You are about to disconnect yourself from the Slack Integration. You will stop receiving LiquidPlanner
              notifications in Slack and your saved connection will be deleted.
            </p>
          }
          onClose={onCloseConfirmDisconnectionModal}
          onConfirm={onConfirmDisconnection}
        />
      )}
    </div>
  );
};

export default OrganizationUserConfiguration;
