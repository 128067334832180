import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Button } from 'semantic-ui-react';

import { LpIcon, caretLeftSolid, caretRightSolid, circleDotSolid, userPlusSolid } from 'features/common/lp_icon';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

import { useJiraProjectModalContext } from './jira_project_modal_context';
import './jira_project_modal.scss';

export const Footer = () => {
  const {
    activeTab,
    tabs,
    handleCloseButtonClick,
    handleBackView,
    isSubmitting,
    isLoadingContent,
    isEditMode,
    issueCount,
    userCount,
    setIssueCount,
    connectionInformation,
    canModify,
    isLoadingJiraProjects,
  } = useJiraProjectModalContext();
  const { values } = useFormikContext<JiraProjectModalFormValues>();

  const index = tabs.findIndex((tab) => tab.key === activeTab);
  const activeTabOrFirstIndex = Math.max(0, index);

  const oauthCredentialsId = values[JiraProjectModalFormFields.OauthCredentialsId];
  const cloudId = values[JiraProjectModalFormFields.CloudId];
  const selectedJiraProjectId = values[JiraProjectModalFormFields.JiraProjectId];

  useEffect(() => {
    if (!selectedJiraProjectId) {
      setIssueCount(undefined);
    }
  }, [selectedJiraProjectId, setIssueCount]);

  const shouldShowIssueAndUserCount =
    (isEditMode && !!connectionInformation?.email) ||
    (!isEditMode && !!selectedJiraProjectId && !!oauthCredentialsId && !!cloudId);
  const shouldShowBackButton = activeTabOrFirstIndex > 0 && !isEditMode;
  const shouldShowNextButton = activeTabOrFirstIndex < tabs.length - 1 && !isEditMode;
  const shouldShowFinishButton = isEditMode || activeTabOrFirstIndex === tabs.length - 1;
  const shouldDisableSubmitButton =
    !canModify || (isEditMode && !connectionInformation?.email) || isSubmitting || isLoadingContent;

  return (
    <div className="jira-project-modal__footer">
      {shouldShowIssueAndUserCount && (
        <div className="jira-project-modal__footer-count">
          <div className="jira-project-modal__footer-count-item">
            <LpIcon icon={circleDotSolid} />
            Issue Count: {issueCount}
          </div>
          <div className="jira-project-modal__footer-count-item">
            <LpIcon icon={userPlusSolid} />
            User Count: {userCount}
          </div>
        </div>
      )}
      <div className="jira-project-modal__footer-actions">
        <Button
          type="button"
          key="close-btn"
          data-testid="closeButton"
          onClick={handleCloseButtonClick}
          content={isEditMode ? 'Close' : 'Cancel'}
          className="jira-project-modal__footer-actions-close"
        />
        {shouldShowBackButton && (
          <Button
            onClick={handleBackView}
            data-testid="backButton"
            type="button"
            loading={isSubmitting}
            disabled={isSubmitting || isLoadingContent}
            key="back-btn"
            className="jira-project-modal__footer-actions-back"
          >
            <LpIcon icon={caretLeftSolid} size="xs" />
            Back
          </Button>
        )}
        {shouldShowNextButton && (
          <Button
            type="submit"
            data-testid="nextButton"
            loading={isSubmitting}
            disabled={isSubmitting || isLoadingContent || isLoadingJiraProjects}
            key="next-btn"
            className="jira-project-modal__footer-actions-next"
          >
            Next
            <LpIcon icon={caretRightSolid} size="xs" />
          </Button>
        )}
        {shouldShowFinishButton && (
          <Button
            key="finish-btn"
            type="submit"
            loading={isSubmitting}
            disabled={shouldDisableSubmitButton}
            content={
              isEditMode ? (
                <>Save & Close</>
              ) : (
                <>
                  Launch <LpIcon icon={caretRightSolid} size="xs" />
                </>
              )
            }
            className="jira-project-modal__footer-actions-next"
          />
        )}
      </div>
    </div>
  );
};
