import { Image } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { thirdPartyUrls } from 'daos/urls';
import { LIQUIDPLANNER_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';
import { frontend } from 'lib/urls';
import lpRiverBendSquareLogo from 'static/img/300-Riverbed-Square-Logo.png';

import './login_sign_up_release_notes.scss';

export const LoginSignUpAndReleaseNotes = () => {
  return (
    <div className="login__logo">
      <NewWindowLink to={LIQUIDPLANNER_URL}>
        <Image centered className="login__logo-image" draggable={false} src={lpRiverBendSquareLogo} />
      </NewWindowLink>
      <p className="login__logo-text login__logo-text--large-font">
        See the latest updates in <br />
        <NewWindowLink className="login__logo-text login__logo-text--link" to={thirdPartyUrls.release_notes_url}>
          Release Notes
        </NewWindowLink>
      </p>
      <p className="login__logo-text">
        Ready to join?{' '}
        <LpLink
          data-e2e-test-id="sign-up"
          className="login__logo-text login__logo-text--link"
          to={frontend.trial.url({})}
        >
          Sign up
        </LpLink>
      </p>
    </div>
  );
};
