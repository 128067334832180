import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import { ItemType, Permission } from 'daos/enums';
import { Item } from 'daos/model_types';
import { getItemPickerSelection } from 'features/common/item_picker/selectors';
import { useItemPanelParams } from 'features/item_panel/use_item_panel_params';
import { hasAccess } from 'hooks/use_has_access';

interface ItemPickerCheckboxProps {
  item: Item;
  allowMultiSelect: boolean | undefined;
  requiredAccess: Permission;
  allowedItemTypesToSelect?: ReadonlySet<ItemType>;
  updateSelectedItem: (_: React.SyntheticEvent, { checked, value }: CheckboxProps) => void;
  forceDisableFlag: boolean;
}

export const ItemPickerCheckbox = ({
  item,
  allowMultiSelect,
  requiredAccess,
  allowedItemTypesToSelect,
  updateSelectedItem,
  forceDisableFlag,
}: ItemPickerCheckboxProps) => {
  const selection = useSelector(getItemPickerSelection);

  const { itemPanelId } = useItemPanelParams();

  const hasRequiredAccess = hasAccess(requiredAccess, item);
  const numberItemsSelected = Object.keys(selection).length;
  const isOnlyOneItemSelected = numberItemsSelected === 1;
  const isAllowedItemTypeToSelect = allowedItemTypesToSelect?.has(item.itemType) ?? true;

  return (
    <Checkbox
      className={classNames(!isAllowedItemTypeToSelect && 'hidden')}
      disabled={
        !hasRequiredAccess ||
        (!allowMultiSelect && isOnlyOneItemSelected && !selection[item.id]) ||
        item.id === itemPanelId ||
        !isAllowedItemTypeToSelect ||
        forceDisableFlag
      }
      checked={!!selection[item.id]}
      onChange={updateSelectedItem}
      value={item.id}
    />
  );
};
