import { PackageStatus } from 'daos/enums';
import { frontend } from 'lib/urls';

export const getProjectDashboardRoutes = (projectProps: {
  organizationId: number;
  workspaceId: number;
  dashboardId: number | string;
  itemId: number;
}) => {
  return {
    dashboardRoute: frontend.projectLibraryDashboard.url(projectProps),
    designLayoutRoute: frontend.projectDashboardDesignLayout.url(projectProps),
    designSettingsRoute: frontend.projectDashboardDesignSettings.url(projectProps),
    designGuestAccessRoute: frontend.projectDashboardGuestAccess.url(projectProps),
  };
};

export const getPackageDashboardRoutes = (packageProps: {
  organizationId: number;
  workspaceId: number;
  dashboardId: number | string;
  packageId: number;
}) => {
  return {
    dashboardRoute: frontend.packageLibraryDashboard.url(packageProps),
    designLayoutRoute: frontend.packageDashboardDesignLayout.url(packageProps),
    designSettingsRoute: frontend.packageDashboardDesignSettings.url(packageProps),
    designGuestAccessRoute: frontend.packageDashboardGuestAccess.url(packageProps),
  };
};

export const getWorkspaceDashboardRoutes = (workspaceRootProps: {
  organizationId: number;
  workspaceId: number;
  dashboardId: number | string;
}) => {
  const workspaceDashboardRoutes = {
    dashboardRoute: frontend.workspaceDashboard.url(workspaceRootProps),
    designLayoutRoute: frontend.workspaceDashboardDesignLayout.url(workspaceRootProps),
    designSettingsRoute: frontend.workspaceDashboardDesignSettings.url(workspaceRootProps),
    designGuestAccessRoute: frontend.workspaceDashboardGuestAccess.url(workspaceRootProps),
  };

  return workspaceDashboardRoutes;
};

export const getCollectionDashboardRoutes = (
  collectionProps: { organizationId: number | string; workspaceId: number | string; dashboardId: number | string },
  packageStatus: PackageStatus | null | undefined
) => {
  switch (packageStatus) {
    case PackageStatus.SCHEDULED:
      return {
        dashboardRoute: frontend.scheduledCollectionDashboard.url(collectionProps),
        designLayoutRoute: frontend.scheduledCollectionDashboardDesignLayout.url(collectionProps),
        designSettingsRoute: frontend.scheduledCollectionDashboardDesignSettings.url(collectionProps),
        designGuestAccessRoute: frontend.scheduledCollectionDashboardDesignAccess.url(collectionProps),
      };
    case PackageStatus.BACKLOG:
      return {
        dashboardRoute: frontend.pendingCollectionDashboard.url(collectionProps),
        designLayoutRoute: frontend.pendingCollectionDashboardDesignLayout.url(collectionProps),
        designSettingsRoute: frontend.pendingCollectionDashboardDesignSettings.url(collectionProps),
        designGuestAccessRoute: frontend.pendingCollectionDashboardDesignAccess.url(collectionProps),
      };
    case PackageStatus.ARCHIVED:
      return {
        dashboardRoute: frontend.archiveCollectionDashboard.url(collectionProps),
        designLayoutRoute: frontend.archiveCollectionDashboardDesignLayout.url(collectionProps),
        designSettingsRoute: frontend.archiveCollectionDashboardDesignSettings.url(collectionProps),
        designGuestAccessRoute: frontend.archiveCollectionDashboardDesignAccess.url(collectionProps),
      };
    case PackageStatus.TEMPLATE:
    default:
      return undefined;
  }
};
