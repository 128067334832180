export const JiraSoftwareBlueSvg = () => (
  <svg viewBox="0 0 584 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1180_605)">
      <path
        d="M109.28 9H117.2V49.2C117.2 59.85 112.54 67.2 101.62 67.2C98.3907 67.2489 95.1771 66.7415 92.12 65.7V58C94.7278 59.0111 97.5032 59.52 100.3 59.5C106.99 59.5 109.3 55.5 109.3 49.73L109.28 9Z"
        fill="#253858"
      />
      <path
        d="M131.63 5.91001C133.059 5.80774 134.463 6.33109 135.476 7.34431C136.489 8.35753 137.012 9.76076 136.91 11.19C136.91 14.1061 134.546 16.47 131.63 16.47C128.714 16.47 126.35 14.1061 126.35 11.19C126.248 9.76076 126.771 8.35753 127.784 7.34431C128.798 6.33109 130.201 5.80774 131.63 5.91001ZM127.76 22.81H135.33V66.81H127.76V22.81Z"
        fill="#253858"
      />
      <path
        d="M146.76 22.8H154.15V30.54C156.7 25.35 161.15 21.65 169.73 22.18V29.57C160.05 28.57 154.15 31.51 154.15 40.83V66.83H146.76V22.8Z"
        fill="#253858"
      />
      <path
        d="M205.19 58.88C202.37 64.69 197.09 67.68 190.32 67.68C178.62 67.68 172.72 57.74 172.72 44.8C172.72 32.39 178.88 21.92 191.2 21.92C197.62 21.92 202.55 24.82 205.2 30.54V22.8H212.77V66.8H205.2L205.19 58.88ZM192.34 60.64C199.12 60.64 205.19 56.33 205.19 46.56V43C205.19 33.23 199.65 28.92 193.19 28.92C184.65 28.92 180.25 34.55 180.25 44.76C180.29 55.36 184.51 60.64 192.35 60.64H192.34Z"
        fill="#253858"
      />
      <path
        d="M257.55 67.68C246.37 67.68 241.18 65.48 236.69 63.19V55.1C242.06 57.92 250.33 59.94 257.98 59.94C266.69 59.94 269.98 56.51 269.98 51.4C269.98 46.29 266.81 43.57 255.81 40.93C242.81 37.76 236.98 33.27 236.98 23.77C236.98 14.77 243.84 8.10999 257.31 8.10999C265.67 8.10999 271.04 10.11 275 12.33V20.25C269.495 17.2166 263.284 15.6982 257 15.85C249.43 15.85 244.94 18.49 244.94 23.77C244.94 28.52 248.64 30.9 258.84 33.45C271.07 36.53 277.94 40.45 277.94 50.87C277.88 60.9 271.9 67.68 257.55 67.68Z"
        fill="#253858"
      />
      <path
        d="M284.57 44.71C284.57 31.51 292.31 21.92 305.51 21.92C318.71 21.92 326.28 31.51 326.28 44.71C326.28 57.91 318.62 67.71 305.51 67.71C292.4 67.71 284.57 57.91 284.57 44.71ZM291.96 44.71C291.96 53.07 296.1 60.64 305.51 60.64C314.92 60.64 318.89 53.07 318.89 44.71C318.89 36.35 314.84 29 305.51 29C296.18 29 292 36.35 292 44.71H291.96Z"
        fill="#253858"
      />
      <path
        d="M345.55 22.8H356.9V29.8H345.55V66.8H338.16V29.8H331V22.8H338.13V18C338.13 9.82 342.71 4.27 352.13 4.27C353.855 4.26038 355.572 4.49607 357.23 4.97V11.97C355.664 11.6723 354.074 11.5249 352.48 11.53C347.82 11.53 345.48 14.26 345.48 18.22L345.55 22.8Z"
        fill="#253858"
      />
      <path
        d="M382.51 59.41C384.11 59.3622 385.701 59.1545 387.26 58.79V66.09C385.602 66.5644 383.885 66.8001 382.16 66.79C372.74 66.79 368.16 61.25 368.16 53.06V29.84H361V22.84H368.13V13.47H375.52V22.8H387.22V29.8H375.52V52.9C375.56 56.77 377.85 59.41 382.51 59.41Z"
        fill="#253858"
      />
      <path
        d="M389.38 22.8H397.74L410.41 60.11L423.25 22.8H430.47L443.32 60.11L456 22.8H464.36L448.17 66.8H438.49L430.92 45.51L426.92 31.96L422.92 45.51L415.25 66.8H405.57L389.38 22.8Z"
        fill="#253858"
      />
      <path
        d="M498.93 58.88C496.11 64.69 490.83 67.68 484.06 67.68C472.36 67.68 466.46 57.74 466.46 44.8C466.46 32.39 472.62 21.92 484.94 21.92C491.36 21.92 496.29 24.82 498.94 30.54V22.8H506.51V66.8H498.94L498.93 58.88ZM486.08 60.64C492.86 60.64 498.93 56.33 498.93 46.56V43C498.93 33.23 493.39 28.92 486.93 28.92C478.43 29 474 34.59 474 44.8C474 55.36 478.25 60.64 486.09 60.64H486.08Z"
        fill="#253858"
      />
      <path
        d="M517.94 22.8H525.33V30.54C527.88 25.35 532.33 21.65 540.91 22.18V29.57C531.23 28.57 525.33 31.51 525.33 40.83V66.83H517.94V22.8Z"
        fill="#253858"
      />
      <path
        d="M567.57 67.68C551.11 67.68 543.9 58.18 543.9 44.68C543.9 31.39 551.29 21.89 564.67 21.89C578.22 21.89 583.67 31.31 583.67 44.68V48.11H551.56C552.62 55.59 557.46 60.43 567.84 60.43C572.405 60.4259 576.934 59.6203 581.22 58.05V65.05C577.61 67 572.06 67.68 567.57 67.68ZM551.47 41.68H576C575.56 33.5 571.86 28.83 564.3 28.83C556.31 28.79 552.26 34 551.47 41.63V41.68Z"
        fill="#253858"
      />
      <path
        d="M72.4 35.76L39.8 3.16L36.64 0L12.1 24.54L0.879985 35.76C-0.289829 36.9313 -0.289829 38.8287 0.879985 40L23.3 62.42L36.64 75.76L61.18 51.22L61.56 50.84L72.4 40C73.5698 38.8287 73.5698 36.9313 72.4 35.76ZM36.64 49.08L25.44 37.88L36.64 26.68L47.84 37.88L36.64 49.08Z"
        fill="#2684FF"
      />
      <path
        d="M36.64 26.68C29.3071 19.3462 29.2713 7.46781 36.56 0.0900269L12.05 24.59L25.39 37.93L36.64 26.68Z"
        fill="url(#paint0_linear_1180_605)"
      />
      <path
        d="M47.87 37.85L36.64 49.08C40.1794 52.6172 42.1679 57.416 42.1679 62.42C42.1679 67.4239 40.1794 72.2227 36.64 75.76L61.21 51.19L47.87 37.85Z"
        fill="url(#paint1_linear_1180_605)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1180_605"
        x1="2271.05"
        y1="1526.09"
        x2="383.24"
        y2="2323.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1180_605"
        x1="95319.1"
        y1="97128"
        x2="137327"
        y2="85691.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <clipPath id="clip0_1180_605">
        <rect width="584" height="76" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
