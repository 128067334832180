import classNames from 'classnames';
import { useSelector } from 'react-redux';

import ItemName from 'containers/shared/custom_column/item_name';
import { CustomFieldType } from 'daos/enums';
import { CustomField, CustomFieldValue } from 'daos/model_types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { toggleOffRegular, toggleOnRegular, LpIcon, squareSolid } from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getPicklistChoiceForId } from 'redux/entities/selectors/custom_field';
import {
  getCurrentOrganizationUser,
  getCurrentOrganizationUserForWorkspaceUser,
  getWorkspaceUserById,
} from 'redux/entities/selectors/user';
import { gray300, gray600, green600 } from 'style/variables';

import './custom_field.scss';

export const CUSTOM_FIELD_FORMATTER__INHERITED = 'custom-field-formatter--inherited';

export const CustomFieldFormatters = ({
  value,
  customField,
  itemId,
}: {
  value: Partial<CustomFieldValue>;
  customField: CustomField;
  itemId: number;
}) => {
  const { formatLocalDate, formatCurrency } = useLocalizedFormats();
  const customFieldValueWorkspaceUser = useSelector((state) =>
    value.workspaceUserValue ? getWorkspaceUserById(state, value.workspaceUserValue.id) : undefined
  );
  const customFieldValueOrgUser = useSelector((state) =>
    getCurrentOrganizationUserForWorkspaceUser(state, customFieldValueWorkspaceUser)
  );
  const orgUser = useSelector(getCurrentOrganizationUser);

  const picklistChoice = useSelector((state) =>
    value.picklistChoice ? getPicklistChoiceForId(state, value.picklistChoice?.id) : undefined
  );

  const isValueInherited = itemId !== value.item?.id;

  switch (customField.fieldType) {
    case CustomFieldType.CURRENCY:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>
          {value.currency &&
            customField.currencyUnit &&
            orgUser &&
            formatCurrency(value.currency, customField.currencyUnit, orgUser.numberFormat)}
        </span>
      );
    case CustomFieldType.DATE:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>
          {value.date && formatLocalDate(value.date)}
        </span>
      );
    case CustomFieldType.MULTILINE_TEXT:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>{value.text}</span>
      );
    case CustomFieldType.ITEM:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>
          {value.itemValue && <ItemName itemId={value.itemValue.id} />}
        </span>
      );
    case CustomFieldType.LINK:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>
          {value.text ? value.text : value.url}
        </span>
      );
    case CustomFieldType.NUMERIC:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>{value.number}</span>
      );
    case CustomFieldType.CHECKBOX:
      return (
        <LpIcon
          className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited, lpicon: true })}
          color={value.checked ? green600 : gray600}
          icon={value.checked ? toggleOnRegular : toggleOffRegular}
          size="lg"
        />
      );
    case CustomFieldType.PICKLIST:
      return (
        <span>
          {picklistChoice && (
            <>
              <LpIcon
                icon={squareSolid}
                size="1x"
                color={picklistChoice.color ? `#${picklistChoice.color}` : gray300}
                className={classNames({
                  'custom-field-formatter__picklist-icon': true,
                  [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited,
                })}
              />
              <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>
                {picklistChoice.name}
              </span>
            </>
          )}
        </span>
      );
    case CustomFieldType.TEXT:
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited })}>{value.text}</span>
      );
    case CustomFieldType.USER:
      return customFieldValueOrgUser ? (
        <span
          className={classNames('custom-field-formatter__user', {
            [CUSTOM_FIELD_FORMATTER__INHERITED]: isValueInherited,
          })}
        >
          <UserAvatar size={AvatarSize.ExtraSmall_1_2} orgUserId={customFieldValueOrgUser.id} />
          {customFieldValueOrgUser.username}
        </span>
      ) : (
        <span className="custom-field-formatter__empty">Select User</span>
      );
    default:
      return null;
  }
};
