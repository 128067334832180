import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { OrganizationDao } from 'daos/organization';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { plusSolid, LpIcon } from 'features/common/lp_icon';
import AddWorkspaceForm from 'features/organization_directory/add_workspace_form';
import BeyondLimitModal from 'features/organization_directory/manage_account/beyond_limit_modal';
import WorkspaceCard from 'features/organization_directory/workspace_card';
import { useHasAccess } from 'hooks/use_has_access';
import useOrgUsageLimitWithOrgFetchOnModified from 'hooks/use_org_usage_limit_with_org_fetch_on_modified';
import { getWorkspacesForOrgIdSortedByName } from 'redux/entities/selectors/workspace';

const OrganizationWorkspaces = () => {
  const organizationId = useSelector(getCurrentOrganizationId);

  const hasAccess = useHasAccess(Permission.EDIT, OrganizationDao.id(organizationId));

  const { canAddWorkspaces, reFetchOrgLimits } = useOrgUsageLimitWithOrgFetchOnModified();

  const workspaces = useSelector((state) => getWorkspacesForOrgIdSortedByName(state, organizationId));

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModalStateOnClick = () => setOpenModal((prevState) => !prevState);

  const handleAddWorkspaceClose = () => {
    setOpenModal(false);
    reFetchOrgLimits();
  };

  const showAddWorkspaceFormOrBeyondLimitModal = () => {
    if (openModal) {
      return canAddWorkspaces ? (
        <AddWorkspaceForm onClose={handleAddWorkspaceClose} />
      ) : (
        <BeyondLimitModal onClose={handleOpenModalStateOnClick} />
      );
    }

    return null;
  };

  return (
    <div className="organization-directory__workspaces">
      {workspaces.map((workspace) => (
        <WorkspaceCard
          className="organization-directory__workspaces-card"
          key={workspace.id}
          organizationId={organizationId}
          workspaceId={workspace.id}
        />
      ))}

      <Button
        className="organization-directory__add-workspace"
        disabled={!hasAccess}
        onClick={handleOpenModalStateOnClick}
      >
        <LpIcon icon={plusSolid} color="white" size="4x" />
      </Button>

      {showAddWorkspaceFormOrBeyondLimitModal()}
    </div>
  );
};

export default OrganizationWorkspaces;
