import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button,
  ButtonProps,
  Dimmer,
  Dropdown,
  DropdownProps,
  Input,
  InputOnChangeData,
  Popup,
} from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { EmailConfirmationDao, EMAIL_CONFIRMATION_NON_INTERACTIVE_DURATION } from 'daos/email_confirmation';
import {
  BillingPeriod,
  BillingPeriodDisplay,
  ManageAccountPlanChangeType,
  PlanFamily,
  planFamilyDisplayText,
  PricingModel,
} from 'daos/enums';
import { Plan } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { PlanDao, planPricingTier } from 'daos/plan';
import { thirdPartyUrls } from 'daos/urls';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import InlineButton from 'features/common/buttons/inline_button';
import { getCurrentOrganizationId, getCurrentUser, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { FeatureTrialSimpleBanner } from 'features/common/feature_trial/feature_trial_simple_banner';
import {
  arrowAltSquareDownSolid,
  arrowAltSquareUpSolid,
  creditCardSolid,
  coinSolid,
  robotSolid,
  LpIcon,
} from 'features/common/lp_icon';
import ContentModalWithSingleCloseButton from 'features/common/modals/content_modal_with_single_close_button';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import roundToTwoDecimalPlaces from 'lib/display_helpers/round_to_two_decimal_places';
import NewWindowLink from 'lib/display_helpers/url_links';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import {
  getCurrentOrganizationPlan,
  getPlanForId,
  getActivePublicPlanFamilies,
  getActivePlans,
} from 'redux/entities/selectors/plan';
import { getCurrentOrganizationSubscription } from 'redux/entities/selectors/subscription';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import './index.scss';

export const findPlan = ({
  activePlans,
  family,
  billingPeriod,
}: {
  activePlans: Array<Plan>;
  family: PlanFamily;
  billingPeriod: BillingPeriod;
}) => {
  let targetBillingPeriod = billingPeriod;
  if (family === PlanFamily.Free) {
    targetBillingPeriod = BillingPeriod.Other;
  } else if (billingPeriod === BillingPeriod.Other) {
    targetBillingPeriod = BillingPeriod.Annual;
  }

  return activePlans.find((plan) => plan.family === family && plan.billingPeriod === targetBillingPeriod);
};

export const ManageAccountPlanChange = ({ changeType }: { changeType: ManageAccountPlanChangeType }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { formatNumber, formatLocalDate } = useLocalizedFormats();

  const workspaceId = useSelector(getCurrentWorkspaceId);
  const activePlans = useSelector(getActivePlans);
  const planFamilies = useSelector(getActivePublicPlanFamilies);

  const currentOrganization = useSelector(getCurrentOrganization);
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);
  const currentUser = useSelector(getCurrentUser);
  const subscription = useSelector(getCurrentOrganizationSubscription);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const currentOrganizationSubscription = useSelector(getCurrentOrganizationSubscription);
  const currentPurchasedLicenseCount = subscription?.paidLicenses;
  const currentUserEmailIsUnverified = currentUser?.unconfirmedEmail != null;

  const [confirmationSent, setConfirmationSent] = useState(false);
  const resendConfirmationEmail = () => {
    // Prevent the user from resending multiple confirmation emails by only allowing
    // the button to be clicked while the confirmation message is not visible
    if (!confirmationSent) {
      dispatch(EmailConfirmationDao.reCreateConfirmation());
      setConfirmationSent(true);
      setTimeout(() => setConfirmationSent(false), EMAIL_CONFIRMATION_NON_INTERACTIVE_DURATION);
    }
  };

  const currentPlan = useSelector(getCurrentOrganizationPlan);
  const currentPlanFamily = currentPlan?.family;
  const currentPlanBillingPeriod = currentPlan?.billingPeriod;

  const newPlanFamilies = planFamilies.filter((family) =>
    changeType === ManageAccountPlanChangeType.Upgrade
      ? currentPlan?.upgradeFamilies.includes(family)
      : currentPlan?.downgradeFamilies.includes(family)
  );

  const passedInPlanIdParam = useQueryParams().planId;
  const passedInPlanIdParamNumber = Number(passedInPlanIdParam);
  const passedInPlanId = isNaN(passedInPlanIdParamNumber) ? undefined : passedInPlanIdParamNumber;
  const passedInPlan = useSelector((state) => getPlanForId(state, passedInPlanId ?? 0));

  const [planId, setPlanId] = useState(passedInPlanId ?? currentPlan?.id);
  const [planFamily, setPlanFamily] = useState(passedInPlan?.family ?? currentPlanFamily);
  const [planBillingPeriod, setPlanBillingPeriod] = useState(passedInPlan?.billingPeriod ?? currentPlanBillingPeriod);

  const [planLicenses, setPlanLicenses] = useState<number | undefined>(undefined);
  const newLicenseCountInputRef = useRef<Input>(null);

  const [planDataError, setPlanDataError] = useState(false);
  const [planLicensesRangeError, setPlanLicensesRangeError] = useState(false);
  const [planLicensesCountError, setPlanLicensesCountError] = useState(false);

  const [updateInProgress, setUpdateInProgress] = useState(false);

  const plan = activePlans.find((plan) => plan.id === planId);
  const orgMinimumLicenses = currentOrganization?.flags.licensesUsed ?? 1;

  const pricingTier = useMemo(
    () => (plan && planLicenses ? planPricingTier(plan, planLicenses) : null),
    [plan, planLicenses]
  );
  const monthlyLicenseCents = pricingTier?.monthlyPriceCents ?? plan?.monthlyPriceCents ?? 0;

  const closePlanDataErrorModal = () => setPlanDataError(false);
  const closePlanLicenseRangeErrorModal = () => {
    setPlanLicensesRangeError(false);
    newLicenseCountInputRef?.current?.focus();
  };
  const closePlanLicensesCountErrorModal = () => {
    setPlanLicensesCountError(false);
    newLicenseCountInputRef?.current?.focus();
  };

  const updatePlan = useCallback(
    (selectedPlan: Plan | undefined) => {
      if (selectedPlan && selectedPlan.id !== planId) {
        setPlanId(selectedPlan.id);
        setPlanFamily(selectedPlan.family);
        setPlanBillingPeriod(selectedPlan.billingPeriod);
      }
    },
    [planId]
  );

  const handleChangeFamily = (_: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    const newFamily = value as PlanFamily;
    const originalFamily = passedInPlan?.family ?? currentPlanFamily;

    if (newFamily === originalFamily) {
      return updatePlan(passedInPlan ?? currentPlan);
    }

    updatePlan(findPlan({ activePlans, family: newFamily, billingPeriod: BillingPeriod.Annual }));
  };

  const handleNumberOfLicensesChange = (_: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
    if (!value || /\d+/.test(value)) {
      setPlanLicenses(value ? parseInt(value, 10) : undefined);
    }
  };

  const handleSubmitButtonClick = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, _data: ButtonProps) => {
    const isPlanDataValid =
      planId &&
      (planId !== currentPlan?.id || planLicenses !== currentPurchasedLicenseCount) &&
      planFamily &&
      newPlanFamilies.includes(planFamily);

    if (!isPlanDataValid) {
      return setPlanDataError(true);
    }

    if (planLicenses && planFamily !== PlanFamily.Free && currentPlanFamily !== PlanFamily.Free) {
      const isPlanLicensesCountValid =
        changeType === ManageAccountPlanChangeType.Upgrade
          ? planLicenses >= (currentPurchasedLicenseCount ?? 0)
          : planLicenses <= (currentPurchasedLicenseCount ?? 0);

      if (!isPlanLicensesCountValid) {
        return setPlanLicensesCountError(true);
      }
    }

    const isPlanLicensesGTEMin = planLicenses && planLicenses >= orgMinimumLicenses && planLicenses > 0;
    const isPlanLicensesRangeValid = isPlanLicensesGTEMin || plan?.pricingModel === PricingModel.FlatFee;

    if (!isPlanLicensesRangeValid) {
      return setPlanLicensesRangeError(true);
    }

    if (planId) {
      setUpdateInProgress(true);
      const { uuid } = dispatch(
        OrganizationDao.planChange({ organizationId: currentOrganizationId }, changeType, planId, planLicenses ?? 0)
      );

      dispatch(
        awaitRequestFinish<{ externalUrl: string | null }>(uuid, {
          onError: () => {
            setUpdateInProgress(false);
          },
          onSuccess: ({ data }) => {
            if (data.externalUrl) {
              window.location.assign(data.externalUrl);
            } else {
              history.push(frontend.organizationHubManageAccount.url({ organizationId: currentOrganizationId }));
            }
          },
          onFinish: () => setUpdateInProgress(false),
        })
      );
    }
  };

  useEffect(() => {
    setPlanLicenses(Math.max(orgMinimumLicenses, currentPurchasedLicenseCount ?? 1));
  }, [currentPurchasedLicenseCount, orgMinimumLicenses]);

  useEffect(() => {
    dispatch(PlanDao.fetchAll({ organizationId: currentOrganizationId }));

    const { uuid } = dispatch(
      OrganizationDao.fetch(
        { organizationId: currentOrganizationId },
        { include: { includeSubscription: true, includePlan: true } }
      )
    );
    dispatch(awaitRequestFinish(uuid, {}));
  }, [currentOrganizationId, dispatch]);

  if (
    !currentPlanBillingPeriod ||
    !currentPlanFamily ||
    !currentOrganization ||
    !currentOrganizationUser ||
    !currentOrganizationSubscription ||
    !currentUser
  ) {
    return null;
  }

  return (
    <>
      <TopNavHeader
        breadcrumb={
          <LpBreadcrumb
            sections={[
              {
                key: currentOrganization.name,
                content: (
                  <span>
                    <LpIcon icon={creditCardSolid} />{' '}
                    <LpLink to={frontend.workspaceHub.url({ organizationId: currentOrganizationId, workspaceId })}>
                      {currentOrganization.name}
                    </LpLink>
                  </span>
                ),
              },
              {
                key: 'manage-account',
                content: (
                  <LpLink
                    to={frontend.organizationHubManageAccount.url({
                      organizationId: currentOrganizationId,
                    })}
                  >
                    {NavigationMenuDisplay.ManageAccount}
                  </LpLink>
                ),
              },
              { key: changeType, content: changeType },
            ]}
          />
        }
      />
      <div className="manage-account-change-plan">
        <div className="manage-account-change-plan--max-width">
          {planDataError && (
            <ContentModalWithSingleCloseButton onClose={closePlanDataErrorModal}>
              <div>
                <h4>Please {changeType} Your Plan</h4>
                <p>You have not made any changes to your plan.</p>
              </div>
            </ContentModalWithSingleCloseButton>
          )}
          {planLicensesCountError && (
            <ContentModalWithSingleCloseButton onClose={closePlanLicensesCountErrorModal}>
              <div>
                <h4>Invalid License Total</h4>
                <p>
                  Your new license total can not be{' '}
                  {changeType === ManageAccountPlanChangeType.Upgrade ? 'less' : 'greater'} than your current license
                  total.
                </p>
              </div>
            </ContentModalWithSingleCloseButton>
          )}
          {planLicensesRangeError && (
            <ContentModalWithSingleCloseButton onClose={closePlanLicenseRangeErrorModal}>
              <div>
                <h4>Invalid License Total</h4>
                <p>Your new license total must be at least {orgMinimumLicenses}.</p>
              </div>
            </ContentModalWithSingleCloseButton>
          )}
          <Dimmer inverted active={updateInProgress} />
          {currentUserEmailIsUnverified && (
            <FeatureTrialSimpleBanner
              classname="manage-account-change-plan__resend-email-banner"
              bannerText="Awesome! In order to purchase, you must verify your email address."
              bannerButton={
                <Popup
                  transition="false"
                  trigger={<InlineButton onClick={resendConfirmationEmail}>Resend Email</InlineButton>}
                  content="Confirmation email sent!"
                  open={confirmationSent}
                  basic
                  position={'bottom center'}
                  size="tiny"
                />
              }
            />
          )}
          <div
            className={classNames(
              'manage-account-change-plan__header',
              `manage-account-change-plan__header--${changeType.toLowerCase()}`
            )}
          >
            <div className="manage-account-change-plan__header-title">
              <LpIcon
                icon={
                  changeType === ManageAccountPlanChangeType.Upgrade ? arrowAltSquareUpSolid : arrowAltSquareDownSolid
                }
                size="2x"
              />{' '}
              <span>
                {changeType} / {changeType === ManageAccountPlanChangeType.Upgrade ? 'Add' : 'Reduce'} Licenses
              </span>
            </div>
            <div className="manage-account-change-plan__header-disclaimer">Secure billing with Chargebee&trade;</div>
          </div>
          <div className="manage-account-change-plan-form">
            {currentPlanBillingPeriod !== BillingPeriod.Other && (
              <>
                <div className="manage-account-change-plan-form__current-plan-label">Current Plan:</div>
                <div className="manage-account-change-plan-form__current-plan-family">
                  <Dropdown
                    className="manage-account-change-plan-form__choose-your-plan-dropdown"
                    fluid
                    selection
                    value={currentPlanFamily}
                    disabled
                    options={[{ text: planFamilyDisplayText[currentPlanFamily], value: currentPlanFamily }]}
                  />
                </div>
              </>
            )}
            <br />
            <div className="manage-account-change-plan-form__new-plan-label">New Plan:</div>
            <div className="manage-account-change-plan-form__new-plan-family">
              <Dropdown
                className="manage-account-change-plan-form__choose-your-plan-dropdown"
                fluid
                selection
                onChange={handleChangeFamily}
                value={planFamily}
                options={newPlanFamilies.map((family) => ({
                  text: planFamilyDisplayText[family],
                  value: family,
                }))}
              />
            </div>

            <div className="manage-account-change-plan-form__info-area">
              <div className="manage-account-change-plan-form__info-snippet">
                <LpIcon icon={coinSolid} size="lg" />
                Volume discounts will be applied for {planFamilyDisplayText.ultimate}
              </div>

              <div className="manage-account-change-plan-form__info-snippet">
                <LpIcon icon={robotSolid} size="lg" />
                Each license includes two modeling Resources
              </div>
            </div>

            <hr className="manage-account-change-plan-form__separator" />

            {currentPlanBillingPeriod !== BillingPeriod.Other && (
              <>
                <div className="manage-account-change-plan-form__current-license-label">Current License Total:</div>
                <div className="manage-account-change-plan-form__current-license-input">
                  <Input disabled value={currentPurchasedLicenseCount ?? ''} />
                </div>
              </>
            )}

            <div className="manage-account-change-plan-form__new-license-label">New License Total:</div>
            <div
              className={classNames(
                'manage-account-change-plan-form__new-license-input',
                planFamily === PlanFamily.Free && 'manage-account-change-plan-form__new-license-input--disabled'
              )}
            >
              {planFamily === PlanFamily.Free ? (
                <span>{plan?.freeLicenseCount ?? 0} included with plan</span>
              ) : (
                <>
                  <Input
                    ref={newLicenseCountInputRef}
                    value={planLicenses ?? ''}
                    onChange={handleNumberOfLicensesChange}
                  />
                  {monthlyLicenseCents > 0 && (
                    <span>
                      @{' '}
                      <span className="price">
                        $
                        {formatNumber(
                          roundToTwoDecimalPlaces(monthlyLicenseCents / 100),
                          currentOrganizationUser.numberFormat
                        )}
                      </span>{' '}
                      / license / month
                      {planBillingPeriod && (
                        <>
                          , billed {BillingPeriodDisplay[planBillingPeriod]}
                          {changeType === ManageAccountPlanChangeType.Upgrade && <sup>*</sup>}
                        </>
                      )}
                    </span>
                  )}
                </>
              )}
            </div>

            <div
              className={classNames(
                'manage-account-change-plan-form__disclaimer',
                `manage-account-change-plan-form__disclaimer--${changeType.toLowerCase()}`
              )}
            >
              {changeType === ManageAccountPlanChangeType.Downgrade ? (
                <>
                  <LpIcon icon={arrowAltSquareUpSolid} size="lg" />{' '}
                  <span>
                    Changes take effect on next renewal{' '}
                    {currentOrganizationSubscription.nextBillingOn &&
                      formatLocalDate(currentOrganizationSubscription.nextBillingOn)}
                  </span>
                </>
              ) : (
                <span>
                  <sup>*</sup> Tax not included
                </span>
              )}
            </div>
            <Button
              className={classNames(
                'manage-account-change-plan-form__button',
                `manage-account-change-plan-form__button--${changeType.toLowerCase()}`
              )}
              onClick={handleSubmitButtonClick}
              disabled={currentUserEmailIsUnverified}
            >
              {changeType}
            </Button>
          </div>
          <p>
            <NewWindowLink to={thirdPartyUrls.billingFAQ}>Frequently Asked Billing Questions</NewWindowLink>
          </p>
          <p>
            <NewWindowLink to={thirdPartyUrls.productAdvisor}>Contact a Product Advisor</NewWindowLink>
          </p>
        </div>
      </div>
    </>
  );
};
