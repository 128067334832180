import { defineModel, filterEq, reduceIncludedOptions } from 'daos/shared';
import { backend } from 'daos/urls';
import { AcademyOutpostTrackLessonOrderBy } from 'features/academy/daos/enums';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'academyOutpostTrackLessons',
  relationships: [],
  type: 'ACADEMY_OUTPOST_TRACK_LESSON',
});

const { ACADEMY_OUTPOST_TRACK_LESSON } = resource;

interface Includes {
  includeAcademyLessonsAndProgress?: boolean;
  includeAcademyTrackAndCourse?: boolean;
}

const includes = ({ includeAcademyLessonsAndProgress, includeAcademyTrackAndCourse }: Includes = {}) =>
  reduceIncludedOptions([
    includeAcademyLessonsAndProgress && 'academyTrackLesson.academyLessons.myAcademyLessonProgress',
    includeAcademyTrackAndCourse && 'academyTrackLesson.academyTrack.academyCourse',
  ]);

interface OutpostOptions {
  outpostLocation: OutpostLocation;
  include?: Includes;
  query?: { order?: AcademyOutpostTrackLessonOrderBy };
}

const outpostFetch = ({ outpostLocation, include, query }: OutpostOptions) =>
  request(
    backend.academyOutpostTrackLesson.url(
      {},
      {
        filter: filterEq('outpost', outpostLocation),
        include: includes(include),
        query,
      }
    ),
    ACADEMY_OUTPOST_TRACK_LESSON,
    { method: HttpMethod.GET }
  );

export const OutpostDao = {
  fetch: outpostFetch,
} as const;
