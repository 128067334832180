export enum SearchContext {
  PackageStatus = 1,
  Package = 2,
  Project = 3,
}

export enum SearchLocation {
  Members = 'members',
  Item = 'item',
}
