import classNames from 'classnames';
import { Dropdown, Popup } from 'semantic-ui-react';

import { PackageStatus } from 'daos/enums';
import { ItemTypesDisplaySingular } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import PortableItemLinkButton from 'features/common/buttons/portable_links/portable_item_link_button';
import { EllipsisActionHandlers } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import {
  shieldCheckLight,
  editLight,
  folderOpenLight,
  LpIcon,
  arrowAltRightRegular,
  boxAltSolid,
  layerGroupSolid,
  archiveSolid,
  copyLight,
  foldersLight,
  plusSolid,
  IconDefinition,
  trashXmarkLight,
  boxHeartLight,
  boxOpenSolid,
} from 'features/common/lp_icon';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import itemTypeDisplayName, { singularDisplayToItemType } from 'lib/display_helpers/item_display_name';

export interface EllipsisActionDropdownMethods {
  add: (hasAccess: boolean, label: ItemTypesDisplaySingular | undefined, key: number) => JSX.Element;
  addJira: (hasManageAccess: boolean | undefined, key: number) => JSX.Element;
  copyLink: (key: number) => JSX.Element;
  delete: (hasAccess: boolean, key: number) => JSX.Element;
  divider: (key: string) => JSX.Element;
  duplicate: (hasAccess: boolean, label: ItemTypesDisplaySingular | undefined, key: number) => JSX.Element;
  edit: (key: number) => JSX.Element;
  memberAccess: (hasAccess: boolean, key: number) => JSX.Element;
  move: (hasAccess: boolean, label: ItemTypesDisplaySingular | undefined) => JSX.Element;
  open: (hasAccess: boolean, label: ItemTypesDisplaySingular | undefined) => JSX.Element;
  projectTemplate: (hasAccess: boolean, key: number) => JSX.Element;
  packageToTemplate: (hasAccess: boolean, key: number) => JSX.Element;
  projectToTemplate: (hasAccess: boolean, key: number) => JSX.Element;
  createPackageFromTemplate: (hasAccess: boolean, key: number) => JSX.Element;
  createProjectFromTemplate: (hasAccess: boolean, key: number) => JSX.Element;
}

type EllipsisActionDropdownMethodsProps = {
  expandSubFolders: boolean;
  handleDuplicateItem: (item: Item) => void;
  isGridView: boolean;
  item: Item;
  itemIdParam: string;
  isAnyItemDuplicating: boolean;
  hideDropdown: () => void;
} & EllipsisActionHandlers;

export const ellipsisActionDropdownMethods = ({
  expandSubFolders,
  handleAddItem,
  handleAddJiraProject,
  handleCopyLink,
  handleDuplicateItem,
  handleEditItem,
  handleItemDelete,
  handleMemberAccess,
  handleMoveItem,
  handleOpenItem,
  hideDropdown,
  isGridView,
  item,
  itemIdParam,
  isAnyItemDuplicating,
  handleOpenTemplatePicker,
  handleOpenPackageToTemplateModal,
  handleOpenProjectToTemplateModal,
  handleOpenTemplateToPackageModal,
  handleOpenTemplateToProjectModal,
}: EllipsisActionDropdownMethodsProps): EllipsisActionDropdownMethods => {
  const menuItemIcon = (lpIcon: IconDefinition | undefined) => lpIcon && <LpIcon icon={lpIcon} className="icon" />;
  const itemLabel = itemTypeDisplayName(item);

  const packageIcon = () => {
    switch (item.packageStatus) {
      case PackageStatus.ARCHIVED:
        return archiveSolid;
      case PackageStatus.BACKLOG:
        return layerGroupSolid;
      case PackageStatus.SCHEDULED:
        return boxAltSolid;
      case PackageStatus.TEMPLATE:
        return boxHeartLight;
      case PackageStatus.CAPACITY:
        return boxOpenSolid;
    }
  };

  const openMenuIcon = (label: ItemTypesDisplaySingular) => {
    switch (label) {
      case ItemTypesDisplaySingular.Project:
      case ItemTypesDisplaySingular.Folder:
        return folderOpenLight;
      case ItemTypesDisplaySingular.Package:
        return packageIcon();
    }
  };

  const onDuplicateItemClick = () => {
    if (!isAnyItemDuplicating) {
      handleDuplicateItem(item);
      hideDropdown();
    }
  };

  return {
    add: (hasAccess, label = itemLabel, key) => (
      <Dropdown.Item
        key={`add ${key}`}
        disabled={!hasAccess}
        onClick={handleAddItem(singularDisplayToItemType[label], expandSubFolders)}
        text={
          <span>
            {menuItemIcon(plusSolid)} {`Add ${label}`}
          </span>
        }
      />
    ),
    addJira: (hasManageAccess, key) => (
      <Dropdown.Item
        key={`add-jira ${key}`}
        onClick={handleAddJiraProject(hasManageAccess)}
        text={<span>{menuItemIcon(plusSolid)} Add Jira Project</span>}
      />
    ),
    copyLink: (key) => (
      <Dropdown.Item
        key={`copy-link ${key}`}
        text={
          <PortableItemLinkButton
            isGridView={isGridView}
            onEllipsisMenuCopySuccess={() => {
              hideDropdown();
            }}
          />
        }
        onClick={handleCopyLink}
        data-testid={'copy-link'}
      />
    ),
    delete: (hasAccess, key) => (
      <Dropdown.Item
        key={`delete ${key}`}
        disabled={!hasAccess}
        text={
          <span>
            {menuItemIcon(trashXmarkLight)} {`Delete ${itemLabel}`}
          </span>
        }
        onClick={handleItemDelete}
      />
    ),
    divider: (key) => <Dropdown.Divider data-testid={key} key={key} />,
    duplicate: (hasAccess, label = itemLabel, key) => (
      <Dropdown.Item
        className={classNames({ 'ellipsis-action-dropdown--disabled': isAnyItemDuplicating })}
        disabled={!hasAccess}
        key={`duplicate ${key}`}
        onClick={onDuplicateItemClick}
        text={
          <Popup
            disabled={!isAnyItemDuplicating}
            position="left center"
            size="mini"
            content={'A duplication is already in process'}
            trigger={
              <span>
                {menuItemIcon(copyLight)} Duplicate {label}
              </span>
            }
          />
        }
      />
    ),
    edit: (key) => (
      <Dropdown.Item
        key={`edit ${key}`}
        text={
          <span>
            {menuItemIcon(editLight)} {`Edit ${itemTypeDisplayName(item)}`}
          </span>
        }
        onClick={handleEditItem}
      />
    ),
    memberAccess: (hasAccess, key) => (
      <Dropdown.Item
        key={`manage ${key}`}
        disabled={!hasAccess}
        text={
          <span>
            {menuItemIcon(shieldCheckLight)} {`Member Access`}
          </span>
        }
        onClick={handleMemberAccess}
      />
    ),
    move: (hasAccess, label = itemLabel) => (
      <Dropdown.Item
        key={`move ${itemIdParam} ${item.itemType}`}
        disabled={!hasAccess}
        text={
          <span>
            {menuItemIcon(arrowAltRightRegular)} {`Move ${label}`}
          </span>
        }
        onClick={handleMoveItem}
      />
    ),
    open: (hasAccess, label = itemLabel) => (
      <Dropdown.Item
        key={`open-item ${itemIdParam} ${item.itemType}`}
        disabled={!hasAccess}
        text={
          <span>
            {menuItemIcon(openMenuIcon(label))} {`Open ${label}`}
          </span>
        }
        onClick={handleOpenItem}
      />
    ),
    projectTemplate: (hasAccess, key) => (
      <Dropdown.Item
        key={`project-template ${key}`}
        disabled={!hasAccess}
        onClick={() => {
          handleOpenTemplatePicker();
          hideDropdown();
        }}
        text={
          <span>
            {menuItemIcon(foldersLight)} {`Project Templates${HORIZONTAL_ELLIPSIS}`}
          </span>
        }
      />
    ),
    packageToTemplate: (hasAccess, key) => (
      <Dropdown.Item
        key={`project-to-template ${key}`}
        disabled={!hasAccess}
        onClick={() => {
          handleOpenPackageToTemplateModal();
          hideDropdown();
        }}
        text={<span>{menuItemIcon(boxHeartLight)} Create Template</span>}
      />
    ),
    projectToTemplate: (hasAccess, key) => (
      <Dropdown.Item
        key={`project-to-template ${key}`}
        disabled={!hasAccess}
        onClick={() => {
          handleOpenProjectToTemplateModal();
          hideDropdown();
        }}
        text={<span>{menuItemIcon(boxHeartLight)} Create Template</span>}
      />
    ),
    createPackageFromTemplate: (hasAccess, key) => (
      <Dropdown.Item
        key={`package-to-template ${key}`}
        data-e2e-test-id="create-package"
        disabled={!hasAccess}
        onClick={() => {
          handleOpenTemplateToPackageModal();
          hideDropdown();
        }}
        text={<span>{menuItemIcon(boxHeartLight)} Create Package</span>}
      />
    ),
    createProjectFromTemplate: (hasAccess, key) => (
      <Dropdown.Item
        key={`project-to-template ${key}`}
        disabled={!hasAccess}
        onClick={() => {
          handleOpenTemplateToProjectModal();
          hideDropdown();
        }}
        text={<span>{menuItemIcon(foldersLight)} Create Project</span>}
      />
    ),
  };
};
