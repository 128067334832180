import classNames from 'classnames';

import { useLpDraggableContext } from 'features/common/drag_drop/index';
import { gripDotsVerticalSolid, LpIcon } from 'features/common/lp_icon';

import 'features/common/drag_drop/drag_handle/index.scss';

interface DragHandleProps {
  disable?: boolean;
}
export const DragHandle = ({ disable }: DragHandleProps) => {
  const { draggableProvided } = useLpDraggableContext();

  return (
    <div
      className={classNames('drag-handle', disable && 'drag-handle--disabled')}
      {...draggableProvided?.dragHandleProps}
    >
      <LpIcon icon={gripDotsVerticalSolid} />
    </div>
  );
};
