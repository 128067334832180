import { SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { GroupDao } from 'daos/group';
import { Group, ResourceId, OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { LocationFilterAssigneeSettings } from 'daos/types';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';

const anyone = 'anyone';
const unassigned = 'unassigned';

export const AssignedFilterDropdown = ({
  className,
  onChange,
  options,
  value,
}: {
  className?: string;
  onChange: (values: LocationFilterAssigneeSettings) => void;
  options: Array<DropdownItemProps>;
  value: LocationFilterAssigneeSettings;
}) => {
  const dropdownValue = assignedFilterValueToSerializedDropdownValue(value);
  const handleChange = (_: SyntheticEvent<HTMLElement>, dropdownProps: DropdownProps) => {
    onChange(dropdownValueToAssignedFilterValue(dropdownProps.value));
  };

  return (
    <Dropdown
      className={className}
      search={lpDropdownSearch}
      selection
      fluid
      onChange={handleChange}
      options={options}
      placeholder={'Anyone'}
      selectOnNavigation={false}
      value={dropdownValue}
    />
  );
};

function dropdownValueToAssignedFilterValue(
  dropdownValue?: boolean | number | string | ReadonlyArray<boolean | number | string>
): LocationFilterAssigneeSettings {
  const stringValue = dropdownValue?.toString() || '"anyone"';
  const deserializedValue: ResourceId<OrganizationUser | Group> | 'anyone' | 'unassigned' = JSON.parse(stringValue);
  const isUnAssigned = deserializedValue === anyone;

  if (deserializedValue === unassigned) {
    return {
      groupIdFilter: null,
      organizationUserIdFilter: 0,
    };
  }

  if (!isUnAssigned && deserializedValue.type === 'groups') {
    return {
      groupIdFilter: deserializedValue.id,
      organizationUserIdFilter: null,
    };
  }

  if (!isUnAssigned && deserializedValue.type === 'organizationUsers') {
    return {
      groupIdFilter: null,
      organizationUserIdFilter: deserializedValue.id,
    };
  }

  return {
    groupIdFilter: null,
    organizationUserIdFilter: null,
  };
}

function assignedFilterValueToSerializedDropdownValue({
  groupIdFilter,
  organizationUserIdFilter,
}: LocationFilterAssigneeSettings) {
  if (organizationUserIdFilter != null) {
    if (organizationUserIdFilter == 0) {
      return JSON.stringify(unassigned);
    } else {
      const orgUserResource = OrganizationUserDao.id(Number(organizationUserIdFilter));
      const serializedOrgUser = JSON.stringify(orgUserResource);
      return serializedOrgUser;
    }
  }

  if (groupIdFilter) {
    const groupResource = GroupDao.id(Number(groupIdFilter));
    const serializedGroup = JSON.stringify(groupResource);
    return serializedGroup;
  }

  return JSON.stringify(anyone);
}
