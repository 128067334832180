import { StoryPointScheme, StoryPoints } from 'daos/model_types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { createBody, updateBody, deleteBody, resource } = defineModel({
  apiType: 'storyPointSchemes',
  relationships: ['user'],
  type: 'STORY_POINT_SCHEMES',
});

const { STORY_POINT_SCHEMES, resourceId } = resource;

interface StoryPointSchemesParams {
  organizationId: number | string;
  workspaceId: number | string;
}

interface Includes {
  includeStoryPoints?: boolean;
  includeSchemeOwners?: boolean;
  includeSchemeOwnerItems?: boolean;
}

const includes = ({ includeStoryPoints, includeSchemeOwners, includeSchemeOwnerItems }: Includes = {}) =>
  reduceIncludedOptions([
    includeStoryPoints && 'storyPoints',
    includeSchemeOwners && 'storyPointSchemeOwners',
    includeSchemeOwnerItems && 'storyPointSchemeOwners.items',
  ]);

interface StoryPointSchemesOptions {
  include?: Includes;
}

interface SingleStoryPointSchemesParams extends StoryPointSchemesParams {
  storyPointSchemeId: string | number;
}

type CreateStoryPoints = Pick<StoryPoints, 'value'> & Partial<Pick<StoryPoints, 'highEffort' | 'lowEffort'>>;
type UpdateStoryPoints = Partial<StoryPoints>;

type CreateStoryPointScheme = Pick<StoryPointScheme, 'displayName'> & {
  readonly storyPoints: ReadonlyArray<CreateStoryPoints>;
  readonly workspaceDefault: boolean;
};

type UpdateStoryPointScheme = Partial<StoryPointScheme> & {
  readonly storyPoints: ReadonlyArray<UpdateStoryPoints>;
  readonly workspaceDefault: boolean;
};

const fetch = (params: SingleStoryPointSchemesParams, { include }: StoryPointSchemesOptions = {}) =>
  request(backend.storyPointScheme.url(params, { include: includes(include) }), STORY_POINT_SCHEMES, {
    method: HttpMethod.GET,
  });

const fetchAll = (params: StoryPointSchemesParams, { include }: StoryPointSchemesOptions = {}) =>
  request(backend.storyPointSchemes.url(params, { include: includes(include) }), STORY_POINT_SCHEMES, {
    method: HttpMethod.GET,
  });

const create = (
  params: StoryPointSchemesParams,
  storyPointScheme: CreateStoryPointScheme,
  options?: StoryPointSchemesOptions
) =>
  request(
    backend.storyPointSchemes.url(params, { include: includes(options?.include ?? {}) }),
    STORY_POINT_SCHEMES,
    createBody(storyPointScheme)
  );

const update = (params: SingleStoryPointSchemesParams, storyPointScheme: UpdateStoryPointScheme) =>
  request(
    backend.storyPointScheme.url(params),
    STORY_POINT_SCHEMES,
    updateBody(Number(params.storyPointSchemeId), storyPointScheme)
  );

const destroy = (params: SingleStoryPointSchemesParams, storyPointSchemeId: number) =>
  request(backend.storyPointScheme.url(params), STORY_POINT_SCHEMES, deleteBody(storyPointSchemeId));

export const StoryPointSchemesDao = {
  create,
  destroy,
  fetch,
  fetchAll,
  id: resourceId,
  update,
} as const;
