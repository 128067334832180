const parseQueryString = (queryString: string): Record<string, string> => {
  const urlSearchParams = new URLSearchParams(queryString);

  const record: Record<string, string> = {};

  urlSearchParams.forEach((value, key) => {
    record[key] = value;
  });

  return record;
};

export default parseQueryString;
