import { uniq } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { OrganizationUser, StoryPointScheme, StoryPointSchemeOwner, User } from 'daos/model_types';
import {
  multiComparisonSort,
  numberComparison,
  stringComparison,
  stringDateComparison,
} from 'features/common/frontend_sorting/sort_helpers';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { ReadonlyRecord, ReadonlyRecordKey } from 'lib/readonly_record';
import { getFilteredStoryPointSchemeOwnersBySchemeIds } from 'redux/entities/selectors/story_point_scheme_owners';
import { getOrganizationUsersById, getUsersById } from 'redux/entities/selectors/user';
import { EntityLookupById } from 'redux/entities/types';

import { StoryPointSchemesSortColumn } from './enums';
import { StoryPointSchemeRowData, StoryPointSchemesSortProps } from './type';

export const defaultStoryPointSchemesSort = {
  sortColumn: StoryPointSchemesSortColumn.SchemeName,
  isAscending: true,
};

export const useStoryPointsSchemesRowData = (storyPointSchemes: ReadonlyArray<StoryPointScheme> | undefined) => {
  const users = useSelector(getUsersById);
  const orgUsers = useSelector(getOrganizationUsersById);
  const storyPointSchemeIds = storyPointSchemes?.map((storyPointScheme) => storyPointScheme.id) || [];
  const storyPointOwnersBySchemeId = useSelector((state) =>
    getFilteredStoryPointSchemeOwnersBySchemeIds(state, storyPointSchemeIds)
  );

  if (!storyPointSchemes) {
    return [];
  }

  return storyPointSchemes.map((scheme) => getStoryPointRowData(scheme, users, orgUsers, storyPointOwnersBySchemeId));
};

const reduceDefaultAssigneeIds = (acc: Array<number>, owner: StoryPointSchemeOwner) => {
  const defaultAssigneeOrgId = owner.defaultAssignee?.id;

  if (defaultAssigneeOrgId) {
    acc.push(defaultAssigneeOrgId);
  }

  return uniq(acc);
};

const getStoryPointRowData = (
  scheme: StoryPointScheme,
  users: EntityLookupById<User>,
  orgUsersById: EntityLookupById<OrganizationUser>,
  storyPointOwnersBySchemeId: ReadonlyRecord<ReadonlyRecordKey, Array<StoryPointSchemeOwner>>
): StoryPointSchemeRowData => {
  const updatedByUserId = scheme.updatedBy?.id;
  const schemeOwners = storyPointOwnersBySchemeId[scheme.id] ?? [];
  const defaultAssigneeOrgUserIds = schemeOwners.reduce(reduceDefaultAssigneeIds, []);
  const defaultAssignee = defaultAssigneeOrgUserIds.map((id) => orgUsersById[id]?.username).join(', ');
  const isDefault = storyPointOwnersBySchemeId[scheme.id]?.some((owner) => owner.item === null);
  const itemCount = schemeOwners.filter((owner) => owner.item !== null).length;

  return {
    id: scheme.id,
    default: isDefault ?? false,
    scheme,
    [StoryPointSchemesSortColumn.SchemeName]: scheme.displayName,
    [StoryPointSchemesSortColumn.UpdatedBy]: updatedByUserId
      ? `${users[updatedByUserId]?.firstName} ${users[updatedByUserId]?.lastName}`
      : '',
    [StoryPointSchemesSortColumn.LastUpdated]: scheme.updatedAt,
    [StoryPointSchemesSortColumn.ProjectUsing]: itemCount,
    [StoryPointSchemesSortColumn.DefaultAssignee]: defaultAssignee,
  };
};

export const usePreformStoryPointSchemesSort = () => {
  const { stringTimestampDifference } = useLocalizedFormats();

  const dateStringSorter = (date1: string, date2: string) => stringTimestampDifference(date2, date1);

  const sortStoryPointSchemes = (
    storyPointSchemesRowData: ReadonlyArray<StoryPointSchemeRowData>,
    sortInfo: StoryPointSchemesSortProps
  ) => performStoryPointSchemesSort(storyPointSchemesRowData, sortInfo, dateStringSorter);

  return { sortStoryPointSchemes };
};

const performStoryPointSchemesSort = (
  storyPointSchemesRowData: ReadonlyArray<StoryPointSchemeRowData>,
  sortInfo: StoryPointSchemesSortProps,
  dateStringSorter: (date1: string, date2: string) => number
) => {
  const { isAscending, sortColumn } = sortInfo;
  const stringSort = (isAscendingOrder: boolean, sortColumnTarget = sortColumn) =>
    stringComparison<StoryPointSchemeRowData>(sortColumnTarget, isAscendingOrder);

  const numberSort = (isAscendingOrder: boolean) =>
    numberComparison<StoryPointSchemeRowData>(sortColumn, isAscendingOrder);

  const stringDateSort = (
    dateStringSorter: (date1: string, date2: string) => number,
    isAscendingOrder: boolean,
    sortColumnTarget = sortColumn
  ) => stringDateComparison<StoryPointSchemeRowData>(sortColumnTarget, isAscendingOrder, dateStringSorter);

  const sortableSchemes = [...storyPointSchemesRowData];

  switch (sortColumn) {
    case StoryPointSchemesSortColumn.SchemeName:
    case StoryPointSchemesSortColumn.UpdatedBy:
    case StoryPointSchemesSortColumn.DefaultAssignee:
      return multiComparisonSort<StoryPointSchemeRowData>(sortableSchemes, [stringSort(isAscending)]);
    case StoryPointSchemesSortColumn.ProjectUsing:
      return multiComparisonSort<StoryPointSchemeRowData>(sortableSchemes, [
        numberSort(isAscending),
        stringSort(!isAscending, StoryPointSchemesSortColumn.SchemeName),
      ]);
    case StoryPointSchemesSortColumn.LastUpdated:
      return multiComparisonSort<StoryPointSchemeRowData>(sortableSchemes, [
        stringDateSort(dateStringSorter, isAscending, StoryPointSchemesSortColumn.LastUpdated),
        stringSort(!isAscending, StoryPointSchemesSortColumn.SchemeName),
      ]);
    default:
      return multiComparisonSort<StoryPointSchemeRowData>(sortableSchemes, [stringSort(isAscending)]);
  }
};
