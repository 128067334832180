import { noop } from 'lodash';
import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Mutable } from 'utility-types';

import ItemMoveModal from 'containers/shared/move_modal';
import { ItemDao } from 'daos/item';
import { RelativePriorityType } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { BulkOptionPopupContent, BulkOptionPopup } from 'features/common/bulk_selection/options/option_popup';
import { getBulkSelectedItemIds } from 'features/common/bulk_selection/selectors';
import { clearBulkData } from 'features/common/bulk_selection/slice';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, arrowAltRightSolid } from 'features/common/lp_icon';
import { awaitRequestFinish } from 'lib/api';
import { ApiError } from 'lib/api/types';
import { pluralizeUnitWithCountIgnoringZero } from 'lib/helpers';

interface MoveItemButtonProps {
  disabled: boolean;
  fetchItems?: () => void;
  setLoadingIndicator?: () => void;
}

const MoveItemsButton = ({
  disabled,
  selectedItemIds,
  fetchItems = noop,
  setLoadingIndicator = noop,
}: MoveItemButtonProps & { selectedItemIds: ReadonlyArray<number> }) => {
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [moveError, setMoveError] = useState<ApiError | undefined>(undefined);

  const openMoveModal = () => setIsMoveModalOpen(true);

  const closeMoveModal = useCallback(() => {
    setIsMoveModalOpen(false);
  }, []);

  const handleMoveAction = useMoveItems({
    closeMoveModal,
    fetchItems,
    selectedItemIds,
    setMoveError,
    setLoadingIndicator,
  });

  const headerForMultiItems =
    selectedItemIds.length === 1
      ? {}
      : {
          header: (
            <span style={{ fontSize: '1.5rem' }}>
              Moving {pluralizeUnitWithCountIgnoringZero('Item', selectedItemIds.length)}
            </span>
          ),
        };

  const firstItemId = selectedItemIds[0];

  return (
    <>
      <BulkOptionPopup content={BulkOptionPopupContent.Move}>
        <Button
          className="icon"
          onClick={openMoveModal}
          data-testid={'task-move-button'}
          disabled={disabled}
          size="small"
        >
          <LpIcon icon={arrowAltRightSolid} />
        </Button>
      </BulkOptionPopup>

      {isMoveModalOpen && firstItemId && (
        <ItemMoveModal
          errorBody={moveError}
          itemId={firstItemId}
          handleMove={handleMoveAction}
          onClose={closeMoveModal}
          {...headerForMultiItems}
        />
      )}
    </>
  );
};

export const MoveBulkItemsButton = ({ disabled, fetchItems }: MoveItemButtonProps) => {
  const selectedItemIds = useSelector(getBulkSelectedItemIds);

  return <MoveItemsButton disabled={disabled} fetchItems={fetchItems} selectedItemIds={selectedItemIds} />;
};

export const ItemPanelMoveItemButton = ({
  disabled,
  itemPanelId,
  setLoadingIndicator,
}: MoveItemButtonProps & { itemPanelId: number }) => {
  const selectedItemIds = [itemPanelId];

  return (
    <MoveItemsButton disabled={disabled} selectedItemIds={selectedItemIds} setLoadingIndicator={setLoadingIndicator} />
  );
};

export const useMoveItems = ({
  closeMoveModal,
  fetchItems,
  selectedItemIds,
  setMoveError,
  setLoadingIndicator = noop,
}: {
  closeMoveModal: () => void;
  fetchItems: () => void;
  selectedItemIds: ReadonlyArray<number>;
  setMoveError: Dispatch<SetStateAction<ApiError | undefined>>;
  setLoadingIndicator?: () => void;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const handleMove = useCallback(
    (parentId: number, relativePriority: RelativePriorityType, closeModal: () => void) => {
      const updateBody: ReadonlyArray<Mutable<Partial<Item> & { id: number }>> = selectedItemIds.map((id) => ({
        ...ItemDao.id(id),
        parent: ItemDao.id(parentId),
        relativePriority: { type: relativePriority },
      }));

      let prevItem = updateBody[0];

      for (let i = 1; i < updateBody.length; i++) {
        const updateBodyItem = updateBody[i];
        const previousItemId = prevItem?.id;

        if (updateBodyItem && previousItemId) {
          updateBodyItem.relativePriority = {
            type: RelativePriorityType.AFTER,
            id: previousItemId,
          };
        }
        prevItem = updateBody[i];
      }

      const { uuid } = dispatch(ItemDao.updateBulk({ workspaceId, organizationId }, updateBody));

      setLoadingIndicator();

      dispatch(
        awaitRequestFinish<ReadonlyArray<Item>>(uuid, {
          onError: ({ errors }) => setMoveError(errors[0]),
          onSuccess: () => {
            const moveCleanUp = new Promise((resolutionFunc) => {
              resolutionFunc(closeModal());
            });

            moveCleanUp.finally(() => {
              fetchItems();
              dispatch(clearBulkData());
            });
          },
        })
      );
    },
    [dispatch, fetchItems, organizationId, selectedItemIds, setLoadingIndicator, setMoveError, workspaceId]
  );

  return useCallback(
    (parentId: number, location: RelativePriorityType) => handleMove(parentId, location, closeMoveModal),
    [closeMoveModal, handleMove]
  );
};
