import { ReactNode } from 'react';

interface ComponentJoinProps {
  readonly children?: ReadonlyArray<ReactNode>;
  readonly separator?: string;
}

export const ComponentJoin = ({ children, separator = ', ' }: ComponentJoinProps) => {
  if (!children) {
    return null;
  }

  if (children.length == 0) {
    return null;
  }

  return <>{children.reduce((prev, current) => [prev, separator, current])}</>;
};
