import { Widget } from 'daos/model_types';
import { LpIcon, noteRegular } from 'features/common/lp_icon';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LinkToNoteDropdown } from 'features/dashboards_v2/widget_settings/link_to_dropdown';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { Title } from 'features/dashboards_v2/widget_settings/title';

export const LinkedNoteWidgetSettings = ({ widget }: { widget: Widget }) => {
  const header = (
    <>
      <LpIcon icon={noteRegular} /> Linked Note
    </>
  );

  const content = (
    <>
      <Title widgetId={widget.id} />
      <LocationSetting disablePackageStatusSelection />
      <LinkToNoteDropdown widget={widget} />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
