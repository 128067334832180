import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStateExtraReducer } from 'redux/root_actions';

interface TimesheetsReviewState {
  readonly userIds: Array<number>;
  readonly groupIds: Array<number>;
}

export const initialState: TimesheetsReviewState = {
  userIds: [],
  groupIds: [],
};

const timesheetsReviewSlice = createSlice({
  name: 'timesheets_review',
  initialState,
  reducers: {
    setTimesheetReviewUserIds: (state, action: PayloadAction<Array<number>>) => {
      state.userIds = action.payload;
    },
    setTimesheetReviewGroupIds: (state, action: PayloadAction<Array<number>>) => {
      state.groupIds = action.payload;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { setTimesheetReviewUserIds, setTimesheetReviewGroupIds } = timesheetsReviewSlice.actions;

export default timesheetsReviewSlice.reducer;
