import classNames from 'classnames';
import { useSelector } from 'react-redux';

import TaskIconMaker from 'containers/shared/task_icon_maker';
import { ItemType, PackageStatus } from 'daos/enums';
import { packageStatusIconByPackageStatus } from 'daos/pkg';
import {
  LpIcon,
  cubeSolid,
  folderOpenSolid,
  globeSolid as workspaceIcon,
  questionCircleSolid,
  SizeProp,
  folderClosedSolid,
  circleSmallSolid,
} from 'features/common/lp_icon';
import { getDeletedItemForId } from 'redux/entities/selectors/deleted_items';
import { getItemForId } from 'redux/entities/selectors/item';
import { slate700 } from 'style/variables';
import './item_icon.scss';

interface BaseProps {
  className?: string;
  color?: string;
  hasToolTip?: boolean;
  open?: boolean;
  size?: SizeProp;
  isDeletedItem?: boolean;
}

interface ItemIconProps extends BaseProps {
  itemId?: number;
}

interface PackageStatusIconProps extends BaseProps {
  packageStatus?: PackageStatus;
}

const ItemIcon = ({
  itemId,
  className,
  color: propColor,
  hasToolTip,
  open,
  size = '1x',
  isDeletedItem,
  ...rest
}: ItemIconProps) => {
  const activeItem = useSelector((state) => (!isDeletedItem && itemId ? getItemForId(state, itemId) : undefined));
  const deletedItem = useSelector((state) =>
    isDeletedItem && itemId ? getDeletedItemForId(state, itemId) : undefined
  );
  const item = isDeletedItem ? deletedItem : activeItem;

  if (!item) {
    return null;
  }

  const { color: itemColor, itemType, packageStatus, taskStatus } = item;
  const color = propColor ?? itemColor;
  const iconColor = color ? `#${color}` : undefined;
  const commonProps = {
    className: getClassName({ size, itemType, className, color, packageStatus }),
    ...rest,
  };

  switch (itemType) {
    case ItemType.ASSIGNMENTS:
      return <LpIcon icon={circleSmallSolid} color={slate700} size={size} {...commonProps} />;
    case ItemType.FOLDERS:
      return (
        <LpIcon color={iconColor} icon={open ? folderOpenSolid : folderClosedSolid} size={size} {...commonProps} />
      );
    case ItemType.PACKAGES:
      return (
        <LpIcon
          color={iconColor}
          icon={(packageStatus && packageStatusIconByPackageStatus[packageStatus]) ?? cubeSolid}
          size={size}
          {...commonProps}
        />
      );
    case ItemType.PROJECTS:
      return (
        <LpIcon
          color={iconColor}
          icon={open ? folderOpenSolid : folderClosedSolid}
          transform="grow-2"
          size={size}
          {...commonProps}
        />
      );
    case ItemType.TASKS:
      return <TaskIconMaker className={className} taskStatusId={taskStatus?.id} size={size} hasToolTip={hasToolTip} />;
    case ItemType.WORKSPACE_ROOTS:
      return <LpIcon color={iconColor ?? slate700} icon={workspaceIcon} size={size} {...commonProps} />;
    default:
      return <LpIcon color="red" icon={questionCircleSolid} {...commonProps} size={size} />;
  }
};

export default ItemIcon;

export function PackageStatusIcon({
  packageStatus,
  className,
  color,
  hasToolTip,
  open,
  size,
  isDeletedItem,
  ...rest
}: PackageStatusIconProps) {
  if (!packageStatus) {
    return null;
  }

  const iconColor = color ? `#${color}` : undefined;
  const commonProps = {
    className: getClassName({ size, className, color, packageStatus }),
    ...rest,
  };

  return (
    <LpIcon
      color={iconColor}
      icon={packageStatusIconByPackageStatus[packageStatus] ?? cubeSolid}
      size={size}
      {...commonProps}
    />
  );
}

function getClassName({
  size,
  itemType,
  className,
  color,
  packageStatus,
}: {
  size?: SizeProp;
  itemType?: ItemType;
  className?: string;
  color?: string | null;
  packageStatus?: PackageStatus | null;
}) {
  return classNames(
    'lp-item-icon icon',
    size,
    itemType,
    className,
    color && 'custom-color',
    packageStatus && `package-status-${packageStatus}`
  );
}
