import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { getOrganizationHubFilteredOrgUsers } from 'features/organization_directory/selectors';
import { OrgUsersViewOption } from 'features/organization_directory/users/table/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { frontend } from 'lib/urls';
import { getWorkspaceUsersById } from 'redux/entities/selectors/user';

import OrganizationDirectoryUsersNameCell from './name_cell';
import { wsUsersWorkspaceTallies } from './workspace_users_user_tallies';

interface OrganizationDirectoryUsersRowProps {
  view: OrgUsersViewOption;
}

export const OrganizationDirectoryUsersRow = ({ view }: OrganizationDirectoryUsersRowProps) => {
  const { formatLocalDate } = useLocalizedFormats();
  const wsUsersById = useSelector(getWorkspaceUsersById);
  const workspaceTalliesByUserId = wsUsersWorkspaceTallies(wsUsersById);
  const organizationId = useSelector(getCurrentOrganizationId);
  const filteredOrgUsers = useSelector(getOrganizationHubFilteredOrgUsers);

  return (
    <>
      {filteredOrgUsers.map((orgUser) => {
        return (
          <Table.Row key={orgUser.id}>
            <Table.Cell>
              <span>
                <LpLink to={frontend.organizationHubUser.url({ organizationId, organizationUserId: orgUser.id })}>
                  <UserAvatar orgUserId={orgUser.id} size={AvatarSize.Medium} />
                  <span className={classNames({ 'evicted-user': !!orgUser.disconnectedAt })}>{orgUser.username}</span>
                </LpLink>
              </span>
            </Table.Cell>
            <OrganizationDirectoryUsersNameCell
              userId={orgUser.user.id}
              isAdmin={orgUser.admin}
              isEvicted={!!orgUser.disconnectedAt}
            />
            {view === 'active' && (
              <>
                <Table.Cell textAlign="center">{workspaceTalliesByUserId.get(orgUser.user.id)}</Table.Cell>
                <Table.Cell>{formatLocalDate(orgUser.lastAccessedAt ?? orgUser.createdAt)}</Table.Cell>
                <Table.Cell>{formatLocalDate(orgUser.createdAt)}</Table.Cell>
              </>
            )}
            {view === 'disconnected' && (
              <>
                <Table.Cell collapsing className="evicted-user">
                  {formatLocalDate(orgUser.disconnectedAt ?? '')}
                </Table.Cell>
              </>
            )}
          </Table.Row>
        );
      })}
    </>
  );
};
