import { WidgetType, WidgetSize, IntakeWidgetType, widgetTypeDisplay, ListWidgetType } from 'daos/enums';
import { WidgetGalleryOptionType } from 'features/dashboards_v2/gallery/add_widget_modal/types';
import assignmentListPreview from 'static/img/widget_gallery/assignment_list.png';
import packageListPreview from 'static/img/widget_gallery/package_list.png';
import peopleListPreview from 'static/img/widget_gallery/people_list.png';
import projectListPreview from 'static/img/widget_gallery/project_list.png';
import subFolderListPreview from 'static/img/widget_gallery/sub_folder_list.png';
import taskListPreview from 'static/img/widget_gallery/task_list.png';
import boardSummaryPreview from 'static/img/widget_gallery/widget_board.jpg';
import changesPreview from 'static/img/widget_gallery/widget_changes.jpg';
import widgetImagePreview from 'static/img/widget_gallery/widget_image.jpg';
import insightsPreview from 'static/img/widget_gallery/widget_insights.png';
import intakeProjectPreview from 'static/img/widget_gallery/widget_intake_project.png';
import intakeTaskPreview from 'static/img/widget_gallery/widget_intake_task.png';
import linkedNotePreview from 'static/img/widget_gallery/widget_linked_note.jpg';
import metricsTallyPreview from 'static/img/widget_gallery/widget_metrics.jpg';
import dashboardNotePreview from 'static/img/widget_gallery/widget_note.jpg';
import propertiesSummaryPreview from 'static/img/widget_gallery/widget_properties.jpg';
import scheduleSummaryPreview from 'static/img/widget_gallery/widget_schedule.jpg';
import workloadPreview from 'static/img/widget_gallery/widget_workload.jpg';

export const taskIntake = {
  key: WidgetGalleryOptionType.IntakeTask,
  type: WidgetType.Intake,
  name: 'Task Intake Form',
  preview: intakeTaskPreview,
  config: { intakeType: IntakeWidgetType.Task },
  widgetSize: WidgetSize.Half,
};
export const projectIntake = {
  key: WidgetGalleryOptionType.IntakeProject,
  type: WidgetType.Intake,
  name: 'Project Intake Form',
  preview: intakeProjectPreview,
  config: { intakeType: IntakeWidgetType.Project },
  widgetSize: WidgetSize.Half,
};
export const scheduleSummary = {
  key: WidgetGalleryOptionType.ScheduleSummary,
  type: WidgetType.ScheduleSummary,
  name: widgetTypeDisplay[WidgetType.ScheduleSummary],
  preview: scheduleSummaryPreview,
  config: {},
};
export const workload = {
  key: WidgetGalleryOptionType.Workload,
  type: WidgetType.Workload,
  name: widgetTypeDisplay[WidgetType.Workload],
  preview: workloadPreview,
  config: {},
};
export const boardSummary = {
  key: WidgetGalleryOptionType.BoardSummary,
  type: WidgetType.BoardSummary,
  name: widgetTypeDisplay[WidgetType.BoardSummary],
  preview: boardSummaryPreview,
  config: {},
};
export const insights = {
  key: WidgetGalleryOptionType.Insights,
  type: WidgetType.Insights,
  name: widgetTypeDisplay[WidgetType.Insights],
  preview: insightsPreview,
  config: {},
};
export const changes = {
  key: WidgetGalleryOptionType.Changes,
  type: WidgetType.Changes,
  name: widgetTypeDisplay[WidgetType.Changes],
  preview: changesPreview,
  config: {},
};
export const propertiesSummary = {
  key: WidgetGalleryOptionType.PropertiesSummary,
  type: WidgetType.PropertiesSummary,
  name: widgetTypeDisplay[WidgetType.PropertiesSummary],
  preview: propertiesSummaryPreview,
  config: {},
};
export const dashboardNote = {
  key: WidgetGalleryOptionType.DashboardNote,
  type: WidgetType.DashboardNote,
  name: widgetTypeDisplay[WidgetType.DashboardNote],
  preview: dashboardNotePreview,
  config: {},
};
export const linkedNote = (noteFieldId?: number) => ({
  key: WidgetGalleryOptionType.LinkedNote,
  type: WidgetType.LinkedNote,
  name: widgetTypeDisplay[WidgetType.LinkedNote],
  preview: linkedNotePreview,
  config: { customFieldId: noteFieldId },
});
export const image = {
  key: WidgetGalleryOptionType.Image,
  type: WidgetType.Image,
  name: widgetTypeDisplay[WidgetType.Image],
  preview: widgetImagePreview,
  config: {},
};
export const metricsTally = {
  key: WidgetGalleryOptionType.MetricsTally,
  type: WidgetType.MetricsTally,
  name: widgetTypeDisplay[WidgetType.MetricsTally],
  preview: metricsTallyPreview,
  config: {},
};
export const taskList = {
  key: WidgetGalleryOptionType.TaskList,
  type: WidgetType.List,
  name: 'Task List',
  preview: taskListPreview,
  config: { listType: ListWidgetType.Tasks },
};
export const projectList = {
  key: WidgetGalleryOptionType.ProjectList,
  type: WidgetType.List,
  name: 'Project List',
  preview: projectListPreview,
  config: { listType: ListWidgetType.Projects },
};
export const packageList = {
  key: WidgetGalleryOptionType.PackageList,
  type: WidgetType.List,
  name: 'Package List',
  preview: packageListPreview,
  config: { listType: ListWidgetType.Packages },
};
export const folderList = {
  key: WidgetGalleryOptionType.FolderList,
  type: WidgetType.List,
  name: 'Sub-Folder List',
  preview: subFolderListPreview,
  config: { listType: ListWidgetType.Folders },
};
export const assignmentList = {
  key: WidgetGalleryOptionType.AssignmentList,
  type: WidgetType.List,
  name: 'Assignment List',
  preview: assignmentListPreview,
  config: { listType: ListWidgetType.Assignment },
};
export const peopleList = {
  key: WidgetGalleryOptionType.PeopleList,
  type: WidgetType.List,
  name: 'People List',
  preview: peopleListPreview,
  config: { listType: ListWidgetType.Users },
};
