import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Input, InputProps, Popup, Table } from 'semantic-ui-react';

import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

export const nameAlreadyExistsError = 'Name already exists. Enter a new one.';

export const SchemeNameInput = () => {
  const { setFieldError, values, setFieldValue, errors } = useFormikContext<JiraProjectModalFormValues>();
  const storyPointSchemeNameError = errors[JiraProjectModalFormFields.StoryPointSchemeName];
  const storyPointSchemeName = values[JiraProjectModalFormFields.StoryPointSchemeName];
  const nameAlreadyExists = storyPointSchemeNameError === nameAlreadyExistsError;

  const handleChange = (_: React.SyntheticEvent, { value }: InputProps) => {
    setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, value);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={<span>{'Story Point Scheme Name'}</span>} />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-story-point-scheme-name">
        <Popup
          content={storyPointSchemeNameError}
          className="jira-project-modal__content-estimate-mapping-story-point-scheme-name-popup"
          open={nameAlreadyExists}
          position="right center"
          hoverable={true}
          trigger={
            <StoryPointSchemeNameInput
              storyPointSchemeName={storyPointSchemeName}
              handleChange={handleChange}
              error={!!storyPointSchemeNameError}
            />
          }
        />
      </Table.Cell>
    </JiraProjectModalTableRow>
  );
};

const StoryPointSchemeNameInput = ({
  handleChange,
  storyPointSchemeName,
  error,
}: {
  handleChange: (_: React.SyntheticEvent, { value }: InputProps) => void;
  storyPointSchemeName: string;
  error: boolean;
}) => {
  const inputRef = useRef<Input>(null);
  useEffect(() => {
    if (error && inputRef.current) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <Input
      ref={inputRef}
      onChange={handleChange}
      value={storyPointSchemeName}
      name={JiraProjectModalFormFields.StoryPointSchemeName}
      error={error}
    />
  );
};
