import { writeUrlToClipboard } from 'features/common/portable_link_handler/copy_rich_text';
import { DashboardPortableLink } from 'lib/clipboard/portable_links';

import { PortableLinkButton } from './portable_link_button';

interface PortableDashboardLinkProps {
  dashboardName: string;
  dashboardId: string;
  workspaceId: number;
  organizationId: number;
  showText?: boolean;
}

export const PortableDashboardLinkButton = ({
  dashboardName,
  dashboardId,
  workspaceId,
  organizationId,
  showText = false,
}: PortableDashboardLinkProps) => {
  const copyLink = () => getCopyLink(dashboardName, dashboardId, workspaceId, organizationId);

  return <PortableLinkButton primary copyLinkAction={copyLink} isMultipleSelection={false} showText={showText} />;
};

const getCopyLink = (dashboardName: string, dashboardId: string, workspaceId: number, organizationId: number) =>
  writeUrlToClipboard(new DashboardPortableLink(dashboardId, organizationId, workspaceId, dashboardName));
