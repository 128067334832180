import { Event } from '@bugsnag/js';
import { Component, ErrorInfo } from 'react';

import bugsnagClient from 'lib/bugsnag';

import { ErrorPage } from './error_page';

interface Props {
  children?: React.ReactNode;
  scope?: string;
}

interface State {
  error: Error | null;
}

const getAddBugSnagMetadata = (errorInfo: ErrorInfo, cause?: any) => (event: Event) => {
  event.addMetadata('react', {
    componentStack: errorInfo.componentStack,
  });

  if (cause) {
    event.addMetadata('cause', cause);
  }
};
export class ErrorBoundary extends Component<Props, State> {
  readonly state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): State {
    return { error: error };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    const RumGlobal = window.DD_RUM;

    if (error instanceof Error) {
      bugsnagClient?.notify(error, getAddBugSnagMetadata(errorInfo));

      if (RumGlobal && RumGlobal.addError) {
        RumGlobal.addError(error, getAddBugSnagMetadata(errorInfo));
      }
    } else {
      const message = error.message || error.toString();
      const wrappedError = new Error(message);

      bugsnagClient?.notify(wrappedError, getAddBugSnagMetadata(errorInfo, error));

      if (RumGlobal && RumGlobal.addError) {
        RumGlobal.addError(wrappedError, getAddBugSnagMetadata(errorInfo));
      }
    }
  }

  reset = () => {
    this.setState({ error: null });
  };

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorPage reset={this.reset} />;
    }
    return children;
  }
}
