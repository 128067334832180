import { request } from 'lib/api';

import { CustomFieldType } from './enums';
import { CustomField } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

export function isPicklistTypeField(field: CustomField): boolean {
  switch (field.fieldType) {
    case CustomFieldType.MULTI_PICKLIST:
    case CustomFieldType.PICKLIST:
      return true;
    default:
      return false;
  }
}

export function isMultipleValueField(field: CustomField): boolean {
  switch (field.fieldType) {
    case CustomFieldType.MULTI_PICKLIST:
    case CustomFieldType.MULTI_ITEM:
    case CustomFieldType.MULTI_LINK:
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.MULTI_USER:
      return true;
    default:
      return false;
  }
}

const { createBody, updateBody, resource } = defineModel({
  apiType: 'fields',
  relationships: ['picklistChoices'],
  type: 'CUSTOM_FIELD',
});

const { CUSTOM_FIELD, resourceId } = resource;

interface CustomFieldsParams {
  organizationId: number;
  workspaceId: number;
}

interface SingleCustomFieldParams extends CustomFieldsParams {
  fieldId: number;
}

const customFieldCreate = (params: CustomFieldsParams, field: Partial<CustomField>) =>
  request(backend.fields.url(params), CUSTOM_FIELD, createBody(field));

const customFieldUpdate = (params: SingleCustomFieldParams, field: Partial<CustomField>) =>
  request(backend.field.url(params), CUSTOM_FIELD, updateBody(params.fieldId, field));

export const CustomFieldDao = {
  create: customFieldCreate,
  id: resourceId,
  update: customFieldUpdate,
} as const;
