import { useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentOrganizationId } from 'features/common/current/selectors';
import useSlackIntegrationsForOrg from 'features/integrations/slack/slack_integration_administration/use_slack_integrations_for_org';
import SlackIntegrationTopNavHeader from 'features/integrations/slack/slack_integration_top_nav_header';
import useOrganizationFetchOnOrganizationModified from 'hooks/use_organization_fetch_on_organization_modified';
import { getCurrentOrganizationUserId } from 'redux/entities/selectors/user';

import SlackIntegrationAdministrationActions from './slack_integration_administration_actions';
import SlackIntegrationAdministrationTable from './slack_integration_administration_table';
import './slack_integration_administration.scss';

const SlackIntegrationAdministration = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const organizationUserId = useSelector(getCurrentOrganizationUserId);
  const { disconnect, isLoading, orgUserSlackIntegrations } = useSlackIntegrationsForOrg(
    organizationId,
    organizationUserId
  );
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [filterValue, setFilterValue] = useState<RegExp>();

  useOrganizationFetchOnOrganizationModified();

  if (!organizationId || !organizationUserId) {
    return null;
  }

  const filteredSlackIntegrations = filterValue
    ? orgUserSlackIntegrations.filter(
        ({ name, slackWorkspaceName }) => name.match(filterValue) || slackWorkspaceName.match(filterValue)
      )
    : orgUserSlackIntegrations;

  return (
    <div className="slack-integration-administration">
      <SlackIntegrationTopNavHeader />
      <SlackIntegrationAdministrationActions
        selectedRecordIds={selectedRows}
        onFilterTextChange={(value) => {
          setFilterValue(new RegExp(`^${value}`, 'i'));
          setSelectedRows(new Set());
        }}
        onFilterTextClear={() => {
          setFilterValue(undefined);
          setSelectedRows(new Set());
        }}
        onDelete={() => {
          disconnect(Array.from(selectedRows));
          setSelectedRows(new Set());
        }}
        onReset={() => setSelectedRows(new Set())}
      />
      <SlackIntegrationAdministrationTable
        isLoading={isLoading}
        selectedRows={selectedRows}
        slackIntegrations={filteredSlackIntegrations}
        onSelectedRowsChange={(selectedRows) => setSelectedRows(selectedRows)}
      />
    </div>
  );
};

export default SlackIntegrationAdministration;
