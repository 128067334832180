import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, exclamationTriangleSolid } from 'features/common/lp_icon';
import { frontend } from 'lib/urls';
import { lpRed } from 'style/variables';

export const CostCodeError = () => {
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));

  return (
    <div className="jira-project-modal__content-info-credentials-error">
      <div>
        <LpIcon className="warning-icon" color={lpRed} icon={exclamationTriangleSolid} size="sm" />
        Cost Code is required for logging time.
      </div>
      This can be modified in the{' '}
      <LpLink
        target="_blank"
        rel="noopener noreferrer"
        to={frontend.customizeWorkspaceSettings.url({ organizationId, workspaceId })}
      >
        Workspace Settings
      </LpLink>
    </div>
  );
};
