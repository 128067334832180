import { TrialStatus } from 'daos/enums';

const premiumFeaturesTrialAvailableStatuses: ReadonlySet<TrialStatus> = new Set([
  TrialStatus.AvailableSelfService,
  TrialStatus.AvailableContactSupport,
]);

export const isPremiumFeaturesTrialAvailable = (trialStatus: TrialStatus | undefined) => {
  if (!trialStatus) {
    return false;
  }

  return premiumFeaturesTrialAvailableStatuses.has(trialStatus);
};
