import { ReactNode } from 'react';
import { Button } from 'semantic-ui-react';

import LpModal from './lp_modal';

interface ContentModalWithSingleButtonProps {
  onClose: () => void;
  children: ReactNode;
  buttonText?: string;
  dataTestId?: string;
}

const ContentModalWithSingleCloseButton = ({
  onClose,
  children,
  buttonText = 'Ok',
  dataTestId = 'content-modal-with-single-close-button',
}: ContentModalWithSingleButtonProps) => (
  <LpModal
    onClose={onClose}
    dataTestId={dataTestId}
    size="tiny"
    content={children}
    actions={<Button content={buttonText} onClick={onClose} primary />}
  />
);

export default ContentModalWithSingleCloseButton;
