import { StatusFilterGroups } from 'daos/enums';
import { LocationFilterTaskSettings } from 'daos/types';
import { CustomTaskFilters } from 'features/common/custom_item_filter/types';
import { ControlledTaskStatusFilterDropdown } from 'features/common/inputs/dropdowns/task_status_filter_dropdown';

export const TaskFilter = ({
  dataIsAllActive = false,
  disabled = false,
  onChange,
  value,
}: {
  dataIsAllActive?: boolean;
  disabled?: boolean;
  onChange: (value: LocationFilterTaskSettings) => void;
  value: LocationFilterTaskSettings;
}) => {
  const handleChange = (newValue: CustomTaskFilters) => {
    onChange({ ...value, ...newValue });
  };

  return (
    <ControlledTaskStatusFilterDropdown
      dataIsAllActive={dataIsAllActive}
      defaultTaskStatus={dataIsAllActive ? StatusFilterGroups.Active : StatusFilterGroups.All}
      disabled={disabled}
      onChange={handleChange}
      value={value}
    />
  );
};
