import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';

import { UserType } from 'daos/enums';
import { OrganizationUser } from 'daos/model_types';
import { DefaultAvatarDisplay } from 'features/common/avatars/default_avatar';
import { useUserFirstLastOrUsername } from 'hooks/use_user_first_last_or_username';
import { getOrganizationUserForId, getUserForId } from 'redux/entities/selectors/user';

import customizeAvatarClassNames, { AvatarSize, AvatarMargin } from './avatar_helpers';

import './avatar_styles.scss';

function canUploadAvatar(
  userType: UserType | undefined,
  avatarUrl: OrganizationUser['avatarUrl'] | undefined,
  isGuest: boolean | undefined
) {
  if (avatarUrl && !isGuest) {
    switch (userType) {
      case UserType.Member:
      case UserType.Resource:
        return true;
      case UserType.Placeholder:
        return false;
    }
  } else {
    return false;
  }
}

export const UserAvatar = ({
  className,
  isGuest,
  marginRight,
  orgUserId,
  round,
  size,
  withBorderRadius = true,
}: {
  className?: string;
  isGuest?: boolean;
  marginRight?: AvatarMargin | boolean;
  orgUserId: number;
  round?: boolean;
  size?: AvatarSize;
  withBorderRadius?: boolean;
}) => {
  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const displayName = useUserFirstLastOrUsername(orgUser?.user?.id) ?? '';
  const userType = useSelector((state) => (orgUser ? getUserForId(state, orgUser.user.id)?.userType : undefined));

  return (
    <UserAvatarRenderer
      avatarColor={orgUser?.avatarColor}
      avatarUrl={orgUser?.avatarUrl}
      className={className}
      displayName={displayName}
      isGuest={isGuest}
      marginRight={marginRight}
      round={round}
      size={size}
      userType={userType}
      withBorderRadius={withBorderRadius}
    />
  );
};

export const UserAvatarRenderer = ({
  avatarColor,
  avatarUrl,
  className,
  displayName = '',
  isGuest,
  marginRight,
  round,
  size,
  userType,
  withBorderRadius = true,
}: {
  avatarColor?: string | null;
  avatarUrl?: string | null;
  className?: string;
  displayName?: string;
  isGuest?: boolean;
  marginRight?: AvatarMargin | boolean;
  round?: boolean;
  size?: AvatarSize;
  userType?: UserType | undefined;
  withBorderRadius?: boolean;
}) => {
  return (
    <>
      {canUploadAvatar(userType, avatarUrl, isGuest) ? (
        <UserAvatarDisplay
          className={className}
          size={size}
          round={round}
          marginRight={marginRight}
          avatarUrl={avatarUrl ?? null}
          withBorderRadius={withBorderRadius}
        />
      ) : (
        <DefaultAvatarDisplay
          avatarColor={avatarColor}
          className={className}
          isGuest={isGuest}
          marginRight={marginRight}
          name={displayName}
          round={round}
          size={size}
          userType={userType}
          withBorderRadius={withBorderRadius}
        />
      )}
    </>
  );
};

export const UserAvatarDisplay = ({
  className,
  avatarUrl,
  marginRight,
  round,
  size,
  withBorderRadius,
}: {
  className?: string;
  marginRight?: AvatarMargin | boolean;
  round?: boolean;
  size?: AvatarSize;
  avatarUrl: OrganizationUser['avatarUrl'];
  withBorderRadius?: boolean;
}) => {
  return (
    <Image
      avatar
      className={customizeAvatarClassNames({ size, className, round, marginRight, withBorderRadius })}
      src={avatarUrl}
    />
  );
};
