import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { LibraryResourceOrderBy } from './enums';
import { LibraryResource } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { createBody, resource } = defineModel({
  apiType: 'libraryResources',
  type: 'LIBRARY_RESOURCE',
});

const { LIBRARY_RESOURCE, resourceId } = resource;

interface Includes {
  includeItem?: boolean;
  includeItemAncestors?: boolean;
  includeDashboards?: boolean;
  includeReports?: boolean;
}

const includes = ({ includeItem, includeDashboards, includeReports, includeItemAncestors }: Includes = {}) =>
  reduceIncludedOptions([
    includeItem && 'item',
    includeItemAncestors && 'item.ancestors',
    includeDashboards && 'dashboards',
    includeReports && 'reports',
  ]);

interface LibraryResourcesParams {
  organizationId: string | number;
  workspaceId: string | number;
}
interface SingleLibraryResourcesParams extends LibraryResourcesParams {
  libraryResourceId: string | number;
}

interface LibraryResourcesOptions {
  filter?: string;
  include?: Includes;
  query?: { limit?: number; order?: LibraryResourceOrderBy };
}

type CreateLibraryResource = Pick<
  LibraryResource,
  'name' | 'color' | 'packageStatus' | 'itemId' | 'libraryResourceType' | 'listType'
>;

const libraryResourceFetchAll = (
  params: LibraryResourcesParams,
  { include, filter, query }: LibraryResourcesOptions = {}
) =>
  request(backend.libraryResources.url(params, { filter, include: includes(include), query }), LIBRARY_RESOURCE, {
    method: HttpMethod.GET,
  });

const libraryResourceCreate = (params: LibraryResourcesParams, libraryResource: CreateLibraryResource) =>
  request(backend.libraryResources.url(params), LIBRARY_RESOURCE, createBody(libraryResource));

const libraryResourceDuplicate = (params: SingleLibraryResourcesParams) =>
  request(backend.libraryResourceDuplicate.url(params), LIBRARY_RESOURCE, { method: HttpMethod.POST });

export const LibraryResourceDao = {
  duplicate: libraryResourceDuplicate,
  fetchAll: libraryResourceFetchAll,
  create: libraryResourceCreate,
  id: resourceId,
} as const;
