import { useSelector } from 'react-redux';

import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import { getCurrentUser } from 'features/common/current/selectors';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';

import { OrganizationWorkspaceForm } from './organization_workspace_form';

export const DisconnectedUserAccount = ({
  frontloadData,
}: {
  frontloadData: ({ organizations, providedOrgId, providedWsId }: FrontloadDataProps) => void;
}) => {
  const user = useSelector(getCurrentUser);

  if (!user) {
    return <LpOverlayLoader />;
  }

  return (
    <div className="disconnected-user__restart-account">
      <h1>Restart Account</h1>
      <p>This will create a new Organization &amp; Workspace on the FREE plan under your user login.</p>
      <OrganizationWorkspaceForm frontloadData={frontloadData} userEmail={user.email} />
    </div>
  );
};
