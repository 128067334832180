export enum OutpostLocation {
  Academy = 'academy',
  Collections = 'collections',
  Dashboards = 'dashboards',
  MemberProfile = 'memberProfile',
  MyFavorites = 'myFavorites',
  Workspace = 'workspace',

  Profiles = 'profiles',

  workspaceLibrary = 'workspaceLibrary',
  collectionLibrary = 'collectionLibrary',
  packageLibrary = 'packageLibrary',
  projectLibrary = 'projectLibrary',

  Groups = 'groups',
  GroupTaskBoard = 'groupTaskBoard',
  GroupProjectAccess = 'groupProjectAccess',
  GroupMembers = 'groupMembers',
  GroupWorkload = 'groupWorkload',
  GroupRolesAndRules = 'groupRolesAndRules',

  MembersActive = 'membersActive',
  MembersNotActive = 'membersNotActive',
  MemberAccessGroups = 'memberAccessGroups',
  MemberAccessProjects = 'memberAccessProjects',
  MemberAdd = 'memberAdd',
  MemberAccessRolesAndRules = 'memberAccessRolesAndRules',
  MemberAvailability = 'memberAvailability',
  MemberBoard = 'memberBoard',
  MemberChanges = 'memberChanges',
  MemberSearch = 'memberSearch',
  MemberTimesheet = 'memberTimesheet',
  MemberWorkload = 'memberWorkload',

  PlaceholdersActive = 'placeholdersActive',
  PlaceholdersNotActive = 'placeholdersNotActive',

  PlaceholderAdd = 'placeholderAdd',
  PlaceholderProfile = 'placeholderProfile',

  ResourcesActive = 'resourcesActive',
  ResourcesNotActive = 'resourcesNotActive',

  ResourceAdd = 'resourceAdd',
  ResourceAvailability = 'resourceAvailability',
  ResourceBoard = 'resourceBoard',
  ResourceTimesheet = 'resourceTimesheet',
  ResourceProfile = 'resourceProfile',
  ResourceSearch = 'resourceSearch',
  ResourceWorkload = 'resourceWorkload',

  MyWork = 'myWork',
  MyWorkAvailability = 'myWorkAvailability',
  MyWorkAccessGroup = 'myWorkAccessGroup',
  MyWorkAccessProject = 'myWorkAccessProject',
  MyWorkAccessRolesAndRules = 'myWorkAccessRolesAndRules',
  MyWorkMyBoard = 'myWorkMyBoard',
  MyWorkMyChanges = 'myWorkMyChanges',
  MyWorkMySearch = 'myWorkMySearch',
  MyWorkMyTimesheet = 'myWorkMyTimesheet',

  WorkspaceSettings = 'workspaceSettings',
  CustomTaskStatus = 'customTaskStatus',
  CustomDataField = 'customDataField',
  CostCode = 'costCode',

  OrganizationAccountManagement = 'organizationAccountManagement',
  OrganizationUsersActive = 'organizationUsersActive',
  OrganizationUsersNotActive = 'organizationUsersNotActive',
  OrganizationUsersInvitesPending = 'organizationUsersInvitesPending',
  OrganizationAdminsActive = 'organizationAdminsActive',
  OrganizationUser = 'organizationUser',
  OrganizationWorkspaces = 'organizationWorkspaces',

  UpgradePlan = 'upgradePlan',
  DowngradePlan = 'downgradePlan',

  RateSheets = 'rateSheets',
  RateSheetRules = 'rateSheetRules',
  RateSheetProjectsUsing = 'rateSheetProjectsUsing',

  ScheduledPortfolio = 'scheduledPortfolio',
  ScheduledPortfolioChanges = 'scheduledPortfolioChanges',
  ScheduledPortfolioGrid = 'scheduledPortfolioGrid',
  ScheduledPortfolioWorkload = 'scheduledPortfolioWorkload',
  ScheduledPortfolioSearch = 'scheduledPortfolioSearch',

  ScheduledPackage = 'scheduledPackage',
  ScheduledPackageBoard = 'scheduledPackageBoard',
  ScheduledPackageChanges = 'scheduledPackageChanges',
  ScheduledPackageDashboard = 'scheduledPackageDashboard',
  ScheduledPackageGrid = 'scheduledPackageGrid',
  ScheduledPackageSearch = 'scheduledPackageSearch',
  ScheduledPackageWorkload = 'scheduledPackageWorkload',

  ScheduledProject = 'scheduledProject',
  ScheduledProjectBoard = 'scheduledProjectBoard',
  ScheduledProjectChanges = 'scheduledProjectChanges',
  ScheduledProjectDashboard = 'scheduledProjectDashboard',
  ScheduledProjectGrid = 'scheduledProjectGrid',
  ScheduledProjectSearch = 'scheduledProjectSearch',
  ScheduledProjectWorkload = 'scheduledProjectWorkload',

  SlackConfig = 'slackConfig',
  SlackConnections = 'slackConnections',

  TimesheetExport = 'timesheetExport',
  TimesheetReview = 'timesheetReview',

  workspaceAccess = 'workspaceAccess',
  workspaceRolesAndRules = 'workspaceRolesAndRules',
}
