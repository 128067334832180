import { useSelector } from 'react-redux';

import { UserType } from 'daos/enums';
import { LpIcon, robotSolid, cactusSolid, passportSolid } from 'features/common/lp_icon';
import initials from 'lib/display_helpers/initials';
import { getOrganizationUserForId, getUserForId } from 'redux/entities/selectors/user';
import { trickyTeal, lpBrandWhite, slate600 } from 'style/variables';

import customizeAvatarClassNames, { AvatarSize, AvatarMargin } from './avatar_helpers';

const DefaultAvatar = ({
  className,
  name,
  round,
  size,
  marginRight,
  orgUserId,
  withBorderRadius = true,
}: {
  className?: string;
  name: string;
  size?: AvatarSize;
  round?: boolean;
  marginRight?: AvatarMargin | boolean;
  orgUserId?: number;
  withBorderRadius?: boolean;
}) => {
  const orgUser = useSelector((state) => (orgUserId ? getOrganizationUserForId(state, orgUserId) : undefined));
  const userType = useSelector((state) => (orgUser ? getUserForId(state, orgUser.user.id) : undefined))?.userType;
  const avatarColor = orgUser?.avatarColor;

  return (
    <DefaultAvatarDisplay
      avatarColor={avatarColor}
      name={name}
      className={className}
      round={round}
      size={size}
      marginRight={marginRight}
      userType={userType}
      withBorderRadius={withBorderRadius}
    />
  );
};

export const DefaultAvatarDisplay = ({
  avatarColor,
  className,
  isGuest = false,
  marginRight,
  name,
  round,
  size,
  userType,
  withBorderRadius = true,
}: {
  avatarColor?: string | null;
  className?: string;
  isGuest?: boolean;
  marginRight?: AvatarMargin | boolean;
  name: string;
  round?: boolean;
  size?: AvatarSize;
  userType: UserType | undefined;
  withBorderRadius?: boolean;
}) => {
  const defaultIcon = getDefaultAvatarIcon(isGuest, userType);
  const avatarDisplay = defaultIcon ? <LpIcon icon={defaultIcon} /> : initials(name);

  const color = getDefaultAvatarColor(isGuest, userType, avatarColor);
  const defaultAvatarStyles = {
    color: lpBrandWhite,
    backgroundColor: color,
  };

  return (
    <div
      data-testid="default-avatar"
      style={defaultAvatarStyles}
      className={customizeAvatarClassNames({ size, className, round, marginRight, withBorderRadius })}
    >
      {avatarDisplay}
    </div>
  );
};

export default DefaultAvatar;

function getDefaultAvatarIcon(isGuest: boolean, userType?: UserType) {
  if (isGuest) {
    return passportSolid;
  }

  switch (userType) {
    case UserType.Resource:
      return robotSolid;
    case UserType.Placeholder:
      return cactusSolid;
    case UserType.Member:
    default:
      return undefined;
  }
}

export function getDefaultAvatarColor(isGuest: boolean, userType?: UserType, avatarColor?: string | null) {
  if (isGuest) {
    return slate600;
  }

  const isNonMember = userType !== undefined && userType !== UserType.Member;
  if (isNonMember && avatarColor) {
    return `#${avatarColor}`;
  }

  return trickyTeal;
}
