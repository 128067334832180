import { useSelector } from 'react-redux';

import { LpButtonAsLink } from 'features/common/as_components';
import { chevronCircleLeftSolid, LpIcon } from 'features/common/lp_icon';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useGoBackToDashboardUrl } from 'features/dashboards_v2/common/use_go_back_to_dashboard_url';
import { useDefaultWidgetTitle } from 'features/dashboards_v2/widget/use_default_widget_title';

import './go_back_to_dashboard_button.scss';

const GoBackToDashboardButton = () => {
  const { widgetId } = useWidgetParams();
  const widget = useSelector((state) => getWidgetForId(state, widgetId));
  const defaultWidgetTitle = useDefaultWidgetTitle(widget);
  const widgetTitle = widget?.config.title ?? defaultWidgetTitle;

  const backToDashboardUrl = useGoBackToDashboardUrl();

  return (
    <LpButtonAsLink to={backToDashboardUrl} className="go-back-to-dashboard-button" primary={true}>
      <LpIcon className="go-back-to-dashboard-button__icon" icon={chevronCircleLeftSolid} size="lg" />
      <span className="go-back-to-dashboard-button__text">{widgetTitle}</span>
    </LpButtonAsLink>
  );
};

export default GoBackToDashboardButton;
