import { useSelector } from 'react-redux';

import { PropertyChange } from 'daos/types';
import { getCostCodeForId } from 'redux/entities/selectors/cost_code';
const ChangeCardCostCode = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const costCodeId = propertyChangeData.value;

  const costCode = useSelector((state) => (costCodeId ? getCostCodeForId(state, costCodeId) : undefined));

  return <>{costCode?.name ?? 'None'}</>;
};

export default ChangeCardCostCode;
