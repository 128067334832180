import { ReactNode } from 'react';
import { Popup } from 'semantic-ui-react';

export const LeftNavMenuItemPopup = ({ label, menuItem }: { label: ReactNode; menuItem: JSX.Element }) => {
  return (
    <Popup
      className={'lp-left-nav--collapsed__pop-up'}
      trigger={<span>{menuItem}</span>}
      content={<p>{label}</p>}
      basic
      size="tiny"
      hoverable
      position="right center"
    />
  );
};
