import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import { PlanGadgetSidebar } from 'features/organization_directory/manage_account/plan_gadget';
import { ActionsSection } from 'features/organization_directory/users/actions_section';
import { OrgUsersQuickFilterType } from 'features/organization_directory/users/quick_filter';
import OrganizationDirectoryUsersTable from 'features/organization_directory/users/table';

const OrgDirUsersDisconnected = () => {
  useSetupOutpost(OutpostLocation.OrganizationUsersNotActive);

  return (
    <div className="organization-directory__users-grid">
      <p>Not Active users are disconnected from all workspaces. Records are maintained for Dashboards and reporting.</p>
      <ActionsSection filterType={OrgUsersQuickFilterType.DISCONNECTED} showAdd={false} />
      <OrganizationDirectoryUsersTable view="disconnected" />
      <PlanGadgetSidebar includeContactSupport={true} />
    </div>
  );
};
export default OrgDirUsersDisconnected;
