import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStateExtraReducer } from 'redux/root_actions';

import { OutpostLocation } from './outpost_locations';

interface OutpostsState {
  readonly outpostLocation?: OutpostLocation;
}

export const initialState: OutpostsState = {
  outpostLocation: undefined,
};

const outpostSlice = createSlice({
  name: 'outpost',
  initialState,
  reducers: {
    setOutpostLocation: (state, action: PayloadAction<OutpostLocation | undefined>) => {
      state.outpostLocation = action.payload;
    },
    clearOutpostLocation: () => initialState,
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { clearOutpostLocation, setOutpostLocation } = outpostSlice.actions;

export default outpostSlice.reducer;
