import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { cloneDeep } from 'lodash/fp';

import { ItemType, StatusFilterGroups } from 'daos/enums';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import {
  CustomItemFilterItemType,
  CustomItemFilterLocation,
  OtherCustomProperties,
} from 'features/common/custom_item_filter/types';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import { resetStateExtraReducer } from 'redux/root_actions';

interface CustomItemTypeFilter {
  [ItemType.TASKS]: {
    customTaskStatusFilter: StatusFilterGroups;
    dropdownTaskStatusFilter: StatusFilterGroups;
    taskStatusIds: ReadonlyArray<number>;
    otherCustomProperties: OtherCustomProperties;
    statusFilterSelection: string;
  };
  [ItemType.PROJECTS]: {
    customProjectStatusFilter: FolderFilterStatus;
    dropdownProjectStatusFilter: FolderFilterStatus;
    otherCustomProperties: OtherCustomProperties;
    statusFilterSelection: string;
  };
}

interface CustomItemFilterState {
  [CustomItemFilterLocation.AssignmentGrid]: CustomItemTypeFilter;
  [CustomItemFilterLocation.DataGrid]: CustomItemTypeFilter;
  [CustomItemFilterLocation.Portfolio]: CustomItemTypeFilter;
  [CustomItemFilterLocation.Project]: CustomItemTypeFilter;
  [CustomItemFilterLocation.TaskBoard]: CustomItemTypeFilter;
  [CustomItemFilterLocation.TimesheetExport]: CustomItemTypeFilter;
  [CustomItemFilterLocation.Workload]: CustomItemTypeFilter;
}

const customItemFilterByItemTypeState: CustomItemTypeFilter = {
  [ItemType.TASKS]: {
    customTaskStatusFilter: StatusFilterGroups.All,
    dropdownTaskStatusFilter: StatusFilterGroups.All,
    otherCustomProperties: { asap: false, atRisk: false, workLimitRisk: false, hasFiles: false },
    taskStatusIds: [],
    statusFilterSelection: 'all',
  },
  [ItemType.PROJECTS]: {
    customProjectStatusFilter: FolderFilterStatus.ActiveOnHold,
    dropdownProjectStatusFilter: FolderFilterStatus.ActiveOnHold,
    otherCustomProperties: { asap: false, atRisk: false, workLimitRisk: false, hasFiles: false },
    statusFilterSelection: 'activeOnHold',
  },
};

const timesheetExportDropdownProjectStatusFilterDefault = cloneDeep(customItemFilterByItemTypeState);
timesheetExportDropdownProjectStatusFilterDefault.projects.dropdownProjectStatusFilter = FolderFilterStatus.All;

const customItemFilterForWorkloadDefault = cloneDeep(customItemFilterByItemTypeState);
customItemFilterForWorkloadDefault[ItemType.TASKS].dropdownTaskStatusFilter = StatusFilterGroups.Active;
customItemFilterForWorkloadDefault[ItemType.TASKS].customTaskStatusFilter = StatusFilterGroups.Active;
customItemFilterForWorkloadDefault[ItemType.PROJECTS].dropdownProjectStatusFilter = FolderFilterStatus.Scheduled;
customItemFilterForWorkloadDefault[ItemType.PROJECTS].customProjectStatusFilter = FolderFilterStatus.Scheduled;

export const initialState: CustomItemFilterState = {
  [CustomItemFilterLocation.AssignmentGrid]: { ...customItemFilterByItemTypeState },
  [CustomItemFilterLocation.DataGrid]: { ...customItemFilterByItemTypeState },
  [CustomItemFilterLocation.Portfolio]: { ...customItemFilterByItemTypeState },
  [CustomItemFilterLocation.Project]: { ...customItemFilterByItemTypeState },
  [CustomItemFilterLocation.TaskBoard]: { ...customItemFilterByItemTypeState },
  [CustomItemFilterLocation.TimesheetExport]: { ...timesheetExportDropdownProjectStatusFilterDefault },
  [CustomItemFilterLocation.Workload]: { ...customItemFilterForWorkloadDefault },
};

const customItemFilterSlice = createSlice({
  name: 'customItemFilter',
  initialState,
  reducers: {
    setCustomItemFilterDropdownProjectStatusFilter: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        dropdownProjectStatusFilter: FolderFilterStatus;
      }>
    ) => {
      state[action.payload.location][ItemType.PROJECTS].dropdownProjectStatusFilter =
        action.payload.dropdownProjectStatusFilter;
    },
    setCustomItemFilterDropdownTaskStatusFilter: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        dropdownTaskStatusFilter: StatusFilterGroups;
      }>
    ) => {
      state[action.payload.location][ItemType.TASKS].dropdownTaskStatusFilter = action.payload.dropdownTaskStatusFilter;
    },
    setCustomItemFilterCustomProjectStatusFilter: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        customProjectStatusFilter: FolderFilterStatus;
      }>
    ) => {
      state[action.payload.location][ItemType.PROJECTS].customProjectStatusFilter =
        action.payload.customProjectStatusFilter;
    },
    setCustomItemFilterCustomTaskStatusFilter: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        customTaskStatusFilter: StatusFilterGroups;
      }>
    ) => {
      state[action.payload.location][ItemType.TASKS].customTaskStatusFilter = action.payload.customTaskStatusFilter;
    },
    setCustomItemFilterTaskStatusIds: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        taskStatusIds: ReadonlyArray<number>;
      }>
    ) => {
      state[action.payload.location][ItemType.TASKS].taskStatusIds = castDraft(action.payload.taskStatusIds);
    },
    setCustomItemFilterAtRisk: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        atRisk: boolean;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].otherCustomProperties.atRisk = action.payload.atRisk;
    },
    setCustomItemFilterWorkLimitRisk: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        workLimitRisk: boolean;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].otherCustomProperties.workLimitRisk = action.payload.workLimitRisk;
    },
    setCustomItemFilterAsap: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        asap: boolean;
      }>
    ) => {
      const location = action.payload.location;
      state[location][ItemType.TASKS].otherCustomProperties.asap = action.payload.asap;
    },
    setCustomItemFilterHasFiles: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        hasFiles: boolean;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].otherCustomProperties.hasFiles = action.payload.hasFiles;
    },
    setCustomItemFilterStatusSelection: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        statusFilterSelection: string;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].statusFilterSelection = action.payload.statusFilterSelection;
    },
    setCustomItemFilterNameContains: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        nameContains: string | undefined;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].otherCustomProperties.nameContains = action.payload.nameContains;
    },
    setCustomItemFilterCreatedByUserId: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        createdByUserId: number | undefined;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].otherCustomProperties.createdByUserId = action.payload.createdByUserId;
    },
    setCustomItemFilterCustomFieldProperties: (
      state,
      action: PayloadAction<{
        location: CustomItemFilterLocation;
        itemType: CustomItemFilterItemType;
        customFieldProperties: ReadonlyArray<CustomFieldFilterWithRequiredId> | undefined;
      }>
    ) => {
      const itemType = action.payload.itemType;
      const location = action.payload.location;
      state[location][itemType].otherCustomProperties.customFieldsAndValues = castDraft(
        action.payload.customFieldProperties
      );
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const {
  setCustomItemFilterAsap,
  setCustomItemFilterAtRisk,
  setCustomItemFilterHasFiles,
  setCustomItemFilterWorkLimitRisk,
  setCustomItemFilterCreatedByUserId,
  setCustomItemFilterCustomFieldProperties,
  setCustomItemFilterCustomProjectStatusFilter,
  setCustomItemFilterCustomTaskStatusFilter,
  setCustomItemFilterDropdownProjectStatusFilter,
  setCustomItemFilterDropdownTaskStatusFilter,
  setCustomItemFilterNameContains,
  setCustomItemFilterTaskStatusIds,
  setCustomItemFilterStatusSelection,
} = customItemFilterSlice.actions;

export default customItemFilterSlice.reducer;
