import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LpFormikInput } from 'features/common/forms/formik/input';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useDefaultWidgetTitle } from 'features/dashboards_v2/widget/use_default_widget_title';
import { OPTIONAL } from 'lib/constants';

import { WidgetSettingsV2Field } from './field';

export const Title = ({ widgetId }: { widgetId: number }) => {
  const { setFieldValue } = useFormikContext();
  const widget = useSelector((state) => getWidgetForId(state, widgetId.toString()));
  const widgetPlaceholderTitle = useDefaultWidgetTitle(widget);
  const widgetTitle = widget?.config.title ?? '';

  useEffect(() => {
    setFieldValue('title', widgetTitle);
  }, [setFieldValue, widgetTitle]);

  return (
    <WidgetSettingsV2Field name="Widget Title">
      <LpFormikInput
        name="title"
        placeholder={widgetPlaceholderTitle ?? OPTIONAL}
        className="base-v2-widget-settings__content-options-field-widget-title"
      />
    </WidgetSettingsV2Field>
  );
};
