import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getCurrentOrganizationId } from 'features/common/current/selectors';

import './contact_support_link.scss';

export const getTempoSupportUrl = (orgId?: number) => {
  return orgId
    ? `https://tempo-io.atlassian.net/servicedesk/customer/portal/6/group/1076/create/1264?customfield_12777=${orgId}`
    : 'https://tempo-io.atlassian.net/servicedesk/customer/portal/6/group/1076/create/1264';
};

export const ContactSupportLink = ({ className, text }: { className?: string; text: string }) => {
  const organizationId = useSelector(getCurrentOrganizationId);

  return (
    <a
      className={classNames('contact-support-link', className)}
      href={getTempoSupportUrl(organizationId)}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );
};
