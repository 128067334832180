import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { ScheduleHoverStatus, useItemDataForScheduleTooltip } from 'hooks/use_item_data_for_schedule_hover';
import { useItemDataForStatus } from 'hooks/use_item_data_for_status';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getAssignmentsForItemId } from 'redux/entities/selectors/assignment';
import { getItemForId } from 'redux/entities/selectors/item';

import {
  getTaskTooltipContent,
  getAssignmentTooltipContent,
  getContainerTooltipContent,
  getDateRangeText,
  getIsLateOrLateRisk,
} from './helpers';

const ToolTipContent = ({
  formatLocalDate,
  doneDate,
  itemType,
  expectedStart,
  expectedFinish,
  latestFinish,
  isDone,
  isOnHold,
  isEffectivelyOnHold,
  isAtRisk,
  dateRangeText,
  highEffort,
  isParentDone,
  needsAssignment,
  needsEstimate,
}: {
  dateRangeText: string;
  doneDate?: string | null;
  expectedFinish?: string | null;
  expectedStart?: string | null;
  formatLocalDate: (dateString: string) => string;
  highEffort?: number | null;
  isDone: boolean;
  isEffectivelyOnHold: boolean;
  isAtRisk: boolean;
  isOnHold: boolean;
  isParentDone: boolean;
  itemType?: ItemType;
  latestFinish?: string | null;
  needsAssignment: boolean;
  needsEstimate: boolean;
}) => {
  const formattedDoneDate = formatLocalDate(doneDate ?? '');

  if (itemType === ItemType.ASSIGNMENTS) {
    return getAssignmentTooltipContent({
      hoverDateRange: dateRangeText,
      isItemAtRisk: isAtRisk,
      highEffort,
      isDone,
      isParentDone,
    });
  }

  if (itemType === ItemType.TASKS) {
    return getTaskTooltipContent({
      isOnHold,
      isEffectivelyOnHold,
      isDone,
      formattedDoneDate,
      taskNeedsAttention: needsAssignment || needsEstimate,
      hoverDateRange: dateRangeText,
      isItemAtRisk: isAtRisk,
    });
  }

  const isContainerScheduled =
    expectedStart !== expectedFinish || expectedFinish !== latestFinish || expectedStart !== latestFinish;
  return getContainerTooltipContent({
    isDone,
    isOnHold,
    isEffectivelyOnHold,
    isItemAtRisk: isAtRisk,
    dateRangeText,
    formattedDoneDate,
    isContainerScheduled,
  });
};

export const ScheduleBarToolTipRenderer = ({
  doneDate,
  expectedFinish,
  expectedStart,
  highEffort,
  isDone,
  isEffectivelyOnHold,
  isAtRisk,
  isOnHold,
  isParentDone,
  itemScheduleStatus,
  itemType,
  latestFinish,
  needsAssignment,
  needsEstimate,
}: {
  doneDate: string | null | undefined;
  expectedFinish: string | null | undefined;
  expectedStart: string | null | undefined;
  highEffort: number | null | undefined;
  isDone: boolean;
  isEffectivelyOnHold: boolean;
  isAtRisk: boolean;
  isOnHold: boolean;
  isParentDone: boolean;
  itemScheduleStatus: ScheduleHoverStatus | undefined;
  itemType: ItemType | undefined;
  latestFinish: string | null | undefined;
  needsAssignment: boolean;
  needsEstimate: boolean;
}) => {
  const { formatLocalDate } = useLocalizedFormats();

  const dateRangeText = getDateRangeText({ formatLocalDate, latestFinish, expectedFinish });

  return (
    <div className={classNames('schedule-bar-tooltip', itemScheduleStatus)}>
      <ToolTipContent
        formatLocalDate={formatLocalDate}
        doneDate={doneDate}
        itemType={itemType}
        expectedFinish={expectedFinish}
        expectedStart={expectedStart}
        latestFinish={latestFinish}
        isDone={isDone}
        isOnHold={isOnHold}
        isEffectivelyOnHold={isEffectivelyOnHold}
        isAtRisk={isAtRisk}
        dateRangeText={dateRangeText}
        highEffort={highEffort}
        isParentDone={isParentDone}
        needsAssignment={needsAssignment}
        needsEstimate={needsEstimate}
      />
    </div>
  );
};

export const ScheduleBarTooltip = ({ itemId }: { itemId: number }) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const itemAssignments = useSelector((state) => getAssignmentsForItemId(state, itemId));

  const needsAssignment =
    !itemAssignments.length || itemAssignments.every((assignment) => !assignment.organizationUser);
  const needsEstimate = itemAssignments.every((assignment) => !assignment.highEffort);

  const { isDone, isEffectivelyOnHold, isOnHold, isLate, isLateRisk } = useItemDataForStatus(itemId);

  const { isDone: isParentDone } = useItemDataForStatus(item?.parent?.id);

  const doneDate = item?.doneDate;
  const expectedFinish = item?.expectedFinish;
  const expectedStart = item?.expectedStart;
  const itemType = item?.itemType;
  const highEffort = item?.highEffort;
  const isAtRisk = getIsLateOrLateRisk(isLate, isLateRisk);
  const latestFinish = item?.latestFinish;

  const itemScheduleStatus = useItemDataForScheduleTooltip(itemId);

  return (
    <ScheduleBarToolTipRenderer
      doneDate={doneDate}
      itemScheduleStatus={itemScheduleStatus}
      itemType={itemType}
      expectedFinish={expectedFinish}
      expectedStart={expectedStart}
      latestFinish={latestFinish}
      isDone={isDone}
      isOnHold={isOnHold}
      isEffectivelyOnHold={isEffectivelyOnHold}
      isAtRisk={isAtRisk}
      highEffort={highEffort}
      isParentDone={isParentDone}
      needsAssignment={needsAssignment}
      needsEstimate={needsEstimate}
    />
  );
};
