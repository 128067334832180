import { FolderStatusFilter, StatusFilterGroups } from 'daos/enums';
import { LocationFilterSettings } from 'daos/types';

export function isDefaultProjectFilter(values: LocationFilterSettings, dataIsAllActive: boolean) {
  const {
    projectCreatedByUserId,
    projectCustomFieldFilters,
    projectHasFileFilter,
    projectNameFilter,
    projectScheduleRiskOnly,
    projectStatusFilter,
    projectWorkLimitRiskOnly,
  } = values;

  const isDefaultProjectStatusFilter =
    projectStatusFilter === FolderStatusFilter.All ||
    projectStatusFilter === null ||
    (dataIsAllActive && projectStatusFilter === FolderStatusFilter.Scheduled);
  const isDefaultProjectRiskAndWorkLimitRisk = projectScheduleRiskOnly === false && projectWorkLimitRiskOnly === false;
  const isDefaultProjectNameFilter = projectNameFilter === null || projectNameFilter === '';
  const isDefaultProjectCustomFilter = projectCustomFieldFilters === null || !projectCustomFieldFilters.length;
  const isDefaultProjectFileFilter = projectHasFileFilter === false;
  const isDefaultCreatedByFilter = projectCreatedByUserId === null;

  return (
    isDefaultProjectCustomFilter &&
    isDefaultProjectFileFilter &&
    isDefaultProjectNameFilter &&
    isDefaultProjectRiskAndWorkLimitRisk &&
    isDefaultProjectStatusFilter &&
    isDefaultCreatedByFilter
  );
}

export function isDefaultTaskFilter(values: LocationFilterSettings, dataIsAllActive: boolean) {
  const {
    taskCreatedByUserId,
    taskCustomFieldFilters,
    taskHasFileFilter,
    taskNameFilter,
    taskScheduleDirectiveAsapOnly,
    taskScheduleRiskOnly,
    taskStatusFilter,
    taskStatusIdsFilter,
  } = values;

  const isDefaultTaskStatusFilter =
    taskStatusFilter === StatusFilterGroups.All ||
    taskStatusFilter === null ||
    (dataIsAllActive && taskStatusFilter === StatusFilterGroups.Active);
  const isDefaultTaskStatusIds = taskStatusIdsFilter === null || !taskStatusIdsFilter.length;
  const isDefaultTaskAsapOrRiskFilter = taskScheduleDirectiveAsapOnly === false && taskScheduleRiskOnly === false;
  const isDefaultTaskNameFilter = taskNameFilter === null || taskNameFilter === '';
  const isDefaultTaskCustomFilter = taskCustomFieldFilters === null || !taskCustomFieldFilters.length;
  const isDefaultTaskFileFilter = taskHasFileFilter === false;
  const isDefaultCreatedByFilter = taskCreatedByUserId === null;

  return (
    isDefaultTaskAsapOrRiskFilter &&
    isDefaultTaskCustomFilter &&
    isDefaultTaskFileFilter &&
    isDefaultTaskNameFilter &&
    isDefaultTaskStatusFilter &&
    isDefaultTaskStatusIds &&
    isDefaultCreatedByFilter
  );
}

export function isDefaultFilter(values: LocationFilterSettings, dataIsAllActive: boolean) {
  const { organizationUserIdFilter, groupIdFilter } = values;

  const isTaskFilterAtDefaultState = isDefaultTaskFilter(values, dataIsAllActive);
  const isProjectFilterAtDefaultState = isDefaultProjectFilter(values, dataIsAllActive);

  const isDefaultAssignedFilter = organizationUserIdFilter === null && groupIdFilter === null;

  const isDefaultDateRange = values.dateRangeFilterType === null;

  return isProjectFilterAtDefaultState && isTaskFilterAtDefaultState && isDefaultAssignedFilter && isDefaultDateRange;
}
