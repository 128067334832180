import { CustomFieldType } from 'daos/enums';
import { LpFormikDatePicker } from 'features/common/forms/formik/date';
import { LpFormikInput } from 'features/common/forms/formik/input';
import { LpFormikTextArea } from 'features/common/forms/formik/text_area';
import {
  IntakeCustomFieldUserWidgetData,
  IntakeCustomFieldWidgetData,
} from 'features/dashboards_v2/widget_click_through/intake/types';

import {
  IntakeCheckbox,
  IntakeCurrency,
  IntakeItem,
  IntakePicklist,
  IntakeUser,
  IntakeMultiItem,
  IntakeMultiPicklist,
  IntakeMultiText,
  IntakeMultiUser,
} from './inputs';

export const CustomFieldValueInputCell = ({
  intakeCustomField,
  intakeUserChoices,
  customFieldType,
  fieldId,
  isDisplayOnly,
  hasAutoFocus,
}: {
  intakeCustomField: IntakeCustomFieldWidgetData;
  intakeUserChoices: Record<string, IntakeCustomFieldUserWidgetData>;
  customFieldType: CustomFieldType;
  fieldId: number;
  isDisplayOnly: boolean;
  hasAutoFocus: boolean;
}) => {
  const fieldIdKey = String(fieldId);

  switch (customFieldType) {
    case CustomFieldType.DATE:
      return (
        <LpFormikDatePicker
          name={fieldIdKey}
          className="intake-widget-user-form-section__field-date"
          showError
          hideErrorMessage
          disabled={isDisplayOnly}
        />
      );
    case CustomFieldType.CURRENCY:
      return (
        <IntakeCurrency
          currencyUnit={intakeCustomField.currencyUnit}
          fieldIdKey={fieldIdKey}
          isDisplayOnly={isDisplayOnly}
          hasAutoFocus={hasAutoFocus}
        />
      );
    case CustomFieldType.NUMERIC:
      return (
        <LpFormikInput
          name={fieldIdKey}
          type={'number'}
          hideErrorMessage
          restFieldErrorOnChange
          disabled={isDisplayOnly}
          autoFocus={hasAutoFocus}
        />
      );
    case CustomFieldType.TEXT:
      return (
        <LpFormikInput
          name={fieldIdKey}
          type={'text'}
          hideErrorMessage
          restFieldErrorOnChange
          disabled={isDisplayOnly}
          autoFocus={hasAutoFocus}
        />
      );
    case CustomFieldType.LINK:
      return (
        <LpFormikInput
          autoFocus={hasAutoFocus}
          className="intake-widget-user-form-section__field-link"
          disabled={isDisplayOnly}
          hideErrorMessage
          name={fieldIdKey}
          placeholder="Link"
          restFieldErrorOnChange
          type={'text'}
        />
      );
    case CustomFieldType.PICKLIST:
      return (
        <IntakePicklist
          fieldIdKey={fieldIdKey}
          isDisplayOnly={isDisplayOnly}
          intakePicklistChoices={intakeCustomField.picklistChoiceById}
        />
      );
    case CustomFieldType.CHECKBOX:
      return <IntakeCheckbox fieldIdKey={fieldIdKey} isDisplayOnly={isDisplayOnly} />;
    case CustomFieldType.MULTILINE_TEXT:
      return (
        <LpFormikTextArea
          name={fieldIdKey}
          hideErrorMessage
          restFieldErrorOnChange
          disabled={isDisplayOnly}
          autoFocus={hasAutoFocus}
        />
      );
    case CustomFieldType.USER:
      return <IntakeUser fieldIdKey={fieldIdKey} isDisplayOnly={isDisplayOnly} intakeUserChoices={intakeUserChoices} />;
    case CustomFieldType.ITEM:
      return <IntakeItem fieldIdKey={fieldIdKey} isDisplayOnly={isDisplayOnly} />;
    case CustomFieldType.MULTI_TEXT:
      return (
        <IntakeMultiText fieldIdKey={fieldIdKey} type={'text'} isDisplayOnly={isDisplayOnly} autoFocus={hasAutoFocus} />
      );
    case CustomFieldType.MULTI_LINK:
      return (
        <IntakeMultiText
          fieldIdKey={fieldIdKey}
          type={'text'}
          isDisplayOnly={isDisplayOnly}
          autoFocus={hasAutoFocus}
          placeholder={'Link'}
        />
      );
    case CustomFieldType.MULTI_PICKLIST:
      return (
        <IntakeMultiPicklist
          fieldIdKey={fieldIdKey}
          isDisplayOnly={isDisplayOnly}
          intakePicklistChoices={intakeCustomField.picklistChoiceById}
        />
      );
    case CustomFieldType.MULTI_USER:
      return (
        <IntakeMultiUser fieldIdKey={fieldIdKey} isDisplayOnly={isDisplayOnly} intakeUserChoices={intakeUserChoices} />
      );
    case CustomFieldType.MULTI_ITEM:
      return <IntakeMultiItem fieldIdKey={fieldIdKey} isDisplayOnly={isDisplayOnly} />;
    default:
      return null;
  }
};
