import { timeUnitLookup, timeInputInHoursUSA, TimeUnit } from 'lib/conversion_helpers/time_input_in_hours_usa';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { hoursInputValidationRegEx } from 'lib/validation_helpers/hours_input_validation_regex';

export const timeStringInHoursFromTimeStringWithUnits = (decrementValue: string) => {
  const NUMERIC_MATCH_INDEX = 1;
  const UNIT_MATCH_INDEX = 2;

  const match = decrementValue.match(hoursInputValidationRegEx);

  if (match) {
    const matchForUnit = match[UNIT_MATCH_INDEX] ?? '';
    const timeUnit = timeUnitLookup[matchForUnit] ?? TimeUnit.Hours;
    const valueWithoutUnit = match[NUMERIC_MATCH_INDEX];
    return valueWithoutUnit ? timeInputInHoursUSA({ timeUnit, valueWithoutUnit }) : undefined;
  }
  return undefined;
};

export const calculateDecrementAmountAsFloat = (decrementValue: string) => {
  const adjustedDecrementInHours = timeStringInHoursFromTimeStringWithUnits(decrementValue);
  const parsedDecrement = adjustedDecrementInHours ? parseFloat(adjustedDecrementInHours) : undefined;

  if (parsedDecrement === undefined || isNaN(parsedDecrement)) {
    return 0;
  }

  return parsedDecrement;
};

export const calculateDecrementedEstimates = ({
  decrementValue,
  lowEstimate,
  highEstimate,
}: {
  decrementValue: string;
  lowEstimate: number;
  highEstimate: number;
}) => {
  const decrementAmountAsFloat = calculateDecrementAmountAsFloat(decrementValue);

  return {
    adjustedLow: formatAsHours(Math.max(lowEstimate - decrementAmountAsFloat, 0)),
    adjustedHigh: formatAsHours(Math.max(highEstimate - decrementAmountAsFloat, 0)),
  };
};
