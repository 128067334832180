import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import { ListWidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';
import {
  DeprecatedSortableSafeWidgetItemGrid,
  DeprecatedSortableSafeWidgetPeopleGrid,
  SortableSafeWidgetItemGrid,
  SortableSafeWidgetPeopleGrid,
} from 'features/common/data_grid/safe_widgets/grid';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import { getWidgetConfigSortColumn } from 'features/dashboards/selectors';
import {
  DeprecatedGridSummaryRow,
  DeprecatedItemListWidgetData,
  DeprecatedItemRow,
} from 'features/dashboards_v2/widget/widgets/list/types';
import {
  DeprecatedPeopleListWidgetData,
  DeprecatedPeopleRow,
  GridSummaryRow,
  ItemListWidgetData,
  ItemRow,
  PeopleListWidgetData,
  PeopleRow,
} from 'features/dashboards_v2/widget/widgets/reports/types';
import { GuestWidgetGridDownload } from 'features/guest/dashboard/expanded_widget/grid/download';
import { getSafeListWidgetFilterQueryString } from 'features/guest/dashboard/expanded_widget/grid/quick_filter';
import { useDebouncedInput } from 'hooks/use_debounced_input';
import { useHasFeature } from 'hooks/use_has_feature';
import { awaitRequestFinish } from 'lib/api';
import { WIDGET_GRID_DEFAULT_FETCH_LIMIT } from 'lib/constants';
import { FeatureFlag } from 'lib/feature_flags';

import './guest_widget_grid.scss';

const DeprecatedItemListGuestGrid = ({ inputValue, widget }: { inputValue: string; widget: WidgetWithData }) => {
  const widgetConfigSortColumn = useSelector((state) => getWidgetConfigSortColumn(state, String(widget.id)));
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState<ReadonlyArray<DeprecatedItemRow>>([]);
  const [summaryRowData, setSummaryRowData] = useState<ReadonlyArray<DeprecatedGridSummaryRow>>([]);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const isInitialGridLoad = useRef(true);

  const { organizationId, workspaceId, dashboardId, id: widgetId, config } = widget;
  const configColumns = config.columns ?? [];
  const filterQueryString = getSafeListWidgetFilterQueryString(widget, inputValue);

  const fetchWidgetItemList = useCallback(
    (sortOrder: string) => {
      if (isInitialGridLoad.current) {
        setIsFetchComplete(false);
      }

      const { uuid } = dispatch(
        WidgetDao.fetchItemList(
          { organizationId, workspaceId, dashboardId, widgetId },
          { filter: filterQueryString, query: { order: sortOrder, limit: WIDGET_GRID_DEFAULT_FETCH_LIMIT } }
        )
      );

      dispatch(
        awaitRequestFinish<DeprecatedItemListWidgetData>(uuid, {
          onSuccess: ({ data }) => {
            setRowData(data.rowData);
            setSummaryRowData(data.totalRowData ? [data.totalRowData] : []);
            isInitialGridLoad.current = false;
          },
          onFinish: () => setIsFetchComplete(true),
        })
      );
    },
    [dispatch, organizationId, workspaceId, dashboardId, widgetId, filterQueryString]
  );

  return (
    <DeprecatedSortableSafeWidgetItemGrid
      configColumns={configColumns}
      fetchWidgetRowData={fetchWidgetItemList}
      initialSortColumn={widgetConfigSortColumn}
      isFetchComplete={isFetchComplete}
      rowData={rowData}
      summaryRow={summaryRowData}
      widget={widget}
    />
  );
};

const ItemListGuestGrid = ({ inputValue, widget }: { inputValue: string; widget: WidgetWithData }) => {
  const widgetConfigSortColumn = useSelector((state) => getWidgetConfigSortColumn(state, String(widget.id)));
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState<ReadonlyArray<ItemRow>>([]);
  const [summaryRowData, setSummaryRowData] = useState<ReadonlyArray<GridSummaryRow>>([]);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const isInitialGridLoad = useRef(true);

  const { organizationId, workspaceId, dashboardId, id: widgetId, config } = widget;
  const configColumns = config.columns ?? [];
  const filterQueryString = getSafeListWidgetFilterQueryString(widget, inputValue);

  const fetchWidgetItemList = useCallback(
    (sortOrder: string) => {
      if (isInitialGridLoad.current) {
        setIsFetchComplete(false);
      }

      const { uuid } = dispatch(
        WidgetDao.fetchItemList(
          { organizationId, workspaceId, dashboardId, widgetId },
          { filter: filterQueryString, query: { order: sortOrder, limit: WIDGET_GRID_DEFAULT_FETCH_LIMIT } }
        )
      );

      dispatch(
        awaitRequestFinish<ItemListWidgetData>(uuid, {
          onSuccess: ({ data }) => {
            setRowData(data.rowData);
            setSummaryRowData(data.totalRowData ? [data.totalRowData] : []);
            isInitialGridLoad.current = false;
          },
          onFinish: () => setIsFetchComplete(true),
        })
      );
    },
    [dispatch, organizationId, workspaceId, dashboardId, widgetId, filterQueryString]
  );

  return (
    <SortableSafeWidgetItemGrid
      configColumns={configColumns}
      fetchWidgetRowData={fetchWidgetItemList}
      initialSortColumn={widgetConfigSortColumn}
      isFetchComplete={isFetchComplete}
      rowData={rowData}
      summaryRow={summaryRowData}
      widget={widget}
    />
  );
};

const DeprecatedPeopleListWidgetGuestGrid = ({
  inputValue,
  widget,
}: {
  inputValue: string;
  widget: WidgetWithData;
}) => {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState<ReadonlyArray<DeprecatedPeopleRow>>([]);
  const [summaryRowData, setSummaryRowData] = useState<ReadonlyArray<DeprecatedGridSummaryRow>>([]);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const isInitialGridLoad = useRef(true);

  const widgetConfigSortColumn = useSelector((state) => getWidgetConfigSortColumn(state, String(widget.id)));
  const filterQueryString = getSafeListWidgetFilterQueryString(widget, inputValue);
  const configColumns = widget.config.columns ?? [];

  const fetchPeopleList = useCallback(
    (sortOrder: string) => {
      if (isInitialGridLoad.current) {
        setIsFetchComplete(false);
      }
      const { dashboardId, organizationId, workspaceId, id: widgetId } = widget;

      const { uuid } = dispatch(
        WidgetDao.fetchPeopleList(
          { organizationId, workspaceId, dashboardId, widgetId },
          {
            filter: filterQueryString,
            query: { order: sortOrder, limit: WIDGET_GRID_DEFAULT_FETCH_LIMIT },
          }
        )
      );

      dispatch(
        awaitRequestFinish<DeprecatedPeopleListWidgetData>(uuid, {
          onSuccess: ({ data }) => {
            setRowData(data.rowData);
            setSummaryRowData(data.totalRowData ? [data.totalRowData] : []);
            isInitialGridLoad.current = false;
          },
          onFinish: () => setIsFetchComplete(true),
        })
      );
    },
    [dispatch, filterQueryString, widget]
  );

  return (
    <DeprecatedSortableSafeWidgetPeopleGrid
      configColumns={configColumns}
      fetchWidgetRowData={fetchPeopleList}
      initialSortColumn={widgetConfigSortColumn}
      isFetchComplete={isFetchComplete}
      rowData={rowData}
      summaryRow={summaryRowData}
      widget={widget}
    />
  );
};

const PeopleListWidgetGuestGrid = ({ inputValue, widget }: { inputValue: string; widget: WidgetWithData }) => {
  const dispatch = useDispatch();

  const [rowData, setRowData] = useState<ReadonlyArray<PeopleRow>>([]);
  const [summaryRowData, setSummaryRowData] = useState<ReadonlyArray<GridSummaryRow>>([]);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const isInitialGridLoad = useRef(true);

  const widgetConfigSortColumn = useSelector((state) => getWidgetConfigSortColumn(state, String(widget.id)));
  const filterQueryString = getSafeListWidgetFilterQueryString(widget, inputValue);
  const configColumns = widget.config.columns ?? [];

  const fetchPeopleList = useCallback(
    (sortOrder: string) => {
      if (isInitialGridLoad.current) {
        setIsFetchComplete(false);
      }
      const { dashboardId, organizationId, workspaceId, id: widgetId } = widget;

      const { uuid } = dispatch(
        WidgetDao.fetchPeopleList(
          { organizationId, workspaceId, dashboardId, widgetId },
          {
            filter: filterQueryString,
            query: { order: sortOrder, limit: WIDGET_GRID_DEFAULT_FETCH_LIMIT },
          }
        )
      );

      dispatch(
        awaitRequestFinish<PeopleListWidgetData>(uuid, {
          onSuccess: ({ data }) => {
            setRowData(data.rowData);
            setSummaryRowData(data.totalRowData ? [data.totalRowData] : []);
            isInitialGridLoad.current = false;
          },
          onFinish: () => setIsFetchComplete(true),
        })
      );
    },
    [dispatch, filterQueryString, widget]
  );

  return (
    <SortableSafeWidgetPeopleGrid
      configColumns={configColumns}
      fetchWidgetRowData={fetchPeopleList}
      initialSortColumn={widgetConfigSortColumn}
      isFetchComplete={isFetchComplete}
      rowData={rowData}
      summaryRow={summaryRowData}
      widget={widget}
    />
  );
};

const ListGuestGrid = ({ inputValue, widget }: { inputValue: string; widget: WidgetWithData }) => {
  const hasPeopleReportRefactor = useHasFeature(FeatureFlag.peopleReportRefactor);
  const hasItemReportRefactor = useHasFeature(FeatureFlag.itemReportRefactor);

  switch (widget.config.listType) {
    case ListWidgetType.Assignment:
    case ListWidgetType.Tasks:
    case ListWidgetType.Folders:
    case ListWidgetType.Projects:
    case ListWidgetType.Packages: {
      if (hasItemReportRefactor) {
        return <ItemListGuestGrid inputValue={inputValue} widget={widget} />;
      }
      return <DeprecatedItemListGuestGrid inputValue={inputValue} widget={widget} />;
    }

    case ListWidgetType.Users: {
      if (hasPeopleReportRefactor) {
        return <PeopleListWidgetGuestGrid inputValue={inputValue} widget={widget} />;
      }
      return <DeprecatedPeopleListWidgetGuestGrid inputValue={inputValue} widget={widget} />;
    }

    default:
      return null;
  }
};

export const GuestWidgetGrid = ({ widget }: { widget: WidgetWithData }) => {
  const { inputValue, setDebouncedValue } = useDebouncedInput(widget.id);

  const handleQuickFilterChange = (value: string) => setDebouncedValue.current(value);

  return (
    <div className="guest-widget-grid">
      <Menu className="guest-widget-grid__menu" borderless secondary>
        <Menu.Menu position="left">
          <Menu.Item content={<GoBackToDashboardButton />} />
          <Menu.Item
            content={<LpSearchInputUncontrolled initialValue={inputValue} onChange={handleQuickFilterChange} />}
          />
        </Menu.Menu>
        <Menu.Menu position="right">
          <Menu.Item content={<GuestWidgetGridDownload inputValue={inputValue} widget={widget} />} />
        </Menu.Menu>
      </Menu>

      <ListGuestGrid inputValue={inputValue} widget={widget} />
    </div>
  );
};
