import { useCallback, useEffect, useState } from 'react';

export const useBrowserTabActive = () => {
  const [isBrowserTabActive, setIsBrowserTabActive] = useState(true);

  const handleBrowserVisibilityChange = useCallback(() => {
    const isPageHidden = document.hidden;

    setIsBrowserTabActive(!isPageHidden);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleBrowserVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleBrowserVisibilityChange);
    };
  }, [handleBrowserVisibilityChange]);

  return isBrowserTabActive;
};
