import moment from 'moment-timezone';
import numeral, { locale as numeralLocale } from 'numeral';
import 'moment-duration-format';
import 'numeral/locales/de';
import 'numeral/locales/en-gb';
import 'numeral/locales/it';

import { Workspace } from 'daos/model_types';
import { ReadonlyRecord } from 'lib/readonly_record';
import { WorkdayTimeType } from 'lib/workday_time_type';

import { DateFormat, FirstDayOfWeek, NumberFormat, TimeFormat, WeekNumberFormat } from './localization_enums';

export const DAYS_PER_WEEK = 7;
const DEFAULT_LOCALE = 'en';
const DEFAULT_CURRENCY_UNIT = 'USD';

export const DEFAULT_DATE_FORMAT = DateFormat.MonthDayYear;
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const QUARTER_DATE_FORMAT = 'YYYY-Q';

export const dateFormatToDisplay = {
  [DateFormat.MonthDayYear]: 'mm/dd/yy',
  [DateFormat.DayMonthYear]: 'dd/mm/yy',
  [DateFormat.YearMonthDay]: 'yy-mm-dd',
};

enum MomentFormatString {
  MonthDayYear = 'MM/DD/YY',
  DayMonthYear = 'DD/MM/YY',
  YearMonthDay = 'YY-MM-DD',
}

const dateFormatToMomentFormatString = {
  [DateFormat.MonthDayYear]: MomentFormatString.MonthDayYear,
  [DateFormat.DayMonthYear]: MomentFormatString.DayMonthYear,
  [DateFormat.YearMonthDay]: MomentFormatString.YearMonthDay,
};

const dateFormatToLongWrittenDateMomentFormatString = {
  [DateFormat.MonthDayYear]: 'MMMM D YYYY',
  [DateFormat.DayMonthYear]: 'D MMMM YYYY',
  [DateFormat.YearMonthDay]: 'D MMMM YYYY', // No separate format for year month day
};

export const DEFAULT_FIRST_DAY_OF_WEEK = FirstDayOfWeek.Sunday;

export const firstDayOfWeekToJsDayNumber = {
  [FirstDayOfWeek.Saturday]: 6,
  [FirstDayOfWeek.Sunday]: 0,
  [FirstDayOfWeek.Monday]: 1,
};

export const firstDayOfWeekToDisplay = {
  [FirstDayOfWeek.Saturday]: 'Saturday',
  [FirstDayOfWeek.Sunday]: 'Sunday',
  [FirstDayOfWeek.Monday]: 'Monday',
};

export const DEFAULT_NUMBER_FORMAT = NumberFormat.CommaThousandsPeriodDecimal;

export const numberFormatToDisplay = {
  [NumberFormat.CommaThousandsPeriodDecimal]: '1,234.56',
  [NumberFormat.PeriodThousandsCommaDecimal]: '1.234,56',
  [NumberFormat.SpaceThousandsCommaDecimal]: '1 234,56',
};

export const DEFAULT_TIME_FORMAT = TimeFormat.TwelveHour;

export type FormatSettingsToDisplay =
  | ReadonlyRecord<TimeFormat, string>
  | ReadonlyRecord<NumberFormat, string>
  | ReadonlyRecord<DateFormat, string>
  | ReadonlyRecord<FirstDayOfWeek, string>
  | ReadonlyRecord<WeekNumberFormat, string>;

export const timeFormatToDisplay = {
  [TimeFormat.TwelveHour]: '12-hour',
  [TimeFormat.TwentyFourHour]: '24-hour',
};

const timeFormatToMomentFormatString = {
  [TimeFormat.TwelveHour]: 'h:mm A',
  [TimeFormat.TwentyFourHour]: 'H:mm',
};

export const DEFAULT_WEEK_NUMBER_FORMAT = WeekNumberFormat.US;

export const weekNumberFormatToDisplay = {
  [WeekNumberFormat.US]: 'United States',
  [WeekNumberFormat.ISO]: 'ISO',
};

function dateTimeMomentFormatString(dateFormat: DateFormat, timeFormat: TimeFormat) {
  const dateFormatString = dateFormatToMomentFormatString[dateFormat];
  const timeFormatString = timeFormatToMomentFormatString[timeFormat];
  return `${dateFormatString} ${timeFormatString}`;
}

const getLocaleData = (locale: string) => moment.localeData(locale);
const getWeekDays = () => getLocaleData(DEFAULT_LOCALE).weekdaysShort();

/* Returns the current browser's timezone or best approximation. */
export const currentBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'US/Pacific';

/* Returns the current browser's locale or best approximation. */
export const currentBrowserLocale = () => Intl.NumberFormat().resolvedOptions().locale ?? DEFAULT_LOCALE;

/* Returns the amount of time between two dates. date1 should be the earlier of the two dates. */
export const dateDifference = (date1: moment.Moment, date2: moment.Moment, format?: moment.unitOfTime.Diff) =>
  date2.diff(date1, format);

export const stringDateDifference = (
  dateFormat: DateFormat,
  date1: string,
  date2: string,
  unitOfTime?: moment.unitOfTime.Diff
) => {
  const dateFormatString = dateFormatToMomentFormatString[dateFormat];

  return moment(date2, dateFormatString).diff(moment(date1, dateFormatString), unitOfTime);
};

export const stringTimestampDifference = (
  timezone: string,
  timestamp1: string,
  timestamp2: string,
  unitOfTime?: moment.unitOfTime.Diff
) => {
  if (unitOfTime && ['day', 'days', 'd'].includes(unitOfTime)) {
    // Instead of comparing dates, moment considers 24 hours to be a day.
    // Convert timestamps to the start of the day for better accuracy.
    const day1 = moment.tz(timestamp1, timezone).startOf('day');
    const day2 = moment.tz(timestamp2, timezone).startOf('day');

    return day2.diff(day1, 'days');
  } else {
    return moment.tz(timestamp2, timezone).diff(moment.tz(timestamp1, timezone), unitOfTime);
  }
};

export const rawStringToIsoDate = (value: string) => {
  const momentDate = moment(value);
  return momentDate.isValid() ? momentDate.format(ISO_DATE_FORMAT) : '';
};

const numberFormatter = (valueInUtc: string, userFormat: NumberFormat) => {
  let updatedValue = valueInUtc;

  updatedValue = updatedValue.replace(/[,]/g, 'COMMA').replace(/[.]/g, 'PERIOD');

  switch (userFormat) {
    case NumberFormat.PeriodThousandsCommaDecimal:
      return updatedValue.replace(/(COMMA)/g, '.').replace(/(PERIOD)/g, ',');
    case NumberFormat.SpaceThousandsCommaDecimal:
      return updatedValue.replace(/(COMMA)/g, ' ').replace(/(PERIOD)/g, ',');
    case NumberFormat.CommaThousandsPeriodDecimal:
      return valueInUtc;
  }
};

export const formatNumber = (value: string | number, userFormat: NumberFormat) => {
  if (value === '' || !value) {
    return value;
  }

  const valueInUtc = numeral(value).format('0,0.[00]');

  return numberFormatter(valueInUtc, userFormat);
};

export const formatCurrency = (value: number | string, currencyUnit: string, userFormat: NumberFormat): string => {
  if (value === '' || !value) {
    return String(value);
  }

  numeralLocale(DEFAULT_LOCALE);

  try {
    return numberFormatter(
      numeral(value)
        .value()
        ?.toLocaleString(DEFAULT_LOCALE, {
          style: 'currency',
          currency: currencyUnit,
        })
        .toString() ?? '',
      userFormat
    );
  } catch (e) {
    return numberFormatter(
      numeral(value)
        .value()
        ?.toLocaleString(DEFAULT_LOCALE, {
          style: 'currency',
          currency: DEFAULT_CURRENCY_UNIT,
        })
        .toString() ?? '',
      NumberFormat.CommaThousandsPeriodDecimal
    );
  }
};

export const formatWeekdayShort = (number: number) => getWeekDays()[number];

/* Returns weekday numbers for the specified locale sorted starting from the
 * first day of the week. Depending on the locale, Sunday, Monday, or Saturday
 * may come first.
 *
 * In javascript, 0=sunday, 1=monday, ... 6=saturday no matter what.
 * example for en-us: [0, 1, 2, 3, 4, 5, 6] (Sun - Sat)
 * example for en-gb: [1, 2, 3, 4, 5, 6, 0] (Mon - Sun)
 */
export const localWeekdayNumbers = (firstDayOfWeek: FirstDayOfWeek) => {
  const startingIndex = firstDayOfWeekToJsDayNumber[firstDayOfWeek];

  const days = [];

  let i = startingIndex;

  // Adapted from https://stackoverflow.com/a/53796178
  do {
    days.push(i);
    i = i + 1;
    if (i === DAYS_PER_WEEK) {
      i = 0;
    }
  } while (i !== startingIndex);

  return days;
};

export const formatSecondsAsTime = (seconds?: number) =>
  seconds ? moment.duration(seconds, 'seconds').locale(DEFAULT_LOCALE).format('_HM_') : '-';

/******
 ****** ***** TIMEZONE ***** ***** ******/
/* Converts localized date string to formatted iso datetime string using the workspace's workday start or finish time
 * Uses the orgUser's timezone.
 * example: 12/03/12 => 2012-12-03T04:00:00.000Z
 *
 * Another way to think about it at a higher level:
 * - Fn takes orgUser.dateFormat and converts it to Moment compatible format
 * - Moment then converts it to a format that is valid for the server
 */
export const parseLocalDateWithWorkspaceTimeAsIsoDateTime = (
  dateFormat: DateFormat,
  workspace: Workspace | undefined,
  dateString: string,
  workdayTime: WorkdayTimeType,
  timezone: string
) => {
  const isoTime = workspace?.[workdayTime];
  const dateTimeFormat = `${dateFormatToMomentFormatString[dateFormat]} HH:mm`;
  const userDateTime = moment.tz(`${dateString} ${isoTime}`, dateTimeFormat, timezone);
  return userDateTime.isValid() ? userDateTime.toISOString() : undefined;
};

/* Helper function used to take a dateString and get it into the correct timezone/locale and return it in the locale format specified */
export const convertLocalDateToSpecifiedTimezoneAndFormat = (
  dateString: string | null,
  timezone: string,
  formatString: string
) => {
  if (!dateString) {
    return '-';
  }
  const isoDate = moment(dateString, moment.ISO_8601);
  return isoDate.isValid() ? moment.tz(dateString, timezone).locale(DEFAULT_LOCALE).format(formatString) : '-';
};

/* Converts ISO LocalDate to formatted local date
 * example: 2017-12-03 => 3/12/2017
 */
export const formatLocalDate = (dateFormat: DateFormat, dateString: string, timezone: string) => {
  const formatString = dateFormatToMomentFormatString[dateFormat];
  return convertLocalDateToSpecifiedTimezoneAndFormat(dateString, timezone, formatString);
};

/* Converts ISO LocalDate to formatted local date
 * example in US/Pacific: 2020-06-03T20:00:00Z => 6:34 PM
 * example in EU: 2020-06-03T20:00:00Z => 18:34
 */
export const formatLocalTimeFromDate = (timeFormat: TimeFormat, dateTimeString: string | null, timezone: string) => {
  const formatString = timeFormatToMomentFormatString[timeFormat];
  return convertLocalDateToSpecifiedTimezoneAndFormat(dateTimeString, timezone, formatString);
};

/* Converts ISO LocalDate to formatted local date
 * example in US/Pacific: 2020-06-03T20:00:00Z =>  12/18/2019 6:34 PM
 * example in EU: 2020-06-03T20:00:00Z =>  18.12.2019 18:34
 */
export const formatLocalDateWithTime = (
  dateFormat: DateFormat,
  timeFormat: TimeFormat,
  dateString: string,
  timezone: string
) => {
  const formatString = dateTimeMomentFormatString(dateFormat, timeFormat);
  return convertLocalDateToSpecifiedTimezoneAndFormat(dateString, timezone, formatString);
};

/* Converts ISO LocalDate to formatted local date with the short day of week
 * example: 2017-12-03 =>  Wed 12/3/2017
 */
export const formatLocalDayOfWeekAndDate = (dateFormat: DateFormat, timezone: string, dateString: string) => {
  const formatString = dateFormatToMomentFormatString[dateFormat];
  return convertLocalDateToSpecifiedTimezoneAndFormat(dateString, timezone, `ddd ${formatString}`);
};

/* Converts ISO DateTime to a formatted datetime in the specified timezone.
 * example: 2019-03-14T21:01:09Z in US/Pacific => March 14, 2019 at 2:01 PM
 * OR
 * example: 2019-03-14T21:01:09Z in US/Pacific => March 14, 2019 at 14:01
 */
export const formatLocaleDateWithTimeForChanges = (
  dateFormat: DateFormat,
  timeFormat: TimeFormat,
  timezone: string,
  dateString: string
) => {
  const dateFormatString = dateFormatToLongWrittenDateMomentFormatString[dateFormat];
  const timeFormatString = timeFormatToMomentFormatString[timeFormat];

  const formatString = `${dateFormatString} [at] ${timeFormatString}`;
  return convertLocalDateToSpecifiedTimezoneAndFormat(dateString, timezone, formatString);
};

/* Returns the current DateTime adjusted to the Browser (or node in the case of unit tests)
 */
export const currentBrowserDateTime = (timezone: string) => moment().tz(timezone).locale(DEFAULT_LOCALE);

/* Returns a the day-duration between two dates. This function takes into account the browser's timezone.
 */
export const localCalendarDayDurationDays = (start: moment.Moment, finish: moment.Moment, timezone: string) => {
  const startAtStartOfDay = start.clone().tz(timezone).startOf('day');
  const finishAtStartOfDay = finish.clone().tz(timezone).startOf('day');

  return Math.abs(finishAtStartOfDay.diff(startAtStartOfDay, 'days'));
};

/* Returns the current time in ISO date format (UTC)
 * example: 2019-03-14T21:01:09Z
 */
export const currentIsoDateTime = (timezone: string) => currentBrowserDateTime(timezone).toISOString();

export const convertDateStringToMomentObject = (dateString: string, dateFormat: DateFormat) => {
  const dateFormatString = dateFormatToMomentFormatString[dateFormat];

  return moment(dateString, dateFormatString);
};

export interface DateParts {
  year: number;
  month: number;
  date: number;
}
export const convertDatePartsToMomentObject = (dateParts: DateParts, timezone: string) => {
  return moment.tz(dateParts, timezone);
};
