import { createSelector } from 'reselect';

import { ItemType, WidgetOnClickViewType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { computerMouseLight, LpIcon } from 'features/common/lp_icon';
import { serializeOnClickViewOption } from 'features/dashboards_v2/widget_settings/on_click_view_options_serialization';
import { ReadonlyRecord } from 'lib/readonly_record';
import { getActiveNoteCustomFieldsForCurrentWorkspace } from 'redux/entities/selectors/custom_field';
import { RootState } from 'redux/root_reducer';

type NonNoteWidgetOnClickView = Exclude<WidgetOnClickViewType, WidgetOnClickViewType.ItemPanelNotes>;

const onClickViewDisplay: ReadonlyRecord<NonNoteWidgetOnClickView, string> = {
  [WidgetOnClickViewType.Availability]: 'Availability',
  [WidgetOnClickViewType.Board]: 'Board View',
  [WidgetOnClickViewType.Changes]: 'Changes View',
  [WidgetOnClickViewType.Dashboard]: 'Dashboard View',
  [WidgetOnClickViewType.DashboardNote]: 'Dashboard Note View',
  [WidgetOnClickViewType.Grid]: 'Grid View',
  [WidgetOnClickViewType.ItemPanelAssignments]: 'Panel > Assignments',
  [WidgetOnClickViewType.ItemPanelChanges]: 'Panel > Changes',
  [WidgetOnClickViewType.ItemPanelPlanning]: 'Panel > Planning',
  [WidgetOnClickViewType.ItemPanelProperties]: 'Panel > Properties',
  [WidgetOnClickViewType.ItemPanelSchedule]: 'Panel > Scheduling',
  [WidgetOnClickViewType.Metrics]: 'Metrics View',
  [WidgetOnClickViewType.None]: 'None',
  [WidgetOnClickViewType.Profile]: 'Profile',
  [WidgetOnClickViewType.Schedule]: 'Schedule View',
  [WidgetOnClickViewType.Timesheet]: 'Timesheet',
  [WidgetOnClickViewType.Workload]: 'Workload View',
};

function customFieldNoteUseOnItemType(noteField: CustomField, itemType?: ItemType) {
  const { onPackages, onProjectFolders, onTasks } = noteField;
  const note = {
    value: noteField.id,
    text: noteField.name,
  };

  if (itemType === ItemType.PACKAGES && onPackages) {
    return note;
  }
  if ((itemType === ItemType.PROJECTS || itemType === ItemType.FOLDERS) && onProjectFolders) {
    return note;
  }
  if (itemType === ItemType.TASKS && onTasks) {
    return note;
  }
  return undefined;
}

export const getLinkToNoteOptionsForItemType = createSelector(
  getActiveNoteCustomFieldsForCurrentWorkspace,
  (_: RootState, itemType: ItemType | undefined) => itemType,
  (activeNoteFields, itemType) => {
    return activeNoteFields.reduce((acc: Array<{ value: number; text: string }>, noteField) => {
      const selectedCustomField = customFieldNoteUseOnItemType(noteField, itemType);
      selectedCustomField && acc.push(selectedCustomField);

      return acc;
    }, []);
  }
);

export const getFirstNoteCustomFieldId = createSelector(getLinkToNoteOptionsForItemType, (activeNoteFieldsForType) => {
  const firstActiveNote = activeNoteFieldsForType[0];

  return firstActiveNote && firstActiveNote.value;
});

export const getOnClickViewOptions = createSelector(
  getLinkToNoteOptionsForItemType,
  (_: RootState, __: ItemType | undefined, includeOptions?: ReadonlyArray<WidgetOnClickViewType>) => includeOptions,
  (activeNoteFields, includeOptions) => {
    const allOptions: Array<{ name: string; value: string }> = [];

    Object.keys(onClickViewDisplay).forEach((key) => {
      const onClickViewType = key as NonNoteWidgetOnClickView;

      if (!includeOptions || includeOptions.includes(onClickViewType)) {
        allOptions.push({
          name: onClickViewDisplay[onClickViewType],
          value: serializeOnClickViewOption({
            onClickView: key as WidgetOnClickViewType,
            onClickViewFieldId: null,
          }),
        });
      }
    });

    if (!includeOptions || includeOptions.includes(WidgetOnClickViewType.ItemPanelNotes)) {
      activeNoteFields.forEach((noteField) =>
        allOptions.push({
          name: `Panel > ${noteField.text}`,
          value: serializeOnClickViewOption({
            onClickView: WidgetOnClickViewType.ItemPanelNotes,
            onClickViewFieldId: noteField.value,
          }),
        })
      );
    }

    return allOptions
      .sort((a, b) => {
        if (a.name === onClickViewDisplay[WidgetOnClickViewType.None]) {
          return -1;
        }

        return a.name > b.name ? 1 : -1;
      })
      .map(({ name, value }) => ({
        value,
        text: (
          <>
            <LpIcon className="on-click-view-option__icon" icon={computerMouseLight} />
            {name}
          </>
        ),
      }));
  }
);
