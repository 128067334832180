import { useSelector } from 'react-redux';

import { FeatureFlag } from 'lib/feature_flags';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getSystemFeatureFlagDefaults } from 'redux/entities/selectors/system_settings';

export const useHasFeature = (feature: FeatureFlag) => {
  return useFeatures()[feature];
};

const useFeatures = () => {
  const featureFlagDefaults = useSelector(getSystemFeatureFlagDefaults);
  const organization = useSelector(getCurrentOrganization);

  return organization?.featureFlags ?? featureFlagDefaults;
};
