import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams, useRouteMatch } from 'react-router';

import { AcademyTrackLesson } from 'features/academy/daos/model_types';
import { TrackLessonDao } from 'features/academy/daos/track_lesson';
import AcademyModal from 'features/academy/modal';
import { ConnectedRoutes } from 'features/authentication/authenticated/connected';
import EmailConfirmationBanner from 'features/authentication/authenticated/email_confirmation_banner';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import GlobalNotificationBanner from 'features/authentication/authenticated/global_notification_banner';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import GenericErrorMessage from 'features/errors/generic_error_message';
import { LeftNav } from 'features/organization/left_nav';
import useHashParams from 'hooks/use_hash_params';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';

import 'features/organization/index.scss';
import 'features/organization/organization.scss';
import 'features/organization/left_nav/left_nav.scss';

interface AuthenticatedProps {
  selectedOrgId: number | undefined;
  selectedWsId: number | undefined;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}

export const Authenticated = ({ selectedOrgId, selectedWsId, setEntityNotFound }: AuthenticatedProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { courseId, trackId, lessonId, autoPlay } = useHashParams();
  const showAcademyModal = courseId && trackId;

  const isPortableCourseLink = !!useRouteMatch(frontend.portableCourse.pattern)?.isExact;
  const isPortableAcademyLessonLink = !!useRouteMatch(frontend.portableAcademyLesson.pattern)?.isExact;
  const { portableLinkName } = useParams<{ portableLinkName: string }>();

  if (!selectedOrgId || !selectedWsId) {
    return <LpOverlayLoader />;
  }

  if (courseId && trackId && isPortableCourseLink) {
    return (
      <Redirect
        to={{
          pathname: frontend.track.url({
            organizationId: selectedOrgId,
            workspaceId: selectedWsId,
            courseId,
            trackId,
          }),
          hash: location.hash,
        }}
      />
    );
  }

  if (isPortableAcademyLessonLink) {
    const { uuid } = dispatch(TrackLessonDao.findByName(portableLinkName, { includeCourseId: true }));
    dispatch(
      awaitRequestFinish<Readonly<AcademyTrackLesson>>(uuid, {
        onSuccess: ({ data, entities }) => {
          const trackId = data.academyTrackId;
          const lessonId = data.academyLessonId;
          const courseId = Object.getOwnPropertyNames(entities.academyCourses)[0];
          if (courseId) {
            history.push(
              frontend.track.url(
                {
                  organizationId: selectedOrgId,
                  workspaceId: selectedWsId,
                  courseId,
                  trackId,
                },
                { hash: { trackId, lessonId, courseId } }
              )
            );
          }
        },
        onError: () => {
          history.push(
            frontend.academy.url({
              organizationId: selectedOrgId,
              workspaceId: selectedWsId,
            })
          );
        },
      })
    );
    return <LpOverlayLoader />;
  }

  const derivedLessonId = lessonId === 'undefined' ? undefined : lessonId;

  return (
    <div className="lp-main">
      <GlobalNotificationBanner />
      <EmailConfirmationBanner />

      {showAcademyModal && !!courseId && trackId && (
        <AcademyModal courseId={courseId} trackId={trackId} lessonId={derivedLessonId} autoPlay={autoPlay === 'true'} />
      )}

      <div className="lp-main__container">
        <LeftNav />
        <div className="lp-main__content">
          <GenericErrorMessage />
          <ConnectedRoutes
            selectedWsId={selectedWsId}
            selectedOrgId={selectedOrgId}
            setEntityNotFound={setEntityNotFound}
          />
        </div>
      </div>
    </div>
  );
};
