import classNames from 'classnames';
import { LayoutGroup } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Menu, Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { SidebarOutpost } from 'features/academy/outpost';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { chevronDoubleLeftRegular, chevronDoubleRightRegular, LpIcon } from 'features/common/lp_icon';
import { GuestLeftNavMenu } from 'features/guest/left_nav/menu';
import FavoritesList from 'features/organization/left_nav/favorites/favorites_list';
import { reportLeftMenuSegmentOriginAction } from 'features/organization/left_nav/helpers';
import { LpLogo } from 'features/shared/components/lp_logo';
import { WorkspaceMenu } from 'features/workspace/menu';
import { SessionStorageKey, useSessionState } from 'hooks/use_session_state';
import { OriginAction } from 'lib/avo/client';
import { frontend } from 'lib/urls';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';

export const LeftNav = () => {
  const workspace = useSelector(getCurrentWorkspace);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const isGuestWsUser = useSelector(getIsGuestCurrentWorkspaceUser);
  const pathName = useLocation().pathname;

  const workspaceName = workspace?.name;

  const [leftNavExpanded, setLeftNavExpanded] = useSessionState(true, SessionStorageKey.LeftNavExpanded);
  const toggleLeftNavExpansion = () => {
    reportLeftMenuSegmentOriginAction({
      originAction: leftNavExpanded ? OriginAction.COLLAPSED : OriginAction.EXPANDED,
      workspaceId: workspace?.id ?? 0,
      pathName,
    });
    setLeftNavExpanded((prev) => !prev);
  };

  const leftNavMenu = isGuestWsUser ? (
    <GuestLeftNavMenu navExpanded={leftNavExpanded} />
  ) : (
    <WorkspaceMenu navExpanded={leftNavExpanded} />
  );

  return (
    <div className={classNames('lp-left-nav', !leftNavExpanded && 'lp-left-nav--collapsed')}>
      <LayoutGroup>
        <div className="lp-left-nav__top-section">
          <Menu attached className={classNames(leftNavExpanded && 'padded-right')} inverted secondary vertical>
            <Table attached className="lp-organization-top-controls transparent" inverted>
              <Table.Body>
                <Table.Row>
                  {leftNavExpanded && (
                    <Table.Cell className="lp-organization-top-controls__logo" textAlign="center">
                      <LpLogo logoType="core" />
                      {workspaceName && (
                        <LpLink
                          className="lp-organization-top-controls__workspace-name"
                          to={frontend.workspaceHub.url({
                            organizationId,
                            workspaceId,
                          })}
                        >
                          {workspaceName}
                        </LpLink>
                      )}
                    </Table.Cell>
                  )}

                  <Table.Cell collapsing={leftNavExpanded} textAlign="center">
                    <Button
                      basic
                      icon={
                        leftNavExpanded ? (
                          <LpIcon color="white" icon={chevronDoubleLeftRegular} size="lg" />
                        ) : (
                          <LpIcon color="white" icon={chevronDoubleRightRegular} size="lg" />
                        )
                      }
                      onClick={toggleLeftNavExpansion}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Menu>

          {leftNavMenu}
        </div>

        <div className="lp-left-nav__favorites-list-container">
          {!isGuestWsUser && <FavoritesList expanded={leftNavExpanded} />}
        </div>

        <SidebarOutpost leftNavExpanded={leftNavExpanded} />
      </LayoutGroup>
    </div>
  );
};
