import { ReactNode } from 'react';
import './feature_trial_simple_banner.scss';

export const FeatureTrialSimpleBanner = ({
  classname,
  bannerText,
  bannerButton,
}: {
  classname: string;
  bannerText: string;
  bannerButton: ReactNode;
}) => {
  return (
    <div className={`feature-trial-simple-banner ${classname}`}>
      <span className="feature-trial-simple-banner__text">{bannerText}</span>
      {bannerButton}
    </div>
  );
};
