import { useCallback, useEffect } from 'react';

import { useScrollContext } from 'containers/shared/helpers/scroll_context';
import { EditorAddEditGridProps } from 'features/common/data_grid/types';

export const useCloseEditorOnScrollListener = (onClose: EditorAddEditGridProps['onClose']) => {
  const { scrollableElement } = useScrollContext();

  const closeEditorOnScroll = useCallback(() => {
    onClose(true);
  }, [onClose]);

  useEffect(() => {
    const element = scrollableElement?.current;

    element?.addEventListener('scroll', closeEditorOnScroll);

    return () => {
      closeEditorOnScroll();
      element?.removeEventListener('scroll', closeEditorOnScroll);
    };
  }, [closeEditorOnScroll, scrollableElement]);
};
