import { SortableContext } from '@dnd-kit/sortable';
import { LayoutGroup } from 'framer-motion';
import { memo } from 'react';
import { areEqual } from 'react-window';

import { useDashboardContext } from 'features/dashboards_v2/context';
import { SortableWidget } from 'features/dashboards_v2/drag_and_drop/sortable_widget';
import { WidgetRenderer } from 'features/dashboards_v2/widget/renderer';
import { WidgetId } from 'features/dashboards_v2/widget/types';

interface WidgetsProps {
  dashboardId: string;
  widgetGroupId: string;
  widgetGroupIndex: number;
  widgetIds: Array<WidgetId>;
  isOverlay?: boolean;
}
export const Widgets = memo(({ dashboardId, widgetGroupId, widgetGroupIndex, widgetIds, isOverlay }: WidgetsProps) => {
  const { inDesign } = useDashboardContext();

  if (!inDesign || isOverlay) {
    return (
      <LayoutGroup>
        {widgetIds.map(({ widgetId }) => (
          <WidgetRenderer key={widgetId} dashboardId={dashboardId} widgetId={widgetId.toString()} />
        ))}
      </LayoutGroup>
    );
  }

  return (
    <SortableContext items={widgetIds} strategy={() => null}>
      <LayoutGroup>
        {widgetIds.map(({ widgetId }, index) => (
          <SortableWidget
            key={widgetId}
            dashboardId={dashboardId}
            widgetGroupId={widgetGroupId}
            widgetId={widgetId.toString()}
            index={index}
            widgetGroupIndex={widgetGroupIndex}
          />
        ))}
      </LayoutGroup>
    </SortableContext>
  );
}, areEqual);
