import { ReactNode, SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import ItemIcon, { PackageStatusIcon } from 'containers/shared/item_icon';
import ItemPickerModal from 'containers/shared/item_picker';
import { ItemType, PackageStatus, Permission } from 'daos/enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { LocationFilterItemSettings } from 'daos/types';
import { LpIcon, timesCircleSolid } from 'features/common/lp_icon';
import { getCurrentWorkspaceRootItem, getItemForId } from 'redux/entities/selectors/item';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';

import { LocationScope } from './types';

import './location.scss';

const LocationContent = ({
  disabled = false,
  onChange,
  value: { locationFilterItemId, locationFilterPackageStatus },
  placeholderText = '(not set)',
}: {
  disabled?: boolean;
  onChange: (value: LocationFilterItemSettings) => void;
  value: LocationFilterItemSettings;
  placeholderText?: ReactNode;
}) => {
  const workspace = useSelector(getCurrentWorkspace);
  const item = useSelector((state) =>
    locationFilterItemId ? getItemForId(state, parseInt(locationFilterItemId, 10)) : undefined
  );

  const isWorkspaceRootItemLocation = item?.itemType === ItemType.WORKSPACE_ROOTS;

  if (!workspace) {
    return null;
  }

  const handleClearLocation = (evt: SyntheticEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    onChange({
      locationFilterItemId: null,
      locationFilterPackageStatus: null,
    });
  };

  if (isWorkspaceRootItemLocation && !locationFilterPackageStatus) {
    return (
      <div className="dashboard-location-filter__content">
        <ItemIcon itemId={item.id} />
        <span className="item-name">{workspace?.name ?? 'Workspace Root'}</span>
        {!disabled && <LpIcon icon={timesCircleSolid} onClick={handleClearLocation} />}
      </div>
    );
  }

  if ((!item || isWorkspaceRootItemLocation) && locationFilterPackageStatus) {
    return (
      <div className="dashboard-location-filter__content">
        <PackageStatusIcon packageStatus={locationFilterPackageStatus} />
        <span className="item-name">{packageStatusDisplayNameByPackageStatus[locationFilterPackageStatus]}</span>
        {!disabled && <LpIcon icon={timesCircleSolid} onClick={handleClearLocation} />}
      </div>
    );
  }

  if (item) {
    return (
      <div className="dashboard-location-filter__content">
        <ItemIcon itemId={item.id} />
        <span className="item-name">{item.name}</span>
        {!disabled && <LpIcon icon={timesCircleSolid} onClick={handleClearLocation} />}
      </div>
    );
  }

  return (
    <div className="dashboard-location-filter__content dashboard-location-filter__content--disabled">
      <span className="item-name">{placeholderText}</span>
    </div>
  );
};

export const LocationFilter = ({
  disabled = false,
  disablePackageStatusSelection = false,
  disableMoveUpTreeAtItemId,
  disableMoveUpTreeAtPackageCollection = false,
  inheritedLocationScope,
  onChange,
  value,
  placeholderText,
}: {
  disabled?: boolean;
  disablePackageStatusSelection?: boolean;
  disableMoveUpTreeAtItemId?: number;
  disableMoveUpTreeAtPackageCollection?: boolean;
  inheritedLocationScope?: LocationScope;
  onChange: (value: LocationFilterItemSettings) => void;
  value: LocationFilterItemSettings;
  placeholderText?: ReactNode;
}) => {
  const [itemPickerOpen, setItemPickerOpen] = useState(false);
  const openItemPicker = () => setItemPickerOpen(true);
  const closeItemPicker = () => setItemPickerOpen(false);

  const { locationFilterItemId, locationFilterPackageStatus } = value;

  const workspaceRoot = useSelector(getCurrentWorkspaceRootItem);
  const item = useSelector((state) =>
    locationFilterItemId ? getItemForId(state, parseInt(locationFilterItemId, 10)) : undefined
  );

  const initialItem = item ?? inheritedLocationScope?.locationFilterItem ?? workspaceRoot;
  const defaultPackageStatus =
    locationFilterPackageStatus ?? inheritedLocationScope?.locationFilterPackageStatus ?? null;

  if (!initialItem) {
    return null;
  }

  const onSelectItem = (itemIds: ReadonlyArray<number>, packageStatus: PackageStatus | null) => {
    const workspaceRootId = workspaceRoot?.id.toString();
    const newLocationFilterItemId = itemIds[0]?.toString() ?? null;
    const newLocationFilterPackageStatus =
      !newLocationFilterItemId || newLocationFilterItemId === workspaceRootId ? packageStatus : null;

    onChange({
      locationFilterItemId: newLocationFilterItemId,
      locationFilterPackageStatus: newLocationFilterPackageStatus,
    });

    closeItemPicker();
  };

  return (
    <>
      <Button
        basic
        className="dashboard-location-filter icon"
        content={
          <LocationContent disabled={disabled} onChange={onChange} value={value} placeholderText={placeholderText} />
        }
        disabled={disabled}
        onClick={openItemPicker}
        type="button"
      />
      {itemPickerOpen && (
        <ItemPickerModal
          defaultPackageStatus={defaultPackageStatus}
          enablePackageStatusSelection={!disablePackageStatusSelection}
          enableParentSelection
          fetchItemsOnFirstRender
          filterItemTypes={[ItemType.PACKAGES, ItemType.PROJECTS, ItemType.FOLDERS]}
          initParentItem={initialItem}
          onCancel={closeItemPicker}
          onSelect={onSelectItem}
          requiredAccess={Permission.OBSERVE}
          disableMoveUpTreeAtItemId={disableMoveUpTreeAtItemId}
          disableMoveUpTreeAtPackageCollection={disableMoveUpTreeAtPackageCollection}
        />
      )}
    </>
  );
};
