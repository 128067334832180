import { request } from 'lib/api';

import { PicklistChoice } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { createBody, updateBody, resource } = defineModel({
  apiType: 'picklistChoices',
  relationships: ['field'],
  type: 'PICKLIST_CHOICE',
});

const { PICKLIST_CHOICE, resourceId } = resource;

interface PicklistChoicesParams {
  organizationId: number;
  workspaceId: number;
  fieldId: number;
}

interface SinglePicklistChoiceParams extends PicklistChoicesParams {
  picklistChoiceId: number;
}

const picklistChoiceCreate = (params: PicklistChoicesParams, picklistChoice: Partial<PicklistChoice>) =>
  request(backend.picklistChoices.url(params), PICKLIST_CHOICE, createBody(picklistChoice));

const picklistChoiceUpdate = (params: SinglePicklistChoiceParams, picklistChoice: Partial<PicklistChoice>) =>
  request(backend.picklistChoice.url(params), PICKLIST_CHOICE, updateBody(params.picklistChoiceId, picklistChoice));

export const PicklistChoiceDao = {
  create: picklistChoiceCreate,
  id: resourceId,
  update: picklistChoiceUpdate,
} as const;
