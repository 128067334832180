import { useFormikContext } from 'formik';

import { exclamationTriangleSolid, LpIcon } from 'features/common/lp_icon';
import { red500 } from 'style/variables';

export const IntakeFormErrors = () => {
  const { errors } = useFormikContext();

  const errorAmount = Object.keys(errors).length;

  return (
    <div className="intake-form__body-errors">
      {errorAmount ? (
        <>
          <LpIcon className="icon" color={red500} icon={exclamationTriangleSolid} />
          <p className="intake-form__body-errors-text">{`${errorAmount} Issues(s) Found`}</p>
        </>
      ) : null}
    </div>
  );
};
