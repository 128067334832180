import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ItemType } from 'daos/enums';
import { SelectionList } from 'features/common/selection_list/types';
import { resetStateExtraReducer } from 'redux/root_actions';

interface GridColumnOptions {
  [ItemType.WORKSPACE_ROOTS]: Array<SelectionList>;
  [ItemType.PACKAGES]: Array<SelectionList>;
  [ItemType.PROJECTS]: Array<SelectionList>;
  [ItemType.FOLDERS]: Array<SelectionList>;
  [ItemType.TASKS]: Array<SelectionList>;
  [ItemType.ASSIGNMENTS]: Array<SelectionList>;
}

interface DataGridState {
  readonly availableColumns: GridColumnOptions;
  readonly customTaskStatusIds?: Array<number>;
  readonly hiddenColumns: Array<string>;
  readonly itemIds: Array<number>;
  readonly itemType?: ItemType | undefined;
  readonly selectedColumns: GridColumnOptions;
  packageId?: number | undefined;
  projectId?: number | undefined;
}
export const initialState: DataGridState = {
  availableColumns: {
    [ItemType.WORKSPACE_ROOTS]: [],
    [ItemType.PACKAGES]: [],
    [ItemType.PROJECTS]: [],
    [ItemType.FOLDERS]: [],
    [ItemType.TASKS]: [],
    [ItemType.ASSIGNMENTS]: [],
  },
  hiddenColumns: ['id'],
  itemIds: [],
  selectedColumns: {
    [ItemType.WORKSPACE_ROOTS]: [],
    [ItemType.PACKAGES]: [],
    [ItemType.PROJECTS]: [],
    [ItemType.FOLDERS]: [],
    [ItemType.TASKS]: [],
    [ItemType.ASSIGNMENTS]: [],
  },
  packageId: undefined,
  projectId: undefined,
};

const itemDataGridSlice = createSlice({
  name: 'dataGrid',
  initialState: initialState,
  reducers: {
    clearDataGridItemIds: (state) => {
      state.itemIds = [];
    },
    clearDataGridSegmentData: (state) => {
      state.packageId = undefined;
      state.projectId = undefined;
    },
    clearGridColumns: (state) => {
      state.availableColumns = initialState.availableColumns;
      state.selectedColumns = initialState.selectedColumns;
      state.hiddenColumns = initialState.hiddenColumns;
    },
    setDataGridPackageId: (state, action: PayloadAction<number>) => {
      state.packageId = action.payload;
    },
    setDataGridProjectId: (state, action: PayloadAction<number>) => {
      state.projectId = action.payload;
    },
    setAvailableColumnsForItemType: (
      state,
      action: PayloadAction<{ itemType: ItemType; availableColumns: Array<SelectionList> }>
    ) => {
      state.availableColumns[action.payload.itemType] = action.payload.availableColumns;
    },
    addToDataGridItemIds: (state, action: PayloadAction<Array<number>>) => {
      state.itemIds = [...new Set(state.itemIds.concat(action.payload))];
    },
    setDataGridItemIds: (state, action: PayloadAction<Array<number>>) => {
      state.itemIds = action.payload;
    },
    setDataGridItemType: (state, action: PayloadAction<ItemType | undefined>) => {
      state.itemType = action.payload;
    },
    setSelectedColumnsForItemType: (
      state,
      action: PayloadAction<{ itemType: ItemType; selectedColumns: Array<SelectionList> }>
    ) => {
      state.selectedColumns[action.payload.itemType] = action.payload.selectedColumns;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const {
  clearDataGridItemIds,
  clearGridColumns,
  setAvailableColumnsForItemType,
  addToDataGridItemIds,
  setDataGridItemIds,
  setDataGridItemType,
  setSelectedColumnsForItemType,
  setDataGridPackageId,
  setDataGridProjectId,
  clearDataGridSegmentData,
} = itemDataGridSlice.actions;

export default itemDataGridSlice.reducer;
