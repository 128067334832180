const firstEntityNameFormatted = (entityName: string) => <strong>{entityName}</strong>;

const lastEntityNameFormatted = (entityName: string) => (
  <span>
    {' and'} <strong>{entityName}</strong>
  </span>
);

const middleEntityNameFormatted = (entityName: string) => (
  <span>
    , <strong>{entityName}</strong>
  </span>
);

export const joinEntityNames = (entityNames: Array<string>) => {
  if (!entityNames.length) {
    return [<strong key="unknown">Unknown</strong>];
  }

  return entityNames.map((name, index) => {
    if (index === 0) {
      return firstEntityNameFormatted(name);
    }

    if (index === entityNames.length - 1) {
      return lastEntityNameFormatted(name);
    }

    return middleEntityNameFormatted(name);
  });
};
