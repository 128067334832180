import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { RelativePriorityType } from 'daos/item_enums';
import { WidgetGroupDao } from 'daos/widget_groups';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LpIcon, plusCircleRegular } from 'features/common/lp_icon';
import { awaitRequestFinish } from 'lib/api';

interface AddWidgetGroupButtonProps {
  dashboardId: string;
  relativePriorityType: RelativePriorityType;
}

export const AddWidgetGroupButton = ({ dashboardId, relativePriorityType }: AddWidgetGroupButtonProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  function addWidgetGroup() {
    const { uuid } = dispatch(
      WidgetGroupDao.create(
        {
          organizationId,
          workspaceId,
          dashboardId,
        },
        { relativePriority: { type: relativePriorityType } }
      )
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }
  return (
    <Button primary className="add-widget-group-button" onClick={addWidgetGroup}>
      <LpIcon icon={plusCircleRegular} /> Group
    </Button>
  );
};
