import { PackageStatus } from 'daos/enums';
import {
  clockSolid,
  columnsSolid,
  tableLight,
  starSolid,
  coffeeSolid as waitingIconSolid,
  magnifyingGlassLocationLight,
  userGroupSolid,
} from 'features/common/lp_icon';

export enum NoContentLocationText {
  CollectionView = 'SCHEDULED',
  PendingView = 'PENDING',
  ArchiveView = 'ARCHIVE',
  ProjectsView = 'Projects View',
  PackageView = 'Package View',
  ProjectView = 'Project View',
  ProjectsScheduled = 'Projects / SCHEDULED',
  ProjectManager = 'Project Manager',
  Groups = 'People / Groups',
  Favorites = '',
}

export enum NoContentLocation {
  GroupBoard = 'group-board',
  MemberBoard = 'member-board',
  MemberSearch = 'member-search',
  MyWorkBoard = 'my-work-board',
  MyWorkSearch = 'my-work-search',
  MyWorkTimesheet = 'my-work-timesheet',
  MyWorkTimesheetReview = 'my-work-timesheet-review',
  MyWorkWorkload = 'my-work-workload',
  MemberWorkload = 'member-workload',
  PackageWorkload = 'package-workload',
  PortfolioWorkload = 'portfolio-workload',
  ProjectBoard = 'project-board',
  ProjectGrid = 'project-grid',
  ProjectSearch = 'project-search',
  PackageSearch = 'package-search',
  PortfolioScheduledSearch = 'portfolio-scheduled-search',
  PortfolioPendingSearch = 'portfolio-pending-search',
  PortfolioArchiveSearch = 'portfolio-archive-search',
  CollectionsSearch = 'collections-search',
  ProjectWorkload = 'project-workload',
  Favorites = 'favorites',
  PortfolioBoard = 'portfolio-board',
  Capacity = 'capacity-timeline',
}

const projectsViewText = {
  defaultInfoText: 'There is no work scheduled',
  moveText: 'Go to',
  locationText: NoContentLocationText.ProjectsView,
  actionText: 'to start adding projects',
};

const collectionSearchViewText = {
  defaultInfoText: 'Nothing to search here',
  moveText: 'Go to',
  locationText: NoContentLocationText.CollectionView,
  actionText: 'to start adding projects',
};

const collectionPendingViewText = {
  defaultInfoText: 'Nothing to search here',
  moveText: 'No projects have been added to',
  locationText: NoContentLocationText.PendingView,
  actionText: 'yet',
};
const collectionArchiveViewText = {
  defaultInfoText: 'Nothing to search here',
  moveText: 'No projects have been added to',
  locationText: NoContentLocationText.ArchiveView,
  actionText: 'yet',
};

const packageViewText = {
  defaultInfoText: 'There is no work scheduled',
  moveText: 'Go to',
  locationText: NoContentLocationText.PackageView,
  actionText: 'to start adding projects',
};

const packageSearchViewText = {
  defaultInfoText: 'Nothing to search here',
  moveText: 'Go to',
  locationText: NoContentLocationText.PackageView,
  actionText: 'to start adding projects ',
};

const projectHubViewText = {
  defaultInfoText: 'There is no work scheduled',
  moveText: 'Go to the',
  locationText: NoContentLocationText.ProjectView,
  actionText: 'to add tasks, assign people and estimate',
};

const myWorkViewText = {
  defaultInfoText: 'There is no work scheduled for you',
  moveText: 'Go to',
  locationText: NoContentLocationText.ProjectsScheduled,
  actionText: 'to explore',
};

const timesheetReviewText = {
  defaultInfoText: 'Add groups or members to this page so you can review their timesheets',
  moveText: 'You will need ',
  locationText: NoContentLocationText.ProjectManager,
  actionText: 'or higher access to their Projects',
};

const groupViewText = {
  defaultInfoText: 'There is no work scheduled for the Group',
  moveText: 'Go to',
  locationText: NoContentLocationText.ProjectsScheduled,
  actionText: 'to add tasks, assign people and estimate',
};

const memberViewText = {
  defaultInfoText: 'There is no work scheduled for this member',
  moveText: 'Go to',
  locationText: NoContentLocationText.ProjectsScheduled,
  actionText: 'to add tasks, assign people and estimate',
};

const favoritesViewText = {
  defaultInfoText: "You haven't set favorites yet",
  moveText: 'Click the star at the top of a view to set it as a favorite',
  locationText: NoContentLocationText.Favorites,
  actionText: '',
};

const CapacityViewText = {
  defaultInfoText: 'Create groups to allocate projects',
  moveText: 'Go to',
  locationText: NoContentLocationText.Groups,
  actionText: 'to explore',
};

export const noContentViewBasedOnPackageStatus = (packageStatus: PackageStatus | undefined) => {
  switch (packageStatus) {
    case PackageStatus.SCHEDULED:
      return NoContentLocation.PortfolioScheduledSearch;
    case PackageStatus.ARCHIVED:
      return NoContentLocation.PortfolioArchiveSearch;
    case PackageStatus.BACKLOG:
      return NoContentLocation.PortfolioPendingSearch;

    default:
      return NoContentLocation.PortfolioScheduledSearch;
  }
};

export const noContentLocationDisplay = (location: NoContentLocation) => {
  switch (location) {
    case NoContentLocation.PortfolioWorkload:
      return { icon: waitingIconSolid, text: projectsViewText };
    case NoContentLocation.PackageWorkload:
      return { icon: waitingIconSolid, text: packageViewText };
    case NoContentLocation.ProjectWorkload:
      return { icon: waitingIconSolid, text: projectHubViewText };
    case NoContentLocation.MyWorkWorkload:
      return { icon: waitingIconSolid, text: myWorkViewText };
    case NoContentLocation.MemberWorkload:
      return { icon: waitingIconSolid, text: memberViewText };
    case NoContentLocation.Favorites:
      return { icon: starSolid, text: favoritesViewText };

    case NoContentLocation.PortfolioBoard:
      return { icon: columnsSolid, text: projectsViewText };
    case NoContentLocation.ProjectBoard:
      return { icon: columnsSolid, text: projectHubViewText };
    case NoContentLocation.MyWorkBoard:
      return { icon: columnsSolid, text: myWorkViewText };
    case NoContentLocation.GroupBoard:
      return { icon: columnsSolid, text: groupViewText };
    case NoContentLocation.MemberBoard:
      return { icon: columnsSolid, text: memberViewText };

    case NoContentLocation.MyWorkTimesheet:
      return { icon: clockSolid, text: myWorkViewText };
    case NoContentLocation.MyWorkTimesheetReview:
      return { icon: clockSolid, text: timesheetReviewText };

    case NoContentLocation.ProjectGrid:
      return { icon: tableLight, text: projectHubViewText };

    case NoContentLocation.ProjectSearch:
      return {
        icon: magnifyingGlassLocationLight,
        text: { ...projectHubViewText, defaultInfoText: 'Nothing to search here' },
      };
    case NoContentLocation.PackageSearch:
      return { icon: magnifyingGlassLocationLight, text: { ...packageSearchViewText } };
    case NoContentLocation.PortfolioScheduledSearch:
      return { icon: magnifyingGlassLocationLight, text: { ...collectionSearchViewText } };
    case NoContentLocation.PortfolioPendingSearch:
      return { icon: magnifyingGlassLocationLight, text: collectionPendingViewText };
    case NoContentLocation.PortfolioArchiveSearch:
      return { icon: magnifyingGlassLocationLight, text: collectionArchiveViewText };
    case NoContentLocation.CollectionsSearch:
      return { icon: magnifyingGlassLocationLight, text: { ...collectionSearchViewText } };
    case NoContentLocation.MyWorkSearch:
      return { icon: magnifyingGlassLocationLight, text: myWorkViewText };
    case NoContentLocation.MemberSearch:
      return { icon: magnifyingGlassLocationLight, text: memberViewText };

    case NoContentLocation.Capacity:
      return { icon: userGroupSolid, text: CapacityViewText };

    default:
      return { icon: waitingIconSolid, text: projectHubViewText };
  }
};
