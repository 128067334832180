import { ReactNode } from 'react';

import { PackageStatus } from 'daos/enums';
import { Item, LibraryResource } from 'daos/model_types';

import { LibraryRowSortColumn } from './enum';

type LibraryTableRow = LibraryResource | Item | PackageStatus;

export enum LibraryTableRowType {
  Dashboard = 'dashboard',
  Collection = 'collection',
  Report = 'report',
  Item = 'item',
}

export interface LibraryVirtualizedTableRow {
  rowItem: LibraryTableRow;
  displayName: string;
  libraryRowType: LibraryTableRowType;
  updatedAt: string;
  updatedAtDateText: string;
  itemId: number;
  updatedByUserName: string;
  favoriteId: number | undefined;
}

export interface SortDirectionInfo {
  sortColumn: LibraryRowSortColumn;
  isAscending: boolean;
  isDefaultSort: boolean;
}

export interface LibraryDeleteModalProps {
  content: ReactNode;
  deleteAction: (followUpAction: () => void) => void;
}

export enum LibraryModals {
  Dashboard = 'dashboard',
  Reports = 'reports',
}

export enum LibraryLocation {
  Workspace = 'workspace',
  Collection = 'collection',
  Package = 'package',
  Project = 'project',
}
