import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { InvitationState, MarketingConsentStatus } from './enums';
import { Dashboard, Organization, ResourceId, Workspace } from './model_types';
import { defineModel, filterEq, filterOr, reduceIncludedOptions } from './shared';
import { InvitationWorkspaceAccess } from './types';
import { backend } from './urls';

const { createBody, updateBody, resource } = defineModel({
  apiType: 'invitations',
  relationships: ['workspaces'],
  type: 'INVITATION',
});

const { INVITATION } = resource;

interface InvitationIncludes {
  includeCreatedBy?: boolean;
  includeReceiver?: boolean;
  includeWorkspace?: boolean;
  includeDashboards?: boolean;
}
const includes = ({
  includeCreatedBy,
  includeReceiver,
  includeWorkspace,
  includeDashboards,
}: InvitationIncludes = {}) =>
  reduceIncludedOptions([
    includeCreatedBy && 'createdBy',
    includeReceiver && 'receiver',
    includeWorkspace && 'workspace.organization',
    includeDashboards && 'dashboard',
  ]);

interface InvitationGetProps {
  token: string;
  include?: InvitationIncludes;
}

interface InvitationPatchProps {
  email: string;
  firstName: string;
  lastName: string;
  invitationId: number;
  locale?: string;
  timezone?: string;
  password?: string;
  marketingConsent?: MarketingConsentStatus;
  tosAccepted: boolean;
}

interface WorkspaceInvitationCreateProps {
  email: string;
  organization: ResourceId<Organization>;
  workspaces: Array<ResourceId<Workspace>>;
  workspaceAccess?: InvitationWorkspaceAccess; //undefined means use the WS default for 'all members'
}
interface GuestInvitationCreateProps {
  email: string;
  organization: ResourceId<Organization>;
  workspaces: Array<ResourceId<Workspace>>;
  dashboards: Array<ResourceId<Dashboard>>;
}

const GUEST_ACCESS = 'guest';

const createWorkspace = (invitation: WorkspaceInvitationCreateProps) =>
  request(backend.invitations.url({}), INVITATION, createBody(invitation));

const createGuest = (invitation: GuestInvitationCreateProps) =>
  request(backend.guestInvitations.url({}), INVITATION, createBody({ ...invitation, workspaceAccess: GUEST_ACCESS }));

const invitationGet = ({ token, include }: InvitationGetProps) =>
  request(backend.invitationsAccept.url({ token }, { include: includes(include) }), INVITATION, {
    method: HttpMethod.GET,
    meta: { normalizeIncludedEntitiesOnly: true },
  });

const accept = ({ token }: { token: string }, { invitationId, ...invitation }: InvitationPatchProps) =>
  request(
    backend.invitationsAccept.url(
      {
        token,
      },
      { include: ['receiver.dashboardGuests'] }
    ),
    INVITATION,
    updateBody(invitationId, { ...invitation, state: InvitationState.Accepted })
  );

const getPending = ({
  organizationId,
  filterExpiredInvitations,
  include,
}: {
  organizationId: number;
  filterExpiredInvitations: boolean;
  include?: InvitationIncludes;
}) =>
  request(
    backend.organizationInvitations.url(
      { organizationId },
      {
        include: includes(include),
        filter: filterExpiredInvitations
          ? filterOr(filterEq('state', InvitationState.Sent), filterEq('state', InvitationState.Expired))
          : filterEq('state', InvitationState.Sent),
      }
    ),
    INVITATION,
    { method: HttpMethod.GET }
  );

const revoke = ({ organizationId, invitationId }: { organizationId: number; invitationId: number }) =>
  request(
    backend.organizationInvitation.url({
      organizationId,
      invitationId,
    }),
    INVITATION,
    updateBody(invitationId, { state: 'revoked' })
  );

const resend = ({ organizationId, invitationId }: { organizationId: number; invitationId: number }) =>
  request(backend.organizationInvitation.url({ organizationId, invitationId }), INVITATION, createBody({}));

export const InvitationDao = {
  create: createWorkspace,
  createGuest,
  get: invitationGet,
  accept,
  revoke,
  getPending,
  resend,
} as const;
