import classNames from 'classnames';
import { Button } from 'semantic-ui-react';

import { LpIcon, checkSquareSolid, squareRegular } from 'features/common/lp_icon';

import 'features/common/inputs/include_done_button/index.scss';

interface IncludeDoneButtonProps {
  className?: string;
  isChecked: boolean;
  handleClick: () => void;
  text?: string;
}

const IncludeDoneButton = ({ className, handleClick, isChecked, text = 'Include Done' }: IncludeDoneButtonProps) => (
  <Button
    className={classNames('lp-include-done-button', className, { 'lp-include-done-button--active': isChecked })}
    content={
      <span className={'lp-include-done-button__text'}>
        <LpIcon icon={isChecked ? checkSquareSolid : squareRegular} /> {text}
      </span>
    }
    onClick={handleClick}
    size="small"
    type="button"
  />
);

export default IncludeDoneButton;
