import classNames from 'classnames';

import { IconDefinition, LpIcon } from 'features/common/lp_icon';

import './index.scss';

interface InvitationHeaderProps {
  headerText: string;
  headerSubtext: string | JSX.Element;
  isOrgDirPage?: boolean;
  icon?: IconDefinition;
}

export const InvitationHeader = ({ icon, headerText, headerSubtext, isOrgDirPage = false }: InvitationHeaderProps) => (
  <div className="invitation-header">
    {icon && (
      <div className={classNames('invitation-header__tile', isOrgDirPage && 'invitation-header__tile--org-dir')}>
        <LpIcon size="4x" className="invitation-header__tile-icon" icon={icon} />
      </div>
    )}
    <span className="invitation-header__top">
      <h1 className="invitation-header__top-text">{headerText}</h1>
    </span>
    <p className="invitation-header__bottom">{headerSubtext}</p>
  </div>
);
