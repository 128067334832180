import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import LpLink from 'containers/shared/lp_link';
import { ChangeOrigin } from 'daos/enums';
import { getDashboardForId, getWidgetForId } from 'features/dashboards/selectors';
import { useControlledGoBackToDashboardUrl } from 'features/dashboards_v2/common/use_go_back_to_dashboard_url';
import { IntakeTypeDisplay } from 'features/dashboards_v2/widget/widgets/intake/helpers';
import { getChangeForId } from 'redux/entities/selectors/changes';
import { getItemForId } from 'redux/entities/selectors/item';

const DashboardLink = ({ intakeWidgetId }: { intakeWidgetId: string }) => {
  const { organizationId, workspaceId } = useParams<{
    organizationId: string;
    workspaceId: string;
  }>();
  const intakeWidget = useSelector((state) => getWidgetForId(state, intakeWidgetId));

  const intakeWidgetDashboardId = intakeWidget?.dashboardId ?? '';
  const intakeWidgetDashboard = useSelector((state) => getDashboardForId(state, intakeWidgetDashboardId));

  const itemId = intakeWidgetDashboard?.itemId || '';
  const item = useSelector((state) => (itemId ? getItemForId(state, Number(itemId)) : undefined));

  const linkToDashBoard = useControlledGoBackToDashboardUrl({
    organizationId,
    workspaceId,
    dashboardId: intakeWidgetDashboardId,
    itemId,
  });

  if (!intakeWidget || !intakeWidgetDashboard) {
    return null;
  }

  const dashboardName = intakeWidgetDashboard.name ?? item?.name ?? '';

  const { title, intakeType } = intakeWidget.config;

  const intakeDisplayName = intakeType ? IntakeTypeDisplay[intakeType] : 'Item';
  const widgetName = title ?? `New ${intakeDisplayName}`;

  return (
    <div className="change-property__description-origin">
      <LpLink to={linkToDashBoard}>
        <div>{dashboardName}</div>
      </LpLink>
      / <div>{widgetName}</div>
    </div>
  );
};

const Origin = ({ widgetId, changeOrigin }: { widgetId: string; changeOrigin: ChangeOrigin | undefined | null }) => {
  switch (changeOrigin) {
    case ChangeOrigin.IntakeWidget:
      return <DashboardLink intakeWidgetId={widgetId} />;
    case ChangeOrigin.PublicApi:
      return <>API</>;
    default:
      return null;
  }
};

const shouldShowChangeOrigin = (changeOrigin: ChangeOrigin) =>
  [ChangeOrigin.IntakeWidget, ChangeOrigin.PublicApi].includes(changeOrigin);

export const OriginRow = ({ changeId }: { changeId: number }) => {
  const change = useSelector((state) => getChangeForId(state, changeId));

  const changeOrigin = change?.origin;
  const showOriginRow = !!changeOrigin && shouldShowChangeOrigin(changeOrigin);

  if (!showOriginRow) {
    return null;
  }

  return (
    <div className="lp-change__card-content-table-simple-description-cell">
      <div className="change-property__name">VIA:</div>
      <div className="change-property__description">
        <Origin widgetId={change.widgetId?.id.toString() ?? ''} changeOrigin={changeOrigin} />
      </div>
    </div>
  );
};
