import { WorkspaceLeftNavMenuUrlKeys } from 'features/workspace/enums';
import { OriginAction, OriginActionValueType, OriginPageValueType, avoClient } from 'lib/avo/client';
import { mapLocationPathnameToLpPageName } from 'lib/url_helpers';

export function urlKeyToSegmentOriginAction(urlKey: WorkspaceLeftNavMenuUrlKeys): OriginActionValueType {
  switch (urlKey) {
    case WorkspaceLeftNavMenuUrlKeys.DashboardPassport:
      return OriginAction.CLICKED_ON_DASHBOARD_PASSPORT;
    case WorkspaceLeftNavMenuUrlKeys.Academy:
      return OriginAction.CLICKED_ON_ACADEMY;
    case WorkspaceLeftNavMenuUrlKeys.Projects:
      return OriginAction.CLICKED_ON_PROJECTS;
    case WorkspaceLeftNavMenuUrlKeys.Iterations:
      return OriginAction.CLICKED_ON_ITERATIONS_LINK;
    case WorkspaceLeftNavMenuUrlKeys.People:
      return OriginAction.CLICKED_ON_PEOPLE;
    case WorkspaceLeftNavMenuUrlKeys.Library:
      return OriginAction.CLICKED_ON_LIBRARY;
    case WorkspaceLeftNavMenuUrlKeys.Administration:
      return OriginAction.CLICKED_ON_ADMINISTRATION;
    case WorkspaceLeftNavMenuUrlKeys.MyWork:
      return OriginAction.CLICKED_ON_MY_WORK;
    case WorkspaceLeftNavMenuUrlKeys.MyDiscussions:
      return OriginAction.CLICKED_ON_MY_DISCUSSIONS;
    case WorkspaceLeftNavMenuUrlKeys.Favorites:
      return OriginAction.CLICKED_ON_MY_FAVORITES;
    case WorkspaceLeftNavMenuUrlKeys.PremiumFeaturesTrial:
      return OriginAction.CLICKED_ON_PREMIUM_FEATURES_TRIAL;
    case WorkspaceLeftNavMenuUrlKeys.UltimateTrial:
      return OriginAction.CLICKED_ON_ULTIMATE_TRIAL;
  }
}

export const getGuestLeftNavOriginAction = (name: string | WorkspaceLeftNavMenuUrlKeys) => {
  if (Object.values(WorkspaceLeftNavMenuUrlKeys).includes(name as WorkspaceLeftNavMenuUrlKeys)) {
    return urlKeyToSegmentOriginAction(name as WorkspaceLeftNavMenuUrlKeys);
  }
  return OriginAction.CLICKED_ON_WORKSPACE_LINK;
};

export const reportLeftMenuItemClickedToSegment = ({
  name,
  workspaceId,
  pathName,
}: {
  name: string | WorkspaceLeftNavMenuUrlKeys;
  workspaceId: number;
  pathName: string;
}) => {
  const originAction = getGuestLeftNavOriginAction(name);

  return reportLeftMenuSegmentOriginAction({
    originAction,
    workspaceId,
    pathName,
  });
};

export const reportLeftMenuSegmentOriginAction = ({
  originAction,
  workspaceId,
  pathName,
  favoriteType,
  numberOfFavorites,
}: {
  originAction: OriginActionValueType;
  workspaceId: number;
  pathName: string;
  favoriteType?: string;
  numberOfFavorites?: number;
}) => {
  const { pageName } = mapLocationPathnameToLpPageName(pathName);

  avoClient?.leftNavigationPaneClicked({
    originAction,
    workspaceId: workspaceId.toString(),
    originPage: pageName as OriginPageValueType,
    numberOfFavorites,
    favoriteType,
  });
};

export const reportFavoriteUpdateToSegment = ({
  originAction,
  workspaceId,
  pathName,
  favoriteType,
}: {
  originAction: OriginActionValueType;
  workspaceId: number;
  pathName: string;
  favoriteType?: string;
}) => {
  const { pageName } = mapLocationPathnameToLpPageName(pathName);

  avoClient?.favoriteUpdated({
    originAction: originAction,
    workspaceId: workspaceId.toString(),
    favoriteType: favoriteType ?? '',
    originPage: pageName as OriginPageValueType,
  });
};
