import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';

import 'features/authentication/unauthenticated/token/return_to_signin/index.scss';

export const ReturnToSignIn = () => {
  return (
    <div className="return-to-signin__nav-text">
      {"Don't need a link?"} <LpLink to={frontend.login.url({})}>Return to Sign In</LpLink>
    </div>
  );
};
