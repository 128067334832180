import { WorkspaceUser } from 'daos/model_types';
import { EntityLookupById } from 'redux/entities/types';

export const wsUsersWorkspaceTallies = (wsUsersById: EntityLookupById<WorkspaceUser>) => {
  const result: Map<number, number> = new Map<number, number>();

  for (const wsUserId in wsUsersById) {
    const userId = wsUsersById[wsUserId]?.user.id;
    const workspaceUser = wsUsersById[wsUserId];

    let currentCount = userId ? result.get(userId) ?? 0 : 0;

    if (workspaceUser && !workspaceUser.disconnectedAt) {
      currentCount++;
    }

    if (userId) {
      result.set(userId, currentCount);
    }
  }

  return result;
};

export const wsUserWorkspaceTally = (
  wsUsersById: EntityLookupById<WorkspaceUser>,
  targetUserId: number
): number | undefined => {
  const totalTallies = wsUsersWorkspaceTallies(wsUsersById);
  return totalTallies.get(targetUserId);
};
