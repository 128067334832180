import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import CustomFieldLinkForm from 'features/common/custom_fields/custom_field_value/custom_field_link_form';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import RemoveSoleFieldValueCell, {
  RemoveSoleFieldValueCellSize,
} from 'features/common/custom_fields/custom_field_value/remove_sole_field_value_cell';
import { LpIcon, pencilLight } from 'features/common/lp_icon';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import NewWindowLink from 'lib/display_helpers/url_links';
import { getFieldValueForId } from 'redux/entities/selectors/custom_field';

import 'features/common/custom_fields/custom_field_value/custom_field_value.scss';

const CustomFieldLinkValue = ({
  allowMultipleValues,
  fieldValueId,
  fieldId,
  resourceId,
  disabled = false,
}: CustomFieldValueProps & {
  fieldValueId?: number;
  allowMultipleValues: boolean;
}) => {
  const fieldValue = useSelector((state) => (fieldValueId ? getFieldValueForId(state, fieldValueId) : undefined));
  const { updateFieldValue, createFieldValue } = useCustomFieldsApiActions(resourceId.type);

  const [open, setOpen] = useState(false);

  const handleAddEditButtonClick = () => setOpen(true);
  const handleFormClose = () => setOpen(false);

  const isDisabledForAddingLink = disabled && !fieldValue;
  const fieldValueUrl = fieldValue?.url ?? '';

  const cellContent = fieldValue ? (
    <NewWindowLink className="custom-fields-link-span" to={fieldValueUrl}>
      {fieldValue.text || fieldValueUrl}
    </NewWindowLink>
  ) : (
    <span onClick={handleAddEditButtonClick}>{allowMultipleValues ? 'Add' : 'None'}</span>
  );

  const handleSubmit = useCallback(
    (payload: { url: string; text: string }) => {
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else {
        createFieldValue(resourceId.id, fieldId, payload);
      }
      handleFormClose();
    },
    [createFieldValue, fieldId, fieldValue, resourceId, updateFieldValue]
  );

  return (
    <span className={allowMultipleValues ? 'lp-custom-field-value__multi' : 'lp-custom-field-value'}>
      <Button.Group className="lp-custom-field-value__button-group">
        <Button
          className="lp-custom-field-value__button-group-button"
          size="mini"
          content={cellContent}
          disabled={isDisabledForAddingLink}
        />

        <Button
          className="lp-custom-field-value__button-group-button icon"
          size="mini"
          onClick={handleAddEditButtonClick}
          icon={<LpIcon icon={pencilLight} />}
          disabled={disabled}
        />
      </Button.Group>

      {allowMultipleValues && fieldValue && fieldValue.text !== '' && (
        <RemoveSoleFieldValueCell
          size={RemoveSoleFieldValueCellSize.Button}
          valueIds={[fieldValue.id]}
          disabled={disabled}
        />
      )}

      {open && <CustomFieldLinkForm onSubmit={handleSubmit} fieldValueId={fieldValueId} onClose={handleFormClose} />}
    </span>
  );
};

export default CustomFieldLinkValue;
