import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { ColumnIndex } from 'containers/shared/custom_column/enum';
import { UserColumn } from 'containers/shared/custom_column/types';
import { PackageStatus } from 'daos/enums';
import { Item } from 'daos/model_types';
import { itemGlobalPrioritySort } from 'lib/helpers';
import { ReadonlyRecord } from 'lib/readonly_record';
import { resetStateExtraReducer } from 'redux/root_actions';

interface CollapsePackages {
  readonly ids: ReadonlyArray<number>;
  readonly collapse: boolean;
}

export interface PortfolioGridState {
  readonly collapsePackages: ReadonlyRecord<string, boolean>;
  readonly itemsByPackageId: ReadonlyRecord<string, ReadonlyArray<number>>;
  readonly packageIds: ReadonlyArray<number>;
  readonly packageStatus?: PackageStatus;
  readonly userSelectableColumns: ReadonlyArray<UserColumn>;
}

export const initialState: PortfolioGridState = {
  collapsePackages: {},
  itemsByPackageId: {},
  packageIds: [],
  packageStatus: undefined,
  userSelectableColumns: [],
};

const portfolioGridSlice = createSlice({
  name: 'portfolioGrid',
  initialState,
  reducers: {
    addPortfolioGridPackage: (state, action: PayloadAction<Item>) => {
      state.itemsByPackageId[action.payload.id] = [];
      state.packageIds.push(action.payload.id);
    },
    clearPortfolioData: () => initialState,
    clearPortfolioGridItems: (state) => {
      state.packageIds = [];
      state.itemsByPackageId = {};
    },
    deletePortfolioGridPackage: (state, action: PayloadAction<number>) => {
      delete state.itemsByPackageId[action.payload];
      state.packageIds = state.packageIds.filter((pkgId) => pkgId !== action.payload);
    },
    reprioritizePortfolioGridProjects: (
      state,
      action: PayloadAction<{
        destinationPkgId: number;
        movedItemId: number;
        prioritizedItemIds: ReadonlyArray<number>;
        sourcePkgId: number;
      }>
    ) => {
      const sourcePkgId = action.payload.sourcePkgId;
      const destinationPkgId = action.payload.destinationPkgId;
      if (sourcePkgId === destinationPkgId) {
        state.itemsByPackageId[destinationPkgId] = castDraft(action.payload.prioritizedItemIds);
        return;
      }

      const sourceProjects = state.itemsByPackageId[sourcePkgId];
      if (sourceProjects) {
        state.itemsByPackageId[sourcePkgId] = sourceProjects.filter(
          (projectId) => projectId !== action.payload.movedItemId
        );
        state.itemsByPackageId[destinationPkgId] = castDraft(action.payload.prioritizedItemIds);
      }
    },
    reprioritizeMultiplePortfolioGridProjects: (
      state,
      action: PayloadAction<Record<string, ReadonlyArray<number>>>
    ) => {
      state.itemsByPackageId = castDraft(action.payload);
    },
    setPortfolioGridPackageIds: (state, action: PayloadAction<ReadonlyArray<number>>) => {
      state.packageIds = castDraft(action.payload);
    },
    setPortfolioGridPackageStatus: (state, action: PayloadAction<PackageStatus>) => {
      state.packageStatus = action.payload;
    },
    setUserSelectableColumns: (state, action: PayloadAction<Array<UserColumn>>) => {
      state.userSelectableColumns = action.payload;
    },
    setUserSelectableColumnForIndex: (state, action: PayloadAction<{ column: UserColumn; index: ColumnIndex }>) => {
      state.userSelectableColumns[action.payload.index] = action.payload.column;
    },
    setPortfolioGridCollapsePackage: (state, action: PayloadAction<CollapsePackages>) => {
      action.payload.ids.forEach((packageId) => {
        state.collapsePackages[packageId] = action.payload.collapse;
      });
    },
    setPortfolioGridPackageData: (state, action: PayloadAction<ReadonlyArray<Item>>) => {
      const prioritizedPackageIds = [...action.payload].sort(itemGlobalPrioritySort).map((pkg) => pkg.id);
      state.packageIds = prioritizedPackageIds;
    },
    setPortfolioGridProjectData: (state, action: PayloadAction<ReadonlyArray<Item>>) => {
      state.itemsByPackageId = populateItemsByPackageIdFromPayload(state, action);
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const {
  clearPortfolioData,
  clearPortfolioGridItems,
  deletePortfolioGridPackage,
  reprioritizeMultiplePortfolioGridProjects,
  reprioritizePortfolioGridProjects,
  setPortfolioGridCollapsePackage,
  setPortfolioGridPackageData,
  setPortfolioGridPackageIds,
  setPortfolioGridPackageStatus,
  setPortfolioGridProjectData,
  setUserSelectableColumns,
  setUserSelectableColumnForIndex,
} = portfolioGridSlice.actions;
export default portfolioGridSlice.reducer;

function populateItemsByPackageIdFromPayload(state: PortfolioGridState, action: PayloadAction<ReadonlyArray<Item>>) {
  const prioritizedItems = [...action.payload].sort(itemGlobalPrioritySort);
  const itemsByPackageId = state.packageIds
    .filter((pkgId) => prioritizedItems.find((item) => item.parent?.id === pkgId))
    .reduce((acc: Record<string, Array<number>>, pkgId) => {
      acc[pkgId] = [];
      return acc;
    }, {});

  prioritizedItems.forEach((item) => {
    const itemsForParentId = itemsByPackageId[item.parent?.id ?? ''];
    if (itemsForParentId) {
      itemsForParentId.push(item.id);
      return;
    }
    itemsByPackageId[item.parent?.id ?? ''] = [item.id];
    return;
  });
  return itemsByPackageId;
}
