import { FormEvent, SyntheticEvent } from 'react';
import { CheckboxProps, Form } from 'semantic-ui-react';

import { JiraProjectModalFieldMappingPicklist } from 'features/jira_project/modal/common/jira_project_modal_field_mapping_picklist';
import { JiraProjectModalTablePicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_picklist_cell';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { useFieldMappings } from 'features/jira_project/modal/sections/field_mapping_section/use_field_mappings';
import { FieldMappingRowType, FieldMappingValue } from 'features/jira_project/modal/types';

import { shouldAllowTwoWaySync } from './utils/row_utils';

interface JiraProjectModalCustomFieldColumnProps {
  picklistData: ReadonlyArray<FieldMappingValue>;
  nameMapping: FieldMappingValue;
  rowType: FieldMappingRowType;
}

export const JiraProjectModalCustomFieldColumn = ({
  picklistData,
  nameMapping,
  rowType,
}: JiraProjectModalCustomFieldColumnProps) => {
  const { canModify } = useJiraProjectModalContext();
  const { updateFieldMappingItem, findFieldMappingItem, createFieldMappingItem, deleteFieldMappingItem } =
    useFieldMappings();
  const fieldMappingItem = findFieldMappingItem({ rowId: nameMapping.id, rowType });

  const handleFieldChange = (_: SyntheticEvent, { value: picklistChoiceId }: { value: string | undefined }) => {
    if (!picklistChoiceId) {
      fieldMappingItem && deleteFieldMappingItem({ fieldMappingItem });
    } else {
      fieldMappingItem
        ? updateFieldMappingItem({ row: nameMapping, rowType, fieldId: picklistChoiceId })
        : createFieldMappingItem({ row: nameMapping, rowType, fieldId: picklistChoiceId });
    }
  };

  const handleCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    updateFieldMappingItem({
      row: nameMapping,
      rowType,
      isTwoWaySyncChecked: data.checked,
      fieldId: getFieldChoiceId(),
    });
  };

  const getFieldChoiceId = () => {
    if (rowType === FieldMappingRowType.LpToJira) {
      return fieldMappingItem?.sourceFieldId ?? '';
    }
    return fieldMappingItem?.lpCustomFieldId?.toString() ?? fieldMappingItem?.lpSystemField ?? '';
  };
  const shouldShowTwoWaySync = shouldAllowTwoWaySync({
    nameMapping,
    rowType,
    fieldMappingItem,
  });

  return (
    <JiraProjectModalTablePicklistCell
      picklist={
        <JiraProjectModalFieldMappingPicklist
          picklist={picklistData}
          onChange={handleFieldChange}
          disabled={!canModify}
          rowType={rowType}
          nameMapping={nameMapping}
          isNameColumn={false}
          showNoneOption
          fieldChoiceId={getFieldChoiceId()}
        />
      }
      checkbox={
        shouldShowTwoWaySync && (
          <Form.Checkbox
            checked={fieldMappingItem?.isPull && fieldMappingItem.isPush}
            onChange={handleCheckboxChange}
            disabled={!canModify}
          />
        )
      }
      checkboxText={shouldShowTwoWaySync ? 'Enable Two-Way Sync' : ''}
    />
  );
};
