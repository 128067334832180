import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  defaultPropsWidgetColumn,
  defaultSortableWidgetConfigColumn,
  mapColumnOptionsToWidgetColumnDefinitions,
  MetricWidgetColumn,
  PropsWidgetColumn,
  WidgetConfigColumn,
} from 'daos/widget_column';
import { getColumnSelectionList } from 'features/common/selection_list';
import { mapWidgetConfigColumnsToSelectionList } from 'features/dashboards_v2/widget/common/columns';
import { getFieldsById } from 'redux/entities/selectors/custom_field';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import {
  getMetricsWidgetColumnsExceptTaskStatus,
  getMetricsWidgetDefaultColumns,
  getPropertiesWidgetDefaultColumns,
  getSystemFeatureFlagDefaults,
} from 'redux/entities/selectors/system_settings';
import {
  getActiveCurrentWorkspaceTaskStatusesSortedByPriority,
  getTaskStatusesById,
} from 'redux/entities/selectors/task_status';

export function useMapColumnsToSelectionList(columns: ReadonlyArray<WidgetConfigColumn>) {
  const fieldsById = useSelector(getFieldsById);
  const taskStatus = useSelector(getTaskStatusesById);

  return useMemo(
    () => mapWidgetConfigColumnsToSelectionList(columns, fieldsById, taskStatus),
    [columns, fieldsById, taskStatus]
  );
}

export const getDefaultPropertiesWidgetColumns = createSelector(getPropertiesWidgetDefaultColumns, (columns) =>
  mapColumnOptionsToWidgetColumnDefinitions<PropsWidgetColumn>(defaultPropsWidgetColumn, columns)
);

export const getDefaultMetricWidgetColumns = createSelector(getMetricsWidgetDefaultColumns, (columns) =>
  mapColumnOptionsToWidgetColumnDefinitions<MetricWidgetColumn>(defaultSortableWidgetConfigColumn, columns)
);

export const getMetricsWidgetColumns = createSelector(
  getMetricsWidgetColumnsExceptTaskStatus,
  getActiveCurrentWorkspaceTaskStatusesSortedByPriority,
  getSystemFeatureFlagDefaults,
  getCurrentOrganization,
  (columns, taskStatuses) =>
    getColumnSelectionList({
      columnOptions: columns,
      taskStatuses,
    })
);
