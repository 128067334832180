import classNames from 'classnames';
import { useEffect } from 'react';
import { Progress, ProgressProps } from 'semantic-ui-react';

import './progress_bar.scss';

export enum ProgressBarColorTheme {
  Academy = 'academy',
  AccountPlan = 'manage-account-plan',
  Inverted = 'inverted',
  Normal = 'normal',
}

const COLOR_SWITCH_POINT = 90;

interface ProgressBarProps {
  className?: string;
  colorSwitchPoint?: number;
  colorTheme?: ProgressBarColorTheme;
  percent: number;
  progress?: ProgressProps['progress'];
  size?: ProgressProps['size'];
  showProgressOver100?: boolean;
  label?: string;
}

const ProgressBar = ({
  className,
  colorSwitchPoint = COLOR_SWITCH_POINT,
  colorTheme = ProgressBarColorTheme.Normal,
  percent = 0,
  progress = false,
  size = 'small',
  showProgressOver100 = false,
  label,
}: ProgressBarProps) => {
  useEffect(() => {
    if (progress && percent > 100 && showProgressOver100) {
      const bar = document.querySelector('div.progress:not(.lp-progress-bar)') as HTMLElement;

      if (bar) {
        bar.innerText = `${String(percent)}%`;
      }
    }
  }, [percent, progress, showProgressOver100]);

  return (
    <Progress
      className={classNames(
        className,
        'lp-progress-bar',
        colorTheme !== ProgressBarColorTheme.Normal && `lp-progress-bar--${colorTheme}`,
        percent >= colorSwitchPoint && 'high'
      )}
      percent={percent}
      size={size}
      progress={progress}
      label={label}
    />
  );
};

export default ProgressBar;
