import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ItemType } from 'daos/enums';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { EntityColumn, EntityRow, GridModalState, ItemGrid, SafeGridModalState } from 'features/common/data_grid/types';
import { ItemRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { ReadonlyRecord } from 'lib/readonly_record';
import { frontend } from 'lib/urls';

enum EffectiveGridType {
  AssignmentsOnly = 'assignmentsOnly',
  TasksOnly = 'tasksOnly',
  SubFoldersOnly = 'subFoldersOnly',
  ProjectsOnly = 'projectsOnly',
  PackagesOnly = 'packagesOnly',
  AssignmentsOrTasks = 'assignmentsOrTasks',
  AssignmentsTasksSubFoldersOrProjects = 'assignmentsTasksSubFoldersOrProjects',
  TasksOrSubFolders = 'tasksOrSubFolders',
  TasksSubFoldersOrProjects = 'tasksSubFoldersOrProjects',
  TasksSubFoldersProjectsOrPackages = 'tasksSubFoldersProjectsOrPackages',
  SubFoldersOrProjects = 'subFoldersOrProjects',
  SubFoldersProjectsOrPackages = 'subFoldersProjectsOrPackages',
  None = 'none',
}

const getEffectiveGridType = (itemType: ItemType | null): ReadonlyRecord<EffectiveGridType, boolean> => {
  const isAssignment = itemType === ItemType.ASSIGNMENTS;
  const isTask = itemType === ItemType.TASKS;
  const isSubFolder = itemType === ItemType.FOLDERS;
  const isProject = itemType === ItemType.PROJECTS;
  const isPackage = itemType === ItemType.PACKAGES;
  return {
    [EffectiveGridType.AssignmentsOnly]: isAssignment,
    [EffectiveGridType.TasksOnly]: isTask,
    [EffectiveGridType.SubFoldersOnly]: isSubFolder,
    [EffectiveGridType.ProjectsOnly]: isProject,
    [EffectiveGridType.PackagesOnly]: isPackage,
    [EffectiveGridType.AssignmentsOrTasks]: isAssignment || isTask,
    [EffectiveGridType.AssignmentsTasksSubFoldersOrProjects]: isAssignment || isTask || isSubFolder || isProject,
    [EffectiveGridType.TasksOrSubFolders]: isTask || isSubFolder,
    [EffectiveGridType.TasksSubFoldersOrProjects]: isTask || isSubFolder || isProject,
    [EffectiveGridType.TasksSubFoldersProjectsOrPackages]: isTask || isSubFolder || isProject || isPackage,
    [EffectiveGridType.SubFoldersOrProjects]: isProject || isSubFolder,
    [EffectiveGridType.SubFoldersProjectsOrPackages]: isSubFolder || isProject || isPackage,
    [EffectiveGridType.None]: itemType === null,
  };
};
export const useItemGridCellClick = (gridType: ItemType | null, modalState?: GridModalState) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const history = useHistory();

  const effectiveGridType = getEffectiveGridType(gridType);

  const onItemGridCellClick = <R extends ItemGrid, S = unknown>({
    row,
    column,
  }: {
    row: EntityRow<R, S>;
    column: EntityColumn<R, S>;
  }) => {
    const columnKey = column.key;
    const itemId = row.id;

    return commonGridCellClickBehavior({
      columnKey,
      effectiveGridType,
      itemId,
      modalState,
      history,
      organizationId,
      workspaceId,
      taskId: row.taskId,
      projectId: row.projectId,
      lastSubFolderId: row.lastSubFolderId,
      packageId: row.packageId,
    });
  };

  return { onItemGridCellClick };
};

function redirectStartAndFinishColumns(
  effectiveGridType: ReadonlyRecord<EffectiveGridType, boolean>,
  taskId: number | undefined,
  history: {
    push: (url: string) => void;
  },
  parentId: number | undefined,
  itemId: number
) {
  if (effectiveGridType.assignmentsOnly && taskId) {
    history.push(`#panelId=${taskId}&panelSection=${TabNames.Assignments}Tab`);
  } else if (effectiveGridType.assignmentsOnly && parentId) {
    history.push(`#panelId=${parentId}&panelSection=${TabNames.Assignments}Tab`);
  } else if (effectiveGridType.tasksSubFoldersProjectsOrPackages) {
    history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
  }
}

function redirectTaskAndTaskStatus(
  effectiveGridType: ReadonlyRecord<EffectiveGridType, boolean>,
  taskId: number | undefined,
  history: {
    push: (url: string) => void;
  },
  parentId: number | undefined,
  itemId: number
) {
  if (effectiveGridType.assignmentsOnly && taskId) {
    history.push(`#panelId=${taskId}&panelSection=${TabNames.Assignments}Tab`);
  } else if (effectiveGridType.assignmentsOnly && parentId) {
    history.push(`#panelId=${parentId}&panelSection=${TabNames.Assignments}Tab`);
  } else if (effectiveGridType.tasksOnly) {
    history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
  }
}

function redirectPackageColumn(
  effectiveGridType: ReadonlyRecord<EffectiveGridType, boolean>,
  packageId: number | undefined,
  pushToPackageListView: (packageId: number) => void,
  parentId: number | undefined,
  itemId: number
) {
  if (effectiveGridType.assignmentsTasksSubFoldersOrProjects && packageId) {
    pushToPackageListView(Number(packageId));
  } else if (effectiveGridType.projectsOnly && parentId) {
    pushToPackageListView(Number(parentId));
  } else if (effectiveGridType.packagesOnly) {
    pushToPackageListView(itemId);
  }
}

function redirectToTab(
  effectiveGridType: ReadonlyRecord<EffectiveGridType, boolean>,
  taskId: number | undefined,
  history: {
    push: (url: string) => void;
  },
  parentId: number | undefined,
  itemId: number,
  tab: TabNames
) {
  if (effectiveGridType.assignmentsOnly && taskId) {
    history.push(`#panelId=${taskId}&panelSection=${tab}Tab`);
  } else if (effectiveGridType.assignmentsOnly && parentId) {
    history.push(`#panelId=${parentId}&panelSection=${tab}Tab`);
  } else {
    history.push(`#panelId=${itemId}&panelSection=${tab}Tab`);
  }
}

function redirectLocationColumns(
  effectiveGridType: ReadonlyRecord<EffectiveGridType, boolean>,
  lastSubFolderId: number | undefined,
  pushToProjectListView: (itemId: number) => void,
  projectId: number | undefined,
  parentId: number | undefined,
  pushToPackageListView: (packageId: number) => void,
  packageId: number | undefined,
  itemId: number
) {
  if (effectiveGridType.assignmentsOrTasks) {
    if (lastSubFolderId) {
      pushToProjectListView(Number(lastSubFolderId));
    }
    if (projectId) {
      pushToProjectListView(Number(projectId));
    }
  }
  if (effectiveGridType.projectsOnly && parentId) {
    pushToPackageListView(Number(parentId));
  }
  if (effectiveGridType.subFoldersOrProjects && packageId) {
    pushToPackageListView(Number(packageId));
  }
  if (effectiveGridType.packagesOnly) {
    pushToPackageListView(itemId);
  }
}

function commonGridCellClickBehavior({
  organizationId,
  workspaceId,
  columnKey,
  effectiveGridType,
  itemId,
  modalState,
  history,
  taskId,
  projectId,
  lastSubFolderId,
  packageId,
  parentId,
}: {
  organizationId: number;
  workspaceId: number;
  columnKey: string;
  effectiveGridType: ReadonlyRecord<EffectiveGridType, boolean>;
  itemId: number;
  modalState?: SafeGridModalState;
  history: { push: (url: string) => void };
  taskId?: number;
  projectId?: number;
  lastSubFolderId?: number;
  packageId?: number;
  parentId?: number;
}) {
  const pushToProjectListView = (itemId: number) => {
    history.push(
      frontend.projectProject.url({
        organizationId,
        workspaceId,
        itemId,
      })
    );
  };

  const pushToPackageListView = (packageId: number) => {
    history.push(
      frontend.singlePackage.url({
        organizationId,
        workspaceId,
        packageId,
      })
    );
  };
  const isCustomField = !!Number(columnKey);
  if (isCustomField && !effectiveGridType.assignmentsOnly) {
    return history.push(`#panelId=${itemId}&panelSection=${TabNames.Properties}Tab`);
  }

  switch (columnKey) {
    case StandardColumns.Assigned:
      if (effectiveGridType.assignmentsOnly) {
        modalState?.setEstimateModalAssignmentId(itemId);
      }
      if (effectiveGridType.tasksOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
      }
      break;
    case StandardColumns.Logged:
    case StandardColumns.TimesheetScheduled:
      if (effectiveGridType.assignmentsOnly) {
        modalState?.setEstimateModalAssignmentId(itemId);
      }
      if (effectiveGridType.tasksOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
      }
      if (effectiveGridType.subFoldersOrProjects) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Properties}Tab`);
      }
      if (effectiveGridType.packagesOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
      }
      break;
    case StandardColumns.TotalWorkExpected:
    case StandardColumns.TotalWorkScheduled:
    case StandardColumns.TotalWorkRange:
    case StandardColumns.TotalWorkLow:
    case StandardColumns.TotalWorkHigh:
      if (effectiveGridType.assignmentsOnly) {
        modalState?.setEstimateModalAssignmentId(itemId);
      }
      if (effectiveGridType.tasksSubFoldersProjectsOrPackages) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Properties}Tab`);
      }
      break;
    case StandardColumns.ClippedEffort:
    case StandardColumns.RemainingWorkExpected:
    case StandardColumns.RemainingWorkRange:
    case StandardColumns.RemainingWorkLow:
    case StandardColumns.RemainingWorkHigh:
    case StandardColumns.UncertainWork:
    case StandardColumns.UnusedEffortExpected:
      if (effectiveGridType.assignmentsOnly) {
        modalState?.setEstimateModalAssignmentId(itemId);
      }
      if (effectiveGridType.tasksOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Properties}Tab`);
      }
      if (effectiveGridType.subFoldersProjectsOrPackages) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
      }
      break;
    case StandardColumns.WorkLimit:
    case StandardColumns.WorkLimitDelta:
      if (effectiveGridType.tasksOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
      }
      if (effectiveGridType.subFoldersProjectsOrPackages) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Planning}Tab`);
      }
      break;
    case StandardColumns.Task:
    case StandardColumns.TaskStatus:
      redirectTaskAndTaskStatus(effectiveGridType, taskId, history, parentId, itemId);
      break;
    case StandardColumns.ExpectedFinish:
    case StandardColumns.ExpectedStart:
    case StandardColumns.FinishRange:
    case StandardColumns.LatestFinish:
      redirectStartAndFinishColumns(effectiveGridType, taskId, history, parentId, itemId);
      break;
    case StandardColumns.FolderStatus:
      if (effectiveGridType.subFoldersOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Planning}Tab`);
      }
      break;
    case StandardColumns.Project:
      if (effectiveGridType.assignmentsOrTasks && projectId) {
        pushToProjectListView(Number(projectId));
      }
      if (effectiveGridType.subFoldersOrProjects) {
        pushToProjectListView(itemId);
      }
      break;
    case StandardColumns.SubFolder:
      if (effectiveGridType.assignmentsOrTasks && lastSubFolderId) {
        const subFolderId = Number(lastSubFolderId);
        subFolderId && pushToProjectListView(subFolderId);
      }
      break;
    case StandardColumns.Location:
      redirectLocationColumns(
        effectiveGridType,
        lastSubFolderId,
        pushToProjectListView,
        projectId,
        parentId,
        pushToPackageListView,
        packageId,
        itemId
      );
      break;
    case StandardColumns.Description:
      if (effectiveGridType.tasksOnly) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
      }
      if (effectiveGridType.subFoldersProjectsOrPackages) {
        history.push(`#panelId=${itemId}&panelSection=${TabNames.Planning}Tab`);
      }
      break;
    case StandardColumns.Package:
      redirectPackageColumn(effectiveGridType, packageId, pushToPackageListView, parentId, itemId);
      break;
    case StandardColumns.Assignment:
    case StandardColumns.AssignmentDone:
    case StandardColumns.CostCode:
    case StandardColumns.TrackingOnly:
      modalState?.setEstimateModalAssignmentId(itemId);
      break;
    case StandardColumns.PriorityRush:
    case StandardColumns.TargetFinishType:
    case StandardColumns.TargetFinish:
    case StandardColumns.TargetStart:
    case StandardColumns.PercentComplete:
      history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
      break;
    case StandardColumns.AssignmentCountActive:
    case StandardColumns.AssignmentCountTotal:
    case StandardColumns.AssignmentCountDone:
    case StandardColumns.WorkType:
      history.push(`#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`);
      break;

    case StandardColumns.CreatedAt:
    case StandardColumns.CreatedBy:
    case StandardColumns.DoneDate:
    case StandardColumns.UpdatedBy:
    case StandardColumns.UpdatedAt:
    case StandardColumns.OnHoldHours:
    case StandardColumns.TotalWorkBillable:
    case StandardColumns.TotalWorkNonBillable:
    case StandardColumns.StoryPointCountOnHold:
      redirectToTab(effectiveGridType, taskId, history, parentId, itemId, TabNames.Properties);
      break;
    case StandardColumns.DateRange:
    case StandardColumns.LoggedBillable:
    case StandardColumns.LoggedNonBillable:
    case StandardColumns.TimesheetScheduledBillableHours:
    case StandardColumns.TimesheetScheduledNonBillableHours:
    case StandardColumns.TargetStartDelta:
    case StandardColumns.TargetFinishDelta:
      redirectToTab(effectiveGridType, taskId, history, parentId, itemId, TabNames.Scheduling);
      break;
    case StandardColumns.BenchmarkEstimate:
    case StandardColumns.ProjectStatus:
    case StandardColumns.BillingRateSheet:
    case StandardColumns.PayRateSheet:
      history.push(`#panelId=${itemId}&panelSection=${TabNames.Planning}Tab`);
      break;

    case StandardColumns.PercentTasksComplete:
    case StandardColumns.ProjectCountTotal:
    case StandardColumns.ProjectCountScheduled:
    case StandardColumns.ProjectCountDone:
    case StandardColumns.ProjectCountOnHold:
    case StandardColumns.TaskCountTotal:
    case StandardColumns.TaskCountActive:
    case StandardColumns.TaskCountDone:
    case StandardColumns.TaskCountOnHold:
      history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
      break;
    case StandardColumns.ItemFileCount:
      history.push(`#panelId=${itemId}&panelSection=${TabNames.Files}Tab`);
      break;
  }
}

export const useSafeItemGridCellClick = (
  gridType: ItemType | null,
  organizationId: number,
  workspaceId: number,
  modalState?: SafeGridModalState
) => {
  const effectiveGridType = getEffectiveGridType(gridType);
  const history = useHistory();

  const onItemGridCellClick = <R extends ItemRow | ItemGrid, S = unknown>({
    row,
    column,
  }: {
    row: EntityRow<R, S>;
    column: EntityColumn<R, S>;
  }) => {
    const columnKey = column.key;
    const itemId = row.id;

    return commonGridCellClickBehavior({
      columnKey,
      effectiveGridType,
      itemId,
      modalState,
      history,
      organizationId,
      workspaceId,
      parentId: row.itemParentId ?? undefined,
    });
  };

  return { onItemGridCellClick };
};
