import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { ManageAccountPlanChangeType, Permission } from 'daos/enums';
import { WorkspaceDao } from 'daos/workspace';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import JiraIntegrationConfiguration from 'features/integrations/jira/jira_integration_connections';
import SlackIntegrationAdministration from 'features/integrations/slack/slack_integration_administration';
import SlackIntegrationConfiguration from 'features/integrations/slack/slack_integration_configuration';
import OrganizationDirectory from 'features/organization_directory';
import { ManageAccount } from 'features/organization_directory/manage_account';
import { OrganizationDirectoryUsers } from 'features/organization_directory/users';
import { useHasAccess } from 'hooks/use_has_access';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getIsSlackIntegrationSupportedByEnvironment } from 'redux/entities/selectors/system_settings';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import { ManageAccountPlanChange } from './manage_account/plan_change';
import UpgradePage from './upgrade_page';

export const OrganizationHubRoutes = ({
  setEntityNotFound,
}: {
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const organization = useSelector(getCurrentOrganization);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isAdmin = useSelector(getCurrentOrganizationUser)?.admin;
  const hasWorkspaceManagerAccess = useHasAccess(Permission.MANAGE, WorkspaceDao.id(workspaceId));
  const environmentSupportsSlackIntegration = useSelector(getIsSlackIntegrationSupportedByEnvironment);

  const isOrgSlackApproved = organization?.slackApproved;
  const hasJiraIntegration = useHasFeature(FeatureFlag.jiraIntegration);
  const hasSlackIntegration = organization?.flags.hasSlackIntegration;

  return (
    <Switch>
      <Route
        exact
        path={frontend.organizationHub.pattern}
        render={() => <OrganizationDirectory setEntityNotFound={setEntityNotFound} />}
      />

      <Route exact path={frontend.organizationHubUpgrade.pattern} component={UpgradePage} />

      {hasJiraIntegration && hasWorkspaceManagerAccess && (
        <Route
          exact
          path={frontend.organizationHubUsersJiraIntegrations.pattern}
          component={JiraIntegrationConfiguration}
        />
      )}

      {environmentSupportsSlackIntegration && ((isOrgSlackApproved && hasSlackIntegration) || isAdmin) && (
        <Route
          exact
          path={frontend.organizationHubUsersSlackIntegrations.pattern}
          component={SlackIntegrationConfiguration}
        />
      )}

      {environmentSupportsSlackIntegration && isAdmin && (
        <Route
          exact
          path={frontend.organizationHubUsersSlackIntegrationsAdmin.pattern}
          component={SlackIntegrationAdministration}
        />
      )}

      {isAdmin && (
        <Route
          path={[frontend.organizationHubUser.pattern, frontend.organizationHubUsers.pattern]}
          component={OrganizationDirectoryUsers}
        />
      )}
      {isAdmin && (
        <Route
          path={frontend.organizationHubManageAccountPlanUpgrade.pattern}
          render={() => <ManageAccountPlanChange changeType={ManageAccountPlanChangeType.Upgrade} />}
        />
      )}
      {isAdmin && (
        <Route
          path={frontend.organizationHubManageAccountPlanDowngrade.pattern}
          render={() => <ManageAccountPlanChange changeType={ManageAccountPlanChangeType.Downgrade} />}
        />
      )}
      {isAdmin && <Route path={frontend.organizationHubManageAccount.pattern} component={ManageAccount} />}

      <Redirect to={frontend.workspaceHub.url({ organizationId, workspaceId })} />
    </Switch>
  );
};
