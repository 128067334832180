import { Button } from 'semantic-ui-react';

import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import PopUpForUserAvatar from 'features/common/avatars/pop_up_for_user_avatar';
import { UserAvatarRenderer } from 'features/common/avatars/user_avatar';
import { ellipsisHSolid, LpIcon } from 'features/common/lp_icon';
import { GuestAssignee } from 'features/common/task_board/guest/types';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

const CARD_SIZE_LIMIT = 6;

export const GuestTaskCardAssignees = ({ assignees }: { assignees: Array<GuestAssignee> }) => (
  <div className="lp-task-board__card-assignees">
    {assignees.slice(0, CARD_SIZE_LIMIT).map((assignee, idx) =>
      idx === CARD_SIZE_LIMIT - 1 ? (
        <PopUpForUserAvatar key={assignee.orgUserId} orgUserName={`More${HORIZONTAL_ELLIPSIS}`}>
          <Button className="guest" key={assignee.orgUserId} icon={<LpIcon icon={ellipsisHSolid} />} size="mini" />
        </PopUpForUserAvatar>
      ) : (
        <PopUpForUserAvatar key={assignee.orgUserId} orgUserName={assignee.orgUsername}>
          <UserAvatarRenderer
            avatarColor={assignee.orgUserAvatarColor}
            avatarUrl={assignee.orgUserAvatarUrl}
            className="lp-task-board__card-assignees-avatar"
            displayName={assignee.displayName}
            marginRight={false}
            size={AvatarSize.Small_2_0}
            userType={assignee.userType}
          />
        </PopUpForUserAvatar>
      )
    )}
  </div>
);
