import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel } from './shared';
import { backend } from './urls';

const { resource } = defineModel({
  apiType: 'avatars',
  relationships: [],
  type: 'AVATAR',
});

const { AVATAR, resourceId } = resource;

interface AvatarParams {
  organizationId: number;
  organizationUserId: number;
}

const avatarUpdate = (params: AvatarParams, file: Blob) => {
  const data = new FormData();
  data.append('file', file);

  return request(backend.avatar.url(params), AVATAR, {
    body: data,
    method: HttpMethod.PUT,
  });
};

const avatarDelete = (params: AvatarParams) =>
  request(backend.avatar.url(params), AVATAR, { method: HttpMethod.DELETE });

export const AvatarDao = {
  delete: avatarDelete,
  id: resourceId,
  update: avatarUpdate,
} as const;
