import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router';

import { PackageStatus } from 'daos/enums';
import { AcademyOutpostTrackLessonOrderBy } from 'features/academy/daos/enums';
import { OutpostDao } from 'features/academy/daos/outpost';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { getOutpostLocation } from 'features/academy/outpost/selectors';
import { OutpostSlider } from 'features/academy/outpost/slider';
import { AnimationDirection } from 'features/academy/outpost/types';
import { getUnfinishedOutpostTrackLessonsForLocationOrderedByPriority } from 'features/academy/selectors/progress';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getPackageStatusForItemId } from 'redux/entities/selectors/item';
import { purgeAcademyOutposts } from 'redux/entities/slice';

import './index.scss';

const outpostAnimationVariants = {
  initial: {
    opacity: 0,
    y: 100,
    scale: 0.95,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    y: 100,
    scale: 0.95,
  },
};

const outpostAnimationTransition = {
  type: 'spring',
  duration: 0.3,
  delay: 0.2,
};

const isIndexInvalid = ({ lessonIndex, outpostLessonCount }: { lessonIndex: number; outpostLessonCount: number }) => {
  return lessonIndex < 0 || lessonIndex >= outpostLessonCount;
};

export const getNextOutpostLessonIndex = ({
  currentLessonIndex,
  outpostLessonCount,
}: {
  currentLessonIndex: number;
  outpostLessonCount: number;
}) => {
  if (isIndexInvalid({ lessonIndex: currentLessonIndex, outpostLessonCount })) {
    return 0;
  }

  return (currentLessonIndex + 1) % outpostLessonCount;
};

export const getPreviousOutpostLessonIndex = ({
  currentLessonIndex,
  outpostLessonCount,
}: {
  currentLessonIndex: number;
  outpostLessonCount: number;
}) => {
  if (isIndexInvalid({ lessonIndex: currentLessonIndex, outpostLessonCount })) {
    return 0;
  }

  const newLessonIndex = currentLessonIndex - 1;
  return isIndexInvalid({ lessonIndex: newLessonIndex, outpostLessonCount }) ? outpostLessonCount - 1 : newLessonIndex;
};

const scheduledPackageOutpostLocations = [
  OutpostLocation.ScheduledPackage,
  OutpostLocation.ScheduledPackageBoard,
  OutpostLocation.ScheduledPackageChanges,
  OutpostLocation.ScheduledPackageDashboard,
  OutpostLocation.ScheduledPackageGrid,
  OutpostLocation.ScheduledPackageSearch,
  OutpostLocation.ScheduledPackageWorkload,

  OutpostLocation.ScheduledProject,
  OutpostLocation.ScheduledProjectBoard,
  OutpostLocation.ScheduledProjectChanges,
  OutpostLocation.ScheduledProjectDashboard,
  OutpostLocation.ScheduledProjectGrid,
  OutpostLocation.ScheduledProjectSearch,
  OutpostLocation.ScheduledProjectWorkload,
];

const shouldRenderOutpostBasedOnPackageStatus = (
  outpostLocation: OutpostLocation,
  packageStatus: PackageStatus | null | undefined
) => {
  if (!packageStatus) {
    return true;
  }
  if (packageStatus !== PackageStatus.SCHEDULED && scheduledPackageOutpostLocations.includes(outpostLocation)) {
    return false;
  }
  return true;
};

const getItemId = (packageId: string | undefined, itemId: string | undefined) => {
  if (packageId) {
    return packageId;
  }
  if (itemId) {
    return itemId;
  }
  return undefined;
};

export const SidebarOutpost = ({ leftNavExpanded }: { leftNavExpanded: boolean }) => {
  const dispatch = useDispatch();
  const outpostLocation = useSelector(getOutpostLocation);

  const params = matchPath<{
    packageId?: string;
    itemId?: string;
  }>(location.pathname, {
    path: [frontend.package.pattern, frontend.project.pattern],
  })?.params;

  const packageId = params?.packageId;
  const itemId = params?.itemId;

  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, Number(getItemId(packageId, itemId))));
  const prioritizedUnfinishedOutpostTrackLessons = useSelector((state) =>
    outpostLocation ? getUnfinishedOutpostTrackLessonsForLocationOrderedByPriority(state, outpostLocation) : undefined
  );
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState(AnimationDirection.Left);
  const [loading, setLoading] = useState(false);

  const currentLessonId = prioritizedUnfinishedOutpostTrackLessons
    ? prioritizedUnfinishedOutpostTrackLessons[currentLessonIndex]?.academyTrackLessonId
    : null;

  useEffect(() => {
    setCurrentLessonIndex(0);
  }, [outpostLocation]);

  useEffect(() => {
    if (!outpostLocation) {
      return;
    }

    setLoading(true);
    dispatch(purgeAcademyOutposts());

    const { uuid } = dispatch(
      OutpostDao.fetch({
        outpostLocation,
        include: {
          includeAcademyLessonsAndProgress: true,
          includeAcademyTrackAndCourse: true,
        },
        query: {
          order: AcademyOutpostTrackLessonOrderBy.Priority,
        },
      })
    );

    const handleLoading = () => setLoading(false);
    dispatch(awaitRequestFinish(uuid, { onFinish: handleLoading }));
  }, [dispatch, outpostLocation]);

  const hasValidOutpostData =
    !loading &&
    outpostLocation &&
    prioritizedUnfinishedOutpostTrackLessons &&
    prioritizedUnfinishedOutpostTrackLessons.length > 0 &&
    currentLessonId;

  const handleGetNextLesson = () => {
    if (!prioritizedUnfinishedOutpostTrackLessons) {
      return;
    }
    setAnimationDirection(AnimationDirection.Left);
    setCurrentLessonIndex(
      getNextOutpostLessonIndex({
        currentLessonIndex,
        outpostLessonCount: prioritizedUnfinishedOutpostTrackLessons.length,
      })
    );
  };

  const handleGetPreviousLesson = () => {
    if (!prioritizedUnfinishedOutpostTrackLessons) {
      return;
    }
    setAnimationDirection(AnimationDirection.Right);
    setCurrentLessonIndex(
      getPreviousOutpostLessonIndex({
        currentLessonIndex,
        outpostLessonCount: prioritizedUnfinishedOutpostTrackLessons.length,
      })
    );
  };

  const shouldRenderOutpostContent =
    leftNavExpanded && hasValidOutpostData && shouldRenderOutpostBasedOnPackageStatus(outpostLocation, packageStatus);

  return (
    <AnimatePresence presenceAffectsLayout>
      {shouldRenderOutpostContent && (
        <motion.div
          layout
          className="sidebar-outpost"
          variants={outpostAnimationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={outpostAnimationTransition}
        >
          <OutpostSlider
            currentLessonId={currentLessonId}
            enableControls={prioritizedUnfinishedOutpostTrackLessons.length > 1}
            getNextLesson={handleGetNextLesson}
            getPreviousLesson={handleGetPreviousLesson}
            animationDirection={animationDirection}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
