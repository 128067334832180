import { useMemo } from 'react';

import { ReadonlyRecord } from 'lib/readonly_record';

import WorkloadNub from './workload_nub';

interface WorkloadDateRangeNubsProps {
  dailyAvailabilitySeconds: Record<string, number>;
  dailyWorkSecondsByDate: Record<string, number>;
  isRollup?: boolean;
  nubColor?: string;
  lateByDate: ReadonlyRecord<string, number>;
  timeOffByDate: ReadonlyRecord<string, number>;
  workISOFinishDate?: string;
  workloadDates: ReadonlyArray<string>;
  workISOStartDate?: string;
  isParentTaskLate?: boolean;
  username: string;
}

const WorkloadDateRangeNubs = ({
  dailyAvailabilitySeconds,
  dailyWorkSecondsByDate,
  isRollup,
  nubColor,
  lateByDate,
  timeOffByDate,
  workISOFinishDate,
  workloadDates,
  workISOStartDate,
  username,
  isParentTaskLate = false,
}: WorkloadDateRangeNubsProps) => {
  const lastDateIndex = workloadDates.length - 1;
  const lastWorkloadDate = workloadDates[lastDateIndex];

  const workloadScheduledBeyondRange = useMemo(() => {
    const finishDateBeyondRange = !!workISOFinishDate && !!lastWorkloadDate && workISOFinishDate > lastWorkloadDate;
    const startDateBeyondRange = !!workISOStartDate && !!lastWorkloadDate && workISOStartDate > lastWorkloadDate;

    return startDateBeyondRange || finishDateBeyondRange;
  }, [lastWorkloadDate, workISOFinishDate, workISOStartDate]);

  return (
    <div className="workload-date-range">
      {workloadDates.map((date, idx) => (
        <WorkloadNub
          dailyWorkSeconds={dailyWorkSecondsByDate[date]}
          date={date}
          isParentTaskLate={isParentTaskLate}
          isRollup={isRollup}
          key={date}
          late={lateByDate[date] !== undefined}
          nubColor={nubColor}
          username={username}
          timeOff={timeOffByDate[date] !== undefined}
          unavailable={(dailyAvailabilitySeconds[date] ?? 0) === 0}
          workScheduledBeyondRange={lastDateIndex === idx && workloadScheduledBeyondRange}
        />
      ))}
    </div>
  );
};

export default WorkloadDateRangeNubs;
