import { request } from 'lib/api';

import { CustomFieldValue } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { createBody, updateBody, deleteBody, resource } = defineModel({
  apiType: 'fieldValues',
  relationships: ['picklistChoice', 'field', 'item'],
  type: 'CUSTOM_FIELD_VALUE',
});

const { CUSTOM_FIELD_VALUE, resourceId } = resource;

interface ItemFieldValuesParams {
  organizationId: number;
  workspaceId: number;
  itemId: number | undefined;
  workspaceUserId?: never;
}

interface WorkspaceUserFieldValuesParams {
  organizationId: number;
  workspaceId: number;
  itemId?: never;
  workspaceUserId: number | undefined;
}

type FieldValuesParams = ItemFieldValuesParams | WorkspaceUserFieldValuesParams;

type SingleFieldValueParams = FieldValuesParams & {
  fieldValueId: number;
};

const fieldValueUrl = ({
  organizationId,
  workspaceId,
  itemId = 0,
  workspaceUserId = 0,
  fieldValueId,
}: SingleFieldValueParams) => {
  if (itemId) {
    return backend.fieldValue.url({ organizationId, workspaceId, itemId, fieldValueId });
  }
  return backend.wsUserFieldValue.url({ organizationId, workspaceId, workspaceUserId, fieldValueId });
};

const fieldValuesUrl = ({ organizationId, workspaceId, itemId = 0, workspaceUserId = 0 }: FieldValuesParams) => {
  if (itemId) {
    return backend.fieldValues.url({ organizationId, workspaceId, itemId });
  }
  return backend.wsUserFieldValues.url({ organizationId, workspaceId, workspaceUserId });
};

const customFieldValueCreate = (params: FieldValuesParams, fieldValue: Partial<CustomFieldValue>) =>
  request(fieldValuesUrl(params), CUSTOM_FIELD_VALUE, createBody(fieldValue));

const customFieldValueDestroy = (params: SingleFieldValueParams, fieldValueId: number) =>
  request(fieldValueUrl(params), CUSTOM_FIELD_VALUE, deleteBody(fieldValueId));

const customFieldValueUpdate = (params: SingleFieldValueParams, { id, ...fieldValue }: Partial<CustomFieldValue>) =>
  request(fieldValueUrl(params), CUSTOM_FIELD_VALUE, updateBody(params.fieldValueId, fieldValue));

export const CustomFieldValueDao = {
  create: customFieldValueCreate,
  destroy: customFieldValueDestroy,
  id: resourceId,
  update: customFieldValueUpdate,
} as const;
