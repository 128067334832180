import { LpFormikCheckbox } from 'features/common/forms/formik/checkbox';
import { WidgetSettingsField } from 'features/dashboards/widgets/widget_settings';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

export const ShowWidgetTotals = () => {
  const hasTotalRowFeature = useHasFeature(FeatureFlag.totalRow);

  if (!hasTotalRowFeature) {
    return null;
  }

  return (
    <WidgetSettingsField name="">
      <LpFormikCheckbox
        data-testid="showTotals"
        name={'showTotals'}
        label={<label className="total-row__checkbox">Show Totals</label>}
      />
    </WidgetSettingsField>
  );
};
