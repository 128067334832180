import { defineModel, reduceIncludedOptions } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'academyCourses',
  relationships: [],
  type: 'ACADEMY_COURSE',
});

const { ACADEMY_COURSE } = resource;

interface Includes {
  includeCreatedBy?: boolean;
  includeUpdatedBy?: boolean;
  includeAcademyTrack?: boolean;
  includeAcademyTrackLesson?: boolean;
  includeAcademyLesson?: boolean;
  includeMyAcademyProgress?: boolean;
  includeMyAcademyCourseProgress?: boolean;
  includeMyAcademyTrackProgress?: boolean;
  includeMyAcademyLessonProgress?: boolean;
}

const includes = ({
  includeCreatedBy,
  includeUpdatedBy,
  includeAcademyTrack,
  includeAcademyTrackLesson,
  includeAcademyLesson,
  includeMyAcademyProgress,
  includeMyAcademyCourseProgress,
  includeMyAcademyTrackProgress,
  includeMyAcademyLessonProgress,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeCreatedBy && 'createdBy',
    includeUpdatedBy && 'updatedBy',
    includeAcademyTrack && 'academyTracks',
    includeAcademyTrackLesson && 'academyTracks.academyTrackLessons',
    includeAcademyLesson && 'academyTracks.academyTrackLessons.academyLessons',
    includeMyAcademyProgress && 'myAcademyProgress',
    includeMyAcademyCourseProgress && 'myAcademyCourseProgress',
    includeMyAcademyTrackProgress && 'academyTracks.myAcademyTrackProgress',
    includeMyAcademyLessonProgress && 'academyTracks.academyTrackLessons.academyLessons.myAcademyLessonProgress',
  ]);

interface SingleCourseParams {
  courseId: string;
}

interface CourseOptions {
  filter?: string;
  include?: Includes;
  query?: {};
}
const courseFetch = (params: SingleCourseParams, { filter, include, query }: CourseOptions = {}) =>
  request(backend.academyCourse.url(params, { filter, include: includes(include), query }), ACADEMY_COURSE, {
    method: HttpMethod.GET,
  });

const courseFetchAll = ({ filter, include, query }: CourseOptions = {}) =>
  request(backend.academyCourses.url({}, { filter, include: includes(include), query }), ACADEMY_COURSE, {
    method: HttpMethod.GET,
  });

export const CourseDao = {
  fetch: courseFetch,
  fetchAll: courseFetchAll,
} as const;
