import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStateExtraReducer } from 'redux/root_actions';

interface RulesEditorState {
  readonly newRuleId?: number;
}

export const initialState: RulesEditorState = {
  newRuleId: undefined,
};

const rulesEditorSlice = createSlice({
  name: 'rulesEditor',
  initialState,
  reducers: {
    setNewRuleId: (state, action: PayloadAction<number | undefined>) => {
      state.newRuleId = action.payload;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { setNewRuleId } = rulesEditorSlice.actions;

export default rulesEditorSlice.reducer;
