import { SyntheticEvent, useMemo } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { PicklistChoice } from 'daos/model_types';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { LpIcon, squareSolid } from 'features/common/lp_icon';
import { gray300 } from 'style/variables';

import { lpDropdownSearch } from './helpers';

import 'features/common/custom_fields/custom_field_value/custom_field_value.scss';

type Picklist = Pick<PicklistChoice, 'name' | 'id'> & Partial<Pick<PicklistChoice, 'color' | 'archived'>>;

export interface PicklistDropdownProps {
  className?: string;
  disabled?: boolean;
  fieldChoiceId?: number;
  isOpen?: boolean;
  onChange: (event: SyntheticEvent<HTMLElement, Event>, data: { value: number }) => void;
  picklist: ReadonlyArray<Picklist>;
  placeHolder?: string;
  usePortal?: boolean;
  onClose?: () => void;
  error?: boolean;
  renderIcon?: boolean;
}

const PicklistDropdown = ({
  className,
  disabled = false,
  fieldChoiceId,
  isOpen,
  onChange,
  picklist,
  placeHolder,
  usePortal = false,
  onClose,
  error = false,
  renderIcon = true,
}: PicklistDropdownProps) => {
  const picklistOptions = useMemo(() => {
    const options: Array<DropdownItemProps> = [{ key: 'NONE', text: <span> None </span>, value: undefined }];

    picklist.forEach((pick) => {
      options.push({
        key: pick.name.toLowerCase(),
        disabled: !!pick.archived,
        text: (
          <span>
            {renderIcon && (
              <LpIcon
                className="lp-custom-field__picklist-icon"
                icon={squareSolid}
                size="1x"
                color={pick.color ? `#${pick.color}` : gray300}
              />
            )}
            {pick.name}
            {pick.archived ? ' (archived)' : ''}
          </span>
        ),
        search: pick.name.toLowerCase(),
        value: pick.id,
      });
    });

    return options;
  }, [picklist, renderIcon]);

  const handleChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) =>
    onChange(event, data as { value: number });

  const picklistDropdown = (
    <Dropdown
      className={className}
      disabled={disabled}
      lazyLoad
      error={error}
      search={lpDropdownSearch}
      onChange={handleChange}
      open={isOpen}
      options={picklistOptions}
      placeholder={placeHolder}
      selection
      selectOnBlur={false}
      selectOnNavigation={false}
      value={fieldChoiceId ?? ''}
      onClose={onClose}
    />
  );

  return usePortal ? <PortalDropdown>{picklistDropdown}</PortalDropdown> : picklistDropdown;
};

export default PicklistDropdown;
