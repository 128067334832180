import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { OrganizationUserDao } from 'daos/organization_user';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { useFetchApiTokensAndUsers } from 'features/api/use_fetch_api_tokens_and_users';
import LpModal from 'features/common/modals/lp_modal';
import { wsUserWorkspaceTally } from 'features/organization_directory/users/table/workspace_users_user_tallies';
import { awaitRequestFinish } from 'lib/api';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getOrganizationUserForId, getWorkspaceUsersById } from 'redux/entities/selectors/user';
import { getWorkspaceForId } from 'redux/entities/selectors/workspace';

import './disconnect_modal.scss';

export interface DisconnectModalProps {
  close: () => void;
  fetch?: () => void;
  workspaceId: number;
  workspaceUserId: number;
  organizationUserId: number;
}

export const DisconnectModal = ({
  close,
  fetch = noop,
  workspaceId,
  workspaceUserId,
  organizationUserId,
}: DisconnectModalProps) => {
  const dispatch = useDispatch();

  const organizationUser = useSelector((state) => getOrganizationUserForId(state, organizationUserId));
  const wsUsersById = useSelector(getWorkspaceUsersById);
  const currentOrganization = useSelector(getCurrentOrganization);
  const workspaceName = useSelector((state) => getWorkspaceForId(state, workspaceId))?.name ?? 'this workspace';

  const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { apiTokens, fetchApiTokens } = useFetchApiTokensAndUsers();
  const hasApiRunAs = apiTokens.some(
    (token) => token.tokenActive && token.organizationUserId.id === organizationUserId
  );

  useEffect(() => fetchApiTokens(), [fetchApiTokens]);

  const onDisconnectClick = () => {
    setError(undefined);
    setApiRequestInProgress(true);
    if (currentOrganization && organizationUser) {
      const { uuid } = dispatch(
        WorkspaceUserDao.update(
          {
            organizationId: currentOrganization.id,
            workspaceId,
            workspaceUserId,
          },
          { id: workspaceUserId, disconnected: true }
        )
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onError: () => setError('Disconnect Failed'),
          onSuccess: () => {
            const { uuid: orgUserUuid } = dispatch(
              OrganizationUserDao.fetch({
                organizationId: currentOrganization.id,
                organizationUserId: organizationUser.id,
              })
            );
            dispatch(
              awaitRequestFinish(orgUserUuid, {
                onError: () => setError('Disconnect Failed'),
                onSuccess: () => {
                  fetch();
                  close();
                },
              })
            );
          },
        })
      );
    }
  };

  const wsTally = wsUserWorkspaceTally(wsUsersById, organizationUser?.user?.id ?? 0);

  if (!organizationUser || !currentOrganization || !wsTally) {
    return null;
  }

  return (
    <LpModal
      className="disconnect-modal"
      header={'Are you sure?'}
      content={
        <>
          <div className="disconnect-modal__content">
            {wsTally > 1 && (
              <p>
                You are about to remove {organizationUser.username} from {workspaceName}.
              </p>
            )}
            {wsTally === 1 && (
              <>
                <p>
                  You are disconnecting {organizationUser.username} from their last workspace, so they will be
                  disconnected from the {currentOrganization.name} account as well.
                </p>
                <p>You will need to re-invite them to join a workspace again in the future.</p>
              </>
            )}
            {hasApiRunAs && (
              <>
                <p>There is at least one active API Token tied to this user&apos;s account.</p>
                <p>
                  Disconnecting the user will break their current API Integration(s), until an Org Admin transfers their
                  token to a different active user account.
                </p>
              </>
            )}
          </div>
          {error && (
            <div className="disconnect-modal__error">
              <p>{error}</p>
            </div>
          )}
        </>
      }
      actions={
        <>
          <Button content={'Cancel'} onClick={close} />
          <Button
            disabled={apiRequestInProgress}
            className="disconnect-modal__actions-disconnect"
            content={'Disconnect'}
            onClick={onDisconnectClick}
            negative
          />
        </>
      }
    />
  );
};
