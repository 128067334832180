import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, Radio } from 'semantic-ui-react';

import ItemIcon from 'containers/shared/item_icon';
import { ItemType, PackageStatus, Permission } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { OrderItemFetch } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { PackageStatusDisplay } from 'daos/pkg';
import { filterAnd, filterItemType, filterPackageStatus } from 'daos/shared';
import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import LpLoaderAnimatedGrid from 'features/common/loaders/lp_item_panel_loader';
import { boxHeartSolid, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { hasAccess } from 'hooks/use_has_access';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getItemForId } from 'redux/entities/selectors/item';

import './index.scss';

interface CreateProjectInPackageModalProps {
  handleCloseModal: () => void;
  projectId: number;
  isCreatingTemplate: boolean;
}

type ProjectsByPackageStatus = {
  [key in PackageStatus]: Array<Item>;
};

const groupPackagesByPackageStatus = (packages: ReadonlyArray<Item>): Record<PackageStatus, Array<Item>> => {
  return packages.reduce((acc, pkg) => {
    const pkgStatus = pkg.packageStatus;
    if (pkgStatus) {
      const projectsByCollection = acc[pkgStatus];

      if (projectsByCollection) {
        acc[pkgStatus] = [...projectsByCollection, pkg];
      } else {
        acc[pkgStatus] = [pkg];
      }
    }

    return acc;
  }, {} as ProjectsByPackageStatus);
};

export const CreateProjectInPackageModal = ({
  handleCloseModal,
  projectId,
  isCreatingTemplate,
}: CreateProjectInPackageModalProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const project = useSelector((state) => getItemForId(state, projectId));

  const [packages, setPackages] = useState<Record<PackageStatus, Array<Item>> | undefined>(undefined);
  const [selectedPackageId, setSelectedPackageId] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPackages = useCallback(() => {
    const packageStatusFilter = isCreatingTemplate
      ? PackageStatus.TEMPLATE
      : [PackageStatus.SCHEDULED, PackageStatus.BACKLOG];

    const { uuid } = dispatch(
      ItemDao.fetchAll(
        { organizationId, workspaceId },
        {
          filter: filterAnd(filterPackageStatus(packageStatusFilter), filterItemType([ItemType.PACKAGES])),
          query: {
            order: OrderItemFetch.GlobalPriority,
          },
        }
      )
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<Item>>(uuid, {
        onSuccess: ({ data }) => {
          const filteredPackages = data.filter((item) => hasAccess(Permission.OBSERVE, item));
          setPackages(groupPackagesByPackageStatus(filteredPackages));
        },
        onFinish: () => setIsLoading(false),
      })
    );
  }, [dispatch, isCreatingTemplate, organizationId, workspaceId]);

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  const handleTemplateCreatedEvent = () => {
    setIsLoading(false);
    handleCloseModal();
  };

  useEvents({
    event: Events.ASYNC_JOB,
    callback: handleTemplateCreatedEvent,
    scope: EventScopes.None,
  });

  if (!packages) {
    return null;
  }

  const handleSubmit = () => {
    setIsLoading(true);

    dispatch(
      ItemDao.duplicateBulk(
        {
          organizationId,
          workspaceId,
        },
        [projectId],
        selectedPackageId
      )
    );
  };

  const handleRadioButtonSelect = (packageId: number) => {
    return () => setSelectedPackageId(packageId);
  };

  const packageList = (packages: Array<Item> | undefined, headerText: string) => {
    return (
      <List.Item>
        <div className="create-project-in-package-modal__list-header">{headerText}</div>
        <List.List>
          {packages ? (
            packages.map((pkg) => (
              <List.Item key={pkg.id} className="create-project-in-package-modal__package">
                <Radio
                  onChange={handleRadioButtonSelect(pkg.id)}
                  value={pkg.id}
                  checked={selectedPackageId === pkg.id}
                  className="create-project-in-package-modal__package-checkbox"
                />
                <ItemIcon itemId={pkg.id} className="create-project-in-package-modal__package-icon" />
                <span>{pkg.name}</span>
              </List.Item>
            ))
          ) : (
            <List.Item className="create-project-in-package-modal__no-packages-found">
              <p>Please create a package in this Collection in order to use it as a destination.</p>
            </List.Item>
          )}
        </List.List>
      </List.Item>
    );
  };
  const modalContent = isCreatingTemplate ? (
    packageList(packages[PackageStatus.TEMPLATE], 'Choose Package location')
  ) : (
    <>
      {packageList(packages[PackageStatus.SCHEDULED], `${PackageStatusDisplay.Scheduled} Collection`)}
      {packageList(packages[PackageStatus.BACKLOG], `${PackageStatusDisplay.Backlog} Collection`)}
    </>
  );

  const createActionText = isCreatingTemplate ? 'Template' : 'Project';
  const isAddButtonDisabled = isLoading || !selectedPackageId;

  return (
    <LpModal
      className="create-project-in-package-modal"
      onClose={handleCloseModal}
      size="tiny"
      header={
        <>
          <span className="create-project-in-package-modal__header-title">{`Creating ${createActionText}:`}</span>
          <ItemIcon size="1x" itemId={projectId} />
          <span className="create-project-in-package-modal__header-name">{project?.name}</span>
        </>
      }
      content={
        <>
          {isLoading ? (
            <div className="create-project-in-package-modal__loader">
              <LpLoaderAnimatedGrid label="" />
            </div>
          ) : (
            <List className="create-project-in-package-modal__list">{modalContent}</List>
          )}
        </>
      }
      actions={
        <>
          {isCreatingTemplate && (
            <LpButtonAsLink
              className="create-project-in-package-modal__link-action"
              to={frontend.templateCollectionPortfolio.url({
                organizationId,
                workspaceId,
              })}
            >
              <LpIcon icon={boxHeartSolid} /> Go to TEMPLATE Collection{' '}
            </LpButtonAsLink>
          )}
          <Button onClick={handleCloseModal} content="Close" />
          <Button disabled={isAddButtonDisabled} onClick={handleSubmit} primary content={`Add ${createActionText}`} />
        </>
      }
    />
  );
};
