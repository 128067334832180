import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { GroupDao } from 'daos/group';
import { Group, OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { GroupAvatarDisplay } from 'features/common/avatars/group_avatar';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { LpIcon, userFriendSolid } from 'features/common/lp_icon';
import {
  getCurrentWorkspaceNonGuestActiveAndDisconnectedOrgUsersAndGroups,
  getCurrentWorkspaceNonGuestActiveOrgUsersAndGroups,
  getCurrentWorkspaceUserDisconnectedByOrgUserIdMap,
} from 'redux/entities/selectors/user';
import { getOrgUsersForGroupId } from 'redux/entities/selectors/workspace_user_group';
import { slate600 } from 'style/variables';

const anyoneOption = {
  key: 'anyone',
  search: 'anyone',
  text: (
    <>
      <LpIcon className="anyone-icon" icon={userFriendSolid} color={slate600} /> Anyone
    </>
  ),
  value: JSON.stringify('anyone'),
};

const unassignedOption = {
  key: 'unassigned',
  search: 'unassigned',
  text: <>Unassigned</>,
  value: JSON.stringify('unassigned'),
};

const mapOrgUserToOption = (orgUser: OrganizationUser, isDisconnected?: boolean) => {
  const orgUserResource = OrganizationUserDao.id(orgUser);
  const serializedOrgUser = JSON.stringify(orgUserResource);

  return {
    key: serializedOrgUser,
    search: orgUser.username.toLowerCase(),
    text: (
      <div className="dropdown-option-row">
        <UserAvatar marginRight orgUserId={orgUser.id} size={AvatarSize.ExtraSmall_1_2} />
        <div
          className={classNames(isDisconnected ? 'dropdown-option-row__disconnected' : 'dropdown-option-row__active')}
        >
          {orgUser.username}
        </div>
      </div>
    ),
    value: serializedOrgUser,
  };
};

const mapGroupToOption = (group: Group) => {
  const groupResource = GroupDao.id(group);
  const serializedGroup = JSON.stringify(groupResource);

  return {
    key: serializedGroup,
    search: group.name.toLowerCase(),
    text: (
      <>
        <GroupAvatarDisplay color={group.color} icon={group.icon} name={group.name} size={AvatarSize.ExtraSmall_1_2} />
        {group.name}
      </>
    ),
    value: serializedGroup,
  };
};

export const useMapOrgUsersAndGroupsToDropdownOptions = ({
  includeUnassigned,
  includeDisconnected,
}: { includeUnassigned?: boolean; includeDisconnected?: boolean } = {}) => {
  const { activeOrgUsers, groups } = useSelector((state) => {
    return includeDisconnected
      ? getCurrentWorkspaceNonGuestActiveAndDisconnectedOrgUsersAndGroups(state)
      : getCurrentWorkspaceNonGuestActiveOrgUsersAndGroups(state);
  });

  const isOrgUserWorkspaceUserDisconnected = useSelector(getCurrentWorkspaceUserDisconnectedByOrgUserIdMap);

  const mapUserOptionsWithDisconnectedValue = (orgUser: OrganizationUser) => {
    const isDisconnected = isOrgUserWorkspaceUserDisconnected[orgUser.id];
    return mapOrgUserToOption(orgUser, isDisconnected);
  };

  const options = [
    anyoneOption,
    ...activeOrgUsers.map(mapUserOptionsWithDisconnectedValue),
    ...groups.map(mapGroupToOption),
  ];

  if (includeUnassigned) {
    options.splice(1, 0, unassignedOption);
  }

  return options;
};

export const useMapOrgUsersForGroupIdToDropdownOptions = (groupId: number) => {
  const groupOrgUsers = useSelector((state) => (groupId ? getOrgUsersForGroupId(state, groupId) : undefined));

  if (groupOrgUsers) {
    return [anyoneOption, ...groupOrgUsers.map((group) => mapOrgUserToOption(group, false))];
  }

  return [];
};
