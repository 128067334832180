import { SyntheticEvent, useMemo } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatarRenderer } from 'features/common/avatars/user_avatar';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { IntakeCustomFieldUserWidgetData } from 'features/dashboards_v2/widget_click_through/intake/types';

interface UserDropdownPropsRenderer {
  className?: string;
  clearable: DropdownProps['clearable'];
  disabled?: DropdownProps['disabled'];
  onChange?: (event: SyntheticEvent<HTMLElement>, { value }: { value: number }) => void;
  placeholder: DropdownProps['placeholder'];
  selectOnBlur?: boolean;
  selectedUserId?: number;
  userOptions: ReadonlyArray<IntakeCustomFieldUserWidgetData>;
  error?: boolean;
  usePortal?: boolean;
}

export const UserDropdownRenderer = ({
  className,
  clearable,
  disabled,
  onChange,
  placeholder,
  selectOnBlur = false,
  selectedUserId,
  userOptions = [],
  error = false,
  usePortal = false,
}: UserDropdownPropsRenderer) => {
  const handleChange = (event: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (onChange) {
      onChange(event, { value: value as number });
    }
  };

  const dropdownOptions = useMemo(() => {
    const options: Array<DropdownItemProps & { search: string }> = userOptions.map((user) => {
      return {
        text: (
          <span>
            <UserAvatarRenderer
              className=""
              avatarUrl={user.avatarUrl}
              size={AvatarSize.ExtraSmall_1_2}
              userType={user.userType}
              displayName={user.displayName}
            />
            {user.username}
          </span>
        ),
        value: user.id,
        search: user.username.toLocaleLowerCase(),
        key: user.id,
      };
    });

    if (clearable) {
      options.unshift({
        text: <span> Unassigned </span>,
        value: 0,
        search: 'UNASSIGNED|NONE',
        key: 'UNASSIGNED|NONE',
      });
    }

    return options;
  }, [clearable, userOptions]);

  const userDropdown = (
    <Dropdown
      className={className}
      disabled={disabled}
      lazyLoad
      error={error}
      onChange={handleChange}
      options={dropdownOptions}
      placeholder={placeholder}
      search={lpDropdownSearch}
      selectOnBlur={selectOnBlur}
      selectOnNavigation={false}
      selection
      upward={false}
      value={!selectedUserId ? '' : selectedUserId}
    />
  );

  return usePortal ? <PortalDropdown>{userDropdown}</PortalDropdown> : userDropdown;
};
