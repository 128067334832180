import { addOptionalTextToCustomColumn } from 'containers/shared/custom_column/display_helpers';
import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import { customFieldWidgetConfigColumnProps } from 'containers/shared/custom_column/helpers';
import { CustomField, TaskStatus } from 'daos/model_types';
import { PropsWidgetColumn, SortDirection, WidgetConfigColumn } from 'daos/widget_column';
import { SelectionList, SelectionListType } from 'features/common/selection_list/types';
import { numberFromString } from 'lib/helpers';
import { EntityLookupById } from 'redux/entities/types';

function getPropsForSelectionList(col: WidgetConfigColumn, customFieldName: string, taskStatusName: string) {
  if (col.taskStatusId) {
    return {
      id: col.taskStatusId.toString(),
      name: `${taskStatusName} (Task Status)`,
      selectionListType: SelectionListType.TaskStatus,
    };
  }

  if (col.customFieldId) {
    return {
      id: col.customFieldId.toString(),
      name: `${customFieldName} (Custom)`,
      selectionListType: SelectionListType.CustomField,
    };
  }

  return {
    id: col.column,
    name: addOptionalTextToCustomColumn(col.column),
    selectionListType: SelectionListType.CustomColumnOptions,
  };
}

export function mapWidgetConfigColumnsToSelectionList(
  columns: ReadonlyArray<WidgetConfigColumn>,
  fieldsById: EntityLookupById<CustomField>,
  taskStatusById: EntityLookupById<TaskStatus>
) {
  return columns
    .map((col) => {
      const customFieldName = fieldsById[col.customFieldId ?? '']?.name ?? '';
      const taskStatusName = taskStatusById[col.taskStatusId ?? '']?.name ?? '';
      const isSortableColumn = !nonSortableColumns.has(col.column);

      const { id, name, selectionListType } = getPropsForSelectionList(col, customFieldName, taskStatusName);

      return {
        id,
        name,
        selectionListType,
        sortable: isSortableColumn,
      };
    })
    .filter((col) => !!col.id);
}

export function mapSelectionListToWidgetConfigColumns(
  selectionList: ReadonlyArray<SelectionList>,
  customFields: EntityLookupById<CustomField>
): ReadonlyArray<WidgetConfigColumn> {
  return selectionList.map((selection) => {
    const { selectionListType, id: columnKey } = selection;

    if (selectionListType === SelectionListType.CustomField) {
      const customField = customFields[columnKey];

      return {
        column: StandardColumns.CustomField,
        ...customFieldWidgetConfigColumnProps(customField),
      };
    }

    return {
      // Intentionally using StandardColumnOptions for these because widget columns must correspond with the server-side enum
      column: columnKey as StandardColumns,
    };
  });
}

export function mapSelectionListToColumns(
  columns: ReadonlyArray<SelectionList>,

  // Investigate: Why are there FrontendColumnOptions in this list if these icons are only
  // used in the properties_summary widget? (...which supports only server-side columns)
  sortId: Columns | number | null,
  sortDirection: SortDirection | null
) {
  return columns.map((col) => {
    const columnId = numberFromString(col.id);
    const columnType = col.selectionListType;
    let column = null;
    let customFieldId = null;
    let taskStatusId = null;

    if (columnType === SelectionListType.CustomColumnOptions) {
      column = col.id;
    }

    if (columnType === SelectionListType.CustomField) {
      column = StandardColumns.CustomField;
      customFieldId = columnId;
    }

    if (columnType === SelectionListType.TaskStatus) {
      column = StandardColumns.TaskStatus;
      taskStatusId = columnId;
    }

    const direction =
      (column === StandardColumns.CustomField && columnId == sortId) || column === sortId ? sortDirection : null;

    return {
      column,
      customFieldId,
      taskStatusId,
      direction,
    };
  });
}

// Investigate: Why are there FrontendColumnOptions in this list if these icons are only
// used in the properties_summary widget? (...which supports only server-side columns)
const nonSortableColumns: ReadonlySet<Columns> = new Set([StandardColumns.Assigned]);

// Intentionally using StandardColumnOptions here because these must correspond with the server-side enum
export function getPropsWidgetColumn(value: StandardColumns | undefined): PropsWidgetColumn {
  const valueAsCustomColumnOptions = value ?? StandardColumns.CustomField;

  const valueAsNumber = Number(value);
  const valueAsCustomFieldId = !isNaN(valueAsNumber) ? valueAsNumber : null;
  return { column: valueAsCustomColumnOptions, customFieldId: valueAsCustomFieldId };
}
