import { createCachedSelector } from 're-reselect';

import { TimesheetRollupValueProps } from 'containers/shared/custom_column/types';
import { TimesheetRollups } from 'daos/model_types';
import { convertSecondsToHours } from 'lib/helpers';
import { createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

const getTimesheetRollupById = (state: RootState) => state.entities.timesheetRollups;
const getTimesheetRollupForId = (state: RootState, itemId: number) => getTimesheetRollupById(state)[itemId];

const getTimesheetRollupValueProps = (timesheetRollup: TimesheetRollups | undefined) => {
  const totalLoggedWorkInSeconds = timesheetRollup?.totalLoggedWorkInSeconds ?? 0;
  const totalScheduledWorkInSeconds = timesheetRollup?.totalScheduledWorkInSeconds ?? 0;
  const totalWorkLoggedPlusScheduledInSeconds = timesheetRollup?.totalWorkLoggedPlusScheduledInSeconds ?? 0;

  const props: TimesheetRollupValueProps = {
    totalLoggedWorkInHours: convertSecondsToHours(totalLoggedWorkInSeconds),
    totalScheduledWorkInHours: convertSecondsToHours(totalScheduledWorkInSeconds),
    totalWorkLoggedPlusScheduledInHours: convertSecondsToHours(totalWorkLoggedPlusScheduledInSeconds),
  };

  return props;
};

export const getTimesheetRollupValuePropsForId = createCachedSelector(
  getTimesheetRollupForId,
  (timesheetRollupForItem) => getTimesheetRollupValueProps(timesheetRollupForItem)
)(createCacheByIdConfig());
