import { useSelector } from 'react-redux';
import { MenuHeader } from 'semantic-ui-react';

import { trackViewUrl } from 'features/academy/helpers';
import { getOutpostLocation } from 'features/academy/outpost/selectors';
import { getLessonForId, getTrackForId, getTrackLessonForId } from 'features/academy/selectors';
import { getOutpostTrackLessonsSortedByPriorityForOutpostLocation } from 'features/academy/selectors/outpost';
import { LpIcon, graduationCapSolid } from 'features/common/lp_icon';

import { UserMenuTableItem } from './table_item';

const SuggestedLesson = ({ trackLessonId }: { trackLessonId: string }) => {
  const trackLesson = useSelector((state) => getTrackLessonForId(state, trackLessonId));
  const lesson = useSelector((state) => (trackLesson ? getLessonForId(state, trackLesson.academyLessonId) : undefined));
  const track = useSelector((state) => (trackLesson ? getTrackForId(state, trackLesson.academyTrackId) : undefined));
  if (!trackLesson || !lesson || !track) {
    return null;
  }

  const courseId = track.academyCourseId;
  const trackId = track.id;
  const lessonId = lesson.id;

  return (
    <UserMenuTableItem to={trackViewUrl({ courseId, trackId, lessonId, path: location.pathname })}>
      <LpIcon icon={graduationCapSolid} />
      {lesson.title}
    </UserMenuTableItem>
  );
};

export const SuggestedLessons = () => {
  const outpostLocation = useSelector(getOutpostLocation);

  const outpostTrackLessons = useSelector((state) =>
    outpostLocation ? getOutpostTrackLessonsSortedByPriorityForOutpostLocation(state, outpostLocation) : undefined
  );

  if (!outpostLocation || !outpostTrackLessons || outpostTrackLessons.length === 0) {
    return null;
  }

  return (
    <>
      <MenuHeader className="user-menu__table-header" content={'Suggested Lessons'} />
      {outpostTrackLessons.map((trackLesson) => (
        <SuggestedLesson key={trackLesson.academyTrackLessonId} trackLessonId={trackLesson.academyTrackLessonId} />
      ))}
    </>
  );
};
