import { CustomFieldType } from 'daos/enums';
import {
  CalculatedLpSystemId,
  FieldMappingRowType,
  FieldMappingValue,
  JiraCustomFieldId,
  JiraCustomFieldType,
  JiraProjectModalFormFieldMapping,
  LpSystemId,
} from 'features/jira_project/modal/types';

export const getAvailableFieldMappingRowNameColumnValues = ({
  rowType,
  jiraProjectMappingFieldData,
  allLpFields,
  addedFieldMappingRows,
}: {
  rowType: FieldMappingRowType;
  jiraProjectMappingFieldData: ReadonlyArray<FieldMappingValue>;
  allLpFields: Array<FieldMappingValue>;
  addedFieldMappingRows: Set<FieldMappingValue>;
}): ReadonlyArray<FieldMappingValue> => {
  switch (rowType) {
    case FieldMappingRowType.JiraToLp:
      return jiraProjectMappingFieldData.filter(
        (item) => !Array.from(addedFieldMappingRows).some((addedItem) => addedItem.id === item.id)
      );
    case FieldMappingRowType.LpToJira:
      return allLpFields.filter(
        (item) => !Array.from(addedFieldMappingRows).some((addedItem) => addedItem.id === item.id)
      );
  }
};

export const getFieldMappingNameColumnPicklistOptions = ({
  rowType,
  nameMapping,
  allLpFields,
  jiraProjectMappingFieldData,
  addedJiraFieldMappingRowItems,
  addedLpFieldMappingRowItems,
  fieldMappingValues,
}: {
  rowType: FieldMappingRowType;
  nameMapping: FieldMappingValue;
  allLpFields: Array<FieldMappingValue>;
  jiraProjectMappingFieldData: ReadonlyArray<FieldMappingValue>;
  addedJiraFieldMappingRowItems: Set<FieldMappingValue>;
  addedLpFieldMappingRowItems: Set<FieldMappingValue>;
  fieldMappingValues: Array<JiraProjectModalFormFieldMapping>;
}) => {
  if (rowType === FieldMappingRowType.JiraToLp) {
    const addedRows = jiraProjectMappingFieldData
      .filter((item) => !Array.from(addedJiraFieldMappingRowItems).some((addedItem) => addedItem.id === item.id))
      .filter((item) => !fieldMappingValues.find((field) => field.sourceFieldId === item.id));
    return [nameMapping, ...addedRows].sort((a, b) => a.name.localeCompare(b.name));
  }

  const addedRows = allLpFields
    .filter((item) => !Array.from(addedLpFieldMappingRowItems).some((addedItem) => addedItem.id === item.id))
    .filter(
      (item) =>
        !fieldMappingValues.find(
          (field) => field.lpCustomFieldId === Number(item.id) || field.lpSystemField === item.id
        )
    );
  return [nameMapping, ...addedRows].sort((a, b) => a.name.localeCompare(b.name));
};

export const getFieldMappingPicklistOptions = ({
  rowType,
  allLpFields,
  jiraProjectMappingFieldData,
  nameMapping,
  addedJiraFieldMappingRowItems,
  addedLpFieldMappingRowItems,
  fieldMappingValues,
}: {
  rowType: FieldMappingRowType;
  nameMapping: FieldMappingValue;
  allLpFields: Array<FieldMappingValue>;
  jiraProjectMappingFieldData: ReadonlyArray<FieldMappingValue>;
  addedJiraFieldMappingRowItems: Set<FieldMappingValue>;
  addedLpFieldMappingRowItems: Set<FieldMappingValue>;
  fieldMappingValues: Array<JiraProjectModalFormFieldMapping>;
}): ReadonlyArray<FieldMappingValue> => {
  const rowValue =
    rowType === FieldMappingRowType.LpToJira
      ? fieldMappingValues.find(
          (field) => field.lpCustomFieldId === Number(nameMapping.id) || field.lpSystemField === nameMapping.id
        )
      : fieldMappingValues.find((field) => field.sourceFieldId === nameMapping.id);

  const existingField =
    rowType === FieldMappingRowType.LpToJira
      ? jiraProjectMappingFieldData.find((field) => field.id === rowValue?.sourceFieldId)
      : allLpFields.find(
          (field) => Number(field.id) === rowValue?.lpCustomFieldId || field.id === rowValue?.lpSystemField
        );

  const availableLpFields = allLpFields
    // remove LP fields which are calculated and cannot be written to
    .filter((field) => !Object.values(CalculatedLpSystemId).includes(field.id as CalculatedLpSystemId))
    // remove LP fields which have rows in the bottom table
    .filter((item) => !Array.from(addedLpFieldMappingRowItems).some((addedItem) => addedItem.id === item.id))
    // remove LP fields which have already been mapped
    .filter(
      (item) =>
        !fieldMappingValues.find(
          (field) => field.lpCustomFieldId === Number(item.id) || field.lpSystemField === item.id
        )
    );

  const availableJiraFields = jiraProjectMappingFieldData
    .filter((item) => !Array.from(addedJiraFieldMappingRowItems).some((addedItem) => addedItem.id === item.id))
    .filter((item) => !fieldMappingValues.find((field) => field.sourceFieldId === item.id))
    .filter((item) => item.isEditable ?? false);

  const fields = rowType === FieldMappingRowType.LpToJira ? availableJiraFields : availableLpFields;
  const filteredFieldsByType = fields.filter(
    (field) =>
      shouldIncludePicklistOption({
        rowType,
        fieldMappingValue: field,
      }) &&
      isFieldTypeMatch({
        fieldMappingValueA: nameMapping,
        fieldMappingValueB: field,
      })
  );

  return [...(existingField ? [existingField] : []), ...filteredFieldsByType].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
};

export const isFieldTypeMatch = ({
  fieldMappingValueA,
  fieldMappingValueB,
}: {
  fieldMappingValueA: FieldMappingValue;
  fieldMappingValueB: FieldMappingValue;
}) => {
  const isOriginalEstimate = ({ id }: FieldMappingValue) => id === JiraCustomFieldId.OriginalEstimate;
  const isWorkLimit = ({ id }: FieldMappingValue) => id === LpSystemId.WorkLimit;
  const isBenchmarkEstimate = ({ id }: FieldMappingValue) => id === LpSystemId.BenchmarkEstimate;

  const isNumericType = ({ type }: FieldMappingValue) =>
    (type === CustomFieldType.NUMERIC || type === 'number') && !isOriginalEstimate(fieldMappingValueA);
  const isDateType = ({ type }: FieldMappingValue) =>
    type === CustomFieldType.DATE || type === 'date' || type === 'datetime';
  const isStringType = ({ custom, id, type }: FieldMappingValue) =>
    type === CustomFieldType.TEXT ||
    (type === 'string' && custom !== JiraCustomFieldType.Url) ||
    id === LpSystemId.ItemUrl;

  const isPicklistType = ({ type }: FieldMappingValue) => type === CustomFieldType.PICKLIST || type === 'option';
  const isMultiPicklistType = ({ type }: FieldMappingValue) =>
    type === CustomFieldType.MULTI_PICKLIST || type === 'array';
  const isLinkType = ({ custom, id, type }: FieldMappingValue) =>
    type === CustomFieldType.LINK || custom === JiraCustomFieldType.Url || id === LpSystemId.ItemUrl;

  if (isOriginalEstimate(fieldMappingValueA)) {
    return isWorkLimit(fieldMappingValueB) || isBenchmarkEstimate(fieldMappingValueB);
  }

  if (isBenchmarkEstimate(fieldMappingValueA) || isWorkLimit(fieldMappingValueA)) {
    return isOriginalEstimate(fieldMappingValueB);
  }

  if (isNumericType(fieldMappingValueA) && isNumericType(fieldMappingValueB)) {
    return true;
  }
  if (isDateType(fieldMappingValueA) && isDateType(fieldMappingValueB)) {
    return true;
  }
  if (isStringType(fieldMappingValueA) && isStringType(fieldMappingValueB)) {
    return true;
  }
  if (isPicklistType(fieldMappingValueA) && isPicklistType(fieldMappingValueB)) {
    return true;
  }
  if (isMultiPicklistType(fieldMappingValueA) && isMultiPicklistType(fieldMappingValueB)) {
    return true;
  }
  if (isLinkType(fieldMappingValueA) && isLinkType(fieldMappingValueB)) {
    return true;
  }
  return fieldMappingValueA === fieldMappingValueB;
};

export const shouldIncludePicklistOption = ({
  rowType,
  fieldMappingValue,
}: {
  rowType: FieldMappingRowType;
  fieldMappingValue: FieldMappingValue;
}) => {
  if (rowType === FieldMappingRowType.LpToJira) {
    if (fieldMappingValue.custom === JiraCustomFieldType.Sprint) {
      return false;
    }
  }

  return true;
};
