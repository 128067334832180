import { Action } from '@reduxjs/toolkit';
import { all, call, put, take, takeEvery } from 'typed-redux-saga';

import { checkError, setApiError } from 'features/errors/slice';
import {
  ApiRequest,
  API_REQUEST,
  AWAIT_REQUEST_FINISH,
  ErrorCodes,
  ErrorPayload,
  SuccessPayload,
  AwaitFinishAction,
} from 'lib/api/types';
import { requestFinishedSuccess, requestFinishedFailure, requestStarted } from 'redux/sagas/api_saga/request_actions';

const ERROR_CODE_START = 400;

const JSON_REGEX = /json/;

const isJSON = (resp: Response) => {
  const responseContentType = resp.headers.get('Content-Type');
  return responseContentType ? Boolean(JSON_REGEX.exec(responseContentType)) : false;
};

export function* apiRequest(requestAction: ApiRequest) {
  const { meta, payload: requestPayload } = requestAction;
  const { body, endpoint, finishAction, headers, method, payloadActions, uuid } = requestPayload;

  const [beginAction, successAction, failureAction] = payloadActions;

  yield* put(beginAction);
  yield* put(requestStarted(uuid));

  let status: number | undefined;
  let statusText: string | undefined;
  let success = false;
  let response: Response | undefined;

  try {
    response = yield* call(() =>
      fetch(endpoint, {
        body,
        headers,
        method,
      })
    );

    success = response.ok && response.status < ERROR_CODE_START;
    status = response.status;
    statusText = response.statusText;
  } catch (error) {
    if (error instanceof Error) {
      statusText = error.message;
    }
  }

  const { payload } = success ? successAction : failureAction;

  let result;

  if (response) {
    if (payload) {
      result = yield* call(() => payload(response as Response));
    } else {
      if (isJSON(response)) {
        result = yield* call([response, response.json]);
      } else {
        result = yield* call([response, response.text]);
      }
    }
  }

  if (!success && (typeof result === 'string' || !result || !result.errors)) {
    result = { errors: [unhandledServerError(statusText, status)] };
  }

  if (success) {
    const resultPayload = result as SuccessPayload<any> | undefined;
    yield* put(requestFinishedSuccess(uuid, success, resultPayload, meta));
  }

  if (!success) {
    const resultPayload = result as ErrorPayload;
    yield* put(checkError({ status: status ?? 500 }));
    yield* put(requestFinishedFailure(uuid, false, resultPayload, meta));
  }

  if (finishAction) {
    yield* put(finishAction);
  }
}

function* awaitRequestFinishSuccess(action: AwaitFinishAction) {
  const {
    payload: {
      requestUuid,
      methods: { onSuccess, onFinish },
    },
  } = action;

  const finishedActionSuccess = yield* take<typeof requestFinishedSuccess>(
    (action: Action) =>
      requestFinishedSuccess.match(action) && requestUuid === action.payload.uuid && action.payload.success === true
  );

  if (requestFinishedSuccess.match(finishedActionSuccess)) {
    const {
      payload: { resultPayload },
    } = finishedActionSuccess;

    onSuccess(resultPayload);
  }

  onFinish();
}

function* awaitRequestFinishFailure(action: AwaitFinishAction) {
  const {
    payload: {
      requestUuid,
      methods: { onError, onFinish },
    },
  } = action;

  const failedFinishedAction = yield* take<typeof requestFinishedFailure>(
    (action: Action) =>
      requestFinishedFailure.match(action) && requestUuid === action.payload.uuid && action.payload.success === false
  );

  if (requestFinishedFailure.match(failedFinishedAction)) {
    const {
      payload: { resultPayload },
    } = failedFinishedAction;

    if (onError) {
      onError(resultPayload);
    } else {
      const apiError = resultPayload.errors[0];
      if (apiError) {
        yield* put(setApiError(apiError));
      }
    }
  }

  onFinish();
}

export default function* rootSaga() {
  yield* all([
    takeEvery(AWAIT_REQUEST_FINISH, awaitRequestFinishSuccess),
    takeEvery(AWAIT_REQUEST_FINISH, awaitRequestFinishFailure),
    takeEvery(API_REQUEST, apiRequest),
  ]);
}

function unhandledServerError(detail?: string, status?: number) {
  return {
    code: ErrorCodes.ServerError,
    title: 'An unknown server error occurred',
    detail,
    status: status ?? 500,
  };
}
