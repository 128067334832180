import { UserType } from 'daos/enums';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { DefaultAvatarDisplay } from 'features/common/avatars/default_avatar';
import { UserAvatarDisplay } from 'features/common/avatars/user_avatar';

interface WidgetAvatarProps {
  avatarUrl: string | null;
  username: string;
  userType: UserType;
  size?: AvatarSize;
  avatarColor?: string | null;
  className?: string;
  marginRight?: boolean;
}

export const WidgetAvatar = ({
  avatarColor,
  username,
  userType,
  avatarUrl,
  className,
  size,
  marginRight = false,
}: WidgetAvatarProps) => {
  return avatarUrl && userType === UserType.Member ? (
    <UserAvatarDisplay className={className} marginRight={marginRight} size={size} avatarUrl={avatarUrl} />
  ) : (
    <DefaultAvatarDisplay
      className={className}
      marginRight={marginRight}
      size={size}
      name={username}
      userType={userType}
      avatarColor={avatarColor}
    />
  );
};
