import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getDashboardForId } from 'features/dashboards/selectors';

const useWidgetParams = () => {
  const { itemId, packageId, widgetId, dashboardId } = useParams<{
    itemId: string | undefined;
    packageId: string | undefined;
    dashboardId: string | undefined;
    widgetId: string;
  }>();
  const dashboard = useSelector((state) => (dashboardId ? getDashboardForId(state, dashboardId) : undefined));

  if (packageId) {
    return { itemId: packageId, widgetId, dashboardId };
  }

  if (dashboardId && dashboard) {
    return { itemId: dashboard.itemId, widgetId, dashboardId };
  }

  return { itemId, widgetId, dashboardId };
};

export default useWidgetParams;
