import { SetStateAction, Dispatch, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import FolderStatusIcon from 'containers/shared/folder_status_icon';
import ItemIcon from 'containers/shared/item_icon';
import { packageIcon } from 'containers/shared/move_modal/item_move_helpers';
import { ItemType, PackageStatus } from 'daos/enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getItemPickerSelection } from 'features/common/item_picker/selectors';
import { LpIcon } from 'features/common/lp_icon';
import { getItemForId } from 'redux/entities/selectors/item';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';
import { slate700 } from 'style/variables';

interface ItemPickerModalProps {
  atWorkspaceRoot: boolean;
  packageStatus: PackageStatus | null;
  parentId: number;
  updatePackageStatus: Dispatch<SetStateAction<PackageStatus | null>>;
  enableParentContainerSelection?: boolean;
  onParentContainerSelection?: (_: React.SyntheticEvent, { checked, value }: CheckboxProps) => void;
}

const ItemPickerParent = ({
  atWorkspaceRoot,
  packageStatus,
  parentId,
  updatePackageStatus,
  enableParentContainerSelection,
  onParentContainerSelection,
}: ItemPickerModalProps) => {
  const parentItem = useSelector((state) => getItemForId(state, parentId));
  const workspace = useSelector(getCurrentWorkspace);
  const parentItemType = parentItem?.itemType;
  const selection = useSelector(getItemPickerSelection);

  const headerIcon = useMemo(() => {
    if (packageStatus && !atWorkspaceRoot && parentItemType !== ItemType.PACKAGES) {
      return <LpIcon size="lg" icon={packageIcon(packageStatus)} color={slate700} />;
    }
    return <ItemIcon size="lg" itemId={parentId} />;
  }, [packageStatus, atWorkspaceRoot, parentItemType, parentId]);

  const headerName = useMemo(() => {
    if (atWorkspaceRoot) {
      return workspace?.name;
    }

    if (packageStatus && parentItemType === ItemType.WORKSPACE_ROOTS) {
      return packageStatusDisplayNameByPackageStatus[packageStatus];
    }

    return parentItem?.name;
  }, [atWorkspaceRoot, packageStatus, parentItem, workspace, parentItemType]);

  useEffect(() => {
    if (packageStatus && parentItemType === ItemType.PROJECTS) {
      updatePackageStatus(null);
    }
  }, [packageStatus, parentItemType, updatePackageStatus]);

  if (!parentItem) {
    return null;
  }

  const disableCheckboxAtCollectionLevel = atWorkspaceRoot && !!packageStatus;

  return (
    <div className="lp-item-picker__content-parent">
      {enableParentContainerSelection && (
        <Checkbox
          checked={!!selection[parentId]}
          disabled={disableCheckboxAtCollectionLevel}
          onChange={onParentContainerSelection}
          value={parentItem.id}
        />
      )}
      {headerIcon} <FolderStatusIcon folderStatus={parentItem.folderStatus} /> <span>{headerName}</span>
    </div>
  );
};

export default ItemPickerParent;
