import { useSelector } from 'react-redux';

import { PropertyChange } from 'daos/types';
import { squareSolid, LpIcon } from 'features/common/lp_icon';
import { getPicklistChoiceForId } from 'redux/entities/selectors/custom_field';

const ChangeCardPickListChoiceProperty = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const choiceId = propertyChangeData.value.id;
  const picklistChoice = useSelector((state) => getPicklistChoiceForId(state, choiceId));

  if (!picklistChoice) {
    return null;
  }

  return (
    <>
      <LpIcon
        className="lp-change__card-change-property-picklist-choice"
        icon={squareSolid}
        color={`#${picklistChoice.color}`}
      />
      {picklistChoice.name}
    </>
  );
};

export default ChangeCardPickListChoiceProperty;
