export const PortfolioSvg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g id="Integration">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C1.79375 0 0 1.92188 0 4.28571V25.7143C0 28.0781 1.79375 30 4 30H21.8447C20.105 28.4408 19 26.1075 19 23.5C19 18.8056 22.5817 15 27 15C28.8919 15 30.6304 15.6978 32 16.8643V4.28571C32 1.92188 30.2062 0 28 0H4ZM4.58579 7.9438C4.21071 7.54194 4 6.99689 4 6.42857C4 5.86025 4.21071 5.31521 4.58579 4.91334C4.96086 4.51148 5.46957 4.28571 6 4.28571C6.53043 4.28571 7.03914 4.51148 7.41421 4.91334C7.78929 5.31521 8 5.86025 8 6.42857C8 6.99689 7.78929 7.54194 7.41421 7.9438C7.03914 8.34566 6.53043 8.57143 6 8.57143C5.46957 8.57143 4.96086 8.34566 4.58579 7.9438ZM26.5 4.82143C27.3312 4.82143 28 5.53795 28 6.42857C28 7.3192 27.3312 8.03572 26.5 8.03572H11.5C10.6687 8.03572 10 7.3192 10 6.42857C10 5.53795 10.6687 4.82143 11.5 4.82143H26.5Z"
        fill="#3D546A"
      />
    </g>
  </svg>
);
