import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, List } from 'semantic-ui-react';

import TaskIconMaker from 'containers/shared/task_icon_maker';
import { StatusFilterGroups, TaskStatusDropdownFilter } from 'daos/enums';
import {
  circleSolid,
  plusCircleSolid,
  dotCircleSolid,
  pauseCircleSolid,
  checkCircleSolid,
  LpIcon,
} from 'features/common/lp_icon';
import { getActiveCurrentWorkspaceTaskStatusesSortedByPriority } from 'redux/entities/selectors/task_status';
import { slate600 } from 'style/variables';

import './item_status_list.scss';

export interface TaskStatusListValue {
  selectedTaskStatusFilter: StatusFilterGroups;
  selectedTaskStatusIds: ReadonlyArray<number>;
}

interface TaskStatusListProps {
  onChange: (value: TaskStatusListValue) => void;
  value: TaskStatusListValue;
}

const options = [
  { icon: circleSolid, text: TaskStatusDropdownFilter.ALL, value: StatusFilterGroups.All },
  { icon: plusCircleSolid, text: TaskStatusDropdownFilter.ACTIVE, value: StatusFilterGroups.ActiveAndOnHold },
  { icon: dotCircleSolid, text: TaskStatusDropdownFilter.SCHEDULED, value: StatusFilterGroups.Active },
  { icon: pauseCircleSolid, text: TaskStatusDropdownFilter.ON_HOLD, value: StatusFilterGroups.OnHold },
  { icon: checkCircleSolid, text: TaskStatusDropdownFilter.DONE, value: StatusFilterGroups.Done },
];

const TaskStatusList = ({
  onChange,
  value: { selectedTaskStatusFilter, selectedTaskStatusIds },
}: TaskStatusListProps) => {
  const taskStatuses = useSelector(getActiveCurrentWorkspaceTaskStatusesSortedByPriority);

  const handleTaskStatusGroupCheckboxChange = (_: SyntheticEvent, { value }: CheckboxProps) => {
    onChange({
      selectedTaskStatusFilter: value as StatusFilterGroups,
      selectedTaskStatusIds: [],
    });
  };

  const handleCustomTaskStatusChange = (_: SyntheticEvent, { checked, value }: CheckboxProps) => {
    onChange({
      selectedTaskStatusFilter: StatusFilterGroups.Custom,
      selectedTaskStatusIds: checked
        ? [...selectedTaskStatusIds, value as number]
        : selectedTaskStatusIds.filter((id) => id !== (value as number)),
    });
  };

  const taskStatusFilterGroupOptions = options.map((option) => (
    <List.Item key={option.text} className="status-options-list__row">
      <List.Content className="status-options-list__row-cell">
        <label className="status-options-list__row-cell-name">
          <Checkbox
            className="status-options-list__row-cell-checkbox"
            checked={selectedTaskStatusFilter === option.value && !selectedTaskStatusIds.length}
            onChange={handleTaskStatusGroupCheckboxChange}
            value={option.value}
          />
          <LpIcon className="icon status-options-list__row-cell-icon" color={slate600} icon={option.icon} />
          {option.text}
        </label>
      </List.Content>
    </List.Item>
  ));

  const customTaskStatusOptions = taskStatuses.map(({ id: taskStatusId, name }) => (
    <List.Item key={`${name}${taskStatusId}`} className="status-options-list__row">
      <List.Content className="status-options-list__row-cell">
        <label className="status-options-list__row-cell-name">
          <Checkbox
            className="status-options-list__row-cell-checkbox"
            checked={selectedTaskStatusIds.includes(taskStatusId)}
            onChange={handleCustomTaskStatusChange}
            value={taskStatusId}
          />
          {taskStatusId && <TaskIconMaker className="status-options-list__row-cell-icon" taskStatusId={taskStatusId} />}{' '}
          {name}
        </label>
      </List.Content>
    </List.Item>
  ));

  return (
    <div className="status-options-list">
      <span className="status-options-list__header">
        <div className="status-options-list__header-name">Task Status</div>
      </span>
      <div className="status-options-list__scroll-container">
        <List divided relaxed>
          <List.Item className="status-options-list__row-cell-info">Status Groups</List.Item>
          {taskStatusFilterGroupOptions}
          <List.Item className="status-options-list__row-cell-info">Individual Status</List.Item>
          {customTaskStatusOptions}
        </List>
      </div>
    </div>
  );
};

export default TaskStatusList;
