import { WidgetWithData } from 'daos/model_types';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { LinkedNoteWidgetData } from 'features/dashboards_v2/widget/widgets/linked_note/types';

import './linked_note.scss';

export const LinkedNoteContent = ({ widget }: { widget: WidgetWithData }) => {
  const data = widget.data as LinkedNoteWidgetData;

  return data ? (
    <div
      dangerouslySetInnerHTML={{
        __html: data.html ?? '',
      }}
    />
  ) : (
    <p className="v2-widget-linked-note--not-configured">Not available as configured</p>
  );
};

export const LinkedNoteWidget = ({ widget }: { widget: WidgetWithData }) => {
  return <RenderedWidget body={<LinkedNoteContent widget={widget} />} />;
};
