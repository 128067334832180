import { useFormikContext } from 'formik';

import { Widget } from 'daos/model_types';
import RichTextEditor from 'features/common/inputs/rich_text_editor';
import { LpIcon, penToSquareRegular } from 'features/common/lp_icon';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { Title } from 'features/dashboards_v2/widget_settings/title';

const fieldName = 'content';

export const DashboardNoteWidgetSettings = ({ widget }: { widget: Widget }) => {
  const { setFieldValue } = useFormikContext();
  const { config } = widget;

  const onDashboardNoteChange = (value: string) => {
    setFieldValue(fieldName, value);
  };

  const header = (
    <>
      <LpIcon icon={penToSquareRegular} /> {'Dashboard Note (saved with dashboard)'}
    </>
  );

  const content = (
    <>
      <Title widgetId={widget.id} />
      <RichTextEditor content={config.content ?? ''} handleEditorChange={onDashboardNoteChange} height={'400px'} />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
