export enum ItemPanelSections {
  ACCESS = 'ACCESS',
  CHANGES = 'CHANGES',
  DISCUSSIONS = 'DISCUSSIONS',
  NOTE = 'NOTE',
  PLANNING = 'PLANNING',
  PROPERTIES = 'PROPERTIES',
  FILES = 'Files',
  SCHEDULING = 'SCHEDULING',
}

export enum FieldDisplayText {
  ASAPScheduling = 'ASAP Scheduling',
  AssignmentScheduling = 'Assignment Scheduling',
  AtFinish = 'At Finish...',
  BenchmarkEstimate = 'Benchmark Estimate',
  Description = 'Description',
  EndDate = 'End Date',
  ProjectCollection = 'Project Collection',
  BillingRateSheet = 'Billing Rate Sheet',
  PayRateSheet = 'Pay Rate Sheet',
  StartDate = 'Start Date',
  TargetFinish = 'Target Finish',
  TargetStart = 'Target Start',
  TaskStatus = 'Task Status',
}
