import { ReactNode } from 'react';
import { Message } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';

export enum ResetPasswordErrors {
  Claimed = 'token has already been claimed',
  Expired = 'token has expired',
}

export const resetPasswordErrors = (field: string) => {
  let resetPasswordError: string | ReactNode | undefined;

  switch (field) {
    case ResetPasswordErrors.Claimed: {
      resetPasswordError = (
        <>
          {'The link is no longer valid. Request a new one on the '}
          <span>
            <LpLink to={frontend.forgotPassword.url({})}>{'Forgot Password'}</LpLink>
            {' page.'}
          </span>
        </>
      );

      return resetPasswordError;
    }

    case ResetPasswordErrors.Expired: {
      resetPasswordError = (
        <>
          {'The link expired due to inactivity. Request a new one on the '}
          <span>
            <LpLink to={frontend.forgotPassword.url({})}>{'Forgot Password'}</LpLink>
            {' page.'}
          </span>
        </>
      );

      return resetPasswordError;
    }

    default: {
      resetPasswordError = 'Contact LiquidPlanner support.';
      return resetPasswordError;
    }
  }
};

export const PasswordError = ({ claimedAt, expired }: { claimedAt: string | null; expired: boolean }) => {
  if (!claimedAt && !expired) {
    return null;
  }

  const errorKey = claimedAt ? ResetPasswordErrors.Claimed : ResetPasswordErrors.Expired;

  return <Message error visible className="password-form_api-error" content={resetPasswordErrors(errorKey)} />;
};
