import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';

import { Permission, UserType } from 'daos/enums';
import { User } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import { AuthType } from 'features/authentication/enums';
import useCurrentUserAuthType from 'features/authentication/hooks/use_current_user_auth_type';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import EditableUserAvatar from 'features/common/avatars/editable_user_avatar';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import { getLocalValidationErrors } from 'features/common/local_validation/selectors';
import { clearLocalValidation, clearLocalValidationErrorForKey } from 'features/common/local_validation/slice';
import CustomErrorMessage from 'features/errors/custom_error_message';
import ChangePasswordModal from 'features/shared/user_profile/change_password_modal';
import { DisconnectedUserWarning } from 'features/shared/user_profile/disconnected_user_warning';
import UpdateLoginModal from 'features/shared/user_profile/update_login_modal';
import { useHasAccess } from 'hooks/use_has_access';
import { ApiError } from 'lib/api/types';
import {
  getCurrentOrganizationUser,
  getOrganizationUserForId,
  getUserForId,
  getCurrentWorkspaceUser,
  getWorkspaceUserForOrganizationUserId,
} from 'redux/entities/selectors/user';

import AboutTable from './about_table';
import ToggleUserWorkspaceMembership from './toggle_user_workspace_membership';
import WorkspaceAttributesTable from './workspace_attributes_table';

import './index.scss';
interface MemberProfileProps {
  orgUserId: number;
}

const MemberProfile = ({ orgUserId }: MemberProfileProps) => {
  const dispatch = useDispatch();

  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const currentOrgUser = useSelector(getCurrentOrganizationUser);
  const currentWorkspaceUser = useSelector(getCurrentWorkspaceUser);

  const userId = orgUser?.user.id;
  const wsUser = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId));
  const user = useSelector((state) => orgUser && getUserForId(state, orgUser.user.id));
  const localValidationErrors = useSelector(getLocalValidationErrors);

  const [userUpdateError, setUserUpdateError] = useState<ApiError | undefined>(undefined);

  const hasAccess = useHasAccess(Permission.EDIT, OrganizationUserDao.id(orgUserId));

  useEffect(() => {
    return () => {
      if (orgUserId) {
        dispatch(clearLocalValidation());
      }
    };
  }, [dispatch, orgUserId]);

  useSetupOutpost(OutpostLocation.MemberProfile);

  if (!orgUser || !userId || !wsUser || !user || !currentOrgUser || !currentWorkspaceUser) {
    return null;
  }

  const isCurrentOrgUserAdmin = currentOrgUser.admin;
  const isCurrentWorkspaceUserManager = currentWorkspaceUser.manager;
  const isOrgUserAdmin = orgUser.admin;

  const isViewingCurrentSelf = currentWorkspaceUser?.id === wsUser.id;
  const hasEqualOrHigherRightsThanUser =
    (isCurrentWorkspaceUserManager && !isOrgUserAdmin) || (isCurrentOrgUserAdmin && isOrgUserAdmin);
  const isOrgAdminOrWsManager = isCurrentOrgUserAdmin || isCurrentWorkspaceUserManager;

  const isAvatarUploadAllowed = hasAccess && (user.userType === UserType.Member || user.userType === UserType.Resource);

  const localValidationErrorMessages = localValidationErrors
    ? Object.entries(localValidationErrors).map(([key, errorMessage]) => {
        const handleDismissError = () => dispatch(clearLocalValidationErrorForKey(key));
        return <CustomErrorMessage key={key} content={errorMessage} onDismiss={handleDismissError} />;
      })
    : null;

  return (
    <>
      <div className="user-profile__errors">
        {userUpdateError && <LpErrorMessage error={userUpdateError} onDismiss={() => setUserUpdateError(undefined)} />}
        {localValidationErrorMessages}
        {!!wsUser.disconnectedAt && <DisconnectedUserWarning userType={user.userType} />}
      </div>

      <AboutTable orgUserId={orgUserId} userId={userId} setUserUpdateError={setUserUpdateError} />

      <Segment className="user-profile__avatar">
        {isAvatarUploadAllowed ? (
          <EditableUserAvatar
            className="avatar-edit-menu"
            size="tiny"
            orgUserId={orgUserId}
            edit={isAvatarUploadAllowed}
          />
        ) : (
          <UserAvatar orgUserId={orgUser.id} size={AvatarSize.XL} marginRight={false} />
        )}

        {!isViewingCurrentSelf && isOrgAdminOrWsManager && hasEqualOrHigherRightsThanUser && (
          <ToggleUserWorkspaceMembership orgUserId={orgUser.id} />
        )}

        {isViewingCurrentSelf && <ChangeEmailPasswordSection userId={user.id} />}
      </Segment>

      <WorkspaceAttributesTable isViewingCurrentSelf={isViewingCurrentSelf} userId={userId} />
    </>
  );
};

export default MemberProfile;

export function ChangeEmailPasswordSection({ userId }: { userId: number }) {
  const user = useSelector((state) => getUserForId(state, userId));
  const authType = useCurrentUserAuthType();

  if (!authType || !user) {
    return null;
  }

  return authType === AuthType.LP_AUTH ? <AllowChangeEmailPassword user={user} /> : <DenyChangeEmailPassword />;
}

function DenyChangeEmailPassword() {
  return (
    <p>Your account is using SSO. Contact your SSO administrator for help with changing your email or password.</p>
  );
}

function AllowChangeEmailPassword({ user }: { user: User }) {
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false);
  const [updateEmailOpen, setUpdateEmailOpen] = useState(false);
  const togglePasswordModal = () => setUpdatePasswordOpen((prev) => !prev);
  const toggleEmailModal = () => setUpdateEmailOpen((prev) => !prev);

  return (
    <>
      <Button primary fluid onClick={togglePasswordModal} content={'Change Password'} />
      {updatePasswordOpen && <ChangePasswordModal onClose={togglePasswordModal} userId={user.id} />}

      <Button primary fluid onClick={toggleEmailModal} content={'Change Email'} />
      {updateEmailOpen && <UpdateLoginModal onClose={toggleEmailModal} user={user} />}
    </>
  );
}
