import { useSelector } from 'react-redux';

import Banner from 'features/common/banner';
import { SessionStorageKey, useSessionState } from 'hooks/use_session_state';
import { getContrast } from 'lib/color';
import { getActiveBanner } from 'redux/entities/selectors/banners';
import { yellow600 } from 'style/variables';

import './global_notification_banner.scss';

const GlobalNotificationBanner = () => {
  const currentBanner = useSelector(getActiveBanner);
  const [dissmissedBannerId, setDismissedBannerId] = useSessionState(-1, SessionStorageKey.DismissedBanner);

  if (!currentBanner || !currentBanner.content || currentBanner?.id <= dissmissedBannerId) {
    return <div className="lp-banner-placeholder"></div>;
  }

  const dissmissCurrentBanner = () => {
    setDismissedBannerId(currentBanner.id);
  };

  return (
    <Banner
      dismissable
      onDismiss={dissmissCurrentBanner}
      style={{ color: getContrast(yellow600) }}
      className="lp-global-notification-banner"
    >
      <span dangerouslySetInnerHTML={{ __html: currentBanner.content }} />
    </Banner>
  );
};

export default GlobalNotificationBanner;
