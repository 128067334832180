import { ReactNode } from 'react';

import { InsightsWidgetData, InsightsWidgetRange } from 'features/dashboards_v2/widget/widgets/insights/types';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { convertSecondsToHours } from 'lib/helpers';

export const getProperty = (widgetData: InsightsWidgetData) => (property: keyof InsightsWidgetData) => {
  return widgetData[property];
};

export const isWidgetDataPropertyNullOrZero =
  (widgetData: InsightsWidgetData) => (property: keyof InsightsWidgetData) => {
    return widgetData[property] === null || widgetData[property] === 0;
  };

export const isWidgetDataRangeZero =
  (widgetData: InsightsWidgetData) =>
  (property: keyof Pick<InsightsWidgetData, 'remainingWorkRange' | 'totalWorkRange'>) => {
    const range = widgetData[property];
    return range.lowSeconds === 0 && range.highSeconds === 0 && range.expectedSeconds === 0;
  };

export const isValueInsightAtRisk = (value: number | null | undefined) => {
  return !!value && value > 0;
};

const renderRange = (low: ReactNode, high: ReactNode, expected: ReactNode) => {
  return (
    <>
      {low} - {high} <span className="insights-widget__expected">({expected})</span>
    </>
  );
};

export const signForNumericDisplay = (input: number) => {
  return input > 0 ? '+' : '';
};

export const numericStringWithSign = (input: number) => {
  const sign = signForNumericDisplay(input);
  return `${sign}${input}`;
};

export const renderDeltaInDaysWithName = (name: string, deltaInDays: number | undefined) => {
  if (deltaInDays === undefined) {
    return name;
  }
  const deltaWithSign = numericStringWithSign(deltaInDays);
  return `${name} (${deltaWithSign}d)`;
};

export const formatDeltaWithName = (name: string, deltaInSeconds: number) => {
  const deltaAsHours = formatAsHours(convertSecondsToHours(deltaInSeconds));
  const sign = signForNumericDisplay(deltaInSeconds);

  return `${name} (${sign}${deltaAsHours})`;
};

export const formatRange = (range: InsightsWidgetRange) => {
  const lowHours = formatAsHours(convertSecondsToHours(range.lowSeconds ?? 0));
  const highHours = formatAsHours(convertSecondsToHours(range.highSeconds ?? 0));
  const expectedHours = formatAsHours(convertSecondsToHours(range.expectedSeconds ?? 0));
  return renderRange(lowHours, highHours, expectedHours);
};

export const formatSecondsAsHours = (seconds: number) => formatAsHours(convertSecondsToHours(seconds));
