import { Middleware } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import bugsnagClient from 'lib/bugsnag';
import { RootState } from 'redux/root_reducer';

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware({
  onError(error: Error) {
    bugsnagClient?.notify(error);
  },
});

export const createMiddleware = (): [Array<Middleware>, SagaMiddleware<{}>] => {
  const middleware: Array<Middleware> = [thunk as ThunkMiddleware<RootState>, sagaMiddleware];

  return [middleware, sagaMiddleware];
};
