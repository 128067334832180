import { PropertyChange } from 'daos/types';

const ChangeCardBooleanProperty = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const value = propertyChangeData.value as boolean;

  if (value !== false && !value) {
    return <></>;
  }

  return <>{value.toString()}</>;
};

export default ChangeCardBooleanProperty;
