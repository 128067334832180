import { useSelector } from 'react-redux';

import { planFamilyDisplayTextWithPlan, PlanFamily, SubscriptionStatus, TrialStatus } from 'daos/enums';
import { backend, thirdPartyUrls } from 'daos/urls';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import NewWindowLink from 'lib/display_helpers/url_links';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getCurrentOrganizationSubscription } from 'redux/entities/selectors/subscription';

export const ManageAccountPlanSection = () => {
  return (
    <div className="manage-account__plan">
      <div className="manage-account__plan_section">
        <div className="manage-account__plan_section-description">
          <p className="manage-account__plan_section-title">
            <PlanTitle />
          </p>
          <PlanContent />
          <PlanFooter />
        </div>
      </div>
    </div>
  );
};

const PlanTitle = () => {
  const organization = useSelector(getCurrentOrganization);
  const currentPlan = useSelector(getCurrentOrganizationPlan);
  if (!currentPlan || !organization) {
    return null;
  }

  if (organization.flags.subscriptionStatus === SubscriptionStatus.Cancelled) {
    return <>EXPIRED TRIAL</>;
  }

  const premiumFeaturesTrialText = organization?.flags?.premiumFeaturesTrial === TrialStatus.InProgress && (
    <>&nbsp;+&nbsp;Premium Features Trial</>
  );

  return (
    <>
      {planFamilyDisplayTextWithPlan[currentPlan.family]}
      {premiumFeaturesTrialText}
    </>
  );
};

const PlanContent = () => {
  const { formatLocalDate } = useLocalizedFormats();

  const organization = useSelector(getCurrentOrganization);
  const plan = useSelector(getCurrentOrganizationPlan);
  const subscription = useSelector(getCurrentOrganizationSubscription);
  const { nextBillingOn } = subscription ?? {};

  if (!plan || !organization) {
    return null;
  }

  const { billingPeriod, family } = plan;

  if (organization.flags.subscriptionStatus === SubscriptionStatus.Cancelled) {
    return (
      <p>
        <b>Want to try LP again? </b>
        <span>Upgrade or </span>
        <ProductAdvisorLink />
      </p>
    );
  } else if (family === PlanFamily.Other) {
    return (
      <p>Your workspace is on a custom plan where members can manage {organization.flags.maxProjectCount} projects.</p>
    );
  } else if (family === PlanFamily.Free) {
    return (
      <p>
        <strong>Feeling like it&apos;s time for more?</strong> Upgrade to a paid plan to get more planning power.{' '}
        <ProductAdvisorLink />
      </p>
    );
  } else if (family === PlanFamily.UltimateTrial) {
    return (
      <p>
        <b>Ready to move forward? </b>
        <span>Upgrade or </span>
        <ProductAdvisorLink />
      </p>
    );
  } else {
    return (
      <p>
        Your subscription is on {billingPeriod} billing
        {nextBillingOn && <> and your next renewal is {formatLocalDate(nextBillingOn)}</>}.
      </p>
    );
  }
};

const PlanFooter = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const { family } = useSelector(getCurrentOrganizationPlan) ?? {};
  const showBillingLinks = family !== PlanFamily.Free && family !== PlanFamily.UltimateTrial;

  if (showBillingLinks) {
    return (
      <>
        <NewWindowLink to={backend.organizationBillingPortal.url({ organizationId })}>
          Visit Billing Portal
        </NewWindowLink>
        <span> | </span>
        <NewWindowLink to={thirdPartyUrls.billingFAQ}>Frequently Asked Billing Questions</NewWindowLink>
        <span> | </span>
        <ProductAdvisorLink />
      </>
    );
  } else {
    return null;
  }
};

const ProductAdvisorLink = () => (
  <NewWindowLink to={thirdPartyUrls.productAdvisor}>Contact a Product Advisor</NewWindowLink>
);
