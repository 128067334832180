import { LpIcon, userPenSolid } from 'features/common/lp_icon';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { Title } from 'features/dashboards_v2/widget_settings/title';

export const ChangesWidgetSettings = ({ widgetId }: { widgetId: number }) => {
  const header = (
    <>
      <LpIcon icon={userPenSolid} /> Changes
    </>
  );

  const content = (
    <>
      <Title widgetId={widgetId} />
      <LocationSetting />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
