import { find, groupBy, keyBy, reduce, filter, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { UserType } from 'daos/enums';
import { WorkspaceUser, OrganizationUser } from 'daos/model_types';
import { DateRangeType } from 'daos/types';
import { getCurrentOrganizationId, getCurrentUserId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { compareStringsAscendingOrder } from 'lib/helpers/comparison_helpers';
import { readonlyArray, groupRecordBy, ReadonlyRecord } from 'lib/readonly_record';
import { getCurrentWorkspaceGroupsSortedByName } from 'redux/entities/selectors/group';
import { getWorkspacesById } from 'redux/entities/selectors/workspace';
import { RootState } from 'redux/root_reducer';

import {
  createCacheByIdConfig,
  getSecondParameter,
  getNumberArgument,
  createCacheByFirstTwoParametersConfig,
} from './shared';

export const compareUserNameData = (a: UserNameData, b: UserNameData): number => {
  if (a.lastName === b.lastName) {
    return compareStringsAscendingOrder(a.firstName, b.firstName);
  } else {
    return compareStringsAscendingOrder(a.lastName, b.lastName);
  }
};

export const getUserForId = ({ entities }: RootState, id: number) => entities.users[id];

export const getUsersById = (state: RootState) => state.entities.users;

export const getOrganizationUsersById = (state: RootState) => state.entities.organizationUsers;

export const getOrganizationUserForId = (state: RootState, id: number | string) => getOrganizationUsersById(state)[id];

export const getWorkspaceUsersById = ({ entities }: RootState) => entities.workspaceUsers;
export const getWorkspaceUserById = ({ entities }: RootState, id: number) => entities.workspaceUsers[id];

export const getWorkspaceUsersByUserId = createSelector(getWorkspaceUsersById, (workspaceUsers) =>
  groupRecordBy(workspaceUsers, (wsUser) => {
    return wsUser.user.id;
  })
);

const emptyWsUserArray = readonlyArray<WorkspaceUser>([]);
const getCurrentOrganizationWorkspaceUsersForUserId = createCachedSelector(
  getWorkspaceUsersByUserId,
  getCurrentOrganizationId,
  getNumberArgument,
  (workspaceUsersByUserId, organizationId, userId) => {
    const workspaceUsers = workspaceUsersByUserId[userId];

    if (!workspaceUsers) {
      return emptyWsUserArray;
    }

    return readonlyArray(workspaceUsers.filter((wsUser) => wsUser.organization.id === organizationId));
  }
)(createCacheByIdConfig());

export const getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId = createCachedSelector(
  getCurrentOrganizationWorkspaceUsersForUserId,
  (workspaceUsers) =>
    workspaceUsers.reduce((acc: { [workspaceId: number]: WorkspaceUser }, workspaceUser) => {
      acc[workspaceUser.workspace.id] = workspaceUser;
      return acc;
    }, {})
)(createCacheByIdConfig());

export const getCurrentWorkspaceUser = createSelector(
  getWorkspaceUsersById,
  (state: RootState) => ({
    currentSpaceId: getCurrentWorkspaceId(state),
    currentUserId: getCurrentUserId(state),
  }),
  (entities, { currentUserId, currentSpaceId }) => {
    if (!currentUserId || !currentSpaceId) {
      return undefined;
    }
    return find(entities, (e) => e.user.id === currentUserId && e.workspace.id === currentSpaceId);
  }
);

export const getCurrentWorkspaceUserId = createSelector(
  getCurrentWorkspaceUser,
  (currentWorkspaceUser) => currentWorkspaceUser?.id ?? 0
);

export const getIsGuestCurrentWorkspaceUser = createSelector(
  getCurrentWorkspaceUser,
  (currentWorkspaceUser) => !!currentWorkspaceUser?.guestUser
);

const getWorkspaceUsersIndexedBySpaceId = createSelector(getWorkspaceUsersById, (workspaceUsers) =>
  groupBy(workspaceUsers, (v) => v.workspace.id)
);

const getWorkspaceUsersForSpaceId = createCachedSelector(
  getWorkspaceUsersIndexedBySpaceId,
  getNumberArgument,
  (wsUsers, wsId) => wsUsers[wsId]
)(createCacheByIdConfig());

const getWorkspaceUsersForCurrentWorkspace = createCachedSelector(
  getWorkspaceUsersIndexedBySpaceId,
  getCurrentWorkspaceId,
  (wsUsers, wsId) => wsUsers[wsId]
)(createCacheByIdConfig());

export interface UserNameData {
  userType: UserType;
  username: string;
  wsUserId: number;
  firstName: string;
  lastName: string;
  isWsUserDisconnected: boolean;
  orgUserId?: number;
}

export const getTimeReviewCurrentWorkspacePaidMembersAndResources = createSelector(
  (state: RootState) => state,
  getWorkspaceUsersForCurrentWorkspace,
  (state, currentWorkspaceUsers) => {
    if (currentWorkspaceUsers) {
      return currentWorkspaceUsers.reduce((acc: Array<UserNameData>, wsUSer) => {
        const user = getUserForId(state, wsUSer.user.id);
        const orgUser = getOrganizationUserInCurrentOrgForUserId(state, wsUSer.user.id);

        const isPlaceholder = user?.userType === UserType.Placeholder;
        const isGuest = wsUSer.guestUser;

        if (orgUser && user && !isPlaceholder && !isGuest) {
          acc.push({
            userType: user.userType,
            username: orgUser.username,
            wsUserId: wsUSer.id,
            firstName: user.firstName,
            lastName: user.lastName,
            isWsUserDisconnected: !!wsUSer.disconnectedAt,
          });
        }

        return acc;
      }, []);
    }

    return [];
  }
);

export const getWorkspaceUsersForWorkspaceIdIndexedByUserId = createCachedSelector(
  getWorkspaceUsersForSpaceId,
  (wsUsers) => keyBy(wsUsers, (e) => e.user.id)
)(createCacheByIdConfig());

export const getCurrentWorkspaceUsersIndexedByUserId = createCachedSelector(
  (state: RootState) => state,
  getCurrentWorkspaceId,
  (state, workspaceId) => getWorkspaceUsersForWorkspaceIdIndexedByUserId(state, workspaceId)
)(createCacheByIdConfig());

export const getWorkspaceUserForSpaceIdAndUserId = createCachedSelector(
  getWorkspaceUsersForWorkspaceIdIndexedByUserId,
  getSecondParameter,
  (usersById, userId) => (usersById ? usersById[userId] : undefined)
)(createCacheByFirstTwoParametersConfig());

interface CurrentOrgUserToWsUserMapping {
  [orgUserId: number]: number;
}
const getOrganizationUsersByOrgId = createSelector(getOrganizationUsersById, (orgUsersById) =>
  groupRecordBy(orgUsersById, (e) => e.organization.id)
);

const getCurrentOrganizationUsers = createSelector(
  getOrganizationUsersByOrgId,
  getCurrentOrganizationId,
  (orgUsersByOrgId, id) => orgUsersByOrgId[id] ?? emptyOrgUserArray
);

export const getCurrentOrganizationUsersByUserId = createSelector(
  getCurrentOrganizationUsers,
  (organizationUsers) => keyBy(organizationUsers, (e) => e.user.id) as ReadonlyRecord<number, OrganizationUser>
);

export const getCurrentOrganizationUsersForUserIds = createSelector(
  getCurrentOrganizationUsersByUserId,
  (_: RootState, userIds: ReadonlyArray<number>) => userIds,
  (orgUsersByUserId, userIds) => {
    const orgUsers = userIds.reduce((acc: Array<OrganizationUser>, userId) => {
      const orgUser = orgUsersByUserId[userId];
      if (orgUser) {
        acc.push(orgUser);
      }
      return acc;
    }, []);

    return readonlyArray(orgUsers);
  }
);

export const getCurrentOrgUserToWsUserMapping = createSelector(
  getCurrentOrganizationUsersByUserId,
  (state: RootState) => getWorkspaceUsersForWorkspaceIdIndexedByUserId(state, getCurrentWorkspaceId(state)),
  (orgUsersById, wsUsersById) =>
    reduce(
      orgUsersById,
      (mapping: CurrentOrgUserToWsUserMapping, orgUser, userId) => {
        const userIdAsNumber = Number(userId);
        const wsUserId = wsUsersById[userIdAsNumber]?.id;

        if (wsUserId) {
          mapping[orgUser.id] = wsUserId;
        }
        return mapping;
      },
      {}
    )
);

export const getWorkspaceUserForOrganizationUserId = createSelector(
  (state: RootState) => state,
  getCurrentOrgUserToWsUserMapping,
  getNumberArgument,
  (state, wsUserIdsByOrgUserId, orgUserId) => {
    const workspaceUserId = wsUserIdsByOrgUserId[orgUserId] ?? 0;

    return getWorkspaceUserById(state, workspaceUserId);
  }
);

export const getIsWorkspaceUserDisconnectedForOrgUserId = createCachedSelector(
  getWorkspaceUserForOrganizationUserId,
  (workspaceUser) => !!workspaceUser?.disconnectedAt
)(createCacheByIdConfig());

const emptyOrgUserArray = readonlyArray<OrganizationUser>([]);

export const getConnectedCurrentOrganizationUsers = createSelector(getCurrentOrganizationUsers, (orgUsers) =>
  readonlyArray(orgUsers.filter((orgUser) => orgUser.disconnectedAt === null))
);

export const getCurrentOrganizationUser = createSelector(
  getCurrentOrganizationUsersByUserId,
  getCurrentUserId,
  (organizationUsersByUserId, currentUserId) => {
    return organizationUsersByUserId[currentUserId];
  }
);

export const getCurrentOrganizationUserId = createSelector(getCurrentOrganizationUser, (currentOrganizationUser) => {
  return currentOrganizationUser?.id ?? 0;
});

const getCurrentOrganizationUsersSortedByUsername = createSelector(getCurrentOrganizationUsers, (users) =>
  readonlyArray(sortBy(users, (e) => (e.username || '').toLowerCase()))
);

export const getCurrentOrganizationUserForWorkspaceUser = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, workspaceUser: WorkspaceUser | undefined) => workspaceUser,
  (state, workspaceUser) => {
    const userId = workspaceUser?.user.id;
    return userId ? getOrganizationUserInCurrentOrgForUserId(state, userId) : undefined;
  }
)((_, workspaceUser) => (workspaceUser ? workspaceUser.id : ''));

export const getOrganizationUserInCurrentOrgForUserId = createSelector(
  getCurrentOrganizationUsersByUserId,
  getNumberArgument,
  (orgUsersByUserId, id) => orgUsersByUserId[id]
);

export const getCurrentWsUserToOrgUserMapping = createSelector(
  getCurrentOrganizationUsersByUserId,
  (state: RootState) => getWorkspaceUsersForWorkspaceIdIndexedByUserId(state, getCurrentWorkspaceId(state)),
  (orgUsersById, wsUsersById) => {
    return reduce(
      wsUsersById,
      (mapping: Record<number, number>, wsUser, userId) => {
        const userIdNumber = parseInt(userId, 10);
        const orgUser = orgUsersById[userIdNumber];
        if (orgUser) {
          mapping[wsUser.id] = orgUser.id;
        }
        return mapping;
      },
      {}
    ) as ReadonlyRecord<number, number>;
  }
);

const getOrganizationUsersForCurrentWorkspaceSortedByUsername = createSelector(
  getCurrentOrganizationUsersSortedByUsername,
  getCurrentOrgUserToWsUserMapping,
  (orgUsers, mapping) => readonlyArray(filter(orgUsers, (orgUser) => !!mapping[orgUser.id]))
);

/**
 * @description Does NOT include guests, placeholders, or resources
 */
export const getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername = createSelector(
  getOrganizationUsersForCurrentWorkspaceSortedByUsername,
  (state: RootState) => state,
  (orgUsers, state) => {
    const nonGuestOrgUsersInCurrentWorkspace = orgUsers.reduce((acc: Array<OrganizationUser>, orgUser) => {
      const wsUser = getWorkspaceUserForOrganizationUserId(state, orgUser.id);
      if (wsUser && !wsUser.guestUser) {
        acc.push(orgUser);
      }

      return acc;
    }, []);

    return readonlyArray(nonGuestOrgUsersInCurrentWorkspace);
  }
);

export const getCurrentWorkspaceUserDisconnectedByOrgUserIdMap = createSelector(
  getOrganizationUsersForCurrentWorkspaceSortedByUsername,
  (state: RootState) => state,
  (orgUsers, state) => {
    const nonGuestOrgUsersInCurrentWorkspace = orgUsers.reduce((acc: Record<number, boolean>, orgUser) => {
      const wsUser = getWorkspaceUserForOrganizationUserId(state, orgUser.id);
      const isDisconnectedFromWorkspace = !!wsUser?.disconnectedAt;
      const isGuest = wsUser?.guestUser;

      if (wsUser && !isGuest) {
        acc[orgUser.id] = isDisconnectedFromWorkspace;
      }

      return acc;
    }, {});

    return nonGuestOrgUsersInCurrentWorkspace;
  }
);

/**
 * @description Includes workspace guests, but not resources or placeholders
 */
export const getOrganizationMemberUsersForCurrentWorkspaceSortedByUsername = createSelector(
  getOrganizationUsersForCurrentWorkspaceSortedByUsername,
  getUsersById,
  (orgUsersForCurrentWorkspaceSortedByUsername, usersById) => {
    const memberUsersForCurrentWorkspaceSortedByUsername = orgUsersForCurrentWorkspaceSortedByUsername.filter(
      (orgUser) => usersById[orgUser.user.id]?.userType === UserType.Member
    );
    return readonlyArray(memberUsersForCurrentWorkspaceSortedByUsername);
  }
);

/**
 * @description Includes guests, placeholders, and resources, but does NOT include disconnected wsUsers
 */
const getActiveOrganizationUsersInCurrentWorkspaceSortedByUsername = createSelector(
  getOrganizationUsersForCurrentWorkspaceSortedByUsername,
  (orgUsers) => readonlyArray(orgUsers.filter((orgUser) => !orgUser.disconnectedAt))
);

export const getOrganizationUserIdForWorkspaceUserId = createSelector(
  getCurrentWsUserToOrgUserMapping,
  getNumberArgument,
  (wsUserMap, orgUserId) => {
    return wsUserMap[orgUserId];
  }
);

/**
 * @description Includes placeholders and resources, but does NOT include disconnected or guest wsUsers
 */
export const getNonGuestOrganizationUsersActiveInCurrentWorkspace = createSelector(
  getActiveOrganizationUsersInCurrentWorkspaceSortedByUsername,
  (state: RootState) => state,
  (activeOrgUsers, state) => {
    const connectedWsOrgUsers = activeOrgUsers.reduce((acc: Array<OrganizationUser>, orgUser) => {
      const wsUser = getWorkspaceUserForOrganizationUserId(state, orgUser.id);
      const isDisconnectedFromWorkspace = !!wsUser?.disconnectedAt;
      const isGuest = wsUser?.guestUser;

      if (wsUser && !isDisconnectedFromWorkspace && !isGuest) {
        acc.push(orgUser);
      }

      return acc;
    }, []);

    return readonlyArray(connectedWsOrgUsers);
  }
);

/**
 * @returns `{ activeOrgUsers, groups }`
 * `activeOrgUsers` only include non-guest, connected orgUsers for the current workspace
 */
export const getCurrentWorkspaceNonGuestActiveOrgUsersAndGroups = createSelector(
  getNonGuestOrganizationUsersActiveInCurrentWorkspace,
  getCurrentWorkspaceGroupsSortedByName,
  getCurrentWorkspaceUserDisconnectedByOrgUserIdMap,
  (nonGuestConnectedOrgUsers, groups) => {
    const activeOrgUsers = nonGuestConnectedOrgUsers.filter(
      (orgUser: OrganizationUser) => orgUser.hasActiveWsNonGuestUser
    );
    return { activeOrgUsers, groups };
  }
);

export const getCurrentWorkspaceNonGuestActiveAndDisconnectedOrgUsersAndGroups = createSelector(
  getNonGuestOrganizationUsersForCurrentWorkspaceSortedByUsername,
  getCurrentWorkspaceGroupsSortedByName,
  (nonGuestConnectedOrgUsers, groups) => {
    return { activeOrgUsers: nonGuestConnectedOrgUsers, groups };
  }
);

const getWorkspaceUsersForUserId = createSelector(
  (state: RootState) => state,
  getNumberArgument,
  getWorkspacesById,
  (state, userId, workspacesById) => {
    const workspaceIds = Object.keys(workspacesById);
    const workspaceUsersByWorkspaceId = getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId(state, userId);

    return workspaceIds.reduce((acc: Array<WorkspaceUser>, workspaceId) => {
      const workspaceUserForWorkspace = workspaceUsersByWorkspaceId[Number(workspaceId)];
      if (workspaceUserForWorkspace) {
        acc.push(workspaceUserForWorkspace);
      }
      return acc;
    }, []);
  }
);

export const getIsOrgUserHasAnyMemberWorkspaces = createSelector(
  getWorkspaceUsersForUserId,
  (workspaceUsersForWorkspace) =>
    !!workspaceUsersForWorkspace.filter((workspaceUserForWorkspace) => !workspaceUserForWorkspace.guestUser).length
);

export const getIsOrgUserHasAnyGuestWorkspaces = createSelector(
  getWorkspaceUsersForUserId,
  (workspaceUsersForWorkspace) =>
    !!workspaceUsersForWorkspace.filter((workspaceUserForWorkspace) => !!workspaceUserForWorkspace.guestUser).length
);

export const getCurrentWorkspacePaidMembersAndResourcesAndPlaceholders = createSelector(
  (state: RootState) => state,
  getWorkspaceUsersForCurrentWorkspace,
  (state, currentWorkspaceUsers) => {
    if (currentWorkspaceUsers) {
      return currentWorkspaceUsers.reduce((acc: Array<UserNameData>, wsUSer) => {
        const user = getUserForId(state, wsUSer.user.id);
        const orgUser = getOrganizationUserInCurrentOrgForUserId(state, wsUSer.user.id);
        const isGuest = wsUSer.guestUser;
        const isDisconnected = !!wsUSer.disconnectedAt;

        if (orgUser && user && !isDisconnected && !isGuest) {
          acc.push({
            userType: user.userType,
            username: orgUser.username,
            wsUserId: wsUSer.id,
            orgUserId: orgUser.id,
            firstName: user.firstName,
            lastName: user.lastName,
            isWsUserDisconnected: false,
          });
        }

        return acc;
      }, []);
    }

    return [];
  }
);
export const getThreeWeekDateRangesForCurrentOrganizationUser = createSelector(
  getCurrentOrganizationUser,
  (orgUser) => {
    if (orgUser) {
      return orgUser.dateRanges.find((dateRange) => dateRange.dateRangeType === DateRangeType.ThreeWeekWindow);
    }
    return null;
  }
);
