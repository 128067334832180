import { customColumnDefinitions } from 'containers/shared/custom_column';
import { StandardColumns } from 'containers/shared/custom_column/enum';
import { SourceSystem } from 'daos/external_integration_enums';
import { WidgetConfigColumn } from 'daos/widget_column';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';

import './custom_field_name.scss';

export const CustomFieldNameBySourceType = ({
  name,
  sourceSystem,
}: {
  name: string;
  sourceSystem: SourceSystem | null;
}) => {
  if (sourceSystem === SourceSystem.JIRA) {
    const shortName = name.replace('(Jira)', '');
    return (
      <div className="custom-field-name">
        <LpSvg icon={LpSvgs.JIRA} size={12} /> <span>{`${shortName}`}</span>
      </div>
    );
  } else {
    return <span>{name}</span>;
  }
};

export const CustomFieldNameForGuests = ({
  configColumn,
  dateRangeDisplay,
}: {
  configColumn: WidgetConfigColumn;
  dateRangeDisplay: string;
}) => {
  const customFieldName = configColumn.customFieldName;
  const customFieldSourceSystem = configColumn.customFieldSourceSystem ?? null;

  const displayText =
    typeof customFieldName === 'string' ? (
      <CustomFieldNameBySourceType name={customFieldName} sourceSystem={customFieldSourceSystem} />
    ) : (
      <>{customFieldName}</>
    );

  if (configColumn.customFieldId) {
    return <>{customFieldName ? displayText : 'Custom Field'}</>;
  }

  if (configColumn.column === StandardColumns.ScheduleBar) {
    return <>{dateRangeDisplay}</>;
  }

  return <>{customColumnDefinitions[configColumn.column]?.displayText ?? configColumn.column}</>;
};
