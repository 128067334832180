import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';

export const itemGridConfigColumn = ({
  customFieldId,
  columnKey,
}: {
  customFieldId: number | undefined;
  columnKey: string;
}): Columns => {
  return customFieldId ? StandardColumns.CustomField : (columnKey as StandardColumns);
};
