import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Breadcrumb } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { usePackageCollectionUrlForPackageStatus } from 'hooks/use_package_collection_url_for_package_status';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { frontend } from 'lib/urls';
import { getItemForId, getPackageStatusForItemId, getAncestryForItemId } from 'redux/entities/selectors/item';

interface ItemPanelBreadcrumbProps {
  isClickable: boolean;
  itemId: number;
}

const PackageBreadcrumbSections = ({ itemId, isClickable }: ItemPanelBreadcrumbProps) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const packageStatus = useSelector((state) => getPackageStatusForItemId(state, itemId));

  const packageCollectionUrl = usePackageCollectionUrlForPackageStatus(packageStatus);

  if (!item) {
    return null;
  }

  const breadcrumbLinkProps = isClickable ? { as: LpLink } : {};

  return (
    <>
      <Breadcrumb.Section {...breadcrumbLinkProps} to={packageCollectionUrl} content={HORIZONTAL_ELLIPSIS} />
      <Breadcrumb.Divider />
      <Breadcrumb.Section
        {...breadcrumbLinkProps}
        to={frontend.singlePackage.url({ workspaceId, organizationId, packageId: itemId })}
        content={item.name}
      />
    </>
  );
};

const ProjectFolderTaskBreadcrumbSections = ({ itemId, isClickable }: ItemPanelBreadcrumbProps) => {
  const history = useHistory();

  const ancestry = useSelector((state) => getAncestryForItemId(state, itemId));
  const item = useSelector((state) => getItemForId(state, itemId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  if (!item) {
    return null;
  }

  const breadcrumbLinkProps = isClickable ? { as: LpLink } : {};

  const breadcrumbSection = (ancestor: Item) => {
    switch (ancestor.itemType) {
      case ItemType.PACKAGES:
        return (
          <Breadcrumb.Section
            {...breadcrumbLinkProps}
            to={frontend.singlePackage.url({ organizationId, workspaceId, packageId: ancestor.id })}
            content={HORIZONTAL_ELLIPSIS}
          />
        );
      case ItemType.PROJECTS:
      case ItemType.FOLDERS:
        return (
          <Breadcrumb.Section
            {...breadcrumbLinkProps}
            to={frontend.projectProject.url({ organizationId, workspaceId, itemId: ancestor.id })}
            content={ancestor.name}
          />
        );
      default:
        return null;
    }
  };

  const handleItemNameClick = () => {
    if (isClickable) {
      const tabName = item.itemType === ItemType.TASKS ? TabNames.Assignments : TabNames.Planning;

      history.push(`#panelId=${itemId}&panelSection=${tabName}Tab`);
    }
  };

  return (
    <>
      {ancestry.map((ancestor) => (
        <Breadcrumb.Section key={ancestor.id}>
          {ancestor.itemType !== ItemType.WORKSPACE_ROOTS && ancestor.id !== item.id && (
            <>
              {breadcrumbSection(ancestor)}
              <Breadcrumb.Divider />
            </>
          )}
        </Breadcrumb.Section>
      ))}

      <Breadcrumb.Section onClick={handleItemNameClick} content={item.name} />
    </>
  );
};

const ItemPanelBreadcrumb = ({ itemId, isClickable }: ItemPanelBreadcrumbProps) => {
  const item = useSelector((state) => getItemForId(state, itemId));

  if (!item) {
    return null;
  }

  const getBreadcrumb = () => {
    const itemType = item.itemType;

    switch (itemType) {
      case ItemType.WORKSPACE_ROOTS:
        return null;
      case ItemType.PACKAGES:
        return <PackageBreadcrumbSections itemId={itemId} isClickable={isClickable} />;
      case ItemType.PROJECTS:
      case ItemType.FOLDERS:
      case ItemType.TASKS:
      default:
        return <ProjectFolderTaskBreadcrumbSections itemId={itemId} isClickable={isClickable} />;
    }
  };

  return (
    <Breadcrumb size="big" className="lp-item-panel__breadcrumb">
      {getBreadcrumb()}
    </Breadcrumb>
  );
};

export default ItemPanelBreadcrumb;
