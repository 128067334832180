/**
 * Important: The strings here reference ongoing GTM campaigns. Do not
 * change these values without checking in with the marketing team first.
 * */
export enum AnalyticsRegistrationEvent {
  RegistrationBegan = 'begin',
  RegistrationCompletedUserData = 'completed_user_data',
  RegistrationCompleted = 'registration_complete',
  RegistrationFailed = 'registration_error',
}

export const logGoogleAnalyticsRegistrationEvent = (action: AnalyticsRegistrationEvent) =>
  window.gtag?.('event', `trial_signup_${action}`, {
    event_category: 'sign_up',
  });
