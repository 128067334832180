import { useFormikContext } from 'formik';
import { ReactNode, SyntheticEvent } from 'react';
import { DropdownProps, Form, FormDropdownProps } from 'semantic-ui-react';

import { formErrorLabel } from 'features/common/forms/form_field_helpers/form_error_label';

type LpFormikDropdownProps = {
  id?: string;
  name: string;
  restFieldErrorOnChange?: boolean;
} & Omit<FormDropdownProps, 'checked' | 'id' | 'name' | 'multiple' | 'onBlur' | 'onChange' | 'value'>;

export const LpFormikDropdown = ({
  id,
  name,
  restFieldErrorOnChange = false,
  hideErrorMessage = false,
  showError = false,
  ...dropdownProps
}: LpFormikDropdownProps) => {
  const { getFieldProps, setFieldValue, getFieldMeta, setFieldError } = useFormikContext();
  const { value } = getFieldProps(name);
  const fieldMeta = getFieldMeta<ReactNode>(name);

  const handleChange = (_e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    if (restFieldErrorOnChange) {
      setFieldError(name, undefined);
    }
    setFieldValue(name, value);
  };

  const handleBlur = (_e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    setFieldValue(name, value);
  };

  return (
    <Form.Dropdown
      id={id ?? name}
      name={name}
      {...dropdownProps}
      error={showError && formErrorLabel({ fieldMeta, hideErrorMessage })}
      value={value as boolean | number | string | Array<boolean | number | string>}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
