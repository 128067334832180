import { FormatterProps, SelectColumn } from 'react-data-grid';

import { UserType } from 'daos/enums';
import { SelectColumnFormatter } from 'features/common/data_grid/formatters/select_column';
import { getPeopleRowCheckboxDisabled } from 'features/common/data_grid/safe_widgets/helpers';
import { EntityRow } from 'features/common/data_grid/types';
import SelectionDropdown from 'features/common/inputs/dropdowns/selection_dropdown';
import {
  SelectionDropdownLocation,
  SelectionDropdownOptions,
} from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { UserTypeSelectionDropdown } from 'features/common/inputs/dropdowns/selection_dropdown/user_selection_dropdown';
import { DeprecatedGridSummaryRow } from 'features/dashboards_v2/widget/widgets/list/types';
import { GridSummaryRow, DeprecatedPeopleRow, PeopleRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { TEXT_ALIGN_LEFT } from 'lib/constants';

const SELECT_COLUMN_HEADER_CELL_CLASSNAME = `lp-data-grid__select-header lp-data-grid__select-header--select-all ${TEXT_ALIGN_LEFT}`;
const SELECT_COLUMN_CELL_CLASSNAME = 'lp-data-grid__select';

export const selectAllColumn = (
  onSelectAll: (selectionOption: SelectionDropdownOptions) => void,
  location: SelectionDropdownLocation
) => ({
  ...SelectColumn,
  headerCellClass: SELECT_COLUMN_HEADER_CELL_CLASSNAME,
  cellClass: SELECT_COLUMN_CELL_CLASSNAME,
  headerRenderer: () => <SelectionDropdown location={location} onSelectAllClick={onSelectAll} tabIndex={-1} />,
  formatter: SelectColumnFormatter,
  summaryFormatter: () => null,
});

export const deprecatedSelectPeopleRowByUserTypeColumn = (
  onSelect: (selectionOption: SelectionDropdownOptions) => void,
  hasAccess: boolean,
  selectedUserType?: UserType
) => {
  return {
    ...SelectColumn,
    headerCellClass: SELECT_COLUMN_HEADER_CELL_CLASSNAME,
    cellClass: SELECT_COLUMN_CELL_CLASSNAME,
    headerRenderer: () => <UserTypeSelectionDropdown onSelectClick={onSelect} tabIndex={-1} />,
    formatter: (
      props: FormatterProps<EntityRow<DeprecatedPeopleRow, DeprecatedGridSummaryRow>, DeprecatedGridSummaryRow>
    ) => {
      const disableCheckbox = getPeopleRowCheckboxDisabled({
        rowUserType: props.row.user.userType,
        selectedUserType,
        hasAccess,
      });

      return <SelectColumnFormatter {...props} disabled={disableCheckbox} />;
    },
  };
};

export const selectPeopleRowByUserTypeColumn = (
  onSelect: (selectionOption: SelectionDropdownOptions) => void,
  hasAccess: boolean,
  selectedUserType?: UserType
) => {
  return {
    ...deprecatedSelectPeopleRowByUserTypeColumn(onSelect, hasAccess, selectedUserType),
    formatter: (props: FormatterProps<EntityRow<PeopleRow, GridSummaryRow>, GridSummaryRow>) => {
      const disableCheckbox = getPeopleRowCheckboxDisabled({
        rowUserType: props.row.user.userType,
        selectedUserType,
        hasAccess,
      });

      return <SelectColumnFormatter {...props} disabled={disableCheckbox} />;
    },
  };
};
