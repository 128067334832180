import { SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, Radio, RadioProps } from 'semantic-ui-react';

import ItemIcon from 'containers/shared/item_icon';
import { PackageStatus } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { PackageStatusDisplay } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import LpLoaderAnimatedGrid from 'features/common/loaders/lp_item_panel_loader';
import { boxAltSolid, layerGroupSolid, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { getItemForId } from 'redux/entities/selectors/item';

import './index.scss';

const baseClassName = 'create-package-in-collection-modal';

export const CreatePackageInCollectionModal = ({
  handleCloseModal,
  packageId,
}: {
  handleCloseModal: () => void;
  packageId: number;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const pkg = useSelector((state) => getItemForId(state, packageId));

  const [selectedCollection, setSelectedCollection] = useState<PackageStatus | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const isAddButtonDisabled = isLoading || !selectedCollection;

  const handleRadioButtonSelect = (_: SyntheticEvent, { value }: RadioProps) =>
    setSelectedCollection(value as PackageStatus);

  const handleTemplateCreatedEvent = () => {
    if (isLoading) {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  useEvents({
    event: Events.ASYNC_JOB,
    callback: handleTemplateCreatedEvent,
    scope: EventScopes.None,
  });

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(
      ItemDao.duplicateBulk(
        {
          organizationId,
          workspaceId,
        },
        [packageId],
        undefined,
        selectedCollection
      )
    );
  };

  return (
    <LpModal
      className={baseClassName}
      onClose={handleCloseModal}
      size="tiny"
      header={
        <>
          <span className={`${baseClassName}__heder-title`}>Creating Package:</span>
          <ItemIcon size="1x" itemId={packageId} />
          <span className={`${baseClassName}__header-name`}>{pkg?.name}</span>
        </>
      }
      content={
        isLoading ? (
          <div className={`${baseClassName}__loader`}>
            <LpLoaderAnimatedGrid label="" />
          </div>
        ) : (
          <List className={`${baseClassName}__list`}>
            <List.Item>
              <div className={`${baseClassName}__list-header`}>Collection</div>
              <List.List>
                <List.Item className={`${baseClassName}__collection`}>
                  <Radio
                    onChange={handleRadioButtonSelect}
                    data-e2e-test-id="create-package-scheduled"
                    value={PackageStatus.SCHEDULED}
                    checked={selectedCollection === PackageStatus.SCHEDULED}
                    className={`${baseClassName}__collection-checkbox`}
                  />
                  <LpIcon icon={boxAltSolid} className={`${baseClassName}__collection-icon`} />
                  <span>{PackageStatusDisplay.Scheduled}</span>
                </List.Item>
                <List.Item className={`${baseClassName}__collection`}>
                  <Radio
                    onChange={handleRadioButtonSelect}
                    data-e2e-test-id="create-package-backlog"
                    value={PackageStatus.BACKLOG}
                    checked={selectedCollection === PackageStatus.BACKLOG}
                    className={`${baseClassName}__collection-checkbox`}
                  />
                  <LpIcon icon={layerGroupSolid} className={`${baseClassName}__collection-icon`} />
                  <span>{PackageStatusDisplay.Backlog}</span>
                </List.Item>
              </List.List>
            </List.Item>
          </List>
        )
      }
      actions={
        <>
          <Button onClick={handleCloseModal} content="Close" data-e2e-test-id="create-package-modal-close" />
          <Button
            disabled={isAddButtonDisabled}
            onClick={handleSubmit}
            primary
            content="Add Package"
            data-e2e-test-id="create-package-modal-submit"
          />
        </>
      }
    />
  );
};
