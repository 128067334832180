import { Table } from 'semantic-ui-react';

import { ListWidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { getFilteredWidgetColumnsOnBenchmarkEstimate } from 'features/common/data_grid/helpers';
import { DeprecatedItemListWidgetData } from 'features/dashboards_v2/widget/widgets/list/types';
import {
  DeprecatedPeopleListWidgetData,
  ItemListWidgetData,
  PeopleListWidgetData,
} from 'features/dashboards_v2/widget/widgets/reports/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

import { ListTableHeader } from './header_row';
import {
  DeprecatedItemListTableRow,
  DeprecatedPeopleListTableRow,
  DeprecatedSummaryTableRow,
  ItemListTableRow,
  PeopleListTableRow,
  SummaryTableRow,
} from './row';

const DeprecatedItemListBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const data = widget.data as DeprecatedItemListWidgetData;

  return (
    <Table.Body>
      {data.rowData.map((itemRow) => (
        <DeprecatedItemListTableRow
          key={itemRow.itemId}
          itemRow={itemRow}
          columnDefinitions={columnDefinitions}
          widget={widget}
        />
      ))}

      {widget.config.showTotals && data.totalRowData && (
        <DeprecatedSummaryTableRow summaryData={data.totalRowData} columnDefinitions={columnDefinitions} />
      )}
    </Table.Body>
  );
};

const ItemListBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const data = widget.data as ItemListWidgetData;

  return (
    <Table.Body>
      {data.rowData.map((itemRow) => (
        <ItemListTableRow
          key={itemRow.itemId}
          itemRow={itemRow}
          columnDefinitions={columnDefinitions}
          widget={widget}
        />
      ))}

      {widget.config.showTotals && data.totalRowData && (
        <SummaryTableRow summaryData={data.totalRowData} columnDefinitions={columnDefinitions} />
      )}
    </Table.Body>
  );
};

const DeprecatedPeopleListBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const data = widget.data as DeprecatedPeopleListWidgetData;

  return (
    <Table.Body>
      {data.rowData.map((peopleRow) => (
        <DeprecatedPeopleListTableRow
          key={peopleRow.user.userId}
          peopleRow={peopleRow}
          columnDefinitions={columnDefinitions}
          widget={widget}
        />
      ))}
      {widget.config.showTotals && data.totalRowData && (
        <DeprecatedSummaryTableRow summaryData={data.totalRowData} columnDefinitions={columnDefinitions} />
      )}
    </Table.Body>
  );
};

const PeopleListBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const data = widget.data as PeopleListWidgetData;

  return (
    <Table.Body>
      {data.rowData.map((peopleRow) => (
        <PeopleListTableRow
          key={peopleRow.user.userId}
          peopleRow={peopleRow}
          columnDefinitions={columnDefinitions}
          widget={widget}
        />
      ))}
      {widget.config.showTotals && data.totalRowData && (
        <SummaryTableRow summaryData={data.totalRowData} columnDefinitions={columnDefinitions} />
      )}
    </Table.Body>
  );
};

const TableBody = ({
  columnDefinitions,
  widget,
}: {
  columnDefinitions: ReadonlyArray<WidgetConfigColumn>;
  widget: WidgetWithData;
}) => {
  const hasPeopleRefactorFeature = useHasFeature(FeatureFlag.peopleReportRefactor);
  const hasItemRefactorFeature = useHasFeature(FeatureFlag.itemReportRefactor);

  switch (widget.config.listType) {
    case ListWidgetType.Assignment:
    case ListWidgetType.Folders:
    case ListWidgetType.Packages:
    case ListWidgetType.Projects:
    case ListWidgetType.Tasks: {
      if (hasItemRefactorFeature) {
        return <ItemListBody widget={widget} columnDefinitions={columnDefinitions} />;
      }
      return <DeprecatedItemListBody widget={widget} columnDefinitions={columnDefinitions} />;
    }

    case ListWidgetType.Users: {
      if (hasPeopleRefactorFeature) {
        return <PeopleListBody widget={widget} columnDefinitions={columnDefinitions} />;
      }

      return <DeprecatedPeopleListBody widget={widget} columnDefinitions={columnDefinitions} />;
    }

    case ListWidgetType.Picklists:
    default:
      return null;
  }
};

export const ListWidgetTable = ({ widget }: { widget: WidgetWithData }) => {
  const { config } = widget;
  const hasBenchmarkEstimateEnabled = useHasFeature(FeatureFlag.benchmarkEstimate);

  const previewColumnsLimit = config.listPreviewColumns ?? 0;
  const columns = config.columns ?? [];
  const featureAwareColumns = getFilteredWidgetColumnsOnBenchmarkEstimate(columns, hasBenchmarkEstimateEnabled);
  const columnDefinitions = getPreviewDisplayColumns(featureAwareColumns, previewColumnsLimit);

  return (
    <Table basic="very" compact singleLine>
      <ListTableHeader config={widget.config} previewColumns={columnDefinitions} />
      <TableBody widget={widget} columnDefinitions={columnDefinitions} />
    </Table>
  );
};

function getPreviewDisplayColumns(initialColumns: ReadonlyArray<WidgetConfigColumn>, previewColumnLimit: number) {
  return [...initialColumns].slice(0, previewColumnLimit);
}
