import { uniqWith } from 'lodash';
import { useSelector } from 'react-redux';

import { WidgetType } from 'daos/enums';
import { Dashboard, StartAndEndDate, WidgetGroup } from 'daos/model_types';
import { DateRangeType } from 'daos/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { pluralize } from 'lib/helpers';
import { ReadonlyRecord } from 'lib/readonly_record';
import { getThreeWeekDateRangesForCurrentOrganizationUser } from 'redux/entities/selectors/user';

export const DATE_RANGE_FILTER_TYPE = 'dateRangeFilterType';
export const DATE_RANGE_FILTER_FROM = 'dateRangeFilterFrom';
export const DATE_RANGE_FILTER_TO = 'dateRangeFilterTo';
export const dateRangeFilterDisplay: ReadonlyRecord<DateRangeType, string> = {
  [DateRangeType.AllDates]: 'All Dates',
  [DateRangeType.CalendarDates]: 'Custom Date Range',
  [DateRangeType.ThreeWeekWindow]: '3 Week Window (last, current, next)',
  [DateRangeType.ThisMonth]: 'This Month',
  [DateRangeType.LastMonth]: 'Last Month',
  [DateRangeType.NextMonth]: 'Next Month',
  [DateRangeType.YearToDate]: 'Year to Date',
};
export const shouldDisplayFilteredDateRange = (widgetType: WidgetType | null) => {
  switch (widgetType) {
    case WidgetType.List:
    case WidgetType.BoardSummary:
    case WidgetType.MetricsTally:
    case WidgetType.PropertiesSummary:
    case WidgetType.Changes:
    case WidgetType.Insights:
      return true;
  }
  return false;
};

export function formatDateRange({
  startDate,
  endDate,
  formatter,
  label = 'Date Range',
}: {
  startDate: string;
  endDate: string;
  formatter: (date: string) => string;
  label?: string;
}) {
  return `${label} ${formatter(startDate)} - ${formatter(endDate)}`;
}

function formatDateRangeArray(dateRanges: ReadonlyArray<StartAndEndDate>, formatter: (date: string) => string) {
  return (
    pluralize('Date Range', dateRanges.length) +
    dateRanges
      .map(({ startDate, endDate }) =>
        formatDateRange({ startDate: startDate ?? '', endDate: endDate ?? '', formatter, label: '' })
      )
      .join(' |')
  );
}
function getShouldDisplay3WeekWindowExplicitDates(
  dateRangeFilterType: DateRangeType,
  threeWeekWindowStartDate: string | null,
  threeWeekWindowEndDate: string | null
) {
  return dateRangeFilterType === DateRangeType.ThreeWeekWindow && threeWeekWindowStartDate && threeWeekWindowEndDate;
}

export const getDateRangeHoverDisplay = ({
  startDate,
  endDate,
  inheritedDateRange,
  dateRangeFilterType,
  formatter,
  threeWeekWindowStartDate,
  threeWeekWindowEndDate,
}: {
  startDate: string | null;
  endDate: string | null;
  dateRangeFilterType: DateRangeType;
  inheritedDateRange: ReadonlyArray<StartAndEndDate>;
  formatter: (date: string) => string;
  threeWeekWindowStartDate: string | null;
  threeWeekWindowEndDate: string | null;
}) => {
  if (inheritedDateRange.length > 0) {
    return formatDateRangeArray(
      uniqWith(inheritedDateRange, (a, b) => a.startDate === b.startDate && a.endDate === b.endDate),
      formatter
    );
  }
  if (dateRangeFilterType === DateRangeType.CalendarDates) {
    return formatDateRange({ startDate: startDate ?? '', endDate: endDate ?? '', formatter });
  }
  const display3WeekWindowExplicitDates = getShouldDisplay3WeekWindowExplicitDates(
    dateRangeFilterType,
    threeWeekWindowStartDate,
    threeWeekWindowEndDate
  );
  if (display3WeekWindowExplicitDates) {
    return formatDateRange({
      startDate: threeWeekWindowStartDate ?? '',
      endDate: threeWeekWindowEndDate ?? '',
      formatter,
      label: '3 Week Window',
    });
  }
  return dateRangeFilterDisplay[dateRangeFilterType];
};

export const getDateRangeDisplay = ({
  startDate,
  endDate,
  dateRangeFilterType,
  formatter,
  threeWeekWindowStartDate,
  threeWeekWindowEndDate,
}: {
  startDate: string | null;
  endDate: string | null;
  dateRangeFilterType: DateRangeType;
  formatter: (date: string) => string;
  threeWeekWindowStartDate: string | null;
  threeWeekWindowEndDate: string | null;
}) => {
  if (dateRangeFilterType === DateRangeType.CalendarDates) {
    return formatDateRange({
      startDate: startDate ?? '',
      endDate: endDate ?? '',
      formatter,
      label: 'Custom Date Range ',
    });
  }
  const display3WeekWindowExplicitDates = getShouldDisplay3WeekWindowExplicitDates(
    dateRangeFilterType,
    threeWeekWindowStartDate,
    threeWeekWindowEndDate
  );

  if (display3WeekWindowExplicitDates) {
    return formatDateRange({
      startDate: threeWeekWindowStartDate ?? '',
      endDate: threeWeekWindowEndDate ?? '',
      formatter,
      label: '3 Week Window',
    });
  }
  if (dateRangeFilterType !== DateRangeType.AllDates) {
    return dateRangeFilterDisplay[dateRangeFilterType];
  }
  return dateRangeFilterDisplay[DateRangeType.AllDates];
};

export function useGetDisplayDateRanges(dashboard: Dashboard | null, widgetGroup: WidgetGroup | null) {
  const { formatLocalDate } = useLocalizedFormats();
  const threeWeekWidgetExplicitDates = useSelector(getThreeWeekDateRangesForCurrentOrganizationUser);

  const commonArgs = {
    formatter: formatLocalDate,
    threeWeekWindowStartDate: threeWeekWidgetExplicitDates?.startDate ?? null,
    threeWeekWindowEndDate: threeWeekWidgetExplicitDates?.endDate ?? null,
  };

  const dashboardDateRange = getDateRangeDisplay({
    startDate: dashboard?.config?.dateRangeFilterFrom ?? '',
    endDate: dashboard?.config?.dateRangeFilterTo ?? '',
    dateRangeFilterType: dashboard?.config?.dateRangeFilterType
      ? (dashboard?.config?.dateRangeFilterType as DateRangeType)
      : DateRangeType.AllDates,
    ...commonArgs,
  });

  const widgetGroupDateRange = getDateRangeDisplay({
    startDate: widgetGroup?.config?.dateRangeFilterFrom ?? '',
    endDate: widgetGroup?.config?.dateRangeFilterTo ?? '',
    dateRangeFilterType: widgetGroup?.config?.dateRangeFilterType
      ? (widgetGroup?.config?.dateRangeFilterType as DateRangeType)
      : DateRangeType.AllDates,
    ...commonArgs,
  });
  return { dashboardDateRange, widgetGroupDateRange };
}
export const shouldDisplayDateRangeWarning = (widgetType: WidgetType | null) => {
  switch (widgetType) {
    case WidgetType.ScheduleSummary:
    case WidgetType.Workload:
      return true;
  }
  return false;
};
