import * as yup from 'yup';

import { ItemIntakeSettableField } from 'features/dashboards_v2/widget_click_through/intake/enums';
import {
  IntakeWidgetItemFieldToFieldIdKey,
  WidgetIntakeFormUserInputValidations,
} from 'features/dashboards_v2/widget_click_through/intake/types';
import { isValidDateRange } from 'lib/helpers/date_valid';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { rawStringToIsoDate } from 'lib/localization';

export type YupIntakeValuesShape = yup.DefaultFromShape<WidgetIntakeFormUserInputValidations>;

export const findTargetStartAndTargetFinishForFieldRowId = (
  itemFieldToFieldIdKey: IntakeWidgetItemFieldToFieldIdKey,
  itemField: ItemIntakeSettableField.TargetStart | ItemIntakeSettableField.TargetFinish
) => {
  const findItem = Object.entries(itemFieldToFieldIdKey).find(([_key, value]) => value === itemField);
  return findItem ? findItem[0] : undefined;
};

export const getTargetDateValueForItemFieldIdAsISODate = (values: YupIntakeValuesShape, targetDateFieldId: string) => {
  const hasField = targetDateFieldId in values;
  const fieldValue = values[targetDateFieldId];

  return hasField && fieldValue && typeof fieldValue === 'string' ? rawStringToIsoDate(fieldValue) : '';
};

export function schemaTestForTargetStartTargetFinish({
  values,
  itemFieldToFieldIdKey,
  createError,
}: {
  values: YupIntakeValuesShape;
  itemFieldToFieldIdKey: IntakeWidgetItemFieldToFieldIdKey;
  createError: (params?: yup.CreateErrorOptions | undefined) => yup.ValidationError;
}) {
  const targetStartFieldId = findTargetStartAndTargetFinishForFieldRowId(
    itemFieldToFieldIdKey,
    ItemIntakeSettableField.TargetStart
  );
  const targetFinishFieldId = findTargetStartAndTargetFinishForFieldRowId(
    itemFieldToFieldIdKey,
    ItemIntakeSettableField.TargetFinish
  );

  const targetStart = targetStartFieldId ? getTargetDateValueForItemFieldIdAsISODate(values, targetStartFieldId) : '';
  const targetFinish = targetFinishFieldId
    ? getTargetDateValueForItemFieldIdAsISODate(values, targetFinishFieldId)
    : '';

  return (
    isValidDateRange(targetStart, targetFinish) ||
    createError({ message: lpErrorText.targetStartDate, path: targetStartFieldId })
  );
}
