import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popup, Table } from 'semantic-ui-react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import LpLink from 'containers/shared/lp_link';
import { DashboardDao } from 'daos/dashboards';
import { InvitationState, UserWorkspaceSettingFieldName } from 'daos/enums';
import { InvitationDao } from 'daos/invitation';
import { Dashboard, Invitation, Organization, ResourceId, Workspace } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { UserWorkspaceSettingsDao } from 'daos/user_workspace_settings';
import { WorkspaceDao } from 'daos/workspace';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import ClickableText from 'features/common/clickable_text';
import { ComponentJoin } from 'features/common/component_join';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { LpIcon, checkSquareSolid, questionCircleSolid, squareRegular } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { getDashboardForId } from 'features/dashboards/selectors';
import { PlanGadgetSidebar } from 'features/organization_directory/manage_account/plan_gadget';
import { InviteErrorModal } from 'features/organization_directory/users/invite/invite_errors';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { awaitRequestFinish } from 'lib/api';
import { ApiError, ErrorPayload } from 'lib/api/types';
import { frontend } from 'lib/urls';
import { getUserForId } from 'redux/entities/selectors/user';
import { getCurrentWorkspaceUserSettings } from 'redux/entities/selectors/user_workspace_settings';
import { getWorkspacesById } from 'redux/entities/selectors/workspace';
import './index.scss';

const reverseSortByCreatedAt = (a: Invitation, b: Invitation) => {
  if (a.createdAt < b.createdAt) {
    return 1;
  }

  if (a.createdAt > b.createdAt) {
    return -1;
  }

  return 0;
};

interface OpenRevokeInvitationConfirmationModalProps {
  id: number;
  email: string;
}
interface ResendInvitationProps {
  organizationId: number;
  id: number;
}

interface ResendGuestInvitationProps {
  invitationId: number;
  email: string;
  organization: ResourceId<Organization>;
  workspaces: Array<ResourceId<Workspace>>;
  dashboards: Array<ResourceId<Dashboard>>;
}

const PendingInviteRow = ({
  invitation,
  openRevokeInvitationConfirmationModal,
  resendInvitation,
  resendGuestInvitation,
}: {
  invitation: Invitation;
  openRevokeInvitationConfirmationModal: ({ id, email }: OpenRevokeInvitationConfirmationModalProps) => void;
  resendInvitation: ({ organizationId, id }: ResendInvitationProps) => void;
  resendGuestInvitation: ({
    invitationId,
    email,
    organization,
    workspaces,
    dashboards,
  }: ResendGuestInvitationProps) => void;
}) => {
  const user = useSelector((state) => getUserForId(state, invitation.createdBy?.id ?? 0));
  const workspaces = useSelector(getWorkspacesById);
  const dashboardName = useSelector((state) =>
    getDashboardForId(state, invitation.dashboards[0]?.id ?? 0)
  )?.defaultName;

  const isExpired = invitation.state === InvitationState.Expired;
  const { formatLocalDate, formatLocalDateWithTime } = useLocalizedFormats();

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const afterLinkClick = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const openPopup = () => setIsPopUpOpen(true);
  const closePopup = () => setIsPopUpOpen(false);
  const handleResend = () => {
    resendInvitation({ organizationId: invitation.organization.id, id: invitation.id });
    openPopup();
    afterLinkClick.current = setTimeout(() => closePopup(), 1000);
  };

  const handleResendGuestInvitation = () =>
    resendGuestInvitation({
      invitationId: invitation.id,
      email: invitation.email,
      organization: OrganizationDao.id(invitation.organization.id),
      workspaces: [WorkspaceDao.id(invitation.workspaces[0]?.id ?? 0)],
      dashboards: [DashboardDao.id(invitation.dashboards[0]?.id ?? 0)],
    });

  return (
    <Table.Row key={invitation.id}>
      <Table.Cell>
        {user?.email ?? (
          <span className="pending-invite-not-active">
            {user?.firstName} {user?.lastName} (Not Active)
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        {invitation.email}

        {invitation.guestInvitation && (
          <span className="pending-invite-guest-label">
            (Guest)
            {dashboardName && (
              <Popup
                content={
                  <p>
                    <strong>Dashboard:</strong>
                    <br />
                    {dashboardName}
                  </p>
                }
                position="top center"
                trigger={<LpIcon icon={questionCircleSolid} />}
              />
            )}
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <ComponentJoin>
          {invitation.workspaces.map((workspace) => (
            <span key={workspace.id}>
              {workspaces[workspace.id]?.name ?? <span className="pending-invite-not-active">Deleted Workspace</span>}
            </span>
          ))}
        </ComponentJoin>
      </Table.Cell>
      <Table.Cell className={isExpired ? 'expired' : ''}>
        <LpPopUp
          trigger={<span>{formatLocalDate(invitation.createdAt)}</span>}
          content={formatLocalDateWithTime(invitation.createdAt)}
        />
        {}
        {isExpired && (
          <>
            <br />
            (Expired)
          </>
        )}
      </Table.Cell>

      <Table.Cell collapsing textAlign="center">
        {isExpired ? (
          <>
            {invitation.guestInvitation ? (
              <ClickableText onClick={handleResendGuestInvitation} text="Reinvite" />
            ) : (
              <LpLink
                to={`${frontend.organizationHubUsersInvite.url({
                  organizationId: invitation.organization.id,
                })}#email=${encodeURIComponent(invitation.email)}&workspaces=${invitation.workspaces
                  .map((workspace) => workspace.id)
                  .join(',')}`}
              >
                Reinvite
              </LpLink>
            )}
          </>
        ) : (
          <Popup
            transition="false"
            trigger={
              <span className="clickable" onClick={handleResend}>
                Resend
              </span>
            }
            content={'Invitation Sent'}
            open={isPopUpOpen}
            basic
            position={'bottom center'}
            size="tiny"
          />
        )}
        {!isExpired && (
          <>
            {' '}
            or{' '}
            <span
              className="clickable"
              onClick={() => openRevokeInvitationConfirmationModal({ id: invitation.id, email: invitation.email })}
            >
              Revoke
            </span>
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export const PendingInvites = () => {
  const dispatch = useDispatch();

  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [invitations, setInvitations] = useState<ReadonlyArray<Invitation>>();

  const currentWorkspaceUserSettings = useSelector(getCurrentWorkspaceUserSettings);
  const [filterExpiredInvitations, setFilterExpiredInvitations] = useState(
    currentWorkspaceUserSettings?.includeExpiredInvitations ?? true
  );

  const [invitationError, setInvitationError] = useState<ApiError | undefined>(undefined);
  const clearApiError = () => setInvitationError(undefined);
  const handleApiError = ({ errors }: ErrorPayload) => {
    if (errors[0]) {
      setInvitationError(errors[0]);
    }
  };

  const [revokeInvitationConfirmationModalProps, setRevokeInvitationConfirmationModalProps] = useState<
    { id: number; email: string } | undefined
  >(undefined);
  const openRevokeInvitationConfirmationModal = ({ id, email }: { id: number; email: string }) =>
    setRevokeInvitationConfirmationModalProps({ id, email });
  const closeRevokeInvitationConfirmationModal = () => setRevokeInvitationConfirmationModalProps(undefined);

  const fetchInvitations = useCallback(() => {
    setInvitations(undefined);

    const { uuid } = dispatch(
      InvitationDao.getPending({
        organizationId,
        filterExpiredInvitations,
        include: {
          includeDashboards: true,
        },
      })
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<Invitation>>(uuid, {
        onSuccess: ({ data }) => {
          setInvitations([...data].sort(reverseSortByCreatedAt));
        },
        onError: (error: ErrorPayload) => {
          setInvitations([]);
          handleApiError(error);
        },
      })
    );
  }, [dispatch, filterExpiredInvitations, organizationId]);

  const revokeInvitation = useCallback(
    (invitationId: number) => {
      const { uuid } = dispatch(InvitationDao.revoke({ organizationId, invitationId }));

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: () => {
            setInvitations((previousInvitations) =>
              previousInvitations
                ? [...previousInvitations].filter((invitation) => invitation.id !== invitationId)
                : undefined
            );
          },
          onError: handleApiError,
          onFinish: () => {
            closeRevokeInvitationConfirmationModal();
          },
        })
      );
    },
    [dispatch, organizationId]
  );

  const handleResendInvitationSuccess = ({
    expiredInvitationId,
    newInvitation,
  }: {
    expiredInvitationId: number;
    newInvitation: Invitation;
  }) => {
    setInvitations((previousInvitations) =>
      [...(previousInvitations ?? [])]
        .filter((invitation) => invitation.id !== expiredInvitationId)
        .concat(newInvitation)
        .sort(reverseSortByCreatedAt)
    );
  };

  const resendGuestInvitation = ({
    invitationId,
    email,
    organization,
    workspaces,
    dashboards,
  }: ResendGuestInvitationProps) => {
    const { uuid } = dispatch(
      InvitationDao.createGuest({
        email,
        organization,
        workspaces,
        dashboards,
      })
    );

    dispatch(
      awaitRequestFinish<Invitation>(uuid, {
        onSuccess: ({ data }) => {
          handleResendInvitationSuccess({
            expiredInvitationId: invitationId,
            newInvitation: data,
          });
        },
        onError: handleApiError,
      })
    );
  };

  const resendInvitation = ({ organizationId, id: invitationId }: { organizationId: number; id: number }) => {
    const { uuid } = dispatch(InvitationDao.resend({ organizationId, invitationId }));

    dispatch(
      awaitRequestFinish<Invitation>(uuid, {
        onSuccess: ({ data }) => {
          handleResendInvitationSuccess({
            expiredInvitationId: invitationId,
            newInvitation: data,
          });
        },
        onError: handleApiError,
      })
    );
  };

  const handleIncludeExpiredClick = () => {
    setFilterExpiredInvitations((prevState) => {
      const newState = !prevState;

      const { uuid } = dispatch(
        UserWorkspaceSettingsDao.update(
          {
            organizationId,
            workspaceId,
            settingFieldName: UserWorkspaceSettingFieldName.IncludeExpiredInvitations,
          },
          newState
        )
      );

      dispatch(awaitRequestFinish(uuid, {}));

      return newState;
    });
  };

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  useSetupOutpost(OutpostLocation.OrganizationUsersInvitesPending);

  return (
    <>
      {invitationError && <InviteErrorModal error={invitationError} onClose={clearApiError} />}
      {!!revokeInvitationConfirmationModalProps && (
        <BaseConfirmModal
          confirmButtonText="Revoke"
          content={
            <span>
              You are about to REVOKE the invitation sent to <em>{revokeInvitationConfirmationModalProps.email}</em>.
            </span>
          }
          onClose={closeRevokeInvitationConfirmationModal}
          onConfirm={() => revokeInvitation(revokeInvitationConfirmationModalProps.id)}
        />
      )}

      <div className="organization-directory__users-grid">
        {!invitations ? (
          <LpOverlayLoader />
        ) : (
          <>
            <p className="pending-invite__title">
              <span>
                Activation links in recent invitations can be invalidated by clicking Revoke. Invitations are
                automatically revoked after 5 days.
              </span>
              <Button
                className={classNames('pending-invite__include-expired-button', {
                  'pending-invite__include-expired-button--active': filterExpiredInvitations,
                })}
                content={
                  <span className={'pending-invite__include-expired-button__text'}>
                    <LpIcon icon={filterExpiredInvitations ? checkSquareSolid : squareRegular} /> Include Expired
                  </span>
                }
                onClick={handleIncludeExpiredClick}
                size="small"
                type="button"
              />
            </p>
            <div className="pending-invite">
              <Table compact className="pending-invite-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Invitation By</Table.HeaderCell>
                    <Table.HeaderCell>Sent To</Table.HeaderCell>
                    <Table.HeaderCell>For Workspace(s)</Table.HeaderCell>
                    <Table.HeaderCell>Sent On</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {!invitations.length ? (
                    <Table.Row>
                      <Table.Cell colSpan="5" collapsing={true} textAlign="center">
                        No pending {!filterExpiredInvitations ? 'active' : ''} invitations for this Organization
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    invitations.map((invitation) => (
                      <PendingInviteRow
                        key={invitation.id}
                        invitation={invitation}
                        openRevokeInvitationConfirmationModal={openRevokeInvitationConfirmationModal}
                        resendInvitation={resendInvitation}
                        resendGuestInvitation={resendGuestInvitation}
                      />
                    ))
                  )}
                </Table.Body>
              </Table>
            </div>
          </>
        )}
        <PlanGadgetSidebar includeContactSupport={true} />
      </div>
    </>
  );
};
