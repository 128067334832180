import { WorkspaceUserOrderBy } from 'daos/enums';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { WorkspaceUser } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { updateBody, resource } = defineModel({ apiType: 'workspaceUsers', type: 'WORKSPACE_USER' });

const { resourceId, WORKSPACE_USER } = resource;

interface Includes {
  includeAvailabilityRanges?: boolean;
  includeFields?: boolean;
  includeFieldValues?: boolean;
  includeGroups?: boolean;
  includeOrganizationUser?: boolean;
  includeUser?: boolean;
  includeWorkspace?: boolean;
  includeWsUserGroups?: boolean;
  includeGuestDashboards?: boolean;
  includeGuestDashboardsAncestors?: boolean;
  includeTimesheetEntryLockExceptions?: boolean;
}

interface RequestOptions {
  include?: Includes;
}

const includes = ({
  includeAvailabilityRanges,
  includeFields,
  includeFieldValues,
  includeUser,
  includeGroups,
  includeOrganizationUser,
  includeWsUserGroups,
  includeWorkspace,
  includeGuestDashboards,
  includeGuestDashboardsAncestors,
  includeTimesheetEntryLockExceptions,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeAvailabilityRanges && 'availabilityRanges',
    includeFields && 'workspace.fields',
    includeFieldValues && 'fieldValues',
    includeUser && 'user',
    includeGroups && 'workspaceUserGroups.group',
    includeOrganizationUser && 'user.organizationUsers',
    includeWsUserGroups && 'workspaceUserGroups',
    includeWorkspace && 'workspace',
    includeGuestDashboards && 'dashboardGuests.dashboards',
    includeGuestDashboardsAncestors && 'dashboardGuests.dashboards.item.ancestors',
    includeTimesheetEntryLockExceptions && 'timesheetEntryLockExceptions',
  ]);

interface WorkspaceUsersParams {
  organizationId: number;
  workspaceId: number;
}

interface SingleWorkspaceUserParams extends WorkspaceUsersParams {
  workspaceUserId: number;
}

interface MemberOptions {
  filter?: string;
  include?: Includes;
  query?: { limit?: number; order?: WorkspaceUserOrderBy };
  skipReduce?: boolean;
  target?: string;
}

const fetch = (params: SingleWorkspaceUserParams, { include }: RequestOptions = {}) =>
  request(backend.workspaceUser.url(params, { include: includes(include) }), WORKSPACE_USER, {
    method: HttpMethod.GET,
  });

const fetchAll = (params: WorkspaceUsersParams, { filter, include, query }: MemberOptions = {}) =>
  request(backend.workspaceUsers.url(params, { filter, include: includes(include), query }), WORKSPACE_USER, {
    method: HttpMethod.GET,
  });

const workspaceUserUpdate = (params: SingleWorkspaceUserParams, wsUser: Partial<WorkspaceUser>) =>
  request(backend.workspaceUser.url(params), WORKSPACE_USER, updateBody(params.workspaceUserId, wsUser));

export const WorkspaceUserDao = {
  fetch,
  fetchAll,
  id: resourceId,
  update: workspaceUserUpdate,
} as const;
