import { boxTapedSolid, folderClosedSolid, globeSolid } from 'features/common/lp_icon';
import { DashboardType } from 'features/dashboards/dashboard_types';

export function camelToHyphenCase(str: string) {
  return str.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
}

export function getDashboardIcon(dashboardType: DashboardType) {
  switch (dashboardType) {
    case DashboardType.workspaces:
    case DashboardType.collections:
      return globeSolid;
    case DashboardType.packages:
      return boxTapedSolid;
    case DashboardType.projects:
      return folderClosedSolid;
  }
}
