import { Message } from 'semantic-ui-react';

import { exclamationTriangleRegular, LpIcon } from 'features/common/lp_icon';

const content =
  'The selected scheme is valid but may not yield the expected results. Please double-check the selected scheme. Ensure it contains at least one row with non-zero values and that the estimate values increase sequentially with story points. Incorrect configurations may affect auto-calculation for story points.';

export const InvalidSchemeMessage = () => {
  return (
    <Message
      icon={true}
      warning
      visible
      className="jira-project-modal__content-estimate-mapping-invalid-scheme-warning"
    >
      <LpIcon
        className="jira-project-modal__content-estimate-mapping-invalid-scheme-warning-icon"
        icon={exclamationTriangleRegular}
        size="2x"
      />
      <Message.Content className="jira-project-modal__content-estimate-mapping-invalid-scheme-warning-content">
        <Message.Header>Attention</Message.Header>
        {content}
      </Message.Content>
    </Message>
  );
};
