import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import RequestFailed from 'features/common/errors/request_failed';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import {
  getOrganizationHubUsersFetchComplete,
  getOrganizationHubHasError,
} from 'features/organization_directory/selectors';
import { OrgUsersViewOption } from 'features/organization_directory/users/table/types';

import { OrganizationDirectoryUsersRow } from './row';

interface OrganizationDirectoryUsersTableProps {
  view: OrgUsersViewOption;
}

const OrganizationDirectoryUsersTable = ({ view }: OrganizationDirectoryUsersTableProps) => {
  const hasError = useSelector(getOrganizationHubHasError);
  const fetchComplete = useSelector(getOrganizationHubUsersFetchComplete);

  if (hasError) {
    return <RequestFailed />;
  }

  if (!fetchComplete) {
    return (
      <LpOverlayLoader
        className="organization-directory__users-table-spinner"
        active={true}
        content="Loading..."
        size="huge"
        inline={'centered'}
      />
    );
  }

  return fetchComplete ? (
    <div className="organization-directory__users-table-area">
      <Table className="organization-directory__users-table">
        <Table.Header className="organization-directory__users-table-header">
          <Table.Row>
            <Table.HeaderCell collapsing={view === 'disconnected'}>Users</Table.HeaderCell>
            <Table.HeaderCell collapsing={view === 'disconnected'}>First & Last Name</Table.HeaderCell>
            {view === 'active' && (
              <>
                <Table.HeaderCell textAlign="center" singleLine>
                  Workspaces
                </Table.HeaderCell>
                <Table.HeaderCell>Last Access</Table.HeaderCell>
                <Table.HeaderCell>Joined On</Table.HeaderCell>
              </>
            )}
            {view === 'disconnected' && (
              <>
                <Table.HeaderCell>Disconnected</Table.HeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <OrganizationDirectoryUsersRow view={view} />
        </Table.Body>
      </Table>
    </div>
  ) : null;
};

export default OrganizationDirectoryUsersTable;
