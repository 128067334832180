import { useLocation } from 'react-router';

/* Based off React Router's example: https://reactrouter.com/web/example/query-parameters
Note that URLSearchParams is not supported by IE 11
*/
export const useUrlQueryParamSearch = (queryKey: string) => {
  const querySearch = new URLSearchParams(useLocation().search);

  return querySearch.get(queryKey);
};
