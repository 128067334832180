import { Table } from 'semantic-ui-react';

import { ItemIntakeSettableField } from 'features/dashboards_v2/widget_click_through/intake/enums';
import { separateNotesFields } from 'features/dashboards_v2/widget_click_through/intake/form/helpers';
import {
  IntakeCustomFieldUserWidgetData,
  ItemIntakeFormSectionField,
} from 'features/dashboards_v2/widget_click_through/intake/types';

import { CustomFieldRow } from './custom_field_row';
import { ItemFieldRow } from './item_field_row';

export const IntakeFormSectionFieldTables = ({
  fields,
  firstFieldIdWithAutoFocus,
  intakeUserChoices,
  organizationId,
  workspaceId,
  isFileUploadsAllowed,
}: {
  fields: ReadonlyArray<ItemIntakeFormSectionField>;
  firstFieldIdWithAutoFocus: number | undefined;
  intakeUserChoices: Record<string, IntakeCustomFieldUserWidgetData>;
  organizationId: string;
  workspaceId: string;
  isFileUploadsAllowed: boolean;
}) => {
  const fieldTableSections = separateNotesFields(fields);

  return (
    <>
      {fieldTableSections.map((tableSection, index) => {
        return (
          <Table
            celled
            definition
            key={`${tableSection.length}+${index}`}
            className="intake-widget-user-form-section__sub-section-table"
          >
            <Table.Body>
              {tableSection.map((field) => (
                <FieldRow
                  key={field.id}
                  field={field}
                  firstFieldIdWithAutoFocus={firstFieldIdWithAutoFocus}
                  intakeUserChoices={intakeUserChoices}
                  organizationId={organizationId}
                  workspaceId={workspaceId}
                  isFileUploadsAllowed={isFileUploadsAllowed}
                />
              ))}
            </Table.Body>
          </Table>
        );
      })}
    </>
  );
};

const FieldRow = ({
  field,
  firstFieldIdWithAutoFocus,
  intakeUserChoices,
  organizationId,
  workspaceId,
  isFileUploadsAllowed,
}: {
  field: ItemIntakeFormSectionField;
  firstFieldIdWithAutoFocus: number | undefined;
  intakeUserChoices: Record<string, IntakeCustomFieldUserWidgetData>;
  organizationId: string;
  workspaceId: string;
  isFileUploadsAllowed: boolean;
}) => {
  const { itemField, customField, rowSetting, id } = field;

  const fieldHasAutoFocus = firstFieldIdWithAutoFocus === id;

  if (field.itemField === ItemIntakeSettableField.Files && !isFileUploadsAllowed) {
    return null;
  }

  return (
    <>
      {itemField === ItemIntakeSettableField.CustomField && customField ? (
        <CustomFieldRow
          intakeCustomField={customField}
          intakeUserChoices={intakeUserChoices}
          fieldId={id}
          rowSetting={rowSetting}
          hasAutoFocus={fieldHasAutoFocus}
        />
      ) : (
        <ItemFieldRow
          itemField={itemField}
          rowSetting={rowSetting}
          fieldId={id}
          hasAutoFocus={fieldHasAutoFocus}
          organizationId={organizationId}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
};
