import { ItemType, PackageStatus } from 'daos/enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';

export const getTitleOrInheritedTitle = ({
  inheritedLocationName,
  locationName,
  defaultTitle,
}: {
  inheritedLocationName: string;
  locationName: string;
  defaultTitle: string;
}) => {
  if (locationName) {
    return locationName;
  } else if (inheritedLocationName) {
    return inheritedLocationName;
  } else {
    return defaultTitle;
  }
};

export const getLocationNameFromScope = ({
  locationItemFilterName,
  locationItemFilterPackageStatus,
  locationItemFilterType,
  currentWorkspaceName,
}: {
  locationItemFilterName: string;
  locationItemFilterPackageStatus: PackageStatus | null;
  locationItemFilterType: ItemType | null;
  currentWorkspaceName: string;
}) => {
  let name = '';

  if (locationItemFilterName) {
    name = locationItemFilterName;
  } else if (locationItemFilterType === ItemType.WORKSPACE_ROOTS && locationItemFilterPackageStatus) {
    name = packageStatusDisplayNameByPackageStatus[locationItemFilterPackageStatus];
  } else if (
    locationItemFilterType === ItemType.WORKSPACE_ROOTS &&
    !locationItemFilterPackageStatus &&
    currentWorkspaceName
  ) {
    name = currentWorkspaceName;
  }
  return name;
};
