import { Button } from 'semantic-ui-react';

import { LpIcon, caretDownSolid } from 'features/common/lp_icon';

interface SelectionDropdownTriggerButtonProps {
  className: string;
}

const SelectionDropdownTriggerButton = ({ className }: SelectionDropdownTriggerButtonProps) => (
  <Button className={`${className} icon`} content={<LpIcon icon={caretDownSolid} size="2x" />} primary size="mini" />
);
export default SelectionDropdownTriggerButton;
