/* eslint-disable no-restricted-imports */

import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Inspector from 'avo-inspector';
import { getParser } from 'bowser';

import bugsnagClient from 'lib/bugsnag';

import * as Avo from './avo';

export {
  GridFilteredProperties,
  OriginAction,
  OriginPage,
  IdentifiedUserProperties,
  OriginActionValueType,
  OriginView,
  OriginPageValueType,
  OriginViewValueType,
  IntegrationCompletedProperties,
  IntegrationUpdatedProperties,
} from 'lib/avo/avo';

export const avoClient = (() => {
  const { segmentKey, nodeEnv } = window as any;

  if (!segmentKey) {
    return undefined;
  }

  const browser = getParser(navigator.userAgent);

  const isProdEnv = nodeEnv?.toUpperCase() === 'TRUE';
  const analytics = AnalyticsBrowser.load({ writeKey: segmentKey });

  const avoInspector = new Inspector.AvoInspector({
    apiKey: Avo.avoInspectorApiKey,
    env: isProdEnv ? Inspector.AvoInspectorEnv.Prod : Inspector.AvoInspectorEnv.Dev,
    version: '1.0.0',
    appName: Avo.OriginProduct.LIQUID_PLANNER,
  });

  Avo.initAvo(
    { env: isProdEnv ? Avo.AvoEnv.Prod : Avo.AvoEnv.Dev, inspector: avoInspector },
    {
      webBrowser: browser.getBrowserName(),
      webBrowserVersion: browser.getBrowserVersion(),
      operatingSystem: browser.getOSName(),
      operatingSystemVersion: browser.getOSVersion(),
      originProduct: Avo.OriginProduct.LIQUID_PLANNER,
      originEcosystem: Avo.OriginEcosystem.BROWSER_BASED,
    },
    {},
    {
      logPage: (pageName: string, eventProperties: Record<string, unknown>) => {
        try {
          avoInspector.trackSchemaFromEvent('Page Viewed', eventProperties);
          analytics.page(pageName, eventProperties);
        } catch (error) {
          if (error instanceof Error) {
            bugsnagClient?.notify({ name: 'failedSegmentPage', message: error.toString() });
          }
        }
      },
      identify: (userId: string) => {
        try {
          avoInspector.trackSchemaFromEvent('Identify', { userId });
          analytics.identify(userId);
        } catch (error) {
          if (error instanceof Error) {
            bugsnagClient?.notify({ name: 'failedSegmentIdentify', message: error.toString() });
          }
        }
      },
      setUserProperties: (userId: string, userProperties: Record<string, any>) => {
        try {
          avoInspector.trackSchemaFromEvent(`User Properties Set for user ${userId}`, userProperties);
          analytics.user().then((user) => user.identify(userId, userProperties));
        } catch (error) {
          if (error instanceof Error) {
            bugsnagClient?.notify({ name: 'failedSegmentUserProperties', message: error.toString() });
          }
        }
      },
      logEvent: (eventName: string, eventProperties: Record<string, unknown>) => {
        try {
          avoInspector.trackSchemaFromEvent(eventName, {});
          analytics.track(eventName, eventProperties);
        } catch (error) {
          if (error instanceof Error) {
            bugsnagClient?.notify({ name: `failed ${eventName}`, message: error.toString() });
          }
        }
      },
    }
  );

  return Avo;
})();
