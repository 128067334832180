import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel } from './shared';
import { backend } from './urls';

const { resource, body } = defineModel({
  apiType: 'subscriptions',
  type: 'SUBSCRIPTION',
});

const { SUBSCRIPTION, resourceId } = resource;

interface SubscriptionChangeParams {
  organizationId: number;
}

const cancelSubscriptionChange = (params: SubscriptionChangeParams) =>
  request(backend.organizationCancelSubscriptionChange.url(params), SUBSCRIPTION, {
    ...body(undefined, {}),
    method: HttpMethod.POST,
  });

export const SubscriptionDao = {
  id: resourceId,
  cancelSubscriptionChange,
} as const;
