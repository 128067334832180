import { useFormikContext } from 'formik';

import { CustomFieldType, ScheduleDirective } from 'daos/enums';
import {
  ItemIntakeRowSetting,
  ItemIntakeSettableField,
} from 'features/dashboards_v2/widget_click_through/intake/enums';
import {
  IntakeFormWidgetData,
  IntakeSettingsTemplateValue,
  IntakeWidgetItemFieldToFieldIdKey,
  WidgetIntakeFormUserInput,
  WidgetIntakeFormUserInputValidations,
  IntakeCustomFieldWidgetData,
  ItemIntakeFormSectionField,
} from 'features/dashboards_v2/widget_click_through/intake/types';
import { convertSecondsToHours } from 'lib/helpers';

import { validationSchemaRules } from './schema';

export function isRequiredField(intakeRowSetting?: ItemIntakeRowSetting) {
  return intakeRowSetting === ItemIntakeRowSetting.Required;
}
export function isDisplayOnlyField(intakeRowSetting?: ItemIntakeRowSetting) {
  return intakeRowSetting === ItemIntakeRowSetting.Automatic || intakeRowSetting === ItemIntakeRowSetting.ReadOnly;
}

export function useIntakeFormField<T>(fieldIdKey: string) {
  const { getFieldProps, setFieldValue, getFieldMeta, setFieldError, setFieldTouched } = useFormikContext();
  const { value: formFieldValue } = getFieldProps<T>(fieldIdKey);
  const fieldMeta = getFieldMeta(fieldIdKey);

  const setFormFieldValue = (value: T, shouldValidate?: boolean) => setFieldValue(fieldIdKey, value, shouldValidate);

  const setFormFieldError = (message: string | undefined) => setFieldError(fieldIdKey, message);
  const setFormFieldTouched = (isTouched: boolean | undefined, shouldValidate?: boolean | undefined) =>
    setFieldTouched(fieldIdKey, isTouched, shouldValidate);

  return {
    formFieldValue,
    setFormFieldValue,
    fieldMeta,
    setFormFieldError,
    setFormFieldTouched,
  };
}

export function isCustomFieldEmpty(value: string | number | Array<string | number>, customFieldType: CustomFieldType) {
  switch (customFieldType) {
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.MULTI_ITEM:
    case CustomFieldType.MULTI_LINK:
    case CustomFieldType.MULTI_USER:
    case CustomFieldType.MULTI_PICKLIST: {
      const multiValue = value as Array<string | number>;
      return !multiValue.length;
    }
    default:
      return !value;
  }
}

export function emptyCustomFieldValues(customFieldType: CustomFieldType) {
  switch (customFieldType) {
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.MULTI_ITEM:
    case CustomFieldType.MULTI_LINK:
    case CustomFieldType.MULTI_USER:
    case CustomFieldType.MULTI_PICKLIST:
      return [];
    case CustomFieldType.CURRENCY:
    case CustomFieldType.DATE:
    case CustomFieldType.LINK:
    case CustomFieldType.MULTILINE_TEXT:
    case CustomFieldType.NOTE:
    case CustomFieldType.TEXT:
    case CustomFieldType.NUMERIC:
      return undefined;
    case CustomFieldType.CHECKBOX:
      return false;
    default:
      return undefined;
  }
}

// intake form initial values

export function getFormInitialValuesAndValidationRules({
  form,
  userInputForName,
}: {
  form: IntakeFormWidgetData;
  userInputForName: string;
}) {
  const initialFormValues: WidgetIntakeFormUserInput = {};
  const validationRules: WidgetIntakeFormUserInputValidations = {};
  const itemFieldToFieldIdKey: IntakeWidgetItemFieldToFieldIdKey = {};

  form.sections.forEach((section) => {
    section.fields.forEach((field) => {
      const customFieldType = field.customField?.fieldType;

      initialFormValues[field.id] = getInitialValues({ field, userInputForName, customFieldType });
      validationRules[field.id] = validationSchemaRules(field, customFieldType);

      if (customFieldType) {
        itemFieldToFieldIdKey[field.id] = customFieldType;
      } else {
        itemFieldToFieldIdKey[field.id] = field.itemField;
      }
    });
  });

  return { initialFormValues, validationRules, itemFieldToFieldIdKey };
}

function intakeWorkLimitInHoursFromSeconds(workLimit: number) {
  return `${convertSecondsToHours(workLimit)}h`;
}

function initialBooleanCustomFieldValue(templateValue: IntakeSettingsTemplateValue | null) {
  if (templateValue instanceof Array<boolean>) {
    return templateValue[0] ?? false;
  }
  return false;
}
function initialSingleCustomFieldValue<T>(templateValue: IntakeSettingsTemplateValue | null) {
  if (templateValue instanceof Array<T>) {
    return templateValue[0] ?? '';
  }
  return '';
}

function initialMultiCustomFieldValue<T>(templateValue: IntakeSettingsTemplateValue | null) {
  if (templateValue instanceof Array<T>) {
    return templateValue;
  }
  return [];
}

function initialCustomFieldValues(customFieldType: CustomFieldType, templateValue: IntakeSettingsTemplateValue | null) {
  switch (customFieldType) {
    case CustomFieldType.CHECKBOX:
      return initialBooleanCustomFieldValue(templateValue);
    case CustomFieldType.USER:
    case CustomFieldType.ITEM:
    case CustomFieldType.NUMERIC:
    case CustomFieldType.CURRENCY:
    case CustomFieldType.PICKLIST:
      return initialSingleCustomFieldValue<number>(templateValue);
    case CustomFieldType.LINK:
    case CustomFieldType.TEXT:
    case CustomFieldType.DATE:
    case CustomFieldType.NOTE:
    case CustomFieldType.MULTILINE_TEXT:
      return initialSingleCustomFieldValue<string>(templateValue);
    case CustomFieldType.MULTI_ITEM:
    case CustomFieldType.MULTI_USER:
    case CustomFieldType.MULTI_PICKLIST:
      return initialMultiCustomFieldValue<number>(templateValue);
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.MULTI_LINK:
      return initialMultiCustomFieldValue<string>(templateValue);
    default:
      return undefined;
  }
}

function getInitialValues({
  field,
  customFieldType,
  userInputForName,
}: {
  field: ItemIntakeFormSectionField;
  customFieldType: CustomFieldType | undefined;
  userInputForName?: string;
}) {
  switch (field.itemField) {
    case ItemIntakeSettableField.Files:
      return [];
    case ItemIntakeSettableField.CustomField:
      return customFieldType ? initialCustomFieldValues(customFieldType, field.templateValue) : undefined;
    case ItemIntakeSettableField.Name:
      return userInputForName;
    case ItemIntakeSettableField.TaskStatus: {
      if (field.templateValue && typeof field.templateValue === 'object' && 'id' in field.templateValue) {
        return field.templateValue.id;
      }
      return undefined;
    }
    case ItemIntakeSettableField.Description:
    case ItemIntakeSettableField.Color:
    case ItemIntakeSettableField.ProjectStatus:
    case ItemIntakeSettableField.TargetFinishType:
      if (typeof field.templateValue === 'string') {
        return field.templateValue;
      }
      return undefined;
    case ItemIntakeSettableField.WorkLimit:
      if (typeof field.templateValue === 'number') {
        return intakeWorkLimitInHoursFromSeconds(field.templateValue) || undefined;
      }
      return undefined;
    case ItemIntakeSettableField.ScheduleDirective:
      return ScheduleDirective.NORMAL;
    default:
      return undefined;
  }
}

export function separateNotesFields(fields: ReadonlyArray<ItemIntakeFormSectionField>) {
  const twoDimensionalArray: Array<Array<ItemIntakeFormSectionField>> = [];
  let innerArray: Array<ItemIntakeFormSectionField> = [];

  fields.forEach((field) => {
    const isNoteCustomField = isCustomFieldNoteField(field.customField);

    if (!isNoteCustomField) {
      innerArray.push(field);
    } else {
      if (innerArray.length) {
        twoDimensionalArray.push(innerArray);
        innerArray = [];
      }

      twoDimensionalArray.push([field]);
    }
  });

  if (innerArray.length) {
    twoDimensionalArray.push(innerArray);
  }

  return twoDimensionalArray;
}

function isCustomFieldNoteField(customField: IntakeCustomFieldWidgetData | null) {
  return customField?.fieldType === CustomFieldType.NOTE;
}

export function filteredDropdownOptions<T extends { id: number }>(
  selectedId: number | undefined,
  selectedPicklistIds: Array<number>,
  options: ReadonlyArray<T>
) {
  return options.filter((option) => {
    return option.id === selectedId || !selectedPicklistIds.includes(option.id);
  });
}
