import {
  checkDoneDateCellClass,
  checkValidDateCellClass,
  checkValidNameCellClass,
} from 'features/common/data_grid/add_edit_grid/helpers/class_name_helpers';
import { AddEditGridRow } from 'features/common/data_grid/types';

export const checkValidNameEditCellClass = ({ name }: AddEditGridRow) => {
  return checkValidNameCellClass(name);
};

export const checkValidDateEditCellClass = ({ targetStart, targetFinish }: AddEditGridRow) => {
  return checkValidDateCellClass(targetStart, targetFinish);
};

export const checkDoneDateEditCellClass = ({ doneDate }: AddEditGridRow) => {
  return checkDoneDateCellClass(doneDate);
};
