import { useSelector } from 'react-redux';

import { getIterationForId } from 'redux/entities/selectors/iterations';

export const ChangeCardIteration = ({ iterationId }: { iterationId: number }) => {
  const iteration = useSelector((state) => getIterationForId(state, iterationId));
  if (!iteration) {
    return <>None</>;
  }

  return <>{iteration.name ?? 'Unknown'}</>;
};
