import { createCachedSelector } from 're-reselect';

import { WorkspaceUser } from 'daos/model_types';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { createCacheByIdConfig, getNumberArgument } from 'redux/entities/selectors/shared';
import { getWorkspaceUsersByUserId } from 'redux/entities/selectors/user';

type WorkspaceUserByWorkspaceId = Record<number, { workspaceId: number; wsUser: WorkspaceUser }>;

export interface WorkspaceInformationForInvitationUser {
  readonly workspaceUsersByWorkspaceId: WorkspaceUserByWorkspaceId;
  readonly isDisconnected: boolean;
  readonly wsUserCount: number;
}

export const getWorkspaceInformationForInvitationUser = createCachedSelector(
  getWorkspaceUsersByUserId,
  getCurrentOrganizationId,
  getNumberArgument,
  (workspaceUsersByUserId, organizationId, userId) => {
    const workspaceUsers = workspaceUsersByUserId[userId];

    const workspaceUsersByWorkspaceId: WorkspaceUserByWorkspaceId = {};
    let isDisconnected = false;
    let wsUserCount = 0;
    let disconnectedCount = 0;

    if (!workspaceUsers) {
      return { isDisconnected, wsUserCount, workspaceUsersByWorkspaceId } as WorkspaceInformationForInvitationUser;
    }

    workspaceUsers.forEach((wsUser) => {
      if (wsUser.organization.id === organizationId) {
        wsUserCount += 1;

        if (wsUser.disconnectedAt) {
          disconnectedCount += 1;
        }

        workspaceUsersByWorkspaceId[wsUser.workspace.id] = { workspaceId: wsUser.workspace.id, wsUser: wsUser };
      }
    });

    if (wsUserCount === disconnectedCount) {
      isDisconnected = true;
    }

    return { isDisconnected, wsUserCount, workspaceUsersByWorkspaceId } as WorkspaceInformationForInvitationUser;
  }
)(createCacheByIdConfig());
