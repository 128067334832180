import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit';

export const resetRootState = createAction(
  'root/RESET_ROOT_STATE',
  ({ setCurrentUserIdNull }: { setCurrentUserIdNull?: boolean } = {}) => ({ payload: setCurrentUserIdNull })
);

export function resetStateExtraReducer<T>(initialState: T) {
  return function extraReducerBuilder(builder: ActionReducerMapBuilder<T>) {
    builder.addCase(resetRootState, () => initialState);
  };
}
