import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStateExtraReducer } from 'redux/root_actions';

interface LibraryState {
  readonly dashboardId?: number;
  readonly libraryResourceId?: number;
}

export const initialState: LibraryState = {};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setDashboardId: (state, action: PayloadAction<number | undefined>) => {
      state.dashboardId = action.payload;
    },
    setLibraryResourceId: (state, action: PayloadAction<number | undefined>) => {
      state.libraryResourceId = action.payload;
    },
  },
  extraReducers: resetStateExtraReducer(initialState),
});

export const { setDashboardId, setLibraryResourceId } = librarySlice.actions;

export default librarySlice.reducer;
