import classNames from 'classnames';
import { CSSProperties } from 'react';

import { LpIcon, backspaceSolid, pauseSolid } from 'features/common/lp_icon';
import { ScheduleBarStyles } from 'features/common/schedule_bar/types';

const ClippedIcon = ({
  effectiveTargetFinish,
  targetFinish,
  style,
}: {
  effectiveTargetFinish: string;
  targetFinish: string;
  style: CSSProperties;
}) => {
  let clippedIconClassName = '';

  const hasOnlyInheritedTargetFinish = !!effectiveTargetFinish && !targetFinish;
  const inheritedTargetFinishIsEarlierThanTargetFinish =
    !!effectiveTargetFinish && !!targetFinish && effectiveTargetFinish < targetFinish;

  if (hasOnlyInheritedTargetFinish || inheritedTargetFinishIsEarlierThanTargetFinish) {
    clippedIconClassName = 'clipped-effort--inherited';
  } else if (!targetFinish) {
    clippedIconClassName = 'clipped-effort--no-finish';
  }

  return (
    <LpIcon
      className={classNames('lp-schedule__bar-element', 'clipped-effort', clippedIconClassName)}
      icon={backspaceSolid}
      style={style}
    />
  );
};

const ScheduleBarExpectedRange = ({
  isAncestorItemScheduleBar,
  isDoneLate,
  isExpectedStartAfterEffectiveTargetFinish,
  isIncomplete,
  isLate,
  isLateRisk,
  scheduleBarStyles,
  showPredecessor,
  showSuccessor,
}: {
  isAncestorItemScheduleBar: boolean;
  isDoneLate: boolean;
  isExpectedStartAfterEffectiveTargetFinish: boolean;
  isIncomplete: boolean;
  isLate: boolean;
  isLateRisk: boolean;
  scheduleBarStyles: ScheduleBarStyles;
  showPredecessor: boolean;
  showSuccessor: boolean;
}) => {
  return (
    <>
      <div className={'lp-schedule__bar-element lp-schedule__bar-asap'} style={scheduleBarStyles.scheduledAsapRange} />
      <div
        className={classNames(
          'lp-schedule__bar-element',
          'lp-schedule__bar-range',
          isAncestorItemScheduleBar && 'lp-schedule__bar-range--ancestor',
          isIncomplete && 'lp-schedule__bar-range--not-complete',
          showPredecessor && 'lp-schedule__bar-element--predecessor',
          showPredecessor &&
            isLate &&
            isExpectedStartAfterEffectiveTargetFinish &&
            'lp-schedule__bar-element--predecessor-late',
          showPredecessor && isAncestorItemScheduleBar && 'lp-schedule__bar-element--predecessor-ancestor',
          showSuccessor && 'lp-schedule__bar-element--successor',
          showSuccessor && (isLate || isLateRisk || isDoneLate) && 'lp-schedule__bar-element--successor-late',
          showSuccessor && isAncestorItemScheduleBar && 'lp-schedule__bar-element--successor-ancestor'
        )}
        style={scheduleBarStyles.scheduledRange}
      />
      <div className="lp-schedule__bar-element lp-schedule__bar-late" style={scheduleBarStyles.scheduledLateRange} />
      <div className="lp-schedule__bar-element lp-schedule__bar-risk" style={scheduleBarStyles.scheduledRiskRange}>
        <div className="lp-schedule__bar-risk-inlay" />
      </div>
    </>
  );
};

export const Bar = ({
  effectiveTargetFinish,
  isAncestorItemScheduleBar,
  isClipped,
  isDoneLate,
  isEffectivelyOnHold,
  isExpectedStartAfterEffectiveTargetFinish,
  isIncomplete,
  isLate,
  isLateRisk,
  isScheduled,
  scheduleBarStyles,
  showEffectiveStartShadow,
  showOnHoldIcon,
  showPredecessor,
  showRollupDates,
  showSuccessor,
  targetFinish,
}: {
  effectiveTargetFinish: string | null | undefined;
  isAncestorItemScheduleBar: boolean;
  isClipped: boolean;
  isDoneLate: boolean;
  isEffectivelyOnHold: boolean;
  isExpectedStartAfterEffectiveTargetFinish: boolean;
  isIncomplete: boolean;
  isLate: boolean;
  isLateRisk: boolean;
  isScheduled: boolean;
  scheduleBarStyles: ScheduleBarStyles;
  showEffectiveStartShadow: boolean;
  showOnHoldIcon: boolean;
  showPredecessor: boolean;
  showRollupDates: boolean;
  showSuccessor: boolean;
  targetFinish: string | null;
}) => (
  <>
    <div
      className={classNames('lp-schedule__bar-element', 'lp-schedule__bar-target-line')}
      style={scheduleBarStyles.targetDateRange}
    />

    <div
      className={classNames('lp-schedule__bar-element', 'lp-schedule__bar-effective-line')}
      style={scheduleBarStyles.effectiveDateRange}
    />

    {showEffectiveStartShadow && (
      <div
        className={classNames('lp-schedule__bar-element', 'lp-schedule__bar-effective-line--start-shadow')}
        style={scheduleBarStyles.effectiveDateRange}
      />
    )}

    {isScheduled && !isEffectivelyOnHold && (
      <ScheduleBarExpectedRange
        isAncestorItemScheduleBar={isAncestorItemScheduleBar}
        isDoneLate={isDoneLate}
        isExpectedStartAfterEffectiveTargetFinish={isExpectedStartAfterEffectiveTargetFinish}
        isIncomplete={isIncomplete}
        isLate={isLate}
        isLateRisk={isLateRisk}
        scheduleBarStyles={scheduleBarStyles}
        showPredecessor={showPredecessor}
        showSuccessor={showSuccessor}
      />
    )}

    {showRollupDates && (
      <div
        className={classNames(
          'lp-schedule__bar-element',
          'lp-schedule__bar-target-finish',
          'lp-schedule__bar-target-finish--rollup'
        )}
        style={scheduleBarStyles.rollupEarliestActiveFinishTick}
      />
    )}

    <div
      className="lp-schedule__bar-element lp-schedule__bar-target-finish"
      style={scheduleBarStyles.targetFinishTick}
    />

    {showRollupDates && (
      <div
        className="lp-schedule__bar-element lp-schedule__bar-target-finish lp-schedule__bar-target-finish--rollup"
        style={scheduleBarStyles.rollupLatestTargetFinishTick}
      />
    )}

    {isClipped && (
      <ClippedIcon
        style={scheduleBarStyles.clippedEffortIcon}
        effectiveTargetFinish={effectiveTargetFinish ?? ''}
        targetFinish={targetFinish ?? ''}
      />
    )}

    {showOnHoldIcon && (
      <div
        className={classNames(
          'lp-schedule__bar-element',
          'on-hold',
          showEffectiveStartShadow && 'start-shadow',
          isAncestorItemScheduleBar && 'lp-schedule__bar-element--ancestor',
          showSuccessor && 'lp-schedule__bar-element--successor',
          showSuccessor && isAncestorItemScheduleBar && 'lp-schedule__bar-element--successor-ancestor'
        )}
        style={scheduleBarStyles.onHoldIcon}
      >
        <LpIcon icon={pauseSolid} />
      </div>
    )}
  </>
);
