import { useFormikContext } from 'formik';
import { List, Radio, Table } from 'semantic-ui-react';

import { TaskPushSetting } from 'daos/external_integration_enums';
import { JiraProjectModalTableDeleteCell } from 'features/jira_project/modal/common/jira_project_modal_table_delete_cell';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  TaskPushSettingValue,
} from 'features/jira_project/modal/types';

export const PushSettingRow = () => {
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const { taskPushSetting } = values;
  const handleResetPushSetting = () => {
    setFieldValue(JiraProjectModalFormFields.TaskPushSetting, TaskPushSetting.Off);
  };

  const taskPushSettingValues: Array<TaskPushSettingValue> = [
    {
      id: TaskPushSetting.Off,
      name: 'No Push',
      description: 'Disable the ability to push new tasks to Jira.',
    },
    {
      id: TaskPushSetting.Manual,
      name: 'Manual Push',
      description: 'Select new tasks and push them to Jira manually.',
    },
  ];

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content="Push New Tasks to Jira" />
      <Table.Cell>
        <List className="jira-project-modal__content-sync-settings-push-setting">
          {taskPushSettingValues.map((option) => (
            <List.Item key={option.id} className="jira-project-modal__content-sync-settings-push-setting-option">
              <TaskPushSettingRadioButton
                option={option}
                taskPushSetting={taskPushSetting}
                setFieldValue={setFieldValue}
              />
              <div>
                <b>{option.name}</b>:
                <span className="jira-project-modal__content-sync-settings-push-setting-description-text">
                  {option.description}
                </span>
              </div>
            </List.Item>
          ))}
        </List>
      </Table.Cell>
      <JiraProjectModalTableDeleteCell
        onClick={handleResetPushSetting}
        enabled={taskPushSetting !== TaskPushSetting.Off}
      />
    </JiraProjectModalTableRow>
  );
};

const TaskPushSettingRadioButton = ({
  option,
  taskPushSetting,
  setFieldValue,
}: {
  option: TaskPushSettingValue;
  taskPushSetting: TaskPushSetting;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const handleChange = () => setFieldValue(JiraProjectModalFormFields.TaskPushSetting, option.id);

  return <Radio onChange={handleChange} value={option.name} checked={taskPushSetting === option.id} />;
};
