import classNames from 'classnames';

import { CustomFieldType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { validEfforts, validWorkLimit } from 'features/common/data_grid/add_edit_grid/helpers/validation_helpers';
import { isValidDateRange } from 'lib/helpers/date_valid';

export const ADD_EDIT_DROPDOWN_CLASS = 'lp-add-edit-data-grid__dropdown';
export const ADD_EDIT_CHECKBOX_CLASS = 'lp-add-edit-data-grid__checkbox';
export const ADD_EDIT_ASSIGNED_TO_CLASS = 'lp-add-edit-data-grid__assigned-to';

const INVALID_CELL_CLASS = 'invalid-cell';
const DISABLED_CELL_CLASS = 'disabled-cell';
const DAY_PICKER_CLASS = 'day-picker-cell';

const customFieldDropDowns = [CustomFieldType.DATE, CustomFieldType.PICKLIST, CustomFieldType.USER];

export const checkValidNameCellClass = (name: string | undefined) => {
  if (!name || !name.trim().length) {
    return INVALID_CELL_CLASS;
  }
};

export const checkValidDateCellClass = (targetStart: string | undefined, targetFinish: string | undefined) => {
  const isDateInvalid = !!targetStart && !!targetFinish && !isValidDateRange(targetStart, targetFinish);

  return classNames(ADD_EDIT_DROPDOWN_CLASS, DAY_PICKER_CLASS, {
    [INVALID_CELL_CLASS]: isDateInvalid,
  });
};

export const checkDoneDateCellClass = (doneDate: string | undefined) => {
  return classNames(ADD_EDIT_DROPDOWN_CLASS, DAY_PICKER_CLASS, !doneDate && DISABLED_CELL_CLASS);
};

export const checkValidEffortCellClass = (row: {
  highEffortHours?: string | number;
  lowEffortHours?: string | number;
}) => {
  if (!validEfforts(row)) {
    return INVALID_CELL_CLASS;
  }
};

export const checkValidWorkLimitCellClass = (workLimitString: string | number) => {
  const workLimitNumber = Number(workLimitString);
  if (!validWorkLimit(workLimitNumber)) {
    return INVALID_CELL_CLASS;
  }
};

export const customFieldCellClass = (customColumn: CustomField) => {
  return classNames({
    [ADD_EDIT_DROPDOWN_CLASS]: customFieldDropDowns.includes(customColumn.fieldType),
    [DAY_PICKER_CLASS]: customColumn.fieldType === CustomFieldType.DATE,
  });
};
