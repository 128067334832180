import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';

import { LpIcon, paperPlaneLight } from 'features/common/lp_icon';

export enum CopyLinkText {
  Copy = 'Copy Link',
  CopyLinkToThisWeek = 'Copy Link to this Week',
  CopyMultiple = 'Copy Links',
  Copied = 'Link Copied!!',
  CopiedMultiple = 'Links Copied!!',
  Failed = 'Could Not Copy Link',
  FailedMultiple = 'Could Not Copy Links',
}

function getInitialCopyLinkText(customHoverText: CopyLinkText | undefined, multipleItems: boolean) {
  if (customHoverText) {
    return customHoverText;
  }
  return multipleItems ? CopyLinkText.CopyMultiple : CopyLinkText.Copy;
}

export const PortableLinkButton = ({
  copyLinkAction,
  showText = false,
  onEllipsisMenuCopySuccess,
  isGridView = false,
  primary = false,
  isMultipleSelection,
  customHoverText,
}: {
  isMultipleSelection: boolean;
  copyLinkAction: () => boolean;
  showText?: boolean;
  onEllipsisMenuCopySuccess?: () => void;
  isGridView?: boolean;
  primary?: boolean;
  customHoverText?: CopyLinkText;
}) => {
  const [copyLinkText, setCopyLinkText] = useState(getInitialCopyLinkText(customHoverText, isMultipleSelection));

  useEffect(() => {
    setCopyLinkText(getInitialCopyLinkText(customHoverText, isMultipleSelection));
  }, [customHoverText, isMultipleSelection, setCopyLinkText]);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const openPopup = () => setIsPopUpOpen(true);
  const closePopup = useCallback(() => {
    if (
      copyLinkText === CopyLinkText.Copy ||
      copyLinkText === CopyLinkText.CopyMultiple ||
      (!!copyLinkText && copyLinkText === customHoverText)
    ) {
      setCopyLinkText(copyLinkText);
      setIsPopUpOpen(false);
    }
  }, [copyLinkText, customHoverText]);

  const afterLinkClick = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const handleCopyLink = () => {
    const copyUrl = copyLinkAction();

    if (!isGridView) {
      if (afterLinkClick.current) {
        clearTimeout(afterLinkClick.current);
      }

      if (copyUrl) {
        setCopyLinkText(isMultipleSelection ? CopyLinkText.CopiedMultiple : CopyLinkText.Copied);
      } else {
        setCopyLinkText(isMultipleSelection ? CopyLinkText.FailedMultiple : CopyLinkText.Failed);
      }

      afterLinkClick.current = setTimeout(() => closePopup(), 1000);
    }
  };

  if (onEllipsisMenuCopySuccess || showText) {
    return (
      <div className="item-link-button" onClick={handleCopyLink}>
        <LpIcon className="lp-icon" icon={paperPlaneLight} /> {copyLinkText}
      </div>
    );
  }

  return (
    <span onMouseEnter={openPopup} onMouseLeave={closePopup}>
      <Popup
        transition="false"
        trigger={
          <Button primary={primary} icon className="item-link-button" size="small" onClick={handleCopyLink}>
            <LpIcon className="lp-icon" icon={paperPlaneLight} />
          </Button>
        }
        content={copyLinkText}
        open={isPopUpOpen}
        basic
        position={'bottom center'}
        size="tiny"
      />
    </span>
  );
};
