import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel } from './shared';
import { backend } from './urls';

const { resource } = defineModel({
  apiType: 'itemEffectiveFieldValues',
  relationships: [],
  type: 'ITEM_EFFECTIVE_FIELD_VALUES',
});

interface ItemEffectiveFieldValuesParams {
  organizationId: string | number;
  workspaceId: string | number;
}

interface ItemEffectiveFieldValueOptions {
  filter?: string;
}

const { ITEM_EFFECTIVE_FIELD_VALUES, resourceId } = resource;

const fetchAll = (params: ItemEffectiveFieldValuesParams, { filter }: ItemEffectiveFieldValueOptions = {}) =>
  request(
    backend.itemEffectiveFieldValues.url(params, { filter, include: ['fieldValues', 'fieldValues.item'] }),
    ITEM_EFFECTIVE_FIELD_VALUES,
    {
      method: HttpMethod.GET,
    }
  );

export const ItemEffectiveFieldValuesDao = {
  fetchAll,
  id: resourceId,
} as const;
