import { useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { LpIcon, plusSolid } from 'features/common/lp_icon';
import { OrgUsersQuickFilter, OrgUsersQuickFilterType } from 'features/organization_directory/users/quick_filter';
import { frontend } from 'lib/urls';

export const ActionsSection = ({ filterType, showAdd }: { filterType: OrgUsersQuickFilterType; showAdd: boolean }) => {
  const organizationId = useSelector(getCurrentOrganizationId);

  return (
    <Menu borderless secondary className="organization-directory__actions-section">
      <Menu.Item fitted>
        <OrgUsersQuickFilter filterType={filterType} />
      </Menu.Item>
      {showAdd && (
        <Menu.Item fitted>
          <LpButtonAsLink
            className="organization-directory__actions-section-add-user-button"
            to={frontend.organizationHubUsersInvite.url({ organizationId })}
            e2eTestId={'invite-add-user'}
          >
            <LpIcon icon={plusSolid} />
            User
          </LpButtonAsLink>
        </Menu.Item>
      )}
    </Menu>
  );
};
