import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import RemoveSoleFieldValueCell, {
  RemoveSoleFieldValueCellSize,
} from 'features/common/custom_fields/custom_field_value/remove_sole_field_value_cell';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { OPTIONAL } from 'lib/constants';
import { blurElementOnEnterKey } from 'lib/helpers/blur_element_on_key_press';
import { getFieldValueForId } from 'redux/entities/selectors/custom_field';

const CustomFieldTextValue = ({
  adding,
  allowMultipleValues,
  fieldValueId,
  fieldId,
  resourceId,
  disabled = false,
}: CustomFieldValueProps & {
  adding: boolean;
  fieldValueId?: number;
  allowMultipleValues: boolean;
  disabled?: boolean;
}) => {
  const fieldValue = useSelector((state) => (fieldValueId ? getFieldValueForId(state, fieldValueId) : undefined));
  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);

  const [content, setContent] = useState(fieldValue?.text ?? '');

  const handleChange = ({ currentTarget: { value } }: { currentTarget: { value: string } }) => {
    setContent(value);
  };

  const handleSubmit = () => {
    if (content === '' && fieldValue) {
      removeFieldValue(resourceId.id, fieldValue.id);
    } else {
      const payload = { text: content };
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else if (content !== '') {
        createFieldValue(resourceId.id, fieldId, payload);
      }
    }

    if (adding) {
      setContent('');
    }
  };

  return (
    <span className="text-area">
      <Input
        className={classNames('l_grow', `lp-custom-field-text__${fieldValueId ?? ''}${fieldId}`)}
        onChange={handleChange}
        onBlur={handleSubmit}
        onKeyDown={blurElementOnEnterKey}
        placeholder={allowMultipleValues ? 'Add' : OPTIONAL}
        value={content}
        type="text"
        disabled={disabled}
      />

      {allowMultipleValues && fieldValueId && (
        <RemoveSoleFieldValueCell
          className="delete-icon"
          size={RemoveSoleFieldValueCellSize.Button}
          valueIds={[fieldValueId]}
          disabled={disabled}
        />
      )}
    </span>
  );
};

export default CustomFieldTextValue;
