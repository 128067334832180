import { ItemMetrics } from 'daos/model_types';

export const getUncertainWork = (itemMetrics?: ItemMetrics) => {
  return itemMetrics ? Math.max(0, itemMetrics.highRemainingWork - itemMetrics.lowRemainingWork) : 0;
};

function getAverage(low: number, high: number) {
  return (low + high) / 2;
}

export const getRemainingWorkExpected = (itemMetrics?: ItemMetrics) => {
  return itemMetrics ? getAverage(itemMetrics.lowRemainingWork, itemMetrics.highRemainingWork) : 0;
};

export const getOnHoldHours = (itemMetrics?: ItemMetrics) => {
  return itemMetrics ? getAverage(itemMetrics.lowOnHoldWork, itemMetrics.highOnHoldWork) : 0;
};

export const getTotalWorkExpected = (itemMetrics?: ItemMetrics) => {
  if (!itemMetrics) {
    return 0;
  }

  const logged = itemMetrics.loggedWork;
  const remainingWorkExpected = getRemainingWorkExpected(itemMetrics);

  return remainingWorkExpected + logged;
};

export const getPercentComplete = (amount: number, total: number) =>
  !amount || !total ? 0 : Math.round((amount / total) * 100);

export const getStoryPointMetricDisplay = ({
  totalStoryPointMetric,
  storyPointMetric,
}: {
  totalStoryPointMetric: number | null | undefined;
  storyPointMetric: number | null | undefined;
}) => {
  const hasAssignedStoryPoints = totalStoryPointMetric !== null;
  const storyPointValue = storyPointMetric ?? '';

  return hasAssignedStoryPoints ? storyPointValue : 'None';
};
