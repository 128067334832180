import { groupBy, map, reduce, sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { FieldValueColumn } from 'containers/shared/custom_column/types';
import { isMultipleValueField } from 'daos/custom_field';
import { CustomFieldType, ItemType } from 'daos/enums';
import { CustomField, CustomFieldValue, Item, PicklistChoice, ResourceId, WorkspaceUser } from 'daos/model_types';
import { IndexModel } from 'daos/shared';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getFieldTypeValue } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { ItemPanelSections } from 'features/item_panel/sections/enums';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { readonlyArray, readonlyRecord, ReadonlyRecord } from 'lib/readonly_record';
import { getItemForId } from 'redux/entities/selectors/item';
import { getItemEffectiveFieldValuesByItemId } from 'redux/entities/selectors/item_effective_field_values';
import { RootState } from 'redux/root_reducer';

import { createCacheByFirstTwoParametersConfig, createCacheByIdConfig, getNumberArgument } from './shared';
import {
  getCurrentOrganizationUser,
  getCurrentWsUserToOrgUserMapping,
  getOrganizationUserForId,
  getOrganizationUserIdForWorkspaceUserId,
} from './user';

export type FieldWithValueAndChoices = CustomField & {
  allowsMultipleValues: boolean;
  picklistChoices?: Array<PicklistChoice>;
  values?: Array<CustomFieldValue>;
};

type FieldSource = (state: RootState, ...rest: Array<any>) => IndexModel<CustomField | FieldWithValueAndChoices>;

type FieldsAndSortedIds = IndexModel<CustomField> & {
  idsByName: Array<number>;
};

type FieldsAndSortedIdsByPriority = IndexModel<CustomField> & {
  idsByPriority: Array<number>;
};

export const getFieldsById = (state: RootState) => state.entities.fields;

const getFieldsByFieldType = (state: RootState, fieldType: CustomFieldType) =>
  Object.values(getFieldsById(state)).filter((field) => field.fieldType === fieldType);

export const getCustomFieldForId = (state: RootState, fieldId: number) => getFieldsById(state)[fieldId];

const getFieldValuesById = (state: RootState) => state.entities.fieldValues;

export const getPicklistChoicesById = (state: RootState) => state.entities.picklistChoices;

export const getPicklistChoiceForId = (state: RootState, id: number) => getPicklistChoicesById(state)[id];

const getFieldsByWorkspaceAndId = createSelector(
  getFieldsById,
  (fieldsById) =>
    reduce(
      fieldsById,
      (obj: Record<number, Record<number, CustomField>>, field: CustomField) => {
        const wsId = field?.workspace.id;

        if (!wsId) {
          return obj;
        }

        const fieldsForWorkspace = obj[wsId] || {};
        fieldsForWorkspace[field.id] = field;
        obj[wsId] = fieldsForWorkspace;

        return obj;
      },
      {}
    ) as ReadonlyRecord<number, ReadonlyRecord<number, CustomField>>
);

const getCurrentWorkspaceFieldsById = createSelector(
  getFieldsByWorkspaceAndId,
  getCurrentWorkspaceId,
  (fieldsByWorkspaceAndId, workspaceId) => fieldsByWorkspaceAndId[workspaceId] || {}
);

export const getCurrentWorkspaceFieldsSortedByPriority = createSelector(getCurrentWorkspaceFieldsById, (fieldsById) => {
  const fields = Object.values(fieldsById);
  fields.sort(compareByPriority);
  return readonlyArray(fields);
});

const getSortedCustomFields = (fieldSource: FieldSource) =>
  createSelector(fieldSource, (fields) => {
    const sorted: FieldsAndSortedIds = {
      ...fields,
      idsByName: map(
        sortBy(Object.values(fields), (e: any) => e.name),
        'id'
      ),
    };

    return sorted;
  });

const getAllCustomFields = getSortedCustomFields(getCurrentWorkspaceFieldsById);

export const getCustomFieldById = createCachedSelector(
  getAllCustomFields,
  getNumberArgument,
  (projectFields, id: number) => projectFields[id]
)(createCacheByIdConfig());

export const getFieldValueForId = ({ entities }: RootState, id: number) => entities.fieldValues[id];

export const getFieldValuesForIds = (state: RootState, valueIds: Array<number>) => {
  return valueIds.map((id) => getFieldValueForId(state, id));
};

const getCustomFieldsAndValuesIndexedByRelation = createCachedSelector(
  getFieldValuesById,
  (_: RootState, relation: 'item' | 'workspaceUser') => relation,
  (fieldValues, relation) => groupBy(fieldValues, (e) => e[relation]?.id)
)(createCacheByIdConfig());

const getCustomFieldsAndValuesIndexedByResourceId = (
  state: RootState,
  resourceId: ResourceId<Item | WorkspaceUser>
) => {
  switch (resourceId.type) {
    case 'items':
      return getCustomFieldsAndValuesIndexedByRelation(state, 'item');
    case 'workspaceUsers':
      return getCustomFieldsAndValuesIndexedByRelation(state, 'workspaceUser');
    default:
      throw new Error('Unknown type ' + resourceId.type);
  }
};

const fieldUsedOnResource = (
  field: CustomField,
  resource: Item | WorkspaceUser | { type: ItemType },
  includeInherited = false
) => {
  const resourceType = resource.type === 'items' ? resource.itemType : resource.type;
  switch (resourceType) {
    case ItemType.FOLDERS:
    case ItemType.PROJECTS:
      return field.onProjectFolders || (includeInherited && field.inheritable && field.onPackages);
    case ItemType.TASKS:
      return field.onTasks || (includeInherited && field.inheritable);
    case ItemType.PACKAGES:
      return field.onPackages;
    case 'workspaceUsers':
      return field.onUsers;
    default:
      return true;
  }
};

const getPicklistChoicesIndexedByFieldId = createSelector(getPicklistChoicesById, (picklistChoices) =>
  groupBy(picklistChoices, (e) => e.field.id)
);

export const getFieldsAndValuesForResourceId = createSelector(
  getAllCustomFields,
  getCustomFieldsAndValuesIndexedByResourceId,
  getPicklistChoicesIndexedByFieldId,
  (state: RootState, resource: ResourceId<Item | WorkspaceUser>) => state.entities[resource.type][resource.id],
  (customFields, customFieldValuesByResourceId, choicesByFieldId, resource) => {
    const valuesByFieldId = (resource && groupBy(customFieldValuesByResourceId[resource.id], (e) => e.field.id)) || {};

    const { idsByName, ...fieldsById } = customFields;

    return reduce(
      fieldsById,
      (res, field: CustomField, key) => {
        if (!field.id) {
          return res;
        }

        const hasValues = valuesByFieldId[field.id];

        if (!hasValues && resource && (field.archived || !fieldUsedOnResource(field, resource))) {
          return res;
        }

        const fieldAndValues: FieldWithValueAndChoices = {
          ...field,
          allowsMultipleValues: isMultipleValueField(field),
          picklistChoices: choicesByFieldId[field.id] || [],
          values: hasValues || [],
        };

        res[key] = fieldAndValues;

        return res;
      },
      {} as Record<string, FieldWithValueAndChoices>
    ) as ReadonlyRecord<string, FieldWithValueAndChoices>;
  }
);

export const getEffectiveFieldsAndValuesForResourceId = createSelector(
  getAllCustomFields,
  getPicklistChoicesIndexedByFieldId,
  getFieldValuesById,
  getItemEffectiveFieldValuesByItemId,
  (state: RootState, resource: ResourceId<Item>) => state.entities[resource.type][resource.id],
  (customFields, choicesByFieldId, fieldValuesById, effectiveFieldValuesByItemId, resource) => {
    const itemEffectiveFieldValues = effectiveFieldValuesByItemId[resource?.id ?? 0];
    const sourceItemsByFieldId = itemEffectiveFieldValues?.sourceItemsByFieldId;
    const { idsByName, ...fieldsById } = customFields;

    return reduce(
      fieldsById,
      (res, field: CustomField, key) => {
        if (!field.id) {
          return res;
        }

        const sourceItemRecord = sourceItemsByFieldId && sourceItemsByFieldId[field.id];
        const values = sourceItemRecord
          ?.map((sourceItem) => fieldValuesById[sourceItem.fieldValueId])
          ?.filter((fieldValue): fieldValue is CustomFieldValue => !!fieldValue);
        values?.sort((a, b) => a.id - b.id);

        if (!values && resource && (field.archived || !fieldUsedOnResource(field, resource, true))) {
          return res;
        }

        const fieldAndValues: FieldWithValueAndChoices = {
          ...field,
          allowsMultipleValues: isMultipleValueField(field),
          picklistChoices: choicesByFieldId[field.id] || [],
          values,
        };

        res[key] = fieldAndValues;
        return res;
      },
      {} as Record<string, FieldWithValueAndChoices>
    ) as ReadonlyRecord<string, FieldWithValueAndChoices>;
  }
);

const getEffectiveFieldAndValuesForItemResourceAndFieldId = createCachedSelector(
  getEffectiveFieldsAndValuesForResourceId,
  (_: RootState, __: ResourceId<Item>, fieldId: number) => fieldId,
  (contentByFieldId, fieldId) => {
    return contentByFieldId[fieldId];
  }
)(createCacheByFirstTwoParametersConfig());

export const getFieldValueColumnProps = createSelector(
  getEffectiveFieldAndValuesForItemResourceAndFieldId,
  (state: RootState) => state,
  (_: RootState, itemResource: ResourceId<Item>) => itemResource,
  (fieldContent, state, itemResource) => {
    const values: Array<FieldValueColumn> = [];
    if (fieldContent) {
      const currentOrgUser = getCurrentOrganizationUser(state);
      const numberFormat = currentOrgUser?.numberFormat;
      const fieldContentValues = fieldContent.values ?? [];

      fieldContentValues.forEach((value) => {
        const itemName = value.itemValue ? getItemForId(state, value.itemValue.id)?.name : undefined;
        const picklistChoice = value.picklistChoice
          ? getPicklistChoiceForId(state, value.picklistChoice.id)
          : undefined;
        const orgUserId = value.workspaceUserValue
          ? getOrganizationUserIdForWorkspaceUserId(state, value.workspaceUserValue.id)
          : undefined;
        const orgUser = getOrganizationUserForId(state, orgUserId ?? 0);

        const props: FieldValueColumn = {
          checked: value.checked ?? undefined,
          currency: value.currency ?? undefined,
          currencyUnit: fieldContent.currencyUnit ?? undefined,
          date: value.date ?? undefined,
          isInherited: itemResource.id !== value.item?.id,
          itemName: itemName ?? undefined,
          number: value.number ?? undefined,
          numberFormat,
          picklistChoiceColor: picklistChoice?.color ?? undefined,
          picklistChoiceName: picklistChoice?.name,
          text: value.text ?? undefined,
          url: value.url ?? undefined,
          username: orgUser?.username,
        };

        values.push(props);
      });
    }

    return readonlyArray(values);
  }
);

const getFieldsAndValuesSortedByPriorityForResourceId = createCachedSelector(
  (state: RootState, resource: ResourceId<Item | WorkspaceUser>) => getFieldsAndValuesForResourceId(state, resource),
  (fields) => {
    const prioritizedFields = Object.values(fields).sort(compareByPriority);
    const sorted: FieldsAndSortedIdsByPriority = {
      ...fields,
      idsByPriority: prioritizedFields.map((field) => field.id),
    };

    return sorted;
  }
)(createCacheByIdConfig());

export const getFieldsUsedOnUserSortedByPriorityForWsUserRescourceId = createCachedSelector(
  getFieldsAndValuesForResourceId,
  (fields) =>
    Object.values(fields)
      .filter((o) => o.onUsers || !!o.values?.length)
      .sort(compareByPriority)
)(createCacheByIdConfig());

const specialCustomFields = new Set([CustomFieldType.NOTE]);
export const customFilterAllowedCustomFields = new Set([
  CustomFieldType.CHECKBOX,
  CustomFieldType.MULTI_PICKLIST,
  CustomFieldType.PICKLIST,
  CustomFieldType.MULTI_USER,
  CustomFieldType.USER,
]);

export const getNonSpecialActiveCustomFieldsForCurrentWorkspace = createSelector(
  getCurrentWorkspaceFieldsSortedByPriority,
  (fields) => {
    return readonlyArray(
      fields.filter((field: CustomField) => !field.archived && !specialCustomFields.has(field.fieldType))
    );
  }
);

export const getActiveCustomFieldsUsedOnProjects = createSelector(getCurrentWorkspaceFieldsSortedByPriority, (fields) =>
  readonlyArray(fields.filter((field) => !field.archived && field.onProjectFolders))
);
export const getActiveCustomFieldsUsedOnTasks = createSelector(getCurrentWorkspaceFieldsSortedByPriority, (fields) =>
  readonlyArray(fields.filter((field) => !field.archived && field.onTasks))
);

export const getActiveNonNoteCustomFieldsUsedOnUsers = createSelector(
  getCurrentWorkspaceFieldsSortedByPriority,
  (fields) =>
    readonlyArray(
      fields.filter((field) => !field.archived && field.onUsers && field.fieldType !== CustomFieldType.NOTE)
    )
);

export const getAllWorkspaceNonNoteCustomFields = createSelector(
  getCurrentWorkspaceFieldsSortedByPriority,
  (fields) => {
    return readonlyArray(fields.filter((field: CustomField) => field.fieldType !== CustomFieldType.NOTE));
  }
);

export const getActiveNoteCustomFieldsForCurrentWorkspace = createSelector(
  getCurrentWorkspaceFieldsSortedByPriority,
  (fields) => {
    return readonlyArray(
      fields.filter((field: CustomField) => !field.archived && field.fieldType == CustomFieldType.NOTE)
    );
  }
);

const getActiveCustomFieldsUsedOnTasksForCurrentWorkspace = createSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (fields) => fields.filter((field) => field.onTasks)
);

const getActiveCustomFieldsUsedOnProjectsForCurrentWorkspace = createSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (fields) => fields.filter((field) => field.onProjectFolders)
);

export const getActiveCustomFieldsUsedOnPackagesAndProjectsForCurrentWorkspace = createSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (fields) => {
    return readonlyArray(fields.filter((field) => field.onPackages || field.onProjectFolders));
  }
);

const getActiveCustomFieldsUsedOnPackagesForCurrentWorkspace = createSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (fields) => {
    return readonlyArray(fields.filter((field) => field.onPackages));
  }
);

export const getActiveWorkspaceCustomFieldsInheritedOrUsedOnProjects = createSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (fields) => {
    return readonlyArray(
      fields.filter((field) => {
        const inheritedFromPackage = field.inheritable && field.onPackages;
        const usedOnProjects = field.onProjectFolders;

        return usedOnProjects || inheritedFromPackage;
      })
    );
  }
);

export const getWorkspaceCustomFieldsInheritedOrUsedOnTasks = createSelector(
  getAllWorkspaceNonNoteCustomFields,
  (fields) => {
    return readonlyArray(
      fields.filter((field) => {
        const inheritedFromPackage = field.inheritable && field.onPackages;
        const inheritedFromProject = field.inheritable && field.onProjectFolders;
        const usedOnTasks = field.onTasks;

        return usedOnTasks || inheritedFromPackage || inheritedFromProject;
      })
    );
  }
);

export const getActiveWorkspaceCustomFieldsInheritedOrUsedOnProjectsOrTasks = createSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (fields) => {
    return readonlyArray(
      fields.filter((field) => {
        const inheritedFromPackage = field.inheritable && field.onPackages;
        const usedOnProjects = field.onProjectFolders;
        const usedOnTasks = field.onTasks;

        return usedOnProjects || usedOnTasks || inheritedFromPackage;
      })
    );
  }
);

interface CustomFieldGroup {
  [ItemPanelSections.PROPERTIES]: Array<FieldWithValueAndChoices>;
  [ItemPanelSections.NOTE]: Array<FieldWithValueAndChoices>;
}

export const getCustomFieldGroups = createSelector(
  (state: RootState, resource: ResourceId<Item | WorkspaceUser>) =>
    getFieldsAndValuesSortedByPriorityForResourceId(state, resource),
  (fields) => {
    return fields.idsByPriority.reduce(
      (acc: CustomFieldGroup, fieldId) => {
        const field = fields[fieldId];

        if (field?.fieldType === CustomFieldType.NOTE) {
          acc[ItemPanelSections.NOTE].push(field as FieldWithValueAndChoices);
          return acc;
        }

        if (field) {
          acc[ItemPanelSections.PROPERTIES].push(field as FieldWithValueAndChoices);
        }
        return acc;
      },
      {
        [ItemPanelSections.PROPERTIES]: [],
        [ItemPanelSections.NOTE]: [],
      }
    );
  }
);

export const getPicklistChoicesSortedByPriorityForFieldId = createSelector(
  getPicklistChoicesIndexedByFieldId,
  getNumberArgument,
  (picklistChoicesByFieldId, fieldId) => {
    const choicesForField = picklistChoicesByFieldId[fieldId] || [];

    return choicesForField.sort(compareByPriority);
  }
);

export const getActivePrioritizedPicklistChoicesForFieldId = createSelector(
  getPicklistChoicesSortedByPriorityForFieldId,
  (picklistChoices) => {
    return readonlyArray(picklistChoices.filter((choice) => !choice.archived));
  }
);

export const getCustomFieldValueForFieldIdAndResourceId = createCachedSelector(
  (state: RootState) => state.entities.fieldValues,
  (_: RootState, fieldId: number) => fieldId,
  (_: RootState, __: number, resource: ResourceId<Item | WorkspaceUser>) => resource,
  (fieldValuesById, fieldId, resource) => {
    const relation = resource.type === 'items' ? 'item' : 'workspaceUser';
    return Object.values(fieldValuesById).find(
      (fieldValue) => fieldValue.field.id === fieldId && fieldValue[relation]?.id === resource.id
    );
  }
)(createCacheByFirstTwoParametersConfig());

const allowedEditGridCustomFields = new Set([
  CustomFieldType.CHECKBOX,
  CustomFieldType.CURRENCY,
  CustomFieldType.DATE,
  CustomFieldType.LINK,
  CustomFieldType.MULTILINE_TEXT,
  CustomFieldType.MULTI_PICKLIST,
  CustomFieldType.MULTI_USER,
  CustomFieldType.NUMERIC,
  CustomFieldType.PICKLIST,
  CustomFieldType.TEXT,
  CustomFieldType.USER,
]);

export const getEditGridCustomFieldValuesByItemId = createSelector(
  (state: RootState) => state.entities.fieldValues,
  (fieldValuesById) => {
    const fieldValues = Object.values(fieldValuesById);
    return readonlyRecord(
      fieldValues.reduce((acc: Record<number, Array<CustomFieldValue>>, fieldValue) => {
        const fieldType = fieldValue.fieldType;
        const itemId = fieldValue.item?.id;

        if (itemId && allowedEditGridCustomFields.has(fieldType)) {
          const itemFieldValues = acc[itemId];
          if (itemFieldValues) {
            itemFieldValues.push(fieldValue);
          } else {
            acc[itemId] = [fieldValue];
          }
        }
        return acc;
      }, {})
    );
  }
);

export interface CustomFieldDataByItemId {
  [key: string]: string | number | boolean;
}

export const getGridViewSortableCustomFieldValuesByItemId = createSelector(
  (state: RootState) => state.entities.fieldValues,
  (state: RootState) => state.entities.picklistChoices,
  (state: RootState) => state.entities.items,
  (state: RootState) => state.entities.organizationUsers,
  (state: RootState) => state.entities.itemEffectiveFieldValues,
  (state: RootState) => getCurrentWsUserToOrgUserMapping(state),
  (_: RootState, items: ReadonlyArray<Item>) => items,
  (
    fieldValuesById,
    picklistChoicesById,
    itemsById,
    orgUsersById,
    itemEffectiveFieldValues,
    orgUserIdsByWsUserId,
    items
  ) => {
    const getFieldValue = (fieldValue: CustomFieldValue | undefined) => {
      if (!fieldValue) {
        return;
      }

      let fieldValueData = getFieldTypeValue(fieldValue) as string | number | boolean;

      if (fieldValue.fieldType === CustomFieldType.LINK || fieldValue.fieldType === CustomFieldType.MULTI_LINK) {
        fieldValueData = fieldValue.text ?? (fieldValue.url || '');
      }

      if (fieldValue.itemValue?.id) {
        const item = itemsById[fieldValue.itemValue.id];

        if (item?.name) {
          fieldValueData = item.name;
        }
      }

      if (fieldValue.picklistChoice?.id) {
        const picklist = picklistChoicesById[fieldValue.picklistChoice.id];

        if (picklist?.name) {
          fieldValueData = picklist.name;
        }
      }

      if (fieldValue.workspaceUserValue?.id) {
        const orgUserId = orgUserIdsByWsUserId[fieldValue.workspaceUserValue.id];
        const orgUser = orgUserId ? orgUsersById[orgUserId] : undefined;

        if (orgUser?.username) {
          fieldValueData = orgUser.username;
        }
      }
      return fieldValueData;
    };

    return items.reduce((acc: Record<number, CustomFieldDataByItemId>, item) => {
      const itemCfValues: CustomFieldDataByItemId = {};
      const effectiveFieldValuesForItem = itemEffectiveFieldValues[item.id]?.sourceItemsByFieldId;

      if (effectiveFieldValuesForItem) {
        Object.entries(effectiveFieldValuesForItem).forEach(([key, fieldValue]) => {
          itemCfValues[key];

          fieldValue.forEach((field) => {
            const inheritedValue = getFieldValue(fieldValuesById[field.fieldValueId]);

            if (inheritedValue) {
              if (itemCfValues[key]) {
                itemCfValues[key] = `${itemCfValues[key]} ${inheritedValue}`;
              } else {
                itemCfValues[key] = inheritedValue;
              }
            }
          });
        });
      }

      acc[item.id] = itemCfValues;

      return acc;
    }, {});
  }
);

const allowedAndPrioritizedCustomFields = (customFields: ReadonlyArray<CustomField>) =>
  readonlyArray(
    customFields.filter((field) => allowedEditGridCustomFields.has(field.fieldType)).sort(compareByPriority)
  );

export const getAddEditGridActiveTaskCustomFieldsSortedByPriority = createSelector(
  getActiveCustomFieldsUsedOnTasksForCurrentWorkspace,
  allowedAndPrioritizedCustomFields
);

export const getEditGridProjectCustomFieldsSortedByPriority = createSelector(
  getActiveCustomFieldsUsedOnProjectsForCurrentWorkspace,
  allowedAndPrioritizedCustomFields
);

export const getEditGridPackageCustomFieldsSortedByPriority = createSelector(
  getActiveCustomFieldsUsedOnPackagesForCurrentWorkspace,
  allowedAndPrioritizedCustomFields
);

const getFieldsByFieldTypeUsedOnTasks = (state: RootState, fieldType: CustomFieldType) =>
  getFieldsByFieldType(state, fieldType).filter((field) => field.onTasks);

export const getFieldsByFieldTypesUsedOnTasks = (state: RootState, fieldTypes: Array<CustomFieldType>) =>
  fieldTypes.flatMap((fieldType) => getFieldsByFieldTypeUsedOnTasks(state, fieldType));

export const supportedJiraMappingCustomFieldTypes: Set<CustomFieldType> = new Set([
  CustomFieldType.DATE,
  CustomFieldType.NUMERIC,
  CustomFieldType.TEXT,
  CustomFieldType.PICKLIST,
  CustomFieldType.MULTI_PICKLIST,
  CustomFieldType.LINK,
]);

export const getNonSystemFieldsByFieldTypesUsedOnTasks = (state: RootState, fieldTypes: Set<CustomFieldType>) => {
  return readonlyArray(
    Object.values(state.entities.fields).filter((field) => fieldTypes.has(field.fieldType) && !field.sourceSystem)
  );
};

export const getCustomFieldsByIdsSorted = (state: RootState, fieldIds: Array<number>) =>
  fieldIds.map((id) => getCustomFieldForId(state, id)).sort(compareByPriority);
