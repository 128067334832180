import { useEffect } from 'react';

import { SourceSystem } from 'daos/external_integration_enums';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { avoClient } from 'lib/avo/client';

const svgPixelSize = 584;
const { segmentKey } = window as any;

const WelcomeSection = () => {
  useEffect(() => {
    if (segmentKey) {
      avoClient?.integrationStarted({
        integrationName: SourceSystem.JIRA,
      });
    }
  }, []);

  return (
    <div className="jira-project-modal__content__welcome">
      <LpSvg icon={LpSvgs.JIRA_SOFTWARE} size={svgPixelSize} />
      <div className="jira-project-modal__content__welcome-text">
        {`You're just a few steps away from integrating LiquidPlanner with Jira.`}
      </div>
    </div>
  );
};

export default WelcomeSection;
