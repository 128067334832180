import { useSelector } from 'react-redux';

import { LpFormikDatePicker } from 'features/common/forms/formik/date';
import { LpFormikInput } from 'features/common/forms/formik/input';
import { LpFormikTextArea } from 'features/common/forms/formik/text_area';
import { folderStatusOptions } from 'features/common/inputs/dropdowns/folder_status_dropdown';
import { targetFinishTypeOptions } from 'features/common/inputs/dropdowns/target_finish_type_dropdown';
import { workTypeOptions } from 'features/common/inputs/dropdowns/work_type_dropdown';
import { colorOptions } from 'features/dashboards_v2/dashboard_editor/settings/color_dropdown';
import { ItemIntakeSettableField } from 'features/dashboards_v2/widget_click_through/intake/enums';
import { WorkdayTimeType } from 'lib/workday_time_type';
import { getActiveCostCodeOptions } from 'redux/entities/selectors/cost_code';

import {
  IntakeWorkLimitInput,
  IntakeTaskStatusDropdown,
  IntakeFormDropdown,
  IntakeScheduleDirectiveDropdown,
  IntakeRateSheetDropdown,
  IntakeFileInput,
} from './inputs';

export function ItemFieldValueInputCell({
  itemField,
  disabled,
  fieldId,
  hasAutoFocus,
  organizationId,
  workspaceId,
}: {
  itemField: ItemIntakeSettableField;
  disabled: boolean;
  fieldId: number;
  hasAutoFocus: boolean;
  organizationId: string;
  workspaceId: string;
}) {
  const costCodeOptions = useSelector(getActiveCostCodeOptions);
  const formikKey = fieldId.toString();

  switch (itemField) {
    case ItemIntakeSettableField.Name:
      return (
        <LpFormikInput
          name={formikKey}
          hideErrorMessage
          restFieldErrorOnChange
          disabled={disabled}
          autoFocus={hasAutoFocus}
        />
      );
    case ItemIntakeSettableField.WorkLimit:
      return <IntakeWorkLimitInput formikKey={formikKey} disabled={disabled} autoFocus={hasAutoFocus} />;
    case ItemIntakeSettableField.Description:
      return (
        <LpFormikTextArea
          name={formikKey}
          hideErrorMessage
          restFieldErrorOnChange
          disabled={disabled}
          autoFocus={hasAutoFocus}
        />
      );
    case ItemIntakeSettableField.TaskStatus:
      return <IntakeTaskStatusDropdown formikKey={formikKey} disabled={disabled} />;
    case ItemIntakeSettableField.Color:
      return <IntakeFormDropdown formikKey={formikKey} options={colorOptions} disabled={disabled} />;
    case ItemIntakeSettableField.WorkType:
      return <IntakeFormDropdown formikKey={formikKey} options={workTypeOptions} disabled={disabled} />;
    case ItemIntakeSettableField.ProjectStatus:
      return <IntakeFormDropdown formikKey={formikKey} options={folderStatusOptions} disabled={disabled} />;
    case ItemIntakeSettableField.TargetFinishType:
      return <IntakeFormDropdown formikKey={formikKey} options={targetFinishTypeOptions} disabled={disabled} />;
    case ItemIntakeSettableField.CostCode:
      return <IntakeFormDropdown formikKey={formikKey} options={costCodeOptions} disabled={disabled} />;

    case ItemIntakeSettableField.TargetFinish:
    case ItemIntakeSettableField.TargetStart: {
      const datePickerWorkdayTimeTypeForFieldKey = {
        [ItemIntakeSettableField.TargetStart]: WorkdayTimeType.WorkDayStart,
        [ItemIntakeSettableField.TargetFinish]: WorkdayTimeType.WorkDayFinish,
      };

      return (
        <LpFormikDatePicker
          workdayTimeType={datePickerWorkdayTimeTypeForFieldKey[itemField]}
          name={formikKey}
          className="intake-widget-user-form-section__field-date"
          hideErrorLabel
          showError
          disabled={disabled}
        />
      );
    }

    case ItemIntakeSettableField.ScheduleDirective:
      return <IntakeScheduleDirectiveDropdown formikKey={formikKey} disabled={disabled} />;
    case ItemIntakeSettableField.RateSheetId:
      return <IntakeRateSheetDropdown formikKey={formikKey} disabled={disabled} />;
    case ItemIntakeSettableField.Files:
      return (
        <IntakeFileInput
          formikKey={formikKey}
          disabled={disabled}
          organizationId={organizationId}
          workspaceId={workspaceId}
        />
      );
    default:
      return null;
  }
}
