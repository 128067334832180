import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'academyTrackLessons',
  relationships: [],
  type: 'ACADEMY_TRACK_LESSON',
});

const { ACADEMY_TRACK_LESSON } = resource;

interface Includes {
  includeCourseId?: boolean;
}

const findByName = (name: string, { includeCourseId }: Includes = {}) =>
  request(
    backend.academyTrackLessons.url(
      { name },
      { include: includeCourseId ? ['academyTrack.academyCourse'] : undefined }
    ),
    ACADEMY_TRACK_LESSON,
    { method: HttpMethod.GET }
  );

export const TrackLessonDao = {
  findByName,
} as const;
