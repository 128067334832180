/**
 * Re-arranges an array. Mainly used for Drag and Drop
 * @param rowData an array of all the data you are moving
 * @param param1 object containing previous index and new index
 */
function reorderRows<T>(rowData: ReadonlyArray<T>, { prevIndex, newIndex }: { prevIndex: number; newIndex: number }) {
  const element = rowData[prevIndex];
  const data = [...rowData];

  if (element) {
    data.splice(prevIndex, 1);
    data.splice(newIndex, 0, element);
  }

  return data;
}

export default reorderRows;
