import classNames from 'classnames';
import { Statistic } from 'semantic-ui-react';

import 'features/common/errors/not_found.scss';

interface RecordNotFound {
  recordType?: string;
  className?: string;
}

const RecordNotFound = ({ recordType, className }: RecordNotFound) => {
  const message = recordType ? `${recordType} NOT FOUND` : 'NOT FOUND';

  return (
    <div className={classNames(className, 'not-found')}>
      <Statistic>
        <Statistic.Label>{message}</Statistic.Label>
        <Statistic.Value>404</Statistic.Value>
      </Statistic>
    </div>
  );
};

export default RecordNotFound;
