import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { LpDatePicker } from 'features/common/inputs/lp_date_picker';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';

const CustomFieldDate = ({ fieldId, resourceId, disabled = false }: CustomFieldValueProps) => {
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);
  const { updateFieldValue, createFieldValue } = useCustomFieldsApiActions(resourceId.type);

  if (!field) {
    return null;
  }

  const { allowsMultipleValues, values } = field;

  if (allowsMultipleValues) {
    throw new Error('multiple values not supported by date field');
  }

  const fieldValue = values?.[0];

  const handleChange = (value: string) => {
    if (value) {
      const payload = { date: value };
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else {
        createFieldValue(resourceId.id, fieldId, payload);
      }
    }
  };

  return <LpDatePicker allowEmpty onChange={handleChange} value={fieldValue?.date ?? ''} disabled={disabled} />;
};

export default CustomFieldDate;
