import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import parseQueryString from 'lib/parse_query_string';

const useHashParams = (): Record<string, string> => {
  let hash = useLocation().hash;
  // URLSearchParams will automatically remove a leading ? character but not a #, so do so manually.
  if (hash.startsWith('#')) {
    hash = hash.substr(1);
  }
  return useMemo(() => parseQueryString(hash), [hash]);
};

export default useHashParams;
