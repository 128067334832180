export enum SelectionDropdownLocation {
  AssignmentGrid = 'AssignmentGrid',
  Portfolio = 'Portfolio',
  Files = 'Files',
  SinglePackage = 'SinglePackage',
  SlackIntegration = 'SlackIntegration',
  Timesheet = 'Timesheet',
  Workload = 'Workload',
  Group = 'Group',
  IterationTable = 'IterationTable',
}

export enum SelectionDropdownOptions {
  UnselectAll = 'Unselect All',
  SelectAll = 'Select All',
  ExpandAll = 'Expand All',
  CollapseAll = 'Collapse All',
  SelectResources = 'Select Resources',
  SelectPlaceholders = 'Select Placeholders',
  SelectMembers = 'Select Members',
}
