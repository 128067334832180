import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { getFormattedDisplay } from 'features/common/data_grid/safe_widgets/formatter_helpers';
import { LpIcon } from 'features/common/lp_icon';
import { RenderedWidget } from 'features/dashboards_v2/widget';
import { DeprecatedPropertiesSummaryWidget } from 'features/dashboards_v2/widget/widgets/properties_summary/deprecated_properties_summary';
import { getPropertyHeaderDisplayText } from 'features/dashboards_v2/widget/widgets/properties_summary/display_helpers';
import {
  getIconForColumn,
  getIsActiveOrInUseArchivedCustomField,
} from 'features/dashboards_v2/widget/widgets/properties_summary/helpers';
import { ColumnDataMap, PropertiesWidgetData } from 'features/dashboards_v2/widget/widgets/reports/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { gray500 } from 'style/variables';

const PropertiesHeader = ({ columnDefinition }: { columnDefinition: WidgetConfigColumn }) => {
  const displayText = getPropertyHeaderDisplayText(columnDefinition);
  const icon = getIconForColumn(columnDefinition.column, columnDefinition.customFieldType ?? null);

  return (
    <div>
      {displayText} <LpIcon icon={icon} color={gray500} />
    </div>
  );
};

const PropertiesRow = ({
  columnDefinition,
  columnDataMap,
}: {
  columnDefinition: WidgetConfigColumn;
  columnDataMap: ColumnDataMap;
}) => {
  const isCustomFieldColumn = !!columnDefinition.customFieldId;
  const isActiveFieldOrHasExplicitArchivedValue = getIsActiveOrInUseArchivedCustomField(
    columnDefinition,
    columnDataMap
  );

  if (isCustomFieldColumn && !isActiveFieldOrHasExplicitArchivedValue) {
    return null;
  }

  const columnKey = String(columnDefinition.customFieldId ?? columnDefinition.column);
  const display = getFormattedDisplay(columnKey, columnDataMap);

  return (
    <>
      <div className="properties-summary__body-row-aside">
        <PropertiesHeader columnDefinition={columnDefinition} />
      </div>
      <div className="properties-summary__body-row-content">{display}</div>
    </>
  );
};

export const PropertiesSummaryWidget = ({ widget }: { widget: WidgetWithData }) => {
  const hasItemReportRefactor = useHasFeature(FeatureFlag.itemReportRefactor);

  if (!widget.config.columns || !widget.data) {
    return <RenderedWidget noContent />;
  }

  if (hasItemReportRefactor) {
    const data = widget.data as PropertiesWidgetData;
    return (
      <RenderedWidget
        body={
          <div className="properties-summary__body">
            {widget.config.columns.map((columnDefinition, idx) => (
              <PropertiesRow key={idx} columnDataMap={data.columnDataMap} columnDefinition={columnDefinition} />
            ))}
          </div>
        }
      />
    );
  }

  return <DeprecatedPropertiesSummaryWidget widget={widget} />;
};
