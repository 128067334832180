import { useState } from 'react';
import { Redirect } from 'react-router';

import NavigationSection from 'containers/shared/navigation_section';
import { getTempoSupportUrl } from 'features/common/buttons/contact_support_link';
import { questionCircleSolid, atSolid, globeSolid as workspaceIcon } from 'features/common/lp_icon';
import { NavigationButtons } from 'features/common/navigation_buttons';
import { frontend } from 'lib/urls';

enum DisconnectedUserNavigationTileNames {
  StartFresh = 'Start Fresh',
  RestartAccount = 'Restart Account',
  Support = 'Support',
}

export const DisconnectedUserNavigation = () => {
  const [logout, setLogout] = useState(false);

  const buttonOptions = [
    {
      key: DisconnectedUserNavigationTileNames.StartFresh,
      icon: atSolid,
      onClick: () => setLogout(true),
      subText: <span>{DisconnectedUserNavigationTileNames.StartFresh}</span>,
    },
    {
      key: DisconnectedUserNavigationTileNames.RestartAccount,
      icon: workspaceIcon,
      toUrl: frontend.disconnectedRestartAccount.url({}),
      subText: <span>{DisconnectedUserNavigationTileNames.RestartAccount}</span>,
    },
    {
      key: DisconnectedUserNavigationTileNames.Support,
      toUrl: getTempoSupportUrl(),
      icon: questionCircleSolid,
      subText: <span>{DisconnectedUserNavigationTileNames.Support}</span>,
    },
  ];

  if (logout) {
    return <Redirect to={frontend.logout.url({}, { query: { redirectTo: frontend.trial.url({}) } })} />;
  }

  return (
    <NavigationSection className="disconnected-user__navigation-section">
      <NavigationButtons buttonOptions={buttonOptions} />
    </NavigationSection>
  );
};
