import { ReactNode } from 'react';
import { Message } from 'semantic-ui-react';

interface CustomErrorMessageProps {
  className?: string;
  content: ReactNode;
  header?: string;
  onDismiss?: () => void;
}

const CustomErrorMessage = ({ className, content, header, onDismiss }: CustomErrorMessageProps) => {
  return <Message error visible className={className} content={content} header={header} onDismiss={onDismiss} />;
};

export default CustomErrorMessage;
