import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserDao } from 'daos/user';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { LpIcon, passportSolid } from 'features/common/lp_icon';
import { filterDashboardByName } from 'features/guest/dashboard_passports/helpers';
import { PassportTables } from 'features/guest/dashboard_passports/table';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { awaitRequestFinish } from 'lib/api';
import { getCurrentUserActiveGuestSortedDashboardsKeyedByWsIdMap } from 'redux/entities/selectors/dashboard_guest';
import { slate600 } from 'style/variables';

import './dashboard_passport.scss';

const breadcrumbSection = {
  key: 'passport',
  content: (
    <span>
      <LpIcon icon={passportSolid} color={slate600} /> Dashboard Passports
    </span>
  ),
};

const PassportsContent = () => {
  const sortedDashboardsByWsIdMap = useSelector(getCurrentUserActiveGuestSortedDashboardsKeyedByWsIdMap);
  const [dashboardsByWsIdMap, setDashboardByWsIdMap] = useState(sortedDashboardsByWsIdMap);

  const handleFilterChange = (filter: string) =>
    setDashboardByWsIdMap(filterDashboardByName(filter, sortedDashboardsByWsIdMap));

  return (
    <div className="dashboard-passport">
      <LpSearchInputUncontrolled onChange={handleFilterChange} />

      <PassportTables dashboardsByWsIdMap={dashboardsByWsIdMap} />
    </div>
  );
};

export const DashboardPassports = () => {
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const stopPageLoad = () => setIsFetchComplete(true);

  const sideLoadDashboardGuests = useSideLoadDashboardGuests(stopPageLoad);

  useEffect(() => {
    sideLoadDashboardGuests();
  }, [sideLoadDashboardGuests]);

  const content = !isFetchComplete ? <LpOverlayLoader /> : <PassportsContent />;

  return (
    <>
      <TopNavHeader breadcrumb={<LpBreadcrumb sections={[breadcrumbSection]} />} />

      {content}
    </>
  );
};

function useSideLoadDashboardGuests(stopPageLoad: () => void) {
  const dispatch = useDispatch();

  return useCallback(() => {
    const { uuid } = dispatch(UserDao.fetchCurrent({ include: { includeOrganization: true } }));

    dispatch(awaitRequestFinish(uuid, { onFinish: stopPageLoad }));
  }, [dispatch, stopPageLoad]);
}
