import { Mutable } from 'utility-types';

import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { PackageStatus } from './enums';
import { Item } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { ItemIncludes, ItemOptions } from './types';
import { backend } from './urls';

const { arrayBody, createBody, updateBody, deleteBody, resource, deleteArrayBody } = defineModel({
  apiType: 'items',
  relationships: ['parent', 'organizationUser'],
  type: 'ITEM',
});

export const itemIncludes = ({
  includeAlerts,
  includeAncestorPredecessorDependencies,
  includeAncestors,
  includeAncestorsItemMetrics,
  includeAncestorSuccessorDependencies,
  includeAssignments,
  includeChildItemMetrics,
  includeChildOrganizationUser,
  includeChildren,
  includeChildrenFieldValues,
  includeChildrenPredecessorDependencies,
  includeChildrenSuccessorDependencies,
  includeChildrenTimesheetEntries,
  includeDashboards,
  includeDiscussions,
  includeEffectiveFieldValues,
  includeEstimates,
  includeExternalIntegrations,
  includeExternalIntegrationsSyncSettings,
  includeFieldValues,
  includeItemAcls,
  includeItemMetrics,
  includeItemPins,
  includeIterations,
  includeParent,
  includePredecessorDependencies,
  includeSchedulingLimits,
  includeSuccessorDependencies,
  includeStoryPoint,
  includeTaskStoryPointSchemesOwnersAndPoints,
  includeTasks,
  includeTaskStatus,
  includeTimesheetEntries,
  includeFiles,
  includeItemFiles,
  includeTimesheetEntryLocks,
  includeTimesheetEntryLockExceptions,
  includeTimesheetRollupDependencies,
  includeOrganizationUser,
  includeWorkspacePicklistChoices,
  includeWorkspaceUsers,
}: ItemIncludes = {}) =>
  reduceIncludedOptions([
    includeAlerts && 'alerts',
    includeAncestorPredecessorDependencies && 'predecessorDependencies.predecessor.ancestors',
    includeAncestors && 'ancestors',
    includeAncestorsItemMetrics && 'ancestors.itemMetrics',
    includeAncestorSuccessorDependencies && 'successorDependencies.successor.ancestors',
    includeAssignments && 'children.children',
    includeChildItemMetrics && 'children.itemMetrics',
    includeChildOrganizationUser && 'children.organizationUser',
    includeChildren && 'children',
    includeChildrenFieldValues && 'children.fieldValues',
    includeChildrenPredecessorDependencies && 'children.predecessorDependencies',
    includeChildrenSuccessorDependencies && 'children.successorDependencies',
    includeChildrenTimesheetEntries && 'children.timesheetEntries',
    includeDashboards && 'dashboards',
    includeDiscussions && 'discussions',
    includeEffectiveFieldValues &&
      'effectiveFieldValues,effectiveFieldValues.fields,effectiveFieldValues.fieldValues.itemValue,effectiveFieldValues.fieldValues.picklistChoice',
    includeEstimates && 'estimates',
    includeExternalIntegrations && 'externalIntegrations',
    includeExternalIntegrationsSyncSettings && 'externalIntegrations.jiraSyncSettings',
    includeFieldValues && 'fieldValues.itemValue',
    includeItemAcls && 'itemAcls',
    includeItemMetrics && 'itemMetrics',
    includeItemPins && 'itemPins',
    includeIterations && 'workspace.iterations',
    includeParent && 'parent',
    includePredecessorDependencies && 'predecessorDependencies',
    includeSchedulingLimits && 'schedulingLimits',
    includeSuccessorDependencies && 'successorDependencies',
    includeStoryPoint && 'storyPoints',
    includeTaskStoryPointSchemesOwnersAndPoints && 'storyPointSchemeOwners.storyPointSchemes.storyPoints',
    includeTasks && 'tasks',
    includeTaskStatus && 'taskStatus',
    includeTimesheetEntries && 'timesheetEntries',
    includeFiles && 'files',
    includeItemFiles && 'itemFiles',
    includeTimesheetEntryLocks && 'timesheetEntryLocks',
    includeTimesheetEntryLockExceptions && 'timesheetEntryLockExceptions',
    includeTimesheetRollupDependencies && 'timesheetRollups',
    includeOrganizationUser && 'workspace.workspaceUsers.organizationUser',
    includeWorkspacePicklistChoices && 'workspace.picklistChoices',
    includeWorkspaceUsers && 'workspace.workspaceUsers',
  ]);

interface ItemsParams {
  organizationId: string | number;
  workspaceId: string | number;
}

interface SingleItemParams extends ItemsParams {
  itemId: string | number;
}

const { ITEM, resourceId } = resource;

const itemCreate = (params: ItemsParams, item: Partial<Item>) =>
  request(backend.items.url(params), ITEM, createBody(item));

const itemCreateBulk = (params: ItemsParams, items: ReadonlyArray<Mutable<Partial<Item>>>) =>
  request(backend.itemsBulk.url(params), ITEM, { ...arrayBody(undefined, items), method: HttpMethod.POST });

const itemDuplicateBulk = (
  params: ItemsParams,
  itemIds: ReadonlyArray<number>,
  parentId?: number,
  packageStatus?: PackageStatus
) =>
  request(backend.duplicateBulk.url(params), ITEM, {
    body: JSON.stringify({ data: { itemIds, parentId, packageStatus } }),
    method: HttpMethod.POST,
  });

const itemFetch = (params: SingleItemParams, { skipReduce, include }: ItemOptions = {}) =>
  request(backend.item.url(params, { include: itemIncludes(include) }), ITEM, {
    meta: { skipReduce },
    method: HttpMethod.GET,
  });

const itemsSearch = (params: ItemsParams, { filter, include, query, skipReduce }: ItemOptions = {}) =>
  request(backend.itemsSearch.url(params, { filter, include: itemIncludes(include), query }), ITEM, {
    method: HttpMethod.GET,
    meta: { skipReduce },
  });

const fetchAll = (params: ItemsParams, { filter, include, query, skipReduce }: ItemOptions = {}) =>
  request(backend.items.url(params, { filter, include: itemIncludes(include), query }), ITEM, {
    method: HttpMethod.GET,
    meta: { skipReduce },
  });

const itemsGrid = (params: ItemsParams, { filter, include, query, skipReduce }: ItemOptions = {}) =>
  request(backend.itemsGrid.url(params, { filter, include: itemIncludes(include), query }), ITEM, {
    method: HttpMethod.GET,
    meta: { skipReduce },
  });

const itemExportXlsxURL = (params: ItemsParams, { filter, include, query }: ItemOptions = {}) =>
  backend.itemExcelExport.url(params, { filter, include: itemIncludes(include), query });

const itemDestroy = (params: SingleItemParams, itemId: number) =>
  request(backend.item.url(params), ITEM, deleteBody(itemId));

const itemDestroyBulk = (params: ItemsParams, itemIds: Array<number>) =>
  request(backend.itemsBulkDelete.url(params), ITEM, {
    ...deleteArrayBody(itemIds),
    method: HttpMethod.POST,
  });

const itemUpdate = (params: SingleItemParams, item: Partial<Item>, { include }: ItemOptions = {}) =>
  request(backend.item.url(params, { include: itemIncludes(include) }), ITEM, updateBody(Number(params.itemId), item));

const updateTaskStoryPoints = (
  params: SingleItemParams,
  item: Pick<Partial<Item>, 'id' | 'storyPoints'>,
  { include }: ItemOptions = {}
) =>
  request(
    backend.taskStoryPoints.url(params, { include: itemIncludes(include) }),
    ITEM,
    updateBody(Number(params.itemId), item)
  );

const itemUpdateBulk = (params: ItemsParams, items: ReadonlyArray<Mutable<Partial<Item> & { id: number }>>) =>
  request(backend.itemsBulk.url(params), ITEM, {
    ...arrayBody(undefined, items),
    method: HttpMethod.PATCH,
  });

interface AssignmentSwapPayload {
  itemIds: ReadonlyArray<number>;
  orgUserReassignmentRules: ReadonlyArray<{ fromOrgUserId: number | null; toOrgUserId: number | null }>;
}

const assignmentSwap = (params: ItemsParams, assignmentSwapPayload: AssignmentSwapPayload) =>
  request(backend.itemAssignmentSwap.url(params), ITEM, {
    body: JSON.stringify({ data: assignmentSwapPayload }),
    method: HttpMethod.POST,
  });

export const ItemDao = {
  assignmentSwap,
  create: itemCreate,
  createBulk: itemCreateBulk,
  destroy: itemDestroy,
  destroyBulk: itemDestroyBulk,
  duplicateBulk: itemDuplicateBulk,
  fetch: itemFetch,
  fetchAll,
  id: resourceId,
  itemsGrid,
  itemsSearch,
  itemExportXlsxURL,
  update: itemUpdate,
  updateBulk: itemUpdateBulk,
  updateTaskStoryPoints,
} as const;
