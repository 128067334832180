/* A utility class to ensure a typescript switch statement is exhaustive (all cases are handled)
 * http://ideasintosoftware.com/exhaustive-switch-in-typescript/
 *
 * switch (color) {
 *   case Color.Red:
 *     return 'WARM';
 *   case Color.Blue:
 *     return 'COLD';
 *   default:
 *     throw new UnreachableCaseError(color);
 *   }
 *
 * In the above example, if the programmer adds a Color.Purple enum value, typescript will refuse to compile the
 * switch statement.
 */
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}
