import classNames from 'classnames';
import { Loader, Popup } from 'semantic-ui-react';

import './lp_inline_loader.scss';

interface LpInlineLoaderProps {
  className?: string;
  title?: string;
}

export const LpInlineLoader = ({ className, title }: LpInlineLoaderProps) => {
  const loader = <Loader className={classNames('lp-inline-loader', className)} active inline size="tiny" />;

  if (title) {
    return <Popup content={title} basic position="top center" size="tiny" trigger={loader} />;
  }

  return loader;
};
