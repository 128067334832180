import { EllipsisActionViewLocation } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';

export type EllipsisActionMenuItem = JSX.Element | null;

export interface EllipsisActionMenuConfig {
  dropdownItem: EllipsisActionMenuItem;
  modifyOnViewLocation?: Array<{ view: EllipsisActionViewLocation; replacementMenuItem: EllipsisActionMenuItem }>;
}

type EllipsisActionMenuConfigs = ReadonlyArray<EllipsisActionMenuConfig>;

export const reduceEllipsisActionMenuItems = (
  menuItems: EllipsisActionMenuConfigs,
  viewLocation: EllipsisActionViewLocation | undefined
) => {
  return menuItems.reduce((acc: Array<EllipsisActionMenuItem>, menuItem) => {
    if (!menuItem.dropdownItem) {
      return acc;
    }

    const menuModifications = menuItem?.modifyOnViewLocation;

    if (menuModifications?.length) {
      menuModifications.forEach((menu) => {
        if (menu.view === viewLocation) {
          menuItem.dropdownItem = menu.replacementMenuItem;
        }
      });
    }

    acc.push(menuItem.dropdownItem);
    return acc;
  }, []);
};
