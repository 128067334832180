import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { ManageAccountPlanChangeType, OrganizationProjectTypeType } from './enums';
import { Organization } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

defineModel({
  apiType: 'organizationProjectTypes',
  relationships: [],
  type: 'ORGANIZATION_PROJECT_TYPE',
});

const { createBody, updateBody, resource } = defineModel({
  apiType: 'organizations',
  relationships: [],
  type: 'ORGANIZATION',
});

const { ORGANIZATION, resourceId } = resource;

interface Includes {
  includeActiveBanner?: boolean;
  includeFields?: boolean;
  includeGroups?: boolean;
  includeOrganizationUsers?: boolean;
  includePlan?: boolean;
  includeSubscription?: boolean;
  includeSystemSettings?: boolean;
  includeUsers?: boolean;
  includeWorkspaceUsers?: boolean;
  includeWorkspaces?: boolean;
  includeOrganizationProjectTypes?: boolean;
}

const includes = ({
  includeActiveBanner,
  includeOrganizationUsers,
  includePlan,
  includeSubscription,
  includeSystemSettings,
  includeUsers,
  includeWorkspaceUsers,
  includeWorkspaces,
  includeOrganizationProjectTypes,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeActiveBanner && 'activeBanners',
    includeOrganizationUsers && 'organizationUsers',
    includePlan && 'plan',
    includeSubscription && 'subscription',
    includeSystemSettings && 'system',
    includeUsers && 'organizationUsers.user',
    includeWorkspaceUsers && 'workspaces.workspaceUsers',
    includeWorkspaces && 'workspaces',
    includeOrganizationProjectTypes && 'organizationProjectTypes',
  ]);

type UpdateOrganization = Partial<Pick<Organization, 'name' | 'slackApproved'>>;

interface SingleOrganizationParams {
  organizationId: number;
}

interface OrganizationOptions {
  filter?: string;
  include?: Includes;
}

const organizationFetchRateData = (params: SingleOrganizationParams) =>
  request(backend.organizationPlansRateData.url(params), ORGANIZATION, { method: HttpMethod.GET });

const organizationFetchAccessBulk = (params: SingleOrganizationParams) =>
  request(backend.organizationAccessBulk.url(params), ORGANIZATION, { method: HttpMethod.GET });

const organizationPlanChange = (
  params: SingleOrganizationParams,
  changeType: ManageAccountPlanChangeType,
  planId: number,
  count: number
) => {
  const changeRequestParts = {
    method: HttpMethod.POST,
    meta: { skipReduce: true },
    body: JSON.stringify({ data: { planId, count } }),
  };
  if (changeType === ManageAccountPlanChangeType.Upgrade) {
    return request(backend.organizationUpgradePlan.url(params), ORGANIZATION, changeRequestParts);
  }
  return request(backend.organizationDowngradePlan.url(params), ORGANIZATION, changeRequestParts);
};

const organizationTrialPremiumFeatures = (params: SingleOrganizationParams) =>
  request(backend.organizationTrialPremiumFeatures.url(params), ORGANIZATION, {
    method: HttpMethod.POST,
    meta: { skipReduce: true },
  });

const fetch = (params: SingleOrganizationParams, { include }: OrganizationOptions = {}) =>
  request(backend.organization.url(params, { include: includes(include) }), ORGANIZATION, {
    method: HttpMethod.GET,
  });

const fetchAll = () => request(backend.organizations.url({}), ORGANIZATION, { method: HttpMethod.GET });

const organizationCreate = (
  organization: Partial<Organization> & {
    workspaceName?: string | null;
    organizationProjectTypes?: Array<OrganizationProjectTypeType>;
  }
) => request(backend.organizations.url({}), ORGANIZATION, createBody(organization));

const organizationUpdate = (params: SingleOrganizationParams, organization: UpdateOrganization) =>
  request(backend.organization.url(params), ORGANIZATION, updateBody(params.organizationId, organization));

export const OrganizationDao = {
  create: organizationCreate,
  fetch,
  fetchRateData: organizationFetchRateData,
  fetchAccessBulk: organizationFetchAccessBulk,
  fetchAll,
  id: resourceId,
  update: organizationUpdate,
  planChange: organizationPlanChange,
  trialPremiumFeatures: organizationTrialPremiumFeatures,
} as const;
