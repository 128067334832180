import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import { PlanGadgetSidebar } from 'features/organization_directory/manage_account/plan_gadget';
import { ActionsSection } from 'features/organization_directory/users/actions_section';
import { OrgUsersQuickFilterType } from 'features/organization_directory/users/quick_filter';
import OrganizationDirectoryUsersTable from 'features/organization_directory/users/table';

const OrgDirUsersActive = () => {
  useSetupOutpost(OutpostLocation.OrganizationUsersActive);

  return (
    <div className="organization-directory__users-grid">
      <p>
        Users will be invited to Workspaces via email. They can be members of multiple Workspaces on the PROFESSIONAL
        and ULTIMATE plans. Workspace access control features also vary by plan.
      </p>
      <ActionsSection filterType={OrgUsersQuickFilterType.MEMBERS} showAdd={true} />
      <OrganizationDirectoryUsersTable view="active" />
      <PlanGadgetSidebar includeContactSupport={true} />
    </div>
  );
};
export default OrgDirUsersActive;
