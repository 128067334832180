export const JiraSvgWhite = () => {
  return (
    <svg viewBox="0 0 38 37" fill="none">
      <g clipPath="url(#clip0_1156_1573)">
        <path
          d="M18.8271 36.7491C20.5354 35.0387 21.495 32.7202 21.495 30.3027C21.495 27.8853 20.5354 25.5667 18.8271 23.8563L6.9446 12.0077L1.53996 17.4123C1.40475 17.547 1.29748 17.7071 1.22428 17.8834C1.15108 18.0597 1.1134 18.2487 1.1134 18.4396C1.1134 18.6305 1.15108 18.8194 1.22428 18.9957C1.29748 19.172 1.40475 19.3321 1.53996 19.4668L18.8223 36.7491H18.8271Z"
          fill="white"
        />
        <path
          d="M36.1094 17.4123L18.8222 0.130005L18.7691 0.183181C17.0753 1.89586 16.1285 4.20949 16.1358 6.61825C16.143 9.027 17.1037 11.3349 18.8077 13.0373L30.7047 24.8715L36.1094 19.4668C36.2446 19.3321 36.3519 19.172 36.4251 18.9957C36.4983 18.8194 36.5359 18.6305 36.5359 18.4396C36.5359 18.2487 36.4983 18.0597 36.4251 17.8834C36.3519 17.7071 36.2446 17.547 36.1094 17.4123Z"
          fill="white"
        />
        <path
          d="M18.8272 13.0228C17.1244 11.3191 16.1655 9.01049 16.16 6.60174C16.1546 4.19299 17.1031 1.88007 18.7982 0.168671L6.45642 12.5007L12.9053 18.9496L18.8272 13.0277V13.0228Z"
          fill="url(#paint0_linear_1156_1573)"
        />
        <path
          d="M24.7394 17.9586L18.8271 23.8563C20.5355 25.5667 21.4951 27.8853 21.4951 30.3027C21.4951 32.7202 20.5355 35.0387 18.8271 36.7492L31.1882 24.3881L24.7394 17.9586Z"
          fill="url(#paint1_linear_1156_1573)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1156_1573"
          x1="18.5371"
          y1="6.86889"
          x2="11.0586"
          y2="14.3474"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stopColor="white" stopOpacity="0.4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1156_1573"
          x1="19.2961"
          y1="29.8314"
          x2="27.4852"
          y2="21.6423"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stopColor="white" stopOpacity="0.4" />
          <stop offset="0.91" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1156_1573">
          <rect width="36.74" height="36.74" fill="white" transform="translate(0.630005 0.130005)" />
        </clipPath>
      </defs>
    </svg>
  );
};
