import { useSelector } from 'react-redux';

import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { CustomFieldValueApiActions } from 'hooks/use_custom_fields_api_actions/types';

import { useItemFieldValueApiActions } from './use_item_field_value_api_actions';
import { useWorkspaceUserFieldValueApiActions } from './use_workspace_user_field_value_api_actions';

export const useCustomFieldsApiActions = (type: 'items' | 'workspaceUsers'): CustomFieldValueApiActions => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isItem = type === 'items';
  const itemFieldValueApiActions = useItemFieldValueApiActions(organizationId, workspaceId);
  const workspaceUserFieldValueApiActions = useWorkspaceUserFieldValueApiActions(organizationId, workspaceId);
  const { updateFieldValue, createFieldValue, removeFieldValue } = isItem
    ? itemFieldValueApiActions
    : workspaceUserFieldValueApiActions;

  return { updateFieldValue, createFieldValue, removeFieldValue };
};
