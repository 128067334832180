import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';

import { LpLogo } from 'features/shared/components/lp_logo';
import { frontend } from 'lib/urls';

export const DisconnectedUserTopBar = () => {
  const history = useHistory();

  const handleLogout = () => {
    history.push(frontend.logout.url({}));
  };

  return (
    <div className="disconnected-user__top-bar">
      <div className="disconnected-user__logo-container">
        <LpLogo className="disconnected-user__logo" logoType="core" />
      </div>
      <Button onClick={handleLogout} className="disconnected-user__logout" color="blue">
        Logout
      </Button>
    </div>
  );
};
