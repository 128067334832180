import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';

import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { WidgetMotionDiv } from 'features/dashboards_v2/widget_click_through/intake/display_helpers';

import { IntakeFormButtons } from './intake_form_buttons';

import './index.scss';

export const IntakeFormImageHeader = ({ imageUrl: s3Id }: { imageUrl: string | null }) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const imageUrl = s3Id ? backend.dashboardImage.url({ organizationId, workspaceId, s3Id }) : '';

  return (
    <WidgetMotionDiv className="intake-form__body-image">
      <Image className="intake-form__body-image-section" src={imageUrl} />

      <div className="intake-form__body-image-buttons">
        <IntakeFormButtons />
      </div>
    </WidgetMotionDiv>
  );
};
