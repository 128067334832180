import { useFormikContext } from 'formik';
import { ReactNode, useRef } from 'react';
import { Form, Ref } from 'semantic-ui-react';

import { useDisableAutofill } from 'hooks/use_disable_auto_fill';

interface LpFormikFormProps {
  applyLastPassIgnore?: boolean;
  children: ReactNode;
  className?: string;
}

export const LpFormikForm = ({ applyLastPassIgnore = true, children, className }: LpFormikFormProps) => {
  const formRef = useRef<HTMLElement>(null);
  useDisableAutofill(formRef, applyLastPassIgnore);

  const { handleSubmit, isValid } = useFormikContext();

  return (
    <Ref innerRef={formRef}>
      <Form onSubmit={handleSubmit} error={!isValid} className={className}>
        {children}
      </Form>
    </Ref>
  );
};
