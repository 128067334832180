import {
  ConnectedStatus,
  ExternalIntegrationStatusValues,
  EstimationType,
  SourceSystem,
  StoryPointSchemeActions,
  SyncProjectJobErrorType,
  TaskGroupHierarchy,
  TaskPushSetting,
} from 'daos/external_integration_enums';
import { AuthType } from 'features/authentication/enums';
import { DiscussionSubscriptionType } from 'features/common/discussion/enum';
import { DashboardType } from 'features/dashboards/dashboard_types';
import { DeprecatedGridSummaryRow } from 'features/dashboards_v2/widget/widgets/list/types';
import { CustomIcon } from 'lib/display_helpers/custom_icon';
import { DateFormat, FirstDayOfWeek, NumberFormat, TimeFormat, WeekNumberFormat } from 'lib/localization_enums';
import { ReadonlyRecord } from 'lib/readonly_record';

import {
  AvailabilityType,
  BillingPeriod,
  ChangeActionType,
  ChangeOrigin,
  CustomFieldType,
  DependencyStatus,
  EmailFrequency,
  FavoriteViewType,
  InvitationState,
  ItemType,
  ListWidgetType,
  MarketingConsentStatus,
  OrganizationProjectTypeType,
  PackageStatus,
  Permission,
  PlanFamily,
  PricingModel,
  RateSheetType,
  ScheduleDirective,
  SchedulingLimitType,
  SchedulingType,
  TimeOffType,
  TimesheetGroupingType,
  TimesheetLockingType,
  TimesheetReviewOptions,
  UserType,
  WidgetGroupSize,
  WidgetSize,
  WidgetType,
  WorkloadDayRange,
  WorkloadGroupingType,
  WorkType,
} from './enums';
import { FolderStatus, TargetFinishType } from './item_enums';
import {
  ChangeEntity,
  Currency,
  DailyWork,
  DashboardV2Config,
  DateRangeType,
  ItemAccessForSubjectGridRow,
  OrganizationFeatureFlags,
  OrganizationFlags,
  PlanPricingTier,
  PropertyChangeHistory,
  RateTable,
  RelativePriority,
  SubjectAccessGridRow,
  SystemColumns,
  UserCustomColumn,
  WeekData,
  WidgetConfig,
  WidgetData,
  WidgetGroupConfig,
} from './types';

export interface Alert extends Model<'alerts'> {
  readonly text: string;
  readonly item: ResourceId<Item>;
}

export interface AvailabilityRange extends Model<'availabilityRanges'> {
  readonly availabilityType: AvailabilityType;
  readonly availabilityRangeId: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly mondayStart: string;
  readonly tuesdayStart: string;
  readonly wednesdayStart: string;
  readonly thursdayStart: string;
  readonly fridayStart: string;
  readonly saturdayStart: string;
  readonly sundayStart: string;
  readonly mondayDuration: number;
  readonly tuesdayDuration: number;
  readonly wednesdayDuration: number;
  readonly thursdayDuration: number;
  readonly fridayDuration: number;
  readonly saturdayDuration: number;
  readonly sundayDuration: number;
  readonly workspaceUser: ResourceId<WorkspaceUser> | null;
  readonly timeOffType: TimeOffType;
  readonly description: string;

  // WRITE-ONLY
  readonly relativePriority?: RelativePriority;
}

export interface Banner extends Model<'banners'> {
  readonly permission: Permission;
  readonly content: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: string | null;
  readonly updatedBy: string | null;
}

export interface Change extends Model<'changes'> {
  readonly changeType: ChangeActionType;
  readonly origin: ChangeOrigin | null;
  readonly changedBy: ResourceId<User> | null;
  readonly changedByName: string;
  readonly date: string;
  readonly description: string;
  readonly propertyChanges: ReadonlyArray<PropertyChangeHistory>;
  readonly record: ChangeEntity;
  readonly item: ResourceId<Item>;
  readonly permission: Permission;
  readonly field: ResourceId<CustomField> | null;
  readonly widgetId: ResourceId<Widget> | null;
}

export interface CostCode extends Model<'costCodes'> {
  readonly name: string;
  readonly archived: boolean;
  readonly billable: boolean;
  readonly workspace: ResourceId<Workspace>;
}

export interface CustomField extends Model<'fields'> {
  readonly archived: boolean;
  readonly currencyUnit: string | null;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly fieldType: CustomFieldType;
  readonly inheritable: boolean;
  readonly name: string;
  readonly onPackages: boolean;
  readonly onProjectFolders: boolean;
  readonly onTasks: boolean;
  readonly onUsers: boolean;
  readonly priority: string;
  readonly sourceSystem: SourceSystem | null;
  readonly includeTotals: boolean;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
  readonly visibilityPermissionLevel: string;
  readonly workspace: ResourceId<Workspace>;

  // WRITE- ONLY
  readonly relativePriority?: RelativePriority;
}

export interface CustomFieldValue extends Model<'fieldValues'> {
  readonly checked: boolean | null;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly currency: number | null;
  readonly date: string | null;
  readonly field: ResourceId<CustomField>;
  readonly fieldType: CustomFieldType;
  readonly item: ResourceId<Item> | null;
  readonly itemValue: ResourceId<Item> | null;
  readonly number: number | null;
  readonly picklistChoice: ResourceId<PicklistChoice> | null;
  readonly sourceSystem: SourceSystem | null;
  readonly text: string | null;
  readonly html: string | null;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
  readonly url: string | null;
  readonly workspaceUser: ResourceId<WorkspaceUser> | null;
  readonly workspaceUserValue: ResourceId<WorkspaceUser> | null;
}

export interface Dashboard extends Model<'dashboards'> {
  readonly workspaceId: string;
  readonly itemId: string;
  readonly itemName: string | null;
  readonly defaultName: string;
  readonly name: string | null;
  readonly color: string;
  readonly config: DashboardV2Config;
  readonly dashboardType: DashboardType;
  readonly headerImageS3Id: string | null;
  readonly headerText: string | null;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: string | null;
  readonly updatedBy: string | null;

  //WRITE-ONLY
  readonly archived?: boolean;
}
interface DateRange {
  readonly dateRangeType: DateRangeType;
  readonly startDate: string | null;
  readonly endDate: string | null;
}

export interface StartAndEndDate {
  readonly startDate: string | null;
  readonly endDate: string | null;
}

export interface DateRangeFilterValue extends Model<'dateRangeFilterValue'> {
  readonly ranges: ReadonlyArray<StartAndEndDate>;
}

export interface DashboardGuest extends Model<'dashboardGuests'> {
  readonly dashboardId: string;
  readonly disconnectedAt: string | null;
  readonly organizationId: string;
  readonly userId: string;
  readonly workspaceId: string;
  readonly workspaceUserId: string;

  // WRITE-ONLY
  readonly disconnected?: boolean;
}

export interface DeletedItem extends Model<'deletedItems'> {
  readonly color: string | null;
  readonly deletedAt: string | null;
  readonly deletedBy: ResourceId<User> | null;
  readonly folderStatus: FolderStatus | null;
  readonly itemType: ItemType;
  readonly name: string | null;
  readonly packageStatus: PackageStatus | null;
  readonly parent: ResourceId<Item> | null;
  readonly taskStatus: ResourceId<TaskStatus> | null;
  readonly workspace: ResourceId<Workspace>;
}
export interface Dependency extends Model<'dependencies'> {
  readonly status: DependencyStatus | null;
  readonly predecessor: ResourceId<Item>;
  readonly successor: ResourceId<Item>;
  readonly waitDays: number;
}

export interface Discussions extends Model<'discussions'> {
  readonly itemId: string;
  readonly workspaceId: string;
  readonly organizationId: string;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
  readonly lastPostDate?: string;
}
export interface MyDiscussions extends Discussions {
  readonly hasUnread: boolean;
  readonly lastPostDate: string;
  readonly subscriptionDto: DiscussionSubscriptions;
}

export interface DiscussionSubscriptions extends Model<'discussionSubscriptions'> {
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly discussionId: string;
  readonly hasUnread: boolean;
  readonly lastPostDate: string | null;
  readonly lastReadDate: string | null;
  readonly organizationId: string;
  readonly subscriptionType: DiscussionSubscriptionType;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
  readonly workspaceId: string;
  readonly workspaceUserId: string;
}

export interface DiscussionPost extends Model<'discussionPosts'> {
  readonly contentHtml: string | null;
  readonly contentText: string | null;
  readonly createdAt: string;
  readonly createdBy?: ResourceId<User>;
  readonly updatedAt: string;
  readonly updatedBy?: ResourceId<User>;
  readonly discussionId: string;
  readonly workspaceId: string;
  readonly organizationId: string;
  readonly workspaceUserId: string;
}

export interface Estimate extends Model<'estimate'> {
  readonly costCode: ResourceId<CostCode> | null;
  readonly date: string;
  readonly highEffort: number;
  readonly item: ResourceId<Item>;
  readonly loggedWork: number;
  readonly lowEffort: number;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
}

export interface ExternalIntegration extends Model<'externalIntegrations'> {
  readonly credentialsId: number;
  readonly itemId: number;
  readonly sourceItemId: string;
  readonly sourceSystem: SourceSystem;
  readonly status: ConnectedStatus;
}

export enum SyncType {
  FULL = 'FULL',
  PULL = 'PULL',
}
export interface ExternalIntegrationStatus extends Model<'externalIntegrations'> {
  readonly finishedAt: Date;
  readonly startedAt: Date;
  readonly status: ExternalIntegrationStatusValues;
  readonly syncType: SyncType;
}

export interface ExternalIntegrationSyncProjectJob extends Model<'syncProjectJobs'> {
  readonly finishedAt: Date;
  readonly startedAt: Date;
  readonly status: ExternalIntegrationStatusValues;
}

export interface ExternalIntegrationSyncErrorDetails {
  readonly fieldName: string | null;
  readonly issueKey: string | null;
  readonly issueUrl: string | null;
  readonly itemId: number | null;
  readonly maxLimit: number | null;
  readonly parentId: number | null;
}

export interface ExternalIntegrationSyncError extends Model<'externalIntegrationSyncErrors'> {
  readonly errorType: SyncProjectJobErrorType;
  readonly syncProjectJobId: number;
  readonly errorDetails: ExternalIntegrationSyncErrorDetails;
}
export interface ExternalIntegrationSyncErrorOverview extends Model<'externalIntegrationSyncErrors'> {
  readonly errorType: SyncProjectJobErrorType;
  readonly errors: ReadonlyArray<ExternalIntegrationSyncError>;
}

export interface Favorite extends Model<'favorites'> {
  readonly id: number;
  readonly group: ResourceId<Group> | null;
  readonly item: ResourceId<Item> | null;
  readonly user: ResourceId<User> | null;
  readonly libraryResource: ResourceId<LibraryResource> | null;
  readonly priority: string;
  readonly view: FavoriteViewType;
  readonly workspace: ResourceId<Workspace>;
  readonly packageStatus: PackageStatus | null;

  // WRITE-ONLY
  readonly relativePriority?: RelativePriority;
}
export interface Group extends Model<'groups'> {
  readonly color: string;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
  readonly icon: CustomIcon | null;
  readonly name: string;
  readonly workspace: ResourceId<Workspace>;
}

export interface GroupAvailability extends Model<'groupAvailability'> {
  readonly startDate: string;
  readonly endDate: string;
  readonly dailyAvailabilitySeconds: ReadonlyRecord<string, number>;
}

export interface GroupAllocation extends Model<'groupAllocations'> {
  readonly groupId: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly itemId: number;
  readonly reservedAmount: number;
}

export interface Invitation extends Model<'invitations'> {
  readonly organization: ResourceId<Organization>;
  readonly workspaces: Array<ResourceId<Workspace>>;
  readonly dashboards: Array<ResourceId<Dashboard>>;
  readonly email: string;
  readonly state: InvitationState;
  readonly authType: AuthType;
  readonly marketingConsent: MarketingConsentStatus;
  readonly guestInvitation: boolean;
  readonly createdBy: ResourceId<User>;
  readonly receiver: ResourceId<User> | null;
  readonly samlUrl: string | null;
  readonly token: string | null;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
}

enum FileCategory {
  CustomFieldValueImages = 'customFieldValueImages',
  DashboardImages = 'dashboardImages',
  ItemFiles = 'itemFiles',
}

export enum ContentCategory {
  Archive = 'archive',
  Audio = 'audio',
  Chart = 'chart',
  Code = 'code',
  CSV = 'csv',
  Excel = 'excel',
  Image = 'image',
  Other = 'other',
  PDF = 'pdf',
  Powerpoint = 'powerpoint',
  Text = 'text',
  Word = 'word',
  Video = 'video',
}

export interface File extends Model<'files'> {
  readonly s3Id: string | null;
  readonly category: FileCategory | null;
  readonly filename: string | null;
  readonly contentType: string | null;
  readonly contentCategory: ContentCategory | null;
  readonly size: number | null;
  readonly title: string | null;
  readonly workspace: ResourceId<Workspace> | null;
  readonly updatedAt: string | null;
  readonly createdAt: string | null;
  readonly createdBy: ResourceId<User> | null;
  readonly updatedBy: ResourceId<User> | null;
}

export interface InAppNotification extends Model<'inAppNotifications'> {
  readonly organization: ResourceId<Organization>;
  readonly workspace: ResourceId<Workspace>;
  readonly workspaceUser: ResourceId<WorkspaceUser>;
  readonly unreadDiscussionPosts: boolean;
}

export interface ItemFile extends Model<'itemFiles'> {
  readonly organization: ResourceId<Organization>;
  readonly workspace: ResourceId<Workspace>;
  readonly item: ResourceId<File>;
  readonly file: ResourceId<File>;
  readonly parent: ResourceId<Item>;
  readonly createdAt: string | null;
  readonly createdBy: ResourceId<User> | null;
  readonly updatedBy: string | null;
}

export interface Item extends Model<'items'> {
  readonly benchmarkEstimate: number | null;
  readonly color: string | null;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly description: string | null;
  readonly doneDate: string | null;
  readonly fileCount: number;
  readonly globalPriority: ReadonlyArray<string>;
  readonly icon: CustomIcon | null;
  readonly itemType: ItemType;
  readonly name: string | null;
  readonly parent: ResourceId<Item> | null;
  readonly predecessorCount: number;
  readonly priority: string;
  readonly successorCount: number;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
  readonly workLimit: number | null;
  readonly workspace: ResourceId<Workspace>;

  // Workspace roots and assignments have NULL targets and statuses
  readonly lastStatusChange: string | null;
  readonly targetFinish: string | null;
  readonly targetFinishType: TargetFinishType | null;
  readonly targetStart: string | null;

  // Packages only, other item types are null
  readonly packageStatus: PackageStatus | null;

  // Project and sub-folder only, other item types are null
  readonly folderStatus: FolderStatus | null;

  // Task only, other item types are null
  readonly iterationId: number | null;
  readonly iterationPriority: string | null;
  readonly scheduleDirective: ScheduleDirective | null;
  readonly storyPoints: ResourceId<StoryPoints> | null;
  readonly taskStatus: ResourceId<TaskStatus> | null;
  readonly workType: WorkType | null;

  // Assignments only, other item types are null
  readonly billingRateSheetId: string | null;
  readonly payRateSheetId: string | null;
  readonly costCode: ResourceId<CostCode> | null;
  readonly highEffort: number | null;
  readonly lowEffort: number | null;
  readonly organizationUser: ResourceId<OrganizationUser> | null;

  // Schedule properties
  readonly clippedEffort: number | null;
  readonly expectedFinish: string | null;
  readonly expectedStart: string | null;
  readonly late: boolean;
  readonly latestFinish: string | null;

  // External integrations properties
  readonly sourceSystem: SourceSystem | null;

  // WRITE-ONLY
  readonly children?: ReadonlyArray<Partial<Item>>;
  readonly fieldValues?: ReadonlyArray<Partial<CustomFieldValue>>;
  readonly relativePriority?: RelativePriority;
  readonly iterationRelativePriority?: RelativePriority;
}

export interface ItemAccessForGroupGrid extends Model<'itemAccessForGroupGrids'> {
  readonly groupId: number; // same as 'id' property
  readonly workspaceRootRow: ItemAccessForSubjectGridRow;
  readonly packageRows: ReadonlyArray<ItemAccessForSubjectGridRow>;
}

export interface ItemAccessForWorkspaceUserGrid extends Model<'itemAccessForWorkspaceUserGrids'> {
  readonly workspaceUserId: number; // same as 'id' property
  readonly workspaceRootRow: ItemAccessForSubjectGridRow;
  readonly packageRows: ReadonlyArray<ItemAccessForSubjectGridRow>;
}

export interface ItemAcl extends Model<'itemAcls'> {
  readonly deletable: boolean;
  readonly everyone: boolean;
  readonly grant: Permission;
  readonly group: ResourceId<Group> | null;
  readonly item: ResourceId<Item>;
  readonly workspaceUser: ResourceId<WorkspaceUser> | null;
}

export interface ItemEffectiveFieldValues extends Model<'itemEffectiveFieldValues'> {
  readonly itemId: number;
  readonly sourceItemsByFieldId: ReadonlyRecord<
    number,
    ReadonlyArray<{
      fieldValueId: number;
      sourceItemId: number;
    }>
  >;
}

export interface ItemMetrics extends Model<'itemMetrics'> {
  readonly loggedWork: number;
  readonly lowRemainingWork: number;
  readonly highRemainingWork: number;
  readonly unusedEffort: number;
  readonly item: ResourceId<Item>;
  readonly lowOnHoldWork: number;
  readonly highOnHoldWork: number;

  // Project counts are only defined for pkgs. Null for other item types.
  readonly scheduledProjectCount: number | null;
  readonly onHoldProjectCount: number | null;
  readonly doneProjectCount: number | null;

  // Task counts are only defined for pkgs/projects/subfolders. Null for other item types.
  readonly activeTaskCount: number | null;
  readonly doneTaskCount: number | null;
  readonly onHoldTaskCount: number | null;

  // Assignment counts are only defined for pkgs/projects/sub-folders/tasks. Null for other item types.
  readonly activeAssignmentCount: number | null;
  readonly doneAssignmentCount: number | null;

  readonly effectiveDoneDate: string | null;
  readonly effectiveTargetStart: string | null;
  readonly effectiveTargetFinish: string | null;
  readonly earliestDoneDate: string | null;
  readonly latestDoneDate: string | null;
  readonly rollupEarliestTargetStart: string | null;
  readonly rollupEarliestActiveTargetFinish: string | null;
  readonly rollupLatestTargetFinish: string | null;

  readonly totalStoryPoints: number | null;
  readonly remainingStoryPoints: number | null;
  readonly completedStoryPoints: number | null;
  readonly onHoldStoryPoints: number | null;
  readonly storyPointSchemeName: string | null;

  readonly workLimitDelta: number | null;
  readonly workLimitAlert: boolean;

  readonly packageStatus: PackageStatus | null;
  readonly folderStatus: FolderStatus | null;
  readonly taskStatus: ResourceId<TaskStatus> | null;
  readonly taskSchedulingType: SchedulingType | null;
}

export interface ItemPin extends Model<'itemPins'> {
  readonly workspaceId: number;
  readonly workspaceUserId: number;
  readonly itemId: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: string | null;
  readonly updatedBy: string | null;
}

export interface Iteration extends Model<'iteration'> {
  readonly done: boolean;
  readonly color: string | null;
  readonly createdAt: string;
  readonly createdById: number;
  readonly name: string;
  readonly description: string | null;
  readonly priority: string;
  readonly updatedAt: string;
  readonly updatedById: number;
  readonly workspaceId: number;

  // Write Only
  readonly relativePriority?: RelativePriority;
}

export interface IterationMetric extends Model<'iterationMetric'> {
  readonly targetStart: string | null;
  readonly targetFinish: string | null;
  readonly lowRemainingWork: number;
  readonly highRemainingWork: number;
  readonly activeTaskCount: number;
  readonly onHoldTaskCount: number;
  readonly doneTaskCount: number;
  readonly activeAssignmentCount: number;
  readonly doneAssignmentCount: number;
  readonly storyPoints: number;
  readonly createdAt: string;
  readonly createdById: number;
  readonly updatedAt: string;
  readonly updatedById: number;
  readonly workspaceId: number;
}

export interface JiraIntegration extends Model<'jiraIntegrations'> {
  readonly name: string;
  readonly url: string;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface JiraAccessibleResourcesView extends Model<'jiraAccessibleResourcesViews'> {
  id: number;
  cloudId: string;
  url: string;
  name: string;
  organizationId: number;
  workspaceId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ConnectionInformation {
  email: string;
  name: string;
  timeConnected: string;
}

export interface JiraOauthAccount {
  readonly oauthCredentialsId: number;
  readonly email: string;
}

export interface JiraOauthResponse {
  readonly returnUrl: string;
  readonly oauthCredentialsId: number;
  readonly accessToken: string;
}

export interface OauthCredentials extends Model<'oauthCredentials'> {
  readonly createdAt: string;
  readonly oauthCredentialsType: SourceSystem;
  readonly sourceId: string;
  readonly updatedAt: string;
  readonly userId: number;
}

export interface ExternalIntegrationFieldMapping extends Model<'externalIntegrationFieldMapping'> {
  readonly lpCustomFieldId: number | null;
  readonly lpSystemField: string | null;
  readonly fieldType: CustomFieldType.DATE | CustomFieldType.NUMERIC | CustomFieldType.TEXT;
  readonly sourceFieldId: string;
  readonly isPull: boolean;
  readonly isPush: boolean;
}
export interface JiraIssueFilter extends Model<'jiraIssueFilters'> {
  issueStatusIds: ReadonlyArray<string>;
  issueTypeIds: ReadonlyArray<string>;
  issueCreatedDate: string;
  externalIntegrationId: number;
}
export interface JiraCredentials extends Model<'jiraCredentials'> {
  readonly email: string;
  readonly siteId: number;
  readonly createdAt: string;
}

export interface JiraMapping extends Model<'jiraMappings'> {
  readonly credentialsId: number;
  readonly itemId: number;
  readonly sourceItemId: string;
  readonly sourceSystem: SourceSystem;
  readonly status: ConnectedStatus;
  readonly createdAt: string;
}

export interface JiraSyncSettings extends Model<'jiraSyncSettings'> {
  syncJiraWorklog: boolean;
  costCodeId: number | null;
  syncTimesheetWorklog: boolean;
  taskGroupHierarchy: TaskGroupHierarchy;
  rankIssues: boolean;
  estimationMapping: EstimationMapping;
  taskPushSetting: TaskPushSetting;
  externalIntegrationId: number;
}

interface EstimationMapping {
  estimationType: EstimationType;
  estimationSettingDto: EstimationSetting | null;
}

interface EstimationSetting {
  type: string;
  action?: StoryPointSchemeActions;
  lowEstimate?: number;
  highEstimate?: number;
  storyPointSchemeDto?: JiraStoryPointScheme;
}

interface JiraStoryPointScheme {
  id?: number;
  displayName: string;
  storyPoints: Array<JiraStoryPointsMapping>;
}

export interface JiraStoryPointsMapping {
  value: number;
  lowEffort: number | null;
  highEffort: number | null;
}

export interface JiraProjectDetails extends Model<'jiraProjectDetails'> {
  readonly types: ReadonlyArray<{ name: string; id: string; hierarchyLevel: number }>;
  readonly statuses: ReadonlyArray<{ name: string; id: string }>;
  readonly hasParentAncestry: boolean;
}

export type Login = Model<'logins'>;

export enum LibraryResourceType {
  Dashboard = 'Dashboard',
  TableReport = 'TableReport',
}

export interface LibraryResource extends Model<'libraryResources'> {
  readonly itemId: string;
  readonly packageStatus: PackageStatus | null;
  readonly name: string | null;
  readonly color: string;
  readonly organizationId: string;
  readonly workspaceId: string;
  readonly dashboardId: string | null;
  readonly widgetId: string | null;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly updatedBy: string | null;
  readonly listType: ListWidgetType | null;
  readonly libraryResourceType: LibraryResourceType;
}

export interface MyWorkItem extends Model<'myWorkItems'> {
  readonly dailyWorkSeconds: Record<string, number>;
  readonly lateByDate: ReadonlyRecord<string, number>;
  readonly project: ResourceId<Item>;
  readonly taskStatus: ResourceId<TaskStatus>;
  readonly start: string | null;
  readonly finish: string | null;
  readonly waiting: boolean;
}

export interface MyWorkGrouping extends Model<'myWorkGroupings'> {
  readonly name: string;
  readonly project: ResourceId<Item> | null;
  readonly taskStatus: ResourceId<TaskStatus> | null;
  readonly myWorkItems: ReadonlyArray<MyWorkItem>;
}

export interface MyWork extends Model<'myWorks'> {
  readonly dates: ReadonlyArray<string>;
  readonly daysUntilStart: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly groupings: ReadonlyArray<MyWorkGrouping>;
  readonly dailyAvailabilitySeconds: ReadonlyRecord<string, number>;
  readonly timeOffByDate: ReadonlyRecord<string, number>;
}

export interface OrganizationUserFormatSettings {
  readonly dateFormat: DateFormat;
  readonly firstDayOfWeek: FirstDayOfWeek;
  readonly locale: string;
  readonly numberFormat: NumberFormat;
  readonly timezone: string;
  readonly timeFormat: TimeFormat;
  readonly dateRanges: ReadonlyArray<DateRange>;
}
export interface OrganizationUserEmailNotifications extends Model<'organizationUserEmailNotifications'> {
  readonly orgId: number;
  readonly orgUserId: number;
  readonly updatedAt: string | null;
  readonly createdAt: string | null;
  readonly discussionMentions: boolean;
  readonly discussionSubscription: boolean;
  readonly notifyAssignedUser: boolean;
  readonly emailFrequency: EmailFrequency;
}

export interface Organization extends Model<'organizations'> {
  readonly featureFlags: OrganizationFeatureFlags;
  readonly flags: OrganizationFlags;
  readonly name: string;
  readonly planId: number;
  readonly lastAccessedAt: string | null;
  readonly lpxWorkspaceId: number | null;
  readonly slackApproved: boolean;

  // WRITE-ONLY props
  readonly creator?: Partial<OrganizationUserFormatSettings>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface OrganizationProjectType extends Model<'organizationProjectTypes'> {
  organizationId: string;
  projectType: OrganizationProjectTypeType;
}

export interface OrganizationUser extends Model<'organizationUsers'>, OrganizationUserFormatSettings {
  readonly admin: boolean;
  readonly avatarColor: string | null;
  readonly avatarUrl: string | null;
  readonly createdAt: string;
  readonly disconnectedAt: string | null;
  readonly lastAccessedAt: string | null;
  readonly hasWsGuestUser: boolean;
  readonly hasActiveWsNonGuestUser: boolean;
  readonly organization: ResourceId<Organization>;
  readonly user: ResourceId<User>;
  readonly username: string;
  readonly weekNumberFormat: WeekNumberFormat;
}

export interface OrganizationUserWithNames extends OrganizationUser {
  lastName?: string;
  firstName?: string;
}

export interface UserWorkspaceSettings extends Model<'userWorkspaceSettings'> {
  readonly autoExpandSubFolders: boolean;
  readonly boardHideEmpty: boolean;
  readonly gridHideDone: boolean;
  readonly searchIncludeDone: boolean;
  readonly timesheetGrouping: TimesheetGroupingType;
  readonly timesheetReviewColumns: ReadonlyArray<string>;
  readonly workloadGrouping: WorkloadGroupingType;
  readonly includeExpiredInvitations: boolean;
  readonly workloadDayRange: WorkloadDayRange;
  readonly portfolioWorkloadGroupIdFilter: number | null;
  readonly packageWorkloadGroupIdFilter: number | null;
  readonly projectWorkloadGroupIdFilter: number | null;
  readonly portfolioWorkloadOrganizationUserIdFilter: number | null;
  readonly packageWorkloadOrganizationUserIdFilter: number | null;
  readonly projectWorkloadOrganizationUserIdFilter: number | null;

  readonly scheduledPortfolioColumns: UserCustomColumn;
  readonly pendingPortfolioColumns: UserCustomColumn;
  readonly archivedPortfolioColumns: UserCustomColumn;

  readonly scheduledProjectColumns: UserCustomColumn;
  readonly pendingProjectColumns: UserCustomColumn;
  readonly archivedProjectColumns: UserCustomColumn;

  readonly scheduledGridProjectColumns: UserCustomColumn;
  readonly pendingGridProjectColumns: UserCustomColumn;
  readonly archivedGridProjectColumns: UserCustomColumn;

  readonly scheduledGridTaskColumns: UserCustomColumn;
  readonly pendingGridTaskColumns: UserCustomColumn;
  readonly archivedGridTaskColumns: UserCustomColumn;

  readonly scheduledGridAssignmentColumns: UserCustomColumn;
  readonly pendingGridAssignmentColumns: UserCustomColumn;
  readonly archivedGridAssignmentColumns: UserCustomColumn;

  readonly scheduledPortfolioGridItemType: ItemType;
  readonly pendingPortfolioGridItemType: ItemType;
  readonly archivedPortfolioGridItemType: ItemType;

  readonly scheduledPackageGridItemType: ItemType;
  readonly pendingPackageGridItemType: ItemType;
  readonly archivedPackageGridItemType: ItemType;

  readonly scheduledProjectGridItemType: ItemType;
  readonly pendingProjectGridItemType: ItemType;
  readonly archivedProjectGridItemType: ItemType;

  readonly organization: ResourceId<Organization>;
  readonly workspace: ResourceId<Workspace>;
  readonly user: ResourceId<User>;
  readonly createdAt: string;
  readonly updatedAt: string;
}

export interface Model<S extends string> {
  readonly id: number;
  readonly permission: Permission;
  readonly type: S;
}

export interface OrganizationUserSlackIntegration extends Model<'organizationUserSlackIntegrations'> {
  readonly orgId: number;
  readonly orgUserId: number;
  readonly createdAt: string;
  readonly slackTeamName: string;
  readonly notifyTaskStatusChanged: boolean;
  readonly notifyTaskAssignmentDeleted: boolean;
  readonly notifyTaskAssignmentDone: boolean;
  readonly notifyTaskAssignmentReopened: boolean;
  readonly notifyTaskAssignmentAssigned: boolean;
  readonly notifyTaskAssignmentUnassigned: boolean;
  readonly notifyTaskDiscussionPostAdded: boolean;
  readonly notifyTaskDiscussionPostMention: boolean;
  readonly notifySiblingAssignmentAdded: boolean;
  readonly notifySiblingAssignmentDone: boolean;
  readonly notifyTaskMoved: boolean;
  readonly notifyTaskScheduleDirectiveChanged: boolean;
  readonly notifyTaskAllDependenciesSatisfied: boolean;
}

export interface PicklistChoice extends Model<'picklistChoices'> {
  readonly archived: boolean;
  readonly color: string | null;
  readonly field: ResourceId<CustomField>;
  readonly name: string;
  readonly priority: string;

  // WRITE-ONLY
  readonly relativePriority?: RelativePriority;
}

enum PlanStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

enum PeriodUnit {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

export interface Plan extends Model<'plans'> {
  readonly family: PlanFamily;
  readonly name: string;
  readonly description: string | null;
  readonly publicPlan: boolean;
  readonly legacyPlan: boolean;
  readonly status: PlanStatus;

  readonly monthlyPriceCents: number;
  readonly priceCents: number;
  readonly pricingModel: PricingModel;
  readonly billingPeriod: BillingPeriod;
  readonly period: number;
  readonly periodUnit: PeriodUnit;
  readonly pricingTiers: ReadonlyArray<PlanPricingTier>;

  readonly maxTaskCount: number | null;
  readonly maxProjectCount: number | null;
  readonly maxWorkspaceCount: number | null;
  readonly freeLicenseCount: number;

  readonly upgradeFamilies: ReadonlyArray<PlanFamily>;
  readonly downgradeFamilies: ReadonlyArray<PlanFamily>;
  readonly upgradeBillingPeriod: ReadonlyArray<BillingPeriod>;
  readonly downgradeBillingPeriod: ReadonlyArray<BillingPeriod>;

  readonly hasDataCustomization: boolean;
  readonly hasUpgradedAccessControls: boolean;
  readonly hasUpgradedTimeTracking: boolean;
}

export interface Portfolio extends Model<'portfolios'> {
  readonly workspace: Workspace;
  readonly scheduledProjectCount: number;
  readonly backlogProjectCount: number;
  readonly archivedProjectCount: number;
  readonly templateProjectCount: number;
  readonly capacityProjectCount: number;
  readonly projects: ReadonlyArray<ResourceId<Item>>;
}

export interface RateData extends Model<'rateData'> {
  readonly monthly: RateTable;
  readonly quarterly: RateTable;
  readonly semiannual: RateTable;
  readonly annual: RateTable;
}

export interface RateSheet extends Model<'rateSheets'> {
  readonly workspaceId: string;
  readonly name: string;
  readonly rateSheetType: RateSheetType;
  readonly archived: boolean;
  readonly archivedAt: string | null;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: ResourceId<User>;
  readonly updatedBy: ResourceId<User>;
}

export interface RateSheetRule extends Model<'rateRules'> {
  readonly rateSheetId: string;
  readonly costCodeId: string | null;
  readonly workspaceUserId: string | null;
  readonly rate: number;
  readonly effectiveFrom: string | null;
  readonly ordinalNumber: number;
  readonly priority: string;
  readonly rateSheetType: RateSheetType;

  // WRITE-ONLY
  readonly relativePriority?: RelativePriority;
}

export interface ResourceId<T extends Model<any>> {
  readonly id: number;
  readonly type: T['type'];
}

export interface SchedulingLimit extends Model<'schedulingLimits'> {
  readonly item: ResourceId<Item>;
  readonly limitSeconds: number;
  readonly limitType: SchedulingLimitType;
  readonly user: ResourceId<User>;

  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
}

export interface StoryPoints extends Model<'storyPoints'> {
  readonly id: number;
  readonly organization: ResourceId<Organization>;
  readonly workspace: ResourceId<Workspace>;
  readonly storyPointScheme: ResourceId<StoryPointScheme>;
  readonly value: number;
  readonly highEffort: number | null;
  readonly lowEffort: number | null;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
  readonly archivedAt: string | null;
  readonly delete?: boolean; // only when editing scheme to delete point
}

export interface StoryPointScheme extends Model<'storyPointSchemes'> {
  readonly id: number;
  readonly organization: ResourceId<Organization>;
  readonly workspace: ResourceId<Workspace>;
  readonly displayName: string;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
}

export interface StoryPointSchemeOwner extends Model<'storyPointSchemeOwners'> {
  readonly id: number;
  readonly organization: ResourceId<Organization>;
  readonly workspace: ResourceId<Workspace>;
  readonly storyPointScheme: ResourceId<StoryPointScheme>;
  readonly item: ResourceId<Item> | null;
  readonly defaultAssignee: ResourceId<OrganizationUser> | null;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User> | null;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User> | null;
}

export interface Subscription extends Model<'subscriptions'> {
  readonly organizationId: number;
  readonly nextBillingOn: string | null;

  readonly freeLicenses: number;
  readonly paidLicenses: number;
  readonly hasScheduledChanges: boolean;
  readonly nextRenewalBillingPeriod: BillingPeriod;
  readonly nextRenewalPlanFamily: PlanFamily;
  readonly nextRenewalPlanQuantity: number;
}

export interface SubjectAccessForItemGrid extends Model<'subjectAccessForItemGrids'> {
  readonly itemId: number; // same as 'id' property
  readonly workspaceManagerRow: SubjectAccessGridRow;
  readonly everyoneRow: SubjectAccessGridRow;
  readonly groupRows: ReadonlyArray<SubjectAccessGridRow>;
  readonly workspaceUserRows: ReadonlyArray<SubjectAccessGridRow>;
}

export interface SystemSettings extends Model<'systemSettings'> {
  readonly columns: SystemColumns;
  readonly currencies: ReadonlyArray<Currency>;
  readonly featureFlagDefaults: OrganizationFeatureFlags;
  readonly timezones: ReadonlyArray<string>;
  readonly allowSlackIntegration: boolean;
  readonly emailRegex: string;
}

export interface Task extends Item {
  readonly itemType: ItemType.TASKS;
  readonly iterationId: number | null;
  readonly taskStatus: ResourceId<TaskStatus>;
  readonly scheduleDirective: ScheduleDirective;
  readonly workType: WorkType;
}

export interface TaskStatus extends Model<'taskStatuses'> {
  readonly archived: boolean;
  readonly color: string;
  readonly name: string;
  readonly priority: string;
  readonly relativePriority?: RelativePriority;
  readonly schedulingType: SchedulingType;
  readonly workspace: ResourceId<Workspace>;
}

export interface Timesheet extends Model<'timesheets'> {
  readonly dates: ReadonlyArray<string>;
  readonly dailyWork: DailyWork;
  readonly grouping: TimesheetGroupingType;
  readonly groups: ReadonlyArray<TimesheetGrouping>;
  readonly work: string;
  readonly weekStart: string;
  readonly previousWeekStart: string;
  readonly nextWeekStart: string;
  readonly weekNumber: number;
}

export interface TimesheetEntry extends Model<'timesheetEntries'> {
  readonly costCode: ResourceId<CostCode> | null;
  readonly date: string;
  readonly item: ResourceId<Item>;
  readonly note: string | null;
  readonly work: string;
  readonly workspaceUser: ResourceId<WorkspaceUser>;
}

export interface TimesheetRollups extends Model<'timesheetRollups'> {
  readonly itemId: number;
  readonly totalLoggedWorkInSeconds: number | null;
  readonly totalScheduledWorkInSeconds: number | null;
  readonly isEmpty: boolean;
  readonly totalWorkLoggedPlusScheduledInSeconds: number | null;
}

export interface AssignmentIdAndCostCodeIds {
  assignmentId: number;
  costCodeIds: ReadonlyArray<number | null>;
}

export interface TimesheetGroupingTaskIdAndAssignmentIds {
  taskId: number;
  assignmentIdsAndCostCodeIds: ReadonlyArray<AssignmentIdAndCostCodeIds>;
}

export interface TimesheetGrouping extends Model<'timesheetGroupings'> {
  readonly dailyWork: DailyWork;
  readonly taskIdsAndAssignments: ReadonlyArray<TimesheetGroupingTaskIdAndAssignmentIds>;
  readonly package: ResourceId<Item> | null;
  readonly project: ResourceId<Item> | null;
  readonly taskStatus: ResourceId<TaskStatus> | null;
  readonly pinnedItems: boolean;
  readonly work: string;
}

export interface TimesheetReview extends Model<'timesheetReviews'> {
  readonly reviewer: ResourceId<WorkspaceUser>;
  readonly userSummaries: ReadonlyArray<TimesheetReviewUserSummary>;
  readonly weekOf: string;
  readonly weeks: ReadonlyArray<WeekData>;
}

export interface TimesheetEntryLock extends Model<'timesheetEntryLocks'> {
  readonly organization: ResourceId<Organization>;
  readonly effectiveThroughDate: string;
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly timesheetEntryLockType: TimesheetLockingType;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
  readonly workspace: ResourceId<Workspace>;
  readonly groupId: number | null;
  readonly workspaceUserId: number | null;
  readonly itemId: number | null;
}

export interface TimesheetEntryLockException extends Model<'timesheetEntryLockExceptions'> {
  readonly createdAt: string;
  readonly createdBy: ResourceId<User>;
  readonly effectiveOnDate: string;
  readonly effectiveThroughDate: string;
  readonly organization: ResourceId<Organization>;
  readonly updatedAt: string;
  readonly updatedBy: ResourceId<User>;
  readonly workspace: ResourceId<Workspace>;
  readonly workspaceUserId: number;
}

export interface TimesheetReviewUser extends Model<'timesheetReviewUsers'> {
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: ResourceId<User>;
  readonly updatedBy: ResourceId<User>;
  readonly reviewUserType: TimesheetReviewOptions;
  readonly reviewer: ResourceId<WorkspaceUser>;
  readonly workspaceUser?: ResourceId<WorkspaceUser>;
  readonly group?: ResourceId<Group>;
}

export interface TimesheetReviewUserSummary extends Model<'timesheetReviewUserSummaries'> {
  readonly weeklyLoggedWorkSeconds: ReadonlyArray<number>;
  readonly workspaceUser: ResourceId<WorkspaceUser>;
}

export interface User extends Model<'users'> {
  readonly createdAt: string;
  readonly countryCode: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  // marketingConsent will be undefined unless permission is manage or higher (OrgAdmins only have edit - they can't see/change the setting for other users)
  readonly marketingConsent?: MarketingConsentStatus;
  readonly userType: UserType;
  readonly unconfirmedEmail: string | null;
  readonly lockedAccount: boolean;
  readonly lpSupportStaff: boolean;

  // WRITE-ONLY
  readonly previousPassword?: string;
  readonly password?: string;
}

export interface UserAuthSettings extends Model<'userAuthSettings'> {
  readonly hasLPAuth: boolean;
  readonly hasSAML: boolean;
}

export interface WidgetGroup extends Model<'widgetGroups'> {
  readonly organizationId: string;
  readonly workspaceId: string;
  readonly dashboardId: string;
  readonly title: string;
  readonly priority: string;
  readonly widgetGroupSize: WidgetGroupSize;

  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: string | null;
  readonly updatedBy: string | null;
  readonly config: WidgetGroupConfig;

  // WRITE-ONLY
  readonly relativePriority?: RelativePriority;
}
interface AppliedDateRangeFilter {
  readonly ranges: ReadonlyArray<StartAndEndDate>;
}

export interface Widget<T = WidgetData | null> extends Model<'widgets'> {
  readonly organizationId: string;
  readonly workspaceId: string;
  readonly dashboardId: string;
  readonly widgetGroupId: string;
  readonly widgetType: WidgetType;
  readonly widgetSize: WidgetSize;
  readonly priority: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly createdBy: string | null;
  readonly updatedBy: string | null;
  readonly color: string | null;
  readonly appliedDateRangeFilter: AppliedDateRangeFilter | null;
  readonly isMetadata: boolean;

  // Each widget will cast data to the appropriate type
  // The backend validates the content of data based on widget type
  readonly data: T;
  readonly config: WidgetConfig;

  // WRITE-ONLY
  readonly relativePriority?: RelativePriority;
}

export interface WidgetWithData extends Widget {
  readonly data: WidgetData;
}

export interface WidgetTotalRows extends Model<'widgetTotalRows'> {
  widgetId: number;
  totalRowData: DeprecatedGridSummaryRow | null;
}

interface WorkloadItemTaskStatus {
  readonly schedulingType: SchedulingType;
  readonly color: string;
  readonly name: string;
}

interface WorkloadItemAncestor {
  readonly id: number;
  readonly name: string | null;
  readonly itemType: ItemType;
}

interface WorkloadItemSchedulingLabels {
  showSchedulingLimitLabel: boolean;
  showDependencyLabel: boolean;
  showRiskLabel: boolean;
  asapStatus: ScheduleDirective;
  activeIteration?: Iteration;
}

export interface WorkloadItem extends Model<'workloadItems'> {
  readonly dailyWorkSeconds: ReadonlyRecord<string, number>;
  readonly effortSeconds: number;
  readonly endDate: string;
  readonly item: ResourceId<Item> | null;
  readonly itemName: string | null;
  readonly itemLate: boolean;
  readonly lateByDate: ReadonlyRecord<string, number>;
  readonly organizationUser: ResourceId<OrganizationUser> | null;
  readonly user: ResourceId<User> | null;
  readonly startDate: string;
  readonly taskStatus: WorkloadItemTaskStatus;
  readonly ancestryBreadcrumbs: Array<WorkloadItemAncestor>;
  readonly schedulingLabels: WorkloadItemSchedulingLabels;
}

export interface WorkloadGroup extends Model<'workloadGroups'> {
  readonly availabilitySeconds: number;
  readonly averageAvailabilitySeconds: number;
  readonly dailyWorkSeconds: ReadonlyRecord<string, number>;
  readonly dailyAvailabilitySeconds: ReadonlyRecord<string, number>;
  readonly effortSeconds: number;
  readonly lateByDate: ReadonlyRecord<string, number>;
  readonly organizationUser: ResourceId<OrganizationUser> | null;
  readonly user: ResourceId<User> | null;
  readonly timeOffByDate: ReadonlyRecord<string, number>;
  readonly workloadItems: ReadonlyArray<WorkloadItem>;
  readonly username: string;
}

export interface Workload extends Model<'workloads'> {
  readonly group: ResourceId<Group> | null;
  readonly item: ResourceId<Item> | null;
  readonly dates: ReadonlyArray<string>;
  readonly daysUntilStart: number;
  readonly groups: ReadonlyArray<WorkloadGroup>;
  readonly startDate: string;
  readonly endDate: string;
}

export interface Workspace extends Model<'workspaces'> {
  readonly costCodeRequired: boolean;
  readonly createdAt: string;
  readonly currencyUnit: string;
  readonly defaultCostCode: ResourceId<CostCode> | null;
  readonly defaultTaskStatus: ResourceId<TaskStatus> | null;
  readonly defaultWorkType: WorkType;
  readonly fileUploads: boolean;
  readonly name: string;
  readonly organization: ResourceId<Organization>;
  readonly workdayFinish: string;
  readonly workdayStart: string;
  readonly workspaceRoot: ResourceId<Item>;
}

export interface WorkspaceUser extends Model<'workspaceUsers'> {
  readonly createdAt: string;
  readonly lastAccessedAt: string | null;
  readonly disconnectedAt: string | null;
  readonly guestUser: boolean;
  readonly manager: boolean;
  readonly timesheetReviewer: boolean;
  readonly timesheetExporter: boolean;
  readonly user: ResourceId<User>;
  readonly workspace: ResourceId<Workspace>;
  readonly organization: ResourceId<Organization>;

  // WRITE-ONLY
  readonly disconnected?: boolean;
}

export interface WorkspaceUserGroup extends Model<'workspaceUserGroups'> {
  readonly createdAt: string;
  readonly group: ResourceId<Group>;
  readonly workspaceUser: ResourceId<WorkspaceUser>;
}
