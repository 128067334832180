import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal, StrictModalProps, Transition } from 'semantic-ui-react';

import './lp_modal.scss';

const DEFAULT_DURATION = 300;
const portal = document.querySelector('#portal');

export const LpModalTransition = ({ children }: { children: ReactNode }) => {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    setShowing(true);

    return () => setShowing(false);
  }, []);

  return (
    <Transition
      animation="scale"
      duration={DEFAULT_DURATION}
      mountOnShow
      transitionOnMount
      unmountOnHide
      visible={showing}
    >
      {children}
    </Transition>
  );
};

export interface LpModalProps extends StrictModalProps {
  actions?: ReactNode;
  centered?: boolean;
  content?: ReactNode;
  header?: ReactNode;
  dataTestId?: string;
}

const LpModal = ({
  centered = false,
  className,
  closeOnDimmerClick = true,
  header,
  content,
  actions,
  onClose,
  size = 'small',
  closeOnEscape,
  dataTestId,
}: LpModalProps) => {
  return (
    <LpModalTransition>
      <Modal
        data-testid={dataTestId ?? 'lp-modal'}
        mountNode={portal}
        className={classNames('lp-modal', className, {
          'lp-modal--centered': centered,
        })}
        closeOnDimmerClick={closeOnDimmerClick}
        open={true}
        size={size}
        onClose={onClose}
        closeOnEscape={closeOnEscape}
      >
        {header && <Modal.Header>{header}</Modal.Header>}
        {content && <Modal.Content>{content}</Modal.Content>}
        {actions && <Modal.Actions>{actions}</Modal.Actions>}
      </Modal>
    </LpModalTransition>
  );
};

export default LpModal;
