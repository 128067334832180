import classNames from 'classnames';
import { DropdownProps } from 'semantic-ui-react';

import {
  addOptionalTextToCustomColumn,
  getFormattedDateOrEmptyString,
  getHoursOrEmptyStringWithNoRounding,
  optionalTextForCustomColumn,
} from 'containers/shared/custom_column/display_helpers';
import {
  CustomFieldWidgetConfigColumn,
  ItemDateProps,
  TimesheetRollupValueProps,
} from 'containers/shared/custom_column/types';
import { CustomFieldNameBySourceType } from 'containers/shared/custom_field_name';
import { CustomFieldType, ItemType } from 'daos/enums';
import { FolderStatus, FolderStatusToFolderStatusDisplayMap } from 'daos/item_enums';
import { CustomField, Item } from 'daos/model_types';
import { toggleOffRegular, toggleOnRegular } from 'features/common/lp_icon';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { TEXT_ALIGN_LEFT } from 'lib/constants';
import { formatItemNameList } from 'lib/display_helpers/format_item_name';
import { camelCaseToKabobCase } from 'lib/helpers';
import { ReadonlyRecord } from 'lib/readonly_record';
import { gray600, green600 } from 'style/variables';

import { Columns, CustomColumnHeaderLocation, StandardColumns } from './enum';

import { customColumnDefinitions, defaultWidths } from '.';

export const customColumnHeaderClassName = ({
  className,
  columnPositionClassName,
  customColumn,
}: {
  className: string | undefined;
  columnPositionClassName: string;
  customColumn: CustomFieldType | Columns;
}) => {
  return `${className} ${className}-${columnPositionClassName}--${camelCaseToKabobCase(customColumn)}`;
};

export const customColumnProjectClassName = ({
  className,
  customColumnLocation,
  customColumn,
  isScheduleColumnExpanded,
  isItemChecked = false,
}: {
  className: string;
  customColumnLocation: string;
  customColumn: CustomFieldType | Columns | string;
  isScheduleColumnExpanded: boolean;
  isItemChecked?: boolean;
}) => {
  const classNameWithColumn = `${className}-${customColumnLocation}`;
  return classNames(
    className,
    classNameWithColumn,
    customColumn && `${className}-${customColumnLocation}--${camelCaseToKabobCase(customColumn)}`,
    isScheduleColumnExpanded && `${className}--${isScheduleColumnExpanded ? 'hidden' : 'expanded'}`,
    isItemChecked && 'checked'
  );
};

export const customFieldOptions = (customFields: ReadonlyArray<CustomField>): DropdownProps['options'] => {
  return Object.values(customFields)
    .map((field: CustomField) => {
      return {
        key: field.name + field.id,
        text: `${field.name} ${optionalTextForCustomColumn(String(field.id))}`,
        value: field.id,
      };
    })
    .sort(({ text: a }: { text: string }, { text: b }: { text: string }) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
};

export const getProjectOrFolderStatusDisplay = (columnOption: Columns, item?: Item) => {
  const isProjectAndProjectStatus =
    item?.itemType === ItemType.PROJECTS && columnOption === StandardColumns.ProjectStatus;
  const isSubFolderAndFolderStatus =
    item?.itemType === ItemType.FOLDERS && columnOption === StandardColumns.FolderStatus;

  if (isProjectAndProjectStatus || isSubFolderAndFolderStatus) {
    return FolderStatusToFolderStatusDisplayMap[item.folderStatus as FolderStatus];
  }

  return 'N/A';
};

interface ItemDataOption {
  readonly key: Columns;
  readonly text: string;
  readonly value: Columns;
}

export const itemDataOption = (customColumn: Columns): ItemDataOption => {
  return {
    key: customColumn,
    text: addOptionalTextToCustomColumn(customColumn),
    value: customColumn,
  };
};

export const planningFieldOptions = (isProjectHub: boolean) => {
  if (isProjectHub) {
    return [
      itemDataOption(StandardColumns.Assigned),
      itemDataOption(StandardColumns.WorkType),
      itemDataOption(StandardColumns.PriorityRush),
      itemDataOption(StandardColumns.TargetStart),
      itemDataOption(StandardColumns.TargetStartDelta),
      itemDataOption(StandardColumns.TargetFinish),
      itemDataOption(StandardColumns.TargetFinishDelta),
      itemDataOption(StandardColumns.TargetFinishType),
    ];
  }

  return [
    itemDataOption(StandardColumns.TargetStart),
    itemDataOption(StandardColumns.TargetStartDelta),
    itemDataOption(StandardColumns.TargetFinish),
    itemDataOption(StandardColumns.TargetFinishDelta),
    itemDataOption(StandardColumns.TargetFinishType),
  ];
};

export const itemMetricOptions = (location: CustomColumnHeaderLocation) => {
  if (location === CustomColumnHeaderLocation.ProjectHub) {
    return [
      itemDataOption(StandardColumns.AssignmentCountTotal),
      itemDataOption(StandardColumns.AssignmentCountActive),
      itemDataOption(StandardColumns.AssignmentCountDone),
    ];
  }
  const taskCounts = [
    itemDataOption(StandardColumns.TaskCountTotal),
    itemDataOption(StandardColumns.TaskCountActive),
    itemDataOption(StandardColumns.TaskCountDone),
    itemDataOption(StandardColumns.TaskCountOnHold),
  ];

  const projectCounts = [
    itemDataOption(StandardColumns.ProjectCountTotal),
    itemDataOption(StandardColumns.ProjectCountScheduled),
    itemDataOption(StandardColumns.ProjectCountDone),
    itemDataOption(StandardColumns.ProjectCountOnHold),
  ];

  if (location === CustomColumnHeaderLocation.PortfolioGrid) {
    return [...projectCounts, ...taskCounts];
  }

  return taskCounts;
};

export const predictiveFieldOptions = [
  itemDataOption(StandardColumns.ExpectedFinish),
  itemDataOption(StandardColumns.LatestFinish),
  itemDataOption(StandardColumns.FinishRange),
  itemDataOption(StandardColumns.ExpectedStart),
  itemDataOption(StandardColumns.DoneDate),
];

export const otherPropertiesFieldOptions = ({
  isProjectHub,
  fileUploads,
  hasUpgradedTimeTracking,
}: {
  isProjectHub: boolean;
  fileUploads: boolean;
  hasUpgradedTimeTracking: boolean;
}) => {
  const output = [
    itemDataOption(StandardColumns.Description),
    itemDataOption(StandardColumns.CreatedAt),
    itemDataOption(StandardColumns.CreatedBy),
    itemDataOption(StandardColumns.UpdatedAt),
    itemDataOption(StandardColumns.UpdatedBy),
    itemDataOption(StandardColumns.ItemId),
  ];

  if (hasUpgradedTimeTracking && !isProjectHub) {
    output.splice(1, 0, itemDataOption(StandardColumns.PayRateSheet));
    output.splice(1, 0, itemDataOption(StandardColumns.BillingRateSheet));
  }

  if (fileUploads) {
    output.splice(1, 0, itemDataOption(StandardColumns.ItemFileCount));
  }

  if (isProjectHub) {
    output.splice(1, 0, itemDataOption(StandardColumns.TaskStatus));
  } else {
    output.splice(1, 0, itemDataOption(StandardColumns.ProjectStatus));
  }
  return output;
};
export const workMetricsOptions = (isProjectHub: boolean, hasBenchmarkEstimateEnabled?: boolean) => {
  return [
    itemDataOption(StandardColumns.WorkLimit),
    itemDataOption(StandardColumns.WorkLimitDelta),
    hasBenchmarkEstimateEnabled ? itemDataOption(StandardColumns.BenchmarkEstimate) : null,
    itemDataOption(StandardColumns.TotalWorkRange),
    itemDataOption(StandardColumns.TotalWorkExpected),
    itemDataOption(StandardColumns.TotalWorkLow),
    itemDataOption(StandardColumns.TotalWorkHigh),
    itemDataOption(StandardColumns.Logged),
    itemDataOption(StandardColumns.RemainingWorkRange),
    itemDataOption(StandardColumns.RemainingWorkExpected),
    itemDataOption(StandardColumns.RemainingWorkLow),
    itemDataOption(StandardColumns.RemainingWorkHigh),
    itemDataOption(StandardColumns.ClippedEffort),
    itemDataOption(StandardColumns.UncertainWork),
    itemDataOption(StandardColumns.OnHoldHours),
    itemDataOption(StandardColumns.UnusedEffortExpected),
    itemDataOption(StandardColumns.PercentComplete),
    isProjectHub ? null : itemDataOption(StandardColumns.PercentTasksComplete),
  ].filter((option): option is ItemDataOption => !!option);
};

export const agilePlanningOptions = () => {
  return [
    itemDataOption(StandardColumns.StoryPoint),
    itemDataOption(StandardColumns.StoryPointCountActive),
    itemDataOption(StandardColumns.StoryPointCountDone),
    itemDataOption(StandardColumns.StoryPointCountOnHold),
  ].filter((option): option is ItemDataOption => !!option);
};

export const getTabNameForColumnOptionAndItemType = (columnOption: Columns, itemType: ItemType) => {
  const planningTab = itemType === ItemType.TASKS ? TabNames.Assignments : TabNames.Planning;
  const loggedAndTotalWorkTab = itemType === ItemType.TASKS ? TabNames.Assignments : TabNames.Properties;
  const remainingWorkRangeTab = itemType === ItemType.TASKS ? TabNames.Assignments : TabNames.Scheduling;

  switch (columnOption) {
    /** Tab names vary by item type */
    case StandardColumns.Assigned:
    case StandardColumns.AssignmentCountActive:
    case StandardColumns.AssignmentCountDone:
    case StandardColumns.AssignmentCountTotal:
    case StandardColumns.BenchmarkEstimate:
    case StandardColumns.Description:
    case StandardColumns.ProjectStatus:
    case StandardColumns.TaskStatus:
    case StandardColumns.WorkLimit:
    case StandardColumns.WorkLimitDelta:
    case StandardColumns.WorkType:
      return planningTab;
    case StandardColumns.Logged:
    case StandardColumns.TotalWorkExpected:
    case StandardColumns.TotalWorkRange:
      return loggedAndTotalWorkTab;
    case StandardColumns.RemainingWorkRange:
      return remainingWorkRangeTab;

    /** Tab names do not vary by item type */
    case StandardColumns.ClippedEffort:
    case StandardColumns.DoneDate:
    case StandardColumns.ExpectedFinish:
    case StandardColumns.ExpectedStart:
    case StandardColumns.FinishRange:
    case StandardColumns.LatestFinish:
    case StandardColumns.OnHoldHours:
    case StandardColumns.PercentComplete:
    case StandardColumns.PercentTasksComplete:
    case StandardColumns.PriorityRush:
    case StandardColumns.RemainingWorkExpected:
    case StandardColumns.RemainingWorkHigh:
    case StandardColumns.RemainingWorkLow:
    case StandardColumns.TimesheetScheduledBillableHours:
    case StandardColumns.TimesheetScheduledNonBillableHours:
    case StandardColumns.StoryPoint:
    case StandardColumns.StoryPointCountActive:
    case StandardColumns.StoryPointCountOnHold:
    case StandardColumns.StoryPointCountDone:
    case StandardColumns.TargetFinish:
    case StandardColumns.TargetFinishType:
    case StandardColumns.TargetStart:
    case StandardColumns.TaskCountActive:
    case StandardColumns.TaskCountDone:
    case StandardColumns.TaskCountOnHold:
    case StandardColumns.TaskCountTotal:
    case StandardColumns.TotalWorkBillable:
    case StandardColumns.TotalWorkNonBillable:
    case StandardColumns.UncertainWork:
    case StandardColumns.UnusedEffortExpected:
      return TabNames.Scheduling;
    case StandardColumns.CreatedAt:
    case StandardColumns.CreatedBy:
    case StandardColumns.CustomField:
    case StandardColumns.TotalWorkHigh:
    case StandardColumns.TotalWorkLow:
    case StandardColumns.UpdatedBy:
    case StandardColumns.UpdatedAt:
      return TabNames.Properties;
    case StandardColumns.ItemFileCount:
      return TabNames.Files;

    case StandardColumns.BillingRateSheet:
    case StandardColumns.PayRateSheet:
      return itemType === ItemType.PROJECTS ? planningTab : '';

    /* We don't want the LP ID column to open the item panel */
    case StandardColumns.ItemId:
    default:
      return '';
  }
};

export const getLocationBreadcrumb = (
  {
    packageStatusCollection,
    packageName,
    projectName,
    subFolders,
    taskName,
  }: {
    packageStatusCollection: string;
    packageName: string;
    projectName: string;
    subFolders: string;
    taskName: string;
  },
  itemType?: ItemType
) => {
  switch (itemType) {
    case ItemType.PACKAGES:
      return packageStatusCollection;

    case ItemType.PROJECTS:
      return packageName;

    case ItemType.FOLDERS:
      return formatItemNameList([packageName, projectName]);

    case ItemType.TASKS:
      return formatItemNameList([packageName, projectName, subFolders]);

    case ItemType.ASSIGNMENTS:
      return formatItemNameList([packageName, projectName, subFolders, taskName]);

    default:
      return '';
  }
};

export const getIsNonNoteCustomFieldApplicableForItemType = (field?: CustomField, itemType?: ItemType) => {
  if (!itemType || !field || field.fieldType === CustomFieldType.NOTE) {
    return false;
  }

  const { inheritable, onPackages, onProjectFolders, onTasks } = field;
  const inheritableFromPackages = inheritable && onPackages;
  const inheritableFromProjects = inheritable && onProjectFolders;
  const inheritableFromTasks = inheritable && onTasks;

  switch (itemType) {
    case ItemType.PACKAGES:
      return onPackages;

    case ItemType.PROJECTS:
    case ItemType.FOLDERS:
      return onProjectFolders || inheritableFromPackages;

    case ItemType.TASKS:
      return onTasks || inheritableFromPackages || inheritableFromProjects;

    case ItemType.ASSIGNMENTS:
      return inheritableFromPackages || inheritableFromProjects || inheritableFromTasks;

    default:
      return false;
  }
};

export const getCheckboxColorAndIcon = (checked: boolean) => {
  return {
    color: checked ? green600 : gray600,
    icon: checked ? toggleOnRegular : toggleOffRegular,
  };
};

export const getItemDateValuesByColumn = ({
  formatLocalDate,
  itemDateProps,
}: {
  formatLocalDate: (date: string) => string;
  itemDateProps: ItemDateProps;
}): ReadonlyRecord<string, { value: string; inherited?: boolean; isAtRisk?: boolean }> => {
  const getDateOrEmptyString = (value: string | undefined) => getFormattedDateOrEmptyString(value, formatLocalDate);

  const {
    createdAt,
    doneDate,
    expectedFinish,
    expectedStart,
    finishRange,
    latestFinish,
    targetFinish,
    targetStart,
    updatedAt,
  } = itemDateProps;

  return {
    [StandardColumns.CreatedAt]: { value: getDateOrEmptyString(createdAt) },
    [StandardColumns.DoneDate]: { value: getDateOrEmptyString(doneDate) },
    [StandardColumns.ExpectedFinish]: {
      value: getDateOrEmptyString(expectedFinish?.finish),
      isAtRisk: expectedFinish?.isAtRisk,
    },
    [StandardColumns.ExpectedStart]: { value: getDateOrEmptyString(expectedStart) },
    [StandardColumns.FinishRange]: {
      value: `${getDateOrEmptyString(finishRange?.expected)} - ${getDateOrEmptyString(finishRange?.latest)}`,
      isAtRisk: finishRange?.isAtRisk,
    },
    [StandardColumns.LatestFinish]: {
      value: getDateOrEmptyString(latestFinish?.finish),
      isAtRisk: latestFinish?.isAtRisk,
    },
    [StandardColumns.TargetFinish]: {
      value: getDateOrEmptyString(targetFinish?.finish),
      inherited: targetFinish?.inherited,
      isAtRisk: targetFinish?.isAtRisk,
    },
    [StandardColumns.TargetStart]: {
      value: getDateOrEmptyString(targetStart?.start),
      inherited: targetStart?.inherited,
    },
    [StandardColumns.UpdatedAt]: { value: getDateOrEmptyString(updatedAt) },
  };
};

export const timesheetRollupValueForColumn = ({
  column,
  timesheetRollupProps,
}: {
  column: Columns;
  timesheetRollupProps: TimesheetRollupValueProps;
}) => {
  switch (column) {
    case StandardColumns.Logged:
      return getHoursOrEmptyStringWithNoRounding(timesheetRollupProps.totalLoggedWorkInHours);
    case StandardColumns.TimesheetScheduled:
      return getHoursOrEmptyStringWithNoRounding(timesheetRollupProps.totalScheduledWorkInHours);
    case StandardColumns.TotalWorkScheduled:
      return getHoursOrEmptyStringWithNoRounding(timesheetRollupProps.totalWorkLoggedPlusScheduledInHours);
    default:
      return null;
  }
};

export const customFieldWidgetConfigColumnProps = (
  customField: CustomField | undefined
): CustomFieldWidgetConfigColumn => {
  const customFieldName = customField?.name ?? 'Custom Field';
  const customFieldSourceSystem = customField?.sourceSystem ?? null;

  const editedCustomFieldName = (
    <CustomFieldNameBySourceType name={customFieldName} sourceSystem={customFieldSourceSystem} />
  );
  return {
    customFieldId: customField?.id ?? 0,
    customFieldName: editedCustomFieldName,
    customFieldType: customField?.fieldType ?? null,
    customFieldSourceSystem,
    customFieldArchived: customField?.archived ?? null,
  };
};

export const customColumnDefaultProps = (customColumn: Columns) => {
  return {
    name: customColumnDefinitions[customColumn]?.displayText ?? customColumn,
    key: customColumn,
    cellClass: customColumnDefinitions[customColumn]?.systemCellClass ?? TEXT_ALIGN_LEFT,
    headerCellClass: TEXT_ALIGN_LEFT,
    width: customColumnDefinitions[customColumn]?.widths.grid ?? defaultWidths.grid,
    resizable: true,
    sortable: customColumnDefinitions[customColumn]?.sortable ?? false,
  };
};
