import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'rateData',
  type: 'RATE_DATA',
});

const { RATE_DATA, resourceId } = resource;

interface RateDataParams {
  organizationId: number;
}

const fetch = (params: RateDataParams) =>
  request(backend.plansRateData.url(params), RATE_DATA, { method: HttpMethod.GET });

export const RateDataDao = {
  id: resourceId,
  fetch,
} as const;
