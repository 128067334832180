import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Button, Popup, Table } from 'semantic-ui-react';

import ConfirmDeleteModal from 'containers/shared/confirm_delete_modal';
import { OrganizationDao } from 'daos/organization';
import { OrganizationApiTokens, OrganizationApiToken } from 'daos/organization_api_tokens';
import { OrganizationUserDao } from 'daos/organization_user';
import { ApiHeader } from 'features/api/header';
import { ApiAccessTableRow } from 'features/api/row';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import FeatureTrialBannerWithIcon from 'features/common/feature_trial/feature_trial_banner_with_icon';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { questionCircleSolid, LpIcon, codeSolid } from 'features/common/lp_icon';
import { useUserFirstLastOrUsername } from 'hooks/use_user_first_last_or_username';
import { awaitRequestFinish } from 'lib/api';
import NewWindowLink from 'lib/display_helpers/url_links';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import { useFetchApiTokensAndUsers } from './use_fetch_api_tokens_and_users';

import './index.scss';

const ApiAccessTableHeaderCell = ({ cellLabel, toolTip }: { cellLabel: string; toolTip?: ReactNode }) => (
  <Table.HeaderCell
    textAlign="center"
    content={
      <>
        <span>{cellLabel}</span>
        {!!toolTip && (
          <Popup
            content={toolTip}
            position="top center"
            trigger={<LpIcon className="help-icon" icon={questionCircleSolid} />}
          />
        )}
      </>
    }
  />
);

export const ApiAccess = () => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organization = useSelector(getCurrentOrganization);
  const currentOrgUser = useSelector(getCurrentOrganizationUser);
  const currentOrgUserDisplayName = useUserFirstLastOrUsername(currentOrgUser?.user.id ?? 0);

  const organizationId = organization?.id ?? 0;
  const isAdmin = !!currentOrgUser?.admin;
  const hasApi = !!organization?.flags.hasApi;

  const { apiTokens, setApiTokens, orgUsers, fetchApiTokens, fetchOrgUsers, fetchingInProgress } =
    useFetchApiTokensAndUsers();

  const [deleteTokenId, setDeleteTokenId] = useState<number | undefined>();
  const handleDeleteTokenClick = (tokenId: number) => () => setDeleteTokenId(tokenId);
  const closeConfirmationModal = () => setDeleteTokenId(undefined);

  const handleCreateTokenClick = () => {
    if (currentOrgUser && currentOrgUserDisplayName) {
      const { uuid } = dispatch(
        OrganizationApiTokens.create(
          {
            organizationId,
          },
          {
            organizationId: OrganizationDao.id(organizationId),
            organizationUserId: OrganizationUserDao.id(currentOrgUser.id),
            description: `New API Token for ${currentOrgUser.username}`,
            tokenActive: true,
          }
        )
      );

      dispatch(
        awaitRequestFinish<OrganizationApiToken>(uuid, {
          onSuccess: ({ data }) => {
            setApiTokens((prev) => [data, ...prev]);
          },
        })
      );
    }
  };

  const handleConfirmDelete = () => {
    if (deleteTokenId) {
      const { uuid } = dispatch(
        OrganizationApiTokens.delete({
          organizationId,
          tokenId: deleteTokenId,
        })
      );

      dispatch(
        awaitRequestFinish<Array<OrganizationApiToken>>(uuid, {
          onSuccess: () => {
            setApiTokens((prev) => prev.filter((apiToken) => apiToken.id !== deleteTokenId));
            closeConfirmationModal();
          },
        })
      );
    }
  };

  useEffect(() => {
    fetchApiTokens();
    fetchOrgUsers();
  }, [fetchApiTokens, fetchOrgUsers]);

  if (!isAdmin) {
    return <Redirect to={frontend.workspaceHub.url({ organizationId, workspaceId })} />;
  }

  return (
    <>
      <ApiHeader />
      <div className="api">
        {fetchingInProgress ? (
          <LpOverlayLoader />
        ) : (
          <>
            {!hasApi && (
              <FeatureTrialBannerWithIcon
                icon={codeSolid}
                title="API is an ULTIMATE only feature"
                description="LiquidPlanner API enables you to retrieve data and build integrations with other tools your team uses."
                className="workspace-settings__banner"
                showTrialButton={false}
                seePlansButtonText="Upgrade to ULTIMATE"
              />
            )}
            <div className="top-section">
              <Button onClick={handleCreateTokenClick} disabled={!hasApi}>
                Create New Token
              </Button>
              <p>
                Below are the tokens that can be used to access the LiquidPlanner API.
                <br />
                See our <NewWindowLink to="http://api-docs.liquidplanner.com/">API Documentation</NewWindowLink> to
                learn more.
              </p>
            </div>
            <Table collapsing celled>
              <Table.Header>
                <Table.Row>
                  <ApiAccessTableHeaderCell cellLabel="Enabled" />
                  <ApiAccessTableHeaderCell
                    cellLabel="Name"
                    toolTip={<p>Enter a custom name to identify the token.</p>}
                  />
                  <ApiAccessTableHeaderCell
                    cellLabel="Token Owner"
                    toolTip={
                      <p>
                        API Tokens inherit the Token Owner&apos;s access level.
                        <br />
                        Token Owners with Project Editor access or higher are able to make changes via their API Token.
                        <br />
                        Updates made with an API Token are recorded in Changes and attributed to the Token Owner.
                      </p>
                    }
                  />
                  <ApiAccessTableHeaderCell
                    cellLabel="API Token"
                    toolTip={
                      <p>
                        This is used to authenticate requests to the LiquidPlanner API.
                        <br />
                        Add &quot;Bearer&quot; as a prefix for the token in the Authorization header.
                        <br />
                        For example: Bearer &lt;API token&gt;.
                      </p>
                    }
                  />
                  <ApiAccessTableHeaderCell cellLabel="Created On" />
                  <ApiAccessTableHeaderCell cellLabel="Created By" />
                  <ApiAccessTableHeaderCell cellLabel="Delete" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {apiTokens.map((apiToken) => (
                  <ApiAccessTableRow
                    apiToken={apiToken}
                    handleDeleteTokenClick={handleDeleteTokenClick}
                    key={apiToken.id}
                    orgUsers={orgUsers}
                    readOnly={!hasApi}
                    setApiTokens={setApiTokens}
                  />
                ))}
              </Table.Body>
            </Table>
          </>
        )}
        {!!deleteTokenId && (
          <ConfirmDeleteModal
            onDelete={handleConfirmDelete}
            onClose={closeConfirmationModal}
            confirmButtonText="Delete"
            content={
              <>
                You are about to delete{' '}
                <em>{apiTokens.find((apiToken) => deleteTokenId === apiToken.id)?.description ?? 'this'}</em> token.
              </>
            }
          />
        )}
      </div>
    </>
  );
};
