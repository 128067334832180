import ProgressBar from 'containers/shared/progress_bar';
import './progress_bars.scss';

const InsightProgressBar = ({ percent, legend }: { percent: number; legend: string }) => {
  return (
    <div className="insight-progress-bar">
      <div>
        <span className="insight-progress-bar__percent">{percent}%</span> {legend}
      </div>
      <ProgressBar percent={percent} size="small" />
    </div>
  );
};
export const ProgressBars = ({
  percentComplete,
  percentTaskComplete,
}: {
  percentComplete: number;
  percentTaskComplete: number;
}) => {
  return (
    <div className="insights-widget__insight-block-progress-bars">
      <InsightProgressBar percent={percentComplete} legend="Hours Complete" />
      <InsightProgressBar percent={percentTaskComplete} legend="Tasks Complete" />
    </div>
  );
};
