import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { CourseDao } from 'features/academy/daos/course';
import { LessonProgressDao } from 'features/academy/daos/lesson_progress';
import { createCourseTrackViewUrl, trackViewUrl } from 'features/academy/helpers';
import { getNextTrackIdInCourse, getNextTrackLesson } from 'features/academy/modal/next_lesson';
import {
  getCoursesSortedByPriority,
  getTrackLessonsSortedByPriorityForTrack,
  getTracksSortedByPriorityForCourse,
} from 'features/academy/selectors';
import { getCurrentUserLessonProgressForLessonId } from 'features/academy/selectors/progress';
import { LpButtonAsLink } from 'features/common/as_components';
import LpLoaderAnimatedGrid from 'features/common/loaders/lp_item_panel_loader';
import { checkCircleSolid, LpIcon, squareRegular } from 'features/common/lp_icon';
import LpTabbedModal from 'features/common/modals/lp_tabbed_modal';
import { awaitRequestFinish } from 'lib/api';

import AcademyModalHeader from './header';
import AcademyModalBody from './modal-content';
import NextCourseButton from './modal_footer_next_course_button';

import './index.scss';

interface AcademyModalParams {
  courseId: string;
  trackId: string;
  lessonId?: string;
  autoPlay: boolean;
}

const AcademyModal = ({ courseId, trackId, lessonId: urlParamLessonId, autoPlay }: AcademyModalParams) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const sortedTrackLessons = useSelector((state) => getTrackLessonsSortedByPriorityForTrack(state, trackId));
  const firstTrackLesson = sortedTrackLessons[0];

  const lessonId = urlParamLessonId ?? firstTrackLesson?.academyLessonId;

  const fetchCourse = useCallback(() => {
    const { uuid } = dispatch(
      CourseDao.fetch(
        { courseId },
        {
          include: {
            includeAcademyTrack: true,
            includeAcademyLesson: true,
            includeAcademyTrackLesson: true,
            includeMyAcademyTrackProgress: true,
            includeMyAcademyLessonProgress: true,
          },
        }
      )
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }, [courseId, dispatch]);

  const handlePanelClose = () => {
    history.push(location.pathname);

    // keep the store fresh if someone has marked a lesson finished.
    fetchCourse();
  };

  useEffect(() => {
    fetchCourse();
  }, [fetchCourse]);

  if (!lessonId) {
    return <LpTabbedModal className="lp-academy-modal" onClose={handlePanelClose} content={<LpLoaderAnimatedGrid />} />;
  }

  if (!urlParamLessonId) {
    return <Redirect to={createCourseTrackViewUrl({ courseId, trackId, lessonId })} />;
  }

  const academyPanelContent = {
    header: <AcademyModalHeader courseId={courseId} trackId={trackId} lessonId={lessonId} />,
    content: <AcademyModalBody courseId={courseId} trackId={trackId} lessonId={lessonId} autoPlay={autoPlay} />,
    actions: (
      <AcademyModalFooter handleClose={handlePanelClose} courseId={courseId} trackId={trackId} lessonId={lessonId} />
    ),
  };

  return <LpTabbedModal className="lp-academy-modal" onClose={handlePanelClose} {...academyPanelContent} />;
};

interface AcademyModalFooterParams {
  handleClose: () => void;
  courseId: string;
  trackId: string;
  lessonId: string;
}

const AcademyModalFooter = ({ handleClose, courseId, trackId, lessonId }: AcademyModalFooterParams) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const lessonProgress = useSelector((state) => getCurrentUserLessonProgressForLessonId(state, lessonId));
  const courseTracks = useSelector((state) => getTracksSortedByPriorityForCourse(state, courseId));
  const nextTrackIdInCourse = getNextTrackIdInCourse({
    courseTracks,
    trackId,
  });

  const courses = useSelector(getCoursesSortedByPriority);
  const courseIds = courses.map((course) => String(course.id));
  const [nextCourseId, setNextCourseId] = useState<string | undefined>(undefined);
  const [fetchComplete, setFetchComplete] = useState(false);

  const trackLessonsInCurrentTrack = useSelector((state) => getTrackLessonsSortedByPriorityForTrack(state, trackId));

  const isLastLessonInCurrentTrack =
    trackLessonsInCurrentTrack[trackLessonsInCurrentTrack.length - 1]?.academyLessonId === lessonId;
  const trackLessonsInNextTrack = useSelector((state) =>
    getTrackLessonsSortedByPriorityForTrack(state, nextTrackIdInCourse)
  );

  const nextTrackLesson = getNextTrackLesson({
    currentLessonId: lessonId,
    trackLessonsInCurrentTrack,
    trackLessonsInNextTrack,
  });

  const isLastLessonInLastTrack = !nextTrackIdInCourse && !nextTrackLesson?.academyLessonId;

  useEffect(() => {
    if (!nextCourseId || (nextCourseId === courseId && isLastLessonInLastTrack)) {
      const nextCourseIdToView = courseIds[courseIds.indexOf(courseId) + 1];
      setNextCourseId(nextCourseIdToView);
    }
  }, [courseId, courseIds, isLastLessonInLastTrack, nextCourseId]);

  const buttonText = useMemo(() => {
    if (isLastLessonInLastTrack && nextCourseId === undefined) {
      return 'Next Course';
    }
    if (isLastLessonInCurrentTrack) {
      return 'Next Track';
    }
    return 'Next Lesson';
  }, [isLastLessonInLastTrack, isLastLessonInCurrentTrack, nextCourseId]);

  useEffect(() => {
    if (!nextCourseId && !fetchComplete) {
      const { uuid } = dispatch(
        CourseDao.fetchAll({
          include: {
            includeAcademyTrackLesson: true,
          },
        })
      );

      dispatch(awaitRequestFinish(uuid, { onFinish: () => setFetchComplete(true) }));
    }
  }, [dispatch, fetchComplete, isLastLessonInLastTrack, nextCourseId]);

  const markLessonNotDone = useCallback(() => {
    if (!lessonProgress?.id) {
      return;
    }

    dispatch(LessonProgressDao.destroy({ lessonId, progressId: String(lessonProgress.id) }));
  }, [dispatch, lessonId, lessonProgress]);

  const markLessonDone = useCallback(() => {
    const { uuid } = dispatch(LessonProgressDao.create({ academyLessonId: lessonId }));

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          history.push(
            trackViewUrl({
              courseId,
              trackId: nextTrackLesson?.academyTrackId ?? '',
              lessonId: nextTrackLesson?.academyLessonId ?? '',
              path: location.pathname,
            })
          );

          const { uuid } = dispatch(
            CourseDao.fetch(
              { courseId },
              {
                include: {
                  includeAcademyTrack: true,
                  includeAcademyLesson: true,
                  includeAcademyTrackLesson: true,
                  includeMyAcademyProgress: true,
                  includeMyAcademyTrackProgress: true,
                  includeMyAcademyLessonProgress: true,
                },
              }
            )
          );

          dispatch(awaitRequestFinish(uuid, {}));
        },
      })
    );
  }, [
    courseId,
    dispatch,
    history,
    lessonId,
    location.pathname,
    nextTrackLesson?.academyLessonId,
    nextTrackLesson?.academyTrackId,
  ]);

  return (
    <div className="lp-academy-modal__footer-actions">
      {lessonProgress ? (
        <Button className="lp-academy-modal__footer-actions-finished" onClick={markLessonNotDone}>
          <LpIcon icon={checkCircleSolid} />
          Done
        </Button>
      ) : (
        <Button className="lp-academy-modal__footer-actions-finished" onClick={markLessonDone}>
          <LpIcon icon={squareRegular} />
          Got It, Next
        </Button>
      )}

      {isLastLessonInLastTrack && nextCourseId ? (
        <NextCourseButton courseId={nextCourseId} />
      ) : (
        <LpButtonAsLink
          to={trackViewUrl({
            courseId,
            trackId: nextTrackLesson?.academyTrackId ?? '',
            lessonId: nextTrackLesson?.academyLessonId ?? '',
            path: location.pathname,
          })}
          key="next-btn"
          className="lp-academy-modal__footer-actions-next"
          disabled={!nextTrackLesson}
        >
          {buttonText}
        </LpButtonAsLink>
      )}

      <Button
        key="close-btn"
        onClick={handleClose}
        content="Close"
        className="lp-academy-modal__footer-actions-close"
      />
    </div>
  );
};

export default AcademyModal;
