import { createSelector } from 'reselect';

import { Dashboard, DashboardGuest, Organization, Workspace } from 'daos/model_types';
import { getCurrentUserId } from 'features/common/current/selectors';
import { getDashboardsById } from 'features/dashboards/selectors';
import { readonlyArray, readonlyRecord } from 'lib/readonly_record';
import { getOrganizationsById } from 'redux/entities/selectors/organization';
import { getNumberArgument } from 'redux/entities/selectors/shared';
import { getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId } from 'redux/entities/selectors/user';
import { getWorkspacesById } from 'redux/entities/selectors/workspace';
import { RootState } from 'redux/root_reducer';

const getDashboardGuestsById = (state: RootState) => state.entities.dashboardGuests;

export const getDashboardGuests = createSelector(getDashboardGuestsById, (dashGuestsById) =>
  readonlyArray(Object.values(dashGuestsById))
);

export const getDashboardGuestForDashboardIdWorkspaceUserIdAndWorkspaceId = createSelector(
  getDashboardGuests,
  (_: RootState, dashboardId: number) => dashboardId,
  (_: RootState, __dashboardId: number, workspaceUserId: number) => workspaceUserId,
  (_: RootState, __dashboardId: number, ___workspaceUserId: number, workspaceId: number) => workspaceId,
  (dashGuests, dashboardId, workspaceUserId, workspaceId) =>
    dashGuests.find(
      (dg) =>
        Number(dg.dashboardId) === dashboardId &&
        Number(dg.workspaceUserId) === workspaceUserId &&
        Number(dg.workspaceId) === workspaceId
    )
);

const getCurrentUserActiveDashboardGuests = createSelector(
  getDashboardGuests,
  getCurrentUserId,
  (dashboardGuests, currentUserId) =>
    readonlyArray(dashboardGuests.filter((dg) => !dg.disconnectedAt && dg.userId === String(currentUserId)))
);

export const getCurrentUserHasGuestAccessToDashboard = createSelector(
  getCurrentUserActiveDashboardGuests,
  (_: RootState, orgId: string) => orgId,
  (_: RootState, _orgId: string, wsId: string) => wsId,
  (_: RootState, _orgId: string, _wsId: string, dashboardId: string) => dashboardId,
  (activeDashboardGuests, orgId, wsId, dashboardId) => {
    return activeDashboardGuests.some(
      (activeDashGuest) =>
        activeDashGuest.organizationId === orgId &&
        activeDashGuest.workspaceId === wsId &&
        activeDashGuest.dashboardId === dashboardId
    );
  }
);

export const getCurrentUserDashboardGuestCount = createSelector(
  getCurrentUserActiveDashboardGuests,
  (dashboardGuests) => dashboardGuests.length
);

export function reduceDashboardGuests(
  acc: Record<string, Record<string, Array<string>>>,
  dashboardGuest: DashboardGuest
) {
  const dashboardIdsByWsId = acc[dashboardGuest.organizationId] ?? {};
  const dashboardIds = dashboardIdsByWsId[dashboardGuest.workspaceId] ?? [];

  if (!dashboardGuest.disconnectedAt) {
    acc[dashboardGuest.organizationId] = {
      ...dashboardIdsByWsId,
      [dashboardGuest.workspaceId]: [...dashboardIds, dashboardGuest.dashboardId],
    };
  }

  return acc;
}

const getCurrentUserActiveGuestDashboardIdsByWsAndOrgId = createSelector(
  getCurrentUserActiveDashboardGuests,
  (dashboardGuests) => {
    const dashboardIdsByWsAndOrgId = dashboardGuests.reduce(reduceDashboardGuests, {});
    return readonlyRecord(dashboardIdsByWsAndOrgId);
  }
);

export const getCurrentUserDashboardGuestOrgsSortedByName = createSelector(
  getCurrentUserActiveDashboardGuests,
  getOrganizationsById,
  (dashboardGuests, orgsById) => {
    const orgIdSet = new Set<string>();

    dashboardGuests.forEach((dashGuest) => orgIdSet.add(dashGuest.organizationId));
    const sortedOrgsByName = Array.from(orgIdSet)
      .reduce((acc: Array<Organization>, organizationId) => {
        const organization = orgsById[organizationId];

        if (organization) {
          acc.push(organization);
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));

    return readonlyArray(sortedOrgsByName);
  }
);

export const getCurrentUserActiveGuestSortedWorkspacesKeyedByOrgIdMap = createSelector(
  getCurrentUserActiveGuestDashboardIdsByWsAndOrgId,
  getCurrentUserDashboardGuestOrgsSortedByName,
  getWorkspacesById,
  (dashboardGuestLookup, sortedOrgsByName, wsById) => {
    const sortedWorkspacesByOrgIdMap = new Map<string, Array<Workspace>>();

    sortedOrgsByName.forEach((org) => {
      const sortedWorkspaces = Object.keys(dashboardGuestLookup[org.id] ?? {})
        .reduce((acc: Array<Workspace>, wsId) => {
          const workspace = wsById[wsId];

          if (workspace) {
            acc.push(workspace);
          }
          return acc;
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name));

      sortedWorkspacesByOrgIdMap.set(String(org.id), sortedWorkspaces);
    });

    return sortedWorkspacesByOrgIdMap;
  }
);

export const getCurrentUserActiveGuestSortedDashboardsKeyedByWsIdMap = createSelector(
  getCurrentUserActiveGuestDashboardIdsByWsAndOrgId,
  getCurrentUserActiveGuestSortedWorkspacesKeyedByOrgIdMap,
  getDashboardsById,
  (dashboardGuestLookup, sortedWorkspacesByOrgIdMap, dashboardsById) => {
    const sortedDashboardsByWsIdMap = new Map<string, Array<Dashboard>>();

    sortedWorkspacesByOrgIdMap.forEach((sortedWorkspaces, orgId) => {
      sortedWorkspaces.forEach((workspace) => {
        const dashboardIds = dashboardGuestLookup[orgId]?.[workspace.id] ?? [];

        const sortedDashboards = dashboardIds
          .reduce((acc: Array<Dashboard>, dashId) => {
            const dashboard = dashboardsById[dashId];

            if (dashboard) {
              acc.push(dashboard);
            }
            return acc;
          }, [])
          .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

        sortedDashboardsByWsIdMap.set(String(workspace.id), sortedDashboards);
      });
    });

    return sortedDashboardsByWsIdMap;
  }
);

export const getWorkspaceUserConnectedGuestDashboardsCount = createSelector(
  getDashboardGuests,
  getNumberArgument,
  (dashboardGuests, workspaceUserId) =>
    dashboardGuests.filter((guest) => guest.workspaceUserId === workspaceUserId.toString() && !guest.disconnectedAt)
      .length
);

export const getGuestWorkspacePassportsForUserId = createSelector(
  (state: RootState) => state,
  getNumberArgument,
  getWorkspacesById,
  (state, userId, workspacesById) => {
    const workspaceUsersByWorkspaceId = getCurrentOrganizationWorkspaceUsersForUserIdByWorkspaceId(state, userId);

    return Object.values(workspacesById)
      .filter((workspace) => !!workspaceUsersByWorkspaceId[workspace.id]?.guestUser)
      .map((workspace) => ({
        id: workspace.id,
        name: workspace.name,
        disconnected: !!workspaceUsersByWorkspaceId[workspace.id]?.disconnectedAt,
        workspaceUserId: workspaceUsersByWorkspaceId[workspace.id]?.id ?? 0,
      }));
  }
);
