import { Table } from 'semantic-ui-react';

interface SelectionListHeaderProps {
  title: string;
}

const SelectionListHeader = ({ title }: SelectionListHeaderProps) => {
  return (
    <Table className="multi-select-list-modal__content-list-header">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{title}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </Table>
  );
};

export default SelectionListHeader;
