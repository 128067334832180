import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { LpIcon, cloudCheckSolid } from 'features/common/lp_icon';
import { getOrganizationUserInCurrentOrgForUserId, getUserForId } from 'redux/entities/selectors/user';

interface UserNameCell {
  userId: number;
  isEvicted: boolean;
}

const OrganizationDirectoryAdminsNameCell = ({ userId, isEvicted }: UserNameCell) => {
  const user = useSelector((state) => getUserForId(state, userId));
  const orgUser = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, userId));

  if (!user) {
    return null;
  }

  return (
    <Table.Cell>
      {orgUser?.admin && <LpIcon className="admin-icon" icon={cloudCheckSolid} />}
      <span className={classNames({ 'evicted-user': !!isEvicted })}>
        {user.firstName} {user.lastName}
      </span>
    </Table.Cell>
  );
};

export default OrganizationDirectoryAdminsNameCell;
