export enum WorkspaceLeftNavMenuUrlKeys {
  Academy = 'academy',
  Administration = 'administration',
  DashboardPassport = 'dashboardPassport',
  Favorites = 'favorites',
  Library = 'library',
  MyWork = 'myWork',
  MyDiscussions = 'myDiscussions',
  People = 'people',
  PremiumFeaturesTrial = 'organizationHubUpgrade',
  Projects = 'projectsHome',
  UltimateTrial = 'organizationHubUltimateTrial',
  Iterations = 'Iterations',
}
