import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { OrganizationSsoSettingsDao, OrganizationSsoSettings } from 'daos/organization_sso_settings';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { setApiError } from 'features/errors/slice';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import { SingleSignOnHeader } from './header';
import SingleSignOnRoutes from './routes';

import './index.scss';

export const SingleSignOn = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isAdmin = useSelector(getCurrentOrganizationUser)?.admin;

  const [organizationSsoSettings, setOrganizationSsoSettings] = useState<OrganizationSsoSettings | undefined>(
    undefined
  );

  const [isFetchComplete, setIsFetchComplete] = useState(false);

  const createSSO = useCallback(() => {
    const { uuid } = dispatch(OrganizationSsoSettingsDao.create({ organizationId }, {}));

    dispatch(
      awaitRequestFinish<OrganizationSsoSettings>(uuid, {
        onSuccess: (response) => {
          const singleSignOnItemForOrg = response.data;
          setOrganizationSsoSettings(singleSignOnItemForOrg);
        },
      })
    );
  }, [dispatch, organizationId]);

  const fetchSSO = useCallback(() => {
    setIsFetchComplete(false);
    const { uuid } = dispatch(OrganizationSsoSettingsDao.fetchCurrentOrgSettings({ organizationId }));

    dispatch(
      awaitRequestFinish<OrganizationSsoSettings>(uuid, {
        onSuccess: (response) => {
          const singleSignOnItemForOrg = response.data;
          setOrganizationSsoSettings(singleSignOnItemForOrg);
        },

        onError: ({ errors }) => {
          const error = errors[0];
          if (error) {
            if (error.status === 404) {
              createSSO();
            } else {
              dispatch(setApiError(error));
            }
          }
        },
        onFinish: () => setIsFetchComplete(true),
      })
    );
  }, [createSSO, dispatch, organizationId]);

  useEffect(() => {
    fetchSSO();
  }, [fetchSSO]);

  if (!isAdmin) {
    return <Redirect to={frontend.workspaceHub.url({ organizationId, workspaceId })} />;
  }

  return (
    <>
      <SingleSignOnHeader />

      <div className="single-sign-on">
        {isFetchComplete && organizationSsoSettings ? (
          <SingleSignOnRoutes organizationSsoSettings={organizationSsoSettings} fetchSSO={fetchSSO} />
        ) : (
          <LpOverlayLoader />
        )}
      </div>
    </>
  );
};
