import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { isMultipleValueField } from 'daos/custom_field';
import { getCustomFieldFilterKeyForFieldType } from 'daos/custom_field_filter';
import { CustomFieldType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import { getPicklistChoicesSortedByPriorityForFieldId } from 'redux/entities/selectors/custom_field';

import CustomFieldValueInput from './custom_field_value_input';
import {
  addCustomFieldPropToState,
  removeCustomFieldPropFromState,
  updateCustomFieldPropInState,
  updateMultiValueField,
} from './helpers';

interface CustomFieldPropertiesProps {
  field: CustomField;
  onChange: (value: ReadonlyArray<CustomFieldFilterWithRequiredId>) => void;
  value: ReadonlyArray<CustomFieldFilterWithRequiredId>;
  disabled: boolean;
}

const CustomFieldProperties = ({ field, onChange, value, disabled }: CustomFieldPropertiesProps) => {
  const isMultiCustomFieldType = isMultipleValueField(field);
  const picklistOptions = useSelector((state) => getPicklistChoicesSortedByPriorityForFieldId(state, field.id));
  const customFieldFilterKey = getCustomFieldFilterKeyForFieldType(field.fieldType);
  const selectedCustomFieldProp = value.find((cfProp) => cfProp.customFieldId === field.id);

  const multiSelectedInitialValue =
    isMultiCustomFieldType && selectedCustomFieldProp && customFieldFilterKey
      ? selectedCustomFieldProp[customFieldFilterKey] ?? []
      : [];
  const [multiSelectedValues, setMultiSelectedValues] = useState(multiSelectedInitialValue);

  useEffect(() => {
    if (isMultiCustomFieldType && !selectedCustomFieldProp) {
      setMultiSelectedValues([]);
    }
  }, [isMultiCustomFieldType, selectedCustomFieldProp]);

  const fieldType = field.fieldType;
  const isCheckboxFieldType = fieldType === CustomFieldType.CHECKBOX;

  const setOtherCustomFieldProperties = useCallback(
    (
      fn: (prevValue: ReadonlyArray<CustomFieldFilterWithRequiredId>) => ReadonlyArray<CustomFieldFilterWithRequiredId>
    ) => {
      onChange(fn(value));
    },
    [onChange, value]
  );

  const handleChange = useCallback(
    (index?: number) => (value: number | boolean | undefined) => {
      if (!customFieldFilterKey) {
        return;
      }

      if (index !== undefined && isMultiCustomFieldType) {
        return updateMultiValueField({
          field,
          value: value as number | undefined,
          index,
          selectedCustomFieldProp,
          setOtherCustomFieldProperties,
          setMultiSelectedValues,
        });
      }

      if (isCheckboxFieldType) {
        if (value === true) {
          const newProp = { customFieldId: field.id, checkedValues: [value] };
          return setOtherCustomFieldProperties((prevState) => addCustomFieldPropToState({ prevState, newProp }));
        } else {
          return setOtherCustomFieldProperties((prevState) =>
            removeCustomFieldPropFromState({ fieldId: field.id, prevState })
          );
        }
      }

      if (selectedCustomFieldProp) {
        if (value) {
          const updatedProp = { customFieldId: field.id, [customFieldFilterKey]: [value] };
          return setOtherCustomFieldProperties((prevState) =>
            updateCustomFieldPropInState({ fieldId: field.id, prevState, updatedProp })
          );
        } else {
          setOtherCustomFieldProperties((prevState) =>
            removeCustomFieldPropFromState({ fieldId: field.id, prevState })
          );
        }
      } else if (value) {
        const newProp = { customFieldId: field.id, [customFieldFilterKey]: [value] };
        return setOtherCustomFieldProperties((prevState) => addCustomFieldPropToState({ prevState, newProp }));
      }
    },
    [
      customFieldFilterKey,
      field,
      isCheckboxFieldType,
      isMultiCustomFieldType,
      selectedCustomFieldProp,
      setOtherCustomFieldProperties,
    ]
  );

  const remainingOptionChoices = Boolean(picklistOptions && picklistOptions?.length - multiSelectedValues.length);

  const emptyMultiValueInput = useMemo(() => {
    const index = multiSelectedValues.length;
    if (fieldType === CustomFieldType.MULTI_PICKLIST) {
      return remainingOptionChoices ? (
        <CustomFieldValueInput
          key={`empty${field.id}`}
          field={field}
          handleChange={handleChange(index)}
          selectedMultiValues={multiSelectedValues}
          disabled={disabled}
        />
      ) : null;
    }

    return (
      <CustomFieldValueInput
        key={`empty${field.id}`}
        field={field}
        handleChange={handleChange(index)}
        selectedMultiValues={multiSelectedValues}
        disabled={disabled}
      />
    );
  }, [disabled, fieldType, field, handleChange, multiSelectedValues, remainingOptionChoices]);

  const multiCustomFieldInputs = multiSelectedValues.map((value, idx) => {
    return (
      <CustomFieldValueInput
        key={`${value}${field.id}`}
        field={field}
        handleChange={handleChange(idx)}
        selectedValue={value}
        selectedMultiValues={multiSelectedValues}
        disabled={disabled}
      />
    );
  });

  if (!field || !customFieldFilterKey) {
    return null;
  }

  return (
    <>
      {isMultiCustomFieldType ? (
        <>
          {multiCustomFieldInputs}
          {emptyMultiValueInput}
        </>
      ) : (
        <CustomFieldValueInput
          field={field}
          handleChange={handleChange()}
          selectedValue={selectedCustomFieldProp?.[customFieldFilterKey]?.[0]}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default CustomFieldProperties;
