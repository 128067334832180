export enum Events {
  ORGANIZATION_MODIFIED = 'OrganizationModified',
  RELOAD_APPLICATION = 'ReloadApplication',
  RESCHEDULE_COMPLETE = 'RescheduleComplete',
  NEW_DISCUSSION_POSTS = 'NewDiscussionPosts',
  INTEGRATION_SYNC_COMPLETED = 'IntegrationSyncCompleted',
  ASYNC_JOB = 'AsyncJob',
}

export enum EventScopes {
  DataGrid = 'DATA_GRID',
  GroupTaskBoard = 'GROUP_TASK_BOARD',
  ItemPanel = 'ITEM_PANEL',
  IterationsList = 'ITERATIONS_LIST',
  ManageAccount = 'MANAGE_ACCOUNT',
  MyWorkTasks = 'MY_WORK_TASKS',
  MyDiscussions = 'MY_WORK_DISCUSSIONS',
  MyWorkTaskBoard = 'MY_WORK_TASK_BOARD',
  None = '',
  PortfolioGrid = 'PORTFOLIO_GRID',
  PortfolioGridRoute = 'PORTFOLIO_GRID_ROUTE',
  PortfolioGridSinglePackage = 'PORTFOLIO_GRID_SINGLE_PACKAGE',
  ProfileTaskBoard = 'PROFILE_TASK_BOARD',
  ProjectHub = 'PROJECT_HUB',
  ProjectHubTaskBoard = 'PROJECT_HUB_TASK_BOARD',
  PortfolioGridTaskBoard = 'PORTFOLIO_GRID_TASK_BOARD',
  ProjectHubTaskList = 'PROJECT_HUB_TASK_LIST',
  TimesheetReview = 'TIMESHEET_REVIEW',
  Timesheet = 'TIMESHEET',
  WidgetGrid = 'WIDGET_GRID',
  WidgetTaskBoard = 'WIDGET_TASK_BOARD',
  Workload = 'WORKLOAD',
}

export enum JobResultStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
export interface JobEventData {
  readonly jobId: string;
}
export interface JobEventDataResult extends JobEventData {
  readonly organizationId: string;
  readonly workspaceId: string;
  readonly result: JobResultStatus;
}
export interface JobEventDataWithUrl extends JobEventData {
  readonly exportUrl: string;
}
