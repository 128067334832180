import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import parseQueryString from 'lib/parse_query_string';

const useQueryParams = (): Record<string, string> => {
  const queryString = useLocation().search;
  return useMemo(() => parseQueryString(queryString), [queryString]);
};

export default useQueryParams;
