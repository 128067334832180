import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import ProgressBar, { ProgressBarColorTheme } from 'containers/shared/progress_bar';
import { backend } from 'daos/urls';
import { getLessonForId, getTrackForId, getTrackLessonsSortedByPriorityForTrack } from 'features/academy/selectors';
import { getCurrentUserTrackProgressForTrackId } from 'features/academy/selectors/progress';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { urlSearchParams } from 'lib/url_builder';
import { frontend } from 'lib/urls';

import './index.scss';

interface AcademyTrackCardProps {
  courseId: string;
  trackId: number;
}

export const AcademyTrackCard = ({ courseId, trackId }: AcademyTrackCardProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const track = useSelector((state) => getTrackForId(state, trackId));
  const trackProgress = useSelector((state) => getCurrentUserTrackProgressForTrackId(state, trackId.toString()));
  const lessons = useSelector((state) => getTrackLessonsSortedByPriorityForTrack(state, trackId.toString()));
  const hasLessons = lessons.length;

  if (!track || !hasLessons) {
    return null;
  }

  return (
    <LpLink to={frontend.track.url({ organizationId, workspaceId, courseId, trackId })} className="academy-track-card">
      <AcademyImageCard imageId={track.trackImageId} />
      <ProgressBar size="tiny" percent={trackProgress?.progress ?? 0} colorTheme={ProgressBarColorTheme.Academy} />
      <span className="academy-track-card__title">{track.title}</span>
    </LpLink>
  );
};

interface AcademyImageCardProps {
  imageId: string | null;
}

export const AcademyImageCard = ({ imageId }: AcademyImageCardProps) => {
  return imageId ? (
    <Image
      src={backend.academyImageDownload.url({ imageId })}
      draggable={false}
      className="academy-track-card__image"
    />
  ) : (
    <div className="academy-track-card__image" />
  );
};

export const AcademyTrackCardPreview = ({ courseId, trackId }: AcademyTrackCardProps) => {
  const lessons = useSelector((state) => getTrackLessonsSortedByPriorityForTrack(state, trackId.toString()));
  const track = useSelector((state) => getTrackForId(state, trackId));
  const firstTrackAcademyLessonId = lessons[0]?.academyLessonId;
  const firstLesson = useSelector((state) => getLessonForId(state, firstTrackAcademyLessonId ?? ''));

  const trackImageId = track?.trackImageId;

  const imageSrc = trackImageId ? backend.academyImageDownload.url({ imageId: trackImageId }) : undefined;

  const lessonHashParams = urlSearchParams({
    courseId,
    trackId,
    lessonId: firstTrackAcademyLessonId,
  });

  if (!firstTrackAcademyLessonId || !firstLesson) {
    return null;
  }

  const lessonLocation = `${frontend.portableCourse.url({ courseId })}${
    lessonHashParams ? `#${lessonHashParams}&autoPlay=true` : ``
  }`;

  return (
    <div className="academy-track-card-preview">
      <ImageTile lessonLocation={lessonLocation} imageSrc={imageSrc} />
    </div>
  );
};

interface ImageTileProps {
  lessonLocation: string;
  imageSrc?: string;
}

const ImageTile = ({ lessonLocation, imageSrc = '' }: ImageTileProps) => {
  return (
    <LpLink
      to={lessonLocation}
      className={classNames('ui', 'image', 'academy-track-card-preview-image', {
        'academy-track-card-preview-no-image': !imageSrc,
      })}
    >
      <img src={imageSrc} />
    </LpLink>
  );
};
