import { MutableRefObject, RefObject, useState } from 'react';

export interface ClickLocation {
  ref?: MutableRefObject<HTMLSpanElement> | RefObject<HTMLSpanElement>;
  top: number;
  left: number;
}

export const useClickLocationForContextMenu = () => {
  const [clickLocation, setClickLocation] = useState<ClickLocation | undefined>(undefined);
  return { clickLocation, setClickLocation };
};
