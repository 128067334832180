import { request } from 'lib/api';

import { TaskStatus } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { createBody, updateBody, resource } = defineModel({
  apiType: 'taskStatuses',
  relationships: [],
  type: 'TASK_STATUS',
});

interface TaskStatusIncludes {
  includeAllTaskStatuses?: boolean;
}

const includes = ({ includeAllTaskStatuses }: TaskStatusIncludes = {}) =>
  reduceIncludedOptions([includeAllTaskStatuses && 'workspace.taskStatuses']);

interface TaskStatusesParams {
  organizationId: number;
  workspaceId: number;
}

interface SingleTaskStatusParams extends TaskStatusesParams {
  taskStatusId: number;
}

interface TaskStatusOptions {
  include?: TaskStatusIncludes;
}

const { TASK_STATUS, resourceId } = resource;

const taskStatusCreate = (
  params: TaskStatusesParams,
  taskStatus: Partial<TaskStatus>,
  { include }: TaskStatusOptions = {}
) => request(backend.taskStatuses.url(params, { include: includes(include) }), TASK_STATUS, createBody(taskStatus));

const taskStatusUpdate = (
  params: SingleTaskStatusParams,
  taskStatus: Partial<TaskStatus>,
  { include }: TaskStatusOptions = {}
) =>
  request(
    backend.taskStatus.url(params, { include: includes(include) }),
    TASK_STATUS,
    updateBody(params.taskStatusId, taskStatus)
  );

export const TaskStatusDao = {
  create: taskStatusCreate,
  id: resourceId,
  update: taskStatusUpdate,
} as const;
