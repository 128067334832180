import { useSelector } from 'react-redux';

import { LpColorHex } from 'daos/item_enums';
import { backend } from 'daos/urls';
import { PortableDashboardLinkButton } from 'features/common/buttons/portable_links/portable_dashboard_link_button';
import { getDashboardForId } from 'features/dashboards/selectors';
import { DashboardHeaderRenderer } from 'features/dashboards_v2/dashboard/header';

export const DashboardGuestHeader = ({
  dashboardId,
  organizationId,
}: {
  dashboardId: string;
  organizationId: string;
}) => {
  const dashboard = useSelector((state) => getDashboardForId(state, dashboardId));

  if (!dashboard) {
    return null;
  }

  const { color, dashboardType, defaultName, headerImageS3Id, headerText, workspaceId } = dashboard;

  const imageUrl = headerImageS3Id
    ? backend.dashboardImage.url({ organizationId, workspaceId, s3Id: headerImageS3Id })
    : undefined;

  return (
    <DashboardHeaderRenderer
      actions={
        <PortableDashboardLinkButton
          dashboardName={defaultName}
          dashboardId={dashboardId}
          workspaceId={Number(workspaceId)}
          organizationId={Number(organizationId)}
        />
      }
      color={color ?? LpColorHex.EARL_GRAY}
      dashboardType={dashboardType}
      headerText={headerText}
      imageUrl={imageUrl}
      name={defaultName}
    />
  );
};
