import classNames from 'classnames';

import './lp_form.scss';

export enum LpFormLabelStyleModifier {
  Bold = 'bold',
  Italic = 'italic',
}

const LpFormLabel = ({
  text,
  style,
  className = '',
}: {
  text: string;
  style?: LpFormLabelStyleModifier;
  className?: string;
}) => {
  return <label className={classNames(className, 'lp-form__label', style && `lp-form__label--${style}`)}>{text}</label>;
};

export default LpFormLabel;
