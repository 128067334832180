import { useSelector } from 'react-redux';

import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { writeUrlToClipboard } from 'features/common/portable_link_handler/copy_rich_text';
import { ItemPortableLink } from 'lib/clipboard/portable_links';
import { getItemsForIdsSortedByGlobalPriority } from 'redux/entities/selectors/item';

import { PortableLinkButton } from './portable_link_button';

interface PortableLinkProps {
  itemId: number;
  dashboardId?: never;
  selectedItemIds?: never;
  isGridView?: never;
  onEllipsisMenuCopySuccess?: () => void;
  primary?: boolean;
  showText?: never;
}

interface MultiplePortableLinkProps {
  itemId?: never;
  dashboardId?: never;
  selectedItemIds: ReadonlyArray<number>;
  isGridView?: never;
  onEllipsisMenuCopySuccess?: () => void;
  primary?: boolean;
  showText?: never;
}

interface PortableLinkButtonOnlyProps {
  itemId?: never;
  dashboardId?: never;
  selectedItemIds?: never;
  isGridView?: boolean;
  onEllipsisMenuCopySuccess?: () => void;
  primary?: boolean;
  showText?: never;
}

type PortableLinkButtonProps = PortableLinkProps | MultiplePortableLinkProps | PortableLinkButtonOnlyProps;

const PortableItemLinkButton = ({
  isGridView,
  itemId,
  selectedItemIds,
  onEllipsisMenuCopySuccess,
  primary = false,
  showText,
}: PortableLinkButtonProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const itemIds = getItemIds(selectedItemIds, itemId);
  const items = useSelector((state) => getItemsForIdsSortedByGlobalPriority(state, itemIds));

  const copyLink = () =>
    writeUrlToClipboard(...items.map((item) => new ItemPortableLink(item.id, organizationId, workspaceId, item?.name)));

  const selectedItemsLength = selectedItemIds?.length ?? 0;
  const isMultipleSelection = selectedItemsLength > 1;

  return (
    <PortableLinkButton
      primary={primary}
      isMultipleSelection={isMultipleSelection}
      copyLinkAction={copyLink}
      showText={showText}
      onEllipsisMenuCopySuccess={onEllipsisMenuCopySuccess}
      isGridView={isGridView}
    />
  );
};

export default PortableItemLinkButton;

function getItemIds(selectedItemIds: ReadonlyArray<number> | undefined, itemId: number | undefined) {
  if (selectedItemIds) {
    return selectedItemIds;
  }

  if (itemId) {
    return [itemId];
  }

  return [];
}
