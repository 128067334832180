import { ReactNode, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown } from 'semantic-ui-react';

import { DashboardDao } from 'daos/dashboards';
import { PackageStatus, Permission } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { LibraryResourceDao } from 'daos/library_resource';
import { LibraryResource } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import {
  IconDefinition,
  LpIcon,
  bookOpenRegular,
  copyLight,
  paperPlaneLight,
  trashSolid,
} from 'features/common/lp_icon';
import { writeUrlToClipboard } from 'features/common/portable_link_handler/copy_rich_text';
import { useLibraryContext } from 'features/library/context';
import { useHasAccess } from 'hooks/use_has_access';
import { awaitRequestFinish } from 'lib/api';
import {
  DashboardPortableLink,
  LibraryCollectionPortableLink,
  LibraryPortableLink,
  PortableLink,
  ReportPortableLink,
} from 'lib/clipboard/portable_links';

const LibraryRowActionDropdown = ({ children }: { children: ReactNode }) => {
  const [usingDropdown, setUsingDropdown] = useState(false);

  const triggerRef = useRef<HTMLSpanElement>(null);

  const openDropdown = () => setUsingDropdown(true);
  const hideDropdown = () => setUsingDropdown(false);

  return (
    <PortalActionDropdown triggerRef={triggerRef} onOpen={openDropdown} onClose={hideDropdown} open={usingDropdown}>
      {children}
    </PortalActionDropdown>
  );
};

const libraryRowActionOptions: {
  open: (onClick: () => void) => JSX.Element;
  copy: (onClick: () => void) => JSX.Element;
  duplicate: (isDisabled: boolean, onClick: () => void) => JSX.Element;
  delete: (isDisabled: boolean, onClick: () => void) => JSX.Element;
  divider: () => JSX.Element;
} = {
  open: (onClick: () => void) => {
    return (
      <Dropdown.Item onClick={onClick}>
        <LpIcon icon={bookOpenRegular} className="icon" /> Open
      </Dropdown.Item>
    );
  },
  copy: (onClick: () => void) => {
    return (
      <Dropdown.Item onClick={onClick}>
        <LpIcon icon={paperPlaneLight} className="icon" /> Copy Link
      </Dropdown.Item>
    );
  },
  duplicate: (isDisabled: boolean, onClick: () => void) => {
    return (
      <Dropdown.Item onClick={onClick} disabled={isDisabled}>
        <LpIcon icon={copyLight} className="icon" /> Duplicate
      </Dropdown.Item>
    );
  },
  delete: (isDisabled: boolean, onClick: () => void) => {
    return (
      <Dropdown.Item onClick={onClick} disabled={isDisabled}>
        <LpIcon icon={trashSolid} className="icon" /> Delete
      </Dropdown.Item>
    );
  },
  divider: () => {
    return <Dropdown.Divider />;
  },
};

export const LibraryResourceRowActionDropdown = ({
  libraryResource,
  openRoute,
  libraryResourceIcon,
}: {
  libraryResource: LibraryResource;
  openRoute: string;
  libraryResourceIcon: IconDefinition;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { name, color, dashboardId, widgetId } = libraryResource;

  const { setShowDeleteModal, setDeleteModalData, fetchLibraryViewData, inputValue } = useLibraryContext();

  const hasManageAccessOnLibraryItem = useHasAccess(Permission.MANAGE, ItemDao.id(Number(libraryResource.itemId)));

  const copyLibraryResourceLink = () => {
    let portableLink: PortableLink;

    if (dashboardId) {
      portableLink = new DashboardPortableLink(dashboardId, organizationId, workspaceId, name);
    } else {
      portableLink = new ReportPortableLink(Number(widgetId), organizationId, workspaceId, name);
    }

    return writeUrlToClipboard(portableLink);
  };

  const deleteLibraryResource = (deleteActionFollowUp: () => void) => {
    let dispatchAction = undefined;

    if (dashboardId) {
      dispatchAction = DashboardDao.destroy({ organizationId, workspaceId, dashboardId });
    }

    if (widgetId) {
      dispatchAction = WidgetDao.deleteReportWidget({ organizationId, workspaceId, widgetId });
    }

    if (dispatchAction) {
      const { uuid } = dispatch(dispatchAction);

      dispatch(
        awaitRequestFinish(uuid, {
          onFinish: () => deleteActionFollowUp(),
        })
      );
    }
  };

  const confirmDeleteLibraryResource = () => {
    setShowDeleteModal(true);
    setDeleteModalData({
      content: (
        <>
          You are about to delete
          <LpIcon className="library-view__delete-item-icon" icon={libraryResourceIcon} color={`#${color}`} />
          <strong>{name}</strong>
        </>
      ),
      deleteAction: deleteLibraryResource,
    });
  };

  const openLibraryResource = () => history.push(openRoute);

  const duplicateLibraryResource = () => {
    const { uuid } = dispatch(
      LibraryResourceDao.duplicate({
        organizationId,
        workspaceId,
        libraryResourceId: libraryResource.id,
      })
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => fetchLibraryViewData(inputValue),
      })
    );
  };

  return (
    <LibraryRowActionDropdown>
      {libraryRowActionOptions.open(openLibraryResource)}
      {libraryRowActionOptions.copy(copyLibraryResourceLink)}
      {libraryRowActionOptions.duplicate(!hasManageAccessOnLibraryItem, duplicateLibraryResource)}
      {libraryRowActionOptions.divider()}
      {libraryRowActionOptions.delete(!hasManageAccessOnLibraryItem, confirmDeleteLibraryResource)}
    </LibraryRowActionDropdown>
  );
};

export const SearchDashboardRowActionDropdown = ({
  libraryResource,
  openRoute,
}: {
  libraryResource: LibraryResource;
  openRoute: string;
}) => {
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { name, dashboardId, widgetId } = libraryResource;

  const copyLibraryResourceLink = () => {
    let portableLink: PortableLink;

    if (dashboardId) {
      portableLink = new DashboardPortableLink(dashboardId, organizationId, workspaceId, name);
    } else {
      portableLink = new ReportPortableLink(Number(widgetId), organizationId, workspaceId, name);
    }

    return writeUrlToClipboard(portableLink);
  };

  const openDashboard = () => history.push(openRoute);

  return (
    <LibraryRowActionDropdown>
      {libraryRowActionOptions.open(openDashboard)}
      {libraryRowActionOptions.copy(copyLibraryResourceLink)}
    </LibraryRowActionDropdown>
  );
};

export const LibraryItemRowActionDropdown = ({
  itemId,
  linkName,
  openRoute,
}: {
  itemId: number;
  linkName: string;
  openRoute: string;
}) => {
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const copyLibraryItemLink = () => {
    const portableLink = new LibraryPortableLink(organizationId, workspaceId, itemId, linkName);
    return writeUrlToClipboard(portableLink);
  };

  const openLibraryItem = () => history.push(openRoute);

  return (
    <LibraryRowActionDropdown>
      {libraryRowActionOptions.open(openLibraryItem)}
      {libraryRowActionOptions.copy(copyLibraryItemLink)}
    </LibraryRowActionDropdown>
  );
};

export const LibraryCollectionRowActionDropdown = ({
  packageStatus,
  linkName,
  openRoute,
}: {
  packageStatus: PackageStatus;
  linkName: string;
  openRoute: string;
}) => {
  const history = useHistory();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const copyLibraryItemLink = () => {
    const portableLink = new LibraryCollectionPortableLink(organizationId, workspaceId, packageStatus, linkName);
    return writeUrlToClipboard(portableLink);
  };

  const openLibraryItem = () => history.push(openRoute);

  return (
    <LibraryRowActionDropdown>
      {libraryRowActionOptions.open(openLibraryItem)}
      {libraryRowActionOptions.copy(copyLibraryItemLink)}
    </LibraryRowActionDropdown>
  );
};
