import 'features/common/inputs/status_filter_menu/index.scss';
import classNames from 'classnames';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { UserWorkspaceSettingFieldName, WorkloadDayRange } from 'daos/enums';
import { UserWorkspaceSettingsDao } from 'daos/user_workspace_settings';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { gridSolid, LpIcon } from 'features/common/lp_icon';
import { awaitRequestFinish } from 'lib/api';
import { ReadonlyRecord } from 'lib/readonly_record';
import { lpBrandWhite, slate600 } from 'style/variables';

export const defaultWorkloadDayRange = WorkloadDayRange.Sixty;

const workloadDisplayByDayRange: ReadonlyRecord<WorkloadDayRange, string> = {
  [WorkloadDayRange.Thirty]: '30d Window',
  [WorkloadDayRange.Sixty]: '60d Window',
  [WorkloadDayRange.Ninety]: '90d Window',
  [WorkloadDayRange.OneHundredEighty]: '180d Window',
  [WorkloadDayRange.ThreeHundredSixtyFive]: '365d Window',
  [WorkloadDayRange.SevenHundredThirty]: '730d Window',
};

const defaultOption = {
  text: workloadDisplayByDayRange[defaultWorkloadDayRange],
  value: defaultWorkloadDayRange,
};

const dateRangeOptions = () => {
  const options = [
    {
      text: workloadDisplayByDayRange[WorkloadDayRange.Thirty],
      value: WorkloadDayRange.Thirty,
    },
    defaultOption,
    {
      text: workloadDisplayByDayRange[WorkloadDayRange.Ninety],
      value: WorkloadDayRange.Ninety,
    },
    {
      text: workloadDisplayByDayRange[WorkloadDayRange.OneHundredEighty],
      value: WorkloadDayRange.OneHundredEighty,
    },
    {
      text: workloadDisplayByDayRange[WorkloadDayRange.ThreeHundredSixtyFive],
      value: WorkloadDayRange.ThreeHundredSixtyFive,
    },
    {
      text: workloadDisplayByDayRange[WorkloadDayRange.SevenHundredThirty],
      value: WorkloadDayRange.SevenHundredThirty,
    },
  ];

  return options;
};

export const WorkloadDayRangeDropdown = ({
  dayRange,
  setDayRange,
}: {
  dayRange: WorkloadDayRange;
  setDayRange: Dispatch<SetStateAction<WorkloadDayRange>>;
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const options = dateRangeOptions();

  const isDefaultDayRangeSelection = dayRange === defaultWorkloadDayRange;

  const handleDropdownItemClick = (_: SyntheticEvent<HTMLDivElement>, { value }: DropdownItemProps) => {
    setDayRange(() => {
      const newState = value as WorkloadDayRange;
      const { uuid } = dispatch(
        UserWorkspaceSettingsDao.update(
          {
            organizationId,
            workspaceId,
            settingFieldName: UserWorkspaceSettingFieldName.WorkloadDayRange,
          },
          newState
        )
      );

      dispatch(awaitRequestFinish(uuid, {}));

      return newState;
    });
  };

  return (
    <Dropdown
      button
      className={classNames('small-button', {
        'folder-status-filter-dropdown--filtered': !isDefaultDayRangeSelection,
      })}
      selectOnNavigation={false}
      value={dayRange}
      trigger={
        <span>
          <LpIcon className="icon" color={isDefaultDayRangeSelection ? slate600 : lpBrandWhite} icon={gridSolid} />{' '}
          {(options.find((option) => option.value === dayRange) ?? defaultOption).text}
        </span>
      }
    >
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            onClick={handleDropdownItemClick}
            key={option.value}
            text={
              <span style={{ pointerEvents: 'none' }}>
                <LpIcon className="icon" color={slate600} icon={gridSolid} /> {option.text}
              </span>
            }
            value={option.value}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
