import { Header } from 'semantic-ui-react';

export const DisconnectedUserHeader = () => {
  return (
    <div className="disconnected-user__header-section">
      <Header className="disconnected-user__header">Welcome Back!</Header>
      <div className="disconnected-user__header-subtext">
        Your user login is no longer connected to any Account ( Organization & Workspace ).
      </div>
    </div>
  );
};
