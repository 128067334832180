import { filesize } from 'filesize';

import { Item, ItemMetrics } from 'daos/model_types';

import { MILLISECONDS_IN_HOUR, SECONDS_IN_HOUR } from './constants';
import { formatAsHours } from './display_helpers/format_as_hours';

export function pluralize(word: string, count: number, replacement = `${word}s`) {
  if (count === 1) {
    return word;
  }

  return replacement;
}

/**
 * @deprecated transition to the pluralize function instead
 */
export function pluralizeUnitWithCountIgnoringZero(str: string, count: number, suffix = 's') {
  if (count === 0) {
    return '';
  }

  return `${count} ${str}${count !== 1 ? suffix : ''}`;
}

export function priorityCompare(
  firstGlobalPriority: ReadonlyArray<string>,
  secondGlobalPriority: ReadonlyArray<string>
) {
  // Checks if these are the same array in memory, doesn't do a deep compare.
  if (firstGlobalPriority === secondGlobalPriority) {
    return 0;
  }

  const minLength = Math.min(firstGlobalPriority.length, secondGlobalPriority.length);

  for (let i = 0; i < minLength; i++) {
    const first = firstGlobalPriority[i];
    const second = secondGlobalPriority[i];
    if (first !== undefined && second !== undefined) {
      const priorityDifference = first.localeCompare(second);

      if (priorityDifference !== 0) {
        return priorityDifference;
      }
    }
  }

  const lengthDifference = firstGlobalPriority.length - secondGlobalPriority.length;

  return lengthDifference;
}

/**
 * @return An item's progress as a floating point number between [0,100].
 *         0 if no metrics exist for this item.
 *         0 if no work has been logged AND no work has been estimated for this item.
 */
export const itemProgressPercentage = (itemMetrics?: ItemMetrics): number => {
  if (!itemMetrics) {
    return 0;
  }

  const expectedRemainingWork = (itemMetrics.lowRemainingWork + itemMetrics.highRemainingWork) / 2;

  // avoid a divide by zero error: If no remaining or logged work, we say there's no progress
  if (itemMetrics.loggedWork === 0 && expectedRemainingWork === 0) {
    return 0;
  }

  const decimalProgress = itemMetrics.loggedWork / (itemMetrics.loggedWork + expectedRemainingWork);

  return decimalProgress * 100;
};

export const progressRatio = (numerator: number, denominator: number) => {
  if (denominator === 0) {
    return 0;
  }

  return numerator / denominator;
};

export const roundedPercentage = (numerator: number, denominator: number) => {
  return Math.round(progressRatio(numerator, denominator) * 100);
};

export const camelCaseToKabobCase = (camelCaseString: string): string =>
  camelCaseString.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();

// takes in a number(time in milliseconds) and returns it in a
// hour fraction of it self(positive or negative).
// if you pass it 0 it will return '-'
export const convertMillisecondsToHours = (ms: number): string => {
  const timeFraction = ms / MILLISECONDS_IN_HOUR;
  return ms ? String(formatAsHours(timeFraction)) : '-';
};

export const convertSecondsToHours = (work: number): number => work / SECONDS_IN_HOUR;

export const convertHoursToSeconds = (work: number): number => work * SECONDS_IN_HOUR;

export const itemGlobalPrioritySort = (a: Item, b: Item) => priorityCompare(a.globalPriority, b.globalPriority);

export const booleanCompare = (a: boolean, b: boolean) => {
  if (a === b) {
    return 0;
  }

  return a ? 1 : -1;
};

export function numberFromString(str: string) {
  const parsed = parseInt(str, 10);
  return isNaN(parsed) ? null : parsed;
}

export function secondsToHoursWithPrecision(seconds: number, precision = 2) {
  const hours = convertSecondsToHours(seconds);
  return parseFloat(hours.toFixed(precision));
}

export const getItemIsLateRisk = ({
  effectiveTargetFinish,
  isLate,
  latestFinish,
}: {
  effectiveTargetFinish: string | null | undefined;
  isLate: boolean;
  latestFinish: string | null | undefined;
}) => {
  return !isLate && !!latestFinish && !!effectiveTargetFinish && latestFinish > effectiveTargetFinish;
};

export function convertMBtoBytes(mb: number) {
  return mb * 1024 * 1024;
}
export function formatFileSizeFromBytes(fileSizeInBytes: number): string {
  return filesize(fileSizeInBytes, { base: 2, standard: 'jedec', output: 'string' }) as string;
}
