import { useSelector } from 'react-redux';

import { ItemType, WidgetOnClickViewType } from 'daos/enums';
import { LpFormikDropdown } from 'features/common/forms/formik/dropdown';

import { WidgetSettingsV2Field } from './field';
import { getOnClickViewOptions } from './selectors';

export const OnClickViewDropdown = ({
  name = 'On-Click',
  include,
  scopeItemType,
}: {
  name?: string;
  include?: ReadonlyArray<WidgetOnClickViewType>;
  scopeItemType?: ItemType;
}) => {
  const dropdownOptions = useSelector((state) => getOnClickViewOptions(state, scopeItemType, include));

  return (
    <WidgetSettingsV2Field name={name}>
      <LpFormikDropdown
        className="base-v2-widget-settings__content-options-field-on-click-view"
        name="onClickViewOption"
        selection={true}
        options={dropdownOptions}
      />
    </WidgetSettingsV2Field>
  );
};
