import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { WidgetDataErrorType, widgetTypeDisplay, WidgetType } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { LocationFilterSettings } from 'daos/types';
import { LpIcon, memoCircleInfoSolid } from 'features/common/lp_icon';
import { getDefaultPropertiesWidgetColumns } from 'features/dashboards/widgets/columns';
import { getOnClickViewOptionsForItemType } from 'features/dashboards_v2/widget/helpers';
import { useGetItemScopeForWidget } from 'features/dashboards_v2/widget/use_get_item_for_scope';
import { usePropertiesWidgetCustomFieldsAndCustomColumns } from 'features/dashboards_v2/widget/widgets/properties_summary/hooks';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { OnClickViewDropdown } from 'features/dashboards_v2/widget_settings/on_click_view_dropdown';
import { Title } from 'features/dashboards_v2/widget_settings/title';
import { WidgetColumnPicker } from 'features/dashboards_v2/widget_settings/widget_column_picker';
import { getItemForId } from 'redux/entities/selectors/item';

export const PropertiesSummarySettings = ({ widget }: { widget: Widget }) => {
  const { config, data } = widget;
  const allProperties = usePropertiesWidgetCustomFieldsAndCustomColumns();
  const defaultPropertiesColumns = useSelector(getDefaultPropertiesWidgetColumns);

  const widgetDataError = data?.errors.find((error) => error === WidgetDataErrorType.NO_PROPERTIES_SELECTED_ERROR);

  const { getFieldProps } = useFormikContext<LocationFilterSettings>();
  const locationFilterItemId = getFieldProps<string | null>('locationFilterItemId').value;

  const widgetScopeItem = useGetItemScopeForWidget(widget);
  const formScopeItem = useSelector((state) =>
    locationFilterItemId ? getItemForId(state, Number(locationFilterItemId)) : undefined
  );

  const scopeItemType = formScopeItem?.itemType ?? widgetScopeItem?.itemType;

  const includeOptions = getOnClickViewOptionsForItemType(scopeItemType, config.onClickView);

  const header = (
    <>
      <LpIcon icon={memoCircleInfoSolid} /> {widgetTypeDisplay[WidgetType.PropertiesSummary]}
    </>
  );

  const content = (
    <>
      <Title widgetId={widget.id} />
      <LocationSetting disablePackageStatusSelection />
      <OnClickViewDropdown scopeItemType={scopeItemType} include={includeOptions} />
      <WidgetColumnPicker
        widgetType={WidgetType.PropertiesSummary}
        allGridColumns={allProperties}
        defaultColumns={defaultPropertiesColumns}
        widgetDataError={widgetDataError}
      />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
