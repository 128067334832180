import classNames from 'classnames';
import { useMemo } from 'react';

import { ScheduleBarDateOffsetPercents, ScheduleBarLabelStyles } from 'features/common/schedule_bar/types';

enum ScheduleBarDisplayText {
  TargetStart = 'Target Start',
  TargetFinish = 'Target Finish',
  Done = 'Done',
  ExpectedStart = 'Expected Start',
  ExpectedFinish = 'Expected Finish',
  LatestFinish = 'Latest Finish',
  EffectiveStart = 'Inherited Target Start',
  EffectiveFinish = 'Inherited Target Finish',
  RollupEarliestActiveTargetFinish = 'Earliest Rollup Target Finish',
  RollupLatestTargetFinish = 'Latest Rollup Target Finish',
}

export const TargetDateLabels = ({
  effectiveTargetFinish,
  effectiveTargetStart,
  formatDateForDisplay,
  isDoneAfterEffectiveTargetFinish,
  isExpectedStartBeforeEffectiveTargetStart,
  isLate,
  isLateRisk,
  isTask,
  rollupEarliestActiveTargetFinish,
  rollupLatestTargetFinish,
  scheduleBarDateOffsetPercents,
  scheduleBarLabelStyles,
  showRollupEarliestActiveTargetFinish,
  showRollupLatestTargetFinish,
  targetFinish,
  targetStart,
}: {
  effectiveTargetFinish: string | null | undefined;
  effectiveTargetStart: string | null | undefined;
  formatDateForDisplay: (day: string) => string;
  isDoneAfterEffectiveTargetFinish: boolean;
  isExpectedStartBeforeEffectiveTargetStart: boolean;
  isLate: boolean;
  isLateRisk: boolean;
  isTask: boolean;
  rollupEarliestActiveTargetFinish: string | null | undefined;
  rollupLatestTargetFinish: string | null | undefined;
  scheduleBarDateOffsetPercents: ScheduleBarDateOffsetPercents;
  scheduleBarLabelStyles: ScheduleBarLabelStyles;
  showRollupEarliestActiveTargetFinish: boolean;
  showRollupLatestTargetFinish: boolean;
  targetFinish: string | null;
  targetStart: string | null;
}) => {
  const targetStartLocalDate = targetStart && formatDateForDisplay(targetStart);
  const targetFinishLocalDate = targetFinish && formatDateForDisplay(targetFinish);
  const effectiveStartLocalDate = effectiveTargetStart && formatDateForDisplay(effectiveTargetStart);
  const effectiveFinishLocalDate = effectiveTargetFinish && formatDateForDisplay(effectiveTargetFinish);
  const rollupEarliestActiveTargetFinishLocalDate =
    rollupEarliestActiveTargetFinish && formatDateForDisplay(rollupEarliestActiveTargetFinish);
  const rollupLatestTargetFinishLocalDate = rollupLatestTargetFinish && formatDateForDisplay(rollupLatestTargetFinish);

  const effectiveTargetFinishGteTargetStart =
    !!targetStart && !!effectiveTargetFinish && effectiveTargetFinish >= targetStart;
  const effectiveTargetFinishLteTargetFinish =
    !!targetFinish && !!effectiveTargetFinish && effectiveTargetFinish <= targetFinish;
  const showEffectiveFinish =
    !!effectiveTargetFinish ||
    isLate ||
    isLateRisk ||
    isDoneAfterEffectiveTargetFinish ||
    effectiveTargetFinishLteTargetFinish ||
    effectiveTargetFinishGteTargetStart;

  const effectiveTargetStartGteTargetStart =
    !!targetStart && !!effectiveTargetStart && effectiveTargetStart >= targetStart;
  const effectiveTargetStartLteTargetFinish =
    !!targetFinish && !!effectiveTargetStart && effectiveTargetStart <= targetFinish;
  const showEffectiveStart =
    (!isTask && isExpectedStartBeforeEffectiveTargetStart) ||
    (!!targetStart && (effectiveTargetStartGteTargetStart || effectiveTargetStartLteTargetFinish));

  const targetStartLabel = useMemo(
    () =>
      !!targetStartLocalDate && (
        <div
          className={classNames(
            'lp-schedule__target-dates-row',
            'lp-schedule__target-dates-start',
            scheduleBarDateOffsetPercents.targetStartOffsetPercent >= 50
              ? 'lp-schedule__target-dates-start--right'
              : 'lp-schedule__target-dates-start--left'
          )}
          style={scheduleBarLabelStyles.targetStart}
        >
          {`${ScheduleBarDisplayText.TargetStart} `}
          <strong>{targetStartLocalDate}</strong>
        </div>
      ),
    [scheduleBarDateOffsetPercents.targetStartOffsetPercent, scheduleBarLabelStyles.targetStart, targetStartLocalDate]
  );

  const targetFinishLabel = useMemo(
    () =>
      !!targetFinishLocalDate && (
        <div
          className={classNames(
            'lp-schedule__target-dates-row',
            'lp-schedule__target-dates-finish',
            scheduleBarDateOffsetPercents.targetFinishOffsetPercent >= 50
              ? 'lp-schedule__target-dates-finish--right'
              : 'lp-schedule__target-dates-finish--left'
          )}
          style={scheduleBarLabelStyles.targetFinish}
        >
          {`${ScheduleBarDisplayText.TargetFinish} `}
          <strong>{targetFinishLocalDate}</strong>
        </div>
      ),
    [
      scheduleBarDateOffsetPercents.targetFinishOffsetPercent,
      scheduleBarLabelStyles.targetFinish,
      targetFinishLocalDate,
    ]
  );

  const effectiveStartLabel = useMemo(
    () =>
      showEffectiveStart &&
      !!effectiveStartLocalDate &&
      effectiveStartLocalDate !== targetStartLocalDate && (
        <div
          className={classNames(
            'lp-schedule__target-dates-row',
            'lp-schedule__target-dates-start',
            'lp-schedule__target-dates-start--effective',
            scheduleBarDateOffsetPercents.effectiveStartOffsetPercent >= 50
              ? 'lp-schedule__target-dates-start--right'
              : 'lp-schedule__target-dates-start--left'
          )}
          style={scheduleBarLabelStyles.effectiveStart}
        >
          {`${ScheduleBarDisplayText.EffectiveStart} `}
          <strong>{effectiveStartLocalDate}</strong>
        </div>
      ),
    [
      effectiveStartLocalDate,
      scheduleBarDateOffsetPercents.effectiveStartOffsetPercent,
      scheduleBarLabelStyles.effectiveStart,
      showEffectiveStart,
      targetStartLocalDate,
    ]
  );

  const effectiveFinishLabel = useMemo(
    () =>
      showEffectiveFinish &&
      !!effectiveFinishLocalDate &&
      effectiveFinishLocalDate !== targetFinishLocalDate && (
        <div
          className={classNames(
            'lp-schedule__target-dates-row',
            'lp-schedule__target-dates-finish',
            'lp-schedule__target-dates-finish--effective',
            scheduleBarDateOffsetPercents.effectiveFinishOffsetPercent >= 50
              ? 'lp-schedule__target-dates-finish--right'
              : 'lp-schedule__target-dates-finish--left'
          )}
          style={scheduleBarLabelStyles.effectiveFinish}
        >
          {`${ScheduleBarDisplayText.EffectiveFinish} `}
          <strong>{effectiveFinishLocalDate}</strong>
        </div>
      ),
    [
      effectiveFinishLocalDate,
      scheduleBarDateOffsetPercents.effectiveFinishOffsetPercent,
      scheduleBarLabelStyles.effectiveFinish,
      showEffectiveFinish,
      targetFinishLocalDate,
    ]
  );

  const rollupEarliestActiveTargetFinishLabel = useMemo(
    () =>
      showRollupEarliestActiveTargetFinish && (
        <div
          className={classNames(
            'lp-schedule__target-dates-row',
            'lp-schedule__target-dates-finish',
            'lp-schedule__target-dates-finish--rollup',
            'lp-schedule__target-dates-finish--left'
          )}
          style={scheduleBarLabelStyles.rollupEarliestActiveTargetFinish}
        >
          {`${ScheduleBarDisplayText.RollupEarliestActiveTargetFinish} `}
          <strong>{rollupEarliestActiveTargetFinishLocalDate}</strong>
        </div>
      ),
    [
      rollupEarliestActiveTargetFinishLocalDate,
      scheduleBarLabelStyles.rollupEarliestActiveTargetFinish,
      showRollupEarliestActiveTargetFinish,
    ]
  );

  const rollupLatestTargetFinishLabel = useMemo(
    () =>
      showRollupLatestTargetFinish && (
        <div
          className={classNames(
            'lp-schedule__target-dates-row',
            'lp-schedule__target-dates-finish',
            'lp-schedule__target-dates-finish--rollup',
            'lp-schedule__target-dates-finish--right'
          )}
          style={scheduleBarLabelStyles.rollupLatestTargetFinish}
        >
          {`${ScheduleBarDisplayText.RollupLatestTargetFinish} `}
          <strong>{rollupLatestTargetFinishLocalDate}</strong>
        </div>
      ),
    [rollupLatestTargetFinishLocalDate, scheduleBarLabelStyles.rollupLatestTargetFinish, showRollupLatestTargetFinish]
  );

  const rowCount = [
    targetStartLabel,
    effectiveStartLabel,
    effectiveFinishLabel,
    targetFinishLabel,
    rollupEarliestActiveTargetFinishLabel,
    rollupLatestTargetFinishLabel,
  ].filter((row) => !!row).length;

  if (!rowCount) {
    return null;
  }

  return (
    <div className={classNames('lp-schedule__target-dates', `lp-schedule__target-dates--height${rowCount}`)}>
      {targetStartLabel}
      {effectiveStartLabel}
      {effectiveFinishLabel}
      {targetFinishLabel}
      {rollupEarliestActiveTargetFinishLabel}
      {rollupLatestTargetFinishLabel}
    </div>
  );
};

export const ExpectedDateLabels = ({
  doneDate,
  expectedFinish,
  expectedStart,
  formatDateForDisplay,
  isExpectedStartBeforeEffectiveTargetStart,
  isExpectedStartEqualExpectedFinish,
  isLate,
  isLateRisk,
  latestFinish,
  scheduleBarDateOffsetPercents,
  scheduleBarLabelStyles,
}: {
  doneDate: string | null;
  expectedFinish: string | null;
  expectedStart: string | null;
  formatDateForDisplay: (day: string) => string;
  isExpectedStartBeforeEffectiveTargetStart: boolean;
  isExpectedStartEqualExpectedFinish: boolean;
  isLate: boolean;
  isLateRisk: boolean;
  latestFinish: string | null;
  scheduleBarDateOffsetPercents: ScheduleBarDateOffsetPercents;
  scheduleBarLabelStyles: ScheduleBarLabelStyles;
}) => {
  const doneLocalDate = doneDate && formatDateForDisplay(doneDate);
  const expectedStartLocalDate = !doneLocalDate && expectedStart && formatDateForDisplay(expectedStart);
  const expectedFinishLocalDate = !doneLocalDate && expectedFinish && formatDateForDisplay(expectedFinish);
  const latestFinishLocalDate = !doneLocalDate && latestFinish && formatDateForDisplay(latestFinish);

  return (
    <div className="lp-schedule__labels">
      {!!doneLocalDate && (
        <div
          className={classNames(
            'lp-schedule__labels-done',
            scheduleBarDateOffsetPercents.doneDateOffsetPercent >= 50
              ? 'lp-schedule__labels-done--right'
              : 'lp-schedule__labels-done--left'
          )}
          style={scheduleBarLabelStyles.done}
        >
          <span>
            {`${ScheduleBarDisplayText.Done} `}
            <strong>{doneLocalDate}</strong>
          </span>
        </div>
      )}
      {!!expectedStartLocalDate && (
        <div
          className={classNames(
            'lp-schedule__labels-start',
            scheduleBarDateOffsetPercents.expectedStartOffsetPercent >= 50
              ? 'lp-schedule__labels-start--right'
              : 'lp-schedule__labels-start--left'
          )}
          style={scheduleBarLabelStyles.expectedStart}
        >
          <span>
            {`${ScheduleBarDisplayText.ExpectedStart} `}
            <strong>{expectedStartLocalDate}</strong>
            {isExpectedStartBeforeEffectiveTargetStart && !isExpectedStartEqualExpectedFinish && (
              <>
                {' '}
                <strong>(ASAP)</strong>
              </>
            )}
          </span>
        </div>
      )}
      {!!expectedFinishLocalDate && (
        <div
          className={classNames(
            'lp-schedule__labels-expected',
            scheduleBarDateOffsetPercents.expectedFinishOffsetPercent >= 50
              ? 'lp-schedule__labels-expected--right'
              : 'lp-schedule__labels-expected--left'
          )}
          style={scheduleBarLabelStyles.expectedFinish}
        >
          <span>
            {`${ScheduleBarDisplayText.ExpectedFinish} `}
            <strong
              className={classNames(
                'lp-schedule__labels-expected-date',
                isLate && 'lp-schedule__labels-expected-date--late'
              )}
            >
              {expectedFinishLocalDate}
            </strong>
          </span>
        </div>
      )}
      {!!latestFinishLocalDate && (
        <div
          className={classNames(
            'lp-schedule__labels-latest',
            scheduleBarDateOffsetPercents.latestFinishOffsetPercent >= 50
              ? 'lp-schedule__labels-latest--right'
              : 'lp-schedule__labels-latest--left'
          )}
          style={scheduleBarLabelStyles.latestFinish}
        >
          <span>
            {`${ScheduleBarDisplayText.LatestFinish} `}
            <strong
              className={classNames(
                'lp-schedule__labels-latest-date',
                (isLate || isLateRisk) && 'lp-schedule__labels-latest-date--late'
              )}
            >
              {latestFinishLocalDate}
            </strong>
          </span>
        </div>
      )}
    </div>
  );
};
