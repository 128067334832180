import { useEffect } from 'react';

import { DisconnectedUserHeader } from 'features/authentication/authenticated/disconnected/header';
import { DisconnectedUserNavigation } from 'features/authentication/authenticated/disconnected/navigation';
import { LpMotionFadeInAndUp } from 'features/common/animated_divs';
import { useTrackPageViewedOnSegment } from 'features/common/segment/use_report_page_viewed_to_segment';

import './index.scss';

export const DisconnectedUser = () => {
  useEffect(() => {
    try {
      localStorage.clear();
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }, []);

  useTrackPageViewedOnSegment();

  return (
    <LpMotionFadeInAndUp className="disconnected-user__welcome-back">
      <DisconnectedUserHeader />
      <DisconnectedUserNavigation />
    </LpMotionFadeInAndUp>
  );
};
