import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemDao } from 'daos/item';
import { filterIds } from 'daos/shared';
import { TaskStatusDao } from 'daos/task_status';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { createCustomFieldValuesPayload } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { getWorkLimitPayload } from 'features/common/data_grid/add_edit_grid/helpers/payload_helpers';
import {
  allRowsAreValid,
  getTaskStoryPointPayload,
} from 'features/common/data_grid/add_edit_grid/modal_edit_grid/edit_item_grids/helpers';
import {
  useTaskEditColumnDefinitions,
  useTasksToEditGridRows,
} from 'features/common/data_grid/add_edit_grid/modal_edit_grid/hooks/use_task_edit_data_grid';
import LpEditGridModal from 'features/common/data_grid/add_edit_grid/modal_edit_grid/lp_edit_grid_modal';
import {
  MultiFieldValueModal,
  MultiFieldEditorProps,
} from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { AddEditGridRow } from 'features/common/data_grid/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { awaitRequestFinish } from 'lib/api';
import { FeatureFlag } from 'lib/feature_flags';
import { getEditGridCustomFieldValuesByItemId, getFieldsById } from 'redux/entities/selectors/custom_field';
import { getItemsById, getItemsForIdsSortedByGlobalPriority } from 'redux/entities/selectors/item';

interface EditTaskGridModalProps {
  fetchTasks: () => void;
  isTemplateGrid: boolean;
  selectedTaskIds: ReadonlyArray<number>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditModal = ({ fetchTasks, isTemplateGrid, selectedTaskIds, setOpen }: EditTaskGridModalProps) => {
  const tasks = useSelector((state) => getItemsForIdsSortedByGlobalPriority(state, selectedTaskIds));
  const customFieldsKeyedById = useSelector(getFieldsById);
  const fieldValuesByItemId = useSelector(getEditGridCustomFieldValuesByItemId);
  const itemsById = useSelector(getItemsById);

  const [multiFieldEditorProps, setMultiFieldEditorProps] = useState<MultiFieldEditorProps | undefined>();
  const closeMultiEditModal = () => setMultiFieldEditorProps(undefined);

  const columns = useTaskEditColumnDefinitions(isTemplateGrid, setMultiFieldEditorProps);
  const rows = useTasksToEditGridRows(tasks);

  const createPayload = useCallback(
    (rows: Array<AddEditGridRow>) => {
      const payload = rows.map((row) => {
        const existingTask = itemsById[row.id];
        const taskStatus = row.taskStatusId ? TaskStatusDao.id(row.taskStatusId) : undefined;
        const storyPoints = getTaskStoryPointPayload(row, existingTask);
        const initialRowFieldValues = fieldValuesByItemId[row.id] ?? [];
        const workLimit = getWorkLimitPayload(row.workLimit);
        const fieldValues = createCustomFieldValuesPayload({ row, customFieldsKeyedById, initialRowFieldValues });

        return {
          id: row.id,
          name: row.name,
          taskStatus,
          description: row.description,
          doneDate: row.doneDate,
          iterationId: row.iterationId ?? null,
          scheduleDirective: row.scheduleDirective,
          storyPoints,
          targetStart: row.targetStart,
          targetFinish: row.targetFinish,
          targetFinishType: row.targetFinishType,
          workLimit,
          workType: row.workType,
          fieldValues,
        };
      });

      return payload;
    },
    [customFieldsKeyedById, fieldValuesByItemId, itemsById]
  );

  return (
    <>
      <LpEditGridModal
        columns={columns}
        createPayload={createPayload}
        fetchItems={fetchTasks}
        helpText={<strong>Bulk edit up to 50 tasks at a time</strong>}
        initialRows={rows}
        itemTypeDisplay="Tasks"
        setOpen={setOpen}
        validateRows={allRowsAreValid}
      />
      {multiFieldEditorProps && <MultiFieldValueModal {...multiFieldEditorProps} onClose={closeMultiEditModal} />}
    </>
  );
};

export const EditTaskGridModal = ({ selectedTaskIds, ...props }: EditTaskGridModalProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const hasStoryPointFeature = useHasFeature(FeatureFlag.storyPoints);
  const [loading, setLoading] = useState(true);

  const fetchTaskDataForModal = useCallback(() => {
    const { uuid } = dispatch(
      ItemDao.fetchAll(
        { organizationId, workspaceId },
        {
          filter: filterIds([...selectedTaskIds]),
          include: {
            includeFieldValues: true,
            includeTaskStoryPointSchemesOwnersAndPoints: hasStoryPointFeature,
          },
        }
      )
    );

    dispatch(awaitRequestFinish(uuid, { onFinish: () => setLoading(false) }));
  }, [dispatch, hasStoryPointFeature, organizationId, selectedTaskIds, workspaceId]);

  useEffect(() => {
    fetchTaskDataForModal();
  }, [fetchTaskDataForModal]);

  if (loading) {
    return null;
  }

  return <EditModal {...props} selectedTaskIds={selectedTaskIds} />;
};
