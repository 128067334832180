import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import FolderStatusIcon from 'containers/shared/folder_status_icon';
import ItemIcon from 'containers/shared/item_icon';
import {
  pickerOrMoveFilteredItemFetchUuid,
  usePickerOrMoveFetchRequests,
  usePickerOrMoveFilterQueryString,
  usePickerOrMoveTreeTraversal,
} from 'containers/shared/move_and_picker_shared_helpers';
import { ItemType, PackageStatus } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { FolderStatus, ItemTypesDisplayPlural, RelativePriorityType } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import { itemAccessMovePermission, permissionCompare } from 'daos/permission';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import IncludeDoneButton from 'features/common/inputs/include_done_button';
import LpSearchInputControlled from 'features/common/inputs/lp_search_input/lp_search_input_controlled';
import { getItemPickerDataOrderedByPriority } from 'features/common/item_picker/selectors';
import { updateItemPickerStore } from 'features/common/item_picker/slice';
import { arrowAltUpSolid, banRegular, LpIcon, keyRegular } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { useItemListContext } from 'features/ppp/project/task_list/context';
import { useDirtyInputUpdate } from 'hooks/use_dirty_input_update';
import { useHasFeature } from 'hooks/use_has_feature';
import { usePrevious } from 'hooks/use_previous';
import { awaitRequestFinish } from 'lib/api';
import { ApiError, SuccessPayload } from 'lib/api/types';
import { KEYDOWN_DEBOUNCE_MILLIS } from 'lib/constants';
import { FeatureFlag } from 'lib/feature_flags';
import { getItemForId } from 'redux/entities/selectors/item';
import { gray500, slate700 } from 'style/variables';

import { canMoveToParent, errorIcon, packageIcon } from './item_move_helpers';
import ItemMoveTableParent from './item_move_table_parent';

import './item_move.scss';

interface ItemMoveDialogProps {
  errorBody?: ApiError;
  filterItemTypes: Array<ItemType>;
  handleMove?: (parentId: number, location: RelativePriorityType) => void;
  header?: JSX.Element;
  isTemplate: boolean;
  itemId: number;
  onClose: () => void;
}

const ItemMoveDialog = ({
  errorBody,
  filterItemTypes,
  handleMove,
  header,
  isTemplate,
  itemId,
  onClose,
}: ItemMoveDialogProps) => {
  const dispatch = useDispatch();
  const hasGroupCapacityEnabled = useHasFeature(FeatureFlag.groupCapacity);
  const item = useSelector((state) => getItemForId(state, itemId));
  const itemParentId = item?.parent?.id ?? undefined;
  const pickerData = useSelector(getItemPickerDataOrderedByPriority);
  const itemParent = useSelector((state) => (itemParentId ? getItemForId(state, itemParentId) : undefined));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [includeDone, setIncludeDone] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [parent, setParent] = useState<Item | undefined>(itemParent);
  const [moveError, setMoveError] = useState<ApiError | undefined>(errorBody);
  const [isFetchingData, setIsFetchingData] = useState(false);

  useEffect(() => {
    if (errorBody) {
      setMoveError(errorBody);
    }
  }, [errorBody]);

  const [canMove, setCanMove] = useState<{
    correctHierarchy: boolean;
    hasAccess: boolean;
  }>({
    correctHierarchy: true,
    hasAccess: true,
  });
  const [atWorkspaceRoot, setAtWorkspaceRoot] = useState(false);
  const [packageStatus, setPackageStatus] = useState<PackageStatus | null>(null);
  const setDebouncedMoveFilterValue = useRef<(value: React.SetStateAction<string>) => void>(
    debounce((value: React.SetStateAction<string>) => setFilterValue(value), KEYDOWN_DEBOUNCE_MILLIS)
  );

  const parentType = parent?.itemType;
  const folderStatusFilter = includeDone
    ? [FolderStatus.ACTIVE, FolderStatus.ON_HOLD, FolderStatus.DONE]
    : [FolderStatus.ACTIVE, FolderStatus.ON_HOLD];

  const canMoveItem = useCallback(
    (parent: Item) => {
      const correctHierarchy = item ? canMoveToParent(item.itemType, parent.itemType) : false;
      const hasPermission = permissionCompare(parent.permission, itemAccessMovePermission(parent.itemType)) <= 0;
      setCanMove({ correctHierarchy, hasAccess: hasPermission });
    },
    [item]
  );

  const filterQueryString = usePickerOrMoveFilterQueryString({
    filterItemTypes,
    filterValue,
    folderStatus: folderStatusFilter,
    packageStatus,
    parent,
  });

  const filteredItemFetch = useCallback(() => {
    if (atWorkspaceRoot) {
      return;
    }

    setIsFetchingData(true);

    const uuid = pickerOrMoveFilteredItemFetchUuid({
      dispatch,
      filterQueryString,
      organizationId,
      workspaceId,
    });

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: (response) => {
          const items = response.entities.items;
          parent && canMoveItem(parent);
          dispatch(updateItemPickerStore(items));
        },
        onFinish: () => setIsFetchingData(false),
      })
    );
  }, [atWorkspaceRoot, dispatch, organizationId, workspaceId, filterQueryString, canMoveItem, parent]);

  const prevIncludeDone = usePrevious(includeDone);
  const prevFilterValue = usePrevious(filterValue);

  const inputFilterChanged = filterValue !== prevFilterValue;
  const includeDoneChanged = prevIncludeDone !== includeDone;

  useEffect(() => {
    if (!isFetchingData && (inputFilterChanged || includeDoneChanged)) {
      filteredItemFetch();
    }
  }, [filteredItemFetch, includeDoneChanged, inputFilterChanged, isFetchingData]);

  useEffect(() => {
    if (parent && parent.packageStatus) {
      setPackageStatus(parent.packageStatus);
    }
  }, [parent]);

  const handleIncludeDoneItems = useCallback(() => {
    setIncludeDone((prevIncludeDone) => !prevIncludeDone);
  }, []);

  const getParentForMove = useCallback(
    (itemId: number) => {
      const { uuid } = dispatch(ItemDao.fetch({ organizationId, workspaceId, itemId }));
      dispatch(
        awaitRequestFinish<Item>(uuid, {
          onSuccess: (response) => {
            setParent(response.data);
            canMoveItem(response.data);
            dispatch(updateItemPickerStore(response.entities.items));
          },
        })
      );
    },
    [canMoveItem, dispatch, organizationId, workspaceId]
  );

  const { fetchPackages, fetchItemsForParent } = usePickerOrMoveFetchRequests({
    dispatch,
    filterItemTypes,
    folderStatus: folderStatusFilter,
    organizationId,
    parent,
    workspaceId,
  });

  const onAwaitMoveUp = (response: SuccessPayload<ReadonlyArray<Item>>, parentId: number) => {
    const items = response.entities.items;
    if (items) {
      const parentItem = items[parentId];
      setParent(parentItem);
      parentItem && canMoveItem(parentItem);
      dispatch(updateItemPickerStore(items));
    } else {
      getParentForMove(parentId);
    }
  };

  const onAwaitMoveDown = (response: SuccessPayload<ReadonlyArray<Item>>, item: Item) => {
    const items = response.entities.items;
    setParent(item);
    canMoveItem(item);
    dispatch(updateItemPickerStore(items));
  };

  const { isDirty, inputValue, setInputValue, setIsDirty, onChange, clearInput } = useDirtyInputUpdate({
    onChangeCallback: useCallback((value: string) => {
      setDebouncedMoveFilterValue.current(value);
    }, []),
  });

  const { moveUpTree, moveDownTree } = usePickerOrMoveTreeTraversal({
    atWorkspaceRoot,
    dispatch,
    fetchRequests: { fetchItemsForParent, fetchPackages },
    onAwaitMoveUp,
    onAwaitMoveDown,
    packageStatus,
    parentItemId: parent?.parent?.id,
    setState: {
      setAtWorkspaceRoot,
      setFilterValue,
      setInputValue,
      setIsDirty,
      setPackageStatus,
      setIsFetchingData,
    },
  });

  const handleMoveClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const { moveAction } = useItemListContext();

  const moveItem = useCallback(
    (relativePriority?: RelativePriorityType) => {
      setMoveError(undefined);

      const moveRelativePriority = relativePriority ? relativePriority : RelativePriorityType.AFTER;
      const parentId = parent?.id;

      if (parentId) {
        if (handleMove) {
          handleMove(parentId, moveRelativePriority);
        } else {
          const { uuid } = dispatch(
            ItemDao.update(
              { workspaceId, organizationId, itemId },
              {
                id: itemId,
                parent: ItemDao.id(parentId),
                relativePriority: { type: moveRelativePriority },
              }
            )
          );

          dispatch(
            awaitRequestFinish(uuid, {
              onError: ({ errors }) => {
                if (errors[0]) {
                  setMoveError(errors[0]);
                }
              },
              onSuccess: () => {
                if (moveAction) {
                  moveAction({
                    newParentId: parentId,
                    itemId,
                  });
                }
                handleMoveClose();
              },
            })
          );
        }
      }
    },
    [dispatch, handleMove, handleMoveClose, itemId, moveAction, organizationId, parent?.id, workspaceId]
  );

  const defaultHeader = (
    <>
      <span className="item-picker-modal__header-action">Moving:</span>
      <ItemIcon className="item-picker-modal__header-icon" size="1x" itemId={item?.id} />
      <span className="item-picker-modal__header-item-name">{item?.name}</span>
    </>
  );

  const handlePackageStatusClick = useCallback(
    (status: PackageStatus) => {
      setPackageStatus(status);
      setAtWorkspaceRoot(false);
      parent && moveDownTree(parent, status);
    },
    [moveDownTree, parent]
  );

  const moveErrorMessage = useMemo(() => {
    const moveLocation = parentType === ItemType.WORKSPACE_ROOTS ? 'the workspace level' : parentType;

    if (!canMove.correctHierarchy && item) {
      return (
        <>
          <LpIcon size="sm" color={gray500} icon={banRegular} />{' '}
          {`${ItemTypesDisplayPlural[item.itemType]} cannot be moved into ${moveLocation}`}
        </>
      );
    } else if (!canMove.hasAccess) {
      return (
        <>
          <LpIcon size="sm" color={gray500} icon={keyRegular} /> Access is limited to this item.
        </>
      );
    } else {
      return '';
    }
  }, [canMove, item, parentType]);

  const pkgStatusButtons = useMemo(() => {
    const packageStatuses = isTemplate
      ? [PackageStatus.TEMPLATE]
      : [
          PackageStatus.SCHEDULED,
          PackageStatus.BACKLOG,
          PackageStatus.ARCHIVED,
          ...(hasGroupCapacityEnabled ? [PackageStatus.CAPACITY] : []),
        ];
    return packageStatuses.map((status: string) => {
      const packageStatus = status as PackageStatus;
      return (
        <Button
          className="lp-item-picker__content-table-items-package"
          basic
          fluid
          key={status}
          onClick={() => handlePackageStatusClick(packageStatus)}
        >
          <LpIcon size="lg" icon={packageStatus && packageIcon(packageStatus)} color={slate700} />{' '}
          {packageStatusDisplayNameByPackageStatus[packageStatus]}{' '}
          <LpIcon size="sm" color={gray500} icon={banRegular} />
        </Button>
      );
    });
  }, [handlePackageStatusClick, isTemplate, hasGroupCapacityEnabled]);

  const itemErrorIcon = useCallback(
    (tableItem: Item) => {
      const icon = item && errorIcon(item, tableItem);
      return icon && <LpIcon size="sm" color={gray500} icon={icon} />;
    },
    [item]
  );

  const itemTargetButtons = useMemo(() => {
    const parentId = parent?.id;
    parentId && pickerData.delete(parentId);

    if (item && pickerData.has(item.id)) {
      pickerData.delete(item.id);
    }

    const itemButtons: Array<JSX.Element> = [];

    pickerData.forEach((item: Item) =>
      itemButtons.push(
        <Button basic fluid key={item.id} onClick={() => moveDownTree(item)}>
          <ItemIcon size="lg" itemId={item.id} />
          <FolderStatusIcon folderStatus={item.folderStatus} />
          {item.name} {itemErrorIcon(item)}
        </Button>
      )
    );
    return itemButtons;
  }, [parent?.id, pickerData, item, itemErrorIcon, moveDownTree]);

  const isDisabled = !parent || !canMove.correctHierarchy || !canMove.hasAccess;

  const moveActions: Array<JSX.Element> = useMemo(() => {
    const cancelButton = (
      <Button
        key="cancel-button"
        className="item-picker-modal__actions-single-button"
        content="Cancel"
        onClick={handleMoveClose}
      />
    );

    const primaryAction = (
      <Button.Group key="top-or-bottom-button" className="item-picker-modal__actions-group-buttons">
        <Button
          primary
          disabled={isDisabled}
          onClick={() => moveItem(RelativePriorityType.AFTER)}
          content={'Move to Bottom'}
        />

        <Button.Or />

        <Button primary disabled={isDisabled} onClick={() => moveItem(RelativePriorityType.BEFORE)} content={'Top'} />
      </Button.Group>
    );
    return [cancelButton, primaryAction];
  }, [handleMoveClose, isDisabled, moveItem]);

  return (
    <LpModal
      size="tiny"
      className="item-picker-modal"
      onClose={onClose}
      header={<div className="item-picker-modal__header">{header || defaultHeader}</div>}
      content={
        <div className="item-picker-modal__content">
          <span className="item-picker-modal__content-controls">
            <Button
              icon
              disabled={atWorkspaceRoot}
              className="item-picker-modal__content-controls-move-up-tree"
              compact
              content={<LpIcon icon={arrowAltUpSolid} size="1x" />}
              onClick={moveUpTree}
              size="tiny"
            />

            <LpSearchInputControlled
              className={'item-picker-modal__content-controls-search'}
              isDirty={isDirty}
              initialValue={inputValue}
              onChange={onChange}
              onClear={clearInput}
            />

            <IncludeDoneButton
              className="item-picker-modal__content-controls-include-done"
              handleClick={handleIncludeDoneItems}
              isChecked={includeDone}
            />
          </span>

          {moveError && (
            <LpErrorMessage
              error={moveError}
              customError={<>The move operation could not be completed. Please check the destination.</>}
              onDismiss={() => setMoveError(undefined)}
            />
          )}

          {parent && (
            <ItemMoveTableParent
              parentId={parent.id}
              currentItemId={itemId}
              atWorkspaceRoot={atWorkspaceRoot}
              packageStatus={packageStatus}
              updatePackageStatus={setPackageStatus}
            />
          )}

          <span className="item-picker-modal__content-move-targets">
            {atWorkspaceRoot ? pkgStatusButtons : itemTargetButtons}
          </span>

          <span className="item-picker-modal__content-error">{parent && moveErrorMessage}</span>
        </div>
      }
      actions={<div className="item-picker-modal__actions">{moveActions}</div>}
    />
  );
};

export default ItemMoveDialog;
