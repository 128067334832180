import { useDispatch } from 'react-redux';

import { CustomFieldDao } from 'daos/custom_field';
import { CustomFieldValueDao } from 'daos/custom_field_value';
import { CustomFieldValue } from 'daos/model_types';
import { CustomFieldValueApiActions } from 'hooks/use_custom_fields_api_actions/types';

export const useWorkspaceUserFieldValueApiActions = (
  organizationId: number,
  workspaceId: number
): CustomFieldValueApiActions => {
  const dispatch = useDispatch();
  const workspaceUserCustomFieldValueParams = { organizationId, workspaceId };

  const updateFieldValue = (workspaceUserId: number, fieldValueId: number, payload: Partial<CustomFieldValue>) =>
    dispatch(
      CustomFieldValueDao.update({ ...workspaceUserCustomFieldValueParams, workspaceUserId, fieldValueId }, payload)
    );

  const createFieldValue = (workspaceUserId: number, fieldId: number, payload: Partial<CustomFieldValue>) =>
    dispatch(
      CustomFieldValueDao.create(
        { ...workspaceUserCustomFieldValueParams, workspaceUserId },
        { field: CustomFieldDao.id(fieldId), ...payload }
      )
    );

  const removeFieldValue = (workspaceUserId: number, valueId: number) =>
    dispatch(
      CustomFieldValueDao.destroy(
        { ...workspaceUserCustomFieldValueParams, workspaceUserId, fieldValueId: valueId },
        valueId
      )
    );

  return { updateFieldValue, createFieldValue, removeFieldValue };
};
