import { DependencyStatus } from 'daos/enums';
import { checkCircleSolid, exclamationTriangleSolid, linkHorizontalSolid, LpIcon } from 'features/common/lp_icon';

const dependencyStatusDisplayOptions = {
  [DependencyStatus.ACTIVE]: { icon: linkHorizontalSolid, text: 'Scheduled', className: undefined },
  [DependencyStatus.SATISFIED]: { icon: checkCircleSolid, text: 'Satisfied', className: undefined },
  [DependencyStatus.CIRCULAR]: {
    icon: exclamationTriangleSolid,
    text: 'Circular',
    className: 'dependency-row__risky-cell',
  },
  [DependencyStatus.CLIPPED]: {
    icon: exclamationTriangleSolid,
    text: 'Clipped',
    className: 'dependency-row__risky-cell',
  },
  [DependencyStatus.UNSCHEDULED]: {
    icon: exclamationTriangleSolid,
    text: 'Unscheduled',
    className: 'dependency-row__risky-cell',
  },
};

export const InlineDependencyStatus = ({ dependencyStatus }: { dependencyStatus: DependencyStatus | null }) => {
  const { icon, text, className } = dependencyStatusDisplayOptions[dependencyStatus ?? DependencyStatus.UNSCHEDULED];

  return (
    <span className={className}>
      <LpIcon className="dependency-row__icon" icon={icon} />
      {text}
    </span>
  );
};
