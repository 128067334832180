import { noop } from 'lodash';
import {
  createContext,
  SyntheticEvent,
  useCallback,
  useMemo,
  useContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react';
import { useDispatch } from 'react-redux';

import { Item } from 'daos/model_types';
import { SetItemDeletionParams } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { moveProjectHubCurrentItemId } from 'features/ppp/project/slice';
import { DateRange } from 'redux/entities/selectors/item';

interface ItemListProviderProps {
  dateRange?: DateRange;
  expandSubFolders: boolean;
  fetchItems: () => void;
  handleCheckboxChange: (e: SyntheticEvent<HTMLInputElement, Event>, checked: boolean, item: Item) => void;
  scheduleColumnExpanded: boolean;
  setDeletionData: Dispatch<SetStateAction<SetItemDeletionParams | undefined>>;
  setExpandSubFolders: Dispatch<SetStateAction<boolean>>;
  setMoveItemId: Dispatch<SetStateAction<number | undefined>>;
  subFolderIdSet: Set<number>;
}

interface ItemListContextProps extends ItemListProviderProps {
  moveAction: (payload: { newParentId: number; itemId: number }) => void;
}

const ItemListContext = createContext<ItemListContextProps>({
  expandSubFolders: true,
  fetchItems: noop,
  handleCheckboxChange: noop,
  moveAction: noop,
  scheduleColumnExpanded: false,
  setDeletionData: noop,
  setExpandSubFolders: noop,
  setMoveItemId: noop,
  subFolderIdSet: new Set(),
});

export const ItemListContextProvider = ({
  children,
  dateRange,
  expandSubFolders,
  fetchItems,
  handleCheckboxChange,
  scheduleColumnExpanded,
  setDeletionData,
  setExpandSubFolders,
  setMoveItemId,
  subFolderIdSet,
}: PropsWithChildren<ItemListProviderProps>) => {
  const dispatch = useDispatch();

  const moveAction = useCallback(
    (payload: { newParentId: number; itemId: number }) => {
      dispatch(moveProjectHubCurrentItemId(payload));
    },
    [dispatch]
  );

  const value = useMemo(
    () => ({
      dateRange,
      expandSubFolders,
      fetchItems,
      handleCheckboxChange,
      moveAction,
      scheduleColumnExpanded,
      setDeletionData,
      setExpandSubFolders,
      setMoveItemId,
      subFolderIdSet,
    }),
    [
      dateRange,
      expandSubFolders,
      fetchItems,
      handleCheckboxChange,
      moveAction,
      scheduleColumnExpanded,
      setDeletionData,
      setExpandSubFolders,
      setMoveItemId,
      subFolderIdSet,
    ]
  );

  return <ItemListContext.Provider value={value}>{children}</ItemListContext.Provider>;
};

export const useItemListContext = () => useContext(ItemListContext);
