import { useCallback, useMemo } from 'react';
import { Dispatch } from 'redux';

import { ItemType, PackageStatus, SchedulingType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { FolderStatus } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import {
  filterAnd,
  filterItemType,
  filterParentId,
  filterFolderStatusOrNull,
  filterPackageStatus,
  filterItemName,
  filterTaskStatusSchedulingTypeOrNull,
} from 'daos/shared';
import { awaitRequestFinish } from 'lib/api';
import { ApiRequest, SuccessPayload } from 'lib/api/types';
import { isWorkspaceRoot } from 'redux/entities/selectors/item';

interface FilterQueryStringProps {
  filterItemTypes: Array<ItemType>;
  filterValue: string;
  folderStatus: Array<FolderStatus>;
  packageStatus: PackageStatus | null;
  parent: Item | undefined;
  schedulingType?: Array<SchedulingType>;
}

export const usePickerOrMoveFilterQueryString = ({
  filterItemTypes,
  filterValue,
  folderStatus,
  packageStatus,
  parent,
  schedulingType,
}: FilterQueryStringProps): string => {
  const { itemTypes, status } = isWorkspaceRoot(parent)
    ? { itemTypes: ItemType.PACKAGES, status: packageStatus }
    : { itemTypes: filterItemTypes, status: null };

  const parentId = parent?.id;

  return useMemo<string>(
    () =>
      filterAnd(
        filterItemType(itemTypes),
        parentId ? filterParentId(parentId) : '',
        filterFolderStatusOrNull(folderStatus),
        status ? filterPackageStatus(status) : '',
        filterValue ? filterItemName(filterValue) : '',
        schedulingType ? filterTaskStatusSchedulingTypeOrNull(schedulingType) : ''
      ),
    [filterValue, folderStatus, itemTypes, parentId, schedulingType, status]
  );
};

interface FilteredItemFetchProps {
  dispatch: Dispatch;
  filterQueryString: string;
  organizationId: number;
  workspaceId: number;
}

export const pickerOrMoveFilteredItemFetchUuid = ({
  dispatch,
  filterQueryString,
  organizationId,
  workspaceId,
}: FilteredItemFetchProps): string => {
  const { uuid } = dispatch(
    ItemDao.fetchAll(
      { organizationId, workspaceId },
      {
        filter: filterQueryString,
        include: { includeParent: true },
      }
    )
  );
  return uuid;
};

interface FetchPackageProps {
  dispatch: Dispatch;
  filterItemTypes: Array<ItemType>;
  folderStatus: Array<FolderStatus>;
  organizationId: number;
  parent: Item | undefined;
  schedulingType?: Array<SchedulingType>;
  workspaceId: number;
}

export const usePickerOrMoveFetchRequests = ({
  dispatch,
  filterItemTypes,
  folderStatus,
  organizationId,
  parent,
  schedulingType,
  workspaceId,
}: FetchPackageProps): {
  fetchPackages: (packageStatus: PackageStatus) => ApiRequest;
  fetchItemsForParent: (item?: Item) => ApiRequest;
} => {
  const parentId = parent?.parent?.id ?? parent?.id;
  const fetchPackages = useCallback(
    (packageStatus: PackageStatus) => {
      return dispatch(
        ItemDao.fetchAll(
          { organizationId, workspaceId },
          {
            filter: filterAnd(
              filterItemType(ItemType.PACKAGES),
              filterPackageStatus(packageStatus),
              parentId ? filterParentId(parentId) : ''
            ),
            include: { includeParent: true },
          }
        )
      );
    },
    [dispatch, organizationId, parentId, workspaceId]
  );

  const fetchItemsForParent = useCallback(
    (item?: Item) => {
      const parentId = item ? item.id : parent?.parent?.id;
      return dispatch(
        ItemDao.fetchAll(
          { organizationId, workspaceId },
          {
            filter: filterAnd(
              filterItemType(filterItemTypes),
              parentId ? filterParentId(parentId) : '',
              filterFolderStatusOrNull(folderStatus),
              schedulingType ? filterTaskStatusSchedulingTypeOrNull(schedulingType) : ''
            ),
            include: { includeParent: true },
          }
        )
      );
    },
    [dispatch, filterItemTypes, folderStatus, organizationId, parent, schedulingType, workspaceId]
  );

  return { fetchPackages, fetchItemsForParent };
};

interface TreeTraversalProps {
  atWorkspaceRoot: boolean;
  dispatch: Dispatch;
  fetchRequests: {
    fetchPackages: (packageStatus: PackageStatus) => ApiRequest;
    fetchItemsForParent: (item?: Item) => ApiRequest;
  };
  packageStatus: PackageStatus | null;
  parentItemId: number | undefined;
  onAwaitMoveUp: (response: SuccessPayload<ReadonlyArray<Item>>, parentId: number) => void;
  onAwaitMoveDown: (response: SuccessPayload<ReadonlyArray<Item>>, parent: Item) => void;
  setState: {
    setAtWorkspaceRoot: (value: React.SetStateAction<boolean>) => void;
    setFilterValue: (value: React.SetStateAction<string>) => void;
    setInputValue: (value: React.SetStateAction<string>) => void;
    setIsDirty: (value: React.SetStateAction<boolean>) => void;
    setPackageStatus: (value: React.SetStateAction<PackageStatus | null>) => void;
    setIsFetchingData: (value: React.SetStateAction<boolean>) => void;
  };
}

export const usePickerOrMoveTreeTraversal = ({
  atWorkspaceRoot,
  dispatch,
  fetchRequests,
  onAwaitMoveDown,
  onAwaitMoveUp,
  packageStatus,
  parentItemId,
  setState,
}: TreeTraversalProps): {
  moveUpTree: () => void;
  moveDownTree: (item: Item, status?: PackageStatus) => void;
} => {
  const { setAtWorkspaceRoot, setFilterValue, setInputValue, setIsDirty, setPackageStatus, setIsFetchingData } =
    setState;

  const { fetchPackages, fetchItemsForParent } = fetchRequests;

  const moveUpTree = useCallback(() => {
    setInputValue('');
    setFilterValue('');
    setIsDirty(false);
    setIsFetchingData(true);

    if (!parentItemId) {
      setAtWorkspaceRoot(true);
      setPackageStatus(null);
      return;
    }

    let awaitUuid = '';

    if (packageStatus && parentItemId) {
      const { uuid } = fetchPackages(packageStatus);
      awaitUuid = uuid;
    } else if (!packageStatus && !atWorkspaceRoot) {
      const { uuid } = fetchItemsForParent();
      awaitUuid = uuid;
    }

    if (awaitUuid) {
      dispatch(
        awaitRequestFinish<ReadonlyArray<Item>>(awaitUuid, {
          onSuccess: (response) => onAwaitMoveUp(response, parentItemId),
          onFinish: () => setIsFetchingData(false),
        })
      );
    }
  }, [
    atWorkspaceRoot,
    dispatch,
    fetchItemsForParent,
    fetchPackages,
    onAwaitMoveUp,
    packageStatus,
    parentItemId,
    setAtWorkspaceRoot,
    setFilterValue,
    setInputValue,
    setIsDirty,
    setIsFetchingData,
    setPackageStatus,
  ]);

  const moveDownTree = useCallback(
    (item: Item, status?: PackageStatus) => {
      setInputValue('');
      setFilterValue('');
      setIsDirty(false);
      setIsFetchingData(true);
      let awaitUuid = '';

      if (atWorkspaceRoot && status) {
        setAtWorkspaceRoot(false);
        const { uuid } = fetchPackages(status);
        awaitUuid = uuid;
      } else {
        const { uuid } = fetchItemsForParent(item);
        awaitUuid = uuid;
      }

      dispatch(
        awaitRequestFinish<ReadonlyArray<Item>>(awaitUuid, {
          onSuccess: (response) => onAwaitMoveDown(response, item),
          onFinish: () => setIsFetchingData(false),
        })
      );
    },
    [
      setInputValue,
      setFilterValue,
      setIsDirty,
      setIsFetchingData,
      atWorkspaceRoot,
      dispatch,
      setAtWorkspaceRoot,
      fetchPackages,
      fetchItemsForParent,
      onAwaitMoveDown,
    ]
  );

  return { moveUpTree, moveDownTree };
};
