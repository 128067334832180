import { useEffect, useRef } from 'react';

export const usePortal = (id = 'portal') => {
  const portal = useRef<HTMLDivElement | undefined>(undefined);

  useEffect(() => {
    portal.current = document.createElement('div');
    portal.current.setAttribute('id', id);
    document.body.appendChild(portal.current);

    return () => {
      if (portal.current) {
        document.body.removeChild(portal.current);
      }
    };
  }, [id]);
};
