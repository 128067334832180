import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import { Organization } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import { registrationErrors } from 'features/authentication/unauthenticated/registration/errors';
import { setCurrentUserDisconnected } from 'features/common/current/slice';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormInput from 'features/common/forms/lp_form_input';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { currentBrowserLocale, currentBrowserTimezone } from 'lib/localization';
import { frontend } from 'lib/urls';
import { purgeEntities } from 'redux/entities/slice';

const schema = yup.object().shape({
  companyName: yup.string().trim().required(lpErrorText.companyName),
});

export const OrganizationWorkspaceForm = ({
  userEmail,
  frontloadData,
}: {
  userEmail: string;
  frontloadData: ({ providedOrgId, providedWsId, callbackOnFrontloadDataComplete }: FrontloadDataProps) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    try {
      localStorage.clear();
      // eslint-disable-next-line no-empty
    } catch (_) {
      // empty catch to handle users who block localStorage use on their browsers
    }
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const {
    handleSubmit,
    setStatus: setFormApiError,
    status: formApiError,
    getFieldProps,
    getFieldMeta,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email: userEmail,
      companyName: '',
    },
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ companyName }) => {
      setFormApiError(undefined);

      const { uuid } = dispatch(
        OrganizationDao.create({
          name: companyName,
          creator: {
            locale: currentBrowserLocale(),
            timezone: currentBrowserTimezone(),
          },
        })
      );

      dispatch(
        awaitRequestFinish<Organization>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormApiError(errors[0]);
            }
            setSubmitting(false);
          },
          onSuccess: ({ data }) => {
            dispatch(purgeEntities());
            dispatch(setCurrentUserDisconnected(false));
            frontloadData({
              providedOrgId: data.id,
              providedWsId: undefined,
              callbackOnFrontloadDataComplete: (workspaceId) => {
                history.push(frontend.workspace.url({ organizationId: data.id, workspaceId }));
              },
            });
          },
        })
      );
    },
  });

  return (
    <Form className="lp-registration__form" autoComplete="on" onSubmit={handleSubmit} loading={isSubmitting}>
      {formApiError && (
        <LpErrorMessage
          className="lp-registration__api-error"
          error={formApiError}
          customError={registrationErrors(formApiError.code, formApiError.detail)}
        />
      )}

      <LpFormInput
        label="Business Email"
        fieldKey="email"
        className="lp-registration__input-field"
        disabled={true}
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
      />

      <LpFormInput
        label="Company Name"
        fieldKey="companyName"
        className="lp-registration__input-field"
        getFieldProps={getFieldProps}
        getFieldMeta={getFieldMeta}
      />

      <div className="lp-registration__actions">
        <Button className="lp-registration__cancel-button" content="Cancel" onClick={handleCancel} type="button" />

        <Button
          className="lp-registration__submit-button lp-registration__submit-button--restart"
          content="Create Account"
          type="submit"
          disabled={isSubmitting}
        />
      </div>
    </Form>
  );
};
