import classnames from 'classnames';
import { ReactNode } from 'react';

import './styles.scss';
import { LpButtonAsLink } from 'features/common/as_components';

interface CallToActionLinkProps {
  children?: ReactNode;
  className?: string;
  openInNewWindow?: boolean;
  to: string;
}

export const CallToActionLink = ({ children, className, openInNewWindow = false, to }: CallToActionLinkProps) => {
  return (
    <LpButtonAsLink to={to} className={classnames(className, 'lp-call-to-action')} openInNewWindow={openInNewWindow}>
      {children}
    </LpButtonAsLink>
  );
};
