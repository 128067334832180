export enum ItemIntakeSettableField {
  Color = 'color',
  CostCode = 'costCode',
  CustomField = 'customField',
  Description = 'description',
  Files = 'files',
  ProjectStatus = 'folderStatus',
  Name = 'name',
  RateSheetId = 'rateSheetId',
  ScheduleDirective = 'scheduleDirective',
  TargetFinish = 'targetFinish',
  TargetFinishType = 'targetFinishType',
  TargetStart = 'targetStart',
  TaskStatus = 'taskStatus',
  WorkLimit = 'workLimit',
  WorkType = 'workType',
}

export enum ItemIntakeSettableFieldDisplay {
  Color = 'Project Color',
  CostCode = 'Cost Code',
  CustomField = 'Custom Field',
  Description = 'Description',
  Files = 'File Uploads',
  ProjectStatus = 'Project Status',
  Name = 'Name',
  RateSheetId = 'RateSheet Id',
  ScheduleDirective = 'Schedule Directive',
  TargetFinish = 'Target Finish',
  TargetFinishType = 'Target FinishType',
  TargetStart = 'Target Start',
  TaskStatus = 'Task Status',
  WorkLimit = 'Work Limit',
  WorkType = 'Work Type',
}

export enum ItemIntakeRowSetting {
  Required = 'required',
  Optional = 'optional',
  Automatic = 'automatic',
  ReadOnly = 'readonly',
}

export enum IntakeSettingsSubmitOption {
  Save = 'save',
  SaveAndClose = 'saveAndClose',
  UseNow = 'useNow',
}
