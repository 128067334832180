import {
  ExternalIntegration,
  Item,
  JiraAccessibleResourcesView,
  JiraIssueFilter,
  JiraSyncSettings,
} from 'daos/model_types';
import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { JiraProjectModalFormFieldMapping } from 'features/jira_project/modal/types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

interface JiraExternalIntegrationParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraExternalIntegrationUpdateParams extends JiraExternalIntegrationParams {
  externalIntegrationId: number;
}

const { resource } = defineModel({
  apiType: 'jiraExternalIntegrations',
  type: 'JIRA_EXTERNAL_INTEGRATION',
});

const { JIRA_EXTERNAL_INTEGRATION } = resource;

export interface JiraExternalIntegrationDao {
  itemDto: Pick<Item, 'type' | 'name' | 'itemType' | 'folderStatus' | 'parent'>;
  externalIntegrationsDto: Pick<ExternalIntegration, 'credentialsId' | 'sourceSystem' | 'sourceItemId'>;
  jiraIssueFilterDto: Pick<JiraIssueFilter, 'issueStatusIds' | 'issueTypeIds' | 'issueCreatedDate'>;
  jiraAccessibleResourcesDto: Pick<JiraAccessibleResourcesView, 'cloudId'>;
  externalIntegrationFieldMappingDtos: Array<JiraProjectModalFormFieldMapping>;
  jiraSyncSettingDto: Pick<
    JiraSyncSettings,
    'syncJiraWorklog' | 'costCodeId' | 'syncTimesheetWorklog' | 'taskGroupHierarchy' | 'rankIssues'
  >;
}

export type JiraExternalIntegrationsUpdateDto = Pick<
  JiraExternalIntegrationDao,
  'externalIntegrationFieldMappingDtos' | 'jiraIssueFilterDto' | 'jiraSyncSettingDto'
> & {
  oauthCredentialsId?: number;
};

const createJiraExternalIntegration = (
  params: JiraExternalIntegrationParams,
  data: Partial<JiraExternalIntegrationDao>
) =>
  request(backend.jiraExternalIntegrations.url(params), JIRA_EXTERNAL_INTEGRATION, {
    body: JSON.stringify({ data }),
    method: HttpMethod.POST,
  });

const updateJiraExternalIntegration = (
  params: JiraExternalIntegrationUpdateParams,
  data: Partial<JiraExternalIntegrationsUpdateDto>
) =>
  request(backend.jiraExternalIntegration.url(params), JIRA_EXTERNAL_INTEGRATION, {
    body: JSON.stringify({ data }),
    method: HttpMethod.PATCH,
  });

export const JiraExternalIntegrationDao = {
  create: createJiraExternalIntegration,
  update: updateJiraExternalIntegration,
} as const;
