import { groupBy, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { TimesheetEntry } from 'daos/model_types';
import { readonlyArray } from 'lib/readonly_record';
import { RootState } from 'redux/root_reducer';

import { createCacheByIdConfig, getNumberArgument, createCacheByIdsConfig } from './shared';

interface TimesheetEntriesIndexedByItemIdCostCodeIdAndDate {
  [costCodeId: string]: {
    [date: string]: TimesheetEntry;
  };
}

export interface TimesheetEntryLookup {
  [assignmentId: number]: TimesheetEntriesIndexedByItemIdCostCodeIdAndDate;
}

interface TimesheetEntrySummary {
  costCodes: { [assignmentId: number]: { [costCodeId: string]: number } };
  days: { [date: string]: number };
  timesheetEntryLookup: {
    [assignmentId: number]: { [costCodeId: string]: { [date: string]: TimesheetEntry } };
  };
  total: number;
}

const getTimesheetEntriesById = (state: RootState) => state.entities.timesheetEntries;
export const getTimesheetEntriesForId = (state: RootState, id: number) => state.entities.timesheetEntries[id];

const getTimesheetEntriesIndexedByWsUserId = createCachedSelector(getTimesheetEntriesById, (tses) =>
  groupBy(tses, (tse) => tse.workspaceUser.id)
)(createCacheByIdConfig());

const getTimesheetEntriesForWsUserId = createCachedSelector(
  getTimesheetEntriesIndexedByWsUserId,
  getNumberArgument,
  (tseByWsUserId, wsUserId) => tseByWsUserId[wsUserId]
)(createCacheByIdConfig());

export const getTimesheetEntriesForWsUserIdIndexedByItemIdCostCodeIdAndDate = createCachedSelector(
  getTimesheetEntriesForWsUserId,
  (tses) => {
    return (
      (tses &&
        tses.reduce(
          (acc: TimesheetEntrySummary, tse) => {
            const {
              date,
              item: { id: assignmentId },
              work,
            } = tse;
            const costCodeId = String(tse.costCode?.id);
            const { timesheetEntryLookup: index, days, costCodes } = acc;

            const indexForAssignment = index[assignmentId] || {};
            index[assignmentId] = indexForAssignment;

            const indexForCostCode = indexForAssignment[costCodeId] || {};
            indexForAssignment[costCodeId] = indexForCostCode;

            indexForCostCode[date] = tse;

            const logged = moment.duration(work).valueOf() as number;

            days[date] = (days[date] || 0) + logged;

            const totalByCostCodeForAssignment = costCodes[assignmentId] || {};
            costCodes[assignmentId] = totalByCostCodeForAssignment;
            totalByCostCodeForAssignment[costCodeId] = (totalByCostCodeForAssignment[costCodeId] || 0) + logged;

            acc.total = acc.total + logged;

            return acc;
          },
          {
            costCodes: {},
            days: {},
            timesheetEntryLookup: {},
            total: 0,
          }
        )) || {
        costCodes: {},
        days: {},
        timesheetEntryLookup: {},
        total: 0,
      }
    );
  }
)(createCacheByIdConfig());

const getTimesheetEntriesForAssignmentId = createSelector(
  getTimesheetEntriesById,
  getNumberArgument,
  (timesheetEntries, id) => {
    const tsesByAssignmentId = groupBy(timesheetEntries, (tse) => tse.item.id);
    const assignmentTse = tsesByAssignmentId[id] || [];
    return readonlyArray(assignmentTse);
  }
);

export const getHasLoggedTimeForAssignmentId = createCachedSelector(getTimesheetEntriesForAssignmentId, (tses) => {
  const zeroDuration = 'PT0S';
  return tses.some((tse) => tse.work !== zeroDuration);
})(createCacheByIdConfig());

export const getTimesheetEntriesForAssignmentIdOrderedByDate = createSelector(
  getTimesheetEntriesForAssignmentId,
  (tses) => orderBy(tses, 'date', 'desc')
);

export const getAssignmentIdsWithLoggedTimeSet = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, assignmentIds: ReadonlyArray<number>) => assignmentIds,
  (state, assignmentIds) => {
    const assignmentIdsWithLoggedTime = new Set<number>();
    assignmentIds.forEach((id) => {
      const hasLoggedTime = getHasLoggedTimeForAssignmentId(state, id);

      if (hasLoggedTime) {
        assignmentIdsWithLoggedTime.add(id);
      }
    });

    return assignmentIdsWithLoggedTime;
  }
)(createCacheByIdsConfig());
