import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { Dependency } from 'daos/model_types';
import { readonlyArray, groupRecordBy } from 'lib/readonly_record';
import { getItemForId } from 'redux/entities/selectors/item';
import { EntityLookupById } from 'redux/entities/types';
import { RootState } from 'redux/root_reducer';

import { createCacheByIdConfig, getNumberArgument } from './shared';

const emptyDependencyArray = readonlyArray<Dependency>([]);

const getDependenciesById = (state: RootState): EntityLookupById<Dependency> => state.entities.dependencies;

export const getDependencyForId = (state: RootState, id: number) => getDependenciesById(state)[id];

const getDependenciesByPredecessorId = createSelector(getDependenciesById, (dependenciesById) => {
  return groupRecordBy(dependenciesById, (dependency) => dependency.predecessor.id);
});

export const getDependenciesForPredecessorId = createCachedSelector(
  getDependenciesByPredecessorId,
  getNumberArgument,
  (dependenciesByPredecessor, predecessorId) => {
    return dependenciesByPredecessor[predecessorId] ?? emptyDependencyArray;
  }
)(createCacheByIdConfig());

const getDependenciesBySuccessorId = createSelector(getDependenciesById, (dependenciesById) => {
  return groupRecordBy(dependenciesById, (dependency) => dependency.successor.id);
});

export const getDependenciesForSuccessorId = createCachedSelector(
  getDependenciesBySuccessorId,
  getNumberArgument,
  (dependenciesBySuccessor, successorId) => {
    return dependenciesBySuccessor[successorId] ?? emptyDependencyArray;
  }
)(createCacheByIdConfig());

export const getHasSuccessors = createSelector(getItemForId, (item) => !!item && item.successorCount > 0);

export const getHasPredecessors = createSelector(getItemForId, (item) => !!item && item.predecessorCount > 0);
