import { WidgetType, widgetTypeDisplay } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { LpIcon, sparklesSolid } from 'features/common/lp_icon';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { Title } from 'features/dashboards_v2/widget_settings/title';

export const InsightsSettings = ({ widget }: { widget: Widget }) => {
  const header = (
    <>
      <LpIcon icon={sparklesSolid} /> {widgetTypeDisplay[WidgetType.Insights]}
    </>
  );

  const content = (
    <>
      <Title widgetId={widget.id} />
      <LocationSetting disablePackageStatusSelection />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
