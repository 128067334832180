export const WhitePortfolioSvg = () => (
  <svg width="45" height="45" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4246_21569)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0.5C1.79375 0.5 0 2.42188 0 4.78571V26.2143C0 28.5781 1.79375 30.5 4 30.5H21.8447C20.105 28.9408 19 26.6075 19 24C19 19.3056 22.5817 15.5 27 15.5C28.8919 15.5 30.6304 16.1978 32 17.3643V4.78571C32 2.42188 30.2062 0.5 28 0.5H4ZM4.58579 8.4438C4.21071 8.04194 4 7.49689 4 6.92857C4 6.36025 4.21071 5.81521 4.58579 5.41334C4.96086 5.01148 5.46957 4.78571 6 4.78571C6.53043 4.78571 7.03914 5.01148 7.41421 5.41334C7.78929 5.81521 8 6.36025 8 6.92857C8 7.49689 7.78929 8.04194 7.41421 8.4438C7.03914 8.84566 6.53043 9.07143 6 9.07143C5.46957 9.07143 4.96086 8.84566 4.58579 8.4438ZM26.5 5.32143C27.3312 5.32143 28 6.03795 28 6.92857C28 7.8192 27.3312 8.53572 26.5 8.53572H11.5C10.6687 8.53572 10 7.8192 10 6.92857C10 6.03795 10.6687 5.32143 11.5 5.32143H26.5Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 31.5C31.866 31.5 35 28.366 35 24.5C35 20.634 31.866 17.5 28 17.5C24.134 17.5 21 20.634 21 24.5C21 28.366 24.134 31.5 28 31.5Z"
      fill="#EBECF0"
    />
    <rect width="12" height="12" transform="translate(22 19)" fill="white" fillOpacity="0.01" />
    <path
      d="M32.8003 24.3341L28.423 19.8183L27.9933 19.375L24.7036 22.7688L23.1998 24.3341C23.0386 24.5003 23.0386 24.7635 23.1998 24.9159L26.2075 28.0188L27.9933 29.8611L31.283 26.4673L31.3367 26.4119L32.8003 24.9159C32.9614 24.7497 32.9614 24.4865 32.8003 24.3341ZM27.9933 26.1765L26.4894 24.625L27.9933 23.0735L29.4972 24.625L27.9933 26.1765Z"
      fill="#7A869A"
    />
    <path
      d="M27.9933 23.0734C27.0131 22.0622 26.9997 20.4137 27.9799 19.3887L24.7036 22.7825L26.4894 24.6248L27.9933 23.0734Z"
      fill="url(#paint0_linear_4246_21569)"
    />
    <path
      d="M29.5106 24.6113L27.9933 26.1766C28.9869 27.2017 28.9869 28.8501 27.9933 29.8752L31.2964 26.4675L29.5106 24.6113Z"
      fill="url(#paint1_linear_4246_21569)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4246_21569"
        x1="27.73"
        y1="21.5005"
        x2="25.5642"
        y2="23.5998"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.176" stopColor="#344563" />
        <stop offset="1" stopColor="#7A869A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4246_21569"
        x1="28.2859"
        y1="27.7252"
        x2="30.4474"
        y2="25.6301"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.176" stopColor="#344563" />
        <stop offset="1" stopColor="#7A869A" />
      </linearGradient>
      <clipPath id="clip0_4246_21569">
        <rect width="45" height="45" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
