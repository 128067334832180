import { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { PackageStatus } from 'daos/enums';
import { PackageStatusDisplay } from 'daos/pkg';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

interface PackageStatusDropdownProps {
  disabled: DropdownProps['disabled'];
  fluid: DropdownProps['fluid'];
  onChange: (event: React.SyntheticEvent<Element, Event>, { value }: { value: PackageStatus }) => void;
  packageStatus: PackageStatus;
  useOnGrid?: boolean;
  onClose?: () => void;
  open?: boolean;
}

const getPackageStatusOptions = (includeCapacity: Boolean) => [
  {
    text: PackageStatusDisplay.Scheduled,
    value: PackageStatus.SCHEDULED,
  },
  {
    text: PackageStatusDisplay.Backlog,
    value: PackageStatus.BACKLOG,
  },
  {
    text: PackageStatusDisplay.Archived,
    value: PackageStatus.ARCHIVED,
  },
  ...(includeCapacity
    ? [
        {
          text: PackageStatusDisplay.Capacity,
          value: PackageStatus.CAPACITY,
        },
      ]
    : []),
];

const PackageStatusDropdown = ({
  disabled,
  fluid,
  onChange,
  packageStatus,
  useOnGrid = false,
  onClose,
  open,
}: PackageStatusDropdownProps) => {
  const hasGroupCapacityEnabled = useHasFeature(FeatureFlag.groupCapacity);
  const handleChange = (event: SyntheticEvent<Element, Event>, { value }: DropdownProps) => {
    if (value !== packageStatus) {
      onChange(event, { value: value as PackageStatus });
    }
  };

  const dropdown = (
    <Dropdown
      selection
      className="lp-package-status-dropdown"
      closeOnChange
      disabled={disabled}
      fluid={fluid}
      value={packageStatus}
      onChange={handleChange}
      selectOnNavigation={false}
      options={getPackageStatusOptions(hasGroupCapacityEnabled)}
      onClose={onClose}
      open={open}
    />
  );

  return useOnGrid ? <PortalDropdown>{dropdown}</PortalDropdown> : dropdown;
};

export default PackageStatusDropdown;
