import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { Label } from 'semantic-ui-react';

import { LpIcon, linkHorizontalLight } from 'features/common/lp_icon';
import { slate700 } from 'style/variables';

import 'containers/shared/notice_icons/icons.scss';

interface DependencyLabelProps {
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
}

export const DependencyLabel = ({ className, onClick }: DependencyLabelProps) => (
  <Label
    className={classNames(className, 'icon notice dependency', onClick && 'clickable')}
    content={<LpIcon hoverText={'Has Dependencies'} size="sm" icon={linkHorizontalLight} color={slate700} />}
    onClick={onClick}
  />
);
