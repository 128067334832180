import { useSelector } from 'react-redux';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  getOrganizationUsersById,
  getWorkspaceUsersForWorkspaceIdIndexedByUserId,
} from 'redux/entities/selectors/user';

interface FilterDisconnectedUsersProps {
  dropdownOptions: Array<DropdownItemProps & { search: string }>;
  selectedOrgUserId: number | undefined;
}

type Props = FilterDisconnectedUsersProps & Partial<DropdownProps>;

export const useFilterDropdownOptions = () => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const orgUsers = useSelector(getOrganizationUsersById);
  const currentWorkspaceUsersByUserId = useSelector((state) =>
    getWorkspaceUsersForWorkspaceIdIndexedByUserId(state, workspaceId)
  );

  const filterDisconnectedUsers = ({ clearable, dropdownOptions, selectedOrgUserId }: Props) => {
    const filteredOptions = dropdownOptions.reduce(
      (acc: Array<DropdownItemProps & { search: string }>, dropdownOption) => {
        const orgUserId = dropdownOption.value as number;
        const orgUser = orgUsers[orgUserId];
        const userId = orgUser?.user.id;

        if (!userId) {
          return acc;
        }

        const isDisconnected = currentWorkspaceUsersByUserId[userId]?.disconnectedAt !== null;

        if (!isDisconnected || orgUserId === selectedOrgUserId) {
          acc.push(dropdownOption);
        }

        return acc;
      },
      []
    );

    if (clearable) {
      filteredOptions.unshift({
        text: <span> Unassigned </span>,
        value: 0,
        search: 'UNASSIGNED|NONE',
        key: 'UNASSIGNED|NONE',
      });
    }

    return filteredOptions;
  };

  return { filterDisconnectedUsers };
};
