import moment, { Moment } from 'moment-timezone';

export const isValidDateRange = (start: string | null, finish: string | null) =>
  !start || !finish || start.localeCompare(finish) <= 0;

const isDateValid = (value: string, dateFormat = 'YYYY-MM-DD', strict = false) => {
  const userFormattedDate: Moment = moment(value, dateFormat, strict);
  const defaultDateFormat: Moment = moment(value, 'YYYY-MM-DD', strict);

  if (!/[\\./-]/.test(value)) {
    return false;
  }

  if (userFormattedDate.isValid()) {
    return true;
  }

  if (defaultDateFormat.isValid()) {
    return true;
  }

  return false;
};

export default isDateValid;
