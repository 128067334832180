import classNames from 'classnames';
import { Moment } from 'moment-timezone';
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';
import { Ref } from 'semantic-ui-react';

import { flagCheckeredSolid, leftArrowSolid, LpIcon, rightArrowSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { BASE_NUBS_COLUMN_WIDTH } from 'features/common/workload/workload_table/helpers';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getItemMetricsForId } from 'redux/entities/selectors/item_metric';
import { gray700, red500 } from 'style/variables';

import './workload_rail.scss';

interface WorkloadRailProps {
  startDate: string;
  endDate: string;
  itemId: number;
  headerSizingBox: DOMRect | null;
}

const getIsEffectiveFinishInsideRange = ({
  startDate,
  endDate,
  momentEffectiveFinishLocalDate,
}: {
  startDate: Moment;
  endDate: Moment;
  momentEffectiveFinishLocalDate: Moment;
}) => {
  const isEffectiveFinishBeforeStartDate = momentEffectiveFinishLocalDate.isBefore(startDate);
  const isEffectiveFinishAfterEndDate = momentEffectiveFinishLocalDate.isAfter(endDate);

  const isEffectiveFinishInBetweenStartAndEndDate = !isEffectiveFinishBeforeStartDate && !isEffectiveFinishAfterEndDate;

  return {
    isEffectiveFinishBeforeStartDate,
    isEffectiveFinishAfterEndDate,
    isEffectiveFinishInBetweenStartAndEndDate,
  };
};

const FLAG_POST_OFFSET = 6;

const WorkloadRail = memo(({ startDate, endDate, itemId, headerSizingBox }: WorkloadRailProps) => {
  const itemMetrics = useSelector((state) => (itemId ? getItemMetricsForId(state, itemId) : undefined));
  const { formatLocalDate, stringDateDifference, parseLocalDateWithWorkspaceDateFormatAsMoment } =
    useLocalizedFormats();

  const effectiveFinish = itemMetrics?.effectiveTargetFinish;
  const effectiveFinishLocalDate = formatLocalDate(effectiveFinish ?? '');

  const { isEffectiveFinishAfterEndDate, isEffectiveFinishBeforeStartDate, isEffectiveFinishInBetweenStartAndEndDate } =
    getIsEffectiveFinishInsideRange({
      startDate: parseLocalDateWithWorkspaceDateFormatAsMoment(startDate),
      endDate: parseLocalDateWithWorkspaceDateFormatAsMoment(endDate),
      momentEffectiveFinishLocalDate: parseLocalDateWithWorkspaceDateFormatAsMoment(effectiveFinishLocalDate),
    });

  const dayCountBeforeEffectiveFinish = stringDateDifference(startDate, effectiveFinishLocalDate, 'days');

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!headerSizingBox) {
      return;
    }

    const dayCountBeforeEffectiveFinishWidth = BASE_NUBS_COLUMN_WIDTH * dayCountBeforeEffectiveFinish;
    const workloadRailLeftWidth = dayCountBeforeEffectiveFinishWidth - FLAG_POST_OFFSET;

    ref.current?.style.setProperty('--workload-rail-left-width', `${workloadRailLeftWidth}px`);
    document.documentElement.style.setProperty('--workload-header-height', `${Math.ceil(headerSizingBox.height)}px`);

    return () => {
      document.documentElement.style.setProperty('--workload-header-height', ``);
    };
  }, [dayCountBeforeEffectiveFinish, headerSizingBox]);

  if (!effectiveFinish) {
    return null;
  }

  return (
    <Ref innerRef={ref}>
      <div className="workload-rail">
        <WorkloadRailOnSchedule
          isEffectiveFinishInBetweenStartAndEndDate={isEffectiveFinishInBetweenStartAndEndDate}
          isEffectiveFinishBeforeStartDate={isEffectiveFinishBeforeStartDate}
          effectiveFinishLocalDate={effectiveFinishLocalDate}
        />
        <WorkloadEffectiveFinishDate
          isEffectiveFinishInBetweenStartAndEndDate={isEffectiveFinishInBetweenStartAndEndDate}
          effectiveFinishLocalDate={effectiveFinishLocalDate}
        />
        <WorkloadRailScheduleRisk
          isEffectiveFinishAfterEndDate={isEffectiveFinishAfterEndDate}
          effectiveFinishLocalDate={effectiveFinishLocalDate}
        />
      </div>
    </Ref>
  );
}, areEqual);

const WorkloadRailOnSchedule = ({
  isEffectiveFinishBeforeStartDate,
  isEffectiveFinishInBetweenStartAndEndDate,
  effectiveFinishLocalDate,
}: {
  isEffectiveFinishInBetweenStartAndEndDate: boolean;
  isEffectiveFinishBeforeStartDate: boolean;
  effectiveFinishLocalDate: string;
}) => {
  return (
    <>
      {isEffectiveFinishInBetweenStartAndEndDate && (
        <div className="workload-rail__on-schedule">
          <div className="workload-rail__on-schedule-nub" />
        </div>
      )}
      {isEffectiveFinishBeforeStartDate && (
        <div className="workload-rail__before-range-arrow">
          <LpPopUp
            positionStrategy="fixed"
            placement="top"
            trigger={
              <span>
                <LpIcon icon={leftArrowSolid} color={red500} />
              </span>
            }
            content={<span>{effectiveFinishLocalDate}</span>}
          />
        </div>
      )}
    </>
  );
};

const WorkloadEffectiveFinishDate = ({
  isEffectiveFinishInBetweenStartAndEndDate,
  effectiveFinishLocalDate,
}: {
  isEffectiveFinishInBetweenStartAndEndDate: boolean;
  effectiveFinishLocalDate: string;
}) => {
  return (
    <>
      {isEffectiveFinishInBetweenStartAndEndDate && (
        <div className="workload-rail__effective-finish-date">
          <LpPopUp
            positionStrategy="fixed"
            placement="top"
            trigger={
              <span>
                <LpIcon icon={flagCheckeredSolid} color={gray700} />
              </span>
            }
            content={<span>{effectiveFinishLocalDate}</span>}
          />
        </div>
      )}
    </>
  );
};

const WorkloadRailScheduleRisk = ({
  isEffectiveFinishAfterEndDate,
  effectiveFinishLocalDate,
}: {
  isEffectiveFinishAfterEndDate: boolean;
  effectiveFinishLocalDate: string;
}) => {
  return (
    <>
      <div className="workload-rail__schedule-risk">
        <div
          className={classNames(
            'workload-rail__schedule-risk-nub',
            isEffectiveFinishAfterEndDate && 'workload-rail__schedule-risk-nub--effectiveDateAfterEndDate'
          )}
        />
      </div>
      {isEffectiveFinishAfterEndDate && (
        <div className="workload-rail__after-range-arrow">
          <LpPopUp
            positionStrategy="fixed"
            placement="top"
            trigger={
              <span>
                <LpIcon icon={rightArrowSolid} color={gray700} />
              </span>
            }
            content={<span>{effectiveFinishLocalDate}</span>}
          />
        </div>
      )}
    </>
  );
};

export default WorkloadRail;
