import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'jira_timesheets',
  type: 'JIRA_TIMESHEETS',
});

const { JIRA_TIMESHEETS } = resource;

interface JiraTimesheetsParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraTimesheetsQuery {
  cloudId: string;
  oauthCredentialsId: number;
}

const jiraTimesheetsAuth = (params: JiraTimesheetsParams, query: JiraTimesheetsQuery) =>
  request(backend.jiraTimesheetsAuthentication.url(params, { query }), JIRA_TIMESHEETS, {
    method: HttpMethod.GET,
  });

export const JiraTimesheetsDao = {
  fetchAuth: jiraTimesheetsAuth,
} as const;
