import { useSelector } from 'react-redux';

import { PackageStatus } from 'daos/enums';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { frontend } from 'lib/urls';

export const usePackageCollectionUrlForPackageStatus = (packageStatus?: PackageStatus | null) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const params = { organizationId, workspaceId };

  if (!packageStatus) {
    return frontend.projectsAll.url(params);
  }

  switch (packageStatus) {
    case PackageStatus.ARCHIVED:
      return frontend.archiveCollectionPortfolio.url(params);
    case PackageStatus.BACKLOG:
      return frontend.pendingCollectionPortfolio.url(params);
    case PackageStatus.SCHEDULED:
      return frontend.scheduledCollectionPortfolio.url(params);
    case PackageStatus.TEMPLATE:
      return frontend.templateCollectionPortfolio.url(params);
    case PackageStatus.CAPACITY:
      return frontend.capacityCollectionPortfolio.url(params);
  }
};
