import { defineModel } from './shared';

const { resource } = defineModel({
  apiType: 'storyPoints',
  relationships: [],
  type: 'STORY_POINTS',
});

const { resourceId } = resource;

export const StoryPointsDao = { id: resourceId } as const;
