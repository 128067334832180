import { useSelector } from 'react-redux';

import { PackageStatus, WidgetOnClickViewType, WidgetType } from 'daos/enums';
import { Item, Dashboard, Widget } from 'daos/model_types';
import { getDashboardForId } from 'features/dashboards/selectors';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { useGetItemScopeForWidget } from 'features/dashboards_v2/widget/use_get_item_for_scope';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { LibraryLocation } from 'features/library/types';
import { frontend } from 'lib/urls';
import { getCustomFieldForId } from 'redux/entities/selectors/custom_field';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

export function useItemWidgetClickThroughUrl(widget?: Widget | null) {
  const { organizationId, workspaceId, config } = widget ?? {};
  const { customFieldId } = config ?? {};

  // onClickView is used for row clicks in the List view. Eventually both should
  // be made configurable.
  const onClickView = widget?.widgetType === WidgetType.List ? WidgetOnClickViewType.Grid : config?.onClickView;

  const dashboard = useSelector((state) =>
    widget?.dashboardId ? getDashboardForId(state, widget.dashboardId) : undefined
  );
  const isGuestWorkspaceUser = useSelector(getIsGuestCurrentWorkspaceUser);

  const customField = useSelector((state) => (customFieldId ? getCustomFieldForId(state, customFieldId) : undefined));
  const item = useGetItemScopeForWidget(widget);

  const hasClickThrough = !!onClickView && onClickView !== WidgetOnClickViewType.None;

  const { libraryLocation, libraryResource } = useDashboardContext();

  const missingRequiredBaseProperties =
    !organizationId || !workspaceId || !widget || !dashboard || !hasClickThrough || widget?.data?.errors.length;
  const missingRequiredNonGuestProperties = !item || !libraryLocation || !libraryResource;

  if (missingRequiredBaseProperties) {
    return '';
  }

  if (isGuestWorkspaceUser) {
    return getExpandableGuestWidgetClickThroughUrl(widget);
  }

  if (missingRequiredNonGuestProperties) {
    return '';
  }

  const packageStatus = libraryResource.packageStatus ?? undefined;

  const itemId = item.id;
  const customFieldName = customField?.name;

  const commonUrlParams = {
    itemId,
    organizationId,
    workspaceId,
    dashboardId: dashboard.id,
  };

  switch (onClickView) {
    case WidgetOnClickViewType.Board:
    case WidgetOnClickViewType.Changes:
    case WidgetOnClickViewType.Grid:
    case WidgetOnClickViewType.Workload:
    case WidgetOnClickViewType.Metrics:
    case WidgetOnClickViewType.DashboardNote:
      return widgetOnClickUrl(widget, item, dashboard, libraryLocation, packageStatus, onClickView);
    case WidgetOnClickViewType.Dashboard:
      return frontend.portableDashboard.url(commonUrlParams);
    case WidgetOnClickViewType.Schedule:
      return frontend.portableSchedule.url(commonUrlParams);
    case WidgetOnClickViewType.ItemPanelChanges:
      return `#panelId=${itemId}&panelSection=${TabNames.Changes}Tab`;
    case WidgetOnClickViewType.ItemPanelPlanning:
      return `#panelId=${itemId}&panelSection=${TabNames.Planning}Tab`;
    case WidgetOnClickViewType.ItemPanelProperties:
      return `#panelId=${itemId}&panelSection=${TabNames.Properties}Tab`;
    case WidgetOnClickViewType.ItemPanelNotes:
      return `#panelId=${itemId}&panelSection=${customFieldName}Tab`;
    case WidgetOnClickViewType.ItemPanelSchedule:
      return `#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`;
    default:
      return '';
  }
}

const expandableGuestWidgetByType: Record<WidgetType, boolean> = {
  [WidgetType.BoardSummary]: true,
  [WidgetType.Changes]: false,
  [WidgetType.DashboardNote]: true,
  [WidgetType.Image]: false,
  [WidgetType.Insights]: false,
  [WidgetType.Intake]: false, // not expandable, but has its own click through handled
  [WidgetType.LinkedNote]: true,
  [WidgetType.List]: true,
  [WidgetType.MetricsTally]: false,
  [WidgetType.PropertiesSummary]: false,
  [WidgetType.ScheduleSummary]: false,
  [WidgetType.Workload]: true,
  [WidgetType.TableReport]: false,
};

function getExpandableGuestWidgetClickThroughUrl(widget: Widget) {
  const { organizationId, workspaceId, dashboardId, id, widgetType } = widget;
  if (expandableGuestWidgetByType[widgetType]) {
    return frontend.dashboardGuestWidget.url({ organizationId, workspaceId, dashboardId, widgetId: id });
  }

  return '';
}

const widgetTypesRequiringNonWorkspaceRootItemLocationFilter = [
  WidgetType.PropertiesSummary,
  WidgetType.ScheduleSummary,
  WidgetType.LinkedNote,
];

function widgetOnClickUrl(
  widget: Widget,
  item: Item,
  dashboard: Dashboard,
  libraryLocation: LibraryLocation,
  packageStatus: PackageStatus | undefined,
  widgetOnClickViewType: WidgetOnClickViewType
) {
  const { widgetType, organizationId, workspaceId } = widget;

  const isScopedWidget = widgetTypesRequiringNonWorkspaceRootItemLocationFilter.includes(widgetType);

  if (isScopedWidget) {
    const commonUrlParams = {
      itemId: item.id,
      organizationId,
      workspaceId,
      dashboardId: widget.dashboardId,
    };

    switch (widgetOnClickViewType) {
      case WidgetOnClickViewType.Board:
        return frontend.portableBoard.url(commonUrlParams);
      case WidgetOnClickViewType.Changes:
        return frontend.portableChanges.url(commonUrlParams);
      case WidgetOnClickViewType.Grid:
        return frontend.portableGrid.url(commonUrlParams);
      case WidgetOnClickViewType.Workload:
        return frontend.portableWorkload.url(commonUrlParams);
      default:
        return frontend.portableDashboard.url(commonUrlParams);
    }
  }

  return getDetachedClickThroughUrl({
    dashboard,
    widget,
    libraryLocation,
    packageStatus,
    widgetOnClickViewType,
  });
}

interface GetDetachedClickThroughUrlProps {
  dashboard: Dashboard;
  widget: Widget;
  libraryLocation: LibraryLocation;
  packageStatus: PackageStatus | undefined;
  widgetOnClickViewType: WidgetOnClickViewType;
}

const getDetachedClickThroughUrl = ({
  dashboard,
  widget,
  libraryLocation,
  packageStatus,
  widgetOnClickViewType,
}: GetDetachedClickThroughUrlProps) => {
  const { organizationId, workspaceId, id: widgetId } = widget;
  const sharedUrlParams = { organizationId, workspaceId, widgetId };

  switch (libraryLocation) {
    case LibraryLocation.Collection: {
      const params = { ...sharedUrlParams, packageId: dashboard.itemId, dashboardId: dashboard.id };
      const collectionUrl = () => {
        if (packageStatus === PackageStatus.BACKLOG) {
          return {
            board: frontend.pendingCollectionWidgetTaskBoard,
            workload: frontend.pendingCollectionWidgetWorkload,
            changes: frontend.pendingCollectionWidgetChanges,
            metrics: frontend.pendingCollectionWidgetMetrics,
            dashboardNote: frontend.pendingCollectionWidgetDashboardNote,
            grid: frontend.pendingCollectionWidgetListGrid,
          };
        }
        if (packageStatus === PackageStatus.ARCHIVED) {
          return {
            board: frontend.archivedCollectionWidgetTaskBoard,
            workload: frontend.archivedCollectionWidgetWorkload,
            changes: frontend.archivedCollectionWidgetChanges,
            metrics: frontend.archivedCollectionWidgetMetrics,
            dashboardNote: frontend.archivedCollectionWidgetDashboardNote,
            grid: frontend.archivedCollectionWidgetListGrid,
          };
        }

        return {
          board: frontend.scheduledCollectionWidgetTaskBoard,
          workload: frontend.scheduledCollectionWidgetWorkload,
          changes: frontend.scheduledCollectionWidgetChanges,
          metrics: frontend.scheduledCollectionWidgetMetrics,
          dashboardNote: frontend.scheduledCollectionWidgetDashboardNote,
          grid: frontend.scheduledCollectionWidgetListGrid,
        };
      };

      switch (widgetOnClickViewType) {
        case WidgetOnClickViewType.Board:
          return collectionUrl().board.url(params);
        case WidgetOnClickViewType.Workload:
          return collectionUrl().workload.url(params);
        case WidgetOnClickViewType.Changes:
          return collectionUrl().changes.url(params);
        case WidgetOnClickViewType.Metrics:
          return collectionUrl().metrics.url(params);
        case WidgetOnClickViewType.DashboardNote:
          return collectionUrl().dashboardNote.url(params);
        case WidgetOnClickViewType.Grid:
          return collectionUrl().grid.url(params);
      }
      break;
    }

    case LibraryLocation.Package: {
      const params = { ...sharedUrlParams, packageId: dashboard.itemId, dashboardId: dashboard.id };
      switch (widgetOnClickViewType) {
        case WidgetOnClickViewType.Board:
          return frontend.packageWidgetTaskBoard.url(params);
        case WidgetOnClickViewType.Workload:
          return frontend.packageWidgetWorkload.url(params);
        case WidgetOnClickViewType.Changes:
          return frontend.packageWidgetChanges.url(params);
        case WidgetOnClickViewType.Metrics:
          return frontend.packageWidgetMetrics.url(params);
        case WidgetOnClickViewType.DashboardNote:
          return frontend.packageWidgetDashboardNote.url(params);
        case WidgetOnClickViewType.Grid:
          return frontend.packageWidgetListGrid.url(params);
      }
      break;
    }
    case LibraryLocation.Project: {
      const params = { ...sharedUrlParams, itemId: dashboard.itemId, dashboardId: dashboard.id };
      switch (widgetOnClickViewType) {
        case WidgetOnClickViewType.Board:
          return frontend.projectWidgetTaskBoard.url(params);
        case WidgetOnClickViewType.Workload:
          return frontend.projectWidgetWorkload.url(params);
        case WidgetOnClickViewType.Changes:
          return frontend.projectWidgetChanges.url(params);
        case WidgetOnClickViewType.Metrics:
          return frontend.projectWidgetMetrics.url(params);
        case WidgetOnClickViewType.DashboardNote:
          return frontend.projectWidgetDashboardNote.url(params);
        case WidgetOnClickViewType.Grid:
          return frontend.projectWidgetListGrid.url(params);
      }
      break;
    }

    case LibraryLocation.Workspace:
    default: {
      const params = { ...sharedUrlParams, dashboardId: dashboard.id };
      switch (widgetOnClickViewType) {
        case WidgetOnClickViewType.Board:
          return frontend.workspaceWidgetTaskBoard.url(params);
        case WidgetOnClickViewType.Workload:
          return frontend.workspaceWidgetWorkload.url(params);
        case WidgetOnClickViewType.Changes:
          return frontend.workspaceWidgetChanges.url(params);
        case WidgetOnClickViewType.Metrics:
          return frontend.workspaceWidgetMetrics.url(params);
        case WidgetOnClickViewType.DashboardNote:
          return frontend.workspaceWidgetDashboardNote.url(params);
        case WidgetOnClickViewType.Grid:
          return frontend.workspaceWidgetListGrid.url(params);
      }
    }
  }
  return '';
};
