import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

export const getOrganizationsById = (state: RootState) => state.entities.organizations;

const getOrganizationForId = (state: RootState, id: number) => getOrganizationsById(state)[id];

export const getCurrentOrganization = (state: RootState) => {
  const currentOrgId = getCurrentOrganizationId(state);

  return getOrganizationForId(state, currentOrgId);
};

export const getCurrentOrganizationProjectTypes = (state: RootState) => {
  const currentOrgId = getCurrentOrganizationId(state);

  return Object.values(state.entities.organizationProjectTypes)
    .filter((organizationProjectType) => organizationProjectType.organizationId === String(currentOrgId))
    .map((organizationProjectType) => organizationProjectType.projectType);
};

export const getCurrentOrganizationHasDataCustomization = createSelector(
  getCurrentOrganization,
  (org) => !!org?.flags.hasDataCustomization
);

export const getCurrentOrganizationProjectCount = createSelector(
  getCurrentOrganization,
  (org) => org?.flags.projectCount ?? 0
);

export const getOrganizationHasFileUpload = createCachedSelector(
  (state: RootState, organizationId: number) => getOrganizationForId(state, organizationId),
  (organization) => !!organization?.flags.hasFileFeature
)(createCacheByIdConfig());

export const getOrganizationNameForId = createCachedSelector(
  getOrganizationForId,
  (organization) => organization?.name
)(createCacheByIdConfig());
