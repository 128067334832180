import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { EmailConfirmationError } from 'features/authentication/authenticated/email_confirmation_error';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { useFrontloadData } from 'features/authentication/hooks/use_frontload_data';
import { UnauthenticatedLayout } from 'features/authentication/unauthenticated/layout';
import { getCurrentUserDisconnected, getCurrentUserId } from 'features/common/current/selectors';
import useMarketingTags from 'features/common/marketing_tags/use_marketing_tags';
import { useTrackPageViewedOnSegment } from 'features/common/segment/use_report_page_viewed_to_segment';
import { frontend } from 'lib/urls';

import ForgotPassword from './forgot_password';
import { LoginPage } from './login';
import { SsoLoginCheckPage } from './login/sso_login_check';
import { Registration } from './registration';

import 'features/authentication/unauthenticated/index.scss';

type ReactSetStateAction = Dispatch<SetStateAction<number | undefined>>;

export const UnauthenticatedRoutes = ({
  setSelectedOrgId,
  setSelectedWsId,
  setEntityNotFound,
}: {
  setSelectedOrgId: ReactSetStateAction;
  setSelectedWsId: ReactSetStateAction;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}) => {
  const currentUserId = useSelector(getCurrentUserId);
  const disconnected = useSelector(getCurrentUserDisconnected);

  const frontloadData = useFrontloadData({ setSelectedOrgId, setSelectedWsId, setEntityNotFound });

  useMarketingTags();
  useTrackPageViewedOnSegment();

  return (
    <UnauthenticatedLayout>
      <Switch>
        <Route exact path={frontend.emailConfirmationExpired.pattern} render={() => <EmailConfirmationError />} />

        {currentUserId && !disconnected && <Redirect to={frontend.authenticated.url({})} />}

        <Route exact path={frontend.trial.pattern} render={() => <Registration frontloadData={frontloadData} />} />
        <Route exact path={frontend.login.pattern} render={() => <LoginPage frontloadData={frontloadData} />} />
        <Route exact path={frontend.loginSso.pattern} render={() => <SsoLoginCheckPage />} />
        <Route exact path={frontend.forgotPassword.pattern} render={() => <ForgotPassword />} />
      </Switch>
    </UnauthenticatedLayout>
  );
};
