import { PropertyChange } from 'daos/types';
import formatTimeUnitToAmount from 'features/common/changes/change_card_property/util/change_duration_formatter';

const ChangeCardWorkLimit = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const durationInSeconds = propertyChangeData.value;

  if (!durationInSeconds) {
    return <>None</>;
  }

  return <>{formatTimeUnitToAmount(durationInSeconds)}</>;
};

export default ChangeCardWorkLimit;
