import { all, select, takeEvery } from 'typed-redux-saga';

import { getCurrentOrganizationId, getCurrentUserId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { setCurrentOrganizationId, setCurrentUserId, setCurrentWorkspaceId } from 'features/common/current/slice';
import bugsnagClient from 'lib/bugsnag';

const traceSection = 'trace';

function* handleSetLoggingIn() {
  const currentUserId = yield* select(getCurrentUserId);
  if (currentUserId !== 0) {
    bugsnagClient?.setUser(currentUserId.toString());
  }
}

function* handleSetOrganizationId() {
  const organizationId = yield* select(getCurrentOrganizationId);
  bugsnagClient?.addMetadata(traceSection, 'organizationId', organizationId);
}

function* handleSetWorkspaceId() {
  const workspaceId = yield* select(getCurrentWorkspaceId);
  bugsnagClient?.addMetadata(traceSection, 'workspaceId', workspaceId);
}

export default function* rootSaga() {
  yield* all([
    takeEvery(setCurrentUserId, handleSetLoggingIn),
    takeEvery(setCurrentOrganizationId, handleSetOrganizationId),
    takeEvery(setCurrentWorkspaceId, handleSetWorkspaceId),
  ]);
}
