import { Types as AblyTypes } from 'ably';
import { createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentOrganizationId, getCurrentUserId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import bugsnagClient from 'lib/bugsnag';
import client from 'lib/websocket/client';

interface SocketContextProps {
  organizationWorkspaceChannel: AblyTypes.RealtimeChannelCallbacks | undefined;
  generalChannel: AblyTypes.RealtimeChannelCallbacks | undefined;
  organizationId: number | undefined;
  workspaceId: number | undefined;
}

export const SocketContext = createContext<SocketContextProps>({
  organizationWorkspaceChannel: undefined,
  organizationId: undefined,
  workspaceId: undefined,
  generalChannel: undefined,
});

interface SocketProviderProps {
  children?: React.ReactNode;
}

const SocketProvider = ({ children }: SocketProviderProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const userId = useSelector(getCurrentUserId);

  const [organizationWorkspaceChannel, setOrganizationWorkspaceChannel] = useState<
    AblyTypes.RealtimeChannelCallbacks | undefined
  >(undefined);
  const [generalChannel, setGeneralChannel] = useState<AblyTypes.RealtimeChannelCallbacks | undefined>(undefined);

  useEffect(() => {
    if (userId) {
      client?.connect();
    } else {
      client?.close();
    }
  }, [userId]);

  useEffect(() => {
    if (client) {
      setGeneralChannel(client.channels.get('general'));
    }

    return () => {
      if (generalChannel) {
        generalChannel.detach();
      }
    };
  }, [generalChannel]);

  useEffect(() => {
    if (client && organizationId && workspaceId) {
      setOrganizationWorkspaceChannel(client.channels.get(`organization:${organizationId}:workspace:${workspaceId}`));
    }

    return () => {
      if (organizationWorkspaceChannel) {
        organizationWorkspaceChannel.detach();
      }
    };
  }, [organizationWorkspaceChannel, organizationId, workspaceId]);

  useEffect(() => {
    if (client) {
      client.connection.on('failed', (connectionStateChange) => {
        // Ignore console log in order to log failed connection state to browser in addition to bugsnag
        // eslint-disable-next-line no-console
        console.log({ connectionStateChange });
        const message = JSON.stringify(connectionStateChange);

        const shouldSkipError = connectionStateChange?.reason?.statusCode === 403;

        if (!shouldSkipError) {
          bugsnagClient?.notify({ name: 'failedAblyConnection', message });
        }
      });
    }

    return () => {
      if (client) {
        client.connection.off();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={{ organizationWorkspaceChannel, generalChannel, organizationId, workspaceId }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
