import { noop } from 'lodash';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { SortColumn } from 'react-data-grid';

import { getItemSortOrderQuery, getPeopleSortOrderQuery } from './helpers';

interface ContextProps {
  sortColumn: SortColumn | undefined;
  setSortColumn: Dispatch<SetStateAction<SortColumn | undefined>>;
}

const SortableDataGridContext = createContext<ContextProps>({
  sortColumn: undefined,
  setSortColumn: noop,
});

export const SortableDataGridContextProvider = ({
  children,
  initialSortColumn,
}: {
  children: ReactNode;
  initialSortColumn?: SortColumn;
}) => {
  const [sortColumn, setSortColumn] = useState(initialSortColumn);

  return (
    <SortableDataGridContext.Provider value={{ sortColumn, setSortColumn }}>
      {children}
    </SortableDataGridContext.Provider>
  );
};

export const useSortableDataGridContext = () => useContext(SortableDataGridContext);

export const useItemDataGridSortOrder = (columnKeys?: ReadonlyArray<string>) => {
  const { sortColumn } = useSortableDataGridContext();

  if (columnKeys) {
    const sortOrderColumn = getConfiguredSortColumn(columnKeys, sortColumn);
    return getItemSortOrderQuery(sortOrderColumn);
  }

  return getItemSortOrderQuery(sortColumn);
};

const getConfiguredSortColumn = (columnKeys: ReadonlyArray<string>, sortColumn: SortColumn | undefined) => {
  return columnKeys.includes(sortColumn?.columnKey ?? '') ? sortColumn : undefined;
};

export const usePeopleDataGridSortOrder = (columnKeys: ReadonlyArray<string>) => {
  const { sortColumn } = useSortableDataGridContext();
  const sortOrderColumn = getConfiguredSortColumn(columnKeys, sortColumn);

  return getPeopleSortOrderQuery(sortOrderColumn);
};
