import { AnimationProps, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { ReadonlyRecord } from 'lib/readonly_record';

import { ItemIntakeSettableField, ItemIntakeSettableFieldDisplay } from './enums';

export const itemIntakeFieldDisplayByField: ReadonlyRecord<ItemIntakeSettableField, ItemIntakeSettableFieldDisplay> = {
  [ItemIntakeSettableField.Name]: ItemIntakeSettableFieldDisplay.Name,
  [ItemIntakeSettableField.Color]: ItemIntakeSettableFieldDisplay.Color,
  [ItemIntakeSettableField.Files]: ItemIntakeSettableFieldDisplay.Files,
  [ItemIntakeSettableField.ScheduleDirective]: ItemIntakeSettableFieldDisplay.ScheduleDirective,
  [ItemIntakeSettableField.WorkType]: ItemIntakeSettableFieldDisplay.WorkType,
  [ItemIntakeSettableField.ProjectStatus]: ItemIntakeSettableFieldDisplay.ProjectStatus,
  [ItemIntakeSettableField.TargetStart]: ItemIntakeSettableFieldDisplay.TargetStart,
  [ItemIntakeSettableField.TargetFinish]: ItemIntakeSettableFieldDisplay.TargetFinish,
  [ItemIntakeSettableField.TargetFinishType]: ItemIntakeSettableFieldDisplay.TargetFinishType,
  [ItemIntakeSettableField.Description]: ItemIntakeSettableFieldDisplay.Description,
  [ItemIntakeSettableField.RateSheetId]: ItemIntakeSettableFieldDisplay.RateSheetId,
  [ItemIntakeSettableField.CostCode]: ItemIntakeSettableFieldDisplay.CostCode,
  [ItemIntakeSettableField.TaskStatus]: ItemIntakeSettableFieldDisplay.TaskStatus,
  [ItemIntakeSettableField.WorkLimit]: ItemIntakeSettableFieldDisplay.WorkLimit,
  [ItemIntakeSettableField.CustomField]: ItemIntakeSettableFieldDisplay.CustomField,
};

export function WidgetMotionDiv({
  animate,
  children,
  className,
  initial,
}: {
  animate?: AnimationProps['animate'];
  children: ReactNode;
  className?: string;
  initial?: AnimationProps['initial'];
}) {
  return (
    <motion.div
      className={className}
      layout
      initial={
        initial ?? {
          opacity: 0,
          scale: 0.9,
        }
      }
      animate={
        animate ?? {
          opacity: 1,
          scale: 1,
        }
      }
    >
      {children}
    </motion.div>
  );
}
