import { request } from 'lib/api';

import { defineModel } from './shared';
import { backend } from './urls';

export const EMAIL_CONFIRMATION_NON_INTERACTIVE_DURATION = 1200;

const { createBody, resource } = defineModel({
  apiType: 'emailConfirmations',
  type: 'EMAIL_CONFIRMATION',
});

const { EMAIL_CONFIRMATION, resourceId } = resource;

const verifyConfirmation = ({ token }: { token: string }) =>
  request(backend.emailConfirmationsVerify.url({ token }), EMAIL_CONFIRMATION, createBody({}));

const reCreateConfirmation = () => request(backend.emailConfirmations.url({}), EMAIL_CONFIRMATION, createBody({}));

export const EmailConfirmationDao = {
  verifyConfirmation,
  reCreateConfirmation,
  id: resourceId,
} as const;
