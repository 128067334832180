import { useEffect, useRef } from 'react';

/**
 * @deprecated Using this hook tends to result in a UX anti-pattern.
 */
export const usePrevious = <T>(previous: T) => {
  const ref = useRef<T>(previous);
  useEffect(() => {
    ref.current = previous;
  });

  return ref.current;
};
