import { ReactNode, createContext } from 'react';
import { createPortal } from 'react-dom';

import 'features/organization/top_nav/top_nav.scss';

export const TopNavContext = createContext<{
  topNavRef: HTMLElement | null;
}>({
  topNavRef: null,
});

export const TopNav = ({ children }: { children: ReactNode }) => (
  <TopNavContext.Consumer>
    {({ topNavRef }) => (topNavRef ? createPortal(children, topNavRef) : null)}
  </TopNavContext.Consumer>
);
