import { noop } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { BaseConfirmModal } from 'containers/shared/base_confirm_modal';
import { DeleteModalProps } from 'features/dashboards_v2/common/confirm_delete_modal_types';
import { useDashboardContext } from 'features/dashboards_v2/context';

const ConfirmDeleteModalForDashboardSection = ({
  deleteModalProps,
  setDeleteModalProps,
  setShowDeleteModal,
}: {
  deleteModalProps: DeleteModalProps;
  setDeleteModalProps: Dispatch<SetStateAction<DeleteModalProps>>;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { deleteContent, deleteAction } = deleteModalProps;

  function closeDeleteModal() {
    setShowDeleteModal(false);
  }

  function cleanUpAfterDelete() {
    closeDeleteModal();
    setDeleteModalProps({ deleteContent: null, deleteAction: noop });
  }

  function deleteItem() {
    deleteAction(cleanUpAfterDelete);
  }

  return (
    <BaseConfirmModal
      confirmButtonText="Delete"
      content={<>{deleteContent}</>}
      onClose={closeDeleteModal}
      onConfirm={deleteItem}
    />
  );
};

export const ConfirmDeleteWidgetOrGroupModal = () => {
  const { deleteModalProps, setDeleteModalProps, setShowDeleteModal } = useDashboardContext();

  return (
    <ConfirmDeleteModalForDashboardSection
      deleteModalProps={deleteModalProps}
      setDeleteModalProps={setDeleteModalProps}
      setShowDeleteModal={setShowDeleteModal}
    />
  );
};
