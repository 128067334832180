export const daysUntilStartDisplayText = (daysUntilStart: number) => {
  if (!daysUntilStart || daysUntilStart === 0) {
    return 'Today...';
  }

  if (daysUntilStart === -1) {
    return '1 day ago...';
  } else if (daysUntilStart < -1) {
    return `${Math.abs(daysUntilStart)} days ago...`;
  }

  if (daysUntilStart === 1) {
    return '1 day from now...';
  }

  return `${daysUntilStart} days from now...`;
};
