import { ItemType, WidgetOnClickViewType } from 'daos/enums';

export function getOnClickViewOptionsForItemType(
  itemType: ItemType | undefined,
  currentOnClickView: WidgetOnClickViewType | null
) {
  const onClickViewOptions = [];

  if (currentOnClickView) {
    onClickViewOptions.push(currentOnClickView);
  }

  switch (itemType) {
    case ItemType.FOLDERS:
      return onClickViewOptions.concat([
        WidgetOnClickViewType.ItemPanelChanges,
        WidgetOnClickViewType.ItemPanelNotes,
        WidgetOnClickViewType.ItemPanelPlanning,
        WidgetOnClickViewType.ItemPanelProperties,
        WidgetOnClickViewType.ItemPanelSchedule,
        WidgetOnClickViewType.None,
        WidgetOnClickViewType.Schedule,
      ]);
    case ItemType.TASKS:
      return onClickViewOptions.concat([
        WidgetOnClickViewType.ItemPanelAssignments,
        WidgetOnClickViewType.ItemPanelChanges,
        WidgetOnClickViewType.ItemPanelNotes,
        WidgetOnClickViewType.ItemPanelProperties,
        WidgetOnClickViewType.ItemPanelSchedule,
        WidgetOnClickViewType.None,
      ]);
    case ItemType.PACKAGES:
    case ItemType.PROJECTS:
      return onClickViewOptions.concat([
        WidgetOnClickViewType.Board,
        WidgetOnClickViewType.Changes,
        WidgetOnClickViewType.Dashboard,
        WidgetOnClickViewType.Grid,
        WidgetOnClickViewType.ItemPanelNotes,
        WidgetOnClickViewType.ItemPanelChanges,
        WidgetOnClickViewType.ItemPanelPlanning,
        WidgetOnClickViewType.ItemPanelProperties,
        WidgetOnClickViewType.ItemPanelSchedule,
        WidgetOnClickViewType.Schedule,
        WidgetOnClickViewType.Workload,
        WidgetOnClickViewType.None,
      ]);
    default:
      return undefined;
  }
}

export const peopleListOnClickViewOptions = [
  WidgetOnClickViewType.None,
  WidgetOnClickViewType.Availability,
  WidgetOnClickViewType.Board,
  WidgetOnClickViewType.Profile,
  WidgetOnClickViewType.Timesheet,
  WidgetOnClickViewType.Workload,
];
