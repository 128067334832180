import { useState } from 'react';
import { useSelector } from 'react-redux';

import { WidgetConfigColumn } from 'daos/widget_column';
import { SelectionList } from 'features/common/selection_list/types';
import { mapWidgetConfigColumnsToSelectionList } from 'features/dashboards_v2/widget/common/columns';
import { getFieldsById } from 'redux/entities/selectors/custom_field';
import { getTaskStatusesById } from 'redux/entities/selectors/task_status';

export const useWidgetColumns = ({
  defaultColumns,
  columns,
}: {
  defaultColumns: ReadonlyArray<WidgetConfigColumn>;
  columns: ReadonlyArray<WidgetConfigColumn>;
}) => {
  const fieldsById = useSelector(getFieldsById);
  const taskStatus = useSelector(getTaskStatusesById);
  const initialColumns = mapWidgetConfigColumnsToSelectionList(defaultColumns, fieldsById, taskStatus);
  const mappedCurrentColumns = mapWidgetConfigColumnsToSelectionList(
    columns.length > 0 ? columns : defaultColumns,
    fieldsById,
    taskStatus
  );
  const [columnsSelected, setColumnsSelected] = useState<ReadonlyArray<SelectionList>>(mappedCurrentColumns);
  const [columnModalOpen, setColumnModalOpen] = useState(false);

  const openSelectColumnModal = () => setColumnModalOpen(true);

  return {
    openSelectColumnModal,
    columnModalOpen,
    setColumnModalOpen,
    initialColumns,
    columnsSelected,
    setColumnsSelected,
  };
};
