import { FieldInputProps, FieldMetaProps } from 'formik';
import { ReactElement } from 'react';
import { Form, StrictLabelProps } from 'semantic-ui-react';

import { formErrorLabel } from './form_field_helpers/form_error_label';

const LpFormCheckbox = ({
  fieldKey,
  label,
  disabled = false,
  getFieldMeta,
  getFieldProps,
  errorLabelDirection = 'above',
  className = '',
  e2eTestId,
}: {
  fieldKey: string;
  label?: string | ReactElement;
  errorLabelDirection?: StrictLabelProps['pointing'];
  disabled?: boolean;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  className?: string;
  e2eTestId?: string;
}) => {
  const fieldMeta = getFieldMeta(fieldKey);
  const fieldProps = getFieldProps(fieldKey);

  return (
    <Form.Checkbox
      className={className}
      id={fieldKey}
      name={fieldKey}
      disabled={disabled}
      error={formErrorLabel({ fieldMeta, errorLabelDirection })}
      label={label}
      onChange={fieldProps.onChange}
      onBlur={fieldProps.onBlur}
      checked={!!fieldProps.value}
      data-e2e-test-id={e2eTestId}
    />
  );
};

export default LpFormCheckbox;
