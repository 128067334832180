import { useCallback, useRef, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';

import { copyLight, LpIcon } from 'features/common/lp_icon';

import './copy_text_button.scss';

enum CopyStringText {
  Copy = 'Copy to clipboard',
  Copied = 'Copied!!',
  Failed = 'Could Not Copy.',
}

export const CopyTextButton = ({ className = '', textToCopy }: { className?: string; textToCopy: string }) => {
  const [copyLinkText, setCopyLinkText] = useState(CopyStringText.Copy);
  const [isOpen, setIsOpen] = useState(false);

  const openCopyLinkPopup = () => setIsOpen(true);

  const closeCopyLinkPopup = () => {
    if (copyLinkText === CopyStringText.Copy) {
      setIsOpen(false);
    }
  };

  const afterLinkClick = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const copyLink = useCallback(() => {
    if (afterLinkClick.current) {
      clearTimeout(afterLinkClick.current);
    }

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyLinkText(CopyStringText.Copied);
      })
      .catch(() => {
        setCopyLinkText(CopyStringText.Failed);
      });

    afterLinkClick.current = setTimeout(() => {
      setCopyLinkText(CopyStringText.Copy);
      setIsOpen(false);
    }, 1000);
  }, [textToCopy]);

  return (
    <span className={className} onMouseEnter={openCopyLinkPopup} onMouseLeave={closeCopyLinkPopup}>
      <Popup
        position="bottom center"
        open={isOpen}
        basic
        size="tiny"
        content={copyLinkText}
        trigger={
          <Button
            icon={<LpIcon icon={copyLight} />}
            className="copy-text-button"
            size="tiny"
            type="button"
            onClick={copyLink}
          />
        }
      />
    </span>
  );
};
