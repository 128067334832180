import { useSelector } from 'react-redux';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ListWidgetType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { useColumnsAndActiveFieldsByItemType } from 'features/common/item_column/use_item_column_selection_list';
import { getColumnSelectionList } from 'features/common/selection_list';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import {
  getActiveNonNoteCustomFieldsUsedOnUsers,
  getCustomFieldsByIdsSorted,
} from 'redux/entities/selectors/custom_field';
import { getPeopleReportWidgetColumns } from 'redux/entities/selectors/system_settings';
import { RootState } from 'redux/root_reducer';

export const useColumnsByListWidgetType = () => {
  const itemGridColumns = useColumnsAndActiveFieldsByItemType({ forPPPGrid: false });

  const systemColumnsRefactorEnabled = useHasFeature(FeatureFlag.systemColumnsRefactor);
  const allPeopleColumnSelectionList = systemColumnsRefactorEnabled
    ? usePeopleWidgetColumnSelectionListRefactor
    : usePeopleWidgetColumnSelectionList;
  return {
    [ListWidgetType.Picklists]: [],
    [ListWidgetType.Packages]: itemGridColumns.packages,
    [ListWidgetType.Projects]: itemGridColumns.projects,
    [ListWidgetType.Folders]: itemGridColumns.folders,
    [ListWidgetType.Tasks]: itemGridColumns.tasks,
    [ListWidgetType.Assignment]: itemGridColumns.assignments,
    [ListWidgetType.Users]: allPeopleColumnSelectionList(),
  };
};

export const usePeopleWidgetColumnSelectionListRefactor = () => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const allColumns = useSelector((state: RootState) => getPeopleReportWidgetColumns(state, workspaceId));
  const allCustomColumnsExceptCustomField = allColumns
    .map((column) => column as StandardColumns)
    .filter((column) => !Number(column) && column !== StandardColumns.CustomField);

  const idsOfCustomFields = allColumns.filter((column) => Number(column)).map(Number);

  const allCustomFieldsForUsers = useSelector((state) => getCustomFieldsByIdsSorted(state, idsOfCustomFields)).filter(
    (field): field is CustomField => field !== undefined
  );

  return getColumnSelectionList({
    columnOptions: allCustomColumnsExceptCustomField,
    customFields: allCustomFieldsForUsers,
  });
};

export const usePeopleWidgetColumnSelectionList = () => {
  const allCustomFieldsForUsers = useSelector(getActiveNonNoteCustomFieldsUsedOnUsers);
  const allCustomColumnsExceptCustomField = useSelector(getPeopleReportWidgetColumns)
    .map((column) => column as StandardColumns)
    .filter((column) => !Number(column) && column !== StandardColumns.CustomField);

  return getColumnSelectionList({
    columnOptions: allCustomColumnsExceptCustomField,
    customFields: allCustomFieldsForUsers,
  });
};
