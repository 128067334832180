import { useDispatch } from 'react-redux';

import { ItemType } from 'daos/enums';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import {
  setCustomItemFilterAtRisk,
  setCustomItemFilterCreatedByUserId,
  setCustomItemFilterCustomFieldProperties,
  setCustomItemFilterCustomProjectStatusFilter,
  setCustomItemFilterDropdownProjectStatusFilter,
  setCustomItemFilterHasFiles,
  setCustomItemFilterStatusSelection,
  setCustomItemFilterNameContains,
  setCustomItemFilterWorkLimitRisk,
} from 'features/common/custom_item_filter/slice';
import { CustomItemFilterLocation } from 'features/common/custom_item_filter/types';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';

export const useApplyProjectFilter = (location: CustomItemFilterLocation) => {
  const dispatch = useDispatch();
  const itemType = ItemType.PROJECTS;

  return ({
    createdByUserId,
    nameContainsValue,
    otherCustomFieldProperties,
    selectedAtRisk,
    selectedHasFiles,
    selectedWorkLimitRisk,
    selectedProjectStatusFilter,
    statusFilterSelection,
  }: {
    createdByUserId: number | undefined;
    nameContainsValue: string;
    otherCustomFieldProperties: ReadonlyArray<CustomFieldFilterWithRequiredId>;
    selectedAtRisk: boolean;
    selectedHasFiles: boolean;
    selectedWorkLimitRisk: boolean;
    selectedProjectStatusFilter: FolderFilterStatus | undefined;
    statusFilterSelection: string;
  }) => {
    const getDropdownProjectStatusFilter = () => {
      const isAllAtRisk =
        selectedAtRisk && selectedWorkLimitRisk && selectedProjectStatusFilter === FolderFilterStatus.All;
      const hasNoNameCreatedByOrCustomFields =
        !nameContainsValue && !createdByUserId && !otherCustomFieldProperties.length;
      const hasNoCustomFiltering =
        !selectedAtRisk &&
        !selectedWorkLimitRisk &&
        !nameContainsValue &&
        !createdByUserId &&
        !otherCustomFieldProperties.length;

      if (isAllAtRisk && hasNoNameCreatedByOrCustomFields) {
        return FolderFilterStatus.atRisk;
      }

      if (!hasNoCustomFiltering) {
        return FolderFilterStatus.Custom;
      }

      return selectedProjectStatusFilter ?? FolderFilterStatus.ActiveOnHold;
    };

    const getCustomProjectStatusFilter = () => {
      if (
        selectedProjectStatusFilter === FolderFilterStatus.Custom ||
        selectedProjectStatusFilter === FolderFilterStatus.atRisk
      ) {
        return FolderFilterStatus.All;
      }

      return selectedProjectStatusFilter ?? FolderFilterStatus.ActiveOnHold;
    };

    if (selectedProjectStatusFilter) {
      const dropdownProjectStatusFilter = getDropdownProjectStatusFilter();
      const customProjectStatusFilter = getCustomProjectStatusFilter();
      dispatch(setCustomItemFilterDropdownProjectStatusFilter({ location, dropdownProjectStatusFilter }));
      dispatch(setCustomItemFilterCustomProjectStatusFilter({ location, customProjectStatusFilter }));
    }

    dispatch(setCustomItemFilterStatusSelection({ location, itemType, statusFilterSelection }));
    dispatch(setCustomItemFilterAtRisk({ location, itemType, atRisk: selectedAtRisk }));
    dispatch(setCustomItemFilterWorkLimitRisk({ location, itemType, workLimitRisk: selectedWorkLimitRisk }));
    dispatch(setCustomItemFilterHasFiles({ location, itemType, hasFiles: selectedHasFiles }));
    dispatch(setCustomItemFilterNameContains({ location, itemType, nameContains: nameContainsValue || undefined }));
    dispatch(setCustomItemFilterCreatedByUserId({ location, itemType, createdByUserId }));
    dispatch(
      setCustomItemFilterCustomFieldProperties({
        location,
        itemType,
        customFieldProperties: otherCustomFieldProperties.length ? otherCustomFieldProperties : undefined,
      })
    );
  };
};
