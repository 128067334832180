import { itemIntakeFieldDisplayByField } from 'features/dashboards_v2/widget_click_through/intake/display_helpers';
import { ItemIntakeSettableField } from 'features/dashboards_v2/widget_click_through/intake/enums';

export function rowHeaderTitle({
  itemField,
  customFieldName,
  isRequired,
}: {
  itemField: ItemIntakeSettableField;
  customFieldName?: string;
  isRequired: boolean;
}) {
  if (itemField === ItemIntakeSettableField.CustomField) {
    return `${customFieldName}${isRequired ? '*' : ''}`;
  }

  return `${itemIntakeFieldDisplayByField[itemField]}${isRequired ? '*' : ''}`;
}
