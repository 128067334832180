import loadable, { lazy } from '@loadable/component';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import PageNotFound from 'features/common/errors/page_not_found';
import PortableLinkHandler from 'features/common/portable_link_handler';
import { PortableDashboardLinkHandler } from 'features/common/portable_link_handler/dashboard';
import { PortableReportLinkHandler } from 'features/common/portable_link_handler/report';
import { useItemPanelParams } from 'features/item_panel/use_item_panel_params';
import { useIterationModalParams } from 'features/iterations/modal/use_iteration_modal_params';
import { useStoryPointsSchemeModalParams } from 'features/story_points/story_points_scheme_modal/use_story_points_scheme_modal';
import { defaultGuestLandingPage, defaultLandingPage, frontend } from 'lib/urls';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

const LoadablePeople = loadable(() => import(/* webpackChunkName: "features_people" */ 'features/people'));
const LoadableGuestProfile = loadable(
  () =>
    import(/* webpackChunkName: "features_people_profiles_member_routes" */ 'features/people/profiles/member/routes')
);
const LoadableDashboardsEditor = loadable(
  () => import(/* webpackChunkName: "features_dashboards_v2" */ 'features/dashboards_v2/dashboard_editor')
);

const LoadableReportEditor = loadable(
  () => import(/* webpackChunkName: "features_dashboards_v2" */ 'features/reports/editor')
);

const LoadableWorkspaceAccess = loadable(
  () => import(/* webpackChunkName: "features_administration_access" */ 'features/administration/access')
);

const LoadableWorkspaceLibrary = loadable(
  () => import(/* webpackChunkName: "features_administration_access" */ 'features/workspace/library/routes')
);

const LoadableAdministration = loadable(
  () => import(/* webpackChunkName: "features_administration" */ 'features/administration')
);
const LoadableMyWork = loadable(() => import(/* webpackChunkName: "features_my_work" */ 'features/my_work'));

const LoadableMyDiscussions = loadable(
  () => import(/* webpackChunkName: "features_my_discussions" */ 'features/my_discussions')
);

const LoadableFavorites = loadable(
  () => import(/* webpackChunkName: "features_administration_favorites" */ 'features/administration/favorites')
);
const LoadableAcademy = loadable(() => import(/* webpackChunkName: "features_academy" */ 'features/academy'));

const LoadableSinglePackageRoutes = loadable(
  () => import(/* webpackChunkName: "features_ppp_package_routes" */ 'features/ppp/package/routes')
);
const LoadablePortfolioRoutes = loadable(
  () => import(/* webpackChunkName: "features_ppp_portfolio_routes" */ 'features/ppp/portfolio/routes')
);

const LoadableIterationsView = loadable(() => import(/* webpackChunkName: "iterations_page" */ 'features/iterations'));

const LoadableWorkspaceSettings = loadable(
  () => import(/* webpackChunkName: "features_administration_settings" */ 'features/administration/settings')
);
const LoadableAllProjects = loadable(
  () => import(/* webpackChunkName: "features_ppp_all_projects" */ 'features/ppp/all_projects')
);
const LoadableAllProjectsSearch = loadable(
  () => import(/* webpackChunkName: "features_all_projects_search" */ 'features/ppp/all_projects/search')
);
const LoadableProjectRoutes = loadable(
  () => import(/* webpackChunkName: "features_project_routes" */ 'features/ppp/project/routes')
);

const LazyItemPanel = lazy(() => import(/* webpackChunkName: "features_item_panel" */ 'features/item_panel'));

const LazyIterationModal = lazy(
  () => import(/* webpackChunkName: "features_iteration_modal" */ 'features/iterations/modal')
);

const LazyStoryPointsModal = lazy(
  () => import(/* webpackChunkName: "features_story_points_modal" */ 'features/story_points/story_points_scheme_modal')
);

const allowedGuestWorkspacePatterns = [
  frontend.academy.pattern,
  frontend.portable.pattern,
  frontend.portableDashboard.pattern,
  frontend.portableReport.pattern,
  frontend.portableLibraryItemDashboard.pattern,
  frontend.workspaceGuestProfile.pattern,
];

const nonGuestWorkspacePatterns = [
  frontend.capacityCollection.pattern,
  frontend.customize.pattern,
  frontend.customizeCostCodes.pattern,
  frontend.customizeDataFields.pattern,
  frontend.customizeDevelopment.pattern,
  frontend.customizeBillingSheetRules.pattern,
  frontend.customizePaySheetRules.pattern,
  frontend.customizePaySheetRulesProjectsUsing.pattern,
  frontend.customizeBillSheetRulesProjectsUsing.pattern,
  frontend.customizeRateSheets.pattern,
  frontend.customizeTaskStatus.pattern,
  frontend.customizeWorkspaceSettings.pattern,
  frontend.storyPointSchemes.pattern,
  frontend.favorites.pattern,
  frontend.group.pattern,
  frontend.myDiscussions.pattern,
  frontend.myWork.pattern,
  frontend.package.pattern,
  frontend.people.pattern,
  frontend.project.pattern,
  frontend.projects.pattern,
  frontend.projectsAll.pattern,
  frontend.projectsSearch.pattern,
  frontend.workspaceAccess.pattern,
  frontend.workspaceHub.pattern,

  frontend.workspaceLibrary.pattern,
  frontend.workspaceReport.pattern,
  frontend.workspaceDashboard.pattern,
  frontend.workspaceDashboardDesign.pattern,

  frontend.iterationsPage.pattern,
  frontend.scheduledCollection.pattern,
  frontend.scheduledCollectionDashboardDesign.pattern,
  frontend.pendingCollection.pattern,
  frontend.pendingCollectionDashboardDesign.pattern,
  frontend.archiveCollection.pattern,
  frontend.archiveCollectionDashboardDesign.pattern,
  frontend.templateCollection.pattern,

  frontend.packageLibrary.pattern,
  frontend.packageLibraryDashboard.pattern,
  frontend.packageDashboardDesign.pattern,
  frontend.packageLibraryReport.pattern,

  frontend.projectLibrary.pattern,
  frontend.projectLibraryDashboard.pattern,
  frontend.projectDashboardDesign.pattern,
  frontend.projectLibraryReport.pattern,
];

const AllowedGuestRoutes = () => {
  return (
    <Switch>
      <Route path={frontend.academy.pattern} component={LoadableAcademy} />

      <Route path={[frontend.portableReport.pattern]} component={PortableReportLinkHandler} />
      <Route
        path={[frontend.portableDashboard.pattern, frontend.portableLibraryItemDashboard.pattern]}
        component={PortableDashboardLinkHandler}
      />
      <Route path={frontend.portable.pattern} component={PortableLinkHandler} />
      <Route path={frontend.workspaceGuestProfile.pattern} component={LoadableGuestProfile} />
    </Switch>
  );
};

const NonGuestRoutes = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isGuestWsUser = useSelector(getIsGuestCurrentWorkspaceUser);

  if (isGuestWsUser) {
    return <Redirect to={frontend.dashboardPassports.url({})} />;
  }

  return (
    <Switch>
      <Route
        path={[
          frontend.workspaceDashboardDesign.pattern,
          frontend.scheduledCollectionDashboardDesign.pattern,
          frontend.pendingCollectionDashboardDesign.pattern,
          frontend.archiveCollectionDashboardDesign.pattern,
          frontend.packageDashboardDesign.pattern,
          frontend.projectDashboardDesign.pattern,
        ]}
        component={LoadableDashboardsEditor}
      />

      <Route
        path={[
          frontend.workspaceReportDesign.pattern,
          frontend.scheduledCollectionReportDesign.pattern,
          frontend.pendingCollectionReportDesign.pattern,
          frontend.archiveCollectionReportDesign.pattern,
          frontend.packageLibraryReportDesign.pattern,
          frontend.projectLibraryReportDesign.pattern,
        ]}
        component={LoadableReportEditor}
      />

      <Route
        path={[
          frontend.workspaceLibrary.pattern,
          frontend.workspaceReport.pattern,
          frontend.workspaceDashboard.pattern,
          frontend.workspaceWidget.pattern,
        ]}
        component={LoadableWorkspaceLibrary}
      />

      <Route path={frontend.projectsAll.pattern} component={LoadableAllProjects} />
      <Route path={frontend.projectsSearch.pattern} component={LoadableAllProjectsSearch} />

      <Redirect exact path={frontend.projects.pattern} to={frontend.projectsAll.url({ organizationId, workspaceId })} />

      <Route path={frontend.workspaceAccess.pattern} component={LoadableWorkspaceAccess} />

      <Route path={frontend.workspaceHub.pattern} component={LoadableAdministration} />

      <Route path={frontend.project.pattern} component={LoadableProjectRoutes} />

      <Route path={frontend.myWork.pattern} component={LoadableMyWork} />

      <Route path={frontend.myDiscussions.pattern} component={LoadableMyDiscussions} />

      <Route path={[frontend.group.pattern, frontend.people.pattern]} component={LoadablePeople} />

      <Route path={frontend.favorites.pattern} component={LoadableFavorites} />

      <Route
        path={[
          frontend.customize.pattern,
          frontend.customizeCostCodes.pattern,
          frontend.customizeDevelopment.pattern,
          frontend.customizeDataFields.pattern,
          frontend.customizeTaskStatus.pattern,
          frontend.customizeRateSheets.pattern,
          frontend.customizePaySheetRules.pattern,
          frontend.customizeBillingSheetRules.pattern,
          frontend.customizePaySheetRulesProjectsUsing.pattern,
          frontend.customizeBillSheetRulesProjectsUsing.pattern,
          frontend.customizeWorkspaceSettings.pattern,
          frontend.storyPointSchemes.pattern,
        ]}
        component={LoadableWorkspaceSettings}
      />

      <Route
        path={[
          frontend.scheduledCollection.pattern,
          frontend.pendingCollection.pattern,
          frontend.archiveCollection.pattern,
          frontend.templateCollection.pattern,
          frontend.capacityCollection.pattern,
        ]}
        component={LoadablePortfolioRoutes}
      />

      <Route path={frontend.iterationsPage.pattern} component={LoadableIterationsView} />

      <Route path={frontend.package.pattern} component={LoadableSinglePackageRoutes} />
    </Switch>
  );
};

export const Workspace = () => {
  const isGuestWsUser = useSelector(getIsGuestCurrentWorkspaceUser);
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { itemPanelIdParam, itemPanelId } = useItemPanelParams();
  const { iterationModalIdParam, iterationModalId } = useIterationModalParams();
  const { showStoryPointsSchemeModal } = useStoryPointsSchemeModalParams();

  const redirectUrl = isGuestWsUser
    ? defaultGuestLandingPage.url({})
    : defaultLandingPage.url({ organizationId, workspaceId });

  return (
    <>
      <Suspense fallback={<></>}>
        {!!itemPanelIdParam && !isGuestWsUser && <LazyItemPanel itemId={itemPanelId} />}
        {!!iterationModalIdParam && !isGuestWsUser && <LazyIterationModal iterationId={iterationModalId} />}
        {showStoryPointsSchemeModal && <LazyStoryPointsModal />}
      </Suspense>

      <Switch>
        <Route path={allowedGuestWorkspacePatterns} component={AllowedGuestRoutes} />
        <Route path={nonGuestWorkspacePatterns} component={NonGuestRoutes} />

        <Redirect exact path={frontend.workspace.pattern} to={redirectUrl} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </>
  );
};
