import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Statistic } from 'semantic-ui-react';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import LpLink from 'containers/shared/lp_link';
import ClickableText from 'features/common/clickable_text';
import { setCurrentOrganizationId, setCurrentUserId, setCurrentWorkspaceId } from 'features/common/current/slice';
import { LpIcon, medkitSolid } from 'features/common/lp_icon';
import { clearApiError } from 'features/errors/slice';
import { frontend } from 'lib/urls';
import { purgeEntities } from 'redux/entities/slice';
import 'features/authentication/authenticated/errors/top_level_error.scss';

export enum EntityTypeNotFound {
  Organization = 'Organization',
  Workspace = 'Workspace',
  Dashboard = 'Dashboard',
}

interface EntityNotFoundProps {
  entityType: EntityTypeNotFound;
  setEntityNotFound: (value: SetStateAction<EntityTypeNotFound | undefined>) => void;
  setFetchingCurrentUser: Dispatch<SetStateAction<boolean>>;
}

export const EntityNotFound = ({ entityType, setEntityNotFound, setFetchingCurrentUser }: EntityNotFoundProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const reconnectUser = () => {
    setEntityNotFound(undefined);
    dispatch(clearApiError());
    dispatch(setCurrentUserId(undefined));
    setFetchingCurrentUser(false);
    history.push('/');
  };

  useEffect(() => {
    if (entityType !== EntityTypeNotFound.Dashboard) {
      dispatch(setCurrentOrganizationId(undefined));
      dispatch(setCurrentWorkspaceId(undefined));
      dispatch(purgeEntities());
    }
  }, [dispatch, entityType]);

  return (
    <div className="top-level-error__wrapper">
      <div className="top-level-error__content">
        <LpIcon icon={medkitSolid} size={'10x'} className="top-level-error__icon" />
        <div className="top-level-error">
          <Statistic>
            <Statistic.Label className="top-level-error__label">
              You do not have access to this {entityType}
            </Statistic.Label>

            {entityType !== EntityTypeNotFound.Dashboard ? (
              <div>
                {'Click '}
                <ClickableText
                  className="custom-task-status-table__task-status-name--anchor"
                  onClick={reconnectUser}
                  text={'here'}
                />
                {` to return to your last ${entityType}`}
              </div>
            ) : (
              <div>
                {'Click '}
                <LpLink to={frontend.dashboardPassports.url({})}>here</LpLink>
                {` to return to your Dashboard Passports`}
              </div>
            )}
          </Statistic>
        </div>
      </div>

      <LpLegalTermsFooter />
    </div>
  );
};
