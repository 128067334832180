import { WidgetMotionDiv } from 'features/dashboards_v2/widget_click_through/intake/display_helpers';
import { isDisplayOnlyField } from 'features/dashboards_v2/widget_click_through/intake/form/helpers';
import {
  IntakeFormWidgetData,
  ItemIntakeFormSection,
  ItemIntakeFormSectionField,
} from 'features/dashboards_v2/widget_click_through/intake/types';

import { IntakeFormSectionHeader } from './header';
import { IntakeFormSectionFieldTables } from './table';

import './index.scss';

function getAllFields(sections: ReadonlyArray<ItemIntakeFormSection>) {
  return sections.reduce((acc: Array<ItemIntakeFormSectionField>, section: ItemIntakeFormSection) => {
    acc.push(...section.fields);
    return acc;
  }, []);
}

export const IntakeFormSection = ({
  intakeForm,
  organizationId,
  workspaceId,
}: {
  intakeForm: IntakeFormWidgetData;
  organizationId: string;
  workspaceId: string;
}) => {
  const firstFieldIdWithAutoFocus = getAllFields(intakeForm.sections).find(
    (field) => !isDisplayOnlyField(field.rowSetting)
  )?.id;

  const intakeUserChoices = intakeForm.userChoiceById;
  const isFileUploadsAllowed = intakeForm.allowsFileUpload;

  return (
    <WidgetMotionDiv className="intake-form__body-form-section intake-widget-user-form-section">
      {intakeForm.sections.map((section, index) => {
        return (
          <div key={`${section.name}${index}`} className="intake-widget-user-form-section__sub-section">
            <IntakeFormSectionHeader title={section.name} description={section.description} />
            <IntakeFormSectionFieldTables
              fields={section.fields}
              firstFieldIdWithAutoFocus={firstFieldIdWithAutoFocus}
              intakeUserChoices={intakeUserChoices}
              organizationId={organizationId}
              workspaceId={workspaceId}
              isFileUploadsAllowed={isFileUploadsAllowed}
            />
          </div>
        );
      })}
    </WidgetMotionDiv>
  );
};
