import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { WidgetDataErrorType } from 'daos/enums';
import { LocationFilterSettings } from 'daos/types';
import { filterSolid, LpIcon } from 'features/common/lp_icon';
import { getWidgetForId } from 'features/dashboards/selectors';
import { isDefaultFilter } from 'features/dashboards_v2/common/location_filters';
import { ItemFilterModal } from 'features/dashboards_v2/item_filter/item_filter_modal';
import { AssignedFilterErrorLabel } from 'features/dashboards_v2/widget_data_error_warning';
import { WidgetSettingsV2Field } from 'features/dashboards_v2/widget_settings/field';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

interface LocationFilterProps {
  widgetId: number;
  combinesWithDashboardFilter?: boolean;
  combinesWithWidgetGroupFilter?: boolean;
  dataIsAllActive?: boolean;
  disableProjectFilter?: boolean;
  includeUnassigned?: boolean;
  includeDisconnected?: boolean;
  showDateRangeFilter?: boolean;
}

export function LocationFilters({
  widgetId,
  combinesWithDashboardFilter = true,
  combinesWithWidgetGroupFilter = true,
  includeUnassigned = true,
  includeDisconnected = false,
  dataIsAllActive = false,
  disableProjectFilter = false,
  showDateRangeFilter = false,
}: LocationFilterProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { setFieldValue, values } = useFormikContext<LocationFilterSettings>();
  const [showModal, setShowModal] = useState(false);

  const widget = useSelector((state) => getWidgetForId(state, String(widgetId)));
  const widgetDataError = widget?.data?.errors.includes(WidgetDataErrorType.ASSIGNED_FILTER_ERROR);

  const onOpenModal = (evt: SyntheticEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    setShowModal(true);
  };

  const onCancelChanges = () => {
    setShowModal(false);
  };

  const onApplyChanges = (locationFilterSettings: Partial<LocationFilterSettings>) => {
    Object.entries(locationFilterSettings).forEach(([key, value]) => {
      setFieldValue(key, value);
    });
    setShowModal(false);
    setIsDirty(true);
  };

  return (
    <>
      <WidgetSettingsV2Field name="Data">
        <Button
          icon={<LpIcon icon={filterSolid} />}
          className={classNames('v2-widget-settings-button', {
            'v2-widget-settings-button--filtered': !isDefaultFilter(values, dataIsAllActive),
          })}
          onClick={onOpenModal}
          content={`Filter Settings${HORIZONTAL_ELLIPSIS}`}
        />

        {widgetDataError && !isDirty && <AssignedFilterErrorLabel />}
      </WidgetSettingsV2Field>
      {showModal && (
        <ItemFilterModal
          combinesWithDashboardFilter={combinesWithDashboardFilter}
          combinesWithWidgetGroupFilter={combinesWithWidgetGroupFilter}
          disableLocationFilter
          dataIsAllActive={dataIsAllActive}
          disableProjectFilter={disableProjectFilter}
          includeUnassigned={includeUnassigned}
          includeDisconnected={includeDisconnected}
          onApply={onApplyChanges}
          onCancel={onCancelChanges}
          title="Filter Settings"
          values={values}
          showDateRangeFilter={showDateRangeFilter}
        />
      )}
    </>
  );
}
