import { ReadonlyRecord } from 'lib/readonly_record';

export enum FolderStatus {
  ACTIVE = 'active',
  ON_HOLD = 'onHold',
  DONE = 'done',
}

export enum FolderStatusDisplay {
  ACTIVE = 'Active',
  ON_HOLD = 'On Hold',
  DONE = 'Done',
}

export const FolderStatusToFolderStatusDisplayMap: ReadonlyRecord<FolderStatus, FolderStatusDisplay> = {
  [FolderStatus.ACTIVE]: FolderStatusDisplay.ACTIVE,
  [FolderStatus.ON_HOLD]: FolderStatusDisplay.ON_HOLD,
  [FolderStatus.DONE]: FolderStatusDisplay.DONE,
};

export enum ScheduleTypeDisplay {
  Done = 'Done',
  Scheduled = 'Active',
  Unscheduled = 'On Hold',
}

export enum TargetFinishType {
  KeepScheduling = 'keepScheduling',
  StopScheduling = 'stopScheduling',
}

enum TargetFinishTypeDisplay {
  KeepScheduling = 'Keep Scheduling',
  StopScheduling = 'Stop Scheduling',
}

export const TargetFinishToTargetFinishDisplayMap: ReadonlyRecord<TargetFinishType, TargetFinishTypeDisplay> = {
  [TargetFinishType.KeepScheduling]: TargetFinishTypeDisplay.KeepScheduling,
  [TargetFinishType.StopScheduling]: TargetFinishTypeDisplay.StopScheduling,
};

export enum ItemColorName {
  RELIGHT_BLUE = 'reLIGHT Blue',
  TRICKY_TEAL = 'Tricky Teal',
  SONIC_GREEN = 'Sonic Green',
  GOLD_METAL = 'Gold Metal',
  TIGER_ORANGE = 'Tiger Orange',
  RISKY_RED = 'Risky Red',
  PINK_DRINK = 'Pink Drink',
  POWER_PURPLE = 'Power Purple',
  SMART_SLATE = 'Smart Slate',
  EARL_GRAY = 'Earl Gray',
  NIGHTSHADE = 'Nightshade',
}

export enum LpColorHex {
  RELIGHT_BLUE = '4088df',
  TRICKY_TEAL = '35d4cc',
  SONIC_GREEN = '3dba72',
  GOLD_METAL = 'd1b219',
  TIGER_ORANGE = 'f28b1d',
  RISKY_RED = 'db2828',
  PINK_DRINK = 'ff8c9f',
  POWER_PURPLE = '695ea6',
  SMART_SLATE = '8397ab',
  EARL_GRAY = 'bfbfbf',
  NIGHTSHADE = '595959',
}

export enum OrderItemFetch {
  GlobalPriority = 'globalPriority',
  AncestorPackageStatusThenGlobalPriority = 'ancestor.packageStatus,globalPriority',
}

export const ItemColorDisplay: Record<string, ItemColorName> = {
  [LpColorHex.RELIGHT_BLUE]: ItemColorName.RELIGHT_BLUE,
  [LpColorHex.TRICKY_TEAL]: ItemColorName.TRICKY_TEAL,
  [LpColorHex.SONIC_GREEN]: ItemColorName.SONIC_GREEN,
  [LpColorHex.EARL_GRAY]: ItemColorName.EARL_GRAY,
  [LpColorHex.GOLD_METAL]: ItemColorName.GOLD_METAL,
  [LpColorHex.NIGHTSHADE]: ItemColorName.NIGHTSHADE,
  [LpColorHex.PINK_DRINK]: ItemColorName.PINK_DRINK,
  [LpColorHex.POWER_PURPLE]: ItemColorName.POWER_PURPLE,
  [LpColorHex.RISKY_RED]: ItemColorName.RISKY_RED,
  [LpColorHex.SMART_SLATE]: ItemColorName.SMART_SLATE,
  [LpColorHex.TIGER_ORANGE]: ItemColorName.TIGER_ORANGE,
};

export enum RelativePriorityType {
  AFTER = 'after',
  BEFORE = 'before',
}

export enum ItemTypesDisplaySingular {
  Assignment = 'Assignment',
  Folder = 'Sub-Folder',
  Package = 'Package',
  Project = 'Project',
  Task = 'Task',
  WorkspaceRoot = 'Workspace Root',
}

export enum ItemTypesDisplayPlural {
  assignments = 'Assignments',
  folders = 'Folders',
  packages = 'Packages',
  projects = 'Projects',
  tasks = 'Tasks',
  workspaceRoots = 'Workspace Roots',
}
