import { Menu } from 'semantic-ui-react';

import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';

import './unsupported.scss';

export const UnsupportedClickThrough = () => {
  return (
    <div className="unsupported-guest-widget-click-through">
      <h3>Expanded Widget View: Not supported at this time</h3>
      <p>Go Back to Dashboard:</p>
      <Menu borderless secondary>
        <Menu.Menu>
          <Menu.Item content={<GoBackToDashboardButton />} />
        </Menu.Menu>
      </Menu>
    </div>
  );
};
