import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, Label } from 'semantic-ui-react';

import { getASAPLabelColor } from 'containers/shared/notice_icons/asap_label';
import { IterationLabelRenderer } from 'containers/shared/notice_icons/iteration_label';
import TaskIconMaker from 'containers/shared/task_icon_maker';
import {
  ScheduleDirective,
  ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon,
  ItemType,
  PackageStatus,
  WorkType,
  WorkTypeToWorkTypeDisplay,
} from 'daos/enums';
import {
  FolderStatus,
  FolderStatusToFolderStatusDisplayMap,
  ItemColorDisplay,
  TargetFinishToTargetFinishDisplayMap,
  TargetFinishType,
} from 'daos/item_enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import { FormatterAddEditGridProps } from 'features/common/data_grid/types';
import { getSelectedIterationDropdownText } from 'features/common/inputs/dropdowns/schedule_directive_dropdown/options';
import { ItemAncestryBreadcrumb } from 'features/common/item_ancestry_breadcrumb';
import { clockSolid, LpIcon, squareSolid } from 'features/common/lp_icon';
import { useHasFeature } from 'hooks/use_has_feature';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { CIRCULAR_BULLET_POINT } from 'lib/constants';
import { FeatureFlag } from 'lib/feature_flags';
import { dateFormatToDisplay } from 'lib/localization';
import { DateFormat } from 'lib/localization_enums';
import { getCostCodeForId } from 'redux/entities/selectors/cost_code';
import { getItemForId } from 'redux/entities/selectors/item';
import { getIterationForId, getIterationsForCurrentWorkspace } from 'redux/entities/selectors/iterations';
import { getStoryPointForId } from 'redux/entities/selectors/story_points';
import { getTaskStatusForId } from 'redux/entities/selectors/task_status';
import { getHasLoggedTimeForAssignmentId } from 'redux/entities/selectors/timesheet_entry';
import { getOrganizationUserForId, getCurrentOrganizationUser } from 'redux/entities/selectors/user';
import { slate700 } from 'style/variables';

export const AssignmentFormatter = ({ row }: FormatterAddEditGridProps) => {
  const name = row.name;
  const assignment = useSelector((state) => getItemForId(state, row.id));
  const task = useSelector((state) => (assignment?.parent ? getItemForId(state, assignment.parent.id) : undefined));
  const isDone = !!assignment?.doneDate;

  return (
    <span className="lp-add-edit-data-grid__assignment-name">
      <span>{task?.name ?? 'Task'}</span> <span className="middle-dot">{CIRCULAR_BULLET_POINT}</span>
      <span
        className={classNames({
          'assignment-name': !isDone,
          'done-assignment-name': isDone,
        })}
      >
        {name ? name : 'Assignment'}
      </span>{' '}
      <ItemAncestryBreadcrumb
        buildAncestryUpToItemType={ItemType.WORKSPACE_ROOTS}
        itemId={row.id}
        includeLink={false}
      />
    </span>
  );
};

export const CheckboxFormatter = ({ column, row, onRowChange }: FormatterAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey.Done | AddEditGridColumnKey.TrackingOnly;
  const checked = !!row[columnKey];

  const handleChange = (_: SyntheticEvent, { checked }: CheckboxProps) => {
    onRowChange({ ...row, [columnKey]: !!checked });
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};

export const ColorColumnFormatter = ({ row }: FormatterAddEditGridProps) => {
  const colorName = ItemColorDisplay[row.color as string];
  return (
    <span>
      <LpIcon color={`#${row.color}`} icon={squareSolid} /> {colorName}
    </span>
  );
};

export const DateFormatter = ({ row, column }: FormatterAddEditGridProps) => {
  const currentOrgUserDateFormat = useSelector(getCurrentOrganizationUser)?.dateFormat || DateFormat.DayMonthYear;
  const { formatLocalDate } = useLocalizedFormats();
  const columnKey = column.key as
    | AddEditGridColumnKey.TargetStart
    | AddEditGridColumnKey.TargetFinish
    | AddEditGridColumnKey.DoneDate;
  const date = row[columnKey];

  return <span>{date ? formatLocalDate(date) : dateFormatToDisplay[currentOrgUserDateFormat]}</span>;
};

export const CostCodeColumnFormatter = ({ row }: FormatterAddEditGridProps) => {
  const costCode = useSelector((state) => (row.costCodeId ? getCostCodeForId(state, row.costCodeId) : undefined));

  const costCodeName = costCode?.name ?? '';
  return <span>{costCodeName}</span>;
};

export const FolderStatusFormatter = ({ row }: FormatterAddEditGridProps) => {
  const folderStatus = row.folderStatus as FolderStatus;

  return <span>{FolderStatusToFolderStatusDisplayMap[folderStatus] ?? ''}</span>;
};

export const FirstAssigneeFormatter = ({ column, row }: FormatterAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey.OrganizationUserId;
  const orgUserId = row[columnKey] ?? 0;
  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));

  if (!orgUser) {
    return null;
  }

  return (
    <>
      <UserAvatar orgUserId={orgUser.id} size={AvatarSize.Small_1_5} /> {orgUser.username}
    </>
  );
};

export const NumberTextFormatter = ({ column, row }: FormatterAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey;
  const rowValue = row[columnKey] || '0';

  return <>{rowValue}</>;
};

export const OrganizationUserColumnFormatter = ({ row }: FormatterAddEditGridProps) => {
  const hasLoggedTime = useSelector((state) => getHasLoggedTimeForAssignmentId(state, row.id));
  const orgUser = useSelector((state) =>
    row.organizationUserId ? getOrganizationUserForId(state, row.organizationUserId) : undefined
  );

  const username = orgUser?.username ?? '';

  return (
    <span>
      {username} {hasLoggedTime && <LpIcon icon={clockSolid} color={slate700} />}
    </span>
  );
};

export const PackageStatusFormatter = ({ row }: FormatterAddEditGridProps) => {
  const packageStatus = row.packageStatus as PackageStatus;

  return <span>{packageStatusDisplayNameByPackageStatus[packageStatus] ?? ''}</span>;
};

const IterationPriorityFormatter = ({ row }: FormatterAddEditGridProps) => {
  const scheduleDirective = row.scheduleDirective ?? ScheduleDirective.NORMAL;
  const iterationId = row.iterationId ?? null;
  const iterations = useSelector(getIterationsForCurrentWorkspace);
  const rowIteration = useSelector((state) => getIterationForId(state, iterationId ?? 0));

  const displayText = getSelectedIterationDropdownText({
    iterations,
    selectedIterationId: iterationId,
    scheduleDirective,
  });

  if (!rowIteration) {
    return (
      <span>
        <Label color={getASAPLabelColor(scheduleDirective)} horizontal>
          ASAP
        </Label>{' '}
        {displayText}
      </span>
    );
  }

  return (
    <span>
      <IterationLabelRenderer iteration={rowIteration} /> {rowIteration.name}
    </span>
  );
};

export const PriorityRushFormatter = (props: FormatterAddEditGridProps) => {
  const hasIterationFeature = useHasFeature(FeatureFlag.iterationBucket);

  if (hasIterationFeature) {
    return <IterationPriorityFormatter {...props} />;
  }

  const { row } = props;
  const scheduleDirective = row.scheduleDirective ?? ScheduleDirective.NORMAL;
  const displayText = ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[scheduleDirective];

  return (
    <span>
      <Label color={getASAPLabelColor(scheduleDirective)} horizontal>
        ASAP
      </Label>{' '}
      {displayText}
    </span>
  );
};

export const StoryPointFormatter = ({ row }: FormatterAddEditGridProps) => {
  const storyPointId = row[AddEditGridColumnKey.StoryPointsId];
  const storyPoint = useSelector((state) => getStoryPointForId(state, storyPointId ?? 0));

  if (!storyPoint) {
    return null;
  }

  return <span>{storyPoint.value}</span>;
};

export const TargetFinishTypeFormatter = ({ row }: FormatterAddEditGridProps) => {
  const targetFinishType = row.targetFinishType ?? TargetFinishType.KeepScheduling;

  return <span>{TargetFinishToTargetFinishDisplayMap[targetFinishType]}</span>;
};

export const TaskStatusFormatter = ({ column, row }: FormatterAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey.TaskStatusId;
  const taskStatusId = row[columnKey] ?? 0;
  const taskStatus = useSelector((state) => getTaskStatusForId(state, taskStatusId));

  if (!taskStatus) {
    return null;
  }

  return (
    <span>
      <TaskIconMaker taskStatusId={taskStatus.id} /> {taskStatus.name}
    </span>
  );
};

export const WorkTypeFormatter = ({ column, row }: FormatterAddEditGridProps) => {
  const columnKey = column.key as AddEditGridColumnKey.WorkType;
  const workType = row[columnKey] ?? WorkType.IN_ORDER;
  return <>{WorkTypeToWorkTypeDisplay[workType]}</>;
};
