export function moveElementFromSourceToDestinationArray<T>({
  indexToMove,
  source,
  destination,
}: {
  indexToMove: number;
  source: Array<T>;
  destination: Array<T>;
}): {
  modifiedSource: Array<T>;
  modifiedDestination: Array<T>;
} {
  const sourceItem = source[indexToMove];
  if (sourceItem) {
    destination.push(sourceItem);
    source.splice(indexToMove, 1);
  }
  return {
    modifiedSource: source,
    modifiedDestination: destination,
  };
}
