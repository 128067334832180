import React, { useCallback } from 'react';
import { Checkbox, CheckboxProps, List, Radio } from 'semantic-ui-react';

import ItemIcon from 'containers/shared/item_icon';
import { Item } from 'daos/model_types';
import { boxHeartSolid, LpIcon } from 'features/common/lp_icon';

export const TemplatePickerPackageList = ({
  packages,
  projectsByPackage,
  selected,
  updateSelected,
}: {
  packages: ReadonlyArray<Item>;
  projectsByPackage: Record<string, ReadonlyArray<Item>>;
  selected: Set<number> | number | null;
  updateSelected: (id: number) => void;
}) => {
  const updateSelectedProjects = (_: React.SyntheticEvent, { value }: CheckboxProps): void => {
    const numberId = Number(value);
    if (!isNaN(numberId)) {
      updateSelected(numberId);
    }
  };

  const Component = selected instanceof Set ? Checkbox : Radio;

  const isChecked = useCallback(
    (id: number) => (selected instanceof Set ? selected.has(id) : id === selected),
    [selected]
  );

  return (
    <List className="lp-project-template-picker__list">
      {packages.map((currentPackage) => (
        <List.Item key={currentPackage.id}>
          <div className="lp-project-template-picker__package">
            <LpIcon size="lg" icon={boxHeartSolid} /> {currentPackage.name}
          </div>
          <List.List>
            {projectsByPackage[currentPackage.id]?.map((currentProject) => (
              <List.Item key={currentProject.id} className="lp-project-template-picker__project">
                <Component
                  className="lp-widget-project-template-picker__project-picker"
                  onChange={updateSelectedProjects}
                  value={currentProject.id}
                  checked={isChecked(currentProject.id)}
                />
                <ItemIcon itemId={currentProject.id} className="lp-project-template-picker__project-icon" />
                <span>{currentProject.name}</span>
              </List.Item>
            ))}
          </List.List>
        </List.Item>
      ))}
    </List>
  );
};
