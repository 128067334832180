import { ReactNode, useEffect } from 'react';

const useMobileDeviceViewportWidth = () => {
  useEffect(() => {
    const viewportElement = document.getElementById('viewport');

    viewportElement?.setAttribute('content', 'width=device-width, initial-scale=1.0');

    return () => {
      viewportElement?.setAttribute('content', 'width=1024, initial-scale=1.0');
    };
  }, []);
};

export const UnauthenticatedLayout = ({ children }: { children: ReactNode }) => {
  useMobileDeviceViewportWidth();

  return (
    <div className="lp-unauthenticated">
      <div className="lp-unauthenticated__container">
        <div className="lp-unauthenticated__content">{children}</div>
      </div>
    </div>
  );
};
