import { chunk } from 'lodash';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { WidgetWithData } from 'daos/model_types';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { useGetItemScopeForWidget } from 'features/dashboards_v2/widget/use_get_item_for_scope';
import { Insight } from 'features/dashboards_v2/widget/widgets/insights/insight';
import { Navigation } from 'features/dashboards_v2/widget/widgets/insights/navigation';
import { ProgressBars } from 'features/dashboards_v2/widget/widgets/insights/progress_bars';
import { InsightData, InsightsWidgetData } from 'features/dashboards_v2/widget/widgets/insights/types';
import { useAllInsightsInOrder } from 'features/dashboards_v2/widget/widgets/insights/use_all_insights_in_order';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

import './index.scss';

function makeInsightBlocks(insights: Array<InsightData>) {
  const output: Array<ReactNode> = [];
  const insightsAsPairs = chunk(insights, 2);

  for (let i = 0; i < insightsAsPairs.length; i++) {
    const pair = insightsAsPairs[i] ?? [null, null];
    const firstInPair = pair[0];
    const secondInPair = pair[1];

    if (firstInPair && secondInPair) {
      output.push(
        <div className="insights-widget__insight-block" key={`${firstInPair.displayName}-${secondInPair.displayName}`}>
          <Insight
            displayName={firstInPair.displayName}
            icon={firstInPair.icon}
            value={firstInPair.value}
            disabled={firstInPair.disabled}
            hasRisk={firstInPair.hasRisk}
          />
          <Insight
            displayName={secondInPair.displayName}
            icon={secondInPair.icon}
            value={secondInPair.value}
            disabled={secondInPair.disabled}
            hasRisk={secondInPair.hasRisk}
          />
        </div>
      );
    }
  }
  return output;
}

export const InsightsWidget = ({ widget }: { widget: WidgetWithData }) => {
  const widgetData = widget.data as InsightsWidgetData;
  const isGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  const item = useGetItemScopeForWidget(widget);
  const { allInsightsInOrder } = useAllInsightsInOrder(widgetData);
  const insightBlocks = makeInsightBlocks(allInsightsInOrder);

  const canAccessInsightsNavigation = !isGuest && item;

  return (
    <RenderedWidget
      body={
        <div className="insights-widget">
          {insightBlocks}
          <ProgressBars
            percentTaskComplete={widgetData.percentTasksComplete ?? 0}
            percentComplete={widgetData.percentComplete ?? 0}
          />
          {canAccessInsightsNavigation && <Navigation itemId={item.id} />}
        </div>
      }
    />
  );
};
