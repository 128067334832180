export enum DateFormat {
  MonthDayYear = 'mdy',
  DayMonthYear = 'dmy',
  YearMonthDay = 'ymd',
}

export enum FirstDayOfWeek {
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
}

export enum NumberFormat {
  CommaThousandsPeriodDecimal = 'commaThousandsPeriodDecimal',
  PeriodThousandsCommaDecimal = 'periodThousandsCommaDecimal',
  SpaceThousandsCommaDecimal = 'spaceThousandsCommaDecimal',
}

export enum TimeFormat {
  TwelveHour = 'twelveHour',
  TwentyFourHour = 'twentyFourHour',
}

export enum WeekNumberFormat {
  ISO = 'iso',
  US = 'us',
}
