import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { Label } from 'semantic-ui-react';

import { LpColorHex } from 'daos/item_enums';
import { Iteration } from 'daos/model_types';
import { calendarArrowUpSolid, LpIcon } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';

import 'containers/shared/notice_icons/icons.scss';

interface IterationLabelProps {
  iteration: Iteration;
  onClick?: (event: SyntheticEvent) => void;
}

const IterationIcon = ({ iteration }: IterationLabelProps) => {
  return <LpIcon icon={calendarArrowUpSolid} color={`#${iteration.color ?? LpColorHex.NIGHTSHADE}`} />;
};

export const IterationNoticeLabelRenderer = ({
  iteration,
  onClick,
  isItemPanel,
}: IterationLabelProps & { isItemPanel?: boolean }) => (
  <Label
    className={classNames(
      'icon notice alert iteration-label iteration-label__notice',
      isItemPanel && 'iteration-label__notice-item-panel'
    )}
    onClick={onClick}
    content={
      <LpPopUp
        positionStrategy="fixed"
        placement="top"
        trigger={
          <span>
            <IterationIcon iteration={iteration} />
          </span>
        }
        content={<span>{iteration.name}</span>}
      />
    }
  />
);

export const IterationLabelRenderer = ({ iteration }: IterationLabelProps) => (
  <Label basic className="iteration-label" content={<IterationIcon iteration={iteration} />} />
);
