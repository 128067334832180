import { ReactNode } from 'react';

interface WidgetSettingsFieldProps {
  name: string;
  children: ReactNode | Array<ReactNode>;
}
export function WidgetSettingsField({ name, children }: WidgetSettingsFieldProps) {
  return (
    <>
      <label className="base-widget-settings__content-options-label">{name}</label>
      <div className="base-widget-settings__content-options-field">{children}</div>
    </>
  );
}
