import classNames from 'classnames';
import { noop } from 'lodash';

import { getCheckboxColorAndIcon } from 'containers/shared/custom_column/helpers';
import { FieldValueColumn } from 'containers/shared/custom_column/types';
import { CustomFieldType } from 'daos/enums';
import { LpIcon, squareSolid } from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import NewWindowLink from 'lib/display_helpers/url_links';
import { NumberFormat } from 'lib/localization_enums';
import { gray300 } from 'style/variables';

const inheritedClassName = 'custom-field-value-cell--inherited';

export const CheckBoxFieldValue = ({ checked, isInherited = false }: { checked: boolean; isInherited?: boolean }) => {
  const { color, icon } = getCheckboxColorAndIcon(checked);
  return (
    <LpIcon
      className={classNames({ [inheritedClassName]: isInherited, lpicon: true })}
      color={color}
      icon={icon}
      size="lg"
    />
  );
};

const FieldValueSpan = ({
  content,
  hasMultipleValues,
  isInherited,
  onClick = noop,
}: {
  content: string | number | JSX.Element | null | undefined;
  hasMultipleValues: boolean;
  isInherited: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}) => {
  const multiFieldSeparator = hasMultipleValues ? ', ' : '';

  return (
    <span className={classNames({ [inheritedClassName]: isInherited })} onClick={onClick}>
      {multiFieldSeparator}
      {content}
    </span>
  );
};

const getContentForFieldType = ({
  fieldType,
  formatCurrency,
  formatLocalDate,
  value,
}: {
  fieldType: CustomFieldType;
  formatCurrency: (value: string | number, currencyUnit: string, format: NumberFormat) => string;
  formatLocalDate: (date: string) => string;
  value: FieldValueColumn;
}) => {
  switch (fieldType) {
    case CustomFieldType.CHECKBOX: {
      const { color, icon } = getCheckboxColorAndIcon(!!value.checked);
      return <LpIcon className="lpicon" color={color} icon={icon} size="lg" />;
    }
    case CustomFieldType.CURRENCY: {
      const { currency, currencyUnit, numberFormat } = value;
      if (currency && currencyUnit && numberFormat) {
        return formatCurrency(currency, currencyUnit, numberFormat);
      }
      return null;
    }
    case CustomFieldType.DATE:
      return value.date ? formatLocalDate(value.date) : null;
    case CustomFieldType.MULTILINE_TEXT:
      return value.text ?? '';

    case CustomFieldType.ITEM:
    case CustomFieldType.MULTI_ITEM:
      return value.itemName ? value.itemName : null;
    case CustomFieldType.LINK:
    case CustomFieldType.MULTI_LINK:
      return value.url ? <NewWindowLink to={value.url}>{value.text ?? value.url}</NewWindowLink> : null;
    case CustomFieldType.NUMERIC:
      return value.number ?? '';
    case CustomFieldType.PICKLIST:
    case CustomFieldType.MULTI_PICKLIST: {
      const { picklistChoiceColor, picklistChoiceName } = value;
      return (
        <span className="nowrap">
          <LpIcon icon={squareSolid} size="1x" color={picklistChoiceColor ? `#${picklistChoiceColor}` : gray300} />
          &nbsp;
          {picklistChoiceName ?? ''}
        </span>
      );
    }
    case CustomFieldType.TEXT:
    case CustomFieldType.MULTI_TEXT:
      return value.text;
    case CustomFieldType.USER:
    case CustomFieldType.MULTI_USER:
      return value.username ? value.username : null;
    default:
      return null;
  }
};

export const FieldValueRenderer = ({
  fieldType,
  value,
  hasMultipleValues,
}: {
  fieldType: CustomFieldType;
  value: FieldValueColumn;
  hasMultipleValues: boolean;
}) => {
  const { formatLocalDate, formatCurrency } = useLocalizedFormats();

  const handleStopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (fieldType === CustomFieldType.LINK || fieldType === CustomFieldType.MULTI_LINK) {
      e.stopPropagation();
    }
  };

  const isInherited = !!value.isInherited;
  const content = getContentForFieldType({ fieldType, value, formatCurrency, formatLocalDate });

  return (
    <FieldValueSpan
      content={content}
      isInherited={isInherited}
      onClick={handleStopPropagation}
      hasMultipleValues={hasMultipleValues}
    />
  );
};
