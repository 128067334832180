import { motion } from 'framer-motion';

import { LpIcon, magnifyingGlassLocationLight } from 'features/common/lp_icon';
import { SearchLocation } from 'features/common/search/enums';
import { orange500 } from 'style/variables';

interface HelpfulInfoProps {
  showHelpInfo: boolean;
  username?: string;
  searchLocation: SearchLocation;
}
export function HelpfulInfo({ showHelpInfo, searchLocation, username }: HelpfulInfoProps) {
  if (!showHelpInfo) {
    return null;
  }

  const headerText = searchLocation === SearchLocation.Members ? 'Members-based Search' : 'Location-based Search';
  const bodyText =
    searchLocation === SearchLocation.Members
      ? `If it's assigned to ${username ? username : 'member'}, we will find it wherever it's hiding.`
      : 'Work likes to hide, it happens to us too. Tell us what you are seeking in this location.';

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="lp-search__helpful-info"
    >
      <LpIcon
        className="lp-search__helpful-info-icon"
        size="4x"
        icon={magnifyingGlassLocationLight}
        color={orange500}
      />
      <h3 className="lp-search__helpful-info-header">{headerText}</h3>
      <div className="lp-search__helpful-info-text">{bodyText}</div>
    </motion.div>
  );
}
