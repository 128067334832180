import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Popup, Table } from 'semantic-ui-react';

import { UserType } from 'daos/enums';
import { getCurrentUserId } from 'features/common/current/selectors';
import { getUserForId } from 'redux/entities/selectors/user';
interface UserNameRowProps {
  userId: number;
  isWsEvicted: boolean;
}

const UserEmailRow = ({ isWsEvicted, userId }: UserNameRowProps) => {
  const currentUserId = useSelector(getCurrentUserId);
  const user = useSelector((state) => getUserForId(state, userId));

  if (!user) {
    return null;
  }

  const isCurrentUser = currentUserId === userId;

  if (user.userType !== UserType.Member) {
    return null;
  }

  const userEmail = <span>{user.email}</span>;
  const userEmailWithConditionalPopup = isCurrentUser ? (
    <Popup
      trigger={userEmail}
      content="Click on the 'Change Email' button on the right-hand side of this page to change your email"
    />
  ) : (
    userEmail
  );

  return (
    <Table.Row>
      <Table.Cell content={'Email'} />
      <Table.Cell
        className={classNames('user-profile__table-cell-input', {
          'user-profile__about-table-row-email': !isWsEvicted,
          'user-profile__about-table--evicted': isWsEvicted && isCurrentUser,
        })}
        content={
          isWsEvicted ? <span className="evicted-user">Needs to be reconnected</span> : userEmailWithConditionalPopup
        }
        disabled={!isCurrentUser}
      />
    </Table.Row>
  );
};

export default UserEmailRow;
