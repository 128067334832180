import { createSelector } from 'reselect';

import { ItemType, PackageStatus, UserWorkspaceSettingFieldName } from 'daos/enums';
import { Item } from 'daos/model_types';
import { DataGridViewType } from 'features/common/data_grid/enums';
import { SelectionList } from 'features/common/selection_list/types';
import { getItemsById } from 'redux/entities/selectors/item';
import { getCurrentWorkspaceUserSettings } from 'redux/entities/selectors/user_workspace_settings';
import { RootState } from 'redux/root_reducer';

export const getGridColumnSettingFieldNameForPackageStatusAndGridItemType = (
  packageStatus: PackageStatus | null | undefined,
  dataGridItemType: ItemType | undefined
) => {
  if (!packageStatus || packageStatus === PackageStatus.TEMPLATE || !dataGridItemType) {
    return undefined;
  }
  switch (packageStatus) {
    case PackageStatus.SCHEDULED:
      switch (dataGridItemType) {
        case ItemType.PROJECTS:
          return UserWorkspaceSettingFieldName.ScheduledGridProjectColumns;
        case ItemType.TASKS:
          return UserWorkspaceSettingFieldName.ScheduledGridTaskColumns;
        case ItemType.ASSIGNMENTS:
          return UserWorkspaceSettingFieldName.ScheduledGridAssignmentColumns;
      }
      break;

    case PackageStatus.BACKLOG:
      switch (dataGridItemType) {
        case ItemType.PROJECTS:
          return UserWorkspaceSettingFieldName.PendingGridProjectColumns;
        case ItemType.TASKS:
          return UserWorkspaceSettingFieldName.PendingGridTaskColumns;
        case ItemType.ASSIGNMENTS:
          return UserWorkspaceSettingFieldName.PendingGridAssignmentColumns;
      }
      break;

    case PackageStatus.ARCHIVED:
      switch (dataGridItemType) {
        case ItemType.PROJECTS:
          return UserWorkspaceSettingFieldName.ArchivedGridProjectColumns;
        case ItemType.TASKS:
          return UserWorkspaceSettingFieldName.ArchivedGridTaskColumns;
        case ItemType.ASSIGNMENTS:
          return UserWorkspaceSettingFieldName.ArchivedGridAssignmentColumns;
      }
  }
};

export const getGridTypeSettingFieldNameForPackageStatusAndGridViewType = (
  packageStatus: PackageStatus | null | undefined,
  dataGridViewType: DataGridViewType
) => {
  if (!packageStatus || packageStatus === PackageStatus.TEMPLATE || dataGridViewType === DataGridViewType.WIDGET) {
    return undefined;
  }
  switch (packageStatus) {
    case PackageStatus.SCHEDULED:
      switch (dataGridViewType) {
        case DataGridViewType.PORTFOLIO:
          return UserWorkspaceSettingFieldName.ScheduledPortfolioGridItemType;
        case DataGridViewType.PACKAGE:
          return UserWorkspaceSettingFieldName.ScheduledPackageGridItemType;
        case DataGridViewType.PROJECT:
          return UserWorkspaceSettingFieldName.ScheduledProjectGridItemType;
      }
      break;

    case PackageStatus.BACKLOG:
      switch (dataGridViewType) {
        case DataGridViewType.PORTFOLIO:
          return UserWorkspaceSettingFieldName.PendingPortfolioGridItemType;
        case DataGridViewType.PACKAGE:
          return UserWorkspaceSettingFieldName.PendingPackageGridItemType;
        case DataGridViewType.PROJECT:
          return UserWorkspaceSettingFieldName.PendingProjectGridItemType;
      }
      break;

    case PackageStatus.ARCHIVED:
      switch (dataGridViewType) {
        case DataGridViewType.PORTFOLIO:
          return UserWorkspaceSettingFieldName.ArchivedPortfolioGridItemType;
        case DataGridViewType.PACKAGE:
          return UserWorkspaceSettingFieldName.ArchivedPackageGridItemType;
        case DataGridViewType.PROJECT:
          return UserWorkspaceSettingFieldName.ArchivedProjectGridItemType;
      }
  }
};

const defaultGridItemTypeByViewType = {
  [DataGridViewType.PORTFOLIO]: ItemType.PROJECTS,
  [DataGridViewType.PACKAGE]: ItemType.PROJECTS,
  [DataGridViewType.PROJECT]: ItemType.TASKS,
  [DataGridViewType.WIDGET]: undefined,
};

export const getDataGridItemTypeForPackageStatusAndViewType = createSelector(
  (
    { itemDataGrid }: RootState,
    packageStatus: PackageStatus | null | undefined,
    dataGridViewType: DataGridViewType
  ) => ({
    dataGridItemType: itemDataGrid.itemType,
    packageStatus,
    dataGridViewType,
  }),
  getCurrentWorkspaceUserSettings,
  ({ dataGridItemType, packageStatus, dataGridViewType }, currentWorkspaceUserSettings) => {
    const settingFieldName = getGridTypeSettingFieldNameForPackageStatusAndGridViewType(
      packageStatus,
      dataGridViewType
    );

    const persistedDataGridType: ItemType | undefined = settingFieldName
      ? currentWorkspaceUserSettings?.[settingFieldName]
      : undefined;

    return persistedDataGridType ?? dataGridItemType ?? defaultGridItemTypeByViewType[dataGridViewType];
  }
);

export const getDataGridAvailableColumnsForItemType = createSelector(
  (state: RootState, packageStatus: PackageStatus | null | undefined, dataGridViewType: DataGridViewType) => ({
    rootState: state,
    packageStatus,
    dataGridViewType,
  }),
  ({ rootState, packageStatus, dataGridViewType }) => {
    const dataGridItemType = getDataGridItemTypeForPackageStatusAndViewType(rootState, packageStatus, dataGridViewType);
    const dataGrid = rootState.itemDataGrid;
    const availableColumnsByItemType = dataGrid.availableColumns;
    return dataGridItemType ? availableColumnsByItemType[dataGridItemType] : [];
  }
);

export const getDataGridSelectedColumnsForPackageStatus = createSelector(
  (
    state: RootState,
    packageStatus: PackageStatus | null | undefined,
    allColumns: ReadonlyArray<SelectionList>,
    dataGridViewType: DataGridViewType
  ) => ({
    rootState: state,
    packageStatus,
    allColumns,
    dataGridViewType,
  }),
  getCurrentWorkspaceUserSettings,
  ({ rootState, packageStatus, allColumns, dataGridViewType }, currentWorkspaceUserSettings): Array<SelectionList> => {
    const selectedColumnsByItemType = rootState.itemDataGrid.selectedColumns;

    const dataGridItemType = getDataGridItemTypeForPackageStatusAndViewType(rootState, packageStatus, dataGridViewType);

    const settingFieldName = getGridColumnSettingFieldNameForPackageStatusAndGridItemType(
      packageStatus,
      dataGridItemType
    );

    const persistedColumns =
      settingFieldName && currentWorkspaceUserSettings?.[settingFieldName]
        ? (currentWorkspaceUserSettings?.[settingFieldName] ?? []).reduce((acc: Array<SelectionList>, scheduledCol) => {
            const col = allColumns.find((col) => col.id === scheduledCol);

            if (col) {
              acc.push(col);
            }
            return acc;
          }, [])
        : undefined;

    const selectedColumnsForItemType = dataGridItemType ? selectedColumnsByItemType[dataGridItemType] : [];

    return persistedColumns?.length ? persistedColumns : selectedColumnsForItemType;
  }
);

export const getHiddenColumns = ({ itemDataGrid }: RootState) => itemDataGrid.hiddenColumns;
export const getDataGridItemIds = ({ itemDataGrid }: RootState) => itemDataGrid.itemIds;

export const getDataGridProjectId = ({ itemDataGrid }: RootState) => itemDataGrid.projectId;
export const getDataGridPackageId = ({ itemDataGrid }: RootState) => itemDataGrid.packageId;

export const getDataGridItems = createSelector(getItemsById, getDataGridItemIds, (itemsById, itemIds) =>
  itemIds.reduce((acc: Array<Item>, itemId) => {
    const item = itemsById[itemId];
    if (item) {
      acc.push(item);
    }
    return acc;
  }, [])
);
