import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationDao } from 'daos/organization';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import RequestFailed from 'features/common/errors/request_failed';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { LpIcon, cloudCheckSolid } from 'features/common/lp_icon';
import { PlanGadgetSidebar } from 'features/organization_directory/manage_account/plan_gadget';
import { getOrganizationHubHasError } from 'features/organization_directory/selectors';
import { setOrganizationHubHasError } from 'features/organization_directory/slice';
import { ActionsSection } from 'features/organization_directory/users/actions_section';
import OrganizationDirectoryAdminsTable from 'features/organization_directory/users/admins/table';
import { OrgUsersQuickFilterType } from 'features/organization_directory/users/quick_filter';
import { awaitRequestFinish } from 'lib/api';

const OrgDirAdmins = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const hasError = useSelector(getOrganizationHubHasError);

  const [fetchComplete, setFetchComplete] = useState(false);

  useEffect(() => {
    const { uuid } = dispatch(OrganizationDao.fetch({ organizationId }, { include: { includeWorkspaceUsers: true } }));
    dispatch(
      awaitRequestFinish(uuid, {
        onError: () => {
          dispatch(setOrganizationHubHasError(true));
        },
        onFinish: () => {
          setFetchComplete(true);
        },
      })
    );
  }, [dispatch, organizationId]);

  useSetupOutpost(OutpostLocation.OrganizationAdminsActive);

  if (hasError) {
    return <RequestFailed />;
  }

  return (
    <div className="organization-directory__org-admins">
      {!fetchComplete ? (
        <LpOverlayLoader
          className="organization-directory__admins-table-spinner"
          active={true}
          content="Loading..."
          size="huge"
          inline={'centered'}
        />
      ) : (
        <>
          <div className="organization-directory__users-grid">
            <p>
              <strong>Organization Administrators</strong> are responsible for user management, billing and plan
              configuration and have full access to workspaces. It&apos;s good to have more than one Admin.
            </p>
            <ActionsSection filterType={OrgUsersQuickFilterType.MEMBERS} showAdd={false} />
            <OrganizationDirectoryAdminsTable />
            <PlanGadgetSidebar includeContactSupport={true} />
          </div>

          <div className="organization-directory__admins-footer">
            <LpIcon icon={cloudCheckSolid} /> To add or remove Admins, visit any user profile and grant or withdraw
            access
          </div>
        </>
      )}
    </div>
  );
};

export default OrgDirAdmins;
