import { useSelector } from 'react-redux';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';

import { Widget } from 'daos/model_types';
import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { getWidgetForId } from 'features/dashboards/selectors';

import './index.scss';

export function WidgetNoteClickThroughRenderer({ content }: { content: JSX.Element }) {
  return (
    <div className="widget-note-click-through">
      <Menu borderless secondary>
        <Menu.Item fitted>
          <GoBackToDashboardButton />
        </Menu.Item>
      </Menu>

      <div className="widget-note-click-through__body">{content}</div>
    </div>
  );
}

export const DashboardNoteContent = ({ widget }: { widget: Widget }) => {
  return <div dangerouslySetInnerHTML={{ __html: widget.config.content ?? '' }} />;
};

export function WidgetDashboardNote() {
  const { widgetId } = useWidgetParams();
  const widget = useSelector((state) => getWidgetForId(state, widgetId));

  if (!widget) {
    return null;
  }

  const content = <DashboardNoteContent widget={widget} />;

  return <WidgetNoteClickThroughRenderer content={content} />;
}
