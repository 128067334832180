import { SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { OrganizationUser } from 'daos/model_types';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { useOrgUserDropdownOptions } from 'features/common/inputs/dropdowns/hooks/use_org_user_dropdown_options';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';

import './user_dropdown.scss';

export interface UserDropdownProps {
  className?: string;
  clearable: DropdownProps['clearable'];
  currentUserId?: number;
  disabled?: DropdownProps['disabled'];
  filterDisconnected?: boolean;
  isOpen?: boolean;
  loading?: DropdownProps['loading'];
  newEntry?: boolean;
  onChange?: (event: SyntheticEvent<HTMLElement>, { value }: { value: number }) => void;
  placeholder: DropdownProps['placeholder'];
  selectOnBlur?: boolean;
  selectedOrgUserId?: number;
  orgUsers: ReadonlyArray<OrganizationUser>;
  usePortal?: boolean;
  apiOrgUserOptions?: never;
  onClose?: () => void;
  error?: boolean;
}

interface ApiUserDropdownProps extends Omit<UserDropdownProps, 'orgUsers' | 'apiOrgUserOptions'> {
  orgUsers?: never;
  apiOrgUserOptions: Array<DropdownItemProps>;
}

export enum UserDropdownPlaceHolderText {
  Add = 'Add',
  SelectUser = 'Select User',
  SelectAllTypes = 'Select Member, Resource or Placeholder',
}

const UserDropdown = ({
  className,
  clearable,
  disabled,
  filterDisconnected = true,
  isOpen,
  loading,
  newEntry,
  onChange,
  placeholder,
  selectOnBlur = false,
  selectedOrgUserId,
  orgUsers = [],
  usePortal = false,
  apiOrgUserOptions,
  onClose,
  error = false,
}: UserDropdownProps | ApiUserDropdownProps) => {
  const handleChange = (event: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    if (onChange) {
      onChange(event, { value: value as number });
    }
  };

  const userDropdownOptions = useOrgUserDropdownOptions({
    orgUsers,
    selectedOrgUserId,
    filterDisconnected,
    clearable,
  });

  const userDropdown = (
    <Dropdown
      className={className}
      disabled={disabled}
      lazyLoad
      error={error}
      loading={loading}
      onChange={handleChange}
      open={isOpen}
      options={apiOrgUserOptions ?? userDropdownOptions}
      placeholder={placeholder}
      search={lpDropdownSearch}
      selectOnBlur={selectOnBlur}
      selectOnNavigation={false}
      selection
      upward={false}
      onClose={onClose}
      value={newEntry || !selectedOrgUserId ? '' : selectedOrgUserId}
    />
  );

  return usePortal ? <PortalDropdown>{userDropdown}</PortalDropdown> : userDropdown;
};

export default UserDropdown;

interface OrgUserDropdownProps {
  clearable: DropdownProps['clearable'];
  onChange: (event: SyntheticEvent<HTMLElement>, { value }: { value: number }) => void;
  placeholder: DropdownProps['placeholder'];
  selectedOrgUserId: number;
  orgUsers: ReadonlyArray<OrganizationUser>;
  onClose: () => void;
}

export const OrgUserDropdownPortal = ({
  clearable,
  onChange,
  placeholder,
  selectedOrgUserId,
  orgUsers,
  onClose,
}: OrgUserDropdownProps) => {
  const handleChange = (event: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    onChange(event, { value: value as number });
  };

  const orgUserDropdownOptions = useOrgUserDropdownOptions({
    orgUsers,
    selectedOrgUserId,
    filterDisconnected: true,
    clearable,
  });

  const orgUserDropdown = (
    <Dropdown
      lazyLoad
      onChange={handleChange}
      options={orgUserDropdownOptions}
      placeholder={placeholder}
      search={lpDropdownSearch}
      selectOnNavigation={false}
      selection
      upward={false}
      value={selectedOrgUserId}
      onClose={onClose}
      open
    />
  );

  return <PortalDropdown>{orgUserDropdown}</PortalDropdown>;
};
