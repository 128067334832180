import { useMemo } from 'react';
import { SortColumn } from 'react-data-grid';

import { SortableDataGridContextProvider, useSortableDataGridContext } from 'features/common/data_grid/context';
import { LpDataGrid } from 'features/common/data_grid/grids/lp_data_grid';
import { clientSideSortByTypeAndDirection } from 'features/common/data_grid/helpers';
import { LpDataGridProps } from 'features/common/data_grid/types';
import { DeprecatedGridSummaryRow } from 'features/dashboards_v2/widget/widgets/list/types';

interface SortableDataGridProps<R, S> extends LpDataGridProps<R, S> {
  initialSortColumn?: SortColumn;
}

export function BaseSortableGrid<R, S = DeprecatedGridSummaryRow>(props: LpDataGridProps<R, S>) {
  const { sortColumn, setSortColumn } = useSortableDataGridContext();
  const { onSortColumnsChange } = props;

  const handleSortColumnChange = (updatedSortColumns: ReadonlyArray<SortColumn>) => {
    setSortColumn(updatedSortColumns[0]);
    onSortColumnsChange ? onSortColumnsChange(updatedSortColumns) : {};
  };

  const sortColumns = sortColumn ? [sortColumn] : [];

  return <LpDataGrid {...props} onSortColumnsChange={handleSortColumnChange} sortColumns={sortColumns} />;
}

function BaseClientSideSortableGrid<R, S>(props: LpDataGridProps<R, S>) {
  const { sortColumn } = useSortableDataGridContext();
  const { rows, columns } = props;

  const sortedRows = useMemo(() => {
    if (!sortColumn) {
      return rows;
    }
    const columnKey = sortColumn.columnKey as keyof R;
    const direction = sortColumn.direction;

    const sortType = columns.find((column) => column.key === columnKey)?.sortType;
    return clientSideSortByTypeAndDirection(rows, columnKey, direction, sortType);
  }, [columns, rows, sortColumn]);

  return <BaseSortableGrid {...props} rows={sortedRows} />;
}

export function ClientSideSortableDataGrid<R, S = unknown>(props: SortableDataGridProps<R, S>) {
  return (
    <SortableDataGridContextProvider initialSortColumn={props.initialSortColumn}>
      <BaseClientSideSortableGrid<R, S> {...props} />
    </SortableDataGridContextProvider>
  );
}
