import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel } from './shared';
import { backend } from './urls';

interface GetCurrentInAppNotificationsParams {
  organizationId: string | number;
  workspaceId: string | number;
}

const { resource } = defineModel({
  apiType: 'inAppNotifications',
  relationships: [],
  type: 'IN_APP_NOTIFICATIONS',
});

const { IN_APP_NOTIFICATIONS } = resource;

const getCurrent = (params: GetCurrentInAppNotificationsParams) =>
  request(backend.inAppNotificationsForCurrentWsUser.url(params), IN_APP_NOTIFICATIONS, { method: HttpMethod.GET });

export const InAppNotificationsDao = {
  getCurrent,
} as const;
