import { createSelector } from 'reselect';

import { Item } from 'daos/model_types';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { RootState } from 'redux/root_reducer';

export const getItemPickerDataOrderedByPriority = createSelector(
  ({ itemPicker: { items } }: RootState) => items,
  (itemsById) => {
    const itemsSortedByPriority = Object.values(itemsById).sort(compareByPriority);

    const itemsByPriorityMap: Map<number, Item> = new Map();
    itemsSortedByPriority.forEach((value) => {
      itemsByPriorityMap.set(value.id, value);
    });

    return itemsByPriorityMap;
  }
);

export const getItemPickerSelection = ({ itemPicker }: RootState) => itemPicker.selected;
