import { PropertyChange } from 'daos/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

const ChangeCardDate = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const date = propertyChangeData.value;
  const { formatLocaleDateWithTimeForChanges } = useLocalizedFormats();

  if (!date) {
    return <>None</>;
  }

  return <>{formatLocaleDateWithTimeForChanges(date)}</>;
};

export default ChangeCardDate;
