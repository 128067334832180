import { createContext, useContext } from 'react';

export interface ScrollContext {
  scrollableElement: React.RefObject<HTMLDivElement> | null;
}

export const ScrollContext = createContext<ScrollContext>({
  scrollableElement: null,
});

export const useScrollContext = () => useContext(ScrollContext);
