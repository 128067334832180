import { Widget } from 'daos/model_types';
import { RenderedWidget } from 'features/dashboards_v2/widget';
import { useDefaultWidgetTitle } from 'features/dashboards_v2/widget/use_default_widget_title';

import './index.scss';

export const DashboardNoteWidget = ({ widget }: { widget: Widget }) => {
  const defaultWidgetTitle = useDefaultWidgetTitle(widget);
  return (
    <RenderedWidget
      body={
        <div
          dangerouslySetInnerHTML={{
            __html: widget.config.content ?? '',
          }}
        />
      }
      header={widget.config.title ?? defaultWidgetTitle}
    />
  );
};
