import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { Permission, UserType } from 'daos/enums';
import { LpColorHex } from 'daos/item_enums';
import { User, WorkspaceUser, OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { UserDao } from 'daos/user';
import { WorkspaceUserDao } from 'daos/workspace_user';
import { getCurrentOrganizationId, getCurrentUserId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import ColorPickerDropdown from 'features/common/inputs/dropdowns/color_picker_dropdown';
import OrgUserFormatSettingsDropdown from 'features/shared/dropdowns/org_user_format_settings';
import OrgUserTimezoneDropdown from 'features/shared/dropdowns/org_user_timezone';
import UserPermissionsRow from 'features/shared/user_profile/about_table/user_permissions_row';
import { useHasAccess } from 'hooks/use_has_access';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { awaitRequestFinish } from 'lib/api';
import { ApiError } from 'lib/api/types';
import {
  dateFormatToDisplay,
  firstDayOfWeekToDisplay,
  numberFormatToDisplay,
  timeFormatToDisplay,
  weekNumberFormatToDisplay,
} from 'lib/localization';
import { DateFormat, FirstDayOfWeek, NumberFormat, TimeFormat, WeekNumberFormat } from 'lib/localization_enums';
import {
  getOrganizationUserForId,
  getUserForId,
  getWorkspaceUserForOrganizationUserId,
} from 'redux/entities/selectors/user';

import FirstLastNameRows from './first_last_name_row';
import UserEmailRow from './user_email_row';
import UsernameRow from './username_row';

interface AboutTableProps {
  orgUserId: number;
  userId: number;
  setUserUpdateError: React.Dispatch<React.SetStateAction<ApiError | undefined>>;
}

const AboutTable = ({ orgUserId, userId, setUserUpdateError }: AboutTableProps) => {
  const dispatch = useDispatch();

  const { formatLocalDate } = useLocalizedFormats();

  const currentUserId = useSelector(getCurrentUserId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const wsUser = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId));
  const user = useSelector((state) => getUserForId(state, userId));
  const canEditOrgUserFields = useHasAccess(Permission.MANAGE, OrganizationUserDao.id(orgUserId));
  const workspaceId = useSelector(getCurrentWorkspaceId);

  if (!currentUserId || !orgUser || !wsUser || !user) {
    return null;
  }

  const isCurrentUser = currentUserId === orgUser.user.id;

  const onColorChange = ({ color }: { color: LpColorHex }) => updateOrgUser({ avatarColor: color });

  const updateOrgUser = (updateProps: Partial<OrganizationUser>) => {
    const { uuid } = dispatch(
      OrganizationUserDao.update(
        { organizationId, organizationUserId: orgUserId },
        { id: orgUserId, ...updateProps },
        { include: { includeWorkspaceUsers: true } }
      )
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            setUserUpdateError(errors[0]);
          }
        },
      })
    );
  };

  const updateWsUser = (updateProps: Partial<WorkspaceUser>) => {
    const { uuid } = dispatch(
      WorkspaceUserDao.update(
        { organizationId, workspaceUserId: wsUser.id, workspaceId },
        { id: wsUser.id, ...updateProps }
      )
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            setUserUpdateError(errors[0]);
          }
        },
      })
    );
  };

  const updateUser = (updateProps: Partial<User>) => {
    const { uuid } = dispatch(UserDao.update({ userId }, { id: userId, ...updateProps }));

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            setUserUpdateError(errors[0]);
          }
        },
      })
    );
  };

  const isWsEvicted = !!wsUser.disconnectedAt;

  const aboutTableRowData = () => {
    const colorRow = {
      text: 'Color',
      value: (
        <ColorPickerDropdown
          disabled={!canEditOrgUserFields}
          onChange={onColorChange}
          hex={orgUser.avatarColor ?? LpColorHex.RELIGHT_BLUE}
        />
      ),
      className: 'user-profile__table-dropdown',
      disabled: false,
    };

    const timeZoneRow = {
      text: 'Time Zone',
      value: <OrgUserTimezoneDropdown orgUserId={orgUser.id} updateOrgUser={updateOrgUser} />,
      className: 'user-profile__table-dropdown',
      disabled: false,
    };

    switch (user.userType) {
      case UserType.Placeholder:
        return [
          colorRow,
          {
            text: 'Role',
            value: 'Non-Member that can hold estimates but will not schedule',
            className: '',
            disabled: false,
          },
        ];
      case UserType.Resource: {
        const resourceRows = [
          colorRow,
          timeZoneRow,
          {
            text: 'Role',
            value: 'Non-Member that can be scheduled and tracked',
            className: '',
            disabled: false,
          },
        ];

        if (orgUser.avatarUrl) {
          resourceRows.shift();
        }

        return resourceRows;
      }
      default:
        return [
          timeZoneRow,
          {
            text: 'Date Format',
            value: (
              <OrgUserFormatSettingsDropdown
                formatSettingToDisplay={dateFormatToDisplay}
                formatSettingsEnum={DateFormat}
                orgUserId={orgUser.id}
                orgUserProperty={{ dateFormat: orgUser.dateFormat }}
                orgUserPropertyString={'dateFormat'}
                updateOrgUser={updateOrgUser}
              />
            ),
            className: 'user-profile__table-dropdown',
            disabled: isWsEvicted,
          },
          {
            text: 'Time Format',
            value: (
              <OrgUserFormatSettingsDropdown
                formatSettingToDisplay={timeFormatToDisplay}
                formatSettingsEnum={TimeFormat}
                orgUserId={orgUser.id}
                orgUserProperty={{ timeFormat: orgUser.timeFormat }}
                orgUserPropertyString={'timeFormat'}
                updateOrgUser={updateOrgUser}
              />
            ),
            className: 'user-profile__table-dropdown',
            disabled: isWsEvicted,
          },
          {
            text: 'Number Format',
            value: (
              <OrgUserFormatSettingsDropdown
                formatSettingToDisplay={numberFormatToDisplay}
                formatSettingsEnum={NumberFormat}
                orgUserId={orgUser.id}
                orgUserProperty={{ numberFormat: orgUser.numberFormat }}
                orgUserPropertyString={'numberFormat'}
                updateOrgUser={updateOrgUser}
              />
            ),
            className: 'user-profile__table-dropdown',
            disabled: isWsEvicted,
          },
          {
            text: 'Week Starts on',
            value: (
              <OrgUserFormatSettingsDropdown
                formatSettingToDisplay={firstDayOfWeekToDisplay}
                formatSettingsEnum={FirstDayOfWeek}
                orgUserId={orgUser.id}
                orgUserProperty={{ firstDayOfWeek: orgUser.firstDayOfWeek }}
                orgUserPropertyString={'firstDayOfWeek'}
                updateOrgUser={updateOrgUser}
              />
            ),
            className: 'user-profile__table-dropdown',
            disabled: isWsEvicted,
          },
          {
            text: 'Week Numbering',
            value: (
              <OrgUserFormatSettingsDropdown
                formatSettingToDisplay={weekNumberFormatToDisplay}
                formatSettingsEnum={WeekNumberFormat}
                orgUserId={orgUser.id}
                orgUserProperty={{ weekNumberFormat: orgUser.weekNumberFormat }}
                orgUserPropertyString={'weekNumberFormat'}
                updateOrgUser={updateOrgUser}
              />
            ),
            className: 'user-profile__table-dropdown',
            disabled: isWsEvicted,
          },
          {
            text: 'User Since',
            value: formatLocalDate(orgUser.createdAt),
            disabled: isWsEvicted,
            className: classNames('user-profile__about-table-row-user-since', {
              'user-profile__about-table--evicted': isWsEvicted,
            }),
          },
        ];
    }
  };

  return (
    <Table className="user-profile__about-table" compact definition>
      <Table.Body>
        <UsernameRow orgUserId={orgUserId} updateOrgUser={updateOrgUser} />
        <FirstLastNameRows userId={userId} wsUserId={wsUser.id} updateUser={updateUser} />
        <UserEmailRow userId={userId} isWsEvicted={isWsEvicted} />

        {aboutTableRowData().map((data) => (
          <Table.Row key={data.text}>
            <Table.Cell content={data.text} />
            <Table.Cell
              className={classNames(data.className, 'user-profile__about-table-row')}
              content={data.value}
              disabled={data.disabled}
            />
          </Table.Row>
        ))}

        {user.userType === UserType.Member && (
          <UserPermissionsRow
            isAdmin={orgUser.admin}
            isCurrentUser={isCurrentUser}
            orgUserId={orgUserId}
            wsUserId={wsUser.id}
            updateOrgUser={updateOrgUser}
            updateWsUser={updateWsUser}
          />
        )}
      </Table.Body>
    </Table>
  );
};

export default AboutTable;
