import { isNil } from 'lodash';

import { Columns, FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { ColumnValueProps, CustomFieldColumnProps, FieldValueColumn } from 'containers/shared/custom_column/types';
import { CustomFieldType } from 'daos/enums';
import { PropsWidgetColumn, WidgetConfigColumn } from 'daos/widget_column';
import {
  arrowUpRightFromSquareSolid,
  boxSolid,
  bullseyePointerSolid,
  bullseyeSolid,
  calculatorSolid,
  calendarDaySolid,
  circleCheckSolid,
  circleDotSolid,
  circlePauseSolid,
  circleSmallSolid,
  circleSolid,
  clockSolid,
  coinSolid,
  flagCheckeredSolid,
  flagShallowtailSolid,
  folderClosedSolid,
  gearSolid,
  inputNumericSolid,
  inputTextSolid,
  locationArrowSolid,
  playSolid,
  scissorsSolid,
  squareOneSolid,
  squareQuestionSolid,
  tagSolid,
  tagsSolid,
  textSolid,
  userGroupSolid,
  userSolid,
} from 'features/common/lp_icon';
import {
  DeprecatedColumnDataMap,
  WidgetCustomFieldData,
} from 'features/dashboards_v2/widget/widgets/properties_summary/types';
import { ColumnDataMap, WidgetColumnDataValue } from 'features/dashboards_v2/widget/widgets/reports/types';
import { mapWidgetAssignees } from 'lib/display_helpers/assignee/assignee';
import { convertSecondsToHours } from 'lib/helpers';
import { NumberFormat } from 'lib/localization_enums';

// Investigate: Why are there FrontendColumnOptions in this list if these icons are only
// used in the properties_summary widget? (...which supports only server-side columns)
export function getIconForColumn(column: Columns | null, customFieldType: CustomFieldType | null) {
  switch (column) {
    case StandardColumns.Assigned:
    case StandardColumns.Assignment:
    case StandardColumns.CreatedBy:
    case StandardColumns.UpdatedBy:
    case FrontendColumns.OrgUserName:
      return userSolid;
    case StandardColumns.AssignmentCountActive:
    case StandardColumns.AssignmentCountDone:
    case StandardColumns.AssignmentCountTotal:
    case StandardColumns.AssignmentDone:
      return circleSmallSolid;
    case StandardColumns.TargetFinishType:
      return gearSolid;
    case StandardColumns.ClippedEffort:
      return scissorsSolid;
    case StandardColumns.CostCode:
      return coinSolid;
    case StandardColumns.CreatedAt:
    case StandardColumns.UpdatedAt:
    case StandardColumns.CustomField:
      switch (customFieldType) {
        case CustomFieldType.CHECKBOX:
          return tagSolid;
        case CustomFieldType.CURRENCY:
          return coinSolid;
        case CustomFieldType.DATE:
          return calendarDaySolid;
        case CustomFieldType.MULTILINE_TEXT:
          return inputTextSolid;
        case CustomFieldType.ITEM:
        case CustomFieldType.LINK:
        case CustomFieldType.MULTI_ITEM:
        case CustomFieldType.MULTI_LINK:
          return arrowUpRightFromSquareSolid;
        case CustomFieldType.MULTI_PICKLIST:
          return tagsSolid;
        case CustomFieldType.MULTI_TEXT:
          return inputTextSolid;
        case CustomFieldType.MULTI_USER:
          return userGroupSolid;
        case CustomFieldType.NOTE:
          return inputTextSolid;
        case CustomFieldType.NUMERIC:
          return inputNumericSolid;
        case CustomFieldType.PICKLIST:
          return tagSolid;
        case CustomFieldType.TEXT:
          return textSolid;
        case CustomFieldType.USER:
          return userSolid;
      }
      return tagSolid;
    case StandardColumns.Description:
      return textSolid;
    case StandardColumns.DoneDate:
    case FrontendColumns.EarliestDoneDate:
    case FrontendColumns.LatestDoneDate:
      return circleCheckSolid;
    case FrontendColumns.EffectiveTargetFinish:
    case StandardColumns.TargetFinish:
      return bullseyePointerSolid;
    case FrontendColumns.EffectiveTargetStart:
      return bullseyeSolid;
    case StandardColumns.ExpectedFinish:
    case StandardColumns.FinishRange:
      return flagCheckeredSolid;
    case StandardColumns.ExpectedStart:
      return playSolid;
    case StandardColumns.FolderStatus:
      return folderClosedSolid;
    case StandardColumns.HighEstimate:
    case StandardColumns.LowEstimate:
      return squareQuestionSolid;
    case StandardColumns.LatestFinish:
      return flagShallowtailSolid;
    case StandardColumns.Location:
      return locationArrowSolid;
    case StandardColumns.Logged:
      return clockSolid;
    case StandardColumns.Package:
      return boxSolid;
    case StandardColumns.PercentTasksComplete:
    case FrontendColumns.PercentCompleteNumericValueAsString:
    case StandardColumns.PercentComplete:
      return calculatorSolid;
    case StandardColumns.PriorityRush:
      return squareOneSolid;
    case StandardColumns.ProjectCountScheduled:
    case StandardColumns.ProjectCountDone:
    case StandardColumns.ProjectCountOnHold:
    case StandardColumns.ProjectCountTotal:
    case StandardColumns.Project:
    case StandardColumns.ProjectStatus:
      return folderClosedSolid;
    case StandardColumns.RemainingWorkExpected:
    case StandardColumns.RemainingWorkRange:
    case StandardColumns.RemainingWorkLow:
    case StandardColumns.RemainingWorkHigh:
      return squareQuestionSolid;
    case FrontendColumns.RollupEarliestActiveTargetFinish:
    case FrontendColumns.RollupLatestTargetFinish:
      return bullseyePointerSolid;
    case FrontendColumns.RollupEarliestTargetStart:
      return bullseyeSolid;
    case StandardColumns.SubFolder:
      return folderClosedSolid;
    case StandardColumns.TargetStart:
      return bullseyeSolid;
    case StandardColumns.Task:
    case StandardColumns.TaskCountActive:
      return circleDotSolid;
    case StandardColumns.TaskCountDone:
      return circleCheckSolid;
    case StandardColumns.TaskCountOnHold:
      return circlePauseSolid;
    case StandardColumns.TaskCountTotal:
      return circleSolid;
    case StandardColumns.TaskStatus:
      return circleDotSolid;
    case StandardColumns.TimesheetScheduled:
    case StandardColumns.TimesheetScheduledBillableHours:
    case StandardColumns.TimesheetScheduledNonBillableHours:
      return clockSolid;
    case StandardColumns.TotalWorkExpected:
    case StandardColumns.TotalWorkScheduled:
    case StandardColumns.TotalWorkRange:
    case StandardColumns.TotalWorkLow:
    case StandardColumns.TotalWorkHigh:
      return circleDotSolid;
    case StandardColumns.TrackingOnly:
      return gearSolid;
    case StandardColumns.UncertainWork:
    case StandardColumns.UnusedEffortExpected:
      return squareQuestionSolid;
    case StandardColumns.WorkType:
      return gearSolid;
    default:
      return tagsSolid;
  }
}

// Investigate: Why are there FrontendColumnOptions in this list if these icons are only
// used in the properties_summary widget? (...which supports only server-side columns)
const getValueForColumn = <T extends Columns>(col: T, columnMap: DeprecatedColumnDataMap) => {
  return columnMap[col];
};

function getPropForData<T>(data: T | null, getProp: (data: T) => Partial<ColumnValueProps>) {
  if (!isNil(data)) {
    return getProp(data);
  }

  return {};
}

/**
 * @description `mapValueToColumn` is only exported for testing purposes.
 * This function maps the data values from the widget configuration to the desired column display shape and value.
 *
 * Investigate: Why are there FrontendColumnOptions in this list if these icons are only
 * used in the properties_summary widget? (...which supports only server-side columns)
 */
export function mapValueToColumn(column: Columns, columnMap: DeprecatedColumnDataMap): Partial<ColumnValueProps> {
  switch (column) {
    case StandardColumns.Assigned: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ assigneeMap: mapWidgetAssignees(data) }));
    }
    case StandardColumns.Assignment: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ assignmentName: data }));
    }
    case StandardColumns.AssignmentDone: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ isDone: data }));
    }
    case StandardColumns.BenchmarkEstimate: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ benchmarkEstimate: data }));
    }
    case StandardColumns.ClippedEffort: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ clippedEffortHours: convertSecondsToHours(data) }));
    }
    case StandardColumns.CostCode: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ costCodeName: data }));
    }
    case StandardColumns.CreatedAt: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ createdAt: data }));
    }
    case StandardColumns.CreatedBy: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ createdByUsername: data }));
    }
    case StandardColumns.DateRange: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ dateRange: data }));
    }
    case StandardColumns.Description: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ description: data }));
    }
    case StandardColumns.DoneDate: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ doneDate: data }));
    }
    case StandardColumns.ExpectedFinish: {
      const { data } = getValueForColumn(column, columnMap);

      return getPropForData(data, (data) => ({
        expectedFinish: { finish: data.expectedFinish, isAtRisk: data.atRisk },
      }));
    }
    case StandardColumns.ExpectedStart: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ expectedStart: data }));
    }
    case StandardColumns.FinishRange: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        finishRange: { expected: data.expectedFinish, latest: data.latestFinish, isAtRisk: data.atRisk },
      }));
    }

    case StandardColumns.AssignmentCountActive: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ assignmentActiveCount: data }));
    }
    case StandardColumns.AssignmentCountDone: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ assignmentDoneCount: data }));
    }
    case StandardColumns.AssignmentCountTotal: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ assignmentTotalCount: data }));
    }
    case FrontendColumns.EarliestDoneDate: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ earliestDoneDate: data }));
    }
    case FrontendColumns.EffectiveTargetFinish: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ effectiveTargetFinish: data }));
    }
    case FrontendColumns.EffectiveTargetStart: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ effectiveTargetStart: data }));
    }
    case StandardColumns.FolderStatus:
    case StandardColumns.ProjectStatus: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ folderStatus: data }));
    }
    case StandardColumns.Groups: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ groups: data }));
    }
    case StandardColumns.ItemFileCount: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ fileCount: data }));
    }
    case StandardColumns.ItemId: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ itemId: data }));
    }
    case FrontendColumns.LatestDoneDate: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ latestDoneDate: data }));
    }
    case StandardColumns.LatestFinish: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        latestFinish: { finish: data.latestFinish, isAtRisk: data.atRisk },
      }));
    }
    case StandardColumns.Location: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ itemBreadcrumb: data.join(' / ') }));
    }
    case StandardColumns.Logged: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        loggedWorkHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.LoggedBillable: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ loggedBillable: convertSecondsToHours(data) }));
    }
    case StandardColumns.LoggedNonBillable: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ loggedNonBillable: convertSecondsToHours(data) }));
    }
    case StandardColumns.OnHoldHours: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ onHoldHours: convertSecondsToHours(data) }));
    }
    case FrontendColumns.OnHoldHoursRange: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        onHoldHoursRange: {
          lowOnHoldHours: convertSecondsToHours(data.low),
          highOnHoldHours: convertSecondsToHours(data.high),
        },
      }));
    }
    case StandardColumns.Package: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ packageName: data }));
    }
    case FrontendColumns.PackageStatus: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ packageStatus: data }));
    }
    case StandardColumns.PercentComplete: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ percentComplete: data }));
    }
    case StandardColumns.PercentTasksComplete: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ percentTasksComplete: data }));
    }
    case StandardColumns.PriorityRush: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ scheduleDirective: data }));
    }
    case StandardColumns.Project: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ projectName: data }));
    }
    case StandardColumns.ProjectCountDone: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ projectDoneCount: data }));
    }
    case StandardColumns.ProjectCountOnHold: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ projectOnHoldCount: data }));
    }
    case StandardColumns.ProjectCountScheduled: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ projectScheduledCount: data }));
    }
    case StandardColumns.ProjectCountTotal: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ projectTotalCount: data }));
    }
    case StandardColumns.BillingRateSheet: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ billingRateSheetName: data }));
    }
    case StandardColumns.PayRateSheet: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ payRateSheetName: data }));
    }
    case StandardColumns.RemainingAvailability: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ remainingAvailability: convertSecondsToHours(data) }));
    }
    case StandardColumns.RemainingWorkExpected: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        remainingWorkExpectedHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.RemainingWorkHigh: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        highRemainingWorkHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.RemainingWorkLow: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        lowRemainingWorkHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.RemainingWorkRange: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        remainingWorkRange: {
          lowRemainingWorkHours: convertSecondsToHours(data.value.low),
          highRemainingWorkHours: convertSecondsToHours(data.value.high),
        },
        workLimitAlert: data.alert,
      }));
    }
    case FrontendColumns.RollupEarliestActiveTargetFinish: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ rollupEarliestActiveTargetFinish: data }));
    }
    case FrontendColumns.RollupEarliestTargetStart: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ rollupEarliestTargetStart: data }));
    }
    case FrontendColumns.RollupLatestTargetFinish: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ rollupEarliestActiveTargetFinish: data }));
    }
    case StandardColumns.ScheduleBar: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (scheduleSummary) => ({ scheduleBarColumnProps: scheduleSummary }));
    }
    case StandardColumns.TimesheetScheduledBillableHours: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ timesheetScheduledBillableHours: convertSecondsToHours(data) }));
    }
    case StandardColumns.TimesheetScheduledNonBillableHours: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ timesheetScheduledNonBillableHours: convertSecondsToHours(data) }));
    }
    case StandardColumns.TotalWorkBillable: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ totalWorkBillable: convertSecondsToHours(data) }));
    }
    case StandardColumns.TotalWorkNonBillable: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ totalWorkNonBillable: convertSecondsToHours(data) }));
    }
    case StandardColumns.SubFolder: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ subFolders: data }));
    }
    case StandardColumns.StoryPoint: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ storyPoint: data }));
    }
    case StandardColumns.StoryPointSchemeName: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ storyPointSchemeName: data }));
    }
    case StandardColumns.StoryPointCountActive: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ storyPointCountActive: data }));
    }
    case StandardColumns.StoryPointCountDone: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ storyPointCountDone: data }));
    }
    case StandardColumns.StoryPointCountOnHold: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ storyPointCountOnHold: data }));
    }
    case StandardColumns.TargetFinish: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        targetFinish: { finish: data.targetFinish, inherited: data.isInherited, isAtRisk: data.atRisk },
      }));
    }
    case StandardColumns.TargetFinishDelta: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        targetFinishDeltaColumn: {
          delta: data,
          includeAlertIcon: true,
          notApplicableText: undefined,
          valueOnly: false,
        },
      }));
    }
    case StandardColumns.TargetFinishType: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ targetFinishType: data }));
    }
    case StandardColumns.TargetStart: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        targetStart: { start: data.targetStart, inherited: data.isiInherited },
      }));
    }
    case StandardColumns.TargetStartDelta: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        targetStartDeltaColumn: {
          delta: data,
          includeAlertIcon: true,
          notApplicableText: undefined,
          valueOnly: false,
        },
      }));
    }
    case StandardColumns.Task: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ taskName: data }));
    }
    case StandardColumns.TaskCountActive: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ taskActiveCount: data }));
    }
    case StandardColumns.TaskCountDone: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ taskDoneCount: data }));
    }
    case StandardColumns.TaskCountOnHold: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ taskOnHoldCount: data }));
    }
    case StandardColumns.TaskCountTotal: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ taskTotalCount: data }));
    }
    case StandardColumns.TaskStatus: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ taskStatusName: data }));
    }
    case StandardColumns.TimesheetScheduled: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ timesheetScheduled: convertSecondsToHours(data) }));
    }
    case StandardColumns.TotalAvailability: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ totalAvailability: convertSecondsToHours(data) }));
    }
    case StandardColumns.TotalWorkExpected: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        totalWorkExpectedHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.TotalWorkHigh: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        highTotalWorkHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.TotalWorkLow: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        lowTotalWorkHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.TotalWorkRange: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        totalWorkRange: {
          lowTotalWorkHours: convertSecondsToHours(data.value.low),
          highTotalWorkHours: convertSecondsToHours(data.value.high),
        },
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.TotalWorkScheduled: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        totalWorkScheduled: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.TrackingOnly: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ trackingOnly: data }));
    }
    case StandardColumns.UncertainWork: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ uncertainWorkHours: convertSecondsToHours(data) }));
    }
    case StandardColumns.UnloggedWork: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ unloggedWork: convertSecondsToHours(data) }));
    }
    case StandardColumns.UnusedEffortExpected: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ unusedEffortHours: convertSecondsToHours(data) }));
    }
    case StandardColumns.UpdatedAt: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ updatedAt: data }));
    }
    case StandardColumns.UpdatedBy: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ updatedByUsername: data }));
    }
    case StandardColumns.WorkLimit: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => {
        if (data.workLimit !== null) {
          return {
            workLimitColumn: {
              workLimitAlert: data.workLimitAlert,
              workLimitHours: convertSecondsToHours(data.workLimit),
            },
          };
        }
        return {};
      });
    }
    case StandardColumns.WorkLimitDelta: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({
        workLimitDeltaHours: convertSecondsToHours(data.value),
        workLimitAlert: data.alert,
      }));
    }
    case StandardColumns.WorkType: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ workType: data }));
    }

    //People columns
    case StandardColumns.AvailabilityUsed:
    case StandardColumns.FirstName:
    case StandardColumns.LastName:
    case StandardColumns.LpUserId:
    case StandardColumns.Username:
    case StandardColumns.UserStatus:
    case StandardColumns.UserType:
    case StandardColumns.Workload: {
      const { data } = getValueForColumn(column, columnMap);
      return getPropForData(data, (data) => ({ [column]: data }));
    }

    // not currently applicable on property or list widgets
    case StandardColumns.CustomField:
    case FrontendColumns.Empty:
    case StandardColumns.HighEstimate:
    case StandardColumns.LowEstimate:
    case FrontendColumns.None:
    case FrontendColumns.OrgUserName:
    case FrontendColumns.PercentCompleteNumericValueAsString:
    case StandardColumns.DeletedAt:
    case StandardColumns.Name:
      return {};

    case FrontendColumns.FileName:
    case FrontendColumns.FileSize:
    case FrontendColumns.MetricsHelp:
    case FrontendColumns.Settings:
    case FrontendColumns.SlackWorkspaceName:
    case FrontendColumns.UploadedBy:
    case FrontendColumns.UploadedOn:
      throw Error(`Column ${column} is not supported for this widget`);
  }
}

function getCustomFieldValueProps(customFieldColumn: WidgetCustomFieldData): ReadonlyArray<Partial<FieldValueColumn>> {
  const { fieldValues, type, inherited } = customFieldColumn;

  switch (type) {
    case CustomFieldType.CHECKBOX:
      return fieldValues.map((value) => ({ checked: value as boolean, isInherited: inherited }));
    case CustomFieldType.CURRENCY:
      return fieldValues.map((currency) => {
        const { value, currencyUnit, numberFormat } = currency as {
          value: number;
          currencyUnit: string;
          numberFormat: NumberFormat;
        };
        return { currency: value, currencyUnit, isInherited: inherited, numberFormat };
      });
    case CustomFieldType.DATE:
      return fieldValues.map((value) => ({ date: value as string }));
    case CustomFieldType.ITEM:
    case CustomFieldType.MULTI_ITEM:
      return fieldValues.map((value) => ({ itemName: value as string }));
    case CustomFieldType.LINK:
    case CustomFieldType.MULTI_LINK:
      return fieldValues.map((value) => {
        const { url, text } = value as { url: string; text: string | null };
        return { url, text: text ?? undefined };
      });
    case CustomFieldType.MULTILINE_TEXT:
    case CustomFieldType.TEXT:
    case CustomFieldType.MULTI_TEXT:
      return fieldValues.map((value) => ({ text: value as string }));
    case CustomFieldType.NUMERIC:
      return fieldValues.map((value) => ({ number: value as number }));
    case CustomFieldType.PICKLIST:
    case CustomFieldType.MULTI_PICKLIST:
      return fieldValues.map((value) => {
        const { name, color } = value as { name: string; color: string };
        return { picklistChoiceColor: color, picklistChoiceName: name };
      });
    case CustomFieldType.MULTI_USER:
    case CustomFieldType.USER:
      return fieldValues.map((value) => ({ username: value as string }));
    case CustomFieldType.NOTE:
      throw Error('Field type is not supported for this widget');
  }
}

function getCustomFieldColumnProps(customFieldData: WidgetCustomFieldData): CustomFieldColumnProps {
  const values = getCustomFieldValueProps(customFieldData);
  return {
    customFieldType: customFieldData.type,
    isFieldApplicable: true,
    values,
  };
}

function getCustomFieldRendererProps(
  customFieldId: number,
  columnDataMap: DeprecatedColumnDataMap,
  isSummaryRow: boolean
) {
  const customFieldColumn = columnDataMap[customFieldId];
  const isApplicable = customFieldColumn?.isApplicable ?? false;
  const customFieldData = customFieldColumn?.data;

  if (isNil(customFieldData)) {
    return { isApplicable, isSummaryRow };
  }

  return {
    isApplicable,
    isSummaryRow,
    customFieldColumnProps: { ...getCustomFieldColumnProps(customFieldData) },
  };
}

function getColumnOptionRendererProps(
  // Investigate: Why are there FrontendColumnOptions in this list if these icons are only
  // used in the properties_summary widget? (...which supports only server-side columns)
  column: Columns,
  columnDataMap: DeprecatedColumnDataMap,
  isSummaryRow: boolean
) {
  const itemColumn = columnDataMap[column];
  const data = itemColumn?.data;
  const isApplicable = itemColumn?.isApplicable ?? false;

  if (isNil(data)) {
    return { isApplicable, isSummaryRow };
  }

  return { isApplicable, isSummaryRow, ...mapValueToColumn(column, columnDataMap) };
}

export function getRendererPropsForWidgetColumn(
  columnDefinition: PropsWidgetColumn | WidgetConfigColumn,
  columnDataMap: DeprecatedColumnDataMap,
  isSummaryRow = false
): Partial<ColumnValueProps> {
  const { column, customFieldId } = columnDefinition;

  if (customFieldId) {
    return getCustomFieldRendererProps(customFieldId, columnDataMap, isSummaryRow);
  }

  return getColumnOptionRendererProps(column, columnDataMap, isSummaryRow);
}

export function deprecatedGetIsActiveOrInUseArchivedCustomField(
  customFieldId: number,
  columnDataMap: Partial<DeprecatedColumnDataMap>
) {
  const customFieldColumn = columnDataMap[customFieldId];
  const customFieldData = customFieldColumn?.data;

  if (customFieldData) {
    const hasValues = customFieldData.fieldValues.length > 0;
    const isInherited = customFieldData.inherited;
    const isArchived = customFieldData.isArchived;

    const isInUseArchivedField = isArchived && !isInherited && hasValues;
    const isActiveField = !isArchived;

    return isInUseArchivedField || isActiveField;
  }

  return false;
}

const getHasExplicitFieldValue = (customFieldId: number, columnDataMap: ColumnDataMap) => {
  const customFieldColumn = columnDataMap[customFieldId];
  const fieldAndValueData: WidgetColumnDataValue = customFieldColumn?.data?.[0] ?? { value: '' };

  const { checked, inherited, itemData, linkData, picklistData, value, userData } = fieldAndValueData;

  const hasFieldValue = !!value || !!checked || !!itemData || !!linkData || !!picklistData || !!userData;

  return !inherited && hasFieldValue;
};

export const getIsActiveOrInUseArchivedCustomField = (
  columnDefinition: WidgetConfigColumn,
  columnDataMap: ColumnDataMap
) => {
  const fieldId = columnDefinition.customFieldId;
  const isArchivedField = !!columnDefinition.customFieldArchived;
  const hasExplicitFieldValue = !!fieldId && getHasExplicitFieldValue(fieldId, columnDataMap);

  const isArchivedInUse = isArchivedField && hasExplicitFieldValue;
  const isActiveField = !!fieldId && !isArchivedField;

  return isActiveField || isArchivedInUse;
};
