import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import { StatusFilterGroups } from 'daos/enums';

interface BulkSelectionState {
  selectedItemIds: ReadonlyArray<number>;
  selectedTaskStatusGroup?: StatusFilterGroups;
  jobId?: string;
}

export const initialState: BulkSelectionState = { selectedItemIds: [] };

const bulkSelectionSlice = createSlice({
  name: 'bulkSelection',
  initialState,
  reducers: {
    clearBulkData: () => initialState,
    setBulkSelectedItemIds: (state, action: PayloadAction<ReadonlyArray<number>>) => {
      state.selectedItemIds = castDraft(action.payload);
    },
    setBulkSelectedTaskStatusGroup: (state, action: PayloadAction<StatusFilterGroups | undefined>) => {
      state.selectedTaskStatusGroup = action.payload;
    },
    setJobId: (state, action: PayloadAction<string | undefined>) => {
      state.jobId = action.payload;
    },
  },
});

export const { clearBulkData, setBulkSelectedItemIds, setBulkSelectedTaskStatusGroup, setJobId } =
  bulkSelectionSlice.actions;
export default bulkSelectionSlice.reducer;
