import { KeypressKeys } from 'lib/helpers/keypress_keys';

export const blurElementOnEnterKey = (e: KeyboardEvent) => {
  if (e.code === KeypressKeys.Enter) {
    const input = e.target as HTMLElement;
    input.blur();
  }
};

export const blurInputOnEscapeKey = (e: KeyboardEvent) => {
  if (e.code === KeypressKeys.Escape) {
    const nameInput = e.target as HTMLElement;
    nameInput.blur();
  }
};
