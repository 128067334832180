import { createSelector } from 'reselect';

import { OrganizationUserEmailNotifications } from 'daos/model_types';
import { ReadonlyRecord } from 'lib/readonly_record';
import { RootState } from 'redux/root_reducer';

import { getNumberArgument } from './shared';

const getOrganizationUserSlackNotifications = (state: RootState) => state.entities.organizationUserEmailNotifications;

const getOrganizationUserEmailNotificationsByOrgUserId = createSelector(
  getOrganizationUserSlackNotifications,
  (emailNotifications) =>
    Object.values(emailNotifications).reduce(
      (acc: ReadonlyRecord<number, OrganizationUserEmailNotifications>, current) => ({
        ...acc,
        [current.orgUserId]: current,
      }),
      {}
    )
);

export const getOrganizationUserEmailNotificationsForOrgUserId = createSelector(
  getOrganizationUserEmailNotificationsByOrgUserId,
  getNumberArgument,
  (emailNotificationsByOrgUserId, orgUserId) => emailNotificationsByOrgUserId[orgUserId]
);
