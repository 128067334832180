import DataGrid, { Row, RowRendererProps } from 'react-data-grid';

import { EntityRow, LpDataGridProps } from 'features/common/data_grid/types';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { TrackingBoundingBox, useTrackBoundingBox } from 'hooks/use_track_bound_box';

import 'react-data-grid/lib/styles.css';
import './lp_data_grid.scss';

export function LpDataGrid<R, S>({
  columns,
  headerRowHeight,
  isFetchComplete,
  onRowClick,
  onSelectedRowsChange,
  onSortColumnsChange,
  rowHeight,
  rows,
  summaryRow,
  selectedRows,
  sortColumns,
  lastRowRef,
}: LpDataGridProps<R, S>) {
  const { box, ref } = useTrackBoundingBox();

  const rowKeyGetter = (row: EntityRow<R, S>) => row.id;

  if (!isFetchComplete) {
    return <LpOverlayLoader />;
  }

  if (!box) {
    return <TrackingBoundingBox innerRef={ref} />;
  }

  // @ts-expect-error should take a look on type
  const infiniteScrollRowRenderer = (key: React.Key, props: RowRendererProps<EntityRow<Row>, S>) => {
    const ref = props.rowIdx === rows.length - 1 ? lastRowRef : null;
    return <Row key={key} {...props} ref={ref} />;
  };

  return (
    <TrackingBoundingBox innerRef={ref}>
      <div
        className="lp-data-grid"
        style={{
          height: box.height,
          width: box.width,
        }}
      >
        <DataGrid
          bottomSummaryRows={summaryRow}
          className="rdg-light"
          cellNavigationMode="CHANGE_ROW"
          columns={columns}
          headerRowHeight={headerRowHeight}
          onRowClick={onRowClick}
          onSelectedRowsChange={onSelectedRowsChange}
          onSortColumnsChange={onSortColumnsChange}
          rowHeight={rowHeight}
          rowKeyGetter={rowKeyGetter}
          rows={rows}
          selectedRows={selectedRows}
          sortColumns={sortColumns}
          renderers={{ rowRenderer: infiniteScrollRowRenderer }}
        />
      </div>
    </TrackingBoundingBox>
  );
}
