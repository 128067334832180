import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Breadcrumb, BreadcrumbSectionProps, List, SemanticShorthandCollection } from 'semantic-ui-react';

import FolderStatusIcon from 'containers/shared/folder_status_icon';
import ItemIcon from 'containers/shared/item_icon';
import { ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { frontend } from 'lib/urls';
import { isFolderOrProject, isPackage, getAncestryForItemId } from 'redux/entities/selectors/item';

import { SearchContext } from './enums';

interface ResultTableRowProps {
  item: Item;
  resultNumber: number;
  searchContext: SearchContext;
}

const ResultItem = ({ item, resultNumber, searchContext }: ResultTableRowProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const ancestry = useSelector((state) => getAncestryForItemId(state, item.id)).slice(searchContext);

  const history = useHistory();

  const showFolderStatusIcon = isFolderOrProject(item);

  const handleAncestorOnClick = (ancestor: Item) => {
    switch (ancestor.itemType) {
      case ItemType.PACKAGES:
        history.push(
          frontend.singlePackage.url({
            organizationId,
            packageId: ancestor.id,
            workspaceId,
          })
        );
        break;

      case ItemType.PROJECTS:
      case ItemType.FOLDERS:
        history.push(
          frontend.projectProject.url({
            itemId: ancestor.id,
            organizationId,
            workspaceId,
          })
        );
        break;

      default:
        history.push(`#panelId=${item.id}`);
    }
  };

  const itemBreadcrumbSections = () =>
    ancestry.reduce((acc: SemanticShorthandCollection<BreadcrumbSectionProps>, ancestor) => {
      if (isPackage(ancestor) && ancestor.packageStatus) {
        acc.push({
          className: 'lp-search__results-items-cell--breadcrumb--section--ancestor',
          content: packageStatusDisplayNameByPackageStatus[ancestor.packageStatus],
          key: `${ancestor.packageStatus}${ancestor.id}`,
        });
      }

      acc.push({
        className: classNames(
          'lp-search__results-items-cell--breadcrumb-section',
          ancestor.id !== item.id && 'lp-search__results-items-cell--breadcrumb-section--ancestor'
        ),
        content: ancestor.name,
        key: ancestor.id,
        onClick: () => handleAncestorOnClick(ancestor),
      });

      return acc;
    }, []);

  return (
    <List.Item className="lp-search__results-items-row">
      <div className="lp-search__results-items-cell--count">{resultNumber.toString().padStart(2, '0')}</div>

      <div className="lp-search__results-items-row-icons">
        <ItemIcon itemId={item.id} />
        {showFolderStatusIcon && <FolderStatusIcon folderStatus={item.folderStatus} className="icon" />}
      </div>

      <Breadcrumb className="lp-search__results-items-cell--breadcrumb" sections={itemBreadcrumbSections()} />
    </List.Item>
  );
};

export default ResultItem;
