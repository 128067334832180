import { Dispatch, SetStateAction } from 'react';
import { Route, Switch } from 'react-router';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { DisconnectedUser } from 'features/authentication/authenticated/disconnected/index';
import { DisconnectedUserTopBar } from 'features/authentication/authenticated/disconnected/top_bar';
import { DisconnectedUserAccount } from 'features/authentication/authenticated/disconnected/user_account';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { useFrontloadData } from 'features/authentication/hooks/use_frontload_data';
import PageNotFound from 'features/common/errors/page_not_found';
import { frontend } from 'lib/urls';

interface AuthenticatedAndDisconnectedProps {
  setSelectedOrgId: Dispatch<SetStateAction<number | undefined>>;
  setSelectedWsId: Dispatch<SetStateAction<number | undefined>>;
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
}

export const AuthenticatedAndDisconnected = ({
  setSelectedOrgId,
  setSelectedWsId,
  setEntityNotFound,
}: AuthenticatedAndDisconnectedProps) => {
  const frontloadData = useFrontloadData({ setSelectedOrgId, setSelectedWsId, setEntityNotFound });

  return (
    <div className="disconnected-user">
      <div className="disconnected-user__frame">
        <DisconnectedUserTopBar />
        <div className="disconnected-user__content-section">
          <div className="disconnected-user__content">
            <Switch>
              <Route exact path={frontend.disconnected.pattern} component={DisconnectedUser} />
              <Route
                exact
                path={frontend.disconnectedRestartAccount.pattern}
                render={() => <DisconnectedUserAccount frontloadData={frontloadData} />}
              />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </div>

          <LpLegalTermsFooter />
        </div>
      </div>
    </div>
  );
};
