import classNames from 'classnames';
import { Breadcrumb, BreadcrumbSectionProps, SemanticShorthandItem } from 'semantic-ui-react';

import './lp_breadcrumb.scss';

interface LpBreadcrumbProps {
  sections: Array<SemanticShorthandItem<BreadcrumbSectionProps>>;
  className?: string;
}

const LpBreadcrumb = ({ sections, className }: LpBreadcrumbProps) => {
  if (!sections.length) {
    return null;
  }
  return <Breadcrumb size="huge" sections={sections} className={classNames('lp-breadcrumb', className)} />;
};

export default LpBreadcrumb;
