import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, Portal } from 'semantic-ui-react';
import { v4 as createUUID } from 'uuid';

import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

const ASYNC_COOKIE_CHECK_INTERVAL = 500;
const ASYNC_COOKIE_CHECK_RESET_INTERVAL = 60 * 1000;
const ASYNC_DOWNLOAD_COOKIE_PREFIX = 'lpn-download-complete';
const ASYNC_DOWNLOAD_UUID_PARAM = 'downloadCompleteUuid';

const getCookieForKey = (cookieKey: string) =>
  document.cookie.split(';').reduce((acc: { [key: string]: string | undefined }, cookie) => {
    const [key, value] = cookie.split('=');
    const trimmedKey = key?.trim();
    if (trimmedKey) {
      acc[trimmedKey] = value;
    }
    return acc;
  }, {})[cookieKey];

const deleteCookieForKey = (cookieKey: string) => {
  if (getCookieForKey(cookieKey)) {
    document.cookie = `${cookieKey}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
};

const detectQueryString = (url: string) => {
  const queryStringPattern = new RegExp(/\?.+=.*/g);
  return queryStringPattern.test(url);
};

interface AsyncDownloadButtonProps {
  className?: string;
  content: ReactNode;
  url: string;
}

export const AsyncDownloadButton = ({ className, content, url }: AsyncDownloadButtonProps) => {
  const [uuid, setUuid] = useState<string | undefined>(undefined);
  const [asyncInterval, setAsyncInterval] = useState<NodeJS.Timeout>();
  const [asyncTimer, setAsyncTimer] = useState<NodeJS.Timeout>();

  const clearAsyncTimer = useCallback(() => {
    if (asyncTimer) {
      clearTimeout(asyncTimer);
      setAsyncTimer(undefined);
    }
  }, [asyncTimer]);

  useEffect(() => {
    return () => {
      if (asyncInterval && uuid) {
        clearInterval(asyncInterval);
        setAsyncInterval(undefined);
      }
      clearAsyncTimer();
    };
  }, [asyncInterval, clearAsyncTimer, uuid]);

  const handleClick = () => {
    const uuid = createUUID();

    setUuid(uuid);

    window.location.href = url.concat(`${detectQueryString(url) ? '&' : '?'}${ASYNC_DOWNLOAD_UUID_PARAM}=${uuid}`);

    setAsyncInterval(
      setInterval(() => {
        const cookie = getCookieForKey(`${ASYNC_DOWNLOAD_COOKIE_PREFIX}-${uuid}`);
        if (cookie) {
          setUuid(undefined);
          deleteCookieForKey(`${ASYNC_DOWNLOAD_COOKIE_PREFIX}-${uuid}`);
          clearAsyncTimer();
        }
      }, ASYNC_COOKIE_CHECK_INTERVAL)
    );
    setAsyncTimer(
      setTimeout(() => {
        setUuid(undefined);
      }, ASYNC_COOKIE_CHECK_RESET_INTERVAL)
    );
  };

  return (
    <>
      <Portal open={!!uuid}>
        <LpOverlayLoader className="async-download-loader" content={`Preparing Download${HORIZONTAL_ELLIPSIS}`} />
      </Portal>

      <Button
        disabled={!!uuid}
        className={className}
        rel="noopener noreferrer"
        content={content}
        onClick={handleClick}
      />
    </>
  );
};
