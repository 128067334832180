import { ControlledAssignmentLinkRenderer, ControlledItemLinkRenderer } from 'containers/shared/item_link';
import { ASAPLabelRenderer } from 'containers/shared/notice_icons/asap_label';
import { DependencyLabel } from 'containers/shared/notice_icons/dependency_label';
import { RiskLabel } from 'containers/shared/notice_icons/risk_label';
import { SchedulingLimitsLabelRenderer } from 'containers/shared/notice_icons/scheduling_limits_label';
import { ItemType, ListWidgetType, ScheduleDirective, WidgetOnClickViewType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { getItemTypeForListWidgetType } from 'features/dashboards/widget_click_through/grids/helpers';
import { getItemIconForStatus } from 'features/dashboards_v2/widget/widgets/list/helpers';
import { DeprecatedItemRow } from 'features/dashboards_v2/widget/widgets/list/types';
import { ItemLabels, ItemLink, ItemRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { frontend } from 'lib/urls';

import './item_name_cell.scss';

const Labels = (props: ItemLabels) => {
  const { hasDependencies, hasSchedulingLimits, hasWorkLimitAlert, isLate, scheduleDirective } = props;

  const hasRushedStatus = scheduleDirective && scheduleDirective !== ScheduleDirective.NORMAL;
  return (
    <>
      {hasSchedulingLimits && <SchedulingLimitsLabelRenderer />}
      {hasDependencies && <DependencyLabel />}
      {(isLate || hasWorkLimitAlert) && <RiskLabel />}
      {hasRushedStatus && <ASAPLabelRenderer className={'icon notice asap'} scheduleDirective={scheduleDirective} />}
    </>
  );
};

export const ItemNameLink = ({
  widget,
  itemRow,
  customToURL,
}: {
  widget: WidgetWithData;
  itemRow: DeprecatedItemRow | ItemRow;
  customToURL?: string;
}) => {
  const { assignmentTaskName, itemStatus, itemName } = itemRow;

  const onClickViewAwareToUrl = getOnClickViewAwareItemLinkToURL(widget, itemRow.itemLink);
  const toURL = customToURL ?? onClickViewAwareToUrl;
  const disabled = !(onClickViewAwareToUrl || customToURL);

  const isAssignment = widget.config.listType === ListWidgetType.Assignment;
  const itemLabels = itemRow.itemLabels;
  const itemIcon = getItemIconForStatus(itemRow.itemStatus);
  const taskStatusHexColor = itemStatus.color ? `#${itemStatus.color}` : undefined;

  return (
    <div className="item-list-row">
      {isAssignment ? (
        <ControlledAssignmentLinkRenderer
          assignmentName={itemName ?? ''}
          className="item-list-row__name"
          disabled={disabled}
          isDone={!!itemStatus.assignmentDone}
          taskName={assignmentTaskName ?? ''}
          taskSchedulingType={itemStatus.taskSchedulingType}
          taskStatusHexColor={taskStatusHexColor}
          to={toURL}
        />
      ) : (
        <ControlledItemLinkRenderer
          className="item-list-row__name"
          disabled={disabled}
          itemIcon={itemIcon}
          itemName={itemName ?? ''}
          to={toURL}
        />
      )}

      {itemLabels && (
        <div className="item-list-row__labels">
          <Labels {...itemLabels} />
        </div>
      )}
    </div>
  );
};

export function getOnClickViewAwareItemLinkToURL(widget: WidgetWithData, itemLink: ItemLink | null): string {
  const {
    organizationId,
    workspaceId,
    config: { onClickView },
  } = widget;

  if (!itemLink) {
    return '';
  }

  const { itemId, customFieldNoteName } = itemLink;

  const commonUrlParams = {
    itemId: itemId ?? 0,
    organizationId,
    workspaceId,
    dashboardId: widget.dashboardId,
  };

  switch (onClickView) {
    case WidgetOnClickViewType.Board:
      return frontend.portableBoard.url(commonUrlParams);
    case WidgetOnClickViewType.Changes:
      return frontend.portableChanges.url(commonUrlParams);
    case WidgetOnClickViewType.Dashboard:
      return frontend.portableDashboard.url(commonUrlParams);
    case WidgetOnClickViewType.Grid:
      return frontend.portableGrid.url(commonUrlParams);
    case WidgetOnClickViewType.ItemPanelChanges:
      return `#panelId=${itemId}&panelSection=${TabNames.Changes}Tab`;
    case WidgetOnClickViewType.ItemPanelAssignments:
      return `#panelId=${itemId}&panelSection=${TabNames.Assignments}Tab`;
    case WidgetOnClickViewType.ItemPanelPlanning:
      return `#panelId=${itemId}&panelSection=${TabNames.Planning}Tab`;
    case WidgetOnClickViewType.ItemPanelProperties:
      return `#panelId=${itemId}&panelSection=${TabNames.Properties}Tab`;
    case WidgetOnClickViewType.ItemPanelNotes:
      return `#panelId=${itemId}&panelSection=${customFieldNoteName}Tab`;
    case WidgetOnClickViewType.ItemPanelSchedule:
      return `#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`;
    case WidgetOnClickViewType.Schedule:
      return frontend.portableSchedule.url(commonUrlParams);
    case WidgetOnClickViewType.Workload:
      return frontend.portableWorkload.url(commonUrlParams);
    default:
      return '';
  }
}

export function getItemPanelAwareItemLinkToURL({
  itemId,
  tabName,
  parentId,
  widget,
}: {
  itemId: number;
  tabName: TabNames;
  parentId: number;
  widget: WidgetWithData;
}): string {
  const organizationId = widget.organizationId;
  const workspaceId = widget.workspaceId;

  const itemType = getItemTypeForListWidgetType(widget.config.listType);

  switch (itemType) {
    case ItemType.PACKAGES:
      return frontend.singlePackage.url({ organizationId, workspaceId, packageId: itemId });
    case ItemType.PROJECTS:
    case ItemType.FOLDERS:
      return frontend.projectProject.url({ itemId, organizationId, workspaceId });
    case ItemType.TASKS:
      return `#panelId=${itemId}&panelSection=${tabName}Tab`;
    case ItemType.ASSIGNMENTS:
      return `#panelId=${parentId}&panelSection=${tabName}Tab`;
    default:
      return '';
  }
}
