import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import {
  HiddenImageUploadLabel,
  ImageUploadIcon,
  PreviewImage,
} from 'features/common/forms/formik/image_upload/preview_image';

import { useImageUpload } from './use_file_upload';

import './image_upload.scss';

interface LpFormikImageUploadProps {
  fieldName: string;
}

export const LpFormikImageUpload = ({ fieldName }: LpFormikImageUploadProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const { getFieldProps, setFieldValue } = useFormikContext();
  const fieldProps = getFieldProps(fieldName);

  const imageS3Id = fieldProps.value;
  const noImageSelected = !imageS3Id;

  const setS3IdUrl = (s3Id: string | null) => setFieldValue(fieldName, s3Id);

  const { handleImageUpload } = useImageUpload(setS3IdUrl);

  const handleRemoveImage = () => {
    setFieldValue(fieldName, '');
  };

  const imageUrl = imageS3Id ? backend.dashboardImage.url({ organizationId, workspaceId, s3Id: imageS3Id }) : '';

  const imageContent = noImageSelected ? (
    <HiddenImageUploadLabel labelContent={<ImageUploadIcon text="Select an image" />} onUpload={handleImageUpload} />
  ) : (
    <PreviewImage imageUrl={imageUrl} onRemove={handleRemoveImage} onUpload={handleImageUpload} />
  );

  return (
    <div
      className={classNames({
        'image-upload': true,
        'image-upload--empty': noImageSelected,
        'image-upload--update': !!imageS3Id,
      })}
    >
      {imageContent}
    </div>
  );
};
