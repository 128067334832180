import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationDao } from 'daos/organization';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { awaitRequestFinish } from 'lib/api';

const useOrganizationFetchOnOrganizationModified = (includePlan?: boolean) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const fetchOrganization = useCallback(() => {
    const { uuid } = dispatch(OrganizationDao.fetch({ organizationId }, { include: { includePlan } }));
    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch, includePlan, organizationId]);

  useEvents({
    event: Events.ORGANIZATION_MODIFIED,
    callback: fetchOrganization,
    scope: EventScopes.None,
  });
};

export default useOrganizationFetchOnOrganizationModified;
