export enum TabNames {
  MemberAccess = 'Member Access',
  Properties = 'Properties',
  Files = 'Files',
  Planning = 'Planning',
  Assignments = 'Assignments',
  Scheduling = 'Scheduling',
  Changes = 'Changes',
  Discussions = 'Discussions',
}
