import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useParams } from 'react-router';

import LpLink from 'containers/shared/lp_link';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { idCardLight, LpIcon } from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationUser, getOrganizationUserForId } from 'redux/entities/selectors/user';

export const OrgDirUsersTopNav = () => {
  const { organizationUserId: organizationUserIdString } = useParams<{ organizationUserId: string }>();
  const profileUserId = Number(organizationUserIdString);
  const profileUser = useSelector((state) => getOrganizationUserForId(state, profileUserId));

  const pathname = useLocation().pathname;
  const isUserInviteRoute = matchPath(pathname, {
    path: [frontend.organizationHubUsersInvite.pattern],
    exact: true,
  });

  const organizationId = useSelector(getCurrentOrganizationId);
  const organizationName = useSelector(getCurrentOrganization)?.name ?? '';
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const isAdmin = useSelector(getCurrentOrganizationUser)?.admin;

  const breadcrumbSections: Array<{ key: string; content: ReactNode | NavigationMenuDisplay }> = [
    {
      key: organizationName,
      content: (
        <span>
          <LpIcon icon={idCardLight} />{' '}
          <LpLink to={frontend.workspaceHub.url({ organizationId, workspaceId })}>{organizationName}</LpLink>
        </span>
      ),
    },
  ];

  if (profileUser) {
    breadcrumbSections.push(
      {
        key: 'all-users',
        content: (
          <LpLink to={frontend.organizationHubUsers.url({ organizationId })}>
            {NavigationMenuDisplay.OrganizationUsersBreadcrumb}
          </LpLink>
        ),
      },
      {
        key: 'user-profile',
        content: <>{profileUser.username}</>,
      }
    );
    return <TopNavHeader breadcrumb={<LpBreadcrumb sections={breadcrumbSections} />} />;
  }

  if (isUserInviteRoute) {
    breadcrumbSections.push(
      {
        key: 'all-users',
        content: (
          <LpLink to={frontend.organizationHubUsers.url({ organizationId })}>
            {NavigationMenuDisplay.OrganizationUsersBreadcrumb}
          </LpLink>
        ),
      },
      {
        key: 'user-invite',
        content: NavigationMenuDisplay.Invite,
      }
    );
    return <TopNavHeader breadcrumb={<LpBreadcrumb sections={breadcrumbSections} />} />;
  }

  const tabs = [
    {
      content: NavigationMenuDisplay.LicensedUsers,
      to: frontend.organizationHubUsers.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.organizationHubUsers.pattern],
        exact: true,
      }),
    },
    {
      content: NavigationMenuDisplay.DashboardGuests,
      to: frontend.organizationHubUsersDashboardGuests.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.organizationHubUsersDashboardGuests.pattern],
      }),
    },
    {
      content: NavigationMenuDisplay.Disconnected,
      to: frontend.organizationHubUsersDisconnected.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.organizationHubUsersDisconnected.pattern],
      }),
    },
    {
      content: NavigationMenuDisplay.PendingInvites,
      to: frontend.organizationHubUsersPendingInvites.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.organizationHubUsersPendingInvites.pattern],
      }),
    },
  ];

  if (isAdmin) {
    tabs.splice(1, 0, {
      content: NavigationMenuDisplay.Admins,
      to: frontend.organizationHubUsersAdmins.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.organizationHubUsersAdmins.pattern],
      }),
    });
  }

  breadcrumbSections.push({
    key: 'all-users',
    content: NavigationMenuDisplay.OrganizationUsersBreadcrumb,
  });

  return <TopNavHeader breadcrumb={<LpBreadcrumb sections={breadcrumbSections} />} menuTabs={tabs} />;
};
