import { useEffect, useState } from 'react';

import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

import 'features/common/loaders/lp_item_panel_loader.scss';

const GRID_ITEM_LENGTH = 9;

const animationGridItem = Array(GRID_ITEM_LENGTH)
  .fill(undefined)
  .map((_, item) => (
    <div
      className={`lp-loader-item-panel__grid-item lp-loader-item-panel__grid-item-${item}`}
      key={`animation-grid-item-${item}`}
    />
  ));

interface LpLoaderAnimatedGridProps {
  label?: string;
  delayDurationMS?: number;
}
const LpLoaderAnimatedGrid = ({
  label = `Looking up your item${HORIZONTAL_ELLIPSIS}`,
  delayDurationMS = 100,
}: LpLoaderAnimatedGridProps) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, delayDurationMS);

    return () => clearTimeout(timer);
  }, [delayDurationMS]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div className="lp-loader-item-panel">
      <div className="lp-loader-item-panel__grid">{animationGridItem}</div>
      {label}
    </div>
  );
};

export default LpLoaderAnimatedGrid;
