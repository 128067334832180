import { matchPath } from 'react-router';

import { ReadonlyRecord } from 'lib/readonly_record';
import { frontend } from 'lib/urls';

import { WorkspaceLeftNavMenuUrlKeys } from './enums';
import { LeftNavMenuItems } from './types';

export const workspaceLeftNavPaths: ReadonlyRecord<WorkspaceLeftNavMenuUrlKeys, Array<string>> = {
  [WorkspaceLeftNavMenuUrlKeys.PremiumFeaturesTrial]: [frontend.organizationHubUpgrade.pattern],
  [WorkspaceLeftNavMenuUrlKeys.UltimateTrial]: [frontend.organizationHubUpgrade.pattern],
  [WorkspaceLeftNavMenuUrlKeys.DashboardPassport]: [
    frontend.dashboardPassports.pattern,
    frontend.dashboardGuest.pattern,
  ],
  [WorkspaceLeftNavMenuUrlKeys.Academy]: [frontend.academy.pattern],
  [WorkspaceLeftNavMenuUrlKeys.Iterations]: [frontend.iterationsPage.pattern],
  [WorkspaceLeftNavMenuUrlKeys.Projects]: [
    frontend.projects.pattern,
    frontend.scheduledCollection.pattern,
    frontend.templateCollection.pattern,
    frontend.archiveCollection.pattern,
    frontend.pendingCollection.pattern,
    frontend.capacityCollection.pattern,
    frontend.package.pattern,
    frontend.project.pattern,
  ],
  [WorkspaceLeftNavMenuUrlKeys.People]: [frontend.people.pattern],
  [WorkspaceLeftNavMenuUrlKeys.Library]: [
    frontend.workspaceLibrary.pattern,
    frontend.workspaceDashboard.pattern,
    frontend.workspaceReport.pattern,
  ],
  [WorkspaceLeftNavMenuUrlKeys.Administration]: [frontend.organization.pattern, frontend.workspace.pattern],
  [WorkspaceLeftNavMenuUrlKeys.MyWork]: [frontend.myWork.pattern],
  [WorkspaceLeftNavMenuUrlKeys.MyDiscussions]: [frontend.myDiscussions.pattern],
  [WorkspaceLeftNavMenuUrlKeys.Favorites]: [frontend.favorites.pattern],
};

export const isPathMatching = (pathname: string, urls: Array<string>) =>
  !!matchPath(pathname, {
    path: urls,
  });

export const getActiveLeftNavTab = ({
  path,
  key,
  pathname,
}: {
  path: Array<string> | undefined;
  key: WorkspaceLeftNavMenuUrlKeys;
  pathname: string;
}) =>
  !!matchPath(pathname, {
    path: path ?? workspaceLeftNavPaths[key],
  });

export const conditionallyAddTab = (tabInfo: LeftNavMenuItems, shouldShow = true) => {
  return shouldShow ? [tabInfo] : [];
};

const nonAdministrationUrlKeys: Array<WorkspaceLeftNavMenuUrlKeys> = [
  WorkspaceLeftNavMenuUrlKeys.Academy,
  WorkspaceLeftNavMenuUrlKeys.PremiumFeaturesTrial,
  WorkspaceLeftNavMenuUrlKeys.Iterations,
  WorkspaceLeftNavMenuUrlKeys.Projects,
  WorkspaceLeftNavMenuUrlKeys.People,
  WorkspaceLeftNavMenuUrlKeys.Library,
  WorkspaceLeftNavMenuUrlKeys.MyWork,
  WorkspaceLeftNavMenuUrlKeys.MyDiscussions,
  WorkspaceLeftNavMenuUrlKeys.Favorites,
];

export const isAdministrationTabActive = (pathname: string) => {
  const nonAdminActivePaths = nonAdministrationUrlKeys.filter((urlKey) => {
    return !!matchPath(pathname, {
      path: workspaceLeftNavPaths[urlKey],
    });
  });

  const isNonAdminPathActive = nonAdminActivePaths.length > 0;

  return !isNonAdminPathActive;
};
