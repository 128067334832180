import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { PackageStatus } from 'daos/enums';
import { Item, LibraryResource } from 'daos/model_types';

import { LibraryDeleteModalProps, LibraryLocation } from './types';

interface LibraryContextProps {
  showDeleteModal: boolean;
  deleteModalData: LibraryDeleteModalProps;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  setDeleteModalData: Dispatch<SetStateAction<LibraryDeleteModalProps>>;
  tableRowLibraryResources: ReadonlyArray<LibraryResource>;
  tableRowsLibraries: ReadonlyArray<Item>;
  libraryLocation: LibraryLocation;
  itemId: number | undefined;
  itemName: string;
  fetchLibraryViewData: (input: string) => void;
  handleInputChange: (value: string) => void;
  packageStatus: PackageStatus | undefined;
  inputValue: string;
}
export const LibraryContext = createContext<LibraryContextProps>({
  showDeleteModal: false,
  deleteModalData: { content: null, deleteAction: noop },
  setDeleteModalData: noop,
  setShowDeleteModal: noop,
  tableRowLibraryResources: [],
  tableRowsLibraries: [],
  libraryLocation: LibraryLocation.Workspace,
  itemId: undefined,
  itemName: '',
  fetchLibraryViewData: noop,
  packageStatus: undefined,
  handleInputChange: noop,
  inputValue: '',
});

export function useLibraryContext() {
  return useContext(LibraryContext);
}
