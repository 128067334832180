import { noop } from 'lodash';
import { RefObject } from 'react';
import { Segment } from 'semantic-ui-react';

import { EventScopes } from 'features/common/events/types';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { GuestTaskStatusColumn } from 'features/common/task_board/guest/column';
import { GuestTaskBoardTaskStatus, GuestTasksByStatusId } from 'features/common/task_board/guest/types';
import { TaskBoardHeader } from 'features/common/task_board/task_board_header';

import 'features/common/task_board/task_board.scss';

export const GuestTaskBoard = ({
  fetchComplete,
  inputValue,
  setDebouncedValue,
  taskStatuses,
  tasksByStatusId,
}: {
  fetchComplete: boolean;
  inputValue: string;
  setDebouncedValue: RefObject<(value: string) => void>;
  taskStatuses: ReadonlyArray<GuestTaskBoardTaskStatus>;
  tasksByStatusId: GuestTasksByStatusId;
}) => {
  return (
    <>
      <TaskBoardHeader
        eventScope={EventScopes.WidgetTaskBoard}
        handleHideEmptyClick={noop}
        hideEmpty={true}
        inputValue={inputValue}
        isGuest={true}
        setDebouncedValue={setDebouncedValue}
      />

      {fetchComplete ? (
        <div className="lp-task-board__container">
          <Segment basic className="lp-task-board">
            {taskStatuses.map((ts) => (
              <GuestTaskStatusColumn key={ts.id} tasks={tasksByStatusId[ts.id] ?? []} taskStatus={ts} />
            ))}
          </Segment>
        </div>
      ) : (
        <LpOverlayLoader />
      )}
    </>
  );
};
