import classNames from 'classnames';
import { noop } from 'lodash';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { Menu } from 'semantic-ui-react';

import { StatusFilterGroups, WorkloadDayRange } from 'daos/enums';
import { LocationFilterAssigneeSettings } from 'daos/types';
import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';
import { CustomItemFilterLocation } from 'features/common/custom_item_filter/types';
import { AssignedFilterDropdown } from 'features/common/inputs/dropdowns/assigned_filter_dropdown';
import { FolderStatusFilterDropdown } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import {
  useMapOrgUsersAndGroupsToDropdownOptions,
  useMapOrgUsersForGroupIdToDropdownOptions,
} from 'features/common/inputs/dropdowns/hooks/use_map_org_users_and_groups_to_options';
import { TaskStatusFilterDropdown } from 'features/common/inputs/dropdowns/task_status_filter_dropdown';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import { isWorkloadFilterFiltered } from 'features/common/workload/shared';

import { WorkloadDayRangeDropdown } from './workload_day_range_dropdown';

interface AssignedDropdownProps {
  className: string;
  onChange: (newValue: Partial<LocationFilterAssigneeSettings>) => void;
  value: LocationFilterAssigneeSettings;
}
const ItemAssignedDropdown = ({ className, onChange, value }: AssignedDropdownProps) => {
  const options = useMapOrgUsersAndGroupsToDropdownOptions();

  return <AssignedFilterDropdown className={className} onChange={onChange} options={options} value={value} />;
};

interface GroupAssignedDropdownProps extends AssignedDropdownProps {
  groupId: number;
}
const GroupAssignedDropdown = ({ className, groupId, onChange, value }: GroupAssignedDropdownProps) => {
  const options = useMapOrgUsersForGroupIdToDropdownOptions(groupId);

  return <AssignedFilterDropdown className={className} onChange={onChange} options={options} value={value} />;
};

interface WorkloadTopControlsProps {
  assignedFilterValue?: LocationFilterAssigneeSettings;
  expandAllUsers: () => void;
  groupId?: number;
  handleAssignedFilterValueChange?: (newValue: Partial<LocationFilterAssigneeSettings>) => void;
  includeProjectFilter?: boolean;
  inputValue: string;
  isWidget?: boolean;
  setDebouncedValue: RefObject<(value: string) => void>;
  dayRange: WorkloadDayRange;
  setDayRange: Dispatch<SetStateAction<WorkloadDayRange>>;
}
export const WorkloadTopControls = ({
  assignedFilterValue,
  expandAllUsers,
  groupId,
  handleAssignedFilterValueChange = noop,
  includeProjectFilter = true,
  inputValue,
  isWidget = false,
  setDebouncedValue,
  dayRange,
  setDayRange,
}: WorkloadTopControlsProps) => {
  const handleFilterInputChange = (value: string) => {
    setDebouncedValue.current?.(value);
    expandAllUsers();
  };

  return (
    <Menu borderless className="lp-portfolio-grid-top-controls" secondary>
      {isWidget && (
        <Menu.Item fitted>
          <GoBackToDashboardButton />
        </Menu.Item>
      )}
      <Menu.Item fitted>
        <WorkloadDayRangeDropdown dayRange={dayRange} setDayRange={setDayRange} />
      </Menu.Item>
      <Menu.Item fitted>
        <LpSearchInputUncontrolled initialValue={inputValue} onChange={handleFilterInputChange} />
      </Menu.Item>
      {!isWidget && (
        <Menu.Menu position="right">
          <Menu.Item fitted>
            <TaskStatusFilterDropdown
              customItemFilterLocation={CustomItemFilterLocation.Workload}
              dataIsAllActive
              defaultTaskStatus={StatusFilterGroups.Active}
            />
          </Menu.Item>
          {includeProjectFilter && (
            <Menu.Item fitted>
              <FolderStatusFilterDropdown
                customItemFilterLocation={CustomItemFilterLocation.Workload}
                dataIsAllActive
                defaultFolderStatus={FolderFilterStatus.Scheduled}
              />
            </Menu.Item>
          )}
          {!!assignedFilterValue && (
            <Menu.Item fitted>
              {groupId ? (
                <GroupAssignedDropdown
                  className={classNames({
                    'v2-dashboard-filter__assigned-filter': true,
                    'v2-dashboard-filter__assigned-filter--filtered': isWorkloadFilterFiltered(assignedFilterValue),
                  })}
                  groupId={groupId}
                  onChange={handleAssignedFilterValueChange}
                  value={assignedFilterValue}
                />
              ) : (
                <ItemAssignedDropdown
                  className={classNames({
                    'v2-dashboard-filter__assigned-filter': true,
                    'v2-dashboard-filter__assigned-filter--filtered': isWorkloadFilterFiltered(assignedFilterValue),
                  })}
                  onChange={handleAssignedFilterValueChange}
                  value={assignedFilterValue}
                />
              )}
            </Menu.Item>
          )}
        </Menu.Menu>
      )}
    </Menu>
  );
};
