import { createSelector } from 'reselect';

import { ColumnIndex, FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { getUserColumns } from 'lib/helpers/user_custom_columns';
import { readonlyArray } from 'lib/readonly_record';
import { getCurrentWorkspaceUserSettings } from 'redux/entities/selectors/user_workspace_settings';
import { RootState } from 'redux/root_reducer';

export const getProjectHubContainerId = ({ projectHub }: RootState) => projectHub.containerId;

export const getProjectHubProjectId = ({ projectHub }: RootState) => projectHub.projectId;

export const getProjectHubCurrentPackageStatus = ({ projectHub }: RootState) => projectHub.currentPackageStatus;

export const getPrioritizedProjectHubCurrentItemIds = createSelector(
  ({ projectHub }: RootState) => projectHub.currentItemIds,
  (currentItemIds) => currentItemIds ?? readonlyArray([])
);

export const getProjectHubUserSelectableColumns = createSelector(
  ({ projectHub }: RootState) => projectHub.userSelectableColumns,
  getProjectHubCurrentPackageStatus,
  getCurrentWorkspaceUserSettings,
  (userSelectableColumns, packageStatus, currentWorkspaceUserSettings) =>
    getUserColumns('Project', userSelectableColumns, packageStatus, currentWorkspaceUserSettings)
);

export const getProjectHubUserSelectableColumnOptionForIndex = createSelector(
  getProjectHubUserSelectableColumns,
  (_: RootState, columnIndex: ColumnIndex) => columnIndex,
  (columns, columnIndex) => columns[columnIndex]?.columnOption ?? StandardColumns.Assigned
);

export const getProjectHubUserSelectableColumnFieldIdForIndex = createSelector(
  getProjectHubUserSelectableColumns,
  (_: RootState, columnIndex: ColumnIndex) => columnIndex,
  (columns, columnIndex) => {
    const column = columns[columnIndex];

    if (column?.columnOption === StandardColumns.CustomField) {
      return column.fieldId;
    }
  }
);

export const getProjectHubSelectedColumnNameForIndex = createSelector(
  (state: RootState) => state.entities.fields,
  (_: RootState, columnIndex: ColumnIndex) => columnIndex,
  getProjectHubUserSelectableColumns,
  (fieldsById, columnIndex, userSelectedColumns) => {
    const column = userSelectedColumns[columnIndex];

    if (!column) {
      return FrontendColumns.Empty;
    }

    if (column.columnOption !== StandardColumns.CustomField) {
      return column.columnOption;
    }

    const field = fieldsById[column.fieldId];
    return field?.fieldType ?? FrontendColumns.Empty;
  }
);

export const getProjectHubItemIds = createSelector(
  getProjectHubContainerId,
  getProjectHubProjectId,
  getPrioritizedProjectHubCurrentItemIds,
  (containerId, projectId, itemIds) => {
    const ids = [...itemIds, containerId, projectId];

    //filter out the undefined values; filter does not change the return type, but reduce does
    return readonlyArray(
      ids.reduce((acc: Array<number>, id) => {
        if (id) {
          acc.push(id);
        }
        return acc;
      }, [])
    );
  }
);
