import { useSelector } from 'react-redux';

import { LpIcon, exclamationTriangleSolid } from 'features/common/lp_icon';
import {
  getItemTargetStartDeltaDaysForItemId,
  getItemTargetFinishDeltaDaysForItemId,
} from 'redux/entities/selectors/item';
import { lpRed } from 'style/variables';

const ScheduleDeltaAlertIcon = () => (
  <LpIcon className="warning-icon" color={lpRed} icon={exclamationTriangleSolid} size="sm" />
);

export const ScheduleDeltaRenderer = ({
  delta,
  includeAlertIcon = false,
  notApplicableText = 'N/A',
  valueOnly = false,
}: {
  delta: number | undefined;
  includeAlertIcon?: boolean;
  notApplicableText?: string;
  valueOnly?: boolean;
}) => {
  if (delta === undefined) {
    return <>{notApplicableText}</>;
  }

  const optionalAlertIcon = delta > 0 && includeAlertIcon ? <ScheduleDeltaAlertIcon /> : null;

  if (valueOnly) {
    return (
      <>
        {optionalAlertIcon}
        {delta}d
      </>
    );
  }

  const absoluteDelta = Math.abs(delta);
  const label = absoluteDelta === 1 ? 'day' : 'days';

  if (delta <= 0) {
    return (
      <>
        {absoluteDelta} {label} early
      </>
    );
  }

  return (
    <>
      {optionalAlertIcon}
      {delta} {label} late
    </>
  );
};

export const TargetStartDelta = ({
  includeAlertIcon,
  itemId,
  notApplicableText,
  valueOnly,
}: {
  includeAlertIcon?: boolean;
  itemId: number;
  notApplicableText?: string;
  valueOnly?: boolean;
}) => {
  const targetStartDeltaDays = useSelector((state) => getItemTargetStartDeltaDaysForItemId(state, itemId));

  return (
    <ScheduleDeltaRenderer
      delta={targetStartDeltaDays}
      includeAlertIcon={includeAlertIcon}
      notApplicableText={notApplicableText}
      valueOnly={valueOnly}
    />
  );
};

export const TargetFinishDelta = ({
  itemId,
  includeAlertIcon,
  notApplicableText,
  valueOnly,
}: {
  itemId: number;
  includeAlertIcon?: boolean;
  notApplicableText?: string;
  valueOnly?: boolean;
}) => {
  const targetFinishDeltaDays = useSelector((state) => getItemTargetFinishDeltaDaysForItemId(state, itemId));

  return (
    <ScheduleDeltaRenderer
      delta={targetFinishDeltaDays}
      includeAlertIcon={includeAlertIcon}
      notApplicableText={notApplicableText}
      valueOnly={valueOnly}
    />
  );
};
