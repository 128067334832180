import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MultiSelectModal from 'containers/shared/multi_select_modal';
import { resetAvailableOptions } from 'containers/shared/multi_select_modal/multi_select_helpers';
import { OrganizationSsoUserFilter } from 'daos/filter_properties';
import { OrganizationSsoUsersDao, OrganizationSsoUser } from 'daos/organization_sso_users';
import { filterAnd, filterNull } from 'daos/shared';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { SelectionList } from 'features/common/selection_list/types';
import { awaitRequestFinish } from 'lib/api';

import { getSsoUserListPayload } from './helpers';
import { useGroupOrgUsersByAvailableAndSelected } from './hooks/use_group_org_users_by_selected_and_available';

interface ManageSsoUserModalProps {
  closeModal: () => void;
  multiOrgUsersIds: ReadonlyArray<number>;
  isNewMembersDefaultEnabled: boolean;
}

export const ManageSsoUserModal = ({
  closeModal,
  isNewMembersDefaultEnabled,
  multiOrgUsersIds,
}: ManageSsoUserModalProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const [ssoUsers, setSsoUsers] = useState<ReadonlyArray<OrganizationSsoUser> | undefined>(undefined);

  const fetchSSOUsers = useCallback(() => {
    const { uuid } = dispatch(
      OrganizationSsoUsersDao.fetchCurrentOrgUsers(
        { organizationId },
        {
          filter: filterAnd(filterNull(OrganizationSsoUserFilter.DisconnectedAt, true)),
          include: { includeOrganizationUser: true },
        }
      )
    );

    dispatch(
      awaitRequestFinish<ReadonlyArray<OrganizationSsoUser>>(uuid, {
        onSuccess: ({ data }) => {
          setSsoUsers([...data]);
        },
      })
    );
  }, [dispatch, organizationId]);

  useEffect(() => {
    fetchSSOUsers();
  }, [fetchSSOUsers]);

  const { available, selected } = useGroupOrgUsersByAvailableAndSelected(
    ssoUsers,
    isNewMembersDefaultEnabled,
    multiOrgUsersIds
  );

  const availableColumnsAfterReset = resetAvailableOptions([...selected, ...available], selected);

  if (!ssoUsers) {
    return <LpOverlayLoader />;
  }

  const handleSsoUserExceptions = (available: Array<SelectionList>, selected: Array<SelectionList>) => {
    const ssoUserEnabled = getSsoUserListPayload({
      ssoUsers,
      selectionList: available,
      organizationId,
      ssoEnabled: true,
    });
    const ssoUserDisabled = getSsoUserListPayload({
      ssoUsers,
      selectionList: selected,
      organizationId,
      ssoEnabled: false,
    });

    const { uuid } = dispatch(
      OrganizationSsoUsersDao.upsert({ organizationId }, [...ssoUserEnabled, ...ssoUserDisabled])
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => closeModal(),
      })
    );
  };

  return (
    <MultiSelectModal
      closeModal={closeModal}
      initAvailableOptions={available}
      initSelectedOptions={selected}
      onCloseAction={handleSsoUserExceptions}
      resetColumnsOptions={{
        selected: selected,
        available: availableColumnsAfterReset,
      }}
      selectedTitle="SSO Disabled (Login with email/password)"
      availableTitle="SSO Enabled"
      dragAndDropEnabled={false}
    />
  );
};
