import { WidgetType, widgetTypeDisplay } from 'daos/enums';
import { ballPileSolid, LpIcon } from 'features/common/lp_icon';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { LocationFilters } from 'features/dashboards_v2/widget_settings/location_filters';
import { PreviewRows } from 'features/dashboards_v2/widget_settings/preview_rows_and_columns_dropdown';
import { Title } from 'features/dashboards_v2/widget_settings/title';

export const WorkloadSettings = ({ widgetId }: { widgetId: number }) => {
  const header = (
    <>
      <LpIcon icon={ballPileSolid} /> {widgetTypeDisplay[WidgetType.Workload]}
    </>
  );

  const content = (
    <>
      <Title widgetId={widgetId} />
      <LocationSetting />
      <LocationFilters dataIsAllActive widgetId={widgetId} />
      <PreviewRows />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
