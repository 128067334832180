import { OrganizationLinkCrumb } from 'features/common/breadcrumbs/breadcrumb_helpers';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { codeSolid } from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';

export const ApiHeader = () => {
  const sections = [
    {
      key: 'organization-name',
      content: <OrganizationLinkCrumb icon={codeSolid} />,
    },
    {
      key: NavigationMenuDisplay.Api,
      content: NavigationMenuDisplay.Api,
    },
  ];

  return <TopNavHeader breadcrumb={<LpBreadcrumb sections={sections} />} />;
};
