import classnames from 'classnames';

import { WidgetDataErrorType } from 'daos/enums';
import { exclamationTriangleSolid, LpIcon } from 'features/common/lp_icon';
import { orange500 } from 'style/variables';

const widgetCanRenderWithError = [
  WidgetDataErrorType.INTAKE_CUSTOM_FIELD_MISSING,
  WidgetDataErrorType.INTAKE_TEMPLATE_MISSING,
];

export const canRenderWidgetWithError = (widgetErrors: ReadonlyArray<WidgetDataErrorType>) => {
  const canRenderWithWidgetError = widgetErrors.every((error) => widgetCanRenderWithError.includes(error));
  return canRenderWithWidgetError;
};

export const WidgetDataErrorWarningLabel = ({
  className,
  widgetDataErrorType,
}: {
  className?: string;
  widgetDataErrorType?: WidgetDataErrorType | null;
}) => {
  switch (widgetDataErrorType) {
    case WidgetDataErrorType.LOCATION_FILTER_ERROR:
      return <LocationFilterErrorLabel className={className} />;
    case WidgetDataErrorType.ASSIGNED_FILTER_ERROR:
      return <AssignedFilterErrorLabel className={className} />;
    case WidgetDataErrorType.NO_METRICS_SELECTED_ERROR:
      return <NoMetricsSelectedErrorLabel className={className} />;
    case WidgetDataErrorType.NO_PROPERTIES_SELECTED_ERROR:
      return <NoPropertiesSelectedErrorLabel className={className} />;
    case WidgetDataErrorType.DATA_NOT_CONFIGURED:
    case WidgetDataErrorType.INTAKE_PARENT_MISSING:
    case WidgetDataErrorType.DATA_RETRIEVAL_ERROR:
      return <WidgetIntakeSetupErrorWarningLabel className={className} />;
    default:
      return null;
  }
};

export const GeneralWidgetDataErrorWarningLabel = ({ className }: { className?: string }) => (
  <BaseWidgetDataErrorWarningLabel className={className} label="Configuration needs attention" />
);

const WidgetIntakeSetupErrorWarningLabel = ({ className }: { className?: string }) => (
  <BaseWidgetDataErrorWarningLabel className={className} label="Widget setup needs attention" />
);

export const LocationFilterErrorLabel = ({ className }: { className?: string }) => (
  <BaseWidgetDataErrorWarningLabel className={className} label="Scope needs attention" />
);

export const AssignedFilterErrorLabel = ({ className }: { className?: string }) => (
  <BaseWidgetDataErrorWarningLabel className={className} label="Assigned filter needs attention" />
);

const NoMetricsSelectedErrorLabel = ({ className }: { className?: string }) => (
  <BaseWidgetDataErrorWarningLabel className={className} label="No metrics selected" />
);

const NoPropertiesSelectedErrorLabel = ({ className }: { className?: string }) => (
  <BaseWidgetDataErrorWarningLabel className={className} label="No properties selected" />
);

const BaseWidgetDataErrorWarningLabel = ({ className, label }: { className?: string; label: string }) => {
  return (
    <div className={classnames(className)}>
      <LpIcon icon={exclamationTriangleSolid} color={orange500} /> {label}
    </div>
  );
};
