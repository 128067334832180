import { createSelector } from 'reselect';

import { RootState } from 'redux/root_reducer';

const getItemDuplicationStatusByItemId = (state: RootState) => state.duplicate;

export const getIsItemDuplicationInProgressForItemId = (state: RootState, itemId: number) =>
  !!getItemDuplicationStatusByItemId(state)[itemId];

export const getIsAnyItemDuplicationInProgress = createSelector(
  getItemDuplicationStatusByItemId,
  (duplicationItemIds) => Object.values(duplicationItemIds).some((item) => !!item)
);
