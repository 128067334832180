import { useFormikContext } from 'formik';
import { Button } from 'semantic-ui-react';

import { LpButtonAsLink } from 'features/common/as_components';
import { useGoBackToDashboardUrl } from 'features/dashboards_v2/common/use_go_back_to_dashboard_url';

export const IntakeFormButtons = () => {
  const backToDashboardUrl = useGoBackToDashboardUrl();
  const { isSubmitting, submitForm } = useFormikContext();

  return (
    <div className="intake-form__body-buttons">
      <LpButtonAsLink disabled={isSubmitting} to={backToDashboardUrl}>
        Cancel
      </LpButtonAsLink>
      <Button primary disabled={isSubmitting} onClick={submitForm} content="Submit Form" />
    </div>
  );
};
