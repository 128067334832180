import { useLocation } from 'react-router';

import { WorkspaceLeftNavMenuUrlKeys } from 'features/workspace/enums';
import { getActiveLeftNavTab } from 'features/workspace/helpers';

export const useActiveTabForLeftNav = () => {
  const pathname = useLocation().pathname;

  const getActiveTabForLeftNav = (path: Array<string> | undefined, key: WorkspaceLeftNavMenuUrlKeys) => {
    return getActiveLeftNavTab({ path, key, pathname });
  };

  return { getActiveTabForLeftNav };
};
