import { sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { SchedulingType, StatusFilterGroups } from 'daos/enums';
import { TaskStatus } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { compareByPriority } from 'lib/helpers/comparison_helpers';
import { readonlyArray, groupRecordBy } from 'lib/readonly_record';
import { memoizeNumericIds } from 'redux/entities/selectors/helpers';
import { RootState } from 'redux/root_reducer';

import { createCacheByIdConfig, createCacheByIdsConfig } from './shared';

const emptyTaskStatusArray = readonlyArray<TaskStatus>([]);

export const getTaskStatusesById = (state: RootState) => state.entities.taskStatuses;

export const getTaskStatusForId = (state: RootState, id: number) => getTaskStatusesById(state)[id];

const getTaskStatusesByWorkspaceId = createSelector(getTaskStatusesById, (taskStatusesById) => {
  return groupRecordBy(taskStatusesById, (taskStatus) => taskStatus.workspace.id);
});

export const getPrioritizedTaskStatusesForTaskStatusIds = createCachedSelector(
  getTaskStatusesById,
  (_: RootState, taskStatusIds: ReadonlyArray<number>) => memoizeNumericIds(taskStatusIds),
  (taskStatusesById, taskStatusIds) => {
    const taskStatuses = taskStatusIds.reduce((acc: Array<TaskStatus>, taskStatusId) => {
      const taskStatus = taskStatusesById[taskStatusId];

      if (taskStatus) {
        acc.push(taskStatus);
      }

      return acc;
    }, []);

    return readonlyArray([...taskStatuses].sort(compareByPriority));
  }
)(createCacheByIdsConfig());

export const getCurrentWorkspaceTaskStatusesSortedByPriority = createSelector(
  getTaskStatusesByWorkspaceId,
  getCurrentWorkspaceId,
  (taskStatusesByWorkspace, currentWorkspaceId) => {
    const taskStatuses = taskStatusesByWorkspace[currentWorkspaceId];
    return taskStatuses
      ? readonlyArray(sortBy(taskStatuses, (taskStatus) => taskStatus.priority))
      : emptyTaskStatusArray;
  }
);

export const getActiveCurrentWorkspaceTaskStatusesSortedByPriority = createSelector(
  getCurrentWorkspaceTaskStatusesSortedByPriority,
  (taskStatuses) => {
    return readonlyArray(taskStatuses.filter((taskStatus) => !taskStatus.archived));
  }
);

export const getActiveCurrentWorkspaceTaskStatusesSortedByPriorityForFilter = createCachedSelector(
  getActiveCurrentWorkspaceTaskStatusesSortedByPriority,
  (_: RootState, statusFilter: StatusFilterGroups) => statusFilter,
  (taskStatuses, statusFilter) => {
    return readonlyArray(
      taskStatuses.filter((taskStatus) => taskStatusMatchesStatusGroup(taskStatus, statusFilter, undefined))
    );
  }
)(createCacheByIdConfig());

export const taskStatusMatchesStatusGroup = (
  status: TaskStatus,
  statusFilter: StatusFilterGroups,
  customTaskStatusIds?: ReadonlySet<number>
): boolean => {
  switch (statusFilter) {
    case StatusFilterGroups.All:
      return true;
    case StatusFilterGroups.ActiveAndOnHold:
      return status.schedulingType === SchedulingType.Scheduled || status.schedulingType === SchedulingType.Unscheduled;
    case StatusFilterGroups.Done:
      return status.schedulingType === SchedulingType.Done;
    case StatusFilterGroups.OnHold:
      return status.schedulingType === SchedulingType.Unscheduled;
    case StatusFilterGroups.Active:
      return status.schedulingType === SchedulingType.Scheduled;
    case StatusFilterGroups.Custom:
      return customTaskStatusIds ? customTaskStatusIds.has(status.id) : false;
    case StatusFilterGroups.atRisk:
      return false;
    case StatusFilterGroups.Asap:
      return false;
    case StatusFilterGroups.None:
      return false;
    default:
      return true;
  }
};
