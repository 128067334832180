import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Button } from 'semantic-ui-react';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { EmailConfirmationDao } from 'daos/email_confirmation';
import { LpHeader } from 'features/common/as_components';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { LpIcon, starExclamationSolid, envelopeOpenTextSolid } from 'features/common/lp_icon';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';

import './index.scss';

export const EmailConfirmationError = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);

  const isEmailConfirmationRoute = Boolean(
    useRouteMatch({
      path: frontend.emailConfirmation.url({}),
      exact: true,
    })
  );

  const handleResendEmailConfirmation = () => {
    setIsSubmitting(true);

    const { uuid } = dispatch(EmailConfirmationDao.reCreateConfirmation());

    dispatch(
      awaitRequestFinish(uuid, {
        onSuccess: () => {
          setConfirmationSent(true);
        },
        onFinish: () => {
          setIsSubmitting(false);
        },
      })
    );
  };

  const subHeader = isEmailConfirmationRoute ? (
    "We're sorry, an error occurred while trying to confirm your email address. Please click the button below to proceed to the app anyway."
  ) : (
    <>
      <span className="email-confirmation-error__sub-header--expired">
        {
          "You'll need to confirm your email address to access LiquidPlanner and keep planning. Not to worry - we'll make it quick!"
        }
      </span>
      <span className="email-confirmation-error__sub-header--expired">
        Click on &quot;<strong>Send Confirmation</strong>&quot; below and a new email confirmation will arrive in your
        inbox in a few minutes. This email confirmation will contain your activation link to get you going!
      </span>
      <span className="email-confirmation-error__sub-header--expired">
        <ContactSupportLink text={'Support & Feedback'} /> <br /> if you need assistance, we&apos;re happy to help!
      </span>
    </>
  );

  const resendEmailConfirmationButton = !confirmationSent ? (
    <Button
      className="email-confirmation-error__submit-button"
      content="Send Confirmation"
      onClick={handleResendEmailConfirmation}
      disabled={isSubmitting}
      type="submit"
    />
  ) : (
    <div className="email-confirmation-error__email-sent-text">
      <strong>Email Sent!</strong>
    </div>
  );

  return (
    <>
      <div className="email-confirmation-error">
        <div className="email-confirmation-error__logo-block">
          <LpIcon size="8x" icon={isEmailConfirmationRoute ? starExclamationSolid : envelopeOpenTextSolid} />
        </div>

        <div className="email-confirmation-error__header-block">
          <LpHeader headerType="h1" className="email-confirmation-error__header">
            {isEmailConfirmationRoute ? 'Email Confirmation Not Found' : 'Email Confirmation Needed'}
          </LpHeader>

          <LpHeader headerType="h2" className="email-confirmation-error__sub-header">
            {subHeader}
          </LpHeader>
        </div>

        {isEmailConfirmationRoute ? (
          <Button
            className="email-confirmation-error__submit-button"
            content="Take Me To The App"
            onClick={() => {
              history.push('/');
            }}
            disabled={isSubmitting}
            type="submit"
          />
        ) : (
          resendEmailConfirmationButton
        )}
      </div>

      <LpLegalTermsFooter />
    </>
  );
};
