import { LocationFilterProjectSettings } from 'daos/types';
import { CustomProjectFilters } from 'features/common/custom_item_filter/types';
import { ControlledFolderStatusFilterDropdown } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';

export const ProjectFilter = ({
  disabled = false,
  dataIsAllActive = false,
  onChange,
  value,
}: {
  disabled?: boolean;
  dataIsAllActive?: boolean;
  onChange: (value: LocationFilterProjectSettings) => void;
  value: LocationFilterProjectSettings;
}) => {
  const handleChange = (newValue: CustomProjectFilters) => {
    onChange({ ...value, ...newValue });
  };

  return (
    <ControlledFolderStatusFilterDropdown
      defaultFolderStatus={dataIsAllActive ? FolderFilterStatus.Scheduled : FolderFilterStatus.All}
      disabled={disabled}
      dataIsAllActive={dataIsAllActive}
      onChange={handleChange}
      value={value}
    />
  );
};
