import { useMemo } from 'react';
import { textEditor } from 'react-data-grid';
import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { FolderStatus, TargetFinishType } from 'daos/item_enums';
import { Item } from 'daos/model_types';
import {
  ColorDropdownEditor,
  DateInputEditor,
  FolderStatusDropdownEditor,
  RateSheetDropdownEditor,
  TargetFinishTypeDropdownEditor,
} from 'features/common/data_grid/add_edit_grid/cell_editors';
import {
  ColorColumnFormatter,
  DateFormatter,
  FolderStatusFormatter,
  NumberTextFormatter,
  TargetFinishTypeFormatter,
} from 'features/common/data_grid/add_edit_grid/cell_formatters';
import { getFieldCellValuesByFieldId } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import {
  checkValidDateEditCellClass,
  checkValidNameEditCellClass,
} from 'features/common/data_grid/add_edit_grid/helpers/cell_class_names_helpers';
import {
  ADD_EDIT_DROPDOWN_CLASS,
  checkValidWorkLimitCellClass,
} from 'features/common/data_grid/add_edit_grid/helpers/class_name_helpers';
import { MultiFieldEditorProps } from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { customFieldAddEditColumn } from 'features/common/data_grid/column_definitions/custom_fields';
import {
  colorColumn,
  descriptionColumn,
  folderStatusColumn,
  itemBillingRateSheetColumn,
  itemNameColumn,
  itemPayRateSheetColumn,
  targetFinishColumn,
  targetFinishTypeColumn,
  targetStartColumn,
} from 'features/common/data_grid/column_definitions/item';
import { AddEditGridColumnKey } from 'features/common/data_grid/enums';
import { AddEditGridRow } from 'features/common/data_grid/types';
import { useHasTimesheetPermissions } from 'hooks/use_has_timesheet_permissions';
import { COLUMN_WIDTH_M } from 'lib/constants';
import { convertSecondsToHours } from 'lib/helpers';
import {
  getEditGridCustomFieldValuesByItemId,
  getEditGridProjectCustomFieldsSortedByPriority,
} from 'redux/entities/selectors/custom_field';
import {
  getCurrentOrganization,
  getCurrentOrganizationHasDataCustomization,
} from 'redux/entities/selectors/organization';

export const useProjectAndFolderEditColumnDefinitions = (
  isTemplateGrid: boolean,
  setMultiEditModalProps: React.Dispatch<React.SetStateAction<MultiFieldEditorProps | undefined>>,
  itemType: ItemType.PROJECTS | ItemType.FOLDERS
) => {
  const organization = useSelector(getCurrentOrganization);
  const orgHasUpgradedTimeTracking = organization?.flags.hasUpgradedTimeTracking;
  const customFields = useSelector(getEditGridProjectCustomFieldsSortedByPriority);
  const hasDataCustomization = useSelector(getCurrentOrganizationHasDataCustomization);
  const { canExportTimesheets } = useHasTimesheetPermissions();

  const canViewRateSheets = orgHasUpgradedTimeTracking && canExportTimesheets;

  const columns = useMemo(() => {
    const baseColumns = [
      {
        ...itemNameColumn(itemType),
        key: AddEditGridColumnKey.Name,
        cellClass: checkValidNameEditCellClass,
        editor: textEditor,
        frozen: true,
      },
      {
        ...descriptionColumn,
        key: AddEditGridColumnKey.Description,
        editor: textEditor,
        width: COLUMN_WIDTH_M,
      },
    ];

    const baseColorColumn = {
      ...colorColumn(itemType),
      key: AddEditGridColumnKey.Color,
      cellClass: ADD_EDIT_DROPDOWN_CLASS,
      formatter: ColorColumnFormatter,
      editor: ColorDropdownEditor,
      editorOptions: { editOnClick: true },
      width: COLUMN_WIDTH_M,
    };

    const scheduledColumns = [
      {
        ...folderStatusColumn(itemType),
        key: AddEditGridColumnKey.FolderStatus,
        cellClass: ADD_EDIT_DROPDOWN_CLASS,
        editor: FolderStatusDropdownEditor,
        editorOptions: { editOnClick: true },
        formatter: FolderStatusFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...targetStartColumn,
        key: AddEditGridColumnKey.TargetStart,
        cellClass: checkValidDateEditCellClass,
        editor: DateInputEditor,
        editorOptions: { editOnClick: true, commitOnOutsideClick: false },
        formatter: DateFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...targetFinishColumn,
        key: AddEditGridColumnKey.TargetFinish,
        cellClass: checkValidDateEditCellClass,
        editor: DateInputEditor,
        editorOptions: { editOnClick: true, commitOnOutsideClick: false },
        formatter: DateFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        ...targetFinishTypeColumn,
        key: AddEditGridColumnKey.TargetFinishType,
        cellClass: ADD_EDIT_DROPDOWN_CLASS,
        editor: TargetFinishTypeDropdownEditor,
        editorOptions: { editOnClick: true },
        formatter: TargetFinishTypeFormatter,
        width: COLUMN_WIDTH_M,
      },
      {
        key: AddEditGridColumnKey.WorkLimit,
        name: 'Work Limit (h)',
        cellClass: ({ workLimit }: AddEditGridRow) => checkValidWorkLimitCellClass(workLimit ?? 0),
        editor: textEditor,
        formatter: NumberTextFormatter,
        editorOptions: { editOnClick: true },
        resizable: true,
        width: COLUMN_WIDTH_M,
      },
    ];

    const billingRateSheetColumn = {
      ...itemBillingRateSheetColumn,
      key: AddEditGridColumnKey.BillingRateSheet,
      cellClass: ADD_EDIT_DROPDOWN_CLASS,
      editorOptions: { editOnClick: true },
      editor: RateSheetDropdownEditor,
      width: COLUMN_WIDTH_M,
    };

    const payRateSheetColumn = {
      ...itemPayRateSheetColumn,
      key: AddEditGridColumnKey.PayRateSheet,
      cellClass: ADD_EDIT_DROPDOWN_CLASS,
      editorOptions: { editOnClick: true },
      editor: RateSheetDropdownEditor,
      width: COLUMN_WIDTH_M,
    };

    const billingRateSheet = canViewRateSheets ? [billingRateSheetColumn] : [];
    const payRateSheet = canViewRateSheets ? [payRateSheetColumn] : [];

    if (isTemplateGrid) {
      return [...baseColumns, baseColorColumn];
    }

    return [...baseColumns, ...scheduledColumns, ...billingRateSheet, ...payRateSheet, baseColorColumn];
  }, [canViewRateSheets, isTemplateGrid, itemType]);

  const customColumns = hasDataCustomization ? customFieldAddEditColumn(customFields, setMultiEditModalProps) : [];

  return [...columns, ...customColumns];
};

export const useProjectAndFolderToEditGridRows = (projectOrFolders: ReadonlyArray<Item>) => {
  const fieldValuesByItemId = useSelector(getEditGridCustomFieldValuesByItemId);

  return projectOrFolders.reduce((acc: Array<AddEditGridRow>, item) => {
    const fieldValues = fieldValuesByItemId[item.id] ?? [];
    const workLimitHours = convertSecondsToHours(item.workLimit ?? 0);

    acc.push({
      [AddEditGridColumnKey.Id]: item.id,
      [AddEditGridColumnKey.Name]: item.name ?? '',
      [AddEditGridColumnKey.Description]: item.description?.trim() ?? '',
      [AddEditGridColumnKey.FolderStatus]: item.folderStatus as FolderStatus,
      [AddEditGridColumnKey.BillingRateSheet]: item.billingRateSheetId,
      [AddEditGridColumnKey.PayRateSheet]: item.payRateSheetId,
      [AddEditGridColumnKey.TargetStart]: item.targetStart ?? '',
      [AddEditGridColumnKey.TargetFinish]: item.targetFinish ?? '',
      [AddEditGridColumnKey.TargetFinishType]: item.targetFinishType as TargetFinishType,
      [AddEditGridColumnKey.Color]: item.color ?? '',
      [AddEditGridColumnKey.WorkLimit]: workLimitHours,
      ...getFieldCellValuesByFieldId(fieldValues),
    });

    return acc;
  }, []);
};
