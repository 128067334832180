import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';

const { createBody, deleteBody, resource } = defineModel({
  apiType: 'academyLessonProgress',
  relationships: [],
  type: 'ACADEMY_LESSON_PROGRESS',
});

const { ACADEMY_LESSON_PROGRESS } = resource;

interface AcademyLessonProgressionsParams {
  academyLessonId: string;
}

interface SingleAcademyLessonProgressionParams {
  lessonId: string;
  progressId: string;
}

const createLessonProgress = ({ academyLessonId }: AcademyLessonProgressionsParams) =>
  request(
    backend.academyLessonProgressions.url({ academyLessonId }),
    ACADEMY_LESSON_PROGRESS,
    createBody({ academyLessonId })
  );

const destroyLessonProgress = (params: SingleAcademyLessonProgressionParams) =>
  request(backend.academyLessonProgress.url(params), ACADEMY_LESSON_PROGRESS, deleteBody(Number(params.progressId)));

export const LessonProgressDao = {
  create: createLessonProgress,
  destroy: destroyLessonProgress,
} as const;
