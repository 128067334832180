import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { OrganizationDao } from 'daos/organization';
import { RateDataDao } from 'daos/rate_data';
import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { useSetupOutpost } from 'features/academy/outpost/use_setup_outpost';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { creditCardSolid, LpIcon, coffeeSolid as waitingIconSolid } from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getCurrentOrganizationSubscription } from 'redux/entities/selectors/subscription';

import { ManageAccountPendingChanges } from './pending_changes';
import { ManageAccountPlanSection } from './plan';
import { ManageAccountRates } from './rates';

import './index.scss';

export const ManageAccount = () => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const organizationName = useSelector(getCurrentOrganization)?.name;
  const currentPlan = useSelector(getCurrentOrganizationPlan);
  const subscription = useSelector(getCurrentOrganizationSubscription);

  const fetchOrganizationWithSubscription = useCallback(() => {
    const { uuid } = dispatch(
      OrganizationDao.fetch({ organizationId }, { include: { includeSubscription: true, includePlan: true } })
    );

    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch, organizationId]);

  useEvents({
    event: Events.ORGANIZATION_MODIFIED,
    callback: fetchOrganizationWithSubscription,
    scope: EventScopes.ManageAccount,
  });

  useEffect(() => {
    const { uuid } = dispatch(RateDataDao.fetch({ organizationId }));
    dispatch(awaitRequestFinish(uuid, {}));

    fetchOrganizationWithSubscription();
  }, [organizationId, dispatch, fetchOrganizationWithSubscription]);

  useSetupOutpost(OutpostLocation.OrganizationAccountManagement);

  if (!currentPlan || !subscription) {
    return null;
  }

  return (
    <>
      <TopNavHeader
        breadcrumb={
          <LpBreadcrumb
            sections={[
              {
                key: organizationName,
                content: (
                  <span>
                    <LpIcon icon={creditCardSolid} />{' '}
                    <LpLink to={frontend.workspaceHub.url({ organizationId, workspaceId })}>{organizationName}</LpLink>
                  </span>
                ),
              },
              {
                key: 'manage-account',
                content: NavigationMenuDisplay.ManageAccount,
              },
            ]}
          />
        }
      />
      <div className="manage-account">
        <div className="manage-account--max-width">
          <p className="manage-account__change-times">
            <LpIcon icon={waitingIconSolid} className="manage-account__change-times-icon" />
            Account changes may take a few minutes to process
          </p>
          <ManageAccountPlanSection />
          <ManageAccountPendingChanges />
          <ManageAccountRates />
        </div>
      </div>
    </>
  );
};
