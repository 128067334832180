import { SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { WorkType } from 'daos/enums';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';

interface WorkTypeDropdownProps {
  disabled?: DropdownProps['disabled'];
  onChange: (event: SyntheticEvent<HTMLElement, Event>, { value }: { value: WorkType }) => void;
  workType: WorkType;
  onClose?: () => void;
  isOpen?: boolean;
  useOnGrid?: boolean;
}

export const workTypeOptions = [
  { value: WorkType.INDEPENDENTLY, text: 'Schedule Independently', search: 'schedule independently' },
  { value: WorkType.IN_ORDER, text: 'Schedule In Order', search: 'schedule in order' },
];

const WorkTypeDropdown = ({
  disabled,
  onChange,
  workType,
  isOpen,
  onClose,
  useOnGrid = false,
}: WorkTypeDropdownProps) => {
  const handleChange = (event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    if (value !== workType) {
      onChange(event, { value: value as WorkType });
    }
  };

  const workTypeDropdown = (
    <Dropdown
      open={isOpen}
      onClose={onClose}
      className="lp-work-type-dropdown"
      disabled={disabled}
      onChange={handleChange}
      options={workTypeOptions}
      selection={true}
      value={workType}
    />
  );

  return useOnGrid ? <PortalDropdown>{workTypeDropdown}</PortalDropdown> : workTypeDropdown;
};

export default WorkTypeDropdown;
