import classNames from 'classnames';
import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { UserType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { WidgetAvatar } from 'features/dashboards/helpers/widget_avatar';
import { getOnClickViewUrlForPeopleListWidget } from 'features/dashboards_v2/widget/widgets/list/helpers';
import { PeopleRowUserData } from 'features/dashboards_v2/widget/widgets/reports/types';
import { frontend } from 'lib/urls';
import { getIsGuestCurrentWorkspaceUser } from 'redux/entities/selectors/user';

import './user_name_cell.scss';

const UsernameLinkRenderer = ({ linkTo, userData }: { linkTo: string; userData: PeopleRowUserData }) => {
  const isGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  const { disconnected } = userData;
  const isActiveLink = linkTo !== '' && !isGuest;

  const usernameContent = (
    <span
      className={classNames('people-grid__username-link', {
        'people-grid__username-link--disconnected-user': disconnected,
        'people-grid__username-link--active-link': isActiveLink,
      })}
    >
      <WidgetAvatar
        avatarUrl={userData.avatarUrl}
        size={AvatarSize.ExtraSmall_1_2}
        avatarColor={userData.avatarColor}
        userType={userData.userType}
        username={userData.username}
      />
      {userData.username}
    </span>
  );

  return isActiveLink ? <LpLink to={linkTo}>{usernameContent}</LpLink> : usernameContent;
};

export const PeopleListWidgetUsernameLink = ({
  userData,
  widget,
}: {
  userData: PeopleRowUserData;
  widget: WidgetWithData;
}) => {
  const linkTo = getOnClickViewUrlForPeopleListWidget(widget, userData);

  return <UsernameLinkRenderer linkTo={linkTo} userData={userData} />;
};

export const PeopleGridUsernameLink = ({
  userData,
  widget,
}: {
  userData: PeopleRowUserData;
  widget: WidgetWithData;
}) => {
  const { organizationId, workspaceId } = widget;
  const { disconnected, userType, userId } = userData;

  const urlByUserType = getUrlByUserType(disconnected);
  const linkTo = urlByUserType[userType].url({ organizationId, workspaceId, userId });

  return <UsernameLinkRenderer linkTo={linkTo} userData={userData} />;
};

function getUrlByUserType(disconnected: boolean) {
  return {
    [UserType.Member]: disconnected ? frontend.member : frontend.memberWorkload,
    [UserType.Placeholder]: frontend.placeholder,
    [UserType.Resource]: disconnected ? frontend.resource : frontend.resourceWorkload,
  };
}
