import { ReactNode } from 'react';

import SchedulingLabels from 'containers/shared/notice_icons/scheduling_labels';
import { SchedulingLabelsRenderer } from 'containers/shared/notice_icons/scheduling_labels/guest';
import { ScheduleDirective, SchedulingType } from 'daos/enums';
import { ItemMetrics } from 'daos/model_types';
import { LpIcon, clockSolid, timelineSolid } from 'features/common/lp_icon';
import { useHasFeature } from 'hooks/use_has_feature';
import { formatAsHours } from 'lib/display_helpers/format_as_hours';
import { formatAsStoryPoints } from 'lib/display_helpers/format_as_story_points';
import { FeatureFlag } from 'lib/feature_flags';
import { convertSecondsToHours } from 'lib/helpers';
import { getRemainingWorkExpected } from 'lib/helpers/item_metric_calculations';

export const taskCardMetricsMessage = (itemMetrics?: ItemMetrics) => {
  const hoursLogged = itemMetrics ? convertSecondsToHours(itemMetrics.loggedWork) : 0;
  const remainingWorkExpected = convertSecondsToHours(getRemainingWorkExpected(itemMetrics));

  return formatAsHours(hoursLogged + remainingWorkExpected);
};

export const taskCardTimelineMessage = (itemMetrics?: ItemMetrics) => {
  const storyPointsValue = storyPoints(itemMetrics);
  return storyPointsValue ? formatAsStoryPoints(storyPointsValue) : null;
};

const storyPoints = (itemMetrics?: ItemMetrics) => {
  switch (itemMetrics?.taskSchedulingType) {
    case SchedulingType.Done:
      return itemMetrics.completedStoryPoints;
    case SchedulingType.Scheduled:
      return itemMetrics.remainingStoryPoints;
    case SchedulingType.Unscheduled:
      return itemMetrics.onHoldStoryPoints;
    default:
      return itemMetrics?.totalStoryPoints ?? null;
  }
};

const BaseTaskBoardCardFooter = ({
  metricsMessage,
  timelineMessage,
  taskStatusColor,
  children,
}: {
  metricsMessage: string;
  timelineMessage: string | undefined;
  taskStatusColor: string | undefined;
  children: ReactNode;
}) => {
  const hasStoryPointsEnabled = useHasFeature(FeatureFlag.storyPoints);
  return (
    <div className="lp-task-board__card-footer">
      <div className="lp-task-board__card-footer-metrics">
        <LpIcon icon={clockSolid} color={`#${taskStatusColor}`} />
        <span>{metricsMessage}&nbsp;</span>
        {hasStoryPointsEnabled && timelineMessage && (
          <>
            <LpIcon icon={timelineSolid} color={`#${taskStatusColor}`} />
            <span>{timelineMessage}&nbsp;</span>
          </>
        )}
      </div>
      <div className="lp-task-board__card-footer-labels">{children}</div>
    </div>
  );
};

export const GuestTaskBoardCardFooter = ({
  metricsMessage,
  timelineMessage,
  taskStatusColor,
  showSchedulingLimitsLabel,
  showDependencyLabel,
  showRiskLabel,
  asapStatus,
}: {
  metricsMessage: string;
  timelineMessage: string;
  taskStatusColor: string | undefined;
  showSchedulingLimitsLabel: boolean;
  showDependencyLabel: boolean;
  showRiskLabel: boolean;
  asapStatus: ScheduleDirective;
}) => (
  <BaseTaskBoardCardFooter
    metricsMessage={metricsMessage}
    timelineMessage={timelineMessage}
    taskStatusColor={taskStatusColor}
  >
    <SchedulingLabelsRenderer
      showSchedulingLimitsLabel={showSchedulingLimitsLabel}
      showDependencyLabel={showDependencyLabel}
      showRiskLabel={showRiskLabel}
      asapStatus={asapStatus}
    />
  </BaseTaskBoardCardFooter>
);

export const TaskBoardCardFooter = ({
  taskId,
  taskMetrics,
  taskStatusColor,
}: {
  taskId: number;
  taskMetrics?: ItemMetrics;
  taskStatusColor: string | undefined;
}) => (
  <BaseTaskBoardCardFooter
    metricsMessage={taskCardMetricsMessage(taskMetrics)}
    timelineMessage={taskCardTimelineMessage(taskMetrics) ?? undefined}
    taskStatusColor={taskStatusColor}
  >
    <SchedulingLabels itemId={taskId} />
  </BaseTaskBoardCardFooter>
);
