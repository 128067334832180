import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import { WidgetType, widgetTypeDisplay } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { backend } from 'daos/urls';
import { imageSolid, LpIcon } from 'features/common/lp_icon';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { RenderedWidget } from 'features/dashboards_v2/widget';

import './image.scss';

const defaultTitle = widgetTypeDisplay[WidgetType.Image];

export const ImageWidget = ({ widget }: { widget: Widget }) => {
  const { setShowSettingsForWidget, inDesign } = useDashboardContext();

  const openWidgetSettingsModal = () => {
    setShowSettingsForWidget(String(widget.id));
  };
  const widgetImageOnClick = inDesign ? openWidgetSettingsModal : undefined;

  const imageUrl =
    widget.config.s3Id &&
    backend.dashboardImage.url({
      organizationId: widget.organizationId,
      workspaceId: widget.workspaceId,
      s3Id: widget.config.s3Id,
    });

  const widgetImage = (
    <Image
      onClick={widgetImageOnClick}
      alt={`${widget.config.title ?? defaultTitle}`}
      className={classNames('image-widget', inDesign && 'image-widget--is-design-layout')}
      src={imageUrl}
    />
  );

  const emptyImage = (
    <div
      onClick={widgetImageOnClick}
      className={classNames('image-widget', 'image-widget--empty', inDesign && 'image-widget--is-design-layout')}
    >
      <LpIcon icon={imageSolid} size="2x" />
      <p>No Image Selected</p>
    </div>
  );

  const body = imageUrl ? widgetImage : emptyImage;

  return <RenderedWidget body={body} header={widget.config.title ?? ''} />;
};
