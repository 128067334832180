import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationUserSlackIntegration } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { OrganizationUserSlackIntegrationDao } from 'daos/organization_user_slack_integration';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { awaitRequestFinish } from 'lib/api';
import { getOrganizationUserSlackIntegrationsForOrgUserId } from 'redux/entities/selectors/organization_user_slack_integration';

export interface UseSlackIntegrationForOrgUserResult extends Partial<OrganizationUserSlackIntegration> {
  disconnect: () => void;
  isLoading: boolean;
  update: (values: Partial<OrganizationUserSlackIntegration>) => void;
}

const useSlackIntegrationForOrgUser = (
  organizationId: number,
  organizationUserId: number
): UseSlackIntegrationForOrgUserResult => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { formatLocalDateWithTime } = useLocalizedFormats();

  const orgUserSlackIntegration = useSelector((state) =>
    getOrganizationUserSlackIntegrationsForOrgUserId(state, organizationUserId)
  );

  const createdAt = orgUserSlackIntegration?.createdAt
    ? formatLocalDateWithTime(orgUserSlackIntegration.createdAt)
    : undefined;

  const fetchOrgUserDetails = useCallback(() => {
    const { uuid } = dispatch(
      OrganizationUserDao.fetch({ organizationId, organizationUserId }, { include: { includeSlackIntegration: true } })
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onFinish: () => setIsLoading(false),
      })
    );
  }, [dispatch, organizationId, organizationUserId]);

  const disconnect = () => {
    if (!orgUserSlackIntegration?.id) {
      return;
    }

    const { uuid } = dispatch(
      OrganizationUserSlackIntegrationDao.destroy({
        id: orgUserSlackIntegration.id,
        organizationId: organizationId,
        organizationUserId: organizationUserId,
      })
    );

    dispatch(awaitRequestFinish(uuid, { onSuccess: fetchOrgUserDetails }));
  };

  const update = (values: Partial<OrganizationUserSlackIntegration>) => {
    if (!orgUserSlackIntegration?.id) {
      return;
    }

    const { uuid } = dispatch(
      OrganizationUserSlackIntegrationDao.update(
        {
          id: orgUserSlackIntegration.id,
          organizationId: organizationId,
          organizationUserId: organizationUserId,
        },
        values
      )
    );

    dispatch(awaitRequestFinish(uuid, { onSuccess: fetchOrgUserDetails }));
  };

  useEffect(() => {
    fetchOrgUserDetails();
  }, [fetchOrgUserDetails]);

  return {
    ...orgUserSlackIntegration,
    createdAt,
    isLoading,
    disconnect,
    update,
  };
};

export default useSlackIntegrationForOrgUser;
