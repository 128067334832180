export const TimesheetLogoSvg = () => {
  return (
    <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Product Logos">
        <rect y="0.5" width="40" height="40" rx="6" fill="#FFCC24" />
        <g id="Group 427319252">
          <path id="Rectangle 34628703" d="M18.9966 7H21.9957V18.9965H18.9966V7Z" fill="#1D1D1B" />
          <path
            id="Rectangle 34628705"
            d="M33.9922 18.9961L33.9922 21.9952L21.9957 21.9952L21.9957 18.9961L33.9922 18.9961Z"
            fill="#1D1D1B"
          />
          <rect id="Rectangle 34628704" x="18.9966" y="27.9922" width="2.99913" height="5.99826" fill="#1D1D1B" />
          <rect
            id="Rectangle 34628706"
            x="12.998"
            y="18.9961"
            width="2.99913"
            height="5.99826"
            transform="rotate(90 12.998 18.9961)"
            fill="#1D1D1B"
          />
          <path
            id="Rectangle 34628707"
            d="M28.979 9.89062L31.0997 12.0113L26.8583 16.2527L24.7376 14.132L28.979 9.89062Z"
            fill="#1D1D1B"
          />
          <path
            id="Rectangle 34628708"
            d="M31.0996 28.9766L28.9789 31.0973L24.7375 26.8559L26.8582 24.7351L31.0996 28.9766Z"
            fill="#1D1D1B"
          />
          <rect
            id="Rectangle 34628709"
            x="14.1343"
            y="24.7383"
            width="2.99913"
            height="5.99826"
            transform="rotate(45 14.1343 24.7383)"
            fill="#1D1D1B"
          />
          <rect
            id="Rectangle 34628710"
            x="16.2544"
            y="14.1328"
            width="2.99913"
            height="5.99826"
            transform="rotate(135 16.2544 14.1328)"
            fill="#1D1D1B"
          />
        </g>
      </g>
    </svg>
  );
};
