import { SyntheticEvent } from 'react';
import { Statistic } from 'semantic-ui-react';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { LpIcon, medkitSolid } from 'features/common/lp_icon';
import { ApiError } from 'lib/api/types';
import { LP_STATUS_URL, LP_SUPPORT_EMAIL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';

import 'features/authentication/authenticated/errors/top_level_error.scss';

interface ServerErrorProps {
  error: ApiError;
}

export const ServerError = ({ error }: ServerErrorProps) => {
  const handleReloadApp = (e: SyntheticEvent) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <div className="top-level-error__wrapper">
      <div className="top-level-error__content">
        <LpIcon icon={medkitSolid} size={'10x'} className="top-level-error__icon" />
        <div className="top-level-error">
          <Statistic>
            <Statistic.Label className="top-level-error__label">{error.detail}</Statistic.Label>

            <p className="top-level-error__try-reloading">
              Click{' '}
              <a className="clickable" href={'/'} onClick={handleReloadApp}>
                here
              </a>{' '}
              to try re-loading the application.
            </p>

            <p className="error-page__unknown-error-content-text">
              Please check <NewWindowLink to={LP_STATUS_URL}>{LP_STATUS_URL}</NewWindowLink>
              {' or contact us at '}
              <span>
                <NewWindowLink to={`mailto:${LP_SUPPORT_EMAIL}`}>{LP_SUPPORT_EMAIL}</NewWindowLink>
              </span>
            </p>
          </Statistic>
        </div>
      </div>

      <LpLegalTermsFooter />
    </div>
  );
};
