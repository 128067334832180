import { useMemo } from 'react';

import { customColumnDefinitions } from 'containers/shared/custom_column';
import { ColumnValueRenderer } from 'containers/shared/custom_column/column_value';
import { WidgetWithData } from 'daos/model_types';
import { PropsWidgetColumn } from 'daos/widget_column';
import { LpIcon } from 'features/common/lp_icon';
import { RenderedWidget } from 'features/dashboards_v2/widget/index';
import { JiraFieldsDisplayText } from 'features/dashboards_v2/widget/widgets/jira_custom_fields';
import {
  getRendererPropsForWidgetColumn,
  getIconForColumn,
  deprecatedGetIsActiveOrInUseArchivedCustomField,
} from 'features/dashboards_v2/widget/widgets/properties_summary/helpers';
import {
  DeprecatedColumnDataMap,
  DeprecatedPropertiesWidgetData,
} from 'features/dashboards_v2/widget/widgets/properties_summary/types';
import { gray500 } from 'style/variables';

import './index.scss';

interface PropertiesCustomColumnHeaderProps {
  columnDefinition: PropsWidgetColumn;
  columnDataMap: DeprecatedColumnDataMap;
}

const EditedDisplayText = ({ name, archived }: { name: string; archived: boolean }) => {
  if (name.includes('(Jira)')) {
    return <JiraFieldsDisplayText name={name} />;
  } else {
    return <div>{`${name}${archived ? ' (Archived)' : ''}`}</div>;
  }
};

const DeprecatedPropertiesColumnHeader = ({ columnDefinition, columnDataMap }: PropertiesCustomColumnHeaderProps) => {
  const { column, customFieldId } = columnDefinition;
  const fieldData = columnDataMap[customFieldId ?? 0]?.data;

  const displayText = useMemo(() => {
    if (fieldData) {
      const name = fieldData.name;
      const archived = fieldData.isArchived;
      return <EditedDisplayText name={name} archived={archived} />;
    }

    const columnDisplayText = customColumnDefinitions[column]?.displayText ?? column;
    return columnDisplayText?.replace(' (Range)', '') ?? '';
  }, [fieldData, column]);

  const icon = getIconForColumn(column, fieldData?.type ?? null);

  return (
    <div>
      {displayText} <LpIcon icon={icon} color={gray500} />
    </div>
  );
};

const DeprecatedPropertiesRow = ({
  columnDefinition,
  columnDataMap,
}: {
  columnDefinition: PropsWidgetColumn;
  columnDataMap: DeprecatedColumnDataMap;
}) => {
  const fieldId = columnDefinition.customFieldId;
  const isActiveFieldOrHasInUseValue = fieldId
    ? deprecatedGetIsActiveOrInUseArchivedCustomField(fieldId, columnDataMap)
    : undefined;

  if (fieldId && !isActiveFieldOrHasInUseValue) {
    return null;
  }

  const props = getRendererPropsForWidgetColumn(columnDefinition, columnDataMap);

  return (
    <>
      <div className="properties-summary__body-row-aside">
        <DeprecatedPropertiesColumnHeader columnDefinition={columnDefinition} columnDataMap={columnDataMap} />
      </div>
      <div className="properties-summary__body-row-content">
        <ColumnValueRenderer selectedColumn={columnDefinition.column} {...props} />
      </div>
    </>
  );
};

export const DeprecatedPropertiesSummaryWidget = ({ widget }: { widget: WidgetWithData }) => {
  const widgetData = widget.data as DeprecatedPropertiesWidgetData | null;

  const propertiesColumns = widget.config.columns as ReadonlyArray<PropsWidgetColumn> | null;

  if (!propertiesColumns || !widgetData) {
    return <RenderedWidget noContent />;
  }

  return (
    <RenderedWidget
      body={
        <div className="properties-summary__body">
          {propertiesColumns.map((columnDefinition, index) => (
            <DeprecatedPropertiesRow
              key={index}
              columnDefinition={columnDefinition}
              columnDataMap={widgetData.columnDataMap}
            />
          ))}
        </div>
      }
    />
  );
};
