import classNames from 'classnames';

import {
  columnsImpactedByWorkLimitAlert,
  getValueOrEmptyString,
  userTypeDisplay,
  getFormattedDateOrEmptyString,
  getHoursOrEmptyStringWithNoRounding,
} from 'containers/shared/custom_column/display_helpers';
import {
  ItemDateProps,
  ItemMetricValueProps,
  PeopleValueProps,
  TimesheetRollupValueProps,
} from 'containers/shared/custom_column/types';
import { UserType } from 'daos/enums';
import { getItemMetricValueForColumn, getStoryPointItemMetricValueForColumn } from 'hooks/use_item_metrics_display';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { DisplayValueWithOptionalWarningIcon } from 'lib/display_helpers/warning_icon_with_value';
import { secondsToHoursWithPrecision } from 'lib/helpers';

import { Columns, StandardColumns } from './enum';
import { getItemDateValuesByColumn, timesheetRollupValueForColumn } from './helpers';

export const ItemDateColumnRenderer = ({
  column,
  itemDateProps,
}: {
  column: Columns;
  itemDateProps: ItemDateProps;
}) => {
  const { formatLocalDate } = useLocalizedFormats();

  const { value, isAtRisk, inherited } = getItemDateValuesByColumn({ itemDateProps, formatLocalDate })[column] ?? {};

  return (
    <span className={classNames({ inherited })}>
      <DisplayValueWithOptionalWarningIcon value={value} showWarningIcon={!!isAtRisk} />
    </span>
  );
};

export const ItemMetricColumnRenderer = ({
  column,
  itemMetricProps,
  workLimitHours,
}: {
  column: Columns;
  itemMetricProps: ItemMetricValueProps;
  workLimitHours?: number | null;
}) => {
  const { formatLocalDate } = useLocalizedFormats();

  const value = getItemMetricValueForColumn({
    column,
    displayEmptyStringInsteadOfCount: false,
    formatLocalDate,
    itemMetricProps,
    workLimitHours,
  });

  const showWarningIcon = columnsImpactedByWorkLimitAlert.has(column) && !!itemMetricProps.workLimitAlert;

  return <DisplayValueWithOptionalWarningIcon value={value} showWarningIcon={showWarningIcon} />;
};

export const PeopleColumnRenderer = ({ column, peopleProps }: { column: Columns; peopleProps: PeopleValueProps }) => {
  const columnValue = () => {
    switch (column) {
      case StandardColumns.AvailabilityUsed: {
        const availabilityUsedValue = peopleProps[column];
        return !availabilityUsedValue ? 'N/A' : `${availabilityUsedValue.toFixed(2)}%`;
      }

      case StandardColumns.Workload:
        return getHoursOrEmptyStringWithNoRounding(secondsToHoursWithPrecision(peopleProps[column] || 0));

      case StandardColumns.RemainingAvailability:
      case StandardColumns.TotalAvailability:
      case StandardColumns.UnloggedWork:
        return getHoursOrEmptyStringWithNoRounding(peopleProps[column]);

      case StandardColumns.Username:
      case StandardColumns.LastName:
      case StandardColumns.FirstName:
      case StandardColumns.LpUserId:
        return getValueOrEmptyString(peopleProps[column]);

      case StandardColumns.Groups: {
        const value = peopleProps[column] ?? [];
        return value.join(', ');
      }

      case StandardColumns.UserStatus:
        return peopleProps.userStatus ?? '';
      case StandardColumns.UserType: {
        const userType = getValueOrEmptyString(peopleProps.userType);
        if (userType) {
          return userTypeDisplay[userType as UserType];
        }
        return '';
      }
      default:
        return '';
    }
  };
  return <DisplayValueWithOptionalWarningIcon value={columnValue()} showWarningIcon={false} />;
};

export const DateRangeColumnRenderer = ({
  dateRange,
}: {
  dateRange: ReadonlyArray<{
    from: string;
    to: string;
  }>;
}) => {
  const { formatLocalDate } = useLocalizedFormats();

  if (dateRange.length === 0) {
    return <>No Date Range</>;
  }

  const formatDateRange = ({ from, to }: { from: string; to: string }) => {
    const formattedStartDate = getFormattedDateOrEmptyString(from, formatLocalDate);
    const formattedEndDate = getFormattedDateOrEmptyString(to, formatLocalDate);
    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const formattedDateRanges = dateRange.map(formatDateRange).join(',');

  return <>{formattedDateRanges}</>;
};

export const TimesheetRollupColumnRenderer = ({
  column,
  timesheetRollupProps,
  itemMetricProps,
}: {
  column: Columns;
  timesheetRollupProps: TimesheetRollupValueProps;
  itemMetricProps: ItemMetricValueProps;
}) => {
  const value = timesheetRollupValueForColumn({
    column,
    timesheetRollupProps,
  });

  const showWarningIcon = columnsImpactedByWorkLimitAlert.has(column) && !!itemMetricProps.workLimitAlert;

  return <DisplayValueWithOptionalWarningIcon value={value} showWarningIcon={showWarningIcon} />;
};

export const StoryPointsColumnRenderer = (props: { column: Columns; itemMetricProps: ItemMetricValueProps }) => {
  const value = getStoryPointItemMetricValueForColumn(props);

  return <span>{value}</span>;
};
