import classNames from 'classnames';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { Dropdown, Popup } from 'semantic-ui-react';

import { BulkSelectionOptionsItemType } from 'features/common/bulk_selection/types';
import PortableItemLinkButton from 'features/common/buttons/portable_links/portable_item_link_button';
import { getIsAnyItemDuplicationInProgress } from 'features/common/duplicate/selectors';
import {
  LpIcon,
  editLight,
  arrowAltRightRegular,
  copyLight,
  trashXmarkLight,
  linkHorizontalLight,
  linkHorizontalSlashLight,
} from 'features/common/lp_icon';

interface BulkMenuItemProps {
  disabled: boolean;
  label: BulkSelectionOptionsItemType;
  onClick: () => void;
}

export const BulkEditMenuItem = ({ disabled, label, onClick }: BulkMenuItemProps) => (
  <Dropdown.Item
    disabled={disabled}
    text={
      <span>
        <LpIcon icon={editLight} className="icon" /> Edit {label}
      </span>
    }
    onClick={onClick}
  />
);

export const BulkMoveMenuItem = ({ disabled, label, onClick }: BulkMenuItemProps) => (
  <Dropdown.Item
    disabled={disabled}
    text={
      <span>
        <LpIcon icon={arrowAltRightRegular} className="icon" /> Move {label}
      </span>
    }
    onClick={onClick}
  />
);

export const BulkCopyLinksMenuItem = ({
  selectedItemIds,
  onEllipsisMenuCopySuccess,
}: {
  selectedItemIds: ReadonlyArray<number>;
  onEllipsisMenuCopySuccess: () => void;
}) => (
  <Dropdown.Item
    text={
      <PortableItemLinkButton selectedItemIds={selectedItemIds} onEllipsisMenuCopySuccess={onEllipsisMenuCopySuccess} />
    }
  />
);

export const BulkDuplicateMenuItem = ({ disabled, label, onClick }: BulkMenuItemProps) => {
  const isAnyItemDuplicating = useSelector(getIsAnyItemDuplicationInProgress);

  return (
    <Dropdown.Item
      className={classNames({ 'ellipsis-action-dropdown--disabled': isAnyItemDuplicating })}
      disabled={disabled}
      onClick={isAnyItemDuplicating ? noop : onClick}
      text={
        <Popup
          disabled={!isAnyItemDuplicating}
          position="left center"
          size="mini"
          content={'A duplication is already in process'}
          trigger={
            <span>
              <LpIcon icon={copyLight} className="icon" /> Duplicate {label}
            </span>
          }
        />
      }
    />
  );
};

export const BulkDeleteMenuItem = ({ disabled, label, onClick }: BulkMenuItemProps) => (
  <Dropdown.Item
    disabled={disabled}
    text={
      <span>
        <LpIcon icon={trashXmarkLight} className="icon" /> Delete {label}
      </span>
    }
    onClick={onClick}
  />
);

export const BulkCreateDependenciesMenuItem = ({ onClick }: { onClick: () => void }) => (
  <Dropdown.Item
    text={
      <span>
        <LpIcon icon={linkHorizontalLight} className={'icon'} /> Create Dependencies
      </span>
    }
    onClick={onClick}
  />
);

export const BulkRemoveDependenciesMenuItem = ({ onClick }: { onClick: () => void }) => (
  <Dropdown.Item
    text={
      <span>
        <LpIcon icon={linkHorizontalSlashLight} className={'icon'} /> Remove Dependencies
      </span>
    }
    onClick={onClick}
  />
);
