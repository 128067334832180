import { LpIcon, jira } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { FieldMappingRowType } from 'features/jira_project/modal/types';
import { slate700 } from 'style/variables';

export const getNameIcon = ({ rowType }: { rowType: FieldMappingRowType }) =>
  rowType === FieldMappingRowType.JiraToLp ? (
    <LpIcon icon={jira} size="lg" color={slate700} />
  ) : (
    <LpSvg icon={LpSvgs.LP_WAVY} size={15} color={slate700} />
  );
