import { FieldMetaProps, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { FormInputProps } from 'semantic-ui-react';

import { formErrorLabel } from 'features/common/forms/form_field_helpers/form_error_label';
import { LpDatePicker } from 'features/common/inputs/lp_date_picker';
import { LpDatePickerWithWorkdayTimeType } from 'features/common/inputs/lp_date_picker/with_workday_time_type';
import { WorkdayTimeType } from 'lib/workday_time_type';

type LpFormikDateProps = {
  id?: string;
  name: string;
  workdayTimeType?: WorkdayTimeType;
} & Omit<FormInputProps, 'checked' | 'id' | 'name' | 'multiple' | 'onBlur' | 'onChange' | 'value'>;

export const LpFormikDatePicker = ({
  id,
  name,
  workdayTimeType,
  disabled,
  hideErrorMessage = false,
  showError = false,
  className,
  ...otherProps
}: LpFormikDateProps) => {
  const { getFieldProps, setFieldValue, setFieldError, getFieldMeta } = useFormikContext();
  const fieldProps = getFieldProps(name);
  const fieldMeta: FieldMetaProps<ReactNode> = getFieldMeta(name);

  const date = fieldProps?.value ?? '';

  const handleChange = (value: string) => {
    setFieldValue(name ?? id, value);
  };

  const onValidityChanged = (invalid: boolean) => {
    if (invalid) {
      setFieldError(name, 'Date format is invalid');
    } else {
      setFieldError(name, undefined);
    }
  };

  const commonDatePickerProps = {
    allowEmpty: true,
    className,
    disabled,
    error: showError && formErrorLabel({ fieldMeta, hideErrorMessage }),
    inline: true,
    onChange: handleChange,
    onInput: handleChange,
    onValidityChanged: onValidityChanged,
    rounded: true,
    value: date,
    ...otherProps,
  };

  return workdayTimeType ? (
    <LpDatePickerWithWorkdayTimeType {...commonDatePickerProps} workdayTimeType={workdayTimeType} />
  ) : (
    <LpDatePicker {...commonDatePickerProps} />
  );
};
