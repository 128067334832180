import classNames from 'classnames';
import { ReactNode, useContext } from 'react';

import { LpMenuItemAsLink } from 'features/common/as_components';
import NewWindowLink from 'lib/display_helpers/url_links';

import { UserMenuContext } from './context';

export const UserMenuTableItem = ({
  to,
  newWindow = false,
  children,
  e2eTestId,
}: {
  to: string;
  newWindow?: boolean;
  children: ReactNode;
  e2eTestId?: string;
}) => {
  const { onClose } = useContext(UserMenuContext);
  const className = 'user-menu__table-item';

  if (newWindow) {
    return (
      <NewWindowLink className={classNames('item', className)} to={to} e2eTestId={e2eTestId}>
        {children}
      </NewWindowLink>
    );
  }

  return (
    <LpMenuItemAsLink onClick={onClose} className={className} to={to} e2eTestId={e2eTestId}>
      {children}
    </LpMenuItemAsLink>
  );
};
