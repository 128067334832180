import { useSignalEffect } from '@preact/signals-react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { List } from 'semantic-ui-react';

import { Item } from 'daos/model_types';
import { useSearchPageContext } from 'features/common/search/context';
import { SearchContext } from 'features/common/search/enums';
import ResultItem from 'features/common/search/result_item';

import { useItemSearch, UseItemSearchProps } from './hooks/use_item_search';

interface ItemsTabProps {
  searchContext: SearchContext;
  emptyState: ReactNode;
  searchProps: UseItemSearchProps;
}
export function SearchItemsTab({ searchContext, emptyState, searchProps }: ItemsTabProps) {
  const [searchResults, setSearchResults] = useState<Array<Item> | undefined>(undefined);
  const { searchEvent } = useSearchPageContext();

  const { itemSearch } = useItemSearch(searchProps, setSearchResults);

  useSignalEffect(() => {
    const searchEventValue = searchEvent.value;
    if (!searchEventValue) {
      setSearchResults(undefined);
      return;
    }

    itemSearch(searchEventValue.searchTerm, searchEventValue.includeDone);
  });

  return (
    <AnimatePresence>
      {searchResults?.length ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="lp-search__results"
        >
          <List divided className="lp-search__results-items">
            {searchResults.map((item, index) => (
              <ResultItem key={item.id} item={item} resultNumber={index + 1} searchContext={searchContext} />
            ))}
          </List>
        </motion.div>
      ) : (
        emptyState
      )}
    </AnimatePresence>
  );
}
