import { useMemo } from 'react';

import { ItemType, ListWidgetType, WidgetOnClickViewType } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { PropsWidgetColumn } from 'daos/widget_column';
import { listSolid, LpIcon } from 'features/common/lp_icon';
import { getOnClickViewOptionsForItemType, peopleListOnClickViewOptions } from 'features/dashboards_v2/widget/helpers';
import { defaultNamesForListTypes } from 'features/dashboards_v2/widget/use_default_widget_title';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { LocationFilters } from 'features/dashboards_v2/widget_settings/location_filters';
import { OnClickViewDropdown } from 'features/dashboards_v2/widget_settings/on_click_view_dropdown';
import { PreviewColumns } from 'features/dashboards_v2/widget_settings/preview_columns';
import { PreviewCols, PreviewRows } from 'features/dashboards_v2/widget_settings/preview_rows_and_columns_dropdown';
import { ShowWidgetTotals } from 'features/dashboards_v2/widget_settings/show_totals';
import { Title } from 'features/dashboards_v2/widget_settings/title';
import { useColumnsByListWidgetType } from 'hooks/use_columns_for_list_widget_type/hooks';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

const OnClickViewSetting = ({ widget }: { widget: Widget }) => {
  const { config } = widget;
  const listType = config.listType;

  const rowItemType = getItemTypeForListType(listType);
  const includeOptions = getOnClickViewOptionsForListType(listType, config.onClickView);

  return <OnClickViewDropdown name="Row Click" scopeItemType={rowItemType} include={includeOptions} />;
};

const SelectableColumns = ({ widget }: { widget: Widget }) => {
  const { config } = widget;
  const hasBenchmarkEstimateEnabled = useHasFeature(FeatureFlag.benchmarkEstimate);

  const listType = config.listType;
  const selectedColumns = (config.columns ?? []) as ReadonlyArray<PropsWidgetColumn>;
  const columns = useListWidgetColumnsByListType(listType);

  const columnsToShow = useMemo(() => {
    let columnsToReturn = columns;
    if (!hasBenchmarkEstimateEnabled) {
      columnsToReturn = columns.filter((column) => column.id !== 'benchmarkEstimate');
    }

    return columnsToReturn;
  }, [columns, hasBenchmarkEstimateEnabled]);

  return <PreviewColumns allGridColumns={columnsToShow} defaultColumns={selectedColumns} />;
};

export const ListWidgetSettings = ({ widget }: { widget: Widget }) => {
  const {
    config: { listType },
  } = widget;

  const header = (
    <>
      <LpIcon icon={listSolid} /> {listType ? defaultNamesForListTypes[listType] : 'List'}
    </>
  );

  const content = (
    <>
      <Title widgetId={widget.id} />
      <LocationSetting />
      <LocationFilters showDateRangeFilter widgetId={widget.id} />
      <SelectableColumns widget={widget} />
      <PreviewCols />
      <PreviewRows />
      <OnClickViewSetting widget={widget} />
      <ShowWidgetTotals />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};

function getItemTypeForListType(listType: ListWidgetType | null) {
  switch (listType) {
    case ListWidgetType.Packages:
      return ItemType.PACKAGES;
    case ListWidgetType.Folders:
      return ItemType.FOLDERS;
    case ListWidgetType.Projects:
      return ItemType.PROJECTS;
    case ListWidgetType.Tasks:
      return ItemType.TASKS;
    case ListWidgetType.Assignment:
      return ItemType.ASSIGNMENTS;
    default:
      undefined;
  }
}

function getOnClickViewOptionsForListType(
  listType: ListWidgetType | null,
  configClickView: WidgetOnClickViewType | null
) {
  const itemType = getItemTypeForListType(listType);

  switch (listType) {
    case ListWidgetType.Packages:
    case ListWidgetType.Folders:
    case ListWidgetType.Projects:
    case ListWidgetType.Tasks:
    case ListWidgetType.Assignment:
      return getOnClickViewOptionsForItemType(itemType, configClickView);

    case ListWidgetType.Users:
      return peopleListOnClickViewOptions;
    default:
      undefined;
  }
}

function useListWidgetColumnsByListType(listType: ListWidgetType | null) {
  const columnsByListType = useColumnsByListWidgetType();

  return listType ? columnsByListType[listType] : [];
}
