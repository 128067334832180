import memoizee from 'memoizee';

import { Favorite } from 'daos/model_types';
import { FavoriteProps } from 'features/favorite/types';

const defaultCacheSize = 25;

export const memoizeSelectorProps = <T>(size?: number) => {
  const cache = memoizee((x) => x, {
    normalizer: function (args) {
      return JSON.stringify(args[0]);
    },
    primitive: true,
    max: size ?? defaultCacheSize,
  });
  return (object: T): T => {
    return cache(object) as T;
  };
};

export const memoizeNumericIds = memoizeSelectorProps<ReadonlyArray<number>>();

export const compareOnlyIfBothArePresent = (valueOne: number | undefined, valueTwo: number | undefined) => {
  if (!valueOne || !valueTwo) {
    return true;
  }

  return valueOne === valueTwo;
};

export const findFavoriteFromCriteriaInFavoriteAndProps = (
  favorites: ReadonlyArray<Favorite>,
  favoriteProps: FavoriteProps
) => {
  const { view, groupId, itemId, userId, libraryResourceId } = favoriteProps;

  return favorites.find((favorite) => {
    return (
      favorite.view === view &&
      compareOnlyIfBothArePresent(favorite.group?.id, groupId) &&
      compareOnlyIfBothArePresent(favorite.item?.id, itemId) &&
      compareOnlyIfBothArePresent(favorite.libraryResource?.id, libraryResourceId) &&
      compareOnlyIfBothArePresent(favorite.user?.id, userId)
    );
  });
};
