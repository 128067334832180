import { isUndefined } from 'lodash';
import { useContext, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { EventBusContext } from 'features/common/events/event_bus';
import { getPausedEventScopes } from 'features/common/events/selectors';
import { Events, EventScopes } from 'features/common/events/types';
import { useBrowserTabActive } from 'hooks/use_browser_tab_active';

type CallbackFunction = (data?: string) => void;
const useEventHandler = ({ callback, eventScope }: { callback: CallbackFunction; eventScope?: EventScopes }) => {
  const isBrowserTabActive = useBrowserTabActive();
  const pausedEventScopes = useSelector(getPausedEventScopes);
  const eventScopeIsPaused = isUndefined(eventScope) ? false : !!pausedEventScopes[eventScope];
  const eventHandlingIsDelayed = !isBrowserTabActive || eventScopeIsPaused;
  const [hasDelayedEvent, setHasDelayedEvent] = useState(false);
  const [eventData, setEventData] = useState<string | undefined>();

  useEffect(() => {
    if (hasDelayedEvent && !eventHandlingIsDelayed) {
      setHasDelayedEvent(false);
      callback(eventData);
      setEventData(undefined);
    }
  }, [hasDelayedEvent, eventHandlingIsDelayed, callback, eventData]);

  return useCallback(
    (eventData?: string) => {
      if (eventHandlingIsDelayed) {
        setHasDelayedEvent(true);
        setEventData(eventData);
      } else {
        callback(eventData);
      }
    },
    [eventHandlingIsDelayed, callback]
  );
};

export const useEvents = ({
  callback,
  event,
  scope,
}: {
  callback: CallbackFunction;
  event: Events;
  scope?: EventScopes;
}) => {
  const { subscribe, unsubscribe } = useContext(EventBusContext);
  const eventHandler = useEventHandler({ callback: callback, eventScope: scope });

  useEffect(() => {
    const modifiedEventHandler = (e?: Event) => {
      eventHandler((e as CustomEvent)?.detail);
    };

    subscribe(event, modifiedEventHandler);

    return () => {
      unsubscribe(event, modifiedEventHandler);
    };
  }, [event, eventHandler, subscribe, unsubscribe]);
};
