import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { forwardRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

import { WidgetType } from 'daos/enums';
import { Widget, WidgetWithData } from 'daos/model_types';
import { getWidgetForId } from 'features/dashboards/selectors';
import { BaseV2Widget, RenderedWidget } from 'features/dashboards_v2/widget/index';
import { ChangesWidget } from 'features/dashboards_v2/widget/widgets/changes';
import { DashboardNoteWidget } from 'features/dashboards_v2/widget/widgets/dashboard_note';
import { ImageWidget } from 'features/dashboards_v2/widget/widgets/image';
import { InsightsWidget } from 'features/dashboards_v2/widget/widgets/insights';
import { LinkedNoteWidget } from 'features/dashboards_v2/widget/widgets/linked_note';
import { MetricsTallyWidget } from 'features/dashboards_v2/widget/widgets/metrics_tally';
import { PropertiesSummaryWidget } from 'features/dashboards_v2/widget/widgets/properties_summary';
import { ScheduleSummaryWidget } from 'features/dashboards_v2/widget/widgets/schedule_summary';
import { TaskBoardWidget } from 'features/dashboards_v2/widget/widgets/task_board';
import { WorkloadWidget } from 'features/dashboards_v2/widget/widgets/workload';
import { IntakeWidgetData } from 'features/dashboards_v2/widget_click_through/intake/types';
import { canRenderWidgetWithError } from 'features/dashboards_v2/widget_data_error_warning';

import { IntakeWidget } from './widgets/intake';
import { ListWidget } from './widgets/list';
import { WidgetLoad } from './widgets/loading';

interface WidgetRendererProps {
  dashboardId: string;
  widgetId: string;
  layoutId?: string;
  isDragging?: boolean;
  isOverlay?: boolean;
  draggableRef?: (el: HTMLElement | null) => void;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}
export const WidgetRenderer = memo(
  forwardRef<HTMLDivElement, WidgetRendererProps>(
    ({ dashboardId, widgetId, layoutId, isDragging, isOverlay, draggableRef, attributes, listeners }, ref) => {
      const widget = useSelector((state) => getWidgetForId(state, widgetId));
      if (widget?.isMetadata) {
        return (
          <WidgetLoad
            id={widget?.id}
            dashboardId={widget?.dashboardId}
            type={widget?.widgetType}
            size={widget?.widgetSize}
          />
        );
      }

      return (
        <BaseV2Widget
          attributes={attributes}
          dashboardId={dashboardId}
          draggableRef={draggableRef}
          isDragging={isDragging}
          isOverlay={isOverlay}
          listeners={listeners}
          key={widgetId}
          layoutId={layoutId}
          ref={ref}
          widgetId={widgetId}
          widgetComponent={<WidgetComponent widget={widget} />}
        />
      );
    }
  ),
  areEqual
);

function WidgetComponent({ widget }: { widget: Widget | undefined }) {
  switch (widget?.widgetType) {
    case WidgetType.DashboardNote:
      return <DashboardNoteWidget widget={widget} />;
    case WidgetType.Image:
      return <ImageWidget widget={widget} />;
  }

  if (!widget?.data) {
    return <RenderedWidget noContent />;
  }

  const canRenderWidget = canRenderWidgetWithError(widget.data.errors);

  if (widget.data.errors.length && !canRenderWidget) {
    return <RenderedWidget widgetDataErrorTypes={widget.data.errors} />;
  }

  // Cast as a WidgetWithData now that we know for sure that there is data
  // available. This keeps our widgets simpler.
  const widgetWithData = widget as WidgetWithData;

  switch (widget?.widgetType) {
    case WidgetType.Changes:
      return <ChangesWidget widget={widgetWithData} />;
    case WidgetType.LinkedNote:
      return <LinkedNoteWidget widget={widgetWithData} />;
    case WidgetType.List:
      return <ListWidget widget={widgetWithData} />;
    case WidgetType.Intake:
      return <IntakeWidget widget={widgetWithData as Widget<IntakeWidgetData>} />;
    case WidgetType.ScheduleSummary:
      return <ScheduleSummaryWidget widget={widgetWithData} />;
    case WidgetType.BoardSummary:
      return <TaskBoardWidget widget={widgetWithData} />;
    case WidgetType.PropertiesSummary:
      return <PropertiesSummaryWidget widget={widgetWithData} />;
    case WidgetType.Workload:
      return <WorkloadWidget widget={widgetWithData} />;
    case WidgetType.MetricsTally:
      return <MetricsTallyWidget widget={widgetWithData} />;
    case WidgetType.Insights:
      return <InsightsWidget widget={widgetWithData} />;
    default:
      return <RenderedWidget noContent />;
  }
}
