import classNames from 'classnames';
import { ReactNode, ReactNodeArray } from 'react';

import LpModal from 'features/common/modals/lp_modal';

import './lp_tabbed_modal.scss';

export interface LpTabbedModalProps {
  className?: string;
  onClose?: () => void;
  dataTestId?: string;
  header?: ReactNode | ReactNodeArray;
  content?: ReactNode | ReactNodeArray;
  actions?: ReactNode | ReactNodeArray;
}

const LpTabbedModal = ({ dataTestId, className, onClose, header, content, actions }: LpTabbedModalProps) => (
  <LpModal
    dataTestId={dataTestId}
    className={classNames('tabbed-modal', className)}
    onClose={onClose}
    size="large"
    header={header}
    content={content}
    actions={actions}
  />
);

export default LpTabbedModal;
