import { sortBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { AcademyTrackLesson } from 'features/academy/daos/model_types';
import { readonlyArray } from 'lib/readonly_record';
import { memoizeSelectorProps } from 'redux/entities/selectors/helpers';
import { createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

// course selectors
export const getCoursesById = (state: RootState) => state.entities.academyCourses;
export const getCoursesSortedByPriority = createSelector(getCoursesById, (coursesById) =>
  readonlyArray(sortBy(Object.values(coursesById), (course) => course.priority))
);
export const getCourseForId = (state: RootState, id: number | string) => state.entities.academyCourses[id];

// track selectors
export const getTracksById = (state: RootState) => state.entities.academyTracks;
export const getTracksSortedByPriorityForCourse = createCachedSelector(
  getTracksById,
  (_: RootState, courseId: string) => courseId,
  (getTracksById, courseId) => {
    const filteredTracks = Object.values(getTracksById).filter((track) => track.academyCourseId === courseId);
    return readonlyArray(sortBy(filteredTracks, (track) => track.priority));
  }
)(createCacheByIdConfig());
export const getTrackForId = (state: RootState, id: number | string) => state.entities.academyTracks[id];

// lesson selectors
export const getLessonsById = (state: RootState) => state.entities.academyLessons;
export const getLessonForId = (state: RootState, id: string) => state.entities.academyLessons[id];

// track lesson selectors
export const getTrackLessonsById = (state: RootState) => state.entities.academyTrackLessons;
export const getTrackLessonsSortedByPriorityForTrack = createCachedSelector(
  getTrackLessonsById,
  (_: RootState, trackId: string) => trackId,
  (trackLessonsById, trackId) => {
    const filteredTrackLessons = Object.values(trackLessonsById).filter(
      (trackLesson) => trackLesson.academyTrackId === trackId
    );
    return readonlyArray(sortBy(filteredTrackLessons, (trackLesson) => trackLesson.priority));
  }
)(createCacheByIdConfig());
export const getTrackLessonForId = (state: RootState, id: number | string) => state.entities.academyTrackLessons[id];

interface TrackLessonProps {
  lessonId: string;
  trackId: string;
}

const memoizeTrackLessonProps = memoizeSelectorProps<TrackLessonProps>();

export const getTrackLessonForTrackIdAndLessonId = createCachedSelector(
  (state: RootState, trackLessonProps: TrackLessonProps) =>
    getTrackLessonsSortedByPriorityForTrack(state, trackLessonProps.trackId),
  (_state: RootState, trackLessonProps: TrackLessonProps) => memoizeTrackLessonProps(trackLessonProps),
  (lessonsForTrack, { lessonId }: TrackLessonProps): AcademyTrackLesson | undefined => {
    if (!lessonsForTrack) {
      return undefined;
    }

    return lessonsForTrack.find((trackLesson) => trackLesson.academyLessonId === lessonId);
  }
)((_state, { lessonId, trackId }) => `${trackId}:${lessonId}`);
