import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import TaskIconMaker from 'containers/shared/task_icon_maker';
import { PackageStatus } from 'daos/enums';
import { FolderStatus, FolderStatusToFolderStatusDisplayMap } from 'daos/item_enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { PropertyChange } from 'daos/types';
import { getChangeForId } from 'redux/entities/selectors/changes';
import { isPackage, isTask, getItemForId } from 'redux/entities/selectors/item';
import { getTaskStatusForId } from 'redux/entities/selectors/task_status';

interface ChangeCardStatusPropertyProps {
  changeId: number;
  propertyChangeData: PropertyChange;
}

const ChangeCardStatusProperty = ({ changeId, propertyChangeData }: ChangeCardStatusPropertyProps) => {
  const change = useSelector((state) => getChangeForId(state, changeId));
  const item = useSelector((state) => (change?.item ? getItemForId(state, change.item.id) : undefined));
  const propertyChangeValue = propertyChangeData.value;
  const isTaskItem = isTask(item);
  const isPackageItem = isPackage(item);

  const taskStatus = useSelector((state) =>
    isTaskItem ? getTaskStatusForId(state, propertyChangeData.value.id) : undefined
  );

  const taskStatusId = taskStatus?.id;
  const taskStatusName = taskStatus?.name;

  const changeStatusIcon = useMemo(() => {
    if (isTaskItem) {
      return (
        <>
          {<TaskIconMaker className="lp-change__card-change-property-status-icon" taskStatusId={taskStatusId} />}{' '}
          {taskStatusName}
        </>
      );
    }

    if (isPackageItem) {
      return <>{capitalize(packageStatusDisplayNameByPackageStatus[propertyChangeValue as PackageStatus])}</>;
    }

    return <>{FolderStatusToFolderStatusDisplayMap[propertyChangeValue as FolderStatus]}</>;
  }, [isPackageItem, isTaskItem, propertyChangeValue, taskStatusId, taskStatusName]);

  if (!change) {
    return null;
  }

  return changeStatusIcon;
};

export default ChangeCardStatusProperty;
