import { Statistic } from 'semantic-ui-react';

import 'features/common/errors/not_found.scss';

const PageNotFound = () => {
  return (
    <div className="not-found">
      <Statistic>
        <Statistic.Label>PAGE NOT FOUND</Statistic.Label>
        <Statistic.Value>404</Statistic.Value>
      </Statistic>
    </div>
  );
};

export default PageNotFound;
