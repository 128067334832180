import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { FileDao } from 'daos/file';
import { ItemDao } from 'daos/item';
import { itemAccessPermission } from 'daos/permission';
import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { writeUrlToClipboard } from 'features/common/portable_link_handler/copy_rich_text';
import { useItemPanelParams } from 'features/item_panel/use_item_panel_params';
import { useHasAccess } from 'hooks/use_has_access';
import { awaitRequestFinish } from 'lib/api';
import { FilePortableLink } from 'lib/clipboard/portable_links';
import { getFilesById, getItemFilesForItemId } from 'redux/entities/selectors/file';
import { getItemTypeForId } from 'redux/entities/selectors/item';
import { removeEntities } from 'redux/entities/slice';

export const useHasFileAccess = (itemId: number) => {
  const itemType = useSelector((state) => getItemTypeForId(state, itemId));
  const defaultType = ItemType.PACKAGES;

  const fileActionPermission = itemAccessPermission(itemType ?? defaultType);

  const hasFileAccess = useHasAccess(fileActionPermission, ItemDao.id(itemId));

  return { hasFileAccess };
};

export const useFileActions = () => {
  const dispatch = useDispatch();
  const { itemPanelId: itemId } = useItemPanelParams();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const itemFiles = useSelector((state) => getItemFilesForItemId(state, itemId));

  const itemFileIdsForDeletedFiles = useCallback(
    (fileIds: ReadonlyArray<number>) => {
      return fileIds.reduce((acc: Array<number>, fileId) => {
        itemFiles.forEach((itemFile) => {
          if (itemFile.file.id === fileId) {
            acc.push(itemFile.id);
          }
        });
        return acc;
      }, []);
    },
    [itemFiles]
  );

  const moveFilesAction = useCallback(
    ({
      destinationItemId,
      fileIds,
      onSuccess,
    }: {
      destinationItemId: number;
      fileIds: ReadonlyArray<number>;
      onSuccess: () => void;
    }) => {
      const moveFiles = fileIds.map((fileId) => ({
        workspaceId,
        fileId,
        sourceItemId: itemId,
        destinationItemId,
      }));

      const { uuid } = dispatch(FileDao.moveFiles({ workspaceId, organizationId, itemId }, moveFiles));

      dispatch(awaitRequestFinish(uuid, { onSuccess }));
    },
    [dispatch, itemId, organizationId, workspaceId]
  );

  const deleteFilesAction = useCallback(
    ({ fileIds, onSuccess }: { fileIds: ReadonlyArray<number>; onSuccess: () => void }) => {
      const deleteFiles = fileIds.map((fileId) => ({
        workspaceId,
        fileId,
        itemId,
      }));

      const { uuid } = dispatch(FileDao.deleteItemFiles({ workspaceId, organizationId, itemId }, deleteFiles));

      dispatch(
        awaitRequestFinish(uuid, {
          onSuccess: () => {
            const itemFileIds = itemFileIdsForDeletedFiles(fileIds);
            dispatch(removeEntities({ ids: itemFileIds, entityType: 'itemFiles' }));
            onSuccess();
          },
        })
      );
    },
    [dispatch, itemFileIdsForDeletedFiles, itemId, organizationId, workspaceId]
  );

  const openFileAction = useCallback(
    (fileS3id: string) => {
      const fileURL = backend.itemFilesDownload.url({
        organizationId,
        workspaceId,
        itemId,
        s3id: fileS3id,
      });

      window.open(fileURL, '_blank');
    },
    [itemId, organizationId, workspaceId]
  );

  return { moveFilesAction, deleteFilesAction, openFileAction };
};

export function useCopyLink(fileIds: Set<number>) {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const { itemPanelId: itemId } = useItemPanelParams();

  const filesById = useSelector(getFilesById);

  const mapPortableLinkProps = (fileId: number) => {
    const file = filesById[fileId];

    if (!file || !file.s3Id) {
      return null;
    }

    return new FilePortableLink(itemId, organizationId, workspaceId, file.s3Id, file.filename);
  };

  const copyLink = () => {
    const filePortableLinks = Array.from(fileIds)
      .map(mapPortableLinkProps)
      .filter((fileOrNull) => fileOrNull !== null) as Array<FilePortableLink>;

    return writeUrlToClipboard(...filePortableLinks);
  };

  return { copyLink };
}
