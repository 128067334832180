import { useSelector } from 'react-redux';

import { getIsNonNoteCustomFieldApplicableForItemType } from 'containers/shared/custom_column/helpers';
import { CustomFieldColumnProps } from 'containers/shared/custom_column/types';
import { CustomFieldType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { getCustomFieldForId, getFieldValueColumnProps } from 'redux/entities/selectors/custom_field';
import { getItemForId } from 'redux/entities/selectors/item';

import { CheckBoxFieldValue, FieldValueRenderer } from './field_value';

import './custom_field.scss';

interface CustomFieldCustomColumnProps {
  fieldId: number;
  itemId: number;
}

export const CustomFieldColumnRenderer = ({ customFieldType, isFieldApplicable, values }: CustomFieldColumnProps) => {
  const hasDefinedValue = !!values[0];
  const displayUncheckedBox = customFieldType === CustomFieldType.CHECKBOX && !hasDefinedValue;

  if (!isFieldApplicable) {
    return <>N/A</>;
  }

  if (displayUncheckedBox) {
    return <CheckBoxFieldValue checked={false} />;
  }

  return (
    <>
      {values.map((value, index) => (
        <FieldValueRenderer key={index} fieldType={customFieldType} hasMultipleValues={index > 0} value={value} />
      ))}
    </>
  );
};

export const CustomFieldColumn = ({ fieldId, itemId }: CustomFieldCustomColumnProps) => {
  const field = useSelector((state) => getCustomFieldForId(state, fieldId));
  const item = useSelector((state) => getItemForId(state, itemId));
  const values = useSelector((state) => getFieldValueColumnProps(state, ItemDao.id(itemId), fieldId));

  if (!field) {
    return null;
  }

  const isApplicable = getIsNonNoteCustomFieldApplicableForItemType(field, item?.itemType);

  return (
    <CustomFieldColumnRenderer customFieldType={field.fieldType} isFieldApplicable={isApplicable} values={values} />
  );
};
