import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ResetPasswordDao } from 'daos/reset_password';
import { validationSchema } from 'features/authentication/helpers';
import { PasswordForm } from 'features/authentication/unauthenticated/token/password_form';
import { ReturnToSignIn } from 'features/authentication/unauthenticated/token/return_to_signin';
import { awaitRequestFinish } from 'lib/api';

export const ResetPasswordForm = ({
  expired,
  claimedAt,
  token,
  resetPasswordId,
}: {
  token: string;
  resetPasswordId: number;
  expired: boolean;
  claimedAt: string | null;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isSubmitting, status, setStatus, handleSubmit, getFieldMeta, getFieldProps, setSubmitting } = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: ({ password }) => {
      setStatus(undefined);

      const { uuid } = dispatch(ResetPasswordDao.patch({ password, token, resetPasswordId }));

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setStatus(errors[0]);
            }
            setSubmitting(false);
          },
          onSuccess: () => {
            history.push('/');
          },
        })
      );
    },
  });

  const handleDismissApiError = () => setStatus(undefined);

  return (
    <>
      <PasswordForm
        expired={expired}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        handleDismissApiError={handleDismissApiError}
        status={status}
        claimedAt={claimedAt}
      />
      <ReturnToSignIn />
    </>
  );
};
