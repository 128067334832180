import classNames from 'classnames';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { FolderStatus } from 'daos/item_enums';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';

interface FolderStatusDropdownProps {
  className?: string;
  disabled?: DropdownProps['disabled'];
  fluid?: boolean;
  folderStatus: FolderStatus;
  onChange: ({ value }: { value: FolderStatus }) => void;
  isOpen?: boolean;
  useOnGrid?: boolean;
  onClose?: () => void;
}

export const folderStatusOptions = [
  {
    text: 'Scheduled',
    search: 'scheduled',
    value: FolderStatus.ACTIVE,
  },
  {
    text: 'On Hold',
    search: 'on hold',
    value: FolderStatus.ON_HOLD,
  },
  {
    text: 'Done',
    search: 'done',
    value: FolderStatus.DONE,
  },
];

const FolderStatusDropdown = ({
  className,
  disabled,
  fluid,
  folderStatus,
  onChange,
  isOpen,
  useOnGrid = false,
  onClose,
}: FolderStatusDropdownProps) => {
  const handleChange = (_: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    onChange({ value } as { value: FolderStatus });
  };

  const folderStatusDropdown = (
    <Dropdown
      selection
      className={classNames('lp-folder-status-dropdown', className)}
      closeOnChange
      disabled={disabled}
      fluid={fluid}
      onChange={handleChange}
      selectOnNavigation={false}
      value={folderStatus}
      options={folderStatusOptions}
      open={isOpen}
      onClose={onClose}
    />
  );

  return useOnGrid ? <PortalDropdown>{folderStatusDropdown}</PortalDropdown> : folderStatusDropdown;
};

export default FolderStatusDropdown;
