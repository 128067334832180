import { ReactNode } from 'react';

import LpLink from 'containers/shared/lp_link';
import { thirdPartyUrls } from 'daos/urls';
import NewWindowLink from 'lib/display_helpers/url_links';
import { frontend } from 'lib/urls';

enum LoginErrors {
  InvalidEmail = 'invalid_email_address',
  InvalidUserNameOrPassword = 'invalid_username_or_password',
  InvalidAuthRequest = 'invalid_auth_request',
  InvalidAuthRequestSAML = 'lp_login_not_authorized_saml',
}

export const loginErrors = (field: string) => {
  let loginError: string | ReactNode | undefined;

  switch (field) {
    case LoginErrors.InvalidEmail: {
      loginError = (
        <>
          {'This email is not in our system. Try again, or '}
          <span>
            <LpLink to={frontend.trial.url({})}>{'Sign up now'}</LpLink>
            {'.'}
          </span>
        </>
      );
      return loginError;
    }
    case LoginErrors.InvalidAuthRequest: {
      return "We're having trouble logging you in with SSO. Contact your SSO Administrator for help or try signing in with a different email.";
    }

    case LoginErrors.InvalidUserNameOrPassword: {
      loginError = (
        <>
          {'Incorrect username or password. Try again, or use the '}
          <span className="lp-login__forgot-password-error-text">{'Forgot Password'}</span>
          <span>{' link.'}</span>
        </>
      );
      return loginError;
    }

    case LoginErrors.InvalidAuthRequestSAML: {
      loginError = (
        <>
          {"We're having trouble signing you in. Try signing in with a different email or "}
          <span>
            <LpLink to={frontend.loginSso.url({})}>{'sign in with SSO'}</LpLink>
            {'.'}
          </span>
        </>
      );
      return loginError;
    }

    default: {
      loginError = (
        <>
          {"We've encountered an unexpected error. "}
          <NewWindowLink to={thirdPartyUrls.contactSupport}>Contact support</NewWindowLink>
          {' for assistance'}
        </>
      );
      return loginError;
    }
  }
};
