import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import relightVersion from './relight_version';
import supportedBrowsersRegex from './supported_browsers_regex';

const { bugsnagKey } = window as any;
const { bugsnagReleaseStage } = window as any;

// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
const resizeObserverLoopErrorRegex = /ResizeObserver loop/i;

// https://blog.mozilla.org/addons/2012/09/12/what-does-cant-access-dead-object-mean/
const deadObjectErrorRegex = /TypeError: can't access dead object/i;

const errorRegexesToIgnore = [resizeObserverLoopErrorRegex, deadObjectErrorRegex];

const bugsnagClient = bugsnagKey
  ? Bugsnag.start({
      apiKey: bugsnagKey,
      appVersion: relightVersion,
      plugins: [new BugsnagPluginReact()],
      releaseStage: bugsnagReleaseStage,
      onError: ({ errors }) => {
        //return false tells the bugsnag client to ignore (will not be sent to bugsnag)
        if (!supportedBrowsersRegex.test(navigator.userAgent)) {
          return false;
        }

        const error = errors[0];

        if (error && errorRegexesToIgnore.some((regex) => regex.test(error.errorMessage))) {
          return false;
        }
        return true;
      },
    })
  : undefined;

export default bugsnagClient;
