import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { ItemFormatterProps } from 'features/common/data_grid/types';
import LpPopUp from 'features/common/lp_popup';
import { ScheduleBar } from 'features/common/schedule_bar';
import { ScheduleBarTooltip } from 'features/common/schedule_bar/tool_tip';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { ScheduleViewType } from 'hooks/use_items_date_range';
import { isAssignment, getItemForId } from 'redux/entities/selectors/item';

export const ScheduleBarFormatter = ({ row }: ItemFormatterProps) => {
  const dateRange = row.scheduleBar;
  const item = useSelector((state) => getItemForId(state, row.id));

  const isAssignmentItem = isAssignment(item) && item?.parent;
  const panelId = isAssignmentItem ? item.parent.id : row.id;
  const tab = isAssignmentItem ? TabNames.Planning : TabNames.Scheduling;

  if (!dateRange || !item) {
    return null;
  }

  return (
    <LpPopUp
      useDefaultStyles={false}
      trackMouseMovement={true}
      trigger={
        <LpLink to={`#panelId=${panelId}&panelSection=${tab}Tab`} className="custom-column__schedule-bar-cell">
          <ScheduleBar
            itemId={item.id}
            dateRange={dateRange}
            showDateLabels={false}
            viewType={ScheduleViewType.Column}
          />
        </LpLink>
      }
      content={<ScheduleBarTooltip itemId={item.id} />}
    />
  );
};
