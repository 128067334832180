import { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { TemplatePickerPackageList } from 'containers/shared/template_picker/template_picker_package_list';
import { useFetchTemplatePackageAndProjects } from 'containers/shared/template_picker/use_fetch_template_packages_and_projects';
import { Item } from 'daos/model_types';
import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import LpLoaderAnimatedGrid from 'features/common/loaders/lp_item_panel_loader';
import { boxHeartSolid, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { frontend } from 'lib/urls';

import './template_picker.scss';

interface ProjectPickerModalProps {
  actionButtonDisabled?: boolean;
  actionButtonText: string;
  handleCloseModal: () => void;
  handleSubmit: () => void;
  loading?: boolean;
  selected: Set<number> | number | null;
  setSelected: ((value: SetStateAction<Set<number>>) => void) | ((value: SetStateAction<number | null>) => void);
  updateSelected: (id: number) => void;
}

export const ProjectPickerModal = ({
  actionButtonDisabled = false,
  actionButtonText,
  handleCloseModal,
  handleSubmit,
  loading = false,
  selected,
  updateSelected,
}: ProjectPickerModalProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [packages, setPackages] = useState<ReadonlyArray<Item>>([]);
  const [projectsByPackage, setProjectsByPackage] = useState<Record<string, ReadonlyArray<Item>>>({});

  const { fetchPackages } = useFetchTemplatePackageAndProjects({ setPackages, setProjectsByPackage });

  useEffect(() => {
    fetchPackages();
  }, [fetchPackages]);

  return (
    <LpModal
      className="lp-project-template-picker"
      onClose={handleCloseModal}
      size="tiny"
      header={`Project Templates`}
      content={
        <>
          {loading ? (
            <div className="lp-project-template-picker__loader">
              <LpLoaderAnimatedGrid label="" />
            </div>
          ) : (
            <TemplatePickerPackageList
              packages={packages}
              projectsByPackage={projectsByPackage}
              selected={selected}
              updateSelected={updateSelected}
            />
          )}
        </>
      }
      actions={
        <>
          <LpButtonAsLink
            to={frontend.templateCollectionPortfolio.url({
              organizationId,
              workspaceId,
            })}
          >
            <LpIcon icon={boxHeartSolid} /> Go to TEMPLATE Collection{' '}
          </LpButtonAsLink>
          <Button onClick={handleCloseModal}> Close </Button>
          <Button disabled={actionButtonDisabled} onClick={handleSubmit} primary>
            {actionButtonText}
          </Button>
        </>
      }
    />
  );
};
