import { RefObject, useCallback } from 'react';
import { Menu } from 'semantic-ui-react';

import GoBackToDashboardButton from 'features/common/buttons/go_back_to_dashboard_button';
import { CustomItemFilterLocation } from 'features/common/custom_item_filter/types';
import { EventScopes } from 'features/common/events/types';
import { TaskStatusFilterDropdown } from 'features/common/inputs/dropdowns/task_status_filter_dropdown';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';

import { BoardHideEmpty } from './board_hide_empty';

import './task_board_header.scss';

interface TaskBoardHeaderProps {
  handleHideEmptyClick: () => void;
  hideEmpty: boolean;
  inputValue: string;
  isGuest: boolean;
  setDebouncedValue: RefObject<(value: string) => void>;
  eventScope: EventScopes;
}

export const TaskBoardHeader = ({
  eventScope,
  handleHideEmptyClick,
  hideEmpty,
  inputValue,
  isGuest,
  setDebouncedValue,
}: TaskBoardHeaderProps) => {
  const handleDebouncedInputChange = useCallback(
    (value: string) => {
      if (setDebouncedValue.current) {
        setDebouncedValue.current(value);
      }
    },
    [setDebouncedValue]
  );

  const isWidgetTaskBoard = eventScope === EventScopes.WidgetTaskBoard;

  return (
    <Menu className="task-board-header" borderless secondary>
      <Menu.Menu className="task-board-header__left-menu">
        {isWidgetTaskBoard && (
          <Menu.Item>
            <GoBackToDashboardButton />
          </Menu.Item>
        )}

        <Menu.Item>
          <LpSearchInputUncontrolled initialValue={inputValue} onChange={handleDebouncedInputChange} />
        </Menu.Item>

        {!isGuest && (
          <Menu.Item className="task-board-header__left-menu-help-text">
            <em>Drag cards left or right to change status</em>
          </Menu.Item>
        )}
      </Menu.Menu>

      <Menu.Menu className="task-board-header__right-menu">
        <Menu.Item>
          <BoardHideEmpty hideEmpty={hideEmpty} handleHideEmptyClick={handleHideEmptyClick} disabled={isGuest} />
        </Menu.Item>

        {!isWidgetTaskBoard && (
          <Menu.Item>
            <TaskStatusFilterDropdown customItemFilterLocation={CustomItemFilterLocation.TaskBoard} />
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu>
  );
};
