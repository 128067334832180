import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import LpLink from 'containers/shared/lp_link';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { FieldMappingTable } from 'features/jira_project/modal/sections/field_mapping_section/field_mapping_table';
import {
  HandleAddFieldClick,
  HandleDeleteRowClick,
  ShouldDisableAddFieldButton,
} from 'features/jira_project/modal/sections/field_mapping_section/types';
import {
  addFieldMappingRow,
  deleteFieldMappingRow,
  shouldDisableAddRowButton,
} from 'features/jira_project/modal/sections/field_mapping_section/utils/row_utils';
import {
  FieldMappingRowType,
  FieldMappingValue,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  lpNameSystemMapping,
} from 'features/jira_project/modal/types';
import { frontend } from 'lib/urls';
import {
  getNonSystemFieldsByFieldTypesUsedOnTasks,
  supportedJiraMappingCustomFieldTypes,
} from 'redux/entities/selectors/custom_field';

import 'features/jira_project/modal/jira_project_modal.scss';

const DateAndFieldMappingSection = () => {
  const {
    addedLpFieldMappingRowItems,
    setAddedLpFieldMappingRowItems,
    addedJiraFieldMappingRowItems,
    setAddedJiraFieldMappingRowItems,
    jiraProjectMappingFieldData,
  } = useJiraProjectModalContext();
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const customLpFields = useSelector((state) =>
    getNonSystemFieldsByFieldTypesUsedOnTasks(state, supportedJiraMappingCustomFieldTypes)
  );
  const customLpFieldsAsNameMapping: Array<FieldMappingValue> = customLpFields.map((field) => {
    return {
      name: field.name,
      id: field.id.toString(),
      type: field.fieldType,
    };
  });
  const allLpFields = [...lpNameSystemMapping, ...customLpFieldsAsNameMapping].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const jiraAcademyPermalink = frontend.portableAcademyLesson.url({
    portableLinkName: 'integrations_jira_integration',
  });

  const jiraIds = values[JiraProjectModalFormFields.FieldMappings].map((field) => field.sourceFieldId);
  const lpIds = values[JiraProjectModalFormFields.FieldMappings]
    .map((field) => field.lpCustomFieldId || field.lpSystemField)
    .toString();
  const filteredJiraProjectMappingFieldData = jiraProjectMappingFieldData.filter((item) => !jiraIds.includes(item.id));
  const filteredLpFields = allLpFields.filter((item) => !lpIds.includes(item.id));

  const handleAddFieldClick: HandleAddFieldClick = ({
    event,
    fieldType,
  }: {
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    fieldType: FieldMappingRowType;
  }) => {
    event.preventDefault();
    const rows =
      fieldType === FieldMappingRowType.JiraToLp ? addedJiraFieldMappingRowItems : addedLpFieldMappingRowItems;
    const data = fieldType === FieldMappingRowType.JiraToLp ? filteredJiraProjectMappingFieldData : filteredLpFields;
    const setRows =
      fieldType === FieldMappingRowType.JiraToLp ? setAddedJiraFieldMappingRowItems : setAddedLpFieldMappingRowItems;
    addFieldMappingRow({ rows, data, setRows });
  };

  const handleDeleteRowClick: HandleDeleteRowClick = ({
    fieldType,
    nameMapping,
  }: {
    fieldType: FieldMappingRowType;
    nameMapping: FieldMappingValue;
  }) => {
    const rows =
      fieldType === FieldMappingRowType.JiraToLp ? addedJiraFieldMappingRowItems : addedLpFieldMappingRowItems;
    const setRows =
      fieldType === FieldMappingRowType.JiraToLp ? setAddedJiraFieldMappingRowItems : setAddedLpFieldMappingRowItems;
    deleteFieldMappingRow({ rows, setRows, row: nameMapping });
  };

  const shouldDisableAddFieldButton: ShouldDisableAddFieldButton = (fieldType: FieldMappingRowType) => {
    const data = fieldType === FieldMappingRowType.JiraToLp ? filteredJiraProjectMappingFieldData : filteredLpFields;
    const rows =
      fieldType === FieldMappingRowType.JiraToLp ? addedJiraFieldMappingRowItems : addedLpFieldMappingRowItems;
    return shouldDisableAddRowButton({ data, rows });
  };

  return (
    <>
      <JiraProjectModalInfo
        title="Field Mapping"
        helperText={
          <>
            <span>
              Map system fields to custom data. If additional custom fields are needed, they can be created and mapped
              post setup.
              <br />
              Click{' '}
              <LpLink target="_blank" rel="noopener noreferrer" to={jiraAcademyPermalink}>
                here
              </LpLink>{' '}
              to explore the comprehensive glossary for LiquidPlanner Fields.
            </span>
          </>
        }
      />
      <div>
        <FieldMappingTable
          shouldDisableAddFieldButton={shouldDisableAddFieldButton}
          handleAddFieldClick={handleAddFieldClick}
          handleDeleteRowClick={handleDeleteRowClick}
          rowType={FieldMappingRowType.JiraToLp}
          allLpFields={allLpFields}
        />
      </div>
      <div>
        <FieldMappingTable
          shouldDisableAddFieldButton={shouldDisableAddFieldButton}
          handleAddFieldClick={handleAddFieldClick}
          handleDeleteRowClick={handleDeleteRowClick}
          rowType={FieldMappingRowType.LpToJira}
          allLpFields={allLpFields}
        />
      </div>
    </>
  );
};

export default DateAndFieldMappingSection;
