import { Editor } from '@tinymce/tinymce-react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import { useSelector } from 'react-redux';
import tinymce from 'tinymce/tinymce';

import relightVersion from 'lib/relight_version';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';

import { useHandleImageUpload } from './image_uploader';

/* In certain browsers – notably Firefox and IE – tinymce will generate incorrect
paths for resolving plugins and skins. To ensure these packages are always
referenced from the root, set tinymce's baseURL to the document's baseURI.
More here: https://stackoverflow.com/questions/4412589/tinymce-paths-how-to-specify-where-to-load-things-like-editor-plugin-js */
tinymce.baseURL = `${document.baseURI}assets/tinymce/${relightVersion}`;

const tinymceUniqueId = 'tiny-mce-rich-text-area';

interface RichTextEditorProps {
  content: string;
  handleEditorChange: (value: string) => void;
  height?: string;
  uniqueTinymceId?: string;
}

const RichTextEditor = ({
  content,
  handleEditorChange,
  height = '100%',
  uniqueTinymceId = tinymceUniqueId,
}: RichTextEditorProps) => {
  const { handleImageUpload } = useHandleImageUpload();

  const orgUserName = useSelector(getCurrentOrganizationUser)?.username;

  const initValues: IProps['init'] = {
    branding: false,
    browser_spellcheck: true,
    content_css: `/assets/tinymce/${relightVersion}/styles/content.css`,
    contextmenu: false,
    convert_unsafe_embeds: true,
    convert_urls: false,
    font_formats:
      'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Open Sans=Open Sans; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
    fontsize_formats: '8px 11px 13px 14px 19px 22px 24px 32px 48px 64px',
    height,
    images_upload_handler: handleImageUpload,
    insertdatetime_formats: [
      '%D - ',
      '%I:%M:%S %p - ',
      '%D %I:%M:%S %p - ',
      '%Y-%m-%d - ',
      '%H:%M:%S - ',
      '%Y-%m-%d %H:%M:%S - ',
    ],
    link_default_target: '_blank',
    link_target_list: [{ title: 'New Window', value: '_blank' }],
    menubar: 'edit view insert format table help',
    removed_menuitems: 'preview fullscreen',
    paste_data_images: true,
    promotion: false,
    plugins: [
      'advlist',
      'autolink',
      'charmap',
      'code',
      'codesample',
      'emoticons',
      'help',
      'image',
      'insertdatetime',
      'link',
      'lists',
      'nonbreaking',
      'searchreplace',
      'table',
      'visualblocks',
      'visualchars',
    ],
    resize: false,
    sandbox_iframes: true,
    statusbar: false,
    toolbar_mode: 'sliding',
    toolbar:
      'undo redo | bold italic underline strikethrough blockquote | outdent indent bullist numlist lineheight align | styles forecolor backcolor removeformat | code insertdatetime image link emoticons charmap nonbreaking hr codesample print | fontselect fontsizeselect | table tablecellprops tablerowprops tableprops',
    color_default_background: '#FBEEB8',
    insertdatetime_formats_default: `%D: ${orgUserName} - `,
  };

  if (uniqueTinymceId === tinymceUniqueId) {
    initValues.auto_focus = tinymceUniqueId;
  }

  if (orgUserName) {
    initValues.insertdatetime_formats = [`%D: ${orgUserName} - `, `%Y-%m-%d: ${orgUserName} - `].concat(
      initValues.insertdatetime_formats
    );
  }

  return <Editor id={uniqueTinymceId} init={initValues} initialValue={content} onEditorChange={handleEditorChange} />;
};

export default RichTextEditor;
