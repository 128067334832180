import { Dashboard } from 'daos/model_types';

export const filterDashboardByName = (filter: string, sortedDashboardsByWsIdMap: Map<string, Array<Dashboard>>) => {
  if (!filter) {
    return sortedDashboardsByWsIdMap;
  }

  const filteredMap = new Map<string, Array<Dashboard>>();
  sortedDashboardsByWsIdMap.forEach((dashboards, wsId) => {
    const filteredDashboards: Array<Dashboard> = [];

    dashboards.forEach((dashboard) => {
      if (dashboard.defaultName.toLowerCase().includes(filter.toLowerCase())) {
        filteredDashboards.push(dashboard);
      }
    });

    if (filteredDashboards.length > 0) {
      filteredMap.set(wsId, filteredDashboards);
    }
  });

  return filteredMap;
};
