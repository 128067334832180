import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';

import { OrganizationLinkCrumb } from 'features/common/breadcrumbs/breadcrumb_helpers';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { slack } from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';
const SlackIntegrationTopNavHeader = () => {
  const pathname = useLocation().pathname;
  const organization = useSelector(getCurrentOrganization);
  const organizationUser = useSelector(getCurrentOrganizationUser);

  if (!organization || !organizationUser) {
    return null;
  }

  const menuTabs = [
    {
      content: NavigationMenuDisplay.Configuration,
      to: frontend.organizationHubUsersSlackIntegrations.url({
        organizationId: organization.id,
        organizationUserId: organizationUser.id,
      }),
      active: !!matchPath(pathname, {
        exact: true,
        path: [frontend.organizationHubUsersSlackIntegrations.pattern],
      }),
    },
    {
      content: NavigationMenuDisplay.Connections,
      to: frontend.organizationHubUsersSlackIntegrationsAdmin.url({
        organizationId: organization.id,
        organizationUserId: organizationUser.id,
      }),
      active: !!matchPath(pathname, {
        exact: true,
        path: [frontend.organizationHubUsersSlackIntegrationsAdmin.pattern],
      }),
    },
  ];

  return (
    <TopNavHeader
      breadcrumb={
        <LpBreadcrumb
          sections={[
            { key: 'organization-name', content: <OrganizationLinkCrumb icon={slack} /> },
            { key: NavigationMenuDisplay.SlackIntegration, content: NavigationMenuDisplay.SlackIntegration },
          ]}
        />
      }
      menuTabs={organizationUser.admin ? menuTabs : undefined}
    />
  );
};

export default SlackIntegrationTopNavHeader;
