import { FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { customColumnDefaultProps } from 'containers/shared/custom_column/helpers';
import { selectAllColumn } from 'features/common/data_grid/column_definitions/select_all_column_definition';
import { ClientSideColumnSort } from 'features/common/data_grid/enums';
import {
  ConnectedOnKeyFormatter,
  OrgUserNameFormatter,
  SlackWorkspaceNameFormatter,
} from 'features/common/data_grid/formatters/slack_integration';
import {
  SelectionDropdownLocation,
  SelectionDropdownOptions,
} from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { COLUMN_WIDTH_L } from 'lib/constants';

const connectedOn = 'Connected On';
const connectedOnKey = StandardColumns.CreatedAt;
const width = COLUMN_WIDTH_L;

export const orgUserNameColumn = {
  ...customColumnDefaultProps(FrontendColumns.OrgUserName),
  sortType: ClientSideColumnSort.String,
  formatter: OrgUserNameFormatter,
};
export const slackWorkspaceNameColumn = {
  ...customColumnDefaultProps(FrontendColumns.SlackWorkspaceName),
  sortType: ClientSideColumnSort.String,
  maxWidth: width,
  formatter: SlackWorkspaceNameFormatter,
};
export const connectedOnColumn = {
  name: connectedOn,
  key: connectedOnKey,
  width,
  resizable: true,
  sortable: true,
  sortType: ClientSideColumnSort.String,
  maxWidth: width,
  formatter: ConnectedOnKeyFormatter,
};

export const selectAllConnectionsColumn = (onSelect: (selectOption: SelectionDropdownOptions) => void) => {
  return selectAllColumn(onSelect, SelectionDropdownLocation.SlackIntegration);
};
