import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import { DashboardDao } from 'daos/dashboards';
import { PackageStatus } from 'daos/enums';
import { Dashboard } from 'daos/model_types';
import PageNotFound from 'features/common/errors/page_not_found';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { getCollectionDashboardRoutes } from 'features/dashboards_v2/dashboard/routes/helpers';
import { LibraryLocation } from 'features/library/types';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getCurrentUserHasGuestAccessToDashboard } from 'redux/entities/selectors/dashboard_guest';
import {
  getLibraryLocationForLibraryResourceId,
  getLibraryResourcesForDashboardId,
} from 'redux/entities/selectors/library_resources';

import 'features/common/portable_link_handler/index.scss';

interface PortableDashboardLinkHandlerProps {
  workspaceId: string;
  organizationId: string;
  itemId?: string;
  dashboardId: string;
}

export const PortableDashboardLinkHandler = () => {
  const dispatch = useDispatch();

  const { workspaceId = '', organizationId = '', dashboardId = '' } = useParams<PortableDashboardLinkHandlerProps>();

  const hasGuestAccessToDashboard = useSelector((state) =>
    getCurrentUserHasGuestAccessToDashboard(state, organizationId, workspaceId, dashboardId)
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined);

  const libraryResourceForDashboard = useSelector((state) =>
    dashboard ? getLibraryResourcesForDashboardId(state, dashboard.id) : undefined
  );

  const libraryLocation = useSelector((state) =>
    libraryResourceForDashboard
      ? getLibraryLocationForLibraryResourceId(state, libraryResourceForDashboard.id)
      : undefined
  );

  const fetchDashboard = useCallback(() => {
    const { uuid } = dispatch(
      DashboardDao.fetch(
        { organizationId, workspaceId, dashboardId },
        { include: { includeLibraryResources: true, includeLibraryResourcesItem: true } }
      )
    );

    dispatch(
      awaitRequestFinish<Dashboard>(uuid, {
        onSuccess: ({ data }) => setDashboard(data),
        onError: () => setDashboard(undefined),
        onFinish: () => setLoading(false),
      })
    );
  }, [dashboardId, dispatch, organizationId, workspaceId]);

  useEffect(() => {
    if (dashboardId) {
      fetchDashboard();
    } else {
      setLoading(false);
    }
  }, [dashboardId, fetchDashboard]);

  if (loading) {
    return (
      <div className="portable-link-handler__container">
        <LpOverlayLoader />
      </div>
    );
  }

  if (!dashboard) {
    return <PageNotFound />;
  }

  if (hasGuestAccessToDashboard) {
    return <Redirect to={frontend.dashboardGuest.url({ organizationId, workspaceId, dashboardId })} />;
  }

  const dashboardUrl = getDashboardRedirectUrlForNonGuest(
    dashboard,
    organizationId,
    libraryLocation,
    libraryResourceForDashboard?.packageStatus ?? undefined
  );
  return <Redirect to={dashboardUrl} />;
};

function getDashboardRedirectUrlForNonGuest(
  dashboard: Dashboard,
  organizationId: string,
  libraryLocation: LibraryLocation | undefined,
  packageStatus: PackageStatus | undefined
) {
  const { id: dashboardId, itemId, workspaceId } = dashboard;

  switch (libraryLocation) {
    case LibraryLocation.Workspace:
      return frontend.workspaceDashboard.url({ dashboardId, workspaceId, organizationId });
    case LibraryLocation.Collection: {
      const collectionDashboardRoutes = getCollectionDashboardRoutes(
        { organizationId, workspaceId, dashboardId },
        packageStatus
      );
      return (
        collectionDashboardRoutes?.dashboardRoute ?? frontend.workspaceLibrary.url({ workspaceId, organizationId })
      );
    }

    case LibraryLocation.Package: {
      return frontend.packageLibraryDashboard.url({ organizationId, workspaceId, packageId: itemId, dashboardId });
    }

    case LibraryLocation.Project: {
      return frontend.projectLibraryDashboard.url({ organizationId, workspaceId, itemId, dashboardId });
    }
  }

  return frontend.workspaceDashboard.url({ dashboardId, workspaceId, organizationId });
}
