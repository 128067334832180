import { createSelector } from 'reselect';

import { UserWorkspaceSettingFieldName, WorkloadDayRange } from 'daos/enums';
import { NoContentLocation } from 'features/common/no_content/no_content_helpers';
import { defaultWorkloadDayRange } from 'features/common/workload/workload_day_range_dropdown';
import { RootState } from 'redux/root_reducer';

// userWorkspaceSettings.id has no meaning for this object in the FE.
// There will only ever be a single key:value, which is for the current workspaceUser.
export const getCurrentWorkspaceUserSettings = ({ entities }: RootState) =>
  Object.values(entities.userWorkspaceSettings)?.[0];

export const getWorkloadDayRangeFromCurrentWorkspaceUserSettings = createSelector(
  getCurrentWorkspaceUserSettings,
  (currentWorkspaceUserSettings) => {
    const dayRange = currentWorkspaceUserSettings?.workloadDayRange;

    // The BE is typed to number, so we coerce it to the default if it isn't one of our supported FE enum values
    if (!dayRange || !Object.values(WorkloadDayRange).includes(dayRange)) {
      return defaultWorkloadDayRange;
    }

    return dayRange;
  }
);

export const defaultWorkloadFilterSettings = {
  groupIdFilter: null,
  organizationUserIdFilter: null,
};

export const getWorkloadFilterWorkspaceUserSettings = createSelector(
  getCurrentWorkspaceUserSettings,
  (_: RootState, location: NoContentLocation) => location,
  (currentWorkspaceUserSettings, location) => {
    switch (location) {
      case NoContentLocation.PackageWorkload:
        return {
          groupIdFilter:
            currentWorkspaceUserSettings?.[UserWorkspaceSettingFieldName.PackageWorkloadGroupIdFilter] ?? null,
          organizationUserIdFilter:
            currentWorkspaceUserSettings?.[UserWorkspaceSettingFieldName.PackageWorkloadOrganizationUserIdFilter] ??
            null,
        };
      case NoContentLocation.ProjectWorkload:
        return {
          groupIdFilter:
            currentWorkspaceUserSettings?.[UserWorkspaceSettingFieldName.ProjectWorkloadGroupIdFilter] ?? null,
          organizationUserIdFilter:
            currentWorkspaceUserSettings?.[UserWorkspaceSettingFieldName.ProjectWorkloadOrganizationUserIdFilter] ??
            null,
        };
      case NoContentLocation.PortfolioWorkload:
        return {
          groupIdFilter:
            currentWorkspaceUserSettings?.[UserWorkspaceSettingFieldName.PortfolioWorkloadGroupIdFilter] ?? null,
          organizationUserIdFilter:
            currentWorkspaceUserSettings?.[UserWorkspaceSettingFieldName.PortfolioWorkloadOrganizationUserIdFilter] ??
            null,
        };
      default:
        return defaultWorkloadFilterSettings;
    }
  }
);
