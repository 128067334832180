import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationDao } from 'daos/organization';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';

const useUsageLimitsForOrg = (numberOfItemsToAdd = 1) => {
  const dispatch = useDispatch();
  const organization = useSelector(getCurrentOrganization);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);

  const workspaceCount = organization?.flags.workspaceCount ?? 0;
  const maxWorkspaceCount = organization?.flags.maxWorkspaceCount;

  const taskCount = organization?.flags.taskCount ?? 0;
  const maxTaskCountHardLimit = organization?.flags.maxTaskCountHardLimit;

  const projectCount = organization?.flags.projectCount ?? 0;
  const maxProjectCountHardLimit = organization?.flags.maxProjectCountHardLimit;

  const canAddItemBasedOnLimits = useCallback(
    ({
      hardLimit,
      currentItemCount,
      numberOfItemsToAdd,
    }: {
      hardLimit: number | null | undefined;
      currentItemCount: number;
      numberOfItemsToAdd: number;
    }) => {
      if (hardLimit === null) {
        return true;
      }

      if (!hardLimit) {
        return false;
      }

      return hardLimit >= currentItemCount + numberOfItemsToAdd;
    },
    []
  );

  const reFetchOrgLimits = () => {
    const { uuid } = dispatch(OrganizationDao.fetch({ organizationId: currentOrganizationId }));
    dispatch(awaitRequestFinish(uuid, {}));
  };

  return {
    canAddWorkspaces: canAddItemBasedOnLimits({
      hardLimit: maxWorkspaceCount,
      currentItemCount: workspaceCount,
      numberOfItemsToAdd: 1,
    }),
    canAddTasks: canAddItemBasedOnLimits({
      hardLimit: maxTaskCountHardLimit,
      currentItemCount: taskCount,
      numberOfItemsToAdd,
    }),
    canAddProjects: canAddItemBasedOnLimits({
      hardLimit: maxProjectCountHardLimit,
      currentItemCount: projectCount,
      numberOfItemsToAdd,
    }),

    // These methods allow a consumer to determine whether more items can be
    // added without changing the state of this hook. They do not count the
    // initial `numberOfItemsToAdd` argument.
    canAddMoreTasks: (count: number): boolean =>
      canAddItemBasedOnLimits({
        hardLimit: maxTaskCountHardLimit,
        currentItemCount: taskCount,
        numberOfItemsToAdd: count,
      }),
    canAddMoreProjects: (count: number): boolean =>
      canAddItemBasedOnLimits({
        hardLimit: maxProjectCountHardLimit,
        currentItemCount: projectCount,
        numberOfItemsToAdd: count,
      }),
    reFetchOrgLimits,
  };
};

export default useUsageLimitsForOrg;
