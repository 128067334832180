import moment from 'moment-timezone';

export const getDeltaDays = ({ expected, target }: { expected?: string | null; target?: string | null }) => {
  if (!expected || !target) {
    return undefined;
  }

  const expectedDay = moment(expected).startOf('day');
  const targetDay = moment(target).startOf('day');

  return expectedDay.diff(targetDay, 'days');
};
