import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router';

import { Organization, Workspace } from 'daos/model_types';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { fetchOrg, fetchWs } from 'features/authentication/helpers';
import { ApiError } from 'lib/api/types';
import { frontend } from 'lib/urls';
import { EntityLookupById } from 'redux/entities/types';

const determineOrgId = (organizations: EntityLookupById<Organization>, pathname?: string) => {
  const defaultOrgId = Object.values(organizations)[0]?.id;

  const match = matchPath<{
    organizationId?: string;
  }>(pathname ?? location.pathname, {
    path: frontend.organization.pattern,
  });

  const matchParamsOrgId = match?.params?.organizationId;
  if (matchParamsOrgId) {
    const matchParamsOrgIdNumber = Number(matchParamsOrgId);
    return isNaN(matchParamsOrgIdNumber) ? 0 : matchParamsOrgIdNumber;
  }

  let localStorageOrgId: string | null | undefined = undefined;
  try {
    localStorageOrgId = localStorage.getItem('organizationId');
    // eslint-disable-next-line no-empty
  } catch (_) {}

  return organizations[Number(localStorageOrgId ?? '')]?.id ?? defaultOrgId;
};

const determineWsId = ({
  defaultWsId,
  workspacesMap,
  ignoreLocalStorageOnEntitySwitch,
}: {
  defaultWsId: number | undefined;
  workspacesMap: EntityLookupById<Workspace>;
  ignoreLocalStorageOnEntitySwitch: boolean;
}) => {
  const match = matchPath<{
    workspaceId?: string;
  }>(location.pathname, {
    path: frontend.workspace.pattern,
  });

  const matchParamsWsId = match?.params?.workspaceId;
  if (matchParamsWsId && !ignoreLocalStorageOnEntitySwitch) {
    const matchParamsWsIdNumber = Number(matchParamsWsId);
    return isNaN(matchParamsWsIdNumber) ? 0 : matchParamsWsIdNumber;
  }

  let localStorageWsId: string | null | undefined = undefined;

  if (!ignoreLocalStorageOnEntitySwitch) {
    try {
      localStorageWsId = localStorage.getItem('workspaceId');
      // eslint-disable-next-line no-empty
    } catch (_) {}
  }

  return workspacesMap[Number(localStorageWsId ?? '')]?.id ?? defaultWsId;
};

interface FrontloadDataWithEntityByIdProps {
  organizations: EntityLookupById<Organization> | undefined;
  pathname?: never;
  providedOrgId?: never;
  providedWsId?: never;
  callbackOnFrontloadDataComplete?: never;
}

interface FrontloadDataWithProvidedEntityIdProps {
  organizations?: never;
  pathname?: never;
  providedOrgId: number | undefined;
  providedWsId: number | undefined;
  callbackOnFrontloadDataComplete?: (workspaceId: number) => void;
}

interface FrontloadDataWithEntityByIdAndRedirectPathnameProps {
  organizations: EntityLookupById<Organization> | undefined;
  pathname: string;
  providedOrgId?: never;
  providedWsId?: never;
  callbackOnFrontloadDataComplete?: never;
}

export type FrontloadDataProps =
  | FrontloadDataWithEntityByIdProps
  | FrontloadDataWithProvidedEntityIdProps
  | FrontloadDataWithEntityByIdAndRedirectPathnameProps;

interface UseFrontloadDataProps {
  setEntityNotFound: Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
  setSelectedOrgId?: Dispatch<SetStateAction<number | undefined>>;
  setSelectedWsId?: Dispatch<SetStateAction<number | undefined>>;
  setServerError?: (error: ApiError) => void;
}

const getCurrentOrganizationIterationFlag = (
  organizationsById?: EntityLookupById<Organization>,
  currentOrgId?: number
) => {
  if (!!organizationsById && !!currentOrgId) {
    return organizationsById[currentOrgId]?.featureFlags.iterationBucket;
  }
};

export const useFrontloadData = ({
  setEntityNotFound,
  setSelectedOrgId,
  setSelectedWsId,
  setServerError,
}: UseFrontloadDataProps) => {
  const dispatch = useDispatch();

  const handleEntityNotFound = (entityId: number | undefined, entityType: EntityTypeNotFound) => {
    if (entityId === 0) {
      setEntityNotFound(entityType);
    }
  };

  const frontloadData = ({
    organizations,
    pathname,
    providedOrgId,
    providedWsId,
    callbackOnFrontloadDataComplete,
  }: FrontloadDataProps) => {
    const organizationId = providedOrgId ?? determineOrgId(organizations ?? {}, pathname);
    const hasIterationFeature = getCurrentOrganizationIterationFlag(organizations, organizationId);

    handleEntityNotFound(organizationId, EntityTypeNotFound.Organization);

    if (organizationId) {
      fetchOrg({
        dispatch,
        organizationId,
        setEntityNotFound,
        setServerError,
        onEntityFetchSuccess: (
          workspaces: Array<Workspace>,
          workspacesMap: EntityLookupById<Workspace> | undefined
        ) => {
          setSelectedOrgId?.(organizationId);

          const workspaceId =
            providedWsId ??
            determineWsId({
              defaultWsId: workspaces[0]?.id,
              workspacesMap: workspacesMap ?? {},
              ignoreLocalStorageOnEntitySwitch: Boolean(providedWsId || providedOrgId),
            });

          handleEntityNotFound(workspaceId, EntityTypeNotFound.Workspace);

          if (workspaceId) {
            fetchWs({
              dispatch,
              organizationId,
              workspaceId,
              setEntityNotFound,
              setServerError,
              onEntityFetchSuccess: () => {
                setSelectedWsId?.(workspaceId);
                callbackOnFrontloadDataComplete?.(workspaceId);
              },
              includeIterations: !!hasIterationFeature,
            });
          }
        },
      });
    }
  };

  return frontloadData;
};
