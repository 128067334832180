import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { MarketingTagKeys, MarketingTags, setMarketingTags } from 'features/common/marketing_tags';

function getFromSearchParams(searchParams: URLSearchParams): MarketingTags {
  const result: MarketingTags = {};

  searchParams.forEach((value, key) => {
    if (key in MarketingTagKeys) {
      result[key as MarketingTagKeys] = value;
    }
  });

  return result;
}

const useMarketingTags = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.search) {
      return;
    }

    // The use of substring here removes the leading "?" character
    const searchParams = new URLSearchParams(location.search.substring(1));
    const newMarketingTags = getFromSearchParams(searchParams);

    if (Object.keys(newMarketingTags).length > 0) {
      setMarketingTags(newMarketingTags);
    }
  }, [location]);
};

export default useMarketingTags;
