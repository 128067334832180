import { ItemType } from 'daos/enums';

export function isAddingItemTypeBeyondAccountLimits({
  itemTypes,
  canAddTasks,
  canAddProjects,
}: {
  itemTypes: Array<ItemType>;
  canAddTasks: boolean;
  canAddProjects: boolean;
}) {
  return (
    (itemTypes.includes(ItemType.TASKS) && !canAddTasks) || (itemTypes.includes(ItemType.PROJECTS) && !canAddProjects)
  );
}
