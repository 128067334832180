import { ReactNode } from 'react';

import LpLink from 'containers/shared/lp_link';
import { frontend } from 'lib/urls';

enum RegistrationErrors {
  RecordNotFound = 'record_not_found',
  DuplicateValue = 'duplicate_value',
}

export const registrationErrors = (code: string, detail?: string) => {
  let registrationError: string | ReactNode | undefined;

  if (code === RegistrationErrors.RecordNotFound && detail && detail.includes('signupKeys')) {
    registrationError = 'Please enter the signup key provided to you by LiquidPlanner staff members.';
    return registrationError;
  } else if (code === RegistrationErrors.DuplicateValue && detail && detail.includes('email')) {
    registrationError = (
      <>
        {'This email address is already in our system. '}
        <span>
          {'Please '}
          <LpLink to={frontend.login.url({})}>{'Sign In'}</LpLink>
          {'.'}
        </span>
      </>
    );
    return registrationError;
  } else {
    registrationError = detail ? detail : 'Contact LiquidPlanner support.';
    return registrationError;
  }
};
