import classNames from 'classnames';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { ChangeEvent, SyntheticEvent } from 'react';
import { Form } from 'semantic-ui-react';

import { formErrorLabel } from './form_field_helpers/form_error_label';

import './lp_form.scss';

const LpFormTextArea = ({
  fieldKey,
  className,
  disabled = false,
  getFieldMeta,
  getFieldProps,
  customOnBlur,
  customOnChange,
  placeholder,
  hideErrorMessage,
  autoFocus,
  onFocus,
  rows = 3,
}: {
  fieldKey: string;
  className?: string;
  disabled?: boolean;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  customOnBlur?: (e: SyntheticEvent) => void;
  customOnChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  hideErrorMessage?: boolean;
  autoFocus?: boolean;
  onFocus?: (e: FocusEvent) => void;
  rows?: number;
}) => {
  const fieldMeta = getFieldMeta(fieldKey);
  const fieldProps = getFieldProps(fieldKey);

  const handleOnBlur = (e: SyntheticEvent) => {
    customOnBlur?.(e);
    fieldProps.onBlur(e);
  };

  const handleOnHandle = (e: ChangeEvent<HTMLTextAreaElement>) => {
    customOnChange?.(e);
    fieldProps.onChange(e);
  };

  return (
    <Form.TextArea
      autoComplete={'off'}
      autoFocus={autoFocus}
      onFocus={onFocus}
      id={fieldKey}
      rows={rows}
      name={fieldKey}
      className={classNames(className, 'lp-form__text-area')}
      disabled={disabled}
      error={formErrorLabel({ fieldMeta, hideErrorMessage })}
      onChange={handleOnHandle}
      onBlur={handleOnBlur}
      value={fieldProps.value}
      placeholder={placeholder}
    />
  );
};

export default LpFormTextArea;
