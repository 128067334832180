import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { OutpostLocation } from 'features/academy/outpost/outpost_locations';
import { clearOutpostLocation, setOutpostLocation } from 'features/academy/outpost/slice';

export const useSetupOutpost = (location: OutpostLocation | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOutpostLocation(location));
  }, [dispatch, location]);

  useEffect(() => {
    return () => {
      dispatch(clearOutpostLocation());
    };
  }, [dispatch]);
};
