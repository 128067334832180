import { SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { PortalDropdown } from 'features/common/inputs/dropdowns/portal_dropdown';
import { LpIcon, jira } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';
import { FieldMappingRowType, FieldMappingValue } from 'features/jira_project/modal/types';

import 'features/common/custom_fields/custom_field_value/custom_field_value.scss';

const noneOption: Array<DropdownItemProps> = [{ key: 'NONE', text: <span> None </span>, value: undefined }];

interface PicklistDropdownProps {
  disabled?: boolean;
  picklist: ReadonlyArray<FieldMappingValue>;
  onClose?: () => void;
  onChange?: (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => void;
  rowType?: FieldMappingRowType;
  nameMapping: FieldMappingValue;
  isNameColumn?: boolean;
  fieldChoiceId?: string | number;
  showNoneOption?: boolean;
}

const getIcon = ({ rowType, isNameColumn }: { rowType?: FieldMappingRowType; isNameColumn: boolean }) => {
  const jiraIcon = <LpIcon icon={jira} size="lg" />;
  const lpIcon = <LpSvg icon={LpSvgs.LP_WAVY} size={15} />;
  if (rowType === FieldMappingRowType.LpToJira) {
    return isNameColumn ? lpIcon : jiraIcon;
  }
  return isNameColumn ? jiraIcon : lpIcon;
};

const getPicklistOptions = ({
  picklist,
  isNameColumn,
  rowType,
  showNoneOption,
}: {
  picklist: ReadonlyArray<FieldMappingValue>;
  isNameColumn: boolean;
  rowType?: FieldMappingRowType;
  showNoneOption?: boolean;
}) => {
  const icon = getIcon({ rowType, isNameColumn });

  const picklistOptions = picklist.map((pick) => ({
    key: pick.id,
    text: (
      <div className="jira-project-modal__content-table-picklist-option">
        {icon}
        {pick.name}
      </div>
    ),
    search: pick.name?.toLowerCase(),
    value: pick.id,
  }));

  return [...(showNoneOption ? noneOption : []), ...picklistOptions];
};

export const JiraProjectModalFieldMappingPicklist = ({
  disabled = false,
  isNameColumn = true,
  picklist,
  onClose,
  rowType,
  nameMapping,
  onChange,
  fieldChoiceId,
  showNoneOption = false,
}: PicklistDropdownProps) => {
  const picklistOptions = getPicklistOptions({ picklist, isNameColumn, rowType, showNoneOption });

  const handleChange = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (onChange !== undefined) {
      return onChange(_, data as { value: string });
    }
  };

  return (
    <PortalDropdown>
      <Dropdown
        data-testid={`lp-form-dropdown-${nameMapping.name}`}
        className="jira-project-modal__picklist"
        disabled={disabled}
        lazyLoad
        search={lpDropdownSearch}
        onChange={handleChange}
        options={picklistOptions}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        value={fieldChoiceId}
        onClose={onClose}
        placeholder="Add"
      />
    </PortalDropdown>
  );
};
