import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { StoryPointScheme } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { groupRecordBy, readonlyArray } from 'lib/readonly_record';
import { RootState } from 'redux/root_reducer';

import {
  getSchemeOwnerForExternalIntegrationId,
  getSchemeOwnerOrDefaultForProjectId,
} from './story_point_scheme_owners';

const emptyStoryPointSchemes = readonlyArray<StoryPointScheme>([]);
export const getStoryPointSchemesById = (state: RootState) => state.entities.storyPointSchemes;

export const getStoryPointSchemeForId = (state: RootState, storyPointSchemeId: number) =>
  getStoryPointSchemesById(state)[storyPointSchemeId];

const getStoryPointSchemesByWorkspaceId = createSelector(getStoryPointSchemesById, (storyPointSchemesById) => {
  return groupRecordBy(storyPointSchemesById, (storyPointScheme) => storyPointScheme.workspace.id);
});

const getStoryPointSchemeForCurrentWorkspace = createSelector(
  getCurrentWorkspaceId,
  getStoryPointSchemesByWorkspaceId,
  (currentWorkspaceId, storyPointSchemesByWorkSpace) =>
    storyPointSchemesByWorkSpace[currentWorkspaceId] ?? emptyStoryPointSchemes
);

export const getStoryPointSchemeForCurrentWorkspaceSortedByName = createSelector(
  getStoryPointSchemeForCurrentWorkspace,
  (storyPointSchemes) => {
    return readonlyArray(sortBy(storyPointSchemes, (storyPointScheme) => storyPointScheme.displayName.toLowerCase()));
  }
);

export const getStoryPointSchemeForProjectId = createSelector(
  getSchemeOwnerOrDefaultForProjectId,
  getStoryPointSchemesById,
  (storyPointSchemeOwner, storyPointSchemesById) => {
    if (!storyPointSchemeOwner) {
      return undefined;
    }
    return storyPointSchemesById[storyPointSchemeOwner.storyPointScheme?.id ?? 0];
  }
);

export const getStoryPointSchemeForExternalIntegrationId = createSelector(
  getSchemeOwnerForExternalIntegrationId,
  getStoryPointSchemesById,
  (storyPointSchemeOwner, storyPointSchemesById) => {
    if (!storyPointSchemeOwner) {
      return undefined;
    }
    return storyPointSchemesById[storyPointSchemeOwner.storyPointScheme?.id ?? 0];
  }
);
