import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { forwardRef, memo, ReactNode, SyntheticEvent, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { areEqual } from 'react-window';

import LpLink from 'containers/shared/lp_link';
import { Permission, WidgetDataErrorType, WidgetSize, widgetTypeDisplay } from 'daos/enums';
import { DateRangeType } from 'daos/types';
import { calendarRangeLight, cogSolid, LpIcon, windowMaximizeSolid } from 'features/common/lp_icon';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useDashboardContext, useWidgetContext, WidgetContext } from 'features/dashboards_v2/context';
import { camelToHyphenCase } from 'features/dashboards_v2/helpers';
import { IconHeader } from 'features/dashboards_v2/widget/common/headers';
import { useItemWidgetClickThroughUrl } from 'features/dashboards_v2/widget/common/use_item_widget_click_through_url';
import { WidgetToolbar } from 'features/dashboards_v2/widget/toolbar';
import {
  GeneralWidgetDataErrorWarningLabel,
  WidgetDataErrorWarningLabel,
} from 'features/dashboards_v2/widget_data_error_warning';
import { useHasAccess } from 'hooks/use_has_access';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getDateRangeHoverDisplay, shouldDisplayFilteredDateRange } from 'lib/date_range';
import { getThreeWeekDateRangesForCurrentOrganizationUser } from 'redux/entities/selectors/user';

import { useDefaultWidgetTitle } from './use_default_widget_title';
import { useGetItemScopeForWidget } from './use_get_item_for_scope';

import './index.scss';

const EXTRA_SMALL_WIDGET_BODY_WIDTH = 250;
const SMALL_WIDGET_BODY_WIDTH = 350;
const LARGE_WIDGET_BODY_WIDTH = 1000;

interface BaseV2WidgetProps {
  dashboardId: string;
  widgetId: string;
  className?: string;
  layoutId?: string;
  isDragging?: boolean;
  isOverlay?: boolean;
  widgetComponent: ReactNode;
  draggableRef?: (el: HTMLElement | null) => void;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}
export const BaseV2Widget = memo(
  forwardRef<HTMLDivElement, BaseV2WidgetProps>(
    (
      {
        dashboardId,
        widgetId,
        className,
        layoutId,
        widgetComponent,
        isDragging,
        isOverlay,
        draggableRef,
        attributes,
        listeners,
      },
      ref
    ) => {
      const [widthClass, setWidthClass] = useState('');
      const resizeObserver = useRef(
        new ResizeObserver(([entry]) => {
          if (!entry) {
            return;
          }
          if (entry.contentRect.width < EXTRA_SMALL_WIDGET_BODY_WIDTH) {
            setWidthClass('base-v2-widget--extra-small-width');
          } else if (entry.contentRect.width < SMALL_WIDGET_BODY_WIDTH) {
            setWidthClass('base-v2-widget--small-width');
          } else if (entry.contentRect.width > LARGE_WIDGET_BODY_WIDTH) {
            setWidthClass('base-v2-widget--large-width');
          } else {
            setWidthClass('');
          }
        })
      );
      const widgetBodyRef = useCallback((node: HTMLDivElement) => {
        if (node) {
          resizeObserver.current.unobserve(node);
          resizeObserver.current.observe(node);
        }
      }, []);

      const widget = useSelector((state) => getWidgetForId(state, widgetId));
      const widgetUrl = useItemWidgetClickThroughUrl(widget);
      const { inDesign, activeGroupId } = useDashboardContext();

      if (!widget) {
        return null;
      }

      const gridColumn = `span ${widget.widgetSize === WidgetSize.Half ? 1 : 2}`;
      const widgetClassName = `v2-widget-${camelToHyphenCase(widget.widgetType)}`;
      const widgetContentClasses = classNames('base-v2-widget__content', className, widgetClassName);
      const hasError = !!widget.data?.errors.length;

      return (
        <motion.div
          ref={ref}
          className={classNames({
            'base-v2-widget': true,
            'base-v2-widget--dragging': isDragging,
            'base-v2-widget--inDesign': inDesign,
            'base-v2-widget--overlay': isOverlay,
            'base-v2-widget--hidden': activeGroupId,
            [widthClass]: true,
          })}
          layoutId={layoutId}
          style={{
            gridColumn,
          }}
        >
          <WidgetContext.Provider
            value={{
              widget,
              widgetBodyRef,
            }}
          >
            <BaseV2WidgetContent
              to={widgetUrl}
              className={widgetContentClasses}
              disabled={!widgetUrl || inDesign || hasError}
            >
              {inDesign && (
                <WidgetToolbar
                  dashboardId={dashboardId}
                  widgetId={widgetId}
                  draggableRef={draggableRef}
                  attributes={attributes}
                  listeners={listeners}
                />
              )}
              {widgetComponent}
            </BaseV2WidgetContent>
          </WidgetContext.Provider>
        </motion.div>
      );
    }
  ),
  areEqual
);

interface RenderedWidgetProps {
  header?: ReactNode;
  body?: ReactNode;
  noContent?: boolean;
  widgetDataErrorTypes?: ReadonlyArray<WidgetDataErrorType>;
}

export function RenderedWidget({ header, body, noContent, widgetDataErrorTypes }: RenderedWidgetProps) {
  const { inDesign, isGuest, setShowSettingsForWidget, dashboard } = useDashboardContext();
  const { widget, widgetBodyRef } = useWidgetContext();
  const defaultWidgetTitle = useDefaultWidgetTitle(widget);
  const widgetUrl = useItemWidgetClickThroughUrl(widget);
  const item = useGetItemScopeForWidget(widget);
  const { formatLocalDate } = useLocalizedFormats();

  const canEditDashboard = useHasAccess(Permission.MANAGE, dashboard ?? undefined);

  const threeWeekWidgetExplicitDates = useSelector(getThreeWeekDateRangesForCurrentOrganizationUser);

  if (!widget || !dashboard) {
    return null;
  }

  const widgetType = widgetTypeDisplay[widget?.widgetType];

  function onSettingsClick(e: SyntheticEvent) {
    e.preventDefault();
    if (canEditDashboard) {
      setShowSettingsForWidget(widget?.id?.toString() ?? null);
    }
  }

  const widgetDateRangeFilterType = widget?.config?.dateRangeFilterType
    ? (widget.config.dateRangeFilterType as DateRangeType)
    : DateRangeType.AllDates;

  const dateRangeHoverText = getDateRangeHoverDisplay({
    startDate: widget.config.dateRangeFilterFrom,
    endDate: widget.config.dateRangeFilterTo,
    dateRangeFilterType: widgetDateRangeFilterType,
    inheritedDateRange: widget.appliedDateRangeFilter?.ranges ?? [],
    formatter: formatLocalDate,
    threeWeekWindowStartDate: threeWeekWidgetExplicitDates?.startDate ?? null,
    threeWeekWindowEndDate: threeWeekWidgetExplicitDates?.endDate ?? null,
  });

  return (
    <>
      {!inDesign && (
        <header className="base-v2-widget__header">
          <div className="base-v2-widget__header-title">
            {header ?? widget.config.title ?? <IconHeader itemId={item?.id} title={defaultWidgetTitle ?? 'Widget'} />}
          </div>

          {shouldDisplayFilteredDateRange(widget.widgetType) && (
            <div className="base-v2-widget__header-date-range">
              <LpIcon icon={calendarRangeLight} hoverText={dateRangeHoverText} />
            </div>
          )}

          <div className="base-v2-widget__header-expand">
            {widgetUrl && <LpIcon icon={windowMaximizeSolid} hoverText="More Details" />}
          </div>

          {!isGuest && (
            <button className="base-v2-widget__header-settings" onClick={onSettingsClick}>
              <LpIcon
                hoverText="Settings"
                className={classNames(
                  'base-v2-widget__header-settings-icon',
                  !canEditDashboard && 'base-v2-widget__header-settings-icon--disabled'
                )}
                icon={cogSolid}
              />
            </button>
          )}
        </header>
      )}

      <section ref={widgetBodyRef} className="base-v2-widget__body">
        {noContent && <div className="v2-error-widget__body">{`${widgetType} `} could not render</div>}

        {widgetDataErrorTypes?.length === 1 && (
          <WidgetDataErrorWarningLabel
            className="v2-error-widget__body"
            widgetDataErrorType={widgetDataErrorTypes[0]}
          />
        )}

        {widgetDataErrorTypes?.length && widgetDataErrorTypes.length > 1 && (
          <GeneralWidgetDataErrorWarningLabel className="v2-error-widget__body" />
        )}

        {body}
      </section>
    </>
  );
}

interface BaseV2WidgetContentProps {
  className: string;
  disabled: boolean;
  children: ReactNode;
  to: string;
}
function BaseV2WidgetContent({ className, disabled, children, to }: BaseV2WidgetContentProps) {
  if (disabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <LpLink to={to} className={className}>
      {children}
    </LpLink>
  );
}
