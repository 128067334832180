import { MetricWidgetColumn } from 'daos/widget_column';

enum MetricTallyType {
  TaskStatus = 'TaskStatus',
  CustomColumn = 'CustomColumn',
}

export const getMetricsTallyCheckboxValue = (option: MetricWidgetColumn) => {
  if (option.taskStatusId) {
    return {
      type: MetricTallyType.TaskStatus,
      taskStatusId: option.taskStatusId,
    };
  } else {
    return {
      type: MetricTallyType.CustomColumn,
      column: option.column,
    };
  }
};
