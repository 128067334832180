import { afterWrite, ModifierArguments, Placement, StrictModifiers } from '@popperjs/core';

export const includesTopOrEnd = new RegExp('top-start|right-end|left-end');
export const applyMaxSize = (selector: string, dropdownMaxHeight: number, dropdownHeightOffset: number) => ({
  name: 'applyMaxSize',
  enabled: true,
  phase: afterWrite,
  requires: ['maxSize'],
  fn({ state }: ModifierArguments<{}>) {
    if (state.elements.popper) {
      const container = state.elements.popper.querySelector<HTMLElement>(selector);
      if (!container) {
        return;
      }

      const { height } = state.modifiersData.maxSize;

      const maxHeight = Math.min(dropdownMaxHeight, height - dropdownHeightOffset);

      container.style.maxHeight = `${maxHeight}px`;
    }
  },
});

export const applyMatchWidth = () => ({
  name: 'applyMatchWidth',
  enabled: true,
  phase: afterWrite,
  fn({ state }: ModifierArguments<{}>) {
    if (state.elements.popper) {
      const { width } = state.rects.reference;

      state.elements.popper.style.width = `${width}px`;
    }
  },
});

export const applyFlip = (placements: Array<Placement>): StrictModifiers => ({
  name: 'flip',
  phase: afterWrite,
  options: {
    fallbackPlacements: placements,
  },
});
