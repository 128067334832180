import { DropdownItemProps } from 'semantic-ui-react';

import { LpFormikDropdown } from 'features/common/forms/formik/dropdown';
import { arrowUpRightAndArrowDownLeftFromCenterSolid, LpIcon } from 'features/common/lp_icon';

import { WidgetSettingsV2Field } from './field';

import './index.scss';

enum PreviewRowsAndCols {
  Rows = 'listPreviewRows',
  Columns = 'listPreviewColumns',
}

const RowsAndColumnsDropdown = ({ type, options }: { type: PreviewRowsAndCols; options: Array<DropdownItemProps> }) => {
  const fieldName = type === PreviewRowsAndCols.Rows ? 'Depth' : 'Width';
  const helpText = type === PreviewRowsAndCols.Rows ? 'rows' : 'columns';

  return (
    <WidgetSettingsV2Field name={fieldName}>
      <div className="base-v2-widget-settings__content-options-field-width-and-depth">
        <LpFormikDropdown
          className="base-v2-widget-settings__content-options-field-width-and-depth-dropdown"
          name={type}
          selection
          options={options}
        />
        <i className="base-v2-widget-settings__content-options-field-width-and-depth-help">
          <LpIcon
            className="base-v2-widget-settings__content-options-field-width-and-depth-help-icon"
            icon={arrowUpRightAndArrowDownLeftFromCenterSolid}
          />
          {`All ${helpText} show on expand`}
        </i>
      </div>
    </WidgetSettingsV2Field>
  );
};

function getPreviewOptions(previewRowOrColLimit: Array<number>, text: string) {
  return previewRowOrColLimit.map((val) => {
    return { key: `${val}_${text}`, text: `${val} ${text}`, value: val };
  });
}

const rowOptions = getPreviewOptions([3, 5, 10, 15, 20], 'row summary');

export const PreviewRows = () => {
  return <RowsAndColumnsDropdown type={PreviewRowsAndCols.Rows} options={rowOptions} />;
};

const columnOptions = getPreviewOptions([0, 1, 2, 3, 4, 5], 'data columns');

export const PreviewCols = () => {
  return <RowsAndColumnsDropdown type={PreviewRowsAndCols.Columns} options={columnOptions} />;
};
