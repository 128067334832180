import { useDispatch, useSelector } from 'react-redux';
import { Message } from 'semantic-ui-react';

import { getApiError } from './selectors';
import { clearApiError } from './slice';
import './generic_error_message.scss';

const GenericErrorMessage = () => {
  const dispatch = useDispatch();
  const apiError = useSelector(getApiError);

  if (!apiError) {
    return <div className="lp-generic-error-placeholder"></div>;
  }

  const handleDismissError = () => {
    dispatch(clearApiError());
  };

  return <Message className="lp-generic-error" error header={apiError.message} onDismiss={handleDismissError} />;
};

export default GenericErrorMessage;
