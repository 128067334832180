import { createAction } from '@reduxjs/toolkit';

import { EntityState } from 'redux/entities/types';

export const deleteEntityFinished = createAction(
  'request/DELETE_ENTITY_FINISHED',
  (entityType: keyof EntityState, id: number) => ({ payload: { entityType, id } })
);

export const deleteBulkEntityFinished = createAction(
  'request/DELETE_BULK_ENTITIES_FINISHED',
  (entityType: keyof EntityState, ids: ReadonlyArray<number>) => ({ payload: { entityType, ids } })
);
