import { useSelector } from 'react-redux';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { SourceSystem } from 'daos/external_integration_enums';
import { CustomField } from 'daos/model_types';
import { getColumnSelectionList } from 'features/common/selection_list';
import { getNonSpecialActiveCustomFieldsForCurrentWorkspace } from 'redux/entities/selectors/custom_field';
import { getPropertiesWidgetColumns } from 'redux/entities/selectors/system_settings';

export const usePropertiesWidgetCustomFieldsAndCustomColumns = () => {
  const customFields = useSelector(getNonSpecialActiveCustomFieldsForCurrentWorkspace);

  const allAllowedCustomFields = customFields.reduce((acc: Array<CustomField>, currCustomField) => {
    const isJiraField = currCustomField.sourceSystem === SourceSystem.JIRA;

    if (!isJiraField || currCustomField.name.includes('Project Lead')) {
      acc.push(currCustomField);
    }
    return acc;
  }, []);

  const allCustomColumnsExceptCustomField = useSelector(getPropertiesWidgetColumns).filter(
    (column) => column !== StandardColumns.CustomField
  );

  return getColumnSelectionList({
    columnOptions: allCustomColumnsExceptCustomField,
    customFields: allAllowedCustomFields,
  });
};
