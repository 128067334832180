import { AcademyTrack, AcademyTrackLesson } from 'features/academy/daos/model_types';

export const findActiveTrackIndex = (tracks: ReadonlyArray<AcademyTrack>, trackId: string) => {
  const trackIdAsNumber = Number(trackId);
  return tracks.findIndex((track) => track.id === trackIdAsNumber);
};

export const getNextTrackIdInCourse = ({
  courseTracks,
  trackId,
}: {
  courseTracks: ReadonlyArray<AcademyTrack>;
  trackId: string;
}): string => {
  const nextTrackIndex = findActiveTrackIndex(courseTracks, trackId) + 1;
  const nextTrack = courseTracks[nextTrackIndex];
  return nextTrack ? nextTrack.id.toString() : '';
};

const getActiveTrackLessonIndex = (lessonId: string, trackLessonsInCurrentTrack: ReadonlyArray<AcademyTrackLesson>) => {
  return trackLessonsInCurrentTrack.findIndex((trackLesson) => trackLesson.academyLessonId === lessonId);
};

export const getNextTrackLesson = ({
  currentLessonId,
  trackLessonsInCurrentTrack,
  trackLessonsInNextTrack,
}: {
  currentLessonId: string;
  trackLessonsInCurrentTrack: ReadonlyArray<AcademyTrackLesson>;
  trackLessonsInNextTrack: ReadonlyArray<AcademyTrackLesson>;
}) => {
  const currentTrackLessonIndex = getActiveTrackLessonIndex(currentLessonId, trackLessonsInCurrentTrack);
  if (currentTrackLessonIndex === -1) {
    return undefined;
  }
  const nextTrackLessonInCurrentTrack = trackLessonsInCurrentTrack[currentTrackLessonIndex + 1];
  const firstTrackLessonInNextTrack = trackLessonsInNextTrack[0];
  return nextTrackLessonInCurrentTrack ?? firstTrackLessonInNextTrack;
};
