import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Card } from 'semantic-ui-react';

import { LpIcon, globeSolid as workspaceIcon } from 'features/common/lp_icon';
import { frontend } from 'lib/urls';
import { getWorkspaceDataForCards } from 'redux/entities/selectors/workspace';
interface WorkspaceCardProps {
  className: string;
  organizationId: number;
  workspaceId: number;
}

const WorkspaceCard = ({ className, organizationId, workspaceId }: WorkspaceCardProps) => {
  const workspaceData = useSelector((state) => getWorkspaceDataForCards(state, workspaceId));

  if (!workspaceData) {
    return null;
  }

  return (
    <Card className={className} href={frontend.workspaceHub.url({ organizationId, workspaceId })}>
      <Card.Header
        className={classNames(
          `${className}-header`,
          workspaceData.active && `${className}-header${workspaceData.active}`
        )}
        content={
          <span>
            <LpIcon className="icon" size="sm" icon={workspaceIcon} />
            <span>{workspaceData.initials}</span>
          </span>
        }
      />
      <Card.Content className={`${className}-content`} textAlign="center">
        <Card.Description content={workspaceData.name} />
      </Card.Content>
    </Card>
  );
};

export default WorkspaceCard;
