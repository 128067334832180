import { noop } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Grid } from 'semantic-ui-react';

import { UnknownError } from 'features/errors/unknown_error/index';

import './index.scss';

interface ErrorPageProps {
  reset: () => void;
}

export const ErrorPage = ({ reset }: ErrorPageProps) => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener(
      'popstate',
      () => {
        reset();
      },
      { once: true }
    );

    return () => window.removeEventListener('popstate', noop);
  });

  const goBack = () => {
    history.goBack();
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <Grid centered={true} columns={2} className="error-page">
      <Grid.Column className="error-page__column">
        <Button.Group>
          <Button onClick={goBack}>Back</Button>
          <Button.Or />
          <Button onClick={refresh}>Refresh</Button>
        </Button.Group>
        <UnknownError />
      </Grid.Column>
    </Grid>
  );
};
