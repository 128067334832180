import classNames from 'classnames';
import { ReactNode } from 'react';

import { LpHeader } from 'features/common/as_components';
import { LpIcon, IconDefinition, SizeProp } from 'features/common/lp_icon';

import './navigation_section.scss';

interface NavigationSectionProps {
  className?: string;
  headerText?: string;
  headerIcon?: IconDefinition;
  headerIconSize?: SizeProp;
  headerContent?: ReactNode;
  subHeaderText?: ReactNode;
  children?: ReactNode;
}

const NavigationSection = ({
  className,
  headerText,
  headerIcon,
  headerIconSize = '1x',
  headerContent,
  subHeaderText,
  children,
}: NavigationSectionProps) => {
  return (
    <div className={classNames('navigation-section', className)}>
      {(headerContent || headerText || headerIcon || subHeaderText) && (
        <LpHeader headerType="h2" className="navigation-section__header" size="medium">
          <>
            {!!headerIcon && <LpIcon className="nav-section-icon" icon={headerIcon} size={headerIconSize} />}{' '}
            {headerText} {headerContent}
          </>
          {subHeaderText && <div className="navigation-section__sub-header-text">{subHeaderText}</div>}
        </LpHeader>
      )}

      <div className="lp-navigation-buttons">{children}</div>
    </div>
  );
};

export default NavigationSection;
