import { RootState } from 'redux/root_reducer';

const getBanners = (state: RootState) => state.entities.banners;

export const getActiveBanner = (state: RootState) => {
  const banners = getBanners(state);

  const propertyName = Object.getOwnPropertyNames(banners)[0];

  if (!banners || !propertyName) {
    return null;
  }

  return banners[propertyName];
};
