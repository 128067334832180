import { ReactNode } from 'react';

interface WidgetSettingsV2FieldProps {
  name: string;
  children: ReactNode | Array<ReactNode>;
}
export function WidgetSettingsV2Field({ name, children }: WidgetSettingsV2FieldProps) {
  return (
    <>
      <label className="base-v2-widget-settings__content-options-label">{name}</label>
      <div className="base-v2-widget-settings__content-options-field">{children}</div>
    </>
  );
}
