import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ItemType } from 'daos/enums';
import { LibraryResource } from 'daos/model_types';
import { LibraryLocation } from 'features/library/types';
import { EntityLookupById } from 'redux/entities/types';
import { RootState } from 'redux/root_reducer';

import { getItemsById } from './item';
import { createCacheByIdConfig, getNumberArgument } from './shared';

export const getLibraryResourcesById = (state: RootState): EntityLookupById<LibraryResource> =>
  state.entities.libraryResources;

export const getLibraryResourcesForId = (state: RootState, id: number) => getLibraryResourcesById(state)[id];

export const getLibraryResourcesForDashboardId = createSelector(
  getLibraryResourcesById,
  getNumberArgument,
  (libraryResourcesById, dashboardId) => {
    return Object.values(libraryResourcesById).find(
      (libraryResource) => Number(libraryResource.dashboardId) === dashboardId
    );
  }
);

export const getLibraryResourcesForReportId = createSelector(
  getLibraryResourcesById,
  getNumberArgument,
  (libraryResourcesById, reportId) => {
    return Object.values(libraryResourcesById).find((libraryResource) => Number(libraryResource.widgetId) === reportId);
  }
);

export const getLibraryLocationForLibraryResourceId = createCachedSelector(
  getLibraryResourcesById,
  getItemsById,
  getNumberArgument,
  (libraryResourcesById, items, id) => {
    const { itemId, packageStatus } = libraryResourcesById[id] || {};

    if (itemId) {
      const libraryResourcesItemType = items[itemId]?.itemType;

      switch (libraryResourcesItemType) {
        case ItemType.WORKSPACE_ROOTS: {
          if (!packageStatus) {
            return LibraryLocation.Workspace;
          } else {
            return LibraryLocation.Collection;
          }
        }
        case ItemType.PACKAGES:
          return LibraryLocation.Package;
        case ItemType.PROJECTS:
          return LibraryLocation.Project;
      }
    }
  }
)(createCacheByIdConfig());
