import { Workspace } from 'daos/model_types';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { defineModel, reduceIncludedOptions } from './shared';
import { backend } from './urls';

const { createBody, updateBody, resource } = defineModel({
  apiType: 'workspaces',
  relationships: [],
  type: 'WORKSPACE',
});

const { WORKSPACE, resourceId } = resource;

interface Includes {
  includeCostCodes?: boolean;
  includeFavorites?: boolean;
  includeFields?: boolean;
  includeGroups?: boolean;
  includeIterations?: boolean;
  includeTaskStatus?: boolean;
  includeWorkspaceRoot?: boolean;
  includeWorkspaceUsers?: boolean;
  includeRateSheets?: boolean;
  includeCurrentUserWorkspaceSettings?: boolean;
  includeTimesheetEntryLocks?: boolean;
  includeMyInAppNotifications?: boolean;
  includeSystemSettings?: boolean;
}

const includes = ({
  includeCostCodes,
  includeFavorites,
  includeFields,
  includeGroups,
  includeIterations,
  includeWorkspaceRoot,
  includeTaskStatus,
  includeWorkspaceUsers,
  includeRateSheets,
  includeCurrentUserWorkspaceSettings,
  includeTimesheetEntryLocks,
  includeMyInAppNotifications,
  includeSystemSettings,
}: Includes = {}) =>
  reduceIncludedOptions([
    includeCostCodes && 'costCodes',
    includeFavorites && 'favorites.entities',
    includeFields && 'fields.picklistChoices',
    includeGroups && 'groups.workspaceUserGroups',
    includeIterations && 'iterations',
    includeWorkspaceRoot && 'workspaceRoot',
    includeTaskStatus && 'taskStatuses',
    includeWorkspaceUsers && 'workspaceUsers',
    includeRateSheets && 'rateSheets',
    includeCurrentUserWorkspaceSettings && 'currentUserWorkspaceSettings',
    includeTimesheetEntryLocks && 'timesheetEntryLocks',
    includeMyInAppNotifications && 'myInAppNotifications',
    includeSystemSettings && 'system',
  ]);

interface WorkspacesParams {
  organizationId: number;
}

interface SingleWorkspaceParams extends WorkspacesParams {
  workspaceId: number;
}

interface WorkspaceOptions {
  include?: Includes;
}

const fetch = (params: SingleWorkspaceParams, { include }: WorkspaceOptions = {}) =>
  request(backend.workspace.url(params, { include: includes(include) }), WORKSPACE, { method: HttpMethod.GET });

const workspaceCreate = (params: WorkspacesParams, workspace: Partial<Workspace>) =>
  request(backend.workspaces.url(params), WORKSPACE, createBody(workspace));

const workspaceUpdate = (params: SingleWorkspaceParams, workspace: Partial<Workspace>) =>
  request(backend.workspace.url(params), WORKSPACE, updateBody(params.workspaceId, workspace));

export const WorkspaceDao = {
  create: workspaceCreate,
  fetch,
  id: resourceId,
  update: workspaceUpdate,
} as const;
