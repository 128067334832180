import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { SourceSystem } from 'daos/external_integration_enums';
import { RootState } from 'redux/root_reducer';

import { createCacheByIdConfig } from './shared';

export const getExternalIntegrationsForItemId = createCachedSelector(
  (state: RootState) => state.entities.externalIntegrations,
  (_: RootState, itemId: number) => itemId,
  (externalIntegrations, itemId) => {
    return Object.values(externalIntegrations).filter((integration) => integration.itemId === itemId);
  }
)(createCacheByIdConfig());

const getExternalIntegrationsForItemIds = createCachedSelector(
  (state: RootState) => state.entities.externalIntegrations,
  (_: RootState, itemIds: ReadonlyArray<number | undefined>) => itemIds,
  (externalIntegrations, itemIds) =>
    Object.values(externalIntegrations).filter((integration) => itemIds.includes(integration.itemId))
)(createCacheByIdConfig());

export const getIntegratedJiraProjectForItemId = createSelector(
  getExternalIntegrationsForItemId,
  (externalIntegrations) => {
    return externalIntegrations?.find((externalIntegration) => externalIntegration.sourceSystem === SourceSystem.JIRA);
  }
);

export const getIntegratedJiraProjectsForItemIds = createSelector(
  getExternalIntegrationsForItemIds,
  (externalIntegrations) =>
    externalIntegrations?.filter((externalIntegration) => externalIntegration.sourceSystem === SourceSystem.JIRA) ?? []
);
