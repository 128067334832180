import { createSelector } from 'reselect';

import { getNumberArgument } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

const getInAppNotificationsByWorkspaceUserId = ({ entities: { inAppNotifications } }: RootState) => inAppNotifications;

export const getInAppNotificationsForWorkspaceUserId = createSelector(
  getInAppNotificationsByWorkspaceUserId,
  getNumberArgument,
  (inAppNotificationsByWorkspaceUserId, workspaceUserId) => inAppNotificationsByWorkspaceUserId[workspaceUserId]
);
