import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, Input, InputOnChangeData, Table } from 'semantic-ui-react';

import { CustomFieldType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { CustomFieldFilterWithRequiredId } from 'daos/types';
import UserDropdown from 'features/common/inputs/dropdowns/user_dropdown';
import {
  getOrganizationMemberUsersForCurrentWorkspaceSortedByUsername,
  getOrganizationUserInCurrentOrgForUserId,
  getOrganizationUsersById,
} from 'redux/entities/selectors/user';

import CustomFieldProperties from './custom_field_properties';
import { addCustomFieldPropToState, removeCustomFieldPropFromState } from './helpers';

interface CustomFieldRowProps {
  customField: CustomField;
  onChange: (value: ReadonlyArray<CustomFieldFilterWithRequiredId>) => void;
  value: ReadonlyArray<CustomFieldFilterWithRequiredId>;
}

interface NameContainsRowProps {
  value: string;
  onChange: (value: string) => void;
}

interface CreatedByRowProps {
  value?: number;
  onChange: (value?: number) => void;
}

interface OtherPropertyRowProps {
  checked: boolean;
  content: ReactNode;
  name: ReactNode;
  onChange: (checked: boolean) => void;
  fieldId?: number;
}

interface CheckBoxRowProps {
  checked: boolean;
  name: ReactNode;
  onChange: (checked: boolean) => void;
  content?: ReactNode;
}

const OtherPropertyRow = ({ checked, content, name, onChange }: OtherPropertyRowProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleCheckboxChange = (_: SyntheticEvent, { checked }: CheckboxProps) => {
    setIsChecked(!!checked);
    onChange?.(!!checked);
  };

  useEffect(() => setIsChecked(checked), [checked]);

  return (
    <Table.Row>
      <Table.Cell
        className="other-properties-options-table__row-cell-header"
        content={
          <label className="other-properties-options-table__row-cell-header-content">
            <Checkbox
              className="other-properties-options-table__row-cell-checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            {name}
          </label>
        }
      />
      <Table.Cell className="other-properties-options-table__row-cell-body" content={content} />
    </Table.Row>
  );
};

const CellCheckbox = ({
  isChecked,
  handleCheckboxChange,
}: {
  isChecked: boolean;
  handleCheckboxChange: (_: SyntheticEvent, { checked }: CheckboxProps) => void;
}) => (
  <Checkbox
    className="other-properties-options-table__row-cell-checkbox"
    checked={isChecked}
    onChange={handleCheckboxChange}
  />
);

export const CheckBoxRow = ({ checked, name, onChange, content }: CheckBoxRowProps) => {
  const handleCheckboxChange = (_: SyntheticEvent, { checked }: CheckboxProps) => {
    onChange(!!checked);
  };

  const cellContentAndCheckBox = (
    <div className="checkbox-row__content-and-checkbox">
      <CellCheckbox isChecked={checked} handleCheckboxChange={handleCheckboxChange} />
      {content ?? null}
    </div>
  );

  return (
    <Table.Row>
      <Table.Cell
        className="checkbox-row__row-cell-header"
        content={
          <label className="checkbox-row__row-cell-header-content">
            <CellCheckbox isChecked={checked} handleCheckboxChange={handleCheckboxChange} />
            {name}
          </label>
        }
      />
      <Table.Cell className="checkbox-row__row-cell-body" content={cellContentAndCheckBox} />
    </Table.Row>
  );
};

export const NameContainsRow = ({ onChange, value }: NameContainsRowProps) => {
  const onCheckboxChange = (checked: boolean) => {
    if (!checked) {
      onChange('');
    }
  };

  const handleInputBlur = () => {
    onChange(value?.trim() ?? '');
  };

  const handleInputChange = (_: SyntheticEvent, { value }: InputOnChangeData) => {
    onChange(value);
  };

  return (
    <OtherPropertyRow
      checked={!!value}
      content={<Input onBlur={handleInputBlur} onChange={handleInputChange} value={value} />}
      name="Name Contains"
      onChange={onCheckboxChange}
    />
  );
};

export const CreatedByRow = ({ onChange, value }: CreatedByRowProps) => {
  const orgUsersById = useSelector(getOrganizationUsersById);
  const selectedOrgUserId = useSelector((state) =>
    value ? getOrganizationUserInCurrentOrgForUserId(state, value)?.id : undefined
  );
  const orgUsers = useSelector(getOrganizationMemberUsersForCurrentWorkspaceSortedByUsername);

  const onCheckboxChange = (checked: boolean) => {
    if (!checked) {
      onChange(undefined);
    }
  };

  const handleChange = (_: SyntheticEvent, { value }: { value: number | undefined }) => {
    const userId = value ? orgUsersById[value]?.user.id : undefined;
    onChange(userId);
  };

  return (
    <OtherPropertyRow
      checked={!!value}
      content={
        <UserDropdown
          clearable={true}
          newEntry={value === undefined}
          onChange={handleChange}
          orgUsers={orgUsers}
          selectedOrgUserId={selectedOrgUserId}
          placeholder={'Select User'}
        />
      }
      name="Created By"
      onChange={onCheckboxChange}
    />
  );
};

export const CustomFieldRow = ({ customField, onChange, value }: CustomFieldRowProps) => {
  const selectedCustomFieldProp = value.find((cfProp) => cfProp.customFieldId === customField.id);

  const hideArchivedAndUnusedCustomField = !selectedCustomFieldProp && customField.archived;

  if (hideArchivedAndUnusedCustomField) {
    return null;
  }

  const handleCheckboxChange = (checked: boolean) => {
    if (!checked) {
      onChange(removeCustomFieldPropFromState({ fieldId: customField.id, prevState: value }));
    } else if (customField.fieldType === CustomFieldType.CHECKBOX) {
      onChange(
        addCustomFieldPropToState({
          prevState: value,
          newProp: { customFieldId: customField.id, checkedValues: [true] },
        })
      );
    }
  };

  const name = (
    <>
      {customField.name}
      {customField.archived ? <span className="archived">&nbsp;(Archived)</span> : null}
    </>
  );

  return (
    <OtherPropertyRow
      checked={!!selectedCustomFieldProp}
      content={
        <CustomFieldProperties field={customField} onChange={onChange} value={value} disabled={customField.archived} />
      }
      name={name}
      onChange={handleCheckboxChange}
    />
  );
};
