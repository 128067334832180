import classNames from 'classnames';
import { ReactNode } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal, StrictModalProps } from 'semantic-ui-react';

import { LpModalTransition } from 'features/common/modals/lp_modal';

const portal = document.querySelector('#portal');
interface WidgetModalProps {
  children: ReactNode;
  className?: string;
  size?: StrictModalProps['size'];
}

const WidgetModal = ({ className, size = 'small', children }: WidgetModalProps) => {
  return (
    <LpModalTransition>
      <Modal mountNode={portal} className={classNames('lp-modal', className)} open={true} size={size}>
        {children}
      </Modal>
    </LpModalTransition>
  );
};

interface WidgetModalComponentProps {
  children: ReactNode;
  className?: string;
}

export function WidgetModalHeader({ children, className }: WidgetModalComponentProps) {
  return <Modal.Header className={className}>{children}</Modal.Header>;
}

export function WidgetModalContent({ children, className }: WidgetModalComponentProps) {
  return <Modal.Content className={className}>{children}</Modal.Content>;
}

export function WidgetModalActions({ children, className }: WidgetModalComponentProps) {
  return <Modal.Actions className={className}>{children}</Modal.Actions>;
}

export default WidgetModal;
