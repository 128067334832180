import { Signal } from '@preact/signals-react';
import { createContext, useContext } from 'react';

export interface SearchEventProps {
  includeDone: boolean;
  searchTerm: string;
}

export const SearchPageContext = createContext({
  searchEvent: new Signal<SearchEventProps | null>(),
});

export function useSearchPageContext() {
  return useContext(SearchPageContext);
}
