import { LpIcon, squareKanbanSolid } from 'features/common/lp_icon';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { LocationFilters } from 'features/dashboards_v2/widget_settings/location_filters';
import { Title } from 'features/dashboards_v2/widget_settings/title';

export function TaskBoardSettings({ widgetId }: { widgetId: number }) {
  return (
    <WidgetSettingsModalContent
      header={
        <>
          <LpIcon icon={squareKanbanSolid} /> Task Board
        </>
      }
      content={
        <>
          <Title widgetId={widgetId} />
          <LocationSetting />
          <LocationFilters showDateRangeFilter widgetId={widgetId} />
        </>
      }
    />
  );
}
