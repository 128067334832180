import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popup } from 'semantic-ui-react';

import { EmailConfirmationDao, EMAIL_CONFIRMATION_NON_INTERACTIVE_DURATION } from 'daos/email_confirmation';
import { UserDao } from 'daos/user';
import Banner from 'features/common/banner';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import InlineButton from 'features/common/buttons/inline_button';
import { getCurrentUser } from 'features/common/current/selectors';
import LpModal from 'features/common/modals/lp_modal';

import './email_confirmation_banner.scss';

const EmailConfirmationBanner = () => {
  const currentUser = useSelector(getCurrentUser);
  const currentEmailAddress = currentUser?.email;
  const unconfirmedEmailAddress = currentUser?.unconfirmedEmail;
  const dispatch = useDispatch();
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [showCancelEmailChangeDialog, setShowCancelEmailChangeDialog] = useState(false);

  if (!unconfirmedEmailAddress) {
    return null;
  }

  const canCancelEmailChange = unconfirmedEmailAddress !== currentEmailAddress;

  const resendConfirmationEmail = () => {
    // Prevent the user from resending multiple confirmation emails by only allowing
    // the button to be clicked while the confirmation message is not visible
    if (!confirmationSent) {
      dispatch(EmailConfirmationDao.reCreateConfirmation());
      setConfirmationSent(true);
      setTimeout(() => setConfirmationSent(false), EMAIL_CONFIRMATION_NON_INTERACTIVE_DURATION);
    }
  };

  const openEmailChangeDialog = () => {
    setShowCancelEmailChangeDialog(true);
  };

  const closeEmailChangeDialog = () => {
    setShowCancelEmailChangeDialog(false);
  };

  const cancelEmailChange = () => {
    const { update } = UserDao;
    dispatch(update({ userId: currentUser.id }, { unconfirmedEmail: null }));
    closeEmailChangeDialog();
  };

  return (
    <>
      <Banner className="lp-email-confirmation-banner">
        We&apos;ve sent a confirmation email to {currentEmailAddress}. Please check your inbox and click the link to
        keep on planning
        <Popup
          transition="false"
          trigger={
            <InlineButton className="lp-email-confirmation-banner__button-link" onClick={resendConfirmationEmail}>
              Resend Email
            </InlineButton>
          }
          content="Confirmation email sent!"
          open={confirmationSent}
          basic
          position={'bottom center'}
          size="tiny"
        />
        <ContactSupportLink className="lp-email-confirmation-banner__button-link" text={'Support & Feedback'} />
        {canCancelEmailChange && (
          <InlineButton className="lp-email-confirmation-banner__button-link" onClick={openEmailChangeDialog}>
            Cancel Email Change
          </InlineButton>
        )}
      </Banner>
      {showCancelEmailChangeDialog && (
        <LpModal
          size="tiny"
          header={'Are you sure?'}
          content={<p>The pending login email change to {unconfirmedEmailAddress} will be cancelled.</p>}
          actions={
            <>
              <Button content={'Close'} onClick={closeEmailChangeDialog} />
              <Button primary content={'Cancel Email Change'} onClick={cancelEmailChange} />
            </>
          }
          onClose={closeEmailChangeDialog}
        />
      )}
    </>
  );
};

export default EmailConfirmationBanner;
