import { StandardColumns } from 'containers/shared/custom_column/enum';
import { ListWidgetType } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { WidgetConfig } from 'daos/types';
import { getListWidgetFilterQueryString } from 'features/dashboards/widget_click_through/grids/helpers';
import { CustomOptions, QuickFilterColumn } from 'lib/quick_filter_and_search_helpers/quick_filter';

const includeTaskAncestorColumns = new Set<string>([
  StandardColumns.Location,
  StandardColumns.Package,
  StandardColumns.Project,
  StandardColumns.SubFolder,
]);

const includeSubFolderAncestorColumns = new Set<string>([
  StandardColumns.Location,
  StandardColumns.Package,
  StandardColumns.Project,
]);

const includeProjectAncestorColumns = new Set<string>([StandardColumns.Location, StandardColumns.Package]);

const getIncludeAncestorName = (columns: ReadonlyArray<QuickFilterColumn>, listType: ListWidgetType | null) => {
  switch (listType) {
    case ListWidgetType.Tasks:
      return columns.some((colDef) => includeTaskAncestorColumns.has(colDef.columnKey));
    case ListWidgetType.Folders:
      return columns.some((colDef) => includeSubFolderAncestorColumns.has(colDef.columnKey));
    case ListWidgetType.Projects:
      return columns.some((colDef) => includeProjectAncestorColumns.has(colDef.columnKey));
    default:
      return false;
  }
};

const getQuickFilterColumnsForWidgetConfig = (config: WidgetConfig) => {
  return (
    config.columns?.map((colDef) => {
      if (colDef.customFieldId) {
        return { columnKey: CustomOptions.CustomField, customFieldId: colDef.customFieldId };
      }

      return { columnKey: colDef.column };
    }) ?? []
  );
};

export const getSafeListWidgetFilterQueryString = (widget: Widget, inputValue: string) => {
  const listType = widget.config.listType;
  const columns = getQuickFilterColumnsForWidgetConfig(widget.config);
  const includeContainsAncestorName = getIncludeAncestorName(columns, listType);

  return getListWidgetFilterQueryString({ columns, includeContainsAncestorName, inputValue, listType }) ?? '';
};
