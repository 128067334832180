import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlanFamily, SubscriptionStatus, TrialStatus } from 'daos/enums';
import { OrganizationDao } from 'daos/organization';
import { thirdPartyUrls } from 'daos/urls';
import CallToActionButton from 'features/common/calls_to_action/button';
import { CallToActionLink } from 'features/common/calls_to_action/link';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import useOrganizationFetchOnOrganizationModified from 'hooks/use_organization_fetch_on_organization_modified';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { frontend } from 'lib/urls';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getCurrentOrganizationUser } from 'redux/entities/selectors/user';
import './upgrade_page_trial_banner.scss';

const trialBannerDescriptionText = {
  [TrialStatus.AvailableSelfService]: 'Test drive all premium features for 7 days',
  [TrialStatus.AvailableContactSupport]: 'Contact a Product Advisor about trialing again',
  [TrialStatus.InProgress]: 'Trial is in progress',
  [TrialStatus.NotSupported]: '',
};

const NonAdminUserButton = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  return (
    <CallToActionLink
      to={frontend.people.url({
        organizationId,
        workspaceId,
      })}
      className="upgrade-page-feature-trial__banner-actions-button--orange"
    >
      Find an Admin to Start Trial
    </CallToActionLink>
  );
};

const AdminUserButtons = ({ premiumTrialStatus }: { premiumTrialStatus: TrialStatus }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);

  const [isTrialStarting, setIsTrialStarting] = useState(false);

  useEffect(() => {
    if (isTrialStarting && premiumTrialStatus !== TrialStatus.AvailableSelfService) {
      setIsTrialStarting(false);
    }
  }, [isTrialStarting, premiumTrialStatus]);

  const startPremiumFeaturesTrial = () => {
    setIsTrialStarting(true);
    dispatch(OrganizationDao.trialPremiumFeatures({ organizationId: organizationId }));
  };

  const showStartTrialButton = !isTrialStarting && premiumTrialStatus === TrialStatus.AvailableSelfService;

  return (
    <>
      {isTrialStarting && (
        <span className="upgrade-page-feature-trial__banner-actions-starting">{`Starting${HORIZONTAL_ELLIPSIS}`}</span>
      )}

      {showStartTrialButton && (
        <CallToActionButton
          className="upgrade-page-feature-trial__banner-actions-button--slate"
          onClick={startPremiumFeaturesTrial}
        >
          Start Now
        </CallToActionButton>
      )}

      <CallToActionLink
        to={frontend.organizationHubManageAccount.url({ organizationId })}
        className="upgrade-page-feature-trial__banner-actions-button--orange"
      >
        See Plans
      </CallToActionLink>
      <CallToActionLink
        to={thirdPartyUrls.productAdvisor}
        className="upgrade-page-feature-trial__banner-actions-button--orange"
        openInNewWindow
      >
        Contact Us
      </CallToActionLink>
    </>
  );
};

function getUltimateTrialStatusText(expiredUltimateTrial: boolean) {
  return expiredUltimateTrial
    ? 'Contact a Product Advisor about trialing again'
    : 'Contact a Product Advisor for questions & support';
}

const UpgradeFeatureTrialBanner = () => {
  const organization = useSelector(getCurrentOrganization);
  const plan = useSelector(getCurrentOrganizationPlan);
  const orgUser = useSelector(getCurrentOrganizationUser);
  const isAdmin = orgUser?.admin;
  const premiumFeaturesTrialStatus = organization?.flags.premiumFeaturesTrial;
  const showPremiumFeaturesTrialBanner = organization?.flags.premiumFeaturesTrial !== TrialStatus.NotSupported;
  const showUltimateTrialBanner = plan?.family === PlanFamily.UltimateTrial;
  const expiredUltimateTrial =
    plan?.family === PlanFamily.UltimateTrial &&
    organization?.flags.subscriptionStatus === SubscriptionStatus.Cancelled;

  useOrganizationFetchOnOrganizationModified();

  if (!premiumFeaturesTrialStatus || (!showPremiumFeaturesTrialBanner && !showUltimateTrialBanner)) {
    return null;
  }

  const title = showPremiumFeaturesTrialBanner
    ? `Premium Features Trial ${premiumFeaturesTrialStatus === TrialStatus.AvailableContactSupport ? '- Expired' : ''}`
    : `ULTIMATE Trial ${expiredUltimateTrial ? '- Expired' : ''}`;

  const description = showPremiumFeaturesTrialBanner
    ? trialBannerDescriptionText[premiumFeaturesTrialStatus]
    : getUltimateTrialStatusText(expiredUltimateTrial);

  const trialButtons = isAdmin ? (
    <AdminUserButtons premiumTrialStatus={premiumFeaturesTrialStatus} />
  ) : (
    <NonAdminUserButton />
  );

  return (
    <div className="upgrade-page-feature-trial__banner">
      <span className="upgrade-page-feature-trial__banner-title-text">{title}</span>
      <span className="upgrade-page-feature-trial__banner-description-text">{description}</span>
      <span className="upgrade-page-feature-trial__banner-actions">{trialButtons}</span>
    </div>
  );
};

export default UpgradeFeatureTrialBanner;
