import { useSelector } from 'react-redux';

import { PropertyChange } from 'daos/types';
import { getRateSheetForId } from 'redux/entities/selectors/rate_sheet';

const ChangeCardRateSheet = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const rateSheetId = propertyChangeData.value;

  const rateSheet = useSelector((state) => (rateSheetId ? getRateSheetForId(state, rateSheetId) : undefined));

  return <>{rateSheet?.name ?? 'None'}</>;
};

export default ChangeCardRateSheet;
