import { uniqBy } from 'lodash';

import { SelectionList } from 'features/common/selection_list/types';
import { compareStringsAscendingOrder } from 'lib/helpers/comparison_helpers';

export const filteredOptionsByName = (options: Array<SelectionList>, filter: string) => {
  if (filter === '') {
    return options;
  }

  return options.filter((option) => {
    return option.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });
};

export const multiSelectCompareDisplayName = (a: SelectionList, b: SelectionList): number => {
  return compareStringsAscendingOrder(a.name, b.name);
};

export const resetAvailableOptions = (allOptions: Array<SelectionList>, selectedOptions: Array<SelectionList> = []) => {
  const selectedColumnIds = selectedOptions.map((option) => option.id);

  const availableOptions = allOptions.filter((option) => {
    return !selectedColumnIds.includes(option.id);
  });

  return uniqBy(availableOptions, 'id');
};

export const getGridColumnsDisplayName = (selectedOptions: Array<SelectionList>) => {
  const columnSelectionList: Array<SelectionList> = [];

  selectedOptions.forEach((option) => {
    columnSelectionList.push({
      id: option.id,
      name: option.name,
      selectionListType: option.selectionListType,
      sortable: true,
    });
  });

  return columnSelectionList;
};
