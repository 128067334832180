import { useSelector } from 'react-redux';

import { WidgetDataErrorType, WidgetType } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { calculatorSolid } from 'features/common/lp_icon';
import { getDefaultMetricWidgetColumns, getMetricsWidgetColumns } from 'features/dashboards/widgets/columns';
import { SettingsHeader } from 'features/dashboards_v2/widget/common/headers';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { LocationSetting } from 'features/dashboards_v2/widget_settings/location';
import { LocationFilters } from 'features/dashboards_v2/widget_settings/location_filters';
import { Title } from 'features/dashboards_v2/widget_settings/title';
import { WidgetColumnPicker } from 'features/dashboards_v2/widget_settings/widget_column_picker';

import './index.scss';

export const MetricsTallySettings = ({ widget }: { widget: Widget }) => {
  const header = <SettingsHeader icon={calculatorSolid} widgetType={WidgetType.MetricsTally} />;

  const { data } = widget;
  const allMetricsCustomColumns = useSelector(getMetricsWidgetColumns);
  const defaultMetricColumnsOptions = useSelector(getDefaultMetricWidgetColumns);
  const widgetDataError = data?.errors.find((error) => error === WidgetDataErrorType.NO_METRICS_SELECTED_ERROR);

  const content = (
    <>
      <Title widgetId={widget.id} />
      <LocationSetting />
      <LocationFilters showDateRangeFilter widgetId={widget.id} />
      <WidgetColumnPicker
        widgetType={WidgetType.MetricsTally}
        allGridColumns={allMetricsCustomColumns}
        defaultColumns={defaultMetricColumnsOptions}
        widgetDataError={widgetDataError}
      />
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
