import { useState } from 'react';
import { Button } from 'semantic-ui-react';

import { CustomField } from 'daos/model_types';
import { FormatterAddEditGridProps } from 'features/common/data_grid/types';
import LpModal from 'features/common/modals/lp_modal';

import { Content } from './content';

import './multi_field_value_modal.scss';

export interface MultiFieldEditorProps {
  customField: CustomField;
  rowProps: FormatterAddEditGridProps;
}

interface MultiFieldEditModalProps extends MultiFieldEditorProps {
  onClose: () => void;
}
export const MultiFieldValueModal = ({ customField, onClose, rowProps }: MultiFieldEditModalProps) => {
  const initialFieldValues = rowProps.row[customField.id] ?? [];
  const [fieldValues, setFieldValues] = useState(initialFieldValues);

  const handleSave = () => {
    rowProps.onRowChange({ ...rowProps.row, [customField.id]: fieldValues });
    onClose();
  };

  const fieldName = customField.name;
  return (
    <LpModal
      header={`Edit ${fieldName} Values`}
      content={<Content customField={customField} fieldValues={fieldValues} setFieldValues={setFieldValues} />}
      actions={
        <>
          <Button content="Cancel" onClick={onClose} />
          <Button primary content="Save" onClick={handleSave} />
        </>
      }
      onClose={onClose}
    />
  );
};
