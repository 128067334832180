import { DropdownItemProps } from 'semantic-ui-react';

import { ASAPLabelRenderer } from 'containers/shared/notice_icons/asap_label';
import { IterationLabelRenderer } from 'containers/shared/notice_icons/iteration_label';
import {
  ScheduleDirective,
  ScheduleDirectiveToScheduleDirectiveDisplayText,
  ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon,
} from 'daos/enums';
import { Iteration } from 'daos/model_types';
import { ReadonlyRecord } from 'lib/readonly_record';

const iterationScheduleDirectiveDisplayTextByDirective: ReadonlyRecord<ScheduleDirective, string> = {
  [ScheduleDirective.NORMAL]: 'As Prioritized',
  [ScheduleDirective.ASAP_IN_PROJECT]:
    ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[ScheduleDirective.ASAP_IN_PROJECT],
  [ScheduleDirective.ASAP_IN_PACKAGE]:
    ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[ScheduleDirective.ASAP_IN_PACKAGE],
  [ScheduleDirective.ASAP_IN_WORKSPACE]:
    ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[ScheduleDirective.ASAP_IN_WORKSPACE],
  [ScheduleDirective.TRACKING_ONLY]:
    ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[ScheduleDirective.TRACKING_ONLY],
};

export const getScheduleDirectiveDropdownOption = (scheduleDirective: ScheduleDirective) => ({
  text: ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[scheduleDirective],
  value: scheduleDirective,
  icon: <ASAPLabelRenderer scheduleDirective={scheduleDirective} />,
});

const getIterationScheduleDirectiveDropdownOption = (scheduleDirective: ScheduleDirective) => {
  const isNormalDirective = scheduleDirective === ScheduleDirective.NORMAL;
  const text = iterationScheduleDirectiveDisplayTextByDirective[scheduleDirective];
  return {
    text: isNormalDirective ? <div>{text}</div> : text,
    value: scheduleDirective,
    icon: scheduleDirective !== ScheduleDirective.NORMAL && <ASAPLabelRenderer scheduleDirective={scheduleDirective} />,
    search: scheduleDirective,
  };
};

const getIterationDropdownOption = (iteration: Iteration) => ({
  text: iteration.name,
  value: iteration.id,
  icon: <IterationLabelRenderer iteration={iteration} />,
  search: iteration.name.toLocaleLowerCase(),
});

export const getSelectedIterationDropdownText = ({
  iterations,
  selectedIterationId,
  scheduleDirective,
}: {
  iterations: ReadonlyArray<Iteration>;
  selectedIterationId: number | null;
  scheduleDirective: ScheduleDirective;
}) => {
  const selectedIteration = iterations.find((iteration) => iteration.id === selectedIterationId);

  return selectedIteration?.name ?? ScheduleDirectiveToScheduleDirectiveDisplayText[scheduleDirective];
};

const iterationScheduleDirectiveOptions = [
  ScheduleDirective.NORMAL,
  ScheduleDirective.ASAP_IN_WORKSPACE,
  ScheduleDirective.ASAP_IN_PACKAGE,
  ScheduleDirective.ASAP_IN_PROJECT,
].map(getIterationScheduleDirectiveDropdownOption);

export const getIterationPriorityDropdownOptions = (
  selectedIterationId: number | null,
  iterations: ReadonlyArray<Iteration>
) => {
  const iterationOptions = iterations.reduce((acc: Array<DropdownItemProps>, iteration) => {
    if (iteration.done) {
      if (selectedIterationId === iteration.id) {
        acc.push({ ...getIterationDropdownOption(iteration), disabled: true });
      }
    } else {
      acc.push(getIterationDropdownOption(iteration));
    }

    return acc;
  }, []);

  return [...iterationOptions, ...iterationScheduleDirectiveOptions];
};
