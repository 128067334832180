import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { UserType } from 'daos/enums';
import { clearBulkData } from 'features/common/bulk_selection/slice';
import { menuOptions } from 'features/common/inputs/dropdowns/selection_dropdown/dropdown_menu_item';
import SelectionDropdownTriggerButton from 'features/common/inputs/dropdowns/selection_dropdown/dropdown_trigger_button';
import {
  SelectionDropdownLocation,
  SelectionDropdownOptions,
} from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { SelectionDropdownProps } from 'features/common/inputs/dropdowns/selection_dropdown/types';
import { checkSquareSolid, folderRegular, folderClosedSolid, squareRegular } from 'features/common/lp_icon';
import { getPortfolioGridPackageIdsOrderedByPriority } from 'features/ppp/selectors';
import { setPortfolioGridCollapsePackage } from 'features/ppp/slice';

export const SelectDropdownRenderer = ({
  className,
  disabled,
  menuItems,
  tabIndex,
}: {
  className: SelectionDropdownProps['className'];
  disabled: SelectionDropdownProps['disabled'];
  menuItems: ReadonlyArray<JSX.Element>;
  tabIndex: SelectionDropdownProps['tabIndex'];
}) => {
  return (
    <Dropdown
      className={classNames('selection-dropdown', className)}
      icon={null}
      selectOnNavigation={false}
      tabIndex={tabIndex}
      disabled={disabled}
      trigger={<SelectionDropdownTriggerButton className="selection-dropdown__button" />}
    >
      <Dropdown.Menu>{menuItems}</Dropdown.Menu>
    </Dropdown>
  );
};

const SelectionDropdown = ({
  className,
  handleTimesheetCollapseAll,
  hasEditAccess,
  handleTimesheetExpandAll,
  location,
  onSelectAllClick,
  onDeselectAllClick,
  setSelectedItems,
  onSelectAllByUserType,
  tabIndex,
  workLoadUserIds,
  disabled,
}: SelectionDropdownProps) => {
  const pkgIds = useSelector(getPortfolioGridPackageIdsOrderedByPriority);
  const dispatch = useDispatch();

  const handleSelectAllClick = useCallback(
    (_: React.SyntheticEvent, { value }: DropdownItemProps) => {
      if (onSelectAllClick) {
        onSelectAllClick(value as SelectionDropdownOptions);
      }
    },
    [onSelectAllClick]
  );

  const handleDeselectAllClick = useCallback(
    (_: React.SyntheticEvent, { value }: DropdownItemProps) => {
      if (onDeselectAllClick) {
        onDeselectAllClick(value as SelectionDropdownOptions);
      }
    },
    [onDeselectAllClick]
  );

  const handleSelectAllByUserType = useCallback(
    (userType: UserType) => {
      if (onSelectAllByUserType) {
        onSelectAllByUserType(userType);
      }
    },
    [onSelectAllByUserType]
  );

  const handlePackageCollapse = useCallback(
    (collapse: boolean) => {
      if (collapse) {
        dispatch(clearBulkData());
      }

      dispatch(
        setPortfolioGridCollapsePackage({
          ids: pkgIds,
          collapse,
        })
      );
    },
    [dispatch, pkgIds]
  );

  const collapseAllPackages = useCallback(() => handlePackageCollapse(true), [handlePackageCollapse]);

  const expandAllPackages = useCallback(() => handlePackageCollapse(false), [handlePackageCollapse]);

  const expandAllWorkloadUsers = useCallback(
    () => setSelectedItems && setSelectedItems(new Set(workLoadUserIds)),
    [setSelectedItems, workLoadUserIds]
  );

  const collapseAllWorkloadUsers = useCallback(
    () => setSelectedItems && setSelectedItems(new Set()),
    [setSelectedItems]
  );

  const menuItems = useMemo(() => {
    switch (location) {
      case SelectionDropdownLocation.Portfolio: {
        return [
          menuOptions.expandAll(expandAllPackages),
          menuOptions.collapseAll(collapseAllPackages),
          menuOptions.divider('divider-1'),
          menuOptions.unselectAll(handleSelectAllClick, folderRegular),
          menuOptions.selectAll(handleSelectAllClick, folderClosedSolid),
        ];
      }
      case SelectionDropdownLocation.SinglePackage: {
        return [
          menuOptions.unselectAll(handleSelectAllClick, folderRegular),
          menuOptions.selectAll(handleSelectAllClick, folderClosedSolid),
        ];
      }
      case SelectionDropdownLocation.Workload: {
        return [menuOptions.expandAll(expandAllWorkloadUsers), menuOptions.collapseAll(collapseAllWorkloadUsers)];
      }
      case SelectionDropdownLocation.AssignmentGrid:
        return [menuOptions.unselectAll(handleSelectAllClick), menuOptions.selectAll(handleSelectAllClick)];
      case SelectionDropdownLocation.Timesheet:
        return [menuOptions.collapseAll(handleTimesheetCollapseAll), menuOptions.expandAll(handleTimesheetExpandAll)];
      case SelectionDropdownLocation.SlackIntegration:
        return [
          menuOptions.unselectAll(handleDeselectAllClick, squareRegular),
          menuOptions.selectAll(handleSelectAllClick, checkSquareSolid),
        ];
      case SelectionDropdownLocation.Group:
        return [
          menuOptions.unselectAll(handleDeselectAllClick),
          menuOptions.selectResources(() => handleSelectAllByUserType(UserType.Resource)),
          menuOptions.selectPlaceholders(() => handleSelectAllByUserType(UserType.Placeholder)),
          menuOptions.selectMembers(() => handleSelectAllByUserType(UserType.Member), hasEditAccess),
        ];
      default:
        return [
          menuOptions.unselectAll(handleSelectAllClick, squareRegular),
          menuOptions.selectAll(handleSelectAllClick, checkSquareSolid),
        ];
    }
  }, [
    location,
    handleSelectAllClick,
    handleTimesheetCollapseAll,
    handleTimesheetExpandAll,
    handleDeselectAllClick,
    hasEditAccess,
    expandAllPackages,
    collapseAllPackages,
    expandAllWorkloadUsers,
    collapseAllWorkloadUsers,
    handleSelectAllByUserType,
  ]);

  return <SelectDropdownRenderer className={className} disabled={disabled} menuItems={menuItems} tabIndex={tabIndex} />;
};

export default SelectionDropdown;
