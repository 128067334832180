import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { FolderStatusFilter, ItemType, StatusFilterGroups } from 'daos/enums';
import { CustomFolderFilterModal } from 'features/common/custom_item_filter/custom_folder_filter_modal';
import { useApplyProjectFilter } from 'features/common/custom_item_filter/hooks/use_apply_project_filter';
import {
  getCustomItemFilterAtRiskForItemTypeAndLocation,
  getCustomItemFilterCreatedByUserIdForItemTypeAndLocation,
  getCustomItemFilterCustomFieldPropertiesForItemTypeAndLocation,
  getCustomItemFilterCustomProjectStatusFilterForLocation,
  getCustomItemFilterHasFilesForItemTypeAndLocation,
  getCustomItemFilterNameContainsForItemTypeAndLocation,
  getCustomItemFilterWorkLimitRiskForItemTypeAndLocation,
} from 'features/common/custom_item_filter/selectors';
import { CustomItemFilterLocation, CustomProjectFilters } from 'features/common/custom_item_filter/types';
import {
  exclamationTriangleSolid,
  filterSolid,
  folderClosedSolid,
  IconDefinition,
  LpIcon,
} from 'features/common/lp_icon';
import { lpBrandWhite, lpRed, slate600 } from 'style/variables';

import './folder_status_filter_dropdown.scss';
import { FolderFilterStatus, FolderFilterStatusDisplay } from './types';

interface FolderStatusFilterDropdownOption {
  icon: IconDefinition;
  text: FolderStatusFilter | string;
  value: FolderFilterStatus;
  color: string;
  showWhenDataIsAllActive: boolean;
}

const PROJECTS_AT_RISK = 'Projects at Risk';

const options: Array<FolderStatusFilterDropdownOption> = [
  {
    text: FolderFilterStatusDisplay.All,
    value: FolderFilterStatus.All,
    color: slate600,
    icon: folderClosedSolid,
    showWhenDataIsAllActive: false,
  },
  {
    text: FolderFilterStatusDisplay.ActiveOnHold,
    value: FolderFilterStatus.ActiveOnHold,
    color: slate600,
    icon: folderClosedSolid,
    showWhenDataIsAllActive: false,
  },
  {
    text: FolderFilterStatusDisplay.Scheduled,
    value: FolderFilterStatus.Scheduled,
    color: slate600,
    icon: folderClosedSolid,
    showWhenDataIsAllActive: true,
  },
  {
    text: FolderFilterStatusDisplay.OnHold,
    value: FolderFilterStatus.OnHold,
    color: slate600,
    icon: folderClosedSolid,
    showWhenDataIsAllActive: false,
  },
  {
    text: FolderFilterStatusDisplay.Done,
    value: FolderFilterStatus.Done,
    color: slate600,
    icon: folderClosedSolid,
    showWhenDataIsAllActive: false,
  },
  {
    text: PROJECTS_AT_RISK,
    value: FolderFilterStatus.atRisk,
    icon: exclamationTriangleSolid,
    color: lpRed,
    showWhenDataIsAllActive: true,
  },
  {
    icon: filterSolid,
    text: FolderFilterStatusDisplay.Custom,
    value: FolderFilterStatus.Custom,
    color: slate600,
    showWhenDataIsAllActive: true,
  },
];

export const ControlledFolderStatusFilterDropdown = ({
  className,
  defaultFolderStatus = FolderFilterStatus.ActiveOnHold,
  disabled,
  dataIsAllActive = false,
  onChange,
  value,
}: {
  className?: string;
  defaultFolderStatus?: FolderFilterStatus;
  disabled?: boolean;
  dataIsAllActive?: boolean;
  onChange: (value: CustomProjectFilters) => void;
  value: CustomProjectFilters;
}) => {
  const [customItemFilterOpen, setCustomItemFilterOpen] = useState(false);
  const onOpenCustomItemFilterModal = () => setCustomItemFilterOpen(true);
  const closeCustomItemFilterModal = () => setCustomItemFilterOpen(false);
  const currentFolderFilterStatus = getFolderFilterStatusForDropdown(value);

  const handleModalChange = (newValue: CustomProjectFilters) => {
    onChange(newValue);
    closeCustomItemFilterModal();
  };

  const handleDropdownChange = (
    _: React.SyntheticEvent<HTMLDivElement>,
    { value: folderFilterStatus }: DropdownItemProps
  ) => {
    if (folderFilterStatus === FolderFilterStatus.Custom) {
      onOpenCustomItemFilterModal();
    } else {
      onChange({
        projectCreatedByUserId: null,
        projectCustomFieldFilters: [],
        projectNameFilter: '',
        projectScheduleRiskOnly: folderFilterStatus === FolderFilterStatus.atRisk,
        projectWorkLimitRiskOnly: folderFilterStatus === FolderFilterStatus.atRisk,
        projectStatusFilter: getFolderStatusFilter(folderFilterStatus as FolderFilterStatus),
        statusFilterSelection: folderFilterStatus
          ? (folderFilterStatus as FolderFilterStatus)
          : StatusFilterGroups.Custom,
      });
    }
  };

  const availableOptionFilter = useCallback(
    (option: FolderStatusFilterDropdownOption) => !dataIsAllActive || option.showWhenDataIsAllActive,
    [dataIsAllActive]
  );

  const getDefaultOption = useCallback(() => {
    const result = options.filter(availableOptionFilter).find((option) => option.value === defaultFolderStatus);
    if (result) {
      return result;
    }

    throw new Error(
      `Folder status filter dropdown configured with a default folder status of '${defaultFolderStatus}' that matches no menu entries.")`
    );
  }, [availableOptionFilter, defaultFolderStatus]);

  const getSelectedOption = useCallback(() => {
    return (
      options.filter(availableOptionFilter).find((option) => option.value === currentFolderFilterStatus) ??
      getDefaultOption()
    );
  }, [availableOptionFilter, currentFolderFilterStatus, getDefaultOption]);

  const dropdownOptions = options.filter(availableOptionFilter).map((option) => (
    <Dropdown.Item
      onClick={handleDropdownChange}
      key={option.value}
      text={
        <span style={{ pointerEvents: 'none' }}>
          <LpIcon className="icon" color={option.color} icon={option.icon} /> {option.text}
        </span>
      }
      value={option.value}
    />
  ));

  const dropdownTrigger = useMemo(() => {
    const option = getSelectedOption();
    const isDefault = option === getDefaultOption();

    const iconColor = isDefault ? slate600 : lpBrandWhite;

    return (
      <span>
        <LpIcon className="icon" color={iconColor} icon={option.icon} /> {option.text}
      </span>
    );
  }, [getSelectedOption, getDefaultOption]);

  return (
    <>
      {customItemFilterOpen && (
        <CustomFolderFilterModal onApply={handleModalChange} onCancel={closeCustomItemFilterModal} value={value} />
      )}
      <Dropdown
        button
        className={classNames(className, 'folder-status-filter-dropdown', {
          'folder-status-filter-dropdown--filtered': getSelectedOption() !== getDefaultOption(),
        })}
        disabled={disabled}
        selectOnNavigation={false}
        value={currentFolderFilterStatus}
        trigger={dropdownTrigger}
      >
        <Dropdown.Menu>{dropdownOptions}</Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const FolderStatusFilterDropdown = ({
  customItemFilterLocation,
  className,
  dataIsAllActive = false,
  defaultFolderStatus = FolderFilterStatus.ActiveOnHold,
  disabled = false,
}: {
  customItemFilterLocation: CustomItemFilterLocation;
  className?: string;
  dataIsAllActive?: boolean;
  defaultFolderStatus?: FolderFilterStatus;
  disabled?: boolean;
}) => {
  const itemType = ItemType.PROJECTS;
  const applyFilter = useApplyProjectFilter(customItemFilterLocation);

  const folderFilterStatus = useSelector((state) =>
    getCustomItemFilterCustomProjectStatusFilterForLocation(state, customItemFilterLocation)
  );

  const atRisk = useSelector((state) =>
    getCustomItemFilterAtRiskForItemTypeAndLocation(state, itemType, customItemFilterLocation)
  );
  const workLimitRisk = useSelector((state) =>
    getCustomItemFilterWorkLimitRiskForItemTypeAndLocation(state, itemType, customItemFilterLocation)
  );

  const nameContainsValue = useSelector((state) =>
    getCustomItemFilterNameContainsForItemTypeAndLocation(state, itemType, customItemFilterLocation)
  );

  const createdByUserId = useSelector((state) =>
    getCustomItemFilterCreatedByUserIdForItemTypeAndLocation(state, itemType, customItemFilterLocation)
  );

  const otherCustomFieldProperties = useSelector((state) =>
    getCustomItemFilterCustomFieldPropertiesForItemTypeAndLocation(state, itemType, customItemFilterLocation)
  );

  const projectHasFileFilter = useSelector((state) =>
    getCustomItemFilterHasFilesForItemTypeAndLocation(state, itemType, customItemFilterLocation)
  );

  const valuesForControlledComponents = {
    projectCreatedByUserId: createdByUserId,
    projectCustomFieldFilters: otherCustomFieldProperties ?? [],
    projectNameFilter: nameContainsValue ?? '',
    projectScheduleRiskOnly: atRisk,
    projectWorkLimitRiskOnly: workLimitRisk,
    projectStatusFilter: getFolderStatusFilter(folderFilterStatus),
    projectHasFileFilter,
  };

  const handleApplyCustomFilters = useCallback(
    (newValue: CustomProjectFilters) => {
      applyFilter({
        createdByUserId: newValue.projectCreatedByUserId ?? undefined,
        nameContainsValue: newValue.projectNameFilter ?? '',
        otherCustomFieldProperties: newValue.projectCustomFieldFilters ?? [],
        selectedAtRisk: newValue.projectScheduleRiskOnly ?? false,
        selectedHasFiles: newValue.projectHasFileFilter ?? false,
        selectedWorkLimitRisk: newValue.projectWorkLimitRiskOnly ?? false,
        selectedProjectStatusFilter: getFolderFilterStatus(newValue.projectStatusFilter),
        statusFilterSelection: newValue.statusFilterSelection ?? '',
      });
    },
    [applyFilter]
  );

  return (
    <ControlledFolderStatusFilterDropdown
      className={className}
      dataIsAllActive={dataIsAllActive}
      defaultFolderStatus={defaultFolderStatus}
      disabled={disabled}
      onChange={handleApplyCustomFilters}
      value={valuesForControlledComponents}
    />
  );
};

function getFolderFilterStatusForDropdown({
  projectCreatedByUserId,
  projectNameFilter,
  projectStatusFilter,
  projectScheduleRiskOnly,
  projectWorkLimitRiskOnly,
  projectCustomFieldFilters,
  projectHasFileFilter,
}: CustomProjectFilters) {
  if (projectScheduleRiskOnly && projectWorkLimitRiskOnly && projectStatusFilter === FolderStatusFilter.All) {
    return FolderFilterStatus.atRisk;
  }

  if (
    projectCreatedByUserId ||
    projectNameFilter ||
    projectScheduleRiskOnly ||
    projectWorkLimitRiskOnly ||
    projectHasFileFilter ||
    projectCustomFieldFilters?.length
  ) {
    return FolderFilterStatus.Custom;
  }

  return getFolderFilterStatus(projectStatusFilter);
}

function getFolderFilterStatus(folderStatusFilter?: FolderStatusFilter | null) {
  switch (folderStatusFilter) {
    case FolderStatusFilter.All:
      return FolderFilterStatus.All;
    case FolderStatusFilter.ActiveOnHold:
      return FolderFilterStatus.ActiveOnHold;
    case FolderStatusFilter.Done:
      return FolderFilterStatus.Done;
    case FolderStatusFilter.OnHold:
      return FolderFilterStatus.OnHold;
    case FolderStatusFilter.Scheduled:
      return FolderFilterStatus.Scheduled;
    default:
      return FolderFilterStatus.All;
  }
}

function getFolderStatusFilter(folderFilterStatus: FolderFilterStatus) {
  switch (folderFilterStatus) {
    case FolderFilterStatus.All:
      return FolderStatusFilter.All;
    case FolderFilterStatus.ActiveOnHold:
      return FolderStatusFilter.ActiveOnHold;
    case FolderFilterStatus.Done:
      return FolderStatusFilter.Done;
    case FolderFilterStatus.OnHold:
      return FolderStatusFilter.OnHold;
    case FolderFilterStatus.Scheduled:
      return FolderStatusFilter.Scheduled;
    case FolderFilterStatus.atRisk:
    case FolderFilterStatus.Custom:
    default:
      return FolderStatusFilter.All;
  }
}
