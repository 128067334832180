import { all } from 'typed-redux-saga';

import apiRootSaga from './api_saga';
import bugsnagRootSaga from './bugsnag_saga';
import currentActionsSaga from './current_actions_saga';
import errorSaga from './error_saga';
import pendoRootSaga from './pendo_saga';
import segmentRootSaga from './segment_saga';

export default function* rootSaga() {
  yield* all([apiRootSaga(), bugsnagRootSaga(), currentActionsSaga(), errorSaga(), pendoRootSaga(), segmentRootSaga()]);
}
