import { isString } from 'lodash';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { LinkProps, Link, useLocation } from 'react-router-dom';

import { getReloadOnNextNavigation } from 'features/common/current/selectors';

export interface LpLinkProps extends LinkProps {
  to: string | { pathname: string };
  className?: string;
  disabled?: boolean;
  removeFromTabOrder?: boolean;
}

const LpLink = forwardRef<HTMLElement & HTMLAnchorElement, LpLinkProps>(
  (
    {
      component,
      className,
      disabled = false,
      removeFromTabOrder = false,
      to: linkPropsTo,
      replace,
      innerRef,
      ...linkProps
    },
    ref
  ) => {
    const { pathname } = useLocation();
    const reloadOnNextNavigation = useSelector(getReloadOnNextNavigation);
    let reloadOnNextNavigationHref = isString(linkPropsTo) ? linkPropsTo : linkPropsTo.pathname;

    if (reloadOnNextNavigation && isString(reloadOnNextNavigationHref) && reloadOnNextNavigationHref.startsWith('#')) {
      reloadOnNextNavigationHref = `${pathname}${reloadOnNextNavigationHref}`;
    }

    if (disabled) {
      return (
        <span ref={ref} className={className}>
          {linkProps.children}
        </span>
      );
    }

    const tabIndex = removeFromTabOrder ? -1 : 0;

    return reloadOnNextNavigation ? (
      <a ref={ref} tabIndex={tabIndex} className={className} {...linkProps} href={reloadOnNextNavigationHref}>
        {linkProps.children}
      </a>
    ) : (
      <Link
        ref={ref}
        tabIndex={tabIndex}
        className={className}
        to={linkPropsTo}
        component={component}
        innerRef={innerRef}
        replace={replace}
        {...linkProps}
      />
    );
  }
);

export default LpLink;
