import { ReactNode } from 'react';

const NewWindowLink = ({
  to,
  children,
  className,
  e2eTestId,
}: {
  to: string;
  children: ReactNode;
  className?: string;
  e2eTestId?: string;
}) => {
  return (
    <a className={className} href={to} target="_blank" rel="noopener noreferrer nofollow" data-e2e-test-id={e2eTestId}>
      {children}
    </a>
  );
};

export default NewWindowLink;
