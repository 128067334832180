import { WidgetMotionDiv } from 'features/dashboards_v2/widget_click_through/intake/display_helpers';

export const IntakeFormSectionHeader = ({
  title,
  description,
}: {
  title: string | null;
  description: string | null;
}) => {
  if (!title && !description) {
    return null;
  }

  return (
    <WidgetMotionDiv className="intake-widget-user-form-section__sub-section-header">
      {title && <div className="intake-widget-user-form-section__sub-section-header-title">{title}</div>}
      {description && <p className="intake-widget-user-form-section__sub-section-header-description">{description}</p>}
    </WidgetMotionDiv>
  );
};
