import { Column, FormatterProps, SummaryFormatterProps } from 'react-data-grid';

import { customColumnDefinitions, defaultWidths } from 'containers/shared/custom_column';
import { ColumnValueRenderer } from 'containers/shared/custom_column/column_value';
import { CustomFieldNameForGuests } from 'containers/shared/custom_field_name';
import { ListWidgetType, UserType, WidgetType } from 'daos/enums';
import { WidgetWithData } from 'daos/model_types';
import { WidgetConfigColumn } from 'daos/widget_column';
import { deprecatedSelectPeopleRowByUserTypeColumn } from 'features/common/data_grid/column_definitions/select_all_column_definition';
import { getCellClass, getColumnKey } from 'features/common/data_grid/safe_widgets/helpers';
import { EntityRow } from 'features/common/data_grid/types';
import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { ItemNameLink } from 'features/dashboards_v2/widget/widgets/list/table/item_name_cell';
import { PeopleGridUsernameLink } from 'features/dashboards_v2/widget/widgets/list/table/user_name_cell';
import { DeprecatedItemRow, DeprecatedGridSummaryRow } from 'features/dashboards_v2/widget/widgets/list/types';
import { getRendererPropsForWidgetColumn } from 'features/dashboards_v2/widget/widgets/properties_summary/helpers';
import { DeprecatedPeopleRow } from 'features/dashboards_v2/widget/widgets/reports/types';
import { COLUMN_WIDTH_XL, TEXT_ALIGN_LEFT } from 'lib/constants';

export const DeprecatedSafeWidgetGridFormatter = <T extends DeprecatedPeopleRow | DeprecatedItemRow>(
  props: FormatterProps<EntityRow<T, DeprecatedGridSummaryRow>, DeprecatedGridSummaryRow> & {
    configColumn?: WidgetConfigColumn;
    widget: WidgetWithData;
  }
) => {
  const { configColumn, column, row, widget } = props;
  const columnKey = column.key;

  if (columnKey === 'itemName') {
    return <ItemNameLink widget={widget} itemRow={row as DeprecatedItemRow} />;
  }

  if (columnKey === 'username') {
    const rowData = row as DeprecatedPeopleRow;
    return <PeopleGridUsernameLink userData={rowData.user} widget={widget} />;
  }

  if (configColumn) {
    const columnValueProps = getRendererPropsForWidgetColumn(configColumn, row.columnDataMap);

    return <ColumnValueRenderer selectedColumn={configColumn.column} {...columnValueProps} />;
  }

  return null;
};

export const DeprecatedSafeWidgetGridSummaryFormatter = <T extends DeprecatedPeopleRow | DeprecatedItemRow>(
  props: SummaryFormatterProps<DeprecatedGridSummaryRow, T> & { configColumn?: WidgetConfigColumn }
) => {
  const { configColumn, row } = props;
  const isSummaryRow = true;

  if (configColumn) {
    const columnValueProps = getRendererPropsForWidgetColumn(configColumn, row.columnDataMap, isSummaryRow);

    return <ColumnValueRenderer selectedColumn={configColumn.column} {...columnValueProps} />;
  }

  return null;
};

const createDeprecatedPeopleColumnDefinition = ({
  configColumn,
  widget,
}: {
  configColumn: WidgetConfigColumn;
  widget: WidgetWithData;
}) => {
  const dateRangeDisplay = '';

  return {
    name: <CustomFieldNameForGuests configColumn={configColumn} dateRangeDisplay={dateRangeDisplay} />,
    key: getColumnKey(configColumn),
    formatter: (
      props: FormatterProps<EntityRow<DeprecatedPeopleRow, DeprecatedGridSummaryRow>, DeprecatedGridSummaryRow>
    ) => <DeprecatedSafeWidgetGridFormatter {...props} configColumn={configColumn} widget={widget} />,
    summaryFormatter: (
      props: SummaryFormatterProps<DeprecatedGridSummaryRow, EntityRow<DeprecatedPeopleRow, DeprecatedGridSummaryRow>>
    ) => <DeprecatedSafeWidgetGridSummaryFormatter {...props} configColumn={configColumn} />,
    resizable: true,
    sortable: true,
    headerCellClass: TEXT_ALIGN_LEFT,
    cellClass: getCellClass(configColumn),
    width: customColumnDefinitions[configColumn.column]?.widths.grid ?? defaultWidths.grid,
    summaryCellClass: 'grid-total-summary',
  };
};

const getDeprecatedUsernameColumnDefinition = (widget: WidgetWithData) => {
  const isPeopleListWidget = getIsPeopleListWidget(widget);
  return {
    name: 'Username',
    key: 'username',
    formatter: (
      props: FormatterProps<EntityRow<DeprecatedPeopleRow, DeprecatedGridSummaryRow>, DeprecatedGridSummaryRow>
    ) => <DeprecatedSafeWidgetGridFormatter {...props} widget={widget} />,
    summaryFormatter: (
      _props: SummaryFormatterProps<DeprecatedGridSummaryRow, EntityRow<DeprecatedPeopleRow, DeprecatedGridSummaryRow>>
    ) => {
      return <strong>Totals</strong>;
    },
    frozen: isPeopleListWidget,
    sortable: false,
    resizable: true,
    width: COLUMN_WIDTH_XL,
    headerCellClass: TEXT_ALIGN_LEFT,
    summaryCellClass: 'grid-total-summary',
  };
};

const getDeprecatedSelectPeopleColumnDefinition = (
  onSelect: (selection: SelectionDropdownOptions) => void,
  selectedUserType: UserType | undefined,
  hasAccess: boolean
) => {
  return deprecatedSelectPeopleRowByUserTypeColumn(onSelect, hasAccess, selectedUserType);
};

export const getDeprecatedSafeWidgetGridPeopleRows = (rowData: ReadonlyArray<DeprecatedPeopleRow>) => {
  return rowData.map((peopleRow) => ({
    id: peopleRow.user.userId,
    ...peopleRow,
    numberOfRows: 0,
  }));
};

export const getDeprecatedSafeWidgetPeopleGridColumns = (
  widget: WidgetWithData,
  configColumns: ReadonlyArray<WidgetConfigColumn>,
  selectColumnProps: {
    hasAccess: boolean;
    onSelect: (selection: SelectionDropdownOptions) => void;
    selectedUserType: UserType | undefined;
    showSelectColumn: boolean;
  }
) => {
  const columnDefinitions: Array<
    Column<EntityRow<DeprecatedPeopleRow, DeprecatedGridSummaryRow>, DeprecatedGridSummaryRow>
  > = [];

  const isPeopleListWidget = getIsPeopleListWidget(widget);

  if (isPeopleListWidget && selectColumnProps.showSelectColumn) {
    const { hasAccess, onSelect, selectedUserType } = selectColumnProps;
    const selectColumn = getDeprecatedSelectPeopleColumnDefinition(onSelect, selectedUserType, hasAccess);

    columnDefinitions.push(selectColumn);
  }

  columnDefinitions.push(getDeprecatedUsernameColumnDefinition(widget));

  configColumns.forEach((configColumn) => {
    const columnDef = createDeprecatedPeopleColumnDefinition({ configColumn, widget });

    columnDefinitions.push(columnDef);
  });

  return columnDefinitions;
};

function getIsPeopleListWidget(widget: WidgetWithData) {
  const { config, widgetType } = widget;
  const isListWidget = widgetType === WidgetType.List;
  const isUserList = config.listType === ListWidgetType.Users;

  return isListWidget && isUserList;
}
