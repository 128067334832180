import { noop } from 'lodash';
import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { Button, Dropdown, Image } from 'semantic-ui-react';

import { cameraRetroLight, imageSolid, LpIcon } from 'features/common/lp_icon';

export function PreviewImage({
  imageUrl,
  onUpload,
  onRemove,
}: {
  imageUrl: string;
  onUpload: ({ target: { files } }: ChangeEvent<HTMLInputElement>) => void;
  onRemove: () => void;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const openDropdown = () => setShowDropdown(true);
  const closeDropdown = () => setShowDropdown(false);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    onUpload(e);
    closeDropdown();
  };
  return (
    <>
      <Image className="image-upload__preview" src={imageUrl} />
      <ImageUploadIcon text="Click to update or remove image" onClick={openDropdown} />
      <Dropdown className="image-upload__dropdown" icon={null} open={showDropdown}>
        <Dropdown.Menu>
          <Dropdown.Item content={<HiddenImageUploadLabel labelContent="Update Image" onUpload={handleUpload} />} />
          <Dropdown.Item text="Remove Image" onClick={onRemove} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export function ImageUploadIcon({ onClick = noop, text }: { onClick?: () => void; text: string }) {
  return (
    <div className="image-upload__icon" onClick={onClick}>
      <LpIcon icon={cameraRetroLight} size="3x" />
      <p>{text}</p>
    </div>
  );
}

export function HiddenImageUploadLabel({
  labelContent,
  onUpload,
}: {
  labelContent: ReactNode;
  onUpload: ({ target: { files } }: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label className="image-upload__label" htmlFor="imageUpload">
      {labelContent}
      <input hidden id="imageUpload" type="file" accept="image/jpeg, image/gif, image/png" onChange={onUpload} />
    </label>
  );
}

export function HiddenImageUploadButton({
  buttonContent = 'Upload',
  onUpload,
}: {
  buttonContent?: ReactNode;
  onUpload: ({ target: { files } }: ChangeEvent<HTMLInputElement>) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => fileInputRef.current?.click();

  return (
    <>
      <Button icon={<LpIcon icon={imageSolid} />} content={buttonContent} onClick={handleClick} />
      <input
        hidden
        ref={fileInputRef}
        id="imageUpload"
        type="file"
        accept="image/jpeg, image/gif, image/png"
        onChange={onUpload}
      />
    </>
  );
}
