import { timeInputSeconds, TimeUnit, timeUnitLookup } from 'lib/conversion_helpers/time_input_in_hours_usa';
import { hoursInputValidationRegEx } from 'lib/validation_helpers/hours_input_validation_regex';

export const timeStringInSecondsFromTimeStringWithUnits = (decrementValue: string) => {
  const NUMERIC_MATCH_INDEX = 1;
  const UNIT_MATCH_INDEX = 2;

  const match = decrementValue.match(hoursInputValidationRegEx);

  if (match) {
    const matchForUnit = match[UNIT_MATCH_INDEX] ?? '';
    const timeUnit = timeUnitLookup[matchForUnit] ?? TimeUnit.Hours;
    const valueWithoutUnit = match[NUMERIC_MATCH_INDEX];
    return valueWithoutUnit ? timeInputSeconds({ timeUnit, valueWithoutUnit }) : undefined;
  }
  return undefined;
};
