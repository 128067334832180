import { useHistory } from 'react-router';

import { useHasFeature } from 'hooks/use_has_feature';
import useHashParams from 'hooks/use_hash_params';
import { FeatureFlag } from 'lib/feature_flags';

export const useStoryPointsSchemeModalParams = (editSchemeId?: number) => {
  const history = useHistory();
  const hasStoryPointsFeature = useHasFeature(FeatureFlag.storyPoints);
  const { newStoryPointsScheme } = useHashParams();
  const { editStoryPointsSchemeId } = useHashParams();

  const closeStoryPointsSchemeModal = () => {
    history.replace({ ...window.location, hash: '' });
  };
  const openStoryPointsSchemeModal = () => {
    history.replace({ ...window.location, hash: '#newStoryPointsScheme' });
  };
  const openEditStoryPointsSchemeModal = () => {
    if (editSchemeId) {
      history.replace({ ...window.location, hash: `#editStoryPointsSchemeId=${editSchemeId}` });
    }
  };

  const showNewStoryPointsSchemeModal = hasStoryPointsFeature && newStoryPointsScheme != undefined;
  const showEditStoryPointsSchemeModal = hasStoryPointsFeature && editStoryPointsSchemeId != undefined;

  return {
    hasStoryPointsFeature,
    showStoryPointsSchemeModal: showNewStoryPointsSchemeModal || showEditStoryPointsSchemeModal,
    closeStoryPointsSchemeModal,
    openStoryPointsSchemeModal,
    openEditStoryPointsSchemeModal,
  };
};
