import { OriginViewValueType } from 'lib/avo/client';

interface NamedUrl {
  readonly pageName: string;
  readonly viewName: OriginViewValueType | null;
}
export const namedUrlsMatcher: Record<string, NamedUrl> = {};

export function addGenericIdsToPathname(pathname: string) {
  return pathname.replace(/\/\d+/g, '/0');
}

export function addGenericIdsToUrlPattern(pattern: string) {
  return pattern?.replace(/:\w+(\([^)]+\))?/g, '0');
}

export const mapLocationPathnameToLpPageName = (pathname: string) => {
  const sanitizedPathname = addGenericIdsToPathname(pathname);

  const match = namedUrlsMatcher[sanitizedPathname];

  return { pageName: match?.pageName ?? 'LiquidPlanner', viewName: match?.viewName ?? null };
};
