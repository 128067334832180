import { defineModel, reduceIncludedOptions } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

const { resource } = defineModel({
  apiType: 'files',
  type: 'ITEM_FILE',
});

const { ITEM_FILE } = resource;

interface ItemFileParams {
  organizationId: number;
  workspaceId: number;
}

interface ItemFileIncludes {
  includeItems?: boolean;
  includeFiles?: boolean;
}

interface ItemFileOptions {
  include?: ItemFileIncludes;
  filter?: string;

  query?: { limit?: number; order?: string };
}

const fileIncludes = ({ includeItems, includeFiles }: ItemFileIncludes = {}) =>
  reduceIncludedOptions([includeItems && 'items', includeFiles && 'files']);

const fetchAll = (params: ItemFileParams, { include, filter, query }: ItemFileOptions = {}) => {
  return request(backend.itemFilesSearch.url(params, { filter, include: fileIncludes(include), query }), ITEM_FILE, {
    method: HttpMethod.GET,
  });
};

export const ItemFileDao = {
  fetchAll,
};
