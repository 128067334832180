import { useSelector } from 'react-redux';

import { UserType } from 'daos/enums';
import { OrganizationSsoUser } from 'daos/organization_sso_users';
import { SelectionList, SelectionListType } from 'features/common/selection_list/types';
import {
  getConnectedCurrentOrganizationUsers,
  getOrganizationUsersById,
  getUsersById,
} from 'redux/entities/selectors/user';

export const useGroupOrgUsersByAvailableAndSelected = (
  ssoUsers: ReadonlyArray<OrganizationSsoUser> | undefined,
  isNewMembersDefaultEnabled: boolean,
  multiOrgUsersIds: ReadonlyArray<number>
) => {
  const currentOrgUsersById = useSelector(getOrganizationUsersById);
  const usersById = useSelector(getUsersById);
  const currentOrgUsers = useSelector(getConnectedCurrentOrganizationUsers);

  const ssoEnabledForUsers: Array<SelectionList> = [];
  const lpLoginForUsers: Array<SelectionList> = [];

  const userIdsAlreadyInAvailableOrSelected: Array<number> = [];

  if (ssoUsers) {
    ssoUsers.forEach((ssoUser) => {
      const orgUserId = ssoUser.organizationUser.id;
      const orgUser = currentOrgUsersById[orgUserId];
      const user = usersById[orgUser?.user.id ?? 0];

      if (user && user.userType === UserType.Member && orgUser) {
        userIdsAlreadyInAvailableOrSelected.push(orgUserId);

        const disabledPopupText = !ssoUser.ssoAvailable
          ? `SSO cannot be enabled for ${user.email} (${user.firstName} ${user.lastName}) until they are disconnected from all other Organizations.`
          : undefined;

        const selectionListItem: SelectionList = {
          id: String(orgUserId),
          selectionListType: SelectionListType.User,
          name: user.email,
          disabledPopupText,
        };

        if (ssoUser.ssoEnabled && !multiOrgUsersIds.includes(orgUserId)) {
          ssoEnabledForUsers.push(selectionListItem);
        } else {
          lpLoginForUsers.push(selectionListItem);
        }
      }
    });
  }

  const lpLoginOrSsoForNewUsers = isNewMembersDefaultEnabled ? ssoEnabledForUsers : lpLoginForUsers;

  currentOrgUsers.forEach((orgUser) => {
    if (!userIdsAlreadyInAvailableOrSelected.includes(orgUser.id)) {
      const user = usersById[orgUser.user.id];

      if (user && user.userType === UserType.Member) {
        const userSelectionListItem = {
          id: String(orgUser.id),
          selectionListType: SelectionListType.User,
          name: user.email,
        };

        if (multiOrgUsersIds.includes(orgUser.id)) {
          lpLoginForUsers.push(userSelectionListItem);
        } else {
          lpLoginOrSsoForNewUsers.push(userSelectionListItem);
        }
      }
    }
  });

  return { selected: lpLoginForUsers, available: ssoEnabledForUsers };
};
