import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { RateSheetType } from 'daos/enums';
import { RateSheet } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { readonlyArray, groupRecordBy } from 'lib/readonly_record';
import { RootState } from 'redux/root_reducer';

const emptyRateSheetArray = readonlyArray<RateSheet>([]);

const getRateSheetsById = (state: RootState) => state.entities.rateSheets;

export const getRateSheetForId = (state: RootState, id: number | string) => getRateSheetsById(state)[id];

const getRateSheetsByWorkspaceId = createSelector(getRateSheetsById, (rateSheetsById) => {
  return groupRecordBy(rateSheetsById, (rateSheet) => rateSheet.workspaceId);
});

const getCurrentWorkspaceRateSheets = createSelector(
  getRateSheetsByWorkspaceId,
  getCurrentWorkspaceId,
  (rateSheetsByWorkspace, currentWorkspaceId) => rateSheetsByWorkspace[currentWorkspaceId] ?? emptyRateSheetArray
);

export const getCurrentWorkspaceRateSheetsSortedByName = createSelector(getCurrentWorkspaceRateSheets, (rateSheets) => {
  return readonlyArray(sortBy(rateSheets, (rateSheet) => rateSheet.name.toLowerCase()));
});

export const getActiveCurrentWorkspaceRateSheetsSortedByName = createSelector(
  getCurrentWorkspaceRateSheetsSortedByName,
  (rateSheets) => {
    return readonlyArray(rateSheets.filter((rateSheet) => !rateSheet.archived));
  }
);

export const getActiveCurrentWorkspaceBillingRateSheets = createSelector(
  getActiveCurrentWorkspaceRateSheetsSortedByName,
  (rateSheets) => {
    return readonlyArray(rateSheets.filter((rateSheet) => rateSheet.rateSheetType === RateSheetType.BillingRate));
  }
);

export const getActiveCurrentWorkspacePayRateSheets = createSelector(
  getActiveCurrentWorkspaceRateSheetsSortedByName,
  (rateSheets) => {
    return readonlyArray(rateSheets.filter((rateSheet) => rateSheet.rateSheetType === RateSheetType.PayRate));
  }
);

export const getArchivedCurrentWorkspaceRateSheetsSortedByName = createSelector(
  getCurrentWorkspaceRateSheetsSortedByName,
  (rateSheets) => {
    return readonlyArray(rateSheets.filter((rateSheet) => rateSheet.archived));
  }
);
