import classNames from 'classnames';

import { ComponentJoin } from 'features/common/component_join';
import { AssigneeMapByOrgUserId } from 'lib/display_helpers/assignee/types';

import './assigned.scss';

const cellClassName = 'assignees_column';

const AssigneeSpan = ({ username, done, disconnected }: { username: string; done: boolean; disconnected: boolean }) => {
  return (
    <span
      className={classNames(cellClassName, {
        [`${cellClassName}--evicted-user`]: disconnected,
        [`${cellClassName}--complete`]: done,
      })}
    >
      {username}
    </span>
  );
};

export const FormattedAssignees = ({ assigneeMap }: { assigneeMap: AssigneeMapByOrgUserId }) => {
  const assignees: Array<JSX.Element> = [];

  assigneeMap.forEach(({ disconnected, done, username }, orgUserId) => {
    assignees.push(<AssigneeSpan key={orgUserId} username={username} disconnected={disconnected} done={done} />);
  });

  return <ComponentJoin>{assignees}</ComponentJoin>;
};
