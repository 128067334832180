import { memo, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { areEqual, GridChildComponentProps, VariableSizeGrid } from 'react-window';

import ConfirmDeleteModal from 'containers/shared/confirm_delete_modal';
import ItemMoveModal from 'containers/shared/move_modal';
import { WorkloadDayRange } from 'daos/enums';
import { isTrialOrFree } from 'daos/plan';
import { SetItemDeletionParams } from 'features/common/inputs/dropdowns/ellipsis_action_dropdown/types';
import { useWorkloadTableContext, WorkloadTableContext } from 'features/common/workload/workload_table/context';
import {
  ADDITIONAL_COLUMN_COUNT,
  getColumnRenderBooleans,
  getColumnWidthAndSetNameColumnWidth,
  ROW_HEIGHT_PIXELS,
} from 'features/common/workload/workload_table/helpers';
import {
  OrgUserAvailabilityCell,
  OrgUserNubCell,
  OrgUserRemainingCell,
} from 'features/common/workload/workload_table/org_user_cells';
import { AvailabilityCell, NubCell, RemainingCell } from 'features/common/workload/workload_table/task_cells';
import { TrackingBoundingBox, useTrackBoundingBox } from 'hooks/use_track_bound_box';
import { getCurrentOrganizationPlan } from 'redux/entities/selectors/plan';
import { getWorkloadById } from 'redux/entities/selectors/workload';

import { StickyWorkloadTableElements } from './sticky_elements';
import { FlattenedWorkloadData } from './types';

interface WorkloadTableProps {
  dayRange: WorkloadDayRange;
  workloadId: number;
  fetchWorkload: () => void;
  isFiltered: boolean;
  shouldExpandAllUsers: boolean;
}

const Cell = memo(
  ({
    data,
    rowIndex,
    columnIndex,
    style,
  }: PropsWithChildren<GridChildComponentProps<Array<FlattenedWorkloadData>>>) => {
    const row = data[rowIndex];
    const { workloadId, dayRange } = useWorkloadTableContext();
    const columnCount = dayRange + ADDITIONAL_COLUMN_COUNT;
    if (!row || !workloadId) {
      return null;
    }

    const { item, group } = row;

    const { renderNubColumn, renderRemainingColumn, renderAvailabilityUsedColumn } = getColumnRenderBooleans(
      columnIndex,
      columnCount
    );

    if (item) {
      if (renderNubColumn) {
        return (
          <NubCell
            style={style}
            workloadId={workloadId}
            workloadGroupId={item.organizationUser?.id ?? 0}
            workloadItemId={item.id}
            nubIndex={columnIndex - 1}
          />
        );
      } else if (renderRemainingColumn) {
        return (
          <RemainingCell
            style={style}
            workloadItemId={item.id}
            workloadId={workloadId}
            workloadGroupId={item.organizationUser?.id ?? 0}
          />
        );
      } else if (renderAvailabilityUsedColumn) {
        return (
          <AvailabilityCell
            style={style}
            workloadGroupId={item.organizationUser?.id ?? 0}
            workloadItemId={item.id}
            workloadId={workloadId}
          />
        );
      }

      return null;
    }

    if (group) {
      if (renderNubColumn) {
        return (
          <OrgUserNubCell
            style={style}
            workloadId={workloadId}
            orgUserId={group.organizationUser?.id}
            nubIndex={columnIndex - 1}
          />
        );
      } else if (renderRemainingColumn) {
        return <OrgUserRemainingCell style={style} workloadId={workloadId} orgUserId={group.organizationUser?.id} />;
      } else if (renderAvailabilityUsedColumn) {
        return <OrgUserAvailabilityCell style={style} orgUserId={group.organizationUser?.id} workloadId={workloadId} />;
      }
    }

    return null;
  },
  areEqual
);

export const WorkloadTable = memo(
  ({ dayRange, workloadId, fetchWorkload, isFiltered, shouldExpandAllUsers }: WorkloadTableProps) => {
    const gridRef = useRef<VariableSizeGrid>(null);
    const { ref, box } = useTrackBoundingBox();
    const workload = useSelector((state) => getWorkloadById(state, workloadId));
    const planFamily = useSelector(getCurrentOrganizationPlan)?.family;
    const shouldExpand = isTrialOrFree(planFamily);
    const expandedRows = shouldExpand
      ? workload?.groups.reduce<Array<number>>((acc, curr) => {
          if (curr.organizationUser) {
            acc.push(curr.organizationUser.id);
          }

          return acc;
        }, [])
      : [];
    const columnCount = dayRange + ADDITIONAL_COLUMN_COUNT;
    const [expandedOrgUserIds, setExpandedOrgUserIds] = useState<Set<number>>(new Set<number>(expandedRows));
    const [deletionData, setDeletionData] = useState<SetItemDeletionParams | undefined>(undefined);
    const [moveItemId, setMoveItemId] = useState<number | undefined>(undefined);

    const workloadFlattenedData = useMemo(
      () =>
        workload?.groups.reduce<Array<FlattenedWorkloadData>>((acc, curr) => {
          const orgUserId = curr.organizationUser?.id;

          if (!orgUserId) {
            return acc;
          }

          acc.push({
            group: curr,
          });

          const isExpand = expandedOrgUserIds.has(orgUserId);

          const hideEmptyFilteredUser = isFiltered && !curr.workloadItems.length;

          if (hideEmptyFilteredUser) {
            return acc;
          }

          if (isExpand) {
            curr.workloadItems.forEach((item) => {
              acc.push({ item });
            });
          }

          return acc;
        }, []) ?? [],
      [expandedOrgUserIds, isFiltered, workload?.groups]
    );

    useEffect(() => {
      gridRef.current?.resetAfterColumnIndex(0, true);
    }, [box]);

    const handleDeleteClose = () => {
      setDeletionData(undefined);
    };

    const handleToggleByOrgUserId = (orgUserId: number) => {
      if (expandedOrgUserIds.has(orgUserId)) {
        expandedOrgUserIds.delete(orgUserId);
      } else {
        expandedOrgUserIds.add(orgUserId);
      }

      setExpandedOrgUserIds(new Set(expandedOrgUserIds));
    };

    const handleCloseMoveModal = () => {
      fetchWorkload();
      setMoveItemId(undefined);
    };

    useEffect(() => {
      if (shouldExpandAllUsers) {
        const workLoadUserIds = workload?.groups.map((user) => user.id);
        setExpandedOrgUserIds(new Set(workLoadUserIds));
      }
    }, [shouldExpandAllUsers, workload?.groups]);

    if (!workload) {
      return null;
    }

    if (!box) {
      return <TrackingBoundingBox innerRef={ref} />;
    }

    return (
      <WorkloadTableContext.Provider
        value={{
          fetchWorkload,
          handleToggleByOrgUserId,
          setDeletionData,
          setMoveItemId,
          workloadId,
          dayRange,
          workloadFlattenedData,
          workload,
          setExpandedOrgUserIds,
          boundingBox: box,
        }}
      >
        <div className="workload-view">
          {moveItemId && <ItemMoveModal onClose={handleCloseMoveModal} itemId={moveItemId} />}

          {deletionData && (
            <ConfirmDeleteModal
              itemId={deletionData.id}
              onDelete={deletionData.deleteItemClick}
              onClose={handleDeleteClose}
            />
          )}

          <TrackingBoundingBox innerRef={ref}>
            <VariableSizeGrid<Array<FlattenedWorkloadData>>
              ref={gridRef}
              rowHeight={() => ROW_HEIGHT_PIXELS}
              columnWidth={(columnIndex) =>
                getColumnWidthAndSetNameColumnWidth(columnIndex, dayRange, columnCount, box?.width ?? 0)
              }
              innerElementType={StickyWorkloadTableElements}
              estimatedRowHeight={ROW_HEIGHT_PIXELS}
              height={box.height}
              width={box.width}
              rowCount={workloadFlattenedData.length}
              columnCount={columnCount}
              itemData={workloadFlattenedData}
            >
              {Cell}
            </VariableSizeGrid>
          </TrackingBoundingBox>
        </div>
      </WorkloadTableContext.Provider>
    );
  },
  areEqual
);
