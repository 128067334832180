export const JiraBlueGradientSvg = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g id="Group 1">
      <path
        id="Vector"
        d="M17.0003 8.51517L9.70485 0.988786L8.98873 0.25L3.50593 5.90633L0.999505 8.51517C0.730959 8.79222 0.730959 9.23087 0.999505 9.48483L6.01235 14.6563L8.98873 17.7269L14.4715 12.0706L14.5611 11.9782L17.0003 9.48483C17.2689 9.20778 17.2689 8.76913 17.0003 8.51517ZM8.98873 11.5858L6.48231 9L8.98873 6.41425L11.4952 9L8.98873 11.5858Z"
        fill="#2684FF"
      />
      <path
        id="Vector_2"
        d="M8.98866 6.4146C7.35501 4.72924 7.33263 1.98188 8.96628 0.273438L3.50586 5.92977L6.48224 9.00035L8.98866 6.4146Z"
        fill="url(#paint0_linear_4166_12754)"
      />
      <path
        id="Vector_3"
        d="M11.5176 8.97656L8.98877 11.5854C10.6448 13.2938 10.6448 16.0412 8.98877 17.7497L14.494 12.0702L11.5176 8.97656Z"
        fill="url(#paint1_linear_4166_12754)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4166_12754"
        x1="8.54984"
        y1="3.79308"
        x2="4.94024"
        y2="7.29194"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.176" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4166_12754"
        x1="9.47649"
        y1="14.1664"
        x2="13.0789"
        y2="10.6745"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.176" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);
