import { Button } from 'semantic-ui-react';

import { exclamationTriangleSolid, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { lpOrange } from 'style/variables';

export const ConfirmItemDuplicationModal = ({ onClick }: { onClick: () => void }) => {
  return (
    <LpModal
      centered
      size="mini"
      content={
        <>
          <LpIcon icon={exclamationTriangleSolid} size={'4x'} color={lpOrange} />
          <p>Attached files will not be copied onto the new items</p>
        </>
      }
      actions={<Button content={'Ok'} primary onClick={onClick} />}
    />
  );
};
