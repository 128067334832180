import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setReloadOnNextNavigation } from 'features/common/current/slice';
import { Events } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';

export const useApplicationReloadEventHandler = () => {
  const dispatch = useDispatch();
  const forceRefresh = useCallback(() => {
    dispatch(setReloadOnNextNavigation());
  }, [dispatch]);

  useEvents({
    callback: forceRefresh,
    event: Events.RELOAD_APPLICATION,
  });
};
