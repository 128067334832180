import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { ScrollContext } from 'containers/shared/helpers/scroll_context';
import { ExternalIntegrationDao } from 'daos/external_integration';
import { SourceSystem } from 'daos/external_integration_enums';
import { JiraMapping } from 'daos/model_types';
import { filterEq } from 'daos/shared';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import JiraConnectedProjectsCells from 'features/integrations/jira/jira_integration_connections/jira_connected_projects_cells';
import { JiraIntegrationsEmpty } from 'features/integrations/jira/jira_integration_connections/jira_integrations_empty';
import { awaitRequestFinish } from 'lib/api';

import 'features/integrations/jira/jira_integration_connections/jira_connected_projects.scss';

export const JiraConnectedProjects = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));

  const [jiraExternalIntegrations, setJiraExternalIntegrations] = useState<ReadonlyArray<JiraMapping>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchJiraExternalIntegrations = useCallback(() => {
    setIsLoading(true);
    const externalIntegrationsFetchFn = ExternalIntegrationDao.fetchAllExternalIntegrations(
      {
        organizationId,
        workspaceId,
      },
      {
        filter: filterEq('sourceSystem', SourceSystem.JIRA),
      }
    );

    const { uuid } = dispatch(externalIntegrationsFetchFn);

    dispatch(
      awaitRequestFinish<ReadonlyArray<JiraMapping>>(uuid, {
        onSuccess: ({ data }) => {
          setJiraExternalIntegrations(data);
        },
        onFinish: () => {
          setIsLoading(false);
        },
      })
    );
  }, [dispatch, organizationId, workspaceId]);

  useEffect(() => {
    fetchJiraExternalIntegrations();
  }, [fetchJiraExternalIntegrations]);

  const tableBody = useRef<HTMLDivElement>(null);
  const tableContent = useMemo(
    () =>
      jiraExternalIntegrations.map((jiraProject) => (
        <Table.Row key={jiraProject.id}>
          <JiraConnectedProjectsCells jiraProjectId={jiraProject.id} itemId={jiraProject.itemId} />
        </Table.Row>
      )),
    [jiraExternalIntegrations]
  );

  if (jiraExternalIntegrations.length === 0 && !isLoading) {
    return <JiraIntegrationsEmpty />;
  }

  return (
    <div data-testid="jiraConfiguration" className="jira-configuration">
      <ScrollContext.Provider value={{ scrollableElement: tableBody }}>
        <div ref={tableBody}>
          <Table className="jira-configuration__table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing content="Projects Integrated" />
                <Table.HeaderCell collapsing content="Last Sync with Jira" />
                <Table.HeaderCell collapsing content="Jira Instance" />
                <Table.HeaderCell collapsing content="Authorized By" />
                <Table.HeaderCell className="jira-configuration__table-header" collapsing />
              </Table.Row>
            </Table.Header>

            <Table.Body>{tableContent}</Table.Body>
          </Table>
        </div>
      </ScrollContext.Provider>
    </div>
  );
};
