import classNames from 'classnames';
import { useEffect } from 'react';

import { slate600 } from 'style/variables';

// https://wistia.com/support/developers/embed-options
const wistiaOptions = [
  'controlsVisibleOnLoad=true',
  'settingsControl=true',
  'volumeControl=true',
  'playbar=true',
  'fullscreenButton=true',
  'seo=false',
  'videoFoam=true',
  `playerColor=${slate600}`,
];

export const WistiaVideo = ({ videoUrlSlug, autoPlay }: { videoUrlSlug: string | null; autoPlay: boolean }) => {
  useEffect(() => {
    if (videoUrlSlug) {
      const embeddedLink = `https://fast.wistia.com/embed/medias/${videoUrlSlug}.jsonp`;
      const wistiaEmbeddedLinkScript = document.createElement('script');

      wistiaEmbeddedLinkScript.src = embeddedLink;
      wistiaEmbeddedLinkScript.async = true;

      document.body.appendChild(wistiaEmbeddedLinkScript);

      return () => {
        document.body.removeChild(wistiaEmbeddedLinkScript);
      };
    }
  }, [videoUrlSlug]);

  return (
    <div className="academy-video">
      <div
        className={classNames(`wistia_embed wistia_async_${videoUrlSlug}`, [
          ...wistiaOptions,
          autoPlay && 'autoPlay=true',
        ])}
      />
    </div>
  );
};
