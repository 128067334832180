import { useSortable } from '@dnd-kit/sortable';
import { memo } from 'react';
import { areEqual } from 'react-window';

import { DashboardsDraggableType } from 'features/dashboards_v2/context';
import { WidgetId } from 'features/dashboards_v2/widget/types';
import { WidgetGroup } from 'features/dashboards_v2/widget_group';

interface SortableWidgetGroupProps {
  dashboardId: string;
  widgetGroupId: string;
  index: number;
  widgetIds: Array<WidgetId>;
}
export const SortableWidgetGroup = memo(
  ({ dashboardId, widgetGroupId, index, widgetIds }: SortableWidgetGroupProps) => {
    const { attributes, listeners, setNodeRef, isDragging, setActivatorNodeRef } = useSortable({
      id: `wg-${widgetGroupId}`,
      data: {
        type: DashboardsDraggableType.Group,
        widgetGroupId,
        widgetIds,
        index,
      },
      transition: null,
    });

    return (
      <WidgetGroup
        dashboardId={dashboardId}
        widgetGroupId={widgetGroupId}
        isDragging={isDragging}
        ref={setNodeRef}
        layoutId={`widget-group-${widgetGroupId}`}
        draggableRef={setActivatorNodeRef}
        widgetIds={widgetIds}
        index={index}
        attributes={attributes}
        listeners={listeners}
      />
    );
  },
  areEqual
);
