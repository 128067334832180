import classNames from 'classnames';
import { ReactNode } from 'react';
import { Label } from 'semantic-ui-react';

import { LpIcon, exclamationTriangleSolid } from 'features/common/lp_icon';

import './lp_form_error.scss';

interface LpFormError {
  error: string;
  className?: string;
  appendedMessage?: ReactNode;
}

const LpFormError = ({ error, className = '', appendedMessage }: LpFormError) => {
  return (
    <Label data-testid="lp-form-error" className={classNames('lp-form-error', className)}>
      <LpIcon style={{ marginRight: '.2rem' }} icon={exclamationTriangleSolid} size="1x" />
      <span>
        {error}
        {appendedMessage}
      </span>
    </Label>
  );
};

export default LpFormError;
