import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import LpLoaderAnimatedGrid from 'features/common/loaders/lp_item_panel_loader';
import { LpIcon, cloudCheckSolid, boxHeartSolid, medkitSolid } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { frontend } from 'lib/urls';
import { blue500, red500 } from 'style/variables';

export const PackageToTemplateModal = ({
  openPackageToTemplateModal,
  closepackageToTemplateModal,
}: {
  openPackageToTemplateModal: 'loading' | 'success' | 'error';
  closepackageToTemplateModal: () => void;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const modalProps: {
    className: string;
    onClose: () => void;
    header?: string;
    content: ReactNode;
    actions?: ReactNode;
  } = {
    className: 'package_to_template_modal',
    onClose: closepackageToTemplateModal,
    content: <></>,
    actions: <></>,
  };

  switch (openPackageToTemplateModal) {
    case 'loading':
      modalProps.content = (
        <div className="lp-package-template-picker__loader">
          <LpLoaderAnimatedGrid label={`Converting Package to TEMPLATE${HORIZONTAL_ELLIPSIS}`} />
        </div>
      );

      break;
    case 'error':
      modalProps.className = `${modalProps.className} ${modalProps.className}--error`;
      modalProps.header = 'Error';
      modalProps.content = (
        <div className="package_to_template_modal__message">
          <LpIcon className="package_to_template_modal__message-icon" icon={medkitSolid} size={'4x'} color={red500} />
          <p>Error converting Package to Template</p>
        </div>
      );
      modalProps.actions = <Button content="Close" onClick={closepackageToTemplateModal} />;

      break;
    case 'success':
      modalProps.header = 'Success!';
      modalProps.content = (
        <div className="package_to_template_modal__message">
          <LpIcon
            className="package_to_template_modal__message-icon"
            icon={cloudCheckSolid}
            size={'4x'}
            color={blue500}
          />
          <p>Package Template has been created!</p>
        </div>
      );
      modalProps.actions = (
        <>
          <LpButtonAsLink
            onClick={closepackageToTemplateModal}
            to={frontend.templateCollectionPortfolio.url({
              organizationId,
              workspaceId,
            })}
          >
            <LpIcon icon={boxHeartSolid} /> Go to TEMPLATE Collection{' '}
          </LpButtonAsLink>
          <Button content="Close" onClick={closepackageToTemplateModal} />
        </>
      );
  }

  return <LpModal {...modalProps} />;
};
