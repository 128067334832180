import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import CustomFieldWsUserValue from 'features/common/custom_fields/custom_field_value/custom_field_ws_user_value';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';

const CustomFieldWsUser = ({ resourceId, fieldId, disabled = false }: CustomFieldValueProps) => {
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);

  if (!field) {
    return null;
  }

  const { allowsMultipleValues, values } = field;

  return (
    <>
      {values?.map((fieldValue) => (
        <CustomFieldWsUserValue
          newEntry={false}
          allowMultipleValues={allowsMultipleValues}
          fieldId={fieldId}
          resourceId={resourceId}
          fieldValueId={fieldValue.id}
          key={fieldValue.id}
          disabled={disabled}
        />
      ))}

      {(!values?.length || allowsMultipleValues) && (
        <CustomFieldWsUserValue
          allowMultipleValues={allowsMultipleValues}
          newEntry={true}
          fieldId={fieldId}
          resourceId={resourceId}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default CustomFieldWsUser;
