import { ItemColorDisplay } from 'daos/item_enums';
import { PropertyChange } from 'daos/types';
import { LpIcon, squareSolid } from 'features/common/lp_icon';

const ChangeCardColorProperty = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const colorHexCode = propertyChangeData.value;

  return (
    <>
      {<LpIcon className="lp-change__card-change-property-color" icon={squareSolid} color={`#${colorHexCode}`} />}{' '}
      {ItemColorDisplay[colorHexCode]}
    </>
  );
};

export default ChangeCardColorProperty;
