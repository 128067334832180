import { isEmpty, noop } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown, Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { Dashboard, User, Workspace } from 'daos/model_types';
import { UserDao } from 'daos/user';
import { useHandleDisconnected } from 'features/authentication/helpers';
import { PortableDashboardLinkButton } from 'features/common/buttons/portable_links/portable_dashboard_link_button';
import { getCurrentWorkspaceUsers } from 'features/common/current/selectors';
import { setCurrentOrganizationId, setCurrentUserId, setCurrentWorkspaceId } from 'features/common/current/slice';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import { bookOpenRegular, globeSolid, LpIcon, passportSolid, shieldMinusLight } from 'features/common/lp_icon';
import { setApiError } from 'features/errors/slice';
import { DisconnectDashboardModal, useDisconnectDashboardModal } from 'features/guest/disconnect_modals';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import {
  getCurrentUserHasGuestAccessToDashboard,
  getDashboardGuestForDashboardIdWorkspaceUserIdAndWorkspaceId,
} from 'redux/entities/selectors/dashboard_guest';
import { slate600 } from 'style/variables';

const PassportEllipsisMenu = ({
  dashboardId,
  dashboardName,
  isLastDashboard,
  onOpenClick,
  organizationId,
  refetchCurrentUserAfterDisconnect,
  workspaceId,
  workspaceName,
}: {
  dashboardId: number;
  dashboardName: string;
  isLastDashboard: boolean;
  onOpenClick: () => void;
  organizationId: number;
  refetchCurrentUserAfterDisconnect: (onFinish: () => void) => void;
  workspaceId: number;
  workspaceName: string;
}) => {
  const history = useHistory();

  const {
    closeDisconnectConfirmModal,
    confirmDisconnectDashboardPassport,
    onConfirmDisconnectClick,
    openDisconnectConfirmModal,
  } = useDisconnectDashboardModal({
    fetchWorkspaceUser: refetchCurrentUserAfterDisconnect,
    guestDashboards: {
      dashboards: [],
      dashboardGuestsByDashboardId: {},
    },
    organizationId,
    setReconnectComplete: noop,
    setReconnectError: noop,
    workspaceId,
  });

  const currentWorkspaceUserId =
    useSelector(getCurrentWorkspaceUsers).find((wsUser) => wsUser.workspace.id === workspaceId)?.id ?? 0;

  const dashboardGuest = useSelector((state) =>
    getDashboardGuestForDashboardIdWorkspaceUserIdAndWorkspaceId(
      state,
      dashboardId,
      currentWorkspaceUserId,
      workspaceId
    )
  );

  const triggerRef = useRef<HTMLSpanElement>(null);
  const [ellipsisIsOpen, setEllipsisIsOpen] = useState(false);
  const handleMenuOpen = () => setEllipsisIsOpen(true);
  const handleMenuClose = () => setEllipsisIsOpen(false);

  const handleDisconnectDashboardClick = () =>
    openDisconnectConfirmModal(dashboardId, dashboardName, dashboardGuest?.id ?? 0);

  const handleOpenDashboardClick = () => {
    onOpenClick();
    history.push(
      frontend.dashboardGuest.url({
        organizationId,
        workspaceId,
        dashboardId,
      })
    );
  };

  return (
    <>
      <PortalActionDropdown
        triggerRef={triggerRef}
        open={ellipsisIsOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
      >
        <Dropdown.Item onClick={handleOpenDashboardClick}>
          <LpIcon icon={bookOpenRegular} /> Open
        </Dropdown.Item>
        <Dropdown.Item>
          <PortableDashboardLinkButton
            dashboardId={String(dashboardId)}
            dashboardName={dashboardName}
            workspaceId={workspaceId}
            organizationId={organizationId}
            showText
          />
        </Dropdown.Item>
        <Dropdown.Item onClick={handleDisconnectDashboardClick}>
          <LpIcon icon={shieldMinusLight} /> Disconnect
        </Dropdown.Item>
      </PortalActionDropdown>
      {!!confirmDisconnectDashboardPassport && (
        <DisconnectDashboardModal
          isLastDashboard={isLastDashboard}
          dashboardName={dashboardName}
          onClose={closeDisconnectConfirmModal}
          onConfirm={onConfirmDisconnectClick}
          workspaceName={workspaceName}
        />
      )}
    </>
  );
};

const WorkspaceHeaderRow = ({ workspaceName }: { workspaceName: string }) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell colSpan="2" className="dashboard-passport-table__workspace-header">
        <LpIcon icon={globeSolid} /> {workspaceName}
      </Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

const DashboardRow = ({
  dashboard,
  organizationId,
  refetchCurrentUserAfterDisconnect,
  workspaceName,
  isLastDashboard,
}: {
  dashboard: Dashboard;
  organizationId: string;
  refetchCurrentUserAfterDisconnect: (onFinish: () => void) => void;
  workspaceName: string;
  isLastDashboard: boolean;
}) => {
  const dispatch = useDispatch();

  const dashboardName = dashboard.defaultName;

  const handleDashboardClick = () => {
    dispatch(setCurrentOrganizationId(Number(organizationId)));
    dispatch(setCurrentWorkspaceId(Number(dashboard.workspaceId)));
  };

  const hasGuestAccessToDashboard = useSelector((state) =>
    getCurrentUserHasGuestAccessToDashboard(state, organizationId, dashboard.workspaceId, String(dashboard.id))
  );

  if (!hasGuestAccessToDashboard) {
    return null;
  }

  return (
    <Table.Row key={dashboard.id}>
      <Table.Cell className="dashboard-passport-table__dashboard-name">
        <LpIcon icon={passportSolid} color={slate600} />{' '}
        <LpLink
          onClick={handleDashboardClick}
          to={frontend.dashboardGuest.url({
            organizationId,
            workspaceId: dashboard.workspaceId,
            dashboardId: dashboard.id,
          })}
        >
          {dashboardName}
        </LpLink>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <PassportEllipsisMenu
          dashboardId={dashboard.id}
          dashboardName={dashboardName}
          isLastDashboard={isLastDashboard}
          onOpenClick={handleDashboardClick}
          organizationId={Number(organizationId)}
          refetchCurrentUserAfterDisconnect={refetchCurrentUserAfterDisconnect}
          workspaceId={Number(dashboard.workspaceId)}
          workspaceName={workspaceName}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export const WorkspaceBody = ({
  organizationId,
  workspace,
  sortedDashboardsByWsIdMap,
}: {
  organizationId: string;
  workspace: Workspace;
  sortedDashboardsByWsIdMap: Map<string, Array<Dashboard>>;
}) => {
  const dispatch = useDispatch();
  const handleDisconnected = useHandleDisconnected();

  const dashboards = sortedDashboardsByWsIdMap.get(String(workspace.id)) ?? [];

  const refetchCurrentUserAfterDisconnect = useCallback(
    (onFinish: () => void) => {
      const { uuid } = dispatch(UserDao.fetchCurrent({ include: { includeOrganization: true } }));

      dispatch(
        awaitRequestFinish<User>(uuid, {
          onError: ({ errors }) => {
            const error = errors[0];
            const unauthorizedError = error?.status === 401;

            if (error) {
              if (!unauthorizedError) {
                dispatch(setApiError(error));
              }
            }
          },
          onSuccess: ({ data, entities }) => {
            const organizations = entities.organizations;
            const organizationUsers = entities.organizationUsers;

            dispatch(setCurrentUserId(data.id));

            const isDisconnected = isEmpty(organizations) || isEmpty(organizationUsers);

            if (isDisconnected) {
              return handleDisconnected();
            }
          },
          onFinish,
        })
      );
    },
    [dispatch, handleDisconnected]
  );

  return (
    <>
      <WorkspaceHeaderRow workspaceName={workspace.name} />
      <Table.Body>
        {dashboards.map((dashboard) => (
          <DashboardRow
            key={dashboard.id}
            dashboard={dashboard}
            isLastDashboard={dashboards.length === 1}
            organizationId={organizationId}
            refetchCurrentUserAfterDisconnect={refetchCurrentUserAfterDisconnect}
            workspaceName={workspace.name}
          />
        ))}
      </Table.Body>
    </>
  );
};
