import { ReactNode, RefObject, SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import { SchedulingLimitType } from 'daos/enums';
import { lpDropdownSearch } from 'features/common/inputs/dropdowns/helpers';
import { LimitDropdownPortal } from 'features/item_panel/sections/planning_section/limits/limit_dropdown_portal';

enum SchedulingLimitDisplayValue {
  DailyLimit = 'Daily Limit',
  WeeklyLimit = 'Weekly Limit',
  MonthlyLimit = 'Monthly Limit',
}

export const schedulingLimitTypeForDisplayValue = {
  [SchedulingLimitType.Day]: SchedulingLimitDisplayValue.DailyLimit,
  [SchedulingLimitType.Week]: SchedulingLimitDisplayValue.WeeklyLimit,
  [SchedulingLimitType.Month]: SchedulingLimitDisplayValue.MonthlyLimit,
};

const dropdownOptions = Object.values(SchedulingLimitType).map((type) => {
  return {
    value: type,
    search: schedulingLimitTypeForDisplayValue[type].toLowerCase(),
    key: type,
    text: schedulingLimitTypeForDisplayValue[type],
  };
});

interface SchedulingLimitTypeDropdownProps {
  isLimitsTypeDropdownOpen: boolean;
  onChange: (_: SyntheticEvent<HTMLElement>, { value }: DropdownProps) => void;
  onClose: () => void;
  selectedLimitType: SchedulingLimitType;
  trigger: ReactNode;
  triggerRef: RefObject<HTMLButtonElement>;
}

export const SchedulingLimitTypeDropdown = ({
  isLimitsTypeDropdownOpen,
  onChange,
  onClose,
  selectedLimitType,
  trigger,
  triggerRef,
}: SchedulingLimitTypeDropdownProps) => {
  return (
    <LimitDropdownPortal onClose={onClose} open={isLimitsTypeDropdownOpen} trigger={trigger} triggerRef={triggerRef}>
      {isLimitsTypeDropdownOpen ? (
        <Dropdown
          className="scheduling-limits-dropdown limit-type-dropdown"
          icon={false}
          lazyLoad
          onChange={onChange}
          onClose={onClose}
          open={isLimitsTypeDropdownOpen}
          options={dropdownOptions}
          search={lpDropdownSearch}
          searchInput={{ autoFocus: isLimitsTypeDropdownOpen }}
          selectOnNavigation={false}
          selectOnBlur={false}
          value={selectedLimitType}
        />
      ) : (
        <></>
      )}
    </LimitDropdownPortal>
  );
};
