import { LpFormikImageUpload } from 'features/common/forms/formik/image_upload';
import { imageSolid, LpIcon } from 'features/common/lp_icon';
import { WidgetSettingsField } from 'features/dashboards/widgets/widget_settings';
import { WidgetSettingsModalContent } from 'features/dashboards_v2/widget_settings';
import { Title } from 'features/dashboards_v2/widget_settings/title';

export const ImageWidgetSettings = ({ widgetId }: { widgetId: number }) => {
  const header = (
    <>
      <LpIcon icon={imageSolid} /> Image
    </>
  );

  const content = (
    <>
      <Title widgetId={widgetId} />
      <WidgetSettingsField name="Image">
        <LpFormikImageUpload fieldName="s3Id" />
      </WidgetSettingsField>
    </>
  );

  return <WidgetSettingsModalContent header={header} content={content} />;
};
