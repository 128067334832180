import { StandardColumns } from 'generated/enum/standard_columns';
export { StandardColumns } from 'generated/enum/standard_columns';

export enum CustomColumnHeaderLocation {
  ProjectHub = 'projectHub',
  PortfolioGrid = 'portfolioGrid',
}

export enum ColumnIndex {
  Zero = 0,
  One = 1,
  Two = 2,
}

// These column options aren't supported by the server-side application
export enum FrontendColumns {
  EarliestDoneDate = 'earliestDoneDate',
  EffectiveTargetFinish = 'effectiveTargetFinish',
  EffectiveTargetStart = 'effectiveTargetStart',
  Empty = '',
  FileName = 'fileName',
  FileSize = 'fileSize',
  LatestDoneDate = 'latestDoneDate',
  MetricsHelp = 'metricsHelp',
  None = 'none',
  OnHoldHoursRange = 'onHoldHoursRange',
  OrgUserName = 'orgUserName',
  PackageStatus = 'packageStatus',
  PercentCompleteNumericValueAsString = 'percentCompleteNumericValueAsString',
  RollupEarliestActiveTargetFinish = 'rollupEarliestActiveTargetFinish',
  RollupEarliestTargetStart = 'rollupEarliestTargetStart',
  RollupLatestTargetFinish = 'rollupLatestTargetFinish',
  Settings = 'settings',
  SlackWorkspaceName = 'slackWorkspaceName',
  UploadedBy = 'uploadedBy',
  UploadedOn = 'uploadedOn',
}

// Aggregate of all column options used for generic method and type arguments
export type Columns = StandardColumns | FrontendColumns;
