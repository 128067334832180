import { isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams, useRouteMatch } from 'react-router';

import { ItemType } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { Item } from 'daos/model_types';
import PageNotFound from 'features/common/errors/page_not_found';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import useHashParams from 'hooks/use_hash_params';
import useQueryParams from 'hooks/use_query_params';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';

import 'features/common/portable_link_handler/index.scss';

interface PortableLinkHandlerProps {
  itemId?: string;
  workspaceId: string;
  organizationId: string;
}

const PortableLinkHandler = () => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const hashParams = useHashParams();

  const query = isEmpty(queryParams) ? {} : { query: queryParams };
  const hash = isEmpty(hashParams) ? {} : { hash: hashParams };

  const { itemId = '', workspaceId = '', organizationId = '' } = useParams<PortableLinkHandlerProps>();
  const isPortableChangesLink = !!useRouteMatch(frontend.portableChanges.pattern)?.isExact;
  const isPortableWorkloadLink = !!useRouteMatch(frontend.portableWorkload.pattern)?.isExact;
  const isPortableBoardLink = !!useRouteMatch(frontend.portableBoard.pattern)?.isExact;
  const isPortableGridLink = !!useRouteMatch(frontend.portableGrid.pattern)?.isExact;
  const isPortableLibraryItemLink = !!useRouteMatch(frontend.portableLibraryItem.pattern)?.isExact;

  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<Item | undefined>(undefined);

  const fetchItem = useCallback(() => {
    const { uuid } = dispatch(
      ItemDao.fetch({
        organizationId,
        workspaceId,
        itemId,
      })
    );

    dispatch(
      awaitRequestFinish<Item>(uuid, {
        onSuccess: ({ data }) => {
          setItem(data);
        },
        onError: () => {
          setItem(undefined);
        },
        onFinish: () => setLoading(false),
      })
    );
  }, [dispatch, itemId, organizationId, workspaceId]);

  useEffect(() => {
    if (itemId) {
      fetchItem();
    } else {
      setLoading(false);
    }
  }, [itemId, fetchItem]);

  if (loading) {
    return (
      <div className="portable-link-handler__container">
        <LpOverlayLoader />
      </div>
    );
  }

  if (!loading && !item) {
    return <PageNotFound />;
  }

  if (!item) {
    return null;
  }

  if (item.itemType === ItemType.WORKSPACE_ROOTS) {
    const link = isPortableWorkloadLink ? frontend.scheduledCollectionWorkload : frontend.workspace;

    return (
      <Redirect
        to={link.url(
          {
            organizationId,
            workspaceId,
          },
          { ...query }
        )}
      />
    );
  }

  if (item.itemType === ItemType.PACKAGES) {
    if (isPortableWorkloadLink) {
      return (
        <Redirect
          to={frontend.packageWorkload.url(
            {
              packageId: itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }
    if (isPortableBoardLink) {
      return (
        <Redirect
          to={frontend.packageTaskBoard.url(
            {
              packageId: itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }
    if (isPortableGridLink) {
      return (
        <Redirect
          to={frontend.packageGrid.url(
            {
              packageId: itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }
    if (isPortableChangesLink) {
      return (
        <Redirect
          to={frontend.packageChanges.url(
            {
              packageId: itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }

    if (isPortableLibraryItemLink) {
      return (
        <Redirect
          to={frontend.packageLibrary.url(
            {
              packageId: itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }

    return (
      <Redirect
        to={frontend.singlePackage.url(
          {
            packageId: itemId,
            workspaceId,
            organizationId,
          },
          { ...query, ...hash }
        )}
      />
    );
  }

  if (item.itemType === ItemType.PROJECTS) {
    if (isPortableWorkloadLink) {
      return (
        <Redirect
          to={frontend.projectWorkload.url(
            {
              itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }
    if (isPortableBoardLink) {
      return (
        <Redirect
          to={frontend.projectBoard.url(
            {
              itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }
    if (isPortableGridLink) {
      return (
        <Redirect
          to={frontend.projectGrid.url(
            {
              itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }

    if (isPortableChangesLink) {
      return (
        <Redirect
          to={frontend.projectChanges.url(
            {
              itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }

    if (isPortableLibraryItemLink) {
      return (
        <Redirect
          to={frontend.projectLibrary.url(
            {
              itemId,
              workspaceId,
              organizationId,
            },
            { ...query, ...hash }
          )}
        />
      );
    }

    return (
      <Redirect
        to={frontend.projectProject.url(
          {
            itemId,
            workspaceId,
            organizationId,
          },
          { ...query, ...hash }
        )}
      />
    );
  }

  if (item.itemType === ItemType.FOLDERS) {
    return (
      <Redirect
        to={frontend.projectProject.url(
          {
            organizationId,
            workspaceId,
            itemId,
          },
          { ...query, ...hash }
        )}
      />
    );
  }

  if (item.parent && item.itemType === ItemType.TASKS) {
    const taskHashParams = isEmpty(hash) ? { hash: { panelId: itemId } } : hash;
    return (
      <Redirect
        to={frontend.projectProject.url(
          {
            organizationId,
            workspaceId,
            itemId: item.parent.id,
          },
          taskHashParams
        )}
      />
    );
  }

  return <PageNotFound />;
};

export default PortableLinkHandler;
