import { copyElementHtmlToClipboard } from 'lib/clipboard/copy_element_html';
import { frontend } from 'lib/urls';

interface CreateCourseTrackViewUrlParams {
  courseId: string | number;
  trackId: string | number;
  lessonId?: string | number;
  path?: string;
}
export function createCourseTrackViewUrl({ courseId, trackId, lessonId, path }: CreateCourseTrackViewUrlParams) {
  const hash = lessonId ? { courseId, trackId, lessonId } : { courseId, trackId };

  if (path) {
    return `${path}#courseId=${courseId}&trackId=${trackId}&lessonId=${lessonId}`;
  }

  return frontend.portableCourse.url({ courseId }, { hash: { ...hash } });
}

interface CreateTrackViewUrlParams {
  courseId: string | number;
  trackId: string | number;
  lessonId: string | number;
  path: string;
}
export function trackViewUrl({ courseId, trackId, lessonId, path }: CreateTrackViewUrlParams) {
  return `${path}#courseId=${courseId}&trackId=${trackId}&lessonId=${lessonId}`;
}

interface WriteAcademyUrlToClipboardParams {
  courseId: string | number;
  trackId: string | number;
  lessonId: string | number;
  title: string;
}
export function writeAcademyUrlToClipboard({ courseId, trackId, lessonId, title }: WriteAcademyUrlToClipboardParams) {
  const origin = window.location.origin;
  const url = `${origin}${createCourseTrackViewUrl({ courseId, trackId, lessonId })}`;

  return copyElementHtmlToClipboard(
    <>
      <strong>{title}</strong>
      <br />
      <a href={url}>{url}</a>
    </>
  );
}
