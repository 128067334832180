/* eslint-disable import/no-unused-modules */
/* FILE IS AUTOMATICALLY GENERATED FROM variables.less FOR USE IN TS FILES */

export const weightLight = `300`;
export const weightRegular = `400`;
export const weightSemiBold = `600`;
export const weightBold = `700`;
export const weightExtraBold = `800`;
export const infoTableHeaderFontWeight = `600`;
export const itemNameMinWidth = `25rem`;
export const customColumnWidth = `10`;
export const itemMetricsWidth = `7`;
export const expectedFinishWidth = `7`;
export const scheduleBarWidth = `25%`;
export const baseFontSize = `14px`;
export const baseFontFamily = `'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif`;
export const importantHeaderFontSize = `22px`;
export const importantMessageFontSize = `18px`;
export const lpPlainTextHeader = `1.2rem`;
export const infoTableHeaderFontSize = `1.1rem`;
export const subHeaderInfoMessage = `0.8rem`;
export const mini = `0.7rem`;
export const tiny = `0.8rem`;
export const small = `0.9rem`;
export const medium = `1rem`;
export const large = `1.2rem`;
export const big = `1.3rem`;
export const huge = `1.5rem`;
export const massive = `1.7rem`;
export const leftNavAnimationTime = `300ms`;
export const trickyTeal = `#35d4cc`;
export const relightBlue = `#4088df`;
export const sonicGreen = `#3dba72`;
export const lemonTwist = `#f8dd29`;
export const tigerOrange = `#f28b1d`;
export const riskyRed = `#db2828`;
export const pinkDrink = `#ff8c9f`;
export const powerPurple = `#695ea6`;
export const smartSlate = `#8397ab`;
export const earlGray = `#bfbfbf`;
export const tableBorder = `#222426`;
export const alphaTableBorder = `#22242626`;
export const lpFocusColor = `#85b7d9`;
export const lpButtonColor = `#3d546a`;
export const lpButtonHoverColor = `#60768a`;
export const lpBrandBlue = `#4088df`;
export const lpBrandSeattleBlue = `#3d518c`;
export const lpBrandWhite = `#ffffff`;
export const lpGray200 = `#f5f5f5`;
export const lpGray500 = `#bfbfbf`;
export const lpGray800 = `#262626`;
export const lpTeal = `#00b5ad`;
export const lpGreen = `#3dba72`;
export const lpPurple = `#8b7dcb`;
export const lpPink = `#fb617f`;
export const lpRed = `#db2828`;
export const lpOrange = `#f28b1d`;
export const lpYellow = `#f8dd29`;
export const blue50 = `#f0f9ff`;
export const blue100 = `#c6e7ff`;
export const blue200 = `#97ccf7`;
export const blue300 = `#6aa8eb`;
export const blue400 = `#4088df`;
export const blue500 = `#2c68b8`;
export const blue600 = `#1d4e91`;
export const blue700 = `#12396b`;
export const blue800 = `#092345`;
export const blue900 = `#04152b`;
export const teal50 = `#defcfb`;
export const teal100 = `#b1f2ef`;
export const teal200 = `#7ae6e0`;
export const teal300 = `#35d4cc`;
export const teal400 = `#00c4bb`;
export const teal500 = `#00b5ad`;
export const teal600 = `#00a199`;
export const teal700 = `#057d7d`;
export const teal800 = `#005a5c`;
export const teal900 = `#003f42`;
export const green50 = `#edfff2`;
export const green100 = `#d2fcde`;
export const green200 = `#aee6c1`;
export const green300 = `#8ad4a3`;
export const green400 = `#61c788`;
export const green500 = `#3dba72`;
export const green600 = `#299459`;
export const green700 = `#196e42`;
export const green800 = `#0d472b`;
export const green900 = `#08311f`;
export const yellow50 = `#fefff0`;
export const yellow100 = `#ffffcf`;
export const yellow200 = `#fffca6`;
export const yellow300 = `#fff67d`;
export const yellow400 = `#ffee54`;
export const yellow500 = `#f8dd29`;
export const yellow600 = `#d1b219`;
export const yellow700 = `#ab8b0c`;
export const yellow800 = `#856603`;
export const yellow900 = `#5e4501`;
export const orange50 = `#fff9eb`;
export const orange100 = `#ffeac2`;
export const orange200 = `#ffd899`;
export const orange300 = `#ffc370`;
export const orange400 = `#ffac47`;
export const orange500 = `#f28b1d`;
export const orange600 = `#cc6a0e`;
export const orange700 = `#a64c03`;
export const orange800 = `#803500`;
export const orange900 = `#592200`;
export const red50 = `#fff2f0`;
export const red100 = `#ffd7d1`;
export const red200 = `#ffb1a8`;
export const red300 = `#f5837a`;
export const red400 = `#e8544f`;
export const red500 = `#db2828`;
export const red600 = `#b5181d`;
export const red700 = `#8f0b14`;
export const red800 = `#69030d`;
export const red900 = `#42010a`;
export const pink50 = `#fff8f8`;
export const pink100 = `#fff0f1`;
export const pink200 = `#ffdee1`;
export const pink300 = `#ffb5bf`;
export const pink400 = `#ff8c9f`;
export const pink500 = `#fb617f`;
export const pink600 = `#d44869`;
export const pink700 = `#ad3253`;
export const pink800 = `#87203f`;
export const pink900 = `#61162f`;
export const purple50 = `#fcfaff`;
export const purple100 = `#f5f0ff`;
export const purple200 = `#e7e1f8`;
export const purple300 = `#d4cdf0`;
export const purple400 = `#b2a7e0`;
export const purple500 = `#8b7dcb`;
export const purple600 = `#695ea6`;
export const purple700 = `#494280`;
export const purple800 = `#2e2a59`;
export const purple900 = `#191733`;
export const gray50 = `#ffffff`;
export const gray100 = `#fafafa`;
export const gray200 = `#f5f5f5`;
export const gray300 = `#e8e8e8`;
export const gray400 = `#d9d9d9`;
export const gray500 = `#bfbfbf`;
export const gray600 = `#8c8c8c`;
export const gray700 = `#595959`;
export const gray800 = `#262626`;
export const gray900 = `#000000`;
export const slate50 = `#eff7ff`;
export const slate100 = `#e9f3fd`;
export const slate200 = `#d8e6f4`;
export const slate300 = `#c3d2e0`;
export const slate400 = `#a7bacc`;
export const slate500 = `#8397ab`;
export const slate600 = `#60768a`;
export const slate700 = `#3d546a`;
export const slate800 = `#253344`;
export const slate900 = `#0b151f`;
export const navy = `#091540`;
export const janBradyGold = `#f5910a`;
export const trueBlack = `#000000`;
export const vinicioViolet = `#114d72`;
export const readOnlyOpacity = `0.45`;
export const jiraBackgroundGray = `#EBECF0`;
export const defaultCubicBezier = `cubic-bezier(0.4, 0, 0.2, 1)`;
export const decelerateCubicBezier = `cubic-bezier(0, 0, 0.2, 1)`;
export const minSizeMediaQuery = `1200px`;
