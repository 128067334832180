import { ItemType } from 'daos/enums';
import { Item } from 'daos/model_types';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';

const formatItemName = (itemName: string | null) => itemName?.replace(/\s+/g, ' ').trim() ?? '';
export const formatItemNameList = (itemNames: ReadonlyArray<string>) => itemNames.filter((name) => !!name).join(' > ');

export function getFormattedNamesForAncestryByItemType(items: ReadonlyArray<Item>) {
  let collectionType = '';
  let packageName = '';
  let projectName = '';

  let taskName = '';
  let assignmentName = '';

  const subFolders: Array<string> = [];

  items.forEach((item) => {
    const formattedName = formatItemName(item.name);
    switch (item.itemType) {
      case ItemType.PACKAGES:
        packageName = formattedName;
        if (item.packageStatus) {
          collectionType = packageStatusDisplayNameByPackageStatus[item.packageStatus];
        }
        break;
      case ItemType.PROJECTS:
        projectName = formattedName;
        break;
      case ItemType.TASKS:
        taskName = formattedName;
        break;
      case ItemType.FOLDERS:
        subFolders.push(formattedName);
        break;
      case ItemType.ASSIGNMENTS:
        assignmentName = formattedName;
        break;
    }
  });

  const formattedSubFolderNames = formatItemNameList(subFolders);
  return {
    packageName,
    projectName,
    subFolders: formattedSubFolderNames,
    taskName,
    collectionType,
    assignmentName,
  };
}
