import { DashboardGuest } from 'daos/model_types';
import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';

const { resource, updateBody } = defineModel({
  apiType: 'dashboardGuests',
  type: 'DASHBOARD_GUEST',
});

const { DASHBOARD_GUEST } = resource;

interface SingleDashboardGuestByIdParams {
  organizationId: number;
  workspaceId: number;
  dashboardId: number;
  dashboardGuestId: number;
}

const update = (params: SingleDashboardGuestByIdParams, dashboardGuest: Partial<DashboardGuest>) =>
  request(backend.dashboardGuest.url(params), DASHBOARD_GUEST, updateBody(params.dashboardGuestId, dashboardGuest));

export const DashboardGuestDao = {
  update,
} as const;
