import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, List } from 'semantic-ui-react';

import ItemLink from 'containers/shared/item_link';
import { ItemFile } from 'daos/model_types';
import { backend } from 'daos/urls';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import PortalActionDropdown from 'features/common/inputs/dropdowns/portal_action_dropdown';
import { fileLight, LpIcon, paperPlaneLight } from 'features/common/lp_icon';
import { SearchContext } from 'features/common/search/enums';
import { FileIconAndName } from 'features/item_panel/sections/files_section/file_icon_and_name';
import { useCopyLink } from 'features/item_panel/sections/files_section/use_file_actions';
import { getFileForId } from 'redux/entities/selectors/file';
import { getItemForId } from 'redux/entities/selectors/item';

interface ResultFileProps {
  itemFile: ItemFile;
  resultNumber: number;
  searchContext?: SearchContext;
}
export function ResultFile({ itemFile, resultNumber }: ResultFileProps) {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationId = useSelector(getCurrentOrganizationId);
  const fileIds = useRef(new Set([itemFile.file.id]));
  const [usingDropdown, setUsingDropdown] = useState(false);
  const file = useSelector((state) => getFileForId(state, itemFile.file.id));
  const item = useSelector((state) => getItemForId(state, itemFile.item.id));
  const triggerRef = useRef<HTMLSpanElement>(null);
  const { copyLink } = useCopyLink(fileIds.current);

  const openDropdown = () => setUsingDropdown(true);
  const hideDropdown = () => {
    setUsingDropdown(false);
  };

  const onOpenFile = () => {
    if (!file || !file.s3Id || !item) {
      return;
    }

    const fileURL = backend.itemFilesDownload.url({
      organizationId,
      workspaceId,
      itemId: item.id,
      s3id: file.s3Id,
    });

    window.open(fileURL, '_blank');
  };

  const onCopyLink = () => {
    copyLink();
  };

  if (!file || !item) {
    return null;
  }

  return (
    <List.Item className="lp-search__results-files-row">
      <div className="lp-search__results-files-cell--count">{resultNumber.toString().padStart(2, '0')}</div>
      <FileIconAndName fileId={file.id} itemId={item.id} />
      <div className="lp-search__results-files-row--on">on</div>
      <ItemLink itemId={item.id} showIcon openScheduleTab={false} />
      <div className="lp-search__results-files-row--menu">
        <PortalActionDropdown triggerRef={triggerRef} onOpen={openDropdown} onClose={hideDropdown} open={usingDropdown}>
          <Dropdown.Item onClick={onOpenFile}>
            <LpIcon icon={fileLight} className="icon" /> Open File
          </Dropdown.Item>
          <Dropdown.Item onClick={onCopyLink}>
            <LpIcon icon={paperPlaneLight} className="icon" /> Copy Link
          </Dropdown.Item>
        </PortalActionDropdown>
      </div>
    </List.Item>
  );
}
