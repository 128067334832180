import CustomFieldItemValue from 'features/common/custom_fields/custom_field_value/custom_field_item_value';
import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';

const CustomFieldItem = ({ fieldId, resourceId, disabled = false }: CustomFieldValueProps) => {
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);

  if (!field) {
    return null;
  }

  const { allowsMultipleValues, values } = field;

  if (!values) {
    return null;
  }

  return (
    <>
      {values.map((fieldValue) => (
        <CustomFieldItemValue
          key={fieldValue.id}
          allowMultipleValues={allowsMultipleValues}
          resourceId={resourceId}
          fieldId={fieldId}
          fieldValueId={fieldValue.id}
          disabled={disabled}
        />
      ))}

      {(!values.length || allowsMultipleValues) && (
        <CustomFieldItemValue
          allowMultipleValues={allowsMultipleValues}
          resourceId={resourceId}
          fieldId={fieldId}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default CustomFieldItem;
