import { FolderStatus } from 'daos/item_enums';
import { checkSolid, LpIcon, pauseSolid } from 'features/common/lp_icon';
import { gray500 } from 'style/variables';

interface FolderStatusIconProps {
  folderStatus: FolderStatus | null;
  className?: string;
}

const FolderStatusIcon = ({ folderStatus, className }: FolderStatusIconProps) => {
  if (!folderStatus) {
    return null;
  }

  switch (folderStatus) {
    case FolderStatus.ON_HOLD:
      return <LpIcon icon={pauseSolid} color={gray500} className={className} />;
    case FolderStatus.DONE:
      return <LpIcon icon={checkSolid} color={gray500} className={className} />;
    default:
      return null;
  }
};

export default FolderStatusIcon;
