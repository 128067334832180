export const isTimesheetExporterCheckboxDisabled = ({
  isAdmin,
  isManager,
  isCurrentWsUser,
  isWsUserDisconnected,
  hasUpgradedTimeTracking,
  hasAdminAccess,
}: {
  isAdmin: boolean;
  isManager: boolean;
  isCurrentWsUser: boolean;
  isWsUserDisconnected: boolean;
  hasUpgradedTimeTracking: boolean;
  hasAdminAccess: boolean;
}) => {
  return !hasUpgradedTimeTracking || !hasAdminAccess || isAdmin || isManager || isCurrentWsUser || isWsUserDisconnected;
};

export const isTimesheetReviewerCheckboxDisabled = ({
  isAdmin,
  isManager,
  isExporter,
  isCurrentWsUser,
  isWsUserDisconnected,
  hasUpgradedTimeTracking,
  hasAdminAccess,
}: {
  isAdmin: boolean;
  isManager: boolean;
  isExporter: boolean;
  isCurrentWsUser: boolean;
  isWsUserDisconnected: boolean;
  hasUpgradedTimeTracking: boolean;
  hasAdminAccess: boolean;
}) => {
  return (
    isTimesheetExporterCheckboxDisabled({
      isAdmin,
      isManager,
      isCurrentWsUser,
      isWsUserDisconnected,
      hasUpgradedTimeTracking,
      hasAdminAccess,
    }) || isExporter
  );
};
