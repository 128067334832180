import { PRIVACY_POLICY_URL, TOS_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';

import './lp_legal_terms_footer.scss';

const LpLegalTermsFooter = () => {
  const browserDate = new Date();

  return (
    <div className="lp-legal-terms-footer">
      <div>&copy; {browserDate.getFullYear()} LiquidPlanner, Inc. All Rights Reserved.</div>
      <div>
        <NewWindowLink className="lp-legal-terms-footer__tos" to={TOS_URL}>
          Terms of Service
        </NewWindowLink>
        <NewWindowLink to={PRIVACY_POLICY_URL}>Privacy Policy</NewWindowLink>
      </div>
    </div>
  );
};

export default LpLegalTermsFooter;
