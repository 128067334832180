import { useFormikContext } from 'formik';
import { Form, FormCheckboxProps } from 'semantic-ui-react';

type LpFormikCheckboxProps = {
  id?: string;
  name: string;
} & Omit<FormCheckboxProps, 'checked' | 'id' | 'name' | 'multiple' | 'onBlur' | 'onChange' | 'value'>;

export const LpFormikCheckbox = ({ id, name: fieldKey, ...checkboxProps }: LpFormikCheckboxProps) => {
  const { getFieldMeta, getFieldProps } = useFormikContext();
  const fieldMeta = getFieldMeta(fieldKey);

  const { name, onBlur, onChange, value } = getFieldProps(fieldKey);
  const fieldProps = { name, onBlur, onChange };

  return (
    <Form.Checkbox
      id={id ?? fieldKey}
      error={fieldMeta.touched && fieldMeta.error}
      checked={!!value}
      {...fieldProps}
      {...checkboxProps}
    />
  );
};
