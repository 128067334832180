import { Active, ClientRect, DroppableContainer, pointerWithin, rectIntersection } from '@dnd-kit/core';
import { Over, RectMap } from '@dnd-kit/core/dist/store';
import { Coordinates, Transform } from '@dnd-kit/utilities';
import { wrap } from 'comlink';
import { MutableRefObject } from 'react';

import { WorkerFunctions } from 'features/dashboards_v2/drag_and_drop/worker';
import { WidgetGroupIds } from 'features/dashboards_v2/widget/types';

const worker = new Worker(new URL('./worker.ts', import.meta.url));
const functions = wrap<WorkerFunctions>(worker);

export const DRAG_OVER_DEBOUNCE_DURATION_MILLISECONDS = 100;

interface ReorderWidgetGroupProps {
  from: number;
  to: number;
  items: Array<WidgetGroupIds>;
}
export function reorderWidgetGroup({ from, to, items }: ReorderWidgetGroupProps) {
  return functions.moveWidgetGroup(items, from, to);
}

interface ReorderWidgetWithinGroupProps {
  from: number;
  to: number;
  widgetGroupIndex: number;
  items: Array<WidgetGroupIds>;
}
export function reorderWidgetWithinGroup({ from, to, widgetGroupIndex, items }: ReorderWidgetWithinGroupProps) {
  return functions.moveWidgetWithinGroup(items, from, to, widgetGroupIndex);
}

interface ReorderWidgetIntoNewGroupOverWidgetProps {
  to: number;
  from: number;
  newWidgetGroupIndex: number;
  oldWidgetGroupIndex: number;
  items: Array<WidgetGroupIds>;
}
export function reorderWidgetIntoNewGroupOverWidget({
  to,
  from,
  newWidgetGroupIndex,
  oldWidgetGroupIndex,
  items,
}: ReorderWidgetIntoNewGroupOverWidgetProps) {
  return functions.moveWidgetIntoNewGroupOverWidget(items, from, to, newWidgetGroupIndex, oldWidgetGroupIndex);
}

interface ReorderWidgetIntoNewGroupProps {
  from: number;
  newWidgetGroupIndex: number;
  oldWidgetGroupIndex: number;
  items: Array<WidgetGroupIds>;
}
export function reorderWidgetIntoNewGroup({
  from,
  newWidgetGroupIndex,
  oldWidgetGroupIndex,
  items,
}: ReorderWidgetIntoNewGroupProps) {
  return functions.moveWidgetIntoNewGroup(items, from, newWidgetGroupIndex, oldWidgetGroupIndex);
}

const POINTER_VERTICAL_OFFSET = 20;
export function recenterPointerLocation({
  activatorEvent,
  overlayNodeRect,
  transform,
}: {
  activatorEvent: Event | null;
  active: Active | null;
  activeNodeRect: ClientRect | null;
  draggingNodeRect: ClientRect | null;
  containerNodeRect: ClientRect | null;
  over: Over | null;
  overlayNodeRect: ClientRect | null;
  scrollableAncestors: Array<Element>;
  scrollableAncestorRects: Array<ClientRect>;
  transform: Transform;
  windowRect: ClientRect | null;
}) {
  if (activatorEvent && overlayNodeRect) {
    const pointerEvent = activatorEvent as PointerEvent;
    transform.y = pointerEvent.y - overlayNodeRect.top + transform.y - POINTER_VERTICAL_OFFSET;
  }
  return transform;
}

export function customCollisionDetectionAlgorithm(isGroupLayoutTransitioning: MutableRefObject<Boolean> | null) {
  return (args: {
    active: Active;
    collisionRect: ClientRect;
    droppableRects: RectMap;
    droppableContainers: Array<DroppableContainer>;
    pointerCoordinates: Coordinates | null;
  }) => {
    // First, check if group layouts are changing size
    if (isGroupLayoutTransitioning?.current) {
      return [];
    }

    // Then, let's see if there are any collisions with the pointer
    // Return the pointer collisions only if we actually have pointer coordinates
    if (args.pointerCoordinates) {
      return pointerWithin(args);
    }

    // If there are no pointer coordinates return rectangle intersections
    return rectIntersection(args);
  };
}
