import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { StatusFilterGroups } from 'daos/enums';
import OtherPropertiesOptionsTable, {
  OtherPropertiesTableValue,
} from 'features/common/custom_item_filter/other_properties_table';
import { filterLight, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { getWorkspaceCustomFieldsInheritedOrUsedOnTasks } from 'redux/entities/selectors/custom_field';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';
import { gray700 } from 'style/variables';

import TaskStatusList, { TaskStatusListValue } from './item_status_lists/task_status_list';
import { CustomTaskFilters } from './types';

import './index.scss';

const taskStatusFilterResetValues: CustomTaskFilters = {
  taskCreatedByUserId: null,
  taskCustomFieldFilters: [],
  taskNameFilter: '',
  taskHasFileFilter: false,
  taskScheduleDirectiveAsapOnly: false,
  taskScheduleRiskOnly: false,
  taskWorkLimitRiskOnly: false,
  taskStatusFilter: StatusFilterGroups.All,
  taskStatusIdsFilter: [],
};

export const CustomTaskFilterModal = ({
  onApply,
  onCancel,
  value,
}: {
  onApply: (value: CustomTaskFilters) => void;
  onCancel: () => void;
  value: CustomTaskFilters;
}) => {
  const [state, setState] = useState(value);
  const customFields = useSelector(getWorkspaceCustomFieldsInheritedOrUsedOnTasks);
  const currentWorkspaceHasFileUploads = useSelector(getCurrentWorkspace)?.fileUploads;

  const onTaskStatusFiltersChange = (newValue: TaskStatusListValue) => {
    setState((prev) => ({
      ...prev,
      taskStatusFilter: newValue.selectedTaskStatusFilter,
      taskStatusIdsFilter: newValue.selectedTaskStatusIds,
      statusFilterSelection: StatusFilterGroups.Custom,
    }));
  };

  const onOtherPropertiesChange = (newValue: OtherPropertiesTableValue) => {
    setState((prev) => ({
      ...prev,
      taskCreatedByUserId: newValue.createdByUserId ?? null,
      taskCustomFieldFilters: newValue.otherCustomFieldProperties,
      taskNameFilter: newValue.nameContainsValue,
      taskScheduleDirectiveAsapOnly: newValue.selectedAsap,
      taskScheduleRiskOnly: newValue.selectedAtRisk,
      taskWorkLimitRiskOnly: newValue.selectedWorkLimitRisk,
      taskHasFileFilter: newValue.selectedHasFiles,
      statusFilterSelection: StatusFilterGroups.Custom,
    }));
  };

  const handleApplyFilter = () => {
    onApply(state);
  };

  const handleCloseModal = () => {
    onCancel();
  };

  const handleResetModal = () => {
    setState(taskStatusFilterResetValues);
  };

  return (
    <LpModal
      className="lp-custom-data-filter-modal"
      header={
        <span>
          <LpIcon className="icon" color={gray700} icon={filterLight} size="1x" /> Custom Task Filter
        </span>
      }
      content={
        <div className="lp-custom-data-filter-modal-content">
          <>
            <TaskStatusList
              onChange={onTaskStatusFiltersChange}
              value={{
                selectedTaskStatusFilter: state.taskStatusFilter ?? StatusFilterGroups.All,
                selectedTaskStatusIds: state.taskStatusIdsFilter ?? [],
              }}
            />
            <OtherPropertiesOptionsTable
              customFields={customFields}
              onChange={onOtherPropertiesChange}
              showAsapRow
              showHasFilesRow={!!currentWorkspaceHasFileUploads}
              title="Other Task Properties"
              value={{
                createdByUserId: state.taskCreatedByUserId ?? undefined,
                nameContainsValue: state.taskNameFilter ?? '',
                otherCustomFieldProperties: state.taskCustomFieldFilters ?? [],
                selectedAtRisk: state.taskScheduleRiskOnly ?? false,
                selectedWorkLimitRisk: state.taskWorkLimitRiskOnly ?? false,
                selectedAsap: state.taskScheduleDirectiveAsapOnly ?? false,
                selectedHasFiles: state.taskHasFileFilter ?? false,
              }}
            />
          </>
        </div>
      }
      actions={
        <div className="lp-custom-data-filter-modal__actions-section">
          <Button
            className="lp-custom-data-filter-modal__actions-section-reset-button"
            content="Reset all"
            onClick={handleResetModal}
          />
          <span className="lp-custom-data-filter-modal__actions-section-apply-and-cancel-button">
            <Button
              className="lp-custom-data-filter-modal__actions-section-cancel-button"
              content="Cancel"
              onClick={handleCloseModal}
            />
            <Button
              className="lp-custom-data-filter-modal__actions-section-apply-button"
              content="Apply"
              primary
              onClick={handleApplyFilter}
            />
          </span>
        </div>
      }
    />
  );
};
