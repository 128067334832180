import { ReactNode } from 'react';

export enum SelectionListType {
  CustomColumnOptions = 'customColumnOptions',
  User = 'user',
  TimesheetExport = 'timesheetExport',
  CustomField = 'customField',
  TaskStatus = 'taskStatus',
  Group = 'group',
}

export interface SelectionList {
  id: string;
  selectionListType: SelectionListType;
  name: string;
  listIcon?: ReactNode;
  optionalCustomClassname?: string;
  sortable?: boolean;
  disabledPopupText?: string;
}
