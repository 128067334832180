import { Action } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'typed-redux-saga';

import { checkError, setApiError } from 'features/errors/slice';
import { frontend } from 'lib/urls';
import { resetRootState } from 'redux/root_actions';

const allowedRoutesOnUserAuthenticationError = new Set([
  frontend.trial.url({}),
  frontend.login.url({}),
  frontend.forgotPassword.url({}),
  frontend.loginSso.url({}),
]);

function* handleErrors(action: Action) {
  if (setApiError.match(action) || checkError.match(action)) {
    const { pathname, hash } = location;
    const isAllowedRoute = pathname.endsWith('profile') || allowedRoutesOnUserAuthenticationError.has(pathname);

    if (!isAllowedRoute && action.payload.status === 401) {
      yield* put(resetRootState({ setCurrentUserIdNull: true }));

      location.replace(`${frontend.login.pattern}?from=${pathname}${hash}`);
    }
  }
}

export default function* errorSaga() {
  yield* all([takeLatest([checkError, setApiError], handleErrors)]);
}
