import classNames from 'classnames';
import { ReactNode } from 'react';
import { Menu } from 'semantic-ui-react';

interface LeftNavMenuProps {
  navExpanded: boolean;
  children: ReactNode | ReadonlyArray<ReactNode>;
}

export const LeftNavMenu = ({ navExpanded, children }: LeftNavMenuProps) => {
  return (
    <Menu
      attached="top"
      className={classNames('icon-left', 'lp-left-nav__menu')}
      icon={!navExpanded}
      inverted
      secondary
      vertical
    >
      {children}
    </Menu>
  );
};
