import { EstimationType } from 'daos/external_integration_enums';
import { EstimationTypeDisplay, EstimationTypeValues } from 'features/jira_project/modal/types';

export const estimationTypeValues: Array<EstimationTypeValues> = [
  {
    text: EstimationTypeDisplay.TimeRemaining,
    value: EstimationType.RemainingTime,
  },
  {
    text: EstimationTypeDisplay.StoryPoints,
    value: EstimationType.StoryPoints,
  },
];
