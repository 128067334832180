import { customColumnDefinitions } from 'containers/shared/custom_column';
import { SourceSystem } from 'daos/external_integration_enums';
import { WidgetConfigColumn } from 'daos/widget_column';
import { JiraFieldsDisplayText } from 'features/dashboards_v2/widget/widgets/jira_custom_fields';

const getCustomFieldHeader = (columnDefinition: WidgetConfigColumn) => {
  const { customFieldName, customFieldSourceSystem, customFieldArchived } = columnDefinition;

  const isJiraCustomField = customFieldSourceSystem === SourceSystem.JIRA;
  const name = (customFieldName as string) ?? 'Custom Field';

  if (isJiraCustomField) {
    return <JiraFieldsDisplayText name={name} />;
  }

  return `${name}${customFieldArchived ? ' (Archived)' : ''}`;
};

export const getPropertyHeaderDisplayText = (columnDefinition: WidgetConfigColumn) => {
  const { column, customFieldId } = columnDefinition;

  if (customFieldId) {
    return getCustomFieldHeader(columnDefinition);
  }

  const columnDisplayText = customColumnDefinitions[column]?.displayText ?? column;
  return columnDisplayText?.replace(' (Range)', '') ?? '';
};
