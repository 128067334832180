export function useUpdateAndRemoveMultiFormFieldValues<T>({
  setFormFieldValue,
}: {
  setFormFieldValue: (value: Array<T>, shouldValidate?: boolean) => void;
}) {
  const removeMultiValue = (index: number, multiValue: Array<T>) => {
    const updatedValues = [...multiValue];
    updatedValues.splice(index, 1);
    setFormFieldValue(updatedValues);
  };

  const addOrUpdateMultiValue = (index: number, value: T, multiValue: Array<T>) => {
    const updatedValues = [...multiValue];
    updatedValues.splice(index, 1, value);
    setFormFieldValue(updatedValues);
  };

  return { removeMultiValue, addOrUpdateMultiValue };
}
