import { SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useHistory } from 'react-router';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

import { Organization, Workspace } from 'daos/model_types';
import { OrganizationDao } from 'daos/organization';
import { WorkspaceDao } from 'daos/workspace';
import { EntityTypeNotFound } from 'features/authentication/authenticated/errors/entity_not_found';
import { isServerError } from 'features/authentication/authenticated/errors/helpers/is_server_error';
import {
  loadingAppComplete,
  setCurrentOrganizationId,
  setCurrentUserDisconnected,
  setCurrentWorkspaceId,
} from 'features/common/current/slice';
import { setApiError } from 'features/errors/slice';
import { awaitRequestFinish } from 'lib/api';
import { ApiError } from 'lib/api/types';
import { MIN_PASSWORD_LENGTH } from 'lib/constants';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { EntityLookupById } from 'redux/entities/types';

export const useHandleDisconnected = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return () => {
    dispatch(setCurrentUserDisconnected(true));

    const disconnectedRestartAccountPathMatch = matchPath(location.pathname, {
      path: frontend.disconnectedRestartAccount.pattern,
    });

    history.push(disconnectedRestartAccountPathMatch?.path ?? frontend.disconnected.url({}));
  };
};

interface FetchEntityProps {
  dispatch: Dispatch;
  organizationId: number;
  setEntityNotFound: React.Dispatch<SetStateAction<EntityTypeNotFound | undefined>>;
  setServerError?: (error: ApiError) => void;
}

export const fetchOrg = ({
  dispatch,
  organizationId,
  setEntityNotFound,
  setServerError,
  onEntityFetchSuccess,
}: FetchEntityProps & {
  onEntityFetchSuccess: (workspaces: Array<Workspace>, workspacesMap: EntityLookupById<Workspace> | undefined) => void;
}) => {
  const { uuid } = dispatch(
    OrganizationDao.fetch(
      { organizationId },
      {
        include: {
          includeActiveBanner: true,
          includePlan: true,
          includeSystemSettings: true,
          includeUsers: true,
          includeWorkspaces: true,
          includeOrganizationProjectTypes: true,
        },
      }
    )
  );
  dispatch(
    awaitRequestFinish<Readonly<Organization>>(uuid, {
      onError: ({ errors }) => {
        const error = errors[0];

        if (error) {
          if (error.status === 404) {
            setEntityNotFound(EntityTypeNotFound.Organization);
            return;
          }

          if (isServerError(error.status)) {
            setServerError?.(error);
            return;
          }
          dispatch(setApiError(error));
        }
      },
      onSuccess: ({ entities }) => {
        try {
          localStorage.setItem('organizationId', JSON.stringify(organizationId));
          // eslint-disable-next-line no-empty
        } catch (_) {}

        dispatch(setCurrentOrganizationId(organizationId));
        onEntityFetchSuccess(Object.values(entities.workspaces ?? {}), entities.workspaces);
      },
    })
  );
};

export const fetchWs = ({
  dispatch,
  organizationId,
  workspaceId,
  setEntityNotFound,
  setServerError,
  onEntityFetchSuccess,
  includeIterations,
}: FetchEntityProps & {
  workspaceId: number;
  onEntityFetchSuccess?: () => void;
  includeIterations: boolean;
}) => {
  const { uuid } = dispatch(
    WorkspaceDao.fetch(
      { organizationId, workspaceId },
      {
        include: {
          includeCostCodes: true,
          includeFavorites: true,
          includeFields: true,
          includeGroups: true,
          includeIterations: includeIterations,
          includeTaskStatus: true,
          includeWorkspaceRoot: true,
          includeWorkspaceUsers: true,
          includeRateSheets: true,
          includeCurrentUserWorkspaceSettings: true,
          includeMyInAppNotifications: true,
          includeSystemSettings: true,
        },
      }
    )
  );
  dispatch(
    awaitRequestFinish<Readonly<Workspace>>(uuid, {
      onError: ({ errors }) => {
        const error = errors[0];
        if (error) {
          if (error.status === 404) {
            setEntityNotFound(EntityTypeNotFound.Workspace);
            return;
          }

          if (isServerError(error.status)) {
            setServerError?.(error);
            return;
          }

          dispatch(setApiError(error));
        }
      },
      onSuccess: () => {
        try {
          localStorage.setItem('workspaceId', JSON.stringify(workspaceId));
          // eslint-disable-next-line no-empty
        } catch (_) {}
        dispatch(setCurrentWorkspaceId(workspaceId));
        onEntityFetchSuccess?.();
      },
      onFinish: () => dispatch(loadingAppComplete()),
    })
  );
};

export const validationSchema = Yup.object().shape({
  password: Yup.string().required(lpErrorText.passwordLength).min(MIN_PASSWORD_LENGTH, lpErrorText.passwordLength),
  passwordConfirmation: Yup.string()
    .required(lpErrorText.passwordConfirmation)
    .test('passwords-do-not-match', lpErrorText.passwordMatch, function (passwordConfirmation) {
      const { password } = this.parent;
      return password === passwordConfirmation;
    }),
});
