export interface LpSvgProps {
  color?: string;
}

export enum LpSvgs {
  JIRA_WHITE = 'jira_white',
  LP_WAVY = 'lp_wavy',
  JIRA = 'jira',
  JIRA_SOFTWARE = 'jira_software',
  JIRA_BLUE_GRADIENT = 'jira_blue_gradient',
  PORTFOLIO = 'portfolio',
  PORTFOLIO_JIRA = 'portfolio_jira',
  TIMESHEET_LOGO = 'timesheet_logo',
  EPIC_BASED_SUBFOLDER = 'epic_based_subfolder',
  HIERARCHICAL_SUBFOLDERS = 'hierarchical_subfolders',
  FLAT_LIST = 'flat_list',
}
