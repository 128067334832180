import { ItemType, PackageStatus } from 'daos/enums';
import { Item } from 'daos/model_types';
import { itemAccessMovePermission, permissionCompare, permissionOrder } from 'daos/permission';
import {
  archiveSolid,
  layerGroupSolid,
  banRegular,
  keyRegular,
  boxAltSolid,
  boxHeartSolid,
  boxOpenSolid,
} from 'features/common/lp_icon';

const permissionTypeOrder = {
  [ItemType.TASKS]: permissionOrder.edit,
  [ItemType.FOLDERS]: permissionOrder.edit,
  [ItemType.PROJECTS]: permissionOrder.edit,
  [ItemType.PACKAGES]: permissionOrder.manage,
  [ItemType.WORKSPACE_ROOTS]: permissionOrder.admin,
};

export const canMoveToParent = (moveItem: ItemType, parentTargetItem: ItemType): boolean => {
  if (moveItem === ItemType.PROJECTS && parentTargetItem === ItemType.PROJECTS) {
    return false;
  }

  const isTaskOrSubfolder = moveItem === ItemType.TASKS || moveItem === ItemType.FOLDERS;
  if (isTaskOrSubfolder && parentTargetItem === ItemType.PACKAGES) {
    return false;
  }

  const permissionRange =
    permissionTypeOrder[parentTargetItem as keyof typeof permissionTypeOrder] -
    permissionTypeOrder[moveItem as keyof typeof permissionTypeOrder];
  return permissionRange <= 1 && permissionRange >= 0;
};

export const packageIcon = (key: PackageStatus) => {
  switch (key) {
    case PackageStatus.SCHEDULED:
      return boxAltSolid;
    case PackageStatus.CAPACITY:
      return boxOpenSolid;
    case PackageStatus.BACKLOG:
      return layerGroupSolid;
    case PackageStatus.ARCHIVED:
      return archiveSolid;
    case PackageStatus.TEMPLATE:
      return boxHeartSolid;
  }
};

export const errorIcon = (item: Item, parent: Item) => {
  if (!item || !parent) {
    return;
  }

  const canMove = canMoveToParent(item.itemType, parent.itemType);
  const hasPermission = permissionCompare(parent.permission, itemAccessMovePermission(parent.itemType)) <= 0;

  if (!canMove) {
    return banRegular;
  } else if (!hasPermission) {
    return keyRegular;
  } else if (canMove && hasPermission) {
    return;
  }
};
