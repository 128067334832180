import { Message } from 'semantic-ui-react';

import { LpIcon, exclamationTriangleSolid } from 'features/common/lp_icon';
import { LP_SUPPORT_EMAIL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';

export const UnknownError = () => {
  const header = "We've hit an unexpected error.";

  return (
    <Message error={true} className="error-page__unknown-error">
      <LpIcon className="error-page__unknown-error-icon" icon={exclamationTriangleSolid} size="10x" />

      <Message.Content className="error-page__unknown-error-content">
        {header !== undefined ? <Message.Header content={header} /> : undefined}
        <p className="error-page__unknown-error-content-text">
          {'Select from the options above and contact '}
          <span>
            <NewWindowLink to={`mailto:${LP_SUPPORT_EMAIL}`}>{LP_SUPPORT_EMAIL}</NewWindowLink> if you have any trouble.
          </span>
        </p>
      </Message.Content>
    </Message>
  );
};
