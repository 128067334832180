import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

interface JiraIntegrationParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraIntegrationRequestData {
  returnUrl: string;
}

interface JiraIntegrationResponseData {
  url: string;
}

const { resource } = defineModel({
  apiType: 'jiraAuthorization',
  type: 'JIRA_AUTHORIZATION',
});

const { JIRA_AUTHORIZATION } = resource;

const jiraOAuthRequest = (params: JiraIntegrationParams, data: JiraIntegrationRequestData) =>
  request(backend.jiraOAuthRequest.url(params), JIRA_AUTHORIZATION, {
    body: JSON.stringify({ data }),
    method: HttpMethod.POST,
  });

const jiraOAuthResponse = (params: JiraIntegrationParams, data: JiraIntegrationResponseData) =>
  request(backend.jiraOAuthResponse.url(params), JIRA_AUTHORIZATION, {
    body: JSON.stringify({ data }),
    method: HttpMethod.POST,
  });

export const JiraAuthorizationDao = {
  jiraOAuthRequest,
  jiraOAuthResponse,
} as const;
