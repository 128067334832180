import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Dependency } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { deleteBody, arrayBody, resource, updateBody } = defineModel({
  apiType: 'dependencies',
  relationships: ['predecessor', 'successor'],
  type: 'DEPENDENCY',
});

interface DependenciesParams {
  organizationId: number;
  workspaceId: number;
}

interface SingleDependencyParams extends DependenciesParams {
  dependencyId: number;
  itemId: number;
}

const { DEPENDENCY, resourceId } = resource;

const dependencyDestroy = (params: SingleDependencyParams, dependencyId: number) =>
  request(backend.dependency.url(params), DEPENDENCY, deleteBody(dependencyId));

const dependencyCreateBulk = (params: DependenciesParams, dependencies: Array<Partial<Dependency>>) =>
  request(backend.dependenciesBulk.url(params), DEPENDENCY, {
    ...arrayBody(undefined, dependencies),
    method: HttpMethod.POST,
  });

const dependencyUpdate = (params: SingleDependencyParams, dependency: Partial<Dependency>) =>
  request(backend.dependency.url(params), DEPENDENCY, {
    ...updateBody(params.dependencyId, dependency),
    method: HttpMethod.PATCH,
  });

const dependencyCreateBulkForItemChain = (params: DependenciesParams, itemIds: Array<number>) =>
  request(
    backend.dependenciesBulkForItemChain.url(params, {
      query: {
        itemIds: itemIds,
      },
    }),
    DEPENDENCY,
    { body: [], method: HttpMethod.POST }
  );

const dependencyDestroyBulk = (params: DependenciesParams, itemIds: Array<number>) =>
  request(
    backend.dependenciesBulk.url(params, {
      query: {
        itemIds: itemIds,
      },
    }),
    DEPENDENCY,
    { body: [], method: HttpMethod.DELETE, meta: { skipReduce: true } }
  );

export const DependencyDao = {
  createBulk: dependencyCreateBulk,
  createForItemChain: dependencyCreateBulkForItemChain,
  update: dependencyUpdate,
  destroy: dependencyDestroy,
  destroyBulk: dependencyDestroyBulk,
  id: resourceId,
} as const;
