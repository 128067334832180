import { gray800, lpBrandWhite } from 'style/variables';

export function getContrast(hexcolor: string) {
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }

  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map(function (hex) {
        return hex + hex;
      })
      .join('');
  }

  const red = parseInt(hexcolor.substr(0, 2), 16) * 299;
  const green = parseInt(hexcolor.substr(2, 2), 16) * 587;
  const blue = parseInt(hexcolor.substr(4, 2), 16) * 114;

  // Convert to YIQ space https://en.wikipedia.org/wiki/YIQ
  const yiq = (red + green + blue) / 1000;

  return yiq >= 180 ? gray800 : lpBrandWhite;
}
