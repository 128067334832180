import { useSelector } from 'react-redux';

import { PackageStatus } from 'daos/enums';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { LibraryLocation } from 'features/library/types';
import { frontend } from 'lib/urls';

import {
  getCollectionDashboardRoutes,
  getPackageDashboardRoutes,
  getProjectDashboardRoutes,
  getWorkspaceDashboardRoutes,
} from './helpers';

export const useDashboardRoutes = ({
  dashboardId,
  libraryLocation,
  itemId,
  packageStatus,
}: {
  libraryLocation: LibraryLocation | undefined;
  dashboardId: number | string;
  itemId: number;
  packageStatus: PackageStatus | null | undefined;
}) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const fallbackDashboardRoutes = {
    dashboardRoute: frontend.workspaceLibrary.url({ organizationId, workspaceId }),
    designLayoutRoute: frontend.workspaceLibrary.url({ organizationId, workspaceId }),
    designSettingsRoute: frontend.workspaceLibrary.url({ organizationId, workspaceId }),
    designGuestAccessRoute: frontend.workspaceLibrary.url({ organizationId, workspaceId }),
  };

  const baseProps = { organizationId, workspaceId };

  switch (libraryLocation) {
    case LibraryLocation.Collection: {
      const collectionProps = { ...baseProps, dashboardId };
      const collectionRoutes = getCollectionDashboardRoutes(collectionProps, packageStatus);
      return collectionRoutes ?? fallbackDashboardRoutes;
    }

    case LibraryLocation.Package: {
      const packageProps = { ...baseProps, dashboardId, packageId: itemId };
      return getPackageDashboardRoutes(packageProps);
    }
    case LibraryLocation.Project: {
      const projectProps = { ...baseProps, dashboardId, itemId };
      return getProjectDashboardRoutes(projectProps);
    }
    case LibraryLocation.Workspace: {
      const workspaceRootProps = { ...baseProps, dashboardId };
      return getWorkspaceDashboardRoutes(workspaceRootProps);
    }
    default:
      return fallbackDashboardRoutes;
  }
};
