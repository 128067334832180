import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table, Button } from 'semantic-ui-react';

import { CustomFieldValue } from 'daos/model_types';
import { LpIcon, timesCircleLight as clearInputIcon } from 'features/common/lp_icon';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { getFieldValuesForIds } from 'redux/entities/selectors/custom_field';
import 'features/common/custom_fields/custom_field_value/remove_sole_field_value_cell.scss';
import { getCurrentOrganization } from 'redux/entities/selectors/organization';

export enum RemoveSoleFieldValueCellSize {
  Cell = 'cell',
  Button = 'button',
}

const RemoveSoleFieldValueCell = ({
  className = 'remove-custom-field',
  valueIds,
  size = RemoveSoleFieldValueCellSize.Cell,
  disabled = false,
}: {
  size?: RemoveSoleFieldValueCellSize;
  valueIds: Array<number>;
  className?: string;
  disabled?: boolean;
}) => {
  const fieldValues = useSelector((state) => getFieldValuesForIds(state, valueIds)).filter(
    (fieldValue): fieldValue is CustomFieldValue => !!fieldValue
  );
  const organization = useSelector(getCurrentOrganization);
  const hasDataCustomization = organization?.flags.hasDataCustomization;
  const resourceType = fieldValues[0]?.workspaceUser ? 'workspaceUsers' : 'items';
  const customFieldsApiActions = useCustomFieldsApiActions(resourceType);
  const enableIcon = fieldValues.length;

  const handleRemoveFieldValueClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      fieldValues.forEach((fieldValue) => {
        if (fieldValue.item?.id) {
          customFieldsApiActions.removeFieldValue(fieldValue.item.id, fieldValue.id);
        } else if (fieldValue.workspaceUser?.id) {
          customFieldsApiActions.removeFieldValue(fieldValue.workspaceUser.id, fieldValue.id);
        }
      });
    },
    [fieldValues, customFieldsApiActions]
  );

  const icon = useMemo(() => {
    return (
      <Button
        className={`${className} delete-button icon`}
        onClick={enableIcon ? handleRemoveFieldValueClick : undefined}
        disabled={disabled}
      >
        <LpIcon className={!enableIcon ? 'disabled' : ''} icon={clearInputIcon} />
      </Button>
    );
  }, [className, disabled, enableIcon, handleRemoveFieldValueClick]);

  const soleField = useMemo(() => {
    if (!hasDataCustomization) {
      return null;
    }

    switch (size) {
      case RemoveSoleFieldValueCellSize.Cell:
        return (
          <Table.Cell className={className} collapsing>
            {icon}
          </Table.Cell>
        );
      default:
        return icon;
    }
  }, [className, hasDataCustomization, icon, size]);

  return soleField;
};

export default RemoveSoleFieldValueCell;
