import { useSelector } from 'react-redux';

import { ScheduleDirective } from 'daos/enums';
import { Iteration } from 'daos/model_types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { getHasPredecessors, getHasSuccessors } from 'redux/entities/selectors/dependency';
import { getItemForId } from 'redux/entities/selectors/item';
import { getIterationForItemId } from 'redux/entities/selectors/iterations';

export const calcInActiveIteration = (asapStatus: ScheduleDirective, iteration?: Partial<Iteration>) =>
  asapStatus === ScheduleDirective.NORMAL && !!iteration && !iteration.done;

export const useIconsForItem = (itemId: number) => {
  const hasIterationFeature = useHasFeature(FeatureFlag.iterationBucket);

  const item = useSelector((state) => getItemForId(state, itemId));
  const hasPredecessors = useSelector((state) => getHasPredecessors(state, itemId));
  const hasSuccessors = useSelector((state) => getHasSuccessors(state, itemId));
  const iteration = useSelector((state) => (hasIterationFeature ? getIterationForItemId(state, itemId) : undefined));

  const asapStatus = item?.scheduleDirective ?? ScheduleDirective.NORMAL;
  const inActiveIteration = calcInActiveIteration(asapStatus, iteration);

  return {
    asapStatus,
    hasSuccessorOrPredecessor: hasPredecessors || hasSuccessors,
    inActiveIteration,
    isScheduleLate: !!item?.late,
  };
};
