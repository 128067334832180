import { useState, useEffect, useCallback } from 'react';
import { Form, TextArea, TextAreaProps } from 'semantic-ui-react';

import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';
import { OPTIONAL } from 'lib/constants';

const CustomFieldMultilineText = ({ fieldId, resourceId, disabled = false }: CustomFieldValueProps) => {
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);
  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);

  const { allowsMultipleValues, values } = field ?? {};

  const fieldValue = values?.[0];
  const fieldValueText = fieldValue?.text ?? '';

  if (allowsMultipleValues) {
    throw new Error('multiple values not supported by Description field');
  }

  const [content, setContent] = useState(fieldValueText);

  useEffect(() => {
    setContent(fieldValueText);
  }, [fieldValueText]);

  const handleChange = ({ currentTarget: { value } }: TextAreaProps) => {
    setContent(value);
  };

  const handleSubmit = useCallback(() => {
    if (/^\s*$/.test(content)) {
      if (fieldValue) {
        removeFieldValue(resourceId.id, fieldValue.id);
      }
    } else {
      const payload = { text: '' + content };
      if (fieldValue) {
        updateFieldValue(resourceId.id, fieldValue.id, payload);
      } else {
        createFieldValue(resourceId.id, fieldId, payload);
      }
    }
  }, [content, fieldValue, removeFieldValue, resourceId.id, updateFieldValue, createFieldValue, fieldId]);

  return (
    <Form autoComplete="off">
      <TextArea
        placeholder={OPTIONAL}
        value={content}
        onChange={handleChange}
        onBlur={handleSubmit}
        disabled={disabled}
      />
    </Form>
  );
};

export default CustomFieldMultilineText;
