import {
  PropertyChangeHistoryProperties,
  Permission,
  WorkType,
  WorkTypeToWorkTypeDisplay,
  SchedulingLimitType,
  ChangeModelType,
} from 'daos/enums';
import { TargetFinishToTargetFinishDisplayMap, TargetFinishType } from 'daos/item_enums';
import { accessRoleByPermission } from 'daos/permission';
import { ChangeEntity, PropertyChange } from 'daos/types';
import ChangeCardColorProperty from 'features/common/changes/change_card_property/change_card_color_property';
import ChangeCardItemProperty from 'features/common/changes/change_card_property/change_card_item_property';
import { ChangeCardIteration } from 'features/common/changes/change_card_property/change_card_iteration';
import { ChangeCardLimitSecondsToHours } from 'features/common/changes/change_card_property/change_card_limit_seconds_to_hours';
import ChangeCardLoggedTimeProperty from 'features/common/changes/change_card_property/change_card_logged_time_property';
import ChangeCardParentProperty from 'features/common/changes/change_card_property/change_card_parent_property';
import ChangeCardStatusProperty from 'features/common/changes/change_card_property/change_card_status_property';
import ChangeCardWorkLimit from 'features/common/changes/change_card_property/change_card_work_limit';
import { schedulingLimitTypeForDisplayValue } from 'features/item_panel/sections/planning_section/limits/limit_type_dropdown';
import { InlineDependencyStatus } from 'features/shared/components/inline_dependency_status';

import ChangeCardBooleanProperty from './change_card_boolean_property';
import ChangeCardCostCode from './change_card_cost_code';
import ChangeCardDate from './change_card_date_property';
import ChangeCardEstimate from './change_card_estimate';
import { ChangeCardFileProperty } from './change_card_file_property';
import ChangeCardGroupProperty from './change_card_group_property';
import ChangeCardPickListChoiceProperty from './change_card_picklist_choice_property';
import ChangeCardPriorityRushProperty from './change_card_priority_rush_property';
import ChangeCardRateSheet from './change_card_rate_sheet_property';
import ChangeCardStoryPoints from './change_card_stoty_points_property';
import ChangeCardUrlProperty from './change_card_url_property';
import ChangeCardUserProperty from './change_card_user_property';

import './change_card_property.scss';
interface ChangeCardPropertyProps {
  changeId: number;
  itemId: number;
  propertyChangeData?: PropertyChange;
  property: string;
  resource: ChangeEntity;
}

const ChangeCardProperty = ({ itemId, propertyChangeData, changeId, property, resource }: ChangeCardPropertyProps) => {
  if (!propertyChangeData) {
    return null;
  }

  if (resource.type === ChangeModelType.Dependencies) {
    switch (property) {
      case PropertyChangeHistoryProperties.Status:
        return <InlineDependencyStatus dependencyStatus={propertyChangeData.value} />;
    }
  }

  switch (property) {
    case PropertyChangeHistoryProperties.Status:
      return <ChangeCardStatusProperty changeId={changeId} propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.BillingRateSheet:
      return <ChangeCardRateSheet propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.PayRateSheet:
      return <ChangeCardRateSheet propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.Parent:
      return <ChangeCardParentProperty itemId={propertyChangeData.value?.id ?? 0} parentId={resource.id} />;
    case PropertyChangeHistoryProperties.Item:
    case PropertyChangeHistoryProperties.Predecessor:
      return <ChangeCardItemProperty itemId={propertyChangeData.value?.id ?? 0} />;
    case PropertyChangeHistoryProperties.Iteration:
      return <ChangeCardIteration iterationId={propertyChangeData.value} />;
    case PropertyChangeHistoryProperties.Color:
      return <ChangeCardColorProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.URL:
      return <ChangeCardUrlProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.Choice:
      return <ChangeCardPickListChoiceProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.User:
      return <ChangeCardUserProperty propertyChangeData={propertyChangeData} resource={resource} />;
    case PropertyChangeHistoryProperties.PriorityRush:
      return <ChangeCardPriorityRushProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.BenchmarkEstimate:
    case PropertyChangeHistoryProperties.HighEstimate:
    case PropertyChangeHistoryProperties.LowEstimate:
      return <ChangeCardEstimate propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.TargetFinish:
    case PropertyChangeHistoryProperties.TargetStart:
      return <ChangeCardDate propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.CostCode:
      return <ChangeCardCostCode propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.Group:
      return <ChangeCardGroupProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.AccessLevel:
      return <>{accessRoleByPermission[propertyChangeData.value as Permission]}</>;
    case PropertyChangeHistoryProperties.Checked:
    case PropertyChangeHistoryProperties.Done:
    case PropertyChangeHistoryProperties.Reprioritized:
      return <ChangeCardBooleanProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.Limit:
      return <ChangeCardLimitSecondsToHours propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.Type:
      return <>{schedulingLimitTypeForDisplayValue[propertyChangeData.value as SchedulingLimitType] ?? 'Unknown'}</>;
    case PropertyChangeHistoryProperties.LoggedTime:
      return <ChangeCardLoggedTimeProperty propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.AtFinish:
      return <>{TargetFinishToTargetFinishDisplayMap[propertyChangeData.value as TargetFinishType]}</>;
    case PropertyChangeHistoryProperties.AssignmentsSchedule:
      return <>{WorkTypeToWorkTypeDisplay[propertyChangeData.value as WorkType]}</>;
    case PropertyChangeHistoryProperties.StoryPoints:
      return <ChangeCardStoryPoints propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.Deleted:
      return <>{propertyChangeData.value ? 'True' : 'False'}</>;
    case PropertyChangeHistoryProperties.WorkLimit:
      return <ChangeCardWorkLimit propertyChangeData={propertyChangeData} />;
    case PropertyChangeHistoryProperties.File:
      return (
        <ChangeCardFileProperty propertyChangeData={propertyChangeData} itemFileId={resource.id} itemId={itemId} />
      );
    case PropertyChangeHistoryProperties.Description:
    default:
      return <>{propertyChangeData.value ?? 'None'}</>;
  }
};

export default ChangeCardProperty;
