import { Button } from 'semantic-ui-react';

import { LpIcon, circleXmarkLight } from 'features/common/lp_icon';

export function FieldClearButton({ disabled, handleClear }: { disabled: boolean; handleClear: () => void }) {
  return (
    <Button
      disabled={disabled}
      className="intake-widget-user-form-section__sub-section-table-clear-button"
      onClick={handleClear}
    >
      <LpIcon className="intake-widget-user-form-section__sub-section-table-clear-icon" icon={circleXmarkLight} />
    </Button>
  );
}
