import { defineModel } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

interface JiraResourcesParams {
  organizationId: number;
  workspaceId: number;
}

interface JiraAccessibleResourcesOptions {
  query: {
    oauthCredentialsId: number;
  };
}

const { resource } = defineModel({
  apiType: 'jiraResources',
  type: 'JIRA_RESOURCES',
});

const { JIRA_RESOURCES } = resource;

const getJiraAccessibleResources = (params: JiraResourcesParams, { query }: JiraAccessibleResourcesOptions) =>
  request(backend.jiraAccessibleResources.url(params, { query }), JIRA_RESOURCES, {
    method: HttpMethod.GET,
  });

const getJiraOAuthAccounts = (params: JiraResourcesParams) =>
  request(backend.jiraOauthAccounts.url(params), JIRA_RESOURCES, {
    method: HttpMethod.GET,
  });

export const JiraResourcesDao = {
  getJiraAccessibleResources,
  getJiraOAuthAccounts,
} as const;
