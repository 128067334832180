import { createSelector } from 'reselect';

import { Item, Dashboard, WidgetGroup, Workspace } from 'daos/model_types';
import { DashboardType } from 'features/dashboards/dashboard_types';
import {
  getDashboardForId,
  getDashboardsById,
  getWidgetForId,
  getWidgetGroupForId,
  getWidgetGroupsById,
} from 'features/dashboards/selectors';
import { getItemsById } from 'redux/entities/selectors/item';
import { getStringArgument } from 'redux/entities/selectors/shared';
import { getWorkspacesById } from 'redux/entities/selectors/workspace';
import { EntityLookupById } from 'redux/entities/types';

import { isDefaultFilter } from './common/location_filters';
import { LocationScope } from './item_filter/types';

export const getItemForDashboardId = createSelector(
  getDashboardForId,
  getWorkspacesById,
  getItemsById,
  (dashboard, workspacesById, itemsById) => {
    const workspace = dashboard ? workspacesById[dashboard.workspaceId] : null;
    const workspaceRootId = workspace?.workspaceRoot.id;

    if (!dashboard || !workspace) {
      return undefined;
    }

    switch (dashboard.dashboardType) {
      case DashboardType.packages:
      case DashboardType.projects:
        return dashboard.itemId ? itemsById[dashboard.itemId] : undefined;
      case DashboardType.workspaces:
      case DashboardType.collections:
        return workspaceRootId ? itemsById[workspaceRootId] : undefined;
    }
  }
);

export const getLocationScopeForDashboardId = createSelector(
  getStringArgument,
  getDashboardsById,
  getItemsById,
  (dashboardId, dashboards, items): LocationScope => {
    return locationScopeForDashboardId(dashboards, items, dashboardId);
  }
);

export const getInheritedLocationScopeForDashboardId = createSelector(
  getDashboardForId,
  getWorkspacesById,
  getItemsById,
  (dashboard, workspaces, items): LocationScope => {
    const defaultLocationScope = locationScopeForWorkspaceId(workspaces, items, dashboard?.workspaceId);

    return {
      locationFilterItem: defaultLocationScope.locationFilterItem ?? null,
      locationFilterPackageStatus: null,
    };
  }
);

export const getDefaultLocationScopeForDashboardId = createSelector(
  getLocationScopeForDashboardId,
  getInheritedLocationScopeForDashboardId,
  (location, inheritedLocation): LocationScope => {
    return {
      locationFilterItem: location.locationFilterItem ?? inheritedLocation.locationFilterItem,
      locationFilterPackageStatus:
        location.locationFilterPackageStatus ?? inheritedLocation.locationFilterPackageStatus,
    };
  }
);

export const getLocationScopeForWidgetGroupId = createSelector(
  getStringArgument,
  getWidgetGroupsById,
  getItemsById,
  (widgetGroupId, widgetGroups, items): LocationScope => {
    return locationScopeForWidgetGroupId(widgetGroups, items, widgetGroupId);
  }
);

export const getWidgetGroupIsFilteredForWidgetGroupId = createSelector(getWidgetGroupForId, (widgetGroup) => {
  if (!widgetGroup) {
    return false;
  }

  const isWorkloadWidget = false;

  return !isDefaultFilter(widgetGroup.config, isWorkloadWidget);
});

export const getWidgetDashboardIsFilteredForWidgetDashboardId = createSelector(getDashboardForId, (dashboard) => {
  if (!dashboard) {
    return false;
  }

  const isWorkloadWidget = false;

  return !isDefaultFilter(dashboard.config, isWorkloadWidget);
});

export const getInheritedLocationScopeForWidgetGroupId = createSelector(
  getWidgetGroupForId,
  getWorkspacesById,
  getDashboardsById,
  getItemsById,
  (widgetGroup, workspaces, dashboards, items): LocationScope => {
    const defaultLocationScope = locationScopeForWorkspaceId(workspaces, items, widgetGroup?.workspaceId);
    const dashboardLocationScope = locationScopeForDashboardId(dashboards, items, widgetGroup?.dashboardId);

    return {
      locationFilterItem: dashboardLocationScope.locationFilterItem ?? defaultLocationScope.locationFilterItem,
      locationFilterPackageStatus: dashboardLocationScope.locationFilterPackageStatus,
    };
  }
);

export const getDefaultLocationScopeForWidgetGroupId = createSelector(
  getLocationScopeForWidgetGroupId,
  getInheritedLocationScopeForWidgetGroupId,
  (location, inheritedLocation): LocationScope => {
    return {
      locationFilterItem: location.locationFilterItem ?? inheritedLocation.locationFilterItem,
      locationFilterPackageStatus:
        location.locationFilterPackageStatus ?? inheritedLocation.locationFilterPackageStatus,
    };
  }
);

export const getInheritedLocationScopeForWidgetId = createSelector(
  getWidgetForId,
  getWorkspacesById,
  getDashboardsById,
  getWidgetGroupsById,
  getItemsById,
  (widget, workspaces, dashboards, widgetGroups, items): LocationScope => {
    const defaultLocationScope = locationScopeForWorkspaceId(workspaces, items, widget?.workspaceId);
    const dashboardLocationScope = locationScopeForDashboardId(dashboards, items, widget?.dashboardId);
    const widgetGroupLocationScope = locationScopeForWidgetGroupId(widgetGroups, items, widget?.widgetGroupId);

    return {
      locationFilterItem:
        widgetGroupLocationScope.locationFilterItem ??
        dashboardLocationScope.locationFilterItem ??
        defaultLocationScope.locationFilterItem,
      locationFilterPackageStatus:
        widgetGroupLocationScope.locationFilterPackageStatus ??
        dashboardLocationScope.locationFilterPackageStatus ??
        defaultLocationScope.locationFilterPackageStatus,
    };
  }
);

function locationScopeForWorkspaceId(
  workspaces: EntityLookupById<Workspace>,
  items: EntityLookupById<Item>,
  workspaceId?: string
): LocationScope {
  const workspace = workspaces[workspaceId ?? 0];
  const workspaceRoot = items[workspace?.workspaceRoot?.id ?? 0];

  return {
    locationFilterItem: workspaceRoot ?? null,
    locationFilterPackageStatus: null,
  };
}

function locationScopeForDashboardId(
  dashboards: EntityLookupById<Dashboard>,
  items: EntityLookupById<Item>,
  dashboardId?: string
): LocationScope {
  const dashboard = dashboards[dashboardId ?? 0];
  const dashboardLocationItemId = parseInt(dashboard?.config?.locationFilterItemId ?? 'NaN', 10);
  const dashboardLocation = isNaN(dashboardLocationItemId) ? undefined : items[dashboardLocationItemId];
  const dashboardLocationPackageStatus = dashboard?.config?.locationFilterPackageStatus;

  return {
    locationFilterItem: dashboardLocation ?? null,
    locationFilterPackageStatus: dashboardLocationPackageStatus ?? null,
  };
}

function locationScopeForWidgetGroupId(
  widgetGroups: EntityLookupById<WidgetGroup>,
  items: EntityLookupById<Item>,
  widgetGroupId?: string
): LocationScope {
  const widgetGroup = widgetGroups[widgetGroupId ?? 0];
  const widgetGroupLocationItemId = parseInt(widgetGroup?.config?.locationFilterItemId ?? 'NaN', 10);
  const widgetGroupLocation = isNaN(widgetGroupLocationItemId) ? undefined : items[widgetGroupLocationItemId];
  const widgetGroupLocationPackageStatus = widgetGroup?.config?.locationFilterPackageStatus;

  return {
    locationFilterItem: widgetGroupLocation ?? null,
    locationFilterPackageStatus: widgetGroupLocationPackageStatus ?? null,
  };
}

export const getLocationScopeAndFiltersForWidgetGroupId = createSelector(
  getWidgetForId,
  getWidgetGroupsById,
  getItemsById,
  (widget, widgetGroups, items) => {
    const widgetGroupId = widget?.widgetGroupId;
    const widgetGroup = widgetGroupId ? widgetGroups[widgetGroupId] : undefined;
    const groupIsFiltered = widgetGroup ? !isDefaultFilter(widgetGroup.config, false) : false;
    const widgetGroupLocationScope = locationScopeForWidgetGroupId(widgetGroups, items, widgetGroupId);

    return { widgetGroupLocationScope, groupIsFiltered };
  }
);
