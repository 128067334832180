import { ItemTypesDisplaySingular } from 'daos/item_enums';

export enum DashboardType {
  workspaces = 'workspaces',
  collections = 'collections',
  packages = 'packages',
  projects = 'projects',
}

export const DashboardTypeDisplay = {
  [DashboardType.packages]: ItemTypesDisplaySingular.Package,
  [DashboardType.projects]: ItemTypesDisplaySingular.Project,
  [DashboardType.workspaces]: 'Workspace',
  [DashboardType.collections]: 'Portfolio',
};
