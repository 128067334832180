import { useState, useRef, SyntheticEvent } from 'react';
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { Button } from 'semantic-ui-react';

import LpModal from 'features/common/modals/lp_modal';

import 'react-image-crop/dist/ReactCrop.css';

interface UserAvatarCropProps {
  fileTypeExtension: string;
  handleAvatarUpload: (croppedImageUrl: string) => void;
  handleCloseModal: () => void;
  src: string;
}
const INITIAL_CROP_SIZE = 400;
const INITIAL_CROP_POSITION = 10;
const INITIAL_CROP_ASPECT = 1; // e.g. 1 for a square or 16 / 9 for landscape

function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: mediaWidth,
        height: mediaHeight,
      },
      INITIAL_CROP_ASPECT,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const UserAvatarCrop = ({ fileTypeExtension, handleAvatarUpload, handleCloseModal, src }: UserAvatarCropProps) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>();

  const handleCropChange = (crop: Crop) => setCrop(crop);

  const onImageLoaded = (event: SyntheticEvent<HTMLImageElement>) => {
    const { height, width } = event.currentTarget;

    imageRef.current = event.currentTarget;

    setCrop(centerAspectCrop(width, height));
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop);
      return handleAvatarUpload(croppedImageUrl);
    }
  };

  const onCropComplete = () => (crop ? makeClientCrop(crop) : undefined);

  const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width ?? INITIAL_CROP_SIZE;
    canvas.height = crop.height ?? INITIAL_CROP_SIZE;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      (crop.x ?? INITIAL_CROP_POSITION) * scaleX,
      (crop.y ?? INITIAL_CROP_POSITION) * scaleY,
      (crop.width ?? INITIAL_CROP_SIZE) * scaleX,
      (crop.height ?? INITIAL_CROP_SIZE) * scaleY,
      0,
      0,
      crop.width ?? INITIAL_CROP_SIZE,
      crop.height ?? INITIAL_CROP_SIZE
    );

    return new Promise<string>((resolve, reject) => {
      canvas.toBlob((blob: Blob | null) => {
        if (!blob) {
          return reject(new Error('Canvas is empty'));
        }

        URL.revokeObjectURL(src);

        const srcToReturn = URL.createObjectURL(blob);

        resolve(srcToReturn);
      }, fileTypeExtension);
    });
  };

  return (
    <LpModal
      className="avatar-edit-menu__cropper-modal"
      closeOnDimmerClick={false}
      onClose={handleCloseModal}
      header={'Update Photo'}
      content={
        <ReactCrop crop={crop} onChange={handleCropChange} aspect={INITIAL_CROP_ASPECT} keepSelection={true}>
          <img src={src} onLoad={onImageLoaded} />
        </ReactCrop>
      }
      actions={
        <>
          <Button content="Cancel" onClick={handleCloseModal} />
          <Button content="Save" onClick={onCropComplete} primary />
        </>
      }
    />
  );
};

export default UserAvatarCrop;
