import classNames from 'classnames';

import { LpIcon, angleDownRegular as toggleOpen, angleRightRegular as toggleClose } from 'features/common/lp_icon';

import './index.scss';

interface ToggleButtonProps {
  expanded: boolean;
  onToggle: (expanded: boolean) => void;
  className?: string;
}

const ToggleButton = ({ expanded, onToggle, className }: ToggleButtonProps) => {
  const handleToggle = () => onToggle(!expanded);

  return (
    <span className={classNames('lp-toggle-button', !!className && className)} onClick={handleToggle}>
      <LpIcon className="icon" icon={expanded ? toggleOpen : toggleClose} />
    </span>
  );
};

export default ToggleButton;
