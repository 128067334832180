import { useSelector } from 'react-redux';

import { PropertyChange } from 'daos/types';
import { getGroupForId } from 'redux/entities/selectors/group';

const ChangeCardGroupProperty = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const groupId = propertyChangeData.value;
  const group = useSelector((state) => (groupId ? getGroupForId(state, groupId) : undefined));

  return <>{group?.name ?? ''}</>;
};

export default ChangeCardGroupProperty;
