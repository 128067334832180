import classNames from 'classnames';
import { isNumber } from 'lodash';
import { ReactNode } from 'react';
import { Button } from 'semantic-ui-react';

import ProgressBar, { ProgressBarColorTheme } from 'containers/shared/progress_bar';
import { backend } from 'daos/urls';
import { LpButtonAsLink } from 'features/common/as_components';
import { LpIcon, IconDefinition } from 'features/common/lp_icon';
import { LpSvg, LpSvgs } from 'features/common/lp_svg';

import 'features/common/navigation_buttons/index.scss';

type NavigationButtonBuilderProps = {
  text?: string;
  subText?: JSX.Element;
  key: string;
} & ({ icon: IconDefinition; svg?: never } | { icon?: never; svg: LpSvgs });

interface NavigationButtonWithProgressBarBuilderProps {
  percentage?: number;
  progressBarColorTheme?: ProgressBarColorTheme;
  progressBarSwitchPoint?: number;
}

interface NavigationButtonProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  toUrl?: string;
  dataTestId?: string;
}

const NavigationButton = ({ children, className, disabled, onClick, toUrl, dataTestId }: NavigationButtonProps) => {
  const baseProps = {
    className: classNames('lp-navigation-buttons__button', className),
    disabled,
    onClick,
  };

  if (!toUrl) {
    return (
      <Button data-testid={dataTestId} {...baseProps}>
        {children}
      </Button>
    );
  }

  const lowercaseUrl = toUrl?.toLowerCase();
  const isExternalLink =
    !!lowercaseUrl && (lowercaseUrl.startsWith('http') || lowercaseUrl.startsWith(backend.base.pattern));

  return (
    <LpButtonAsLink to={toUrl} openInNewWindow={isExternalLink} dataTestId={dataTestId} {...baseProps}>
      {children}
    </LpButtonAsLink>
  );
};

interface NavigationButtonsProps {
  className?: string;
  buttonOptions: Array<
    NavigationButtonProps & NavigationButtonBuilderProps & NavigationButtonWithProgressBarBuilderProps
  >;
}

export const NavigationButtons = ({ className, buttonOptions }: NavigationButtonsProps) => {
  const buttons = buttonOptions.map((navButtonOption) => {
    const isPercentage = isNumber(navButtonOption.percentage);
    const navigationButtonOptions = { className, ...navButtonOption };

    return (
      <NavigationButton
        {...navigationButtonOptions}
        dataTestId={navButtonOption.className}
        key={navButtonOption.key}
        className={classNames(className, navButtonOption.className)}
      >
        {navButtonOption.icon && (
          <LpIcon
            className={classNames('lp-navigation-buttons__icon', { [`${className}-icon`]: className })}
            icon={navButtonOption.icon}
            size={'3x'}
          />
        )}
        {navButtonOption.svg && (
          <LpSvg
            className={classNames('lp-navigation-buttons__icon', { [`${className}-icon`]: className })}
            size={45}
            icon={navButtonOption.svg}
          />
        )}
        <div className={classNames('lp-navigation-buttons__text', { [`${className}-text`]: className })}>
          {navButtonOption.text}
          {navButtonOption.subText && (
            <div className={classNames('lp-navigation-buttons__sub-text', { [`${className}-sub-text`]: className })}>
              {navButtonOption.subText}
            </div>
          )}
        </div>
        {isPercentage && (
          <span
            className={classNames('lp-navigation-buttons__button-percentage', {
              [`${className}-button-percentage`]: className,
            })}
          >
            <ProgressBar
              percent={navButtonOption.percentage ?? 0}
              className={classNames('lp-navigation-buttons__button-percentage-bar', {
                [`${className}-button-percentage-bar`]: className,
              })}
              colorTheme={navButtonOption.progressBarColorTheme}
              colorSwitchPoint={navButtonOption.progressBarSwitchPoint}
            />
          </span>
        )}
      </NavigationButton>
    );
  });

  return <>{buttons}</>;
};
