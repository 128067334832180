import { Table } from 'semantic-ui-react';

import { IconDefinition } from 'features/common/lp_icon';
import { renderDeleteIcon } from 'features/jira_project/modal/helpers';

export const JiraProjectModalTableDeleteCell = ({
  enabled,
  onClick,
  icon,
  hoverText,
}: {
  enabled: boolean;
  onClick: () => void;
  icon?: IconDefinition;
  hoverText?: string;
}) => (
  <Table.Cell className="remove-custom-field" collapsing>
    {renderDeleteIcon({
      enabled,
      onClick,
      icon,
      hoverText,
    })}
  </Table.Cell>
);
