import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, Table } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { UserDao } from 'daos/user';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import LpInput from 'features/common/inputs/lp_input';
import { setOrgHubUserUpdateError } from 'features/organization_directory/slice';
import OrgUserFormatSettingsDropdown from 'features/shared/dropdowns/org_user_format_settings';
import OrgUserTimezoneDropdown from 'features/shared/dropdowns/org_user_timezone';
import { useHasAccess } from 'hooks/use_has_access';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { awaitRequestFinish } from 'lib/api';
import { shouldNameFieldBeDisabled } from 'lib/display_helpers/should_name_field_be_disabled';
import {
  dateFormatToDisplay,
  firstDayOfWeekToDisplay,
  timeFormatToDisplay,
  weekNumberFormatToDisplay,
} from 'lib/localization';
import { DateFormat, FirstDayOfWeek, TimeFormat, WeekNumberFormat } from 'lib/localization_enums';
import {
  getCurrentOrganizationUser,
  getIsGuestCurrentWorkspaceUser,
  getIsOrgUserHasAnyGuestWorkspaces,
  getOrganizationUserForId,
  getUserForId,
} from 'redux/entities/selectors/user';

interface UserOnChangeValues {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const OrganizationDirectoryUsersProfileInformationTable = ({
  orgUserId,
  isGuestProfile,
  orgUserDisconnected,
  profileType,
}: {
  orgUserId: number;
  isGuestProfile: boolean;
  orgUserDisconnected: boolean;
  profileType: 'organizationUser' | 'workspaceUser';
}) => {
  const dispatch = useDispatch();

  const { formatLocalDate } = useLocalizedFormats();

  const organizationId = useSelector(getCurrentOrganizationId);
  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const currentOrganizationUser = useSelector(getCurrentOrganizationUser);

  const isCurrentUser = orgUserId === currentOrganizationUser?.id;

  const userId = orgUser?.user?.id;

  const user = useSelector((state) => (userId ? getUserForId(state, userId) : undefined));

  const hasEditAccess = useHasAccess(Permission.EDIT, UserDao.id(userId ?? 0));
  const isCurrentWorkspaceuserGuest = useSelector(getIsGuestCurrentWorkspaceUser);

  if (!user || !orgUser || !userId) {
    return null;
  }

  const handleOrgUserInputChange = (orgUser: Partial<OrganizationUser>) => {
    if (orgUserId) {
      dispatch(setOrgHubUserUpdateError(undefined));
      const { uuid } = dispatch(
        OrganizationUserDao.update(
          { organizationId, organizationUserId: orgUserId },
          { id: orgUserId, ...orgUser },
          { include: { includeWorkspaceUsers: true } }
        )
      );

      dispatch(
        awaitRequestFinish(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              dispatch(setOrgHubUserUpdateError(errors[0]));
            }
          },
        })
      );
    }
  };

  const handleUserInputChange = (value: UserOnChangeValues) => {
    dispatch(setOrgHubUserUpdateError(undefined));
    const { uuid } = dispatch(UserDao.update({ userId }, { id: userId, ...value }));

    dispatch(
      awaitRequestFinish(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            dispatch(setOrgHubUserUpdateError(errors[0]));
          }
        },
      })
    );
  };

  const handleOrgAdminCheckboxValue = (_: FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    handleOrgUserInputChange({ admin: checked });
  };

  const disableNameField = !hasEditAccess || shouldNameFieldBeDisabled(orgUserDisconnected, user.userType);

  return (
    <Table celled compact="very" className="organization-directory__users-profile-information-table">
      <Table.Body>
        {!isCurrentWorkspaceuserGuest && (
          <Table.Row>
            <Table.Cell collapsing>Username</Table.Cell>
            <Table.Cell className="organization-directory__users-profile-information-table-input-cell organization-directory__users-profile-information-table-row">
              <LpInput
                className="organization-directory__users-profile-information-table-input"
                disabled={isGuestProfile}
                value={orgUser.username}
                onChange={(onChangeValue) => handleOrgUserInputChange({ username: onChangeValue })}
              />
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell collapsing>First Name</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-input-cell">
            <LpInput
              className="organization-directory__users-profile-information-table-input"
              disabled={disableNameField}
              value={user.firstName}
              onChange={(onChangeValue) => handleUserInputChange({ firstName: onChangeValue })}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Last Name</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-input-cell">
            <LpInput
              className="organization-directory__users-profile-information-table-input"
              disabled={disableNameField}
              value={user.lastName}
              onChange={(onChangeValue) => handleUserInputChange({ lastName: onChangeValue })}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Email</Table.Cell>
          {orgUserDisconnected ? (
            <Table.Cell className="disconnected">{user.email} Needs to be reconnected</Table.Cell>
          ) : (
            <Table.Cell
              disabled={!isCurrentUser}
              className="organization-directory__users-profile-information-table-input-cell"
            >
              <LpInput
                className="organization-directory__users-profile-information-table-input"
                disabled={orgUserDisconnected || !isCurrentUser || isGuestProfile}
                value={user.email}
                onChange={(onChangeValue) => handleUserInputChange({ email: onChangeValue })}
              />
            </Table.Cell>
          )}
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Timezone</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-dropdown-cell">
            <OrgUserTimezoneDropdown orgUserId={orgUser.id} updateOrgUser={handleOrgUserInputChange} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Date Format</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-dropdown-cell">
            <OrgUserFormatSettingsDropdown
              formatSettingToDisplay={dateFormatToDisplay}
              formatSettingsEnum={DateFormat}
              orgUserId={orgUser.id}
              orgUserProperty={{ dateFormat: orgUser.dateFormat }}
              orgUserPropertyString={'dateFormat'}
              updateOrgUser={handleOrgUserInputChange}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Time Format</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-dropdown-cell">
            <OrgUserFormatSettingsDropdown
              formatSettingToDisplay={timeFormatToDisplay}
              formatSettingsEnum={TimeFormat}
              orgUserId={orgUser.id}
              orgUserProperty={{ timeFormat: orgUser.timeFormat }}
              orgUserPropertyString={'timeFormat'}
              updateOrgUser={handleOrgUserInputChange}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Week Starts on</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-dropdown-cell">
            <OrgUserFormatSettingsDropdown
              formatSettingToDisplay={firstDayOfWeekToDisplay}
              formatSettingsEnum={FirstDayOfWeek}
              orgUserId={orgUser.id}
              orgUserProperty={{ firstDayOfWeek: orgUser.firstDayOfWeek }}
              orgUserPropertyString={'firstDayOfWeek'}
              updateOrgUser={handleOrgUserInputChange}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>Week Numbering</Table.Cell>
          <Table.Cell className="organization-directory__users-profile-information-table-dropdown-cell">
            <OrgUserFormatSettingsDropdown
              formatSettingToDisplay={weekNumberFormatToDisplay}
              formatSettingsEnum={WeekNumberFormat}
              orgUserId={orgUser.id}
              orgUserProperty={{ weekNumberFormat: orgUser.weekNumberFormat }}
              orgUserPropertyString={'weekNumberFormat'}
              updateOrgUser={handleOrgUserInputChange}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing>User Since</Table.Cell>
          <Table.Cell disabled>{formatLocalDate(orgUser.createdAt)}</Table.Cell>
        </Table.Row>
        {!isGuestProfile && (
          <AccessRow
            checked={orgUser.admin}
            disabled={isCurrentUser || orgUserDisconnected}
            onChange={handleOrgAdminCheckboxValue}
            orgUserDisconnected={orgUserDisconnected}
            userId={orgUser.user.id}
            profileType={profileType}
          />
        )}
      </Table.Body>
    </Table>
  );
};

const AccessRow = ({
  checked,
  disabled,
  onChange,
  orgUserDisconnected,
  userId,
  profileType,
}: {
  checked: boolean;
  disabled: boolean;
  onChange: (_: FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => void;
  orgUserDisconnected: boolean;
  userId: number;
  profileType: 'organizationUser' | 'workspaceUser';
}) => {
  const hasGuestWorkspaceUsers = useSelector((state) => getIsOrgUserHasAnyGuestWorkspaces(state, userId));

  if (hasGuestWorkspaceUsers) {
    return null;
  }

  return (
    <Table.Row>
      <Table.Cell collapsing>Access</Table.Cell>
      {orgUserDisconnected ? (
        <Table.Cell className="disconnected">
          Disconnected from {profileType === 'organizationUser' ? 'all Workspaces' : 'this Workspace'}
        </Table.Cell>
      ) : (
        <Table.Cell>
          <span className="organization-directory__users-profile-information-table-row">
            <Checkbox
              disabled={disabled}
              checked={checked}
              className="organization-directory__users-profile-table-checkbox"
              onChange={onChange}
            />
            Org Admin
          </span>
        </Table.Cell>
      )}
    </Table.Row>
  );
};
