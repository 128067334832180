import { createContext, useContext } from 'react';

import { DateRange } from 'redux/entities/selectors/item';

interface ItemListWidgetContextProps {
  dateRange: DateRange | null;
}

export const ItemListWidgetContext = createContext<ItemListWidgetContextProps>({
  dateRange: null,
});

export function useItemListWidgetContext() {
  return useContext(ItemListWidgetContext);
}
