import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import {
  LpIcon,
  angleRightRegular as toggleClose,
  angleDownRegular as toggleOpen,
  IconDefinition,
} from 'features/common/lp_icon';

type HandleSelectionOnClick = (_: React.SyntheticEvent, { value }: DropdownItemProps) => void;

export const menuOptions = {
  divider: (key: string) => <Dropdown.Divider key={`${Date.now()}-${key}`} />,
  expandAll: (handleExpand?: () => void) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.ExpandAll}
      text={
        <span>
          <LpIcon icon={toggleOpen} className="icon" />
          {SelectionDropdownOptions.ExpandAll}
        </span>
      }
      onClick={handleExpand}
    />
  ),
  collapseAll: (handleCollapse?: () => void) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.CollapseAll}
      text={
        <span>
          <LpIcon icon={toggleClose} className="icon" />
          {SelectionDropdownOptions.CollapseAll}
        </span>
      }
      onClick={handleCollapse}
    />
  ),
  unselectAll: (unselectAllLogic: HandleSelectionOnClick, icon?: IconDefinition) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.UnselectAll}
      text={
        <span>
          {icon && <LpIcon icon={icon} size="1x" />}
          {SelectionDropdownOptions.UnselectAll}
        </span>
      }
      value={SelectionDropdownOptions.UnselectAll}
      onClick={unselectAllLogic}
    />
  ),
  selectAll: (selectAllLogic: HandleSelectionOnClick, icon?: IconDefinition) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.SelectAll}
      text={
        <span>
          {icon && <LpIcon icon={icon} size="1x" />}
          {SelectionDropdownOptions.SelectAll}
        </span>
      }
      value={SelectionDropdownOptions.SelectAll}
      onClick={selectAllLogic}
    />
  ),
  selectResources: (selectResourcesLogic: HandleSelectionOnClick) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.SelectResources}
      text={SelectionDropdownOptions.SelectResources}
      value={SelectionDropdownOptions.SelectResources}
      onClick={selectResourcesLogic}
    />
  ),
  selectPlaceholders: (selectPlaceholdersLogic: HandleSelectionOnClick) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.SelectPlaceholders}
      text={SelectionDropdownOptions.SelectPlaceholders}
      value={SelectionDropdownOptions.SelectPlaceholders}
      onClick={selectPlaceholdersLogic}
    />
  ),
  selectMembers: (selectMembersLogic: HandleSelectionOnClick, hasEditAccess?: boolean) => (
    <Dropdown.Item
      key={SelectionDropdownOptions.SelectMembers}
      text={SelectionDropdownOptions.SelectMembers}
      value={SelectionDropdownOptions.SelectMembers}
      onClick={selectMembersLogic}
      disabled={!hasEditAccess}
    />
  ),
};
