import { DropdownItemProps } from 'semantic-ui-react';

import { StoryPoints } from 'daos/model_types';

const getIsDisabledOption = (storyPoint: StoryPoints, supportedSchemeId: number) => {
  return storyPoint.archivedAt !== null || storyPoint.storyPointScheme.id !== supportedSchemeId;
};

export const buildStoryPointOptions = (storyPoints: ReadonlyArray<StoryPoints>, supportedSchemeId: number) => {
  const storyPointOptions: Array<DropdownItemProps> = [{ key: 0, text: 'None', value: 0 }];

  storyPoints.forEach((sp) => {
    const isDisabled = getIsDisabledOption(sp, supportedSchemeId);
    storyPointOptions.push({ key: sp.id, text: sp.value, value: sp.id, disabled: isDisabled });
  });

  return storyPointOptions;
};
