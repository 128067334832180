import { LpFormikDropdown } from 'features/common/forms/formik/dropdown';
import { colors } from 'features/common/inputs/dropdowns/color_picker_dropdown';
import { LpIcon, squareSolid } from 'features/common/lp_icon';

export const colorOptions = colors.map((color) => ({
  search: color.name,
  text: (
    <span>
      <LpIcon icon={squareSolid} color={`#${color.hex}`} transform="left-5" />
      {color.name}
    </span>
  ),
  value: color.hex,
}));

export const DashboardV2EditorSettingsColorDropdown = () => {
  return (
    <LpFormikDropdown
      className="v2-dashboard-settings__form-field-color"
      name="color"
      options={colorOptions}
      selection
    />
  );
};
