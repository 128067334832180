import { useState } from 'react';
import { Button } from 'semantic-ui-react';

import ConfirmDeleteModal from 'containers/shared/confirm_delete_modal';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import { timesRegular as cancel, banRegular, LpIcon } from 'features/common/lp_icon';
import { pluralizeUnitWithCountIgnoringZero } from 'lib/helpers';

import './slack_integration_administration.scss';

interface Props {
  selectedRecordIds: Set<number>;
  onFilterTextChange: (value: string) => void;
  onFilterTextClear: () => void;
  onDelete: () => void;
  onReset: () => void;
}

const DEBOUNCE_INTERVAL = 200;

const SlackIntegrationAdministrationActions = ({
  selectedRecordIds,
  onFilterTextChange,
  onFilterTextClear,
  onDelete,
  onReset,
}: Props) => {
  const [debounceTimerId, setDebounceTimerId] = useState<ReturnType<typeof setTimeout>>();
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false);
  const openConfirmationModal = () => setConfirmDeleteModalIsOpen(true);
  const closeConfirmationModal = () => setConfirmDeleteModalIsOpen(false);
  const clearDebounceTimer = () => debounceTimerId && clearInterval(debounceTimerId);
  const numberOfSelectedRecords = selectedRecordIds.size;

  const onConfirmDelete = () => {
    onDelete();
    closeConfirmationModal();
  };

  const handleFilterTextClear = () => {
    clearDebounceTimer();
    onFilterTextClear();
  };

  const handleFilterTextInput = (value: string) => {
    clearDebounceTimer();
    setDebounceTimerId(setTimeout(() => onFilterTextChange(value), DEBOUNCE_INTERVAL));
  };

  return (
    <div className="slack-integration-administration__actions">
      <LpSearchInputUncontrolled onChange={handleFilterTextInput} onClear={handleFilterTextClear} />
      {numberOfSelectedRecords > 0 && (
        <>
          <Button
            className="icon slack-integration-administration__action-button"
            primary
            onClick={openConfirmationModal}
            size="small"
            icon={<LpIcon icon={banRegular} />}
          />
          <Button
            className="icon slack-integration-administration__action-button"
            onClick={onReset}
            size="small"
            icon={<LpIcon icon={cancel} />}
          />
        </>
      )}
      {confirmDeleteModalIsOpen && (
        <ConfirmDeleteModal
          onDelete={onConfirmDelete}
          onClose={closeConfirmationModal}
          confirmButtonText="Disconnect"
          content={`You are about to disconnect ${pluralizeUnitWithCountIgnoringZero(
            'user',
            numberOfSelectedRecords
          )} from Slack. Disconnected users will stop receiving LiquidPlanner notifications in Slack and their saved connections will be deleted.`}
        />
      )}
    </div>
  );
};

export default SlackIntegrationAdministrationActions;
