import { ReactText } from 'react';
import { SortColumn } from 'react-data-grid';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import {
  connectedOnColumn,
  orgUserNameColumn,
  selectAllConnectionsColumn,
  slackWorkspaceNameColumn,
} from 'features/common/data_grid/column_definitions/slack_integration';
import { ClientSideSortableDataGrid } from 'features/common/data_grid/grids/lp_sorable_data_grid';
import { SelectionDropdownOptions } from 'features/common/inputs/dropdowns/selection_dropdown/enums';
import { SlackIntegrationDetail } from 'features/integrations/slack/slack_integration_administration/use_slack_integrations_for_org';

const connectedOnKey = StandardColumns.CreatedAt;
const HEADER_ROW_HEIGHT = 35;
const ROW_HEIGHT = 60;
const sortColumn: SortColumn = { columnKey: connectedOnKey, direction: 'DESC' };

interface Props {
  isLoading: boolean;
  selectedRows: Set<number>;
  slackIntegrations: Array<SlackIntegrationDetail>;
  onSelectedRowsChange: (selectedRows: Set<number>) => void;
}

const SlackIntegrationAdministrationTable = ({
  isLoading,
  selectedRows,
  slackIntegrations,
  onSelectedRowsChange,
}: Props) => {
  const handleSelectAll = (selectionOption: SelectionDropdownOptions) => {
    switch (selectionOption) {
      case SelectionDropdownOptions.UnselectAll:
        onSelectedRowsChange(new Set());
        break;
      case SelectionDropdownOptions.SelectAll:
        onSelectedRowsChange(new Set(slackIntegrations.map(({ id }) => id)));
        break;
    }
  };

  const columns = [
    selectAllConnectionsColumn(handleSelectAll),
    orgUserNameColumn,
    slackWorkspaceNameColumn,
    connectedOnColumn,
  ];

  const handleSelectedRowsChange = (selectedRows: Set<ReactText>) => {
    const rowIds = new Set<number>();

    for (const id of selectedRows.values()) {
      rowIds.add(Number(id));
    }

    onSelectedRowsChange(rowIds);
  };

  return (
    <ClientSideSortableDataGrid<SlackIntegrationDetail>
      columns={columns}
      headerRowHeight={HEADER_ROW_HEIGHT}
      initialSortColumn={sortColumn}
      isFetchComplete={!isLoading}
      onSelectedRowsChange={handleSelectedRowsChange}
      rowHeight={ROW_HEIGHT}
      rows={slackIntegrations}
      summaryRow={[]}
      selectedRows={selectedRows}
    />
  );
};

export default SlackIntegrationAdministrationTable;
