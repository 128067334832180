import { ReactNode } from 'react';

export const NameCustomHeader = ({
  columnName,
  selectedItemsCount,
}: {
  selectedItemsCount: number;
  columnName: ReactNode;
}) => {
  const selectedCount = selectedItemsCount !== 0 ? <>( {selectedItemsCount} Selected )</> : null;
  return (
    <span data-testid={'custom-header-span'}>
      {columnName} {selectedCount}
    </span>
  );
};
