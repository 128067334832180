import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import bugsnagClient from 'lib/bugsnag';

export function copyElementHtmlToClipboard(element: ReactElement): boolean {
  try {
    const container = document.createElement('div');
    const innerContent = document.createElement('div');
    // this is meant to run in browser as well as server
    // it will spit out proper markup without the react stuff
    innerContent.innerHTML = renderToStaticMarkup(element);

    // attach shadow will give us access to the shadowRoot property
    // shadow root does not allow outside css to 'pierce' the boundary and style our elements
    container.attachShadow({ mode: 'open' });
    container.shadowRoot?.appendChild(innerContent);

    // hide element until we go to copy it
    container.style.position = 'fixed';
    container.style.pointerEvents = 'none';
    container.style.opacity = '0';
    container.style.color = '#000';
    container.style.fontFamily = "'sans-serif'";
    container.style.fontSize = '14px';

    // mount the container to the DOM to make `contentWindow` available
    document.body.appendChild(container);

    // select text that will be copied
    window.getSelection()?.removeAllRanges();
    const range = document.createRange();
    range.selectNode(innerContent);
    window.getSelection()?.addRange(range);

    // remove container styles before copying
    container.style.position = '';
    container.style.pointerEvents = '';
    container.style.opacity = '';

    // runs a copy on the selection we made
    document.execCommand('copy');
    document.body.removeChild(container);
    return true;
  } catch (e) {
    if (e instanceof Error) {
      bugsnagClient?.notify(e.toString());
    }
    return false;
  }
}
