import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';

import { OrganizationLinkCrumb } from 'features/common/breadcrumbs/breadcrumb_helpers';
import LpBreadcrumb from 'features/common/breadcrumbs/lp_breadcrumb';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { keySolid } from 'features/common/lp_icon';
import { TopNavHeader } from 'features/organization/top_nav/top_nav_header';
import { NavigationMenuDisplay } from 'features/workspace/navigation_menu_display';
import { frontend } from 'lib/urls';

export const SingleSignOnHeader = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const pathname = useLocation().pathname;

  const tabs = [
    {
      content: NavigationMenuDisplay.SetupSso,
      to: frontend.singleSignOnSetup.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.singleSignOnSetup.pattern],
      }),
    },
    {
      content: NavigationMenuDisplay.ManageSso,
      to: frontend.singleSignOnManage.url({ organizationId }),
      active: !!matchPath(pathname, {
        path: [frontend.singleSignOnManage.pattern],
      }),
    },
  ];

  const sections = [
    {
      key: 'organization-name',
      content: <OrganizationLinkCrumb icon={keySolid} />,
    },
    {
      key: NavigationMenuDisplay.SingleSignOn,
      content: NavigationMenuDisplay.SingleSignOn,
    },
  ];

  return <TopNavHeader breadcrumb={<LpBreadcrumb sections={sections} />} menuTabs={tabs} />;
};
