import { useSelector } from 'react-redux';

import { ItemType, ListWidgetType, PackageStatus } from 'daos/enums';
import { ItemDao } from 'daos/item';
import { Widget } from 'daos/model_types';
import { AsyncDownloadButton } from 'features/common/buttons/async_download_button';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { DataGridViewType } from 'features/common/data_grid/enums';
import { getWidgetExportUrl } from 'features/common/data_grid/helpers';
import { getDataGridSelectedColumnsForPackageStatus } from 'features/common/data_grid/selectors';
import { useColumnsAndActiveFieldsByItemType } from 'features/common/item_column/use_item_column_selection_list';
import { LpIcon, cloudDownloadSolid } from 'features/common/lp_icon';
import { getItemTypeForListWidgetType } from 'features/dashboards/widget_click_through/grids/helpers';

interface GridDownloadXlsProps {
  dataGridItemType: ItemType;
  filter: string | undefined;
  packageStatus: PackageStatus | null | undefined;
  dataGridViewType: DataGridViewType;
}

const GridDownloadXLSRenderer = ({ gridExportLink }: { gridExportLink: string }) => {
  return (
    <AsyncDownloadButton
      content={
        <>
          <LpIcon icon={cloudDownloadSolid} /> Download
        </>
      }
      url={gridExportLink}
    />
  );
};

export const ItemGridDownloadXLS = ({
  dataGridItemType,
  filter,
  packageStatus,
  dataGridViewType,
}: GridDownloadXlsProps) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const allColumns = useColumnsAndActiveFieldsByItemType({ forPPPGrid: false })[dataGridItemType];
  const selectedColumns = useSelector((state) =>
    getDataGridSelectedColumnsForPackageStatus(state, packageStatus, allColumns, dataGridViewType)
  );

  const columnHeaders = selectedColumns.map((column) => column.id).join(',');

  const itemNameHeader = getItemNameHeaderForItemType(dataGridItemType);

  const gridExportLink = ItemDao.itemExportXlsxURL(
    { organizationId, workspaceId },
    { filter, query: { columnHeaders, itemNameHeader } }
  );

  return <GridDownloadXLSRenderer gridExportLink={gridExportLink} />;
};

export const WidgetGridDownloadXLS = ({
  columnKeys,
  filter,
  widget,
}: {
  columnKeys: ReadonlyArray<string | number>;
  filter: string | undefined;
  widget: Widget;
}) => {
  const widgetListType = widget.config.listType;

  if (widgetListType === ListWidgetType.Users) {
    return processUserGridDownload(widget, columnKeys, widgetListType, filter);
  }

  return processItemDownload(widget, columnKeys, widgetListType, filter);
};

function processItemDownload(
  widget: Widget,
  columnKeys: ReadonlyArray<string | number>,
  widgetListType: ListWidgetType | null,
  filter: string | undefined
) {
  const itemType = getItemTypeForListWidgetType(widgetListType);

  if (!itemType) {
    return null;
  }

  const { id: widgetId, organizationId, workspaceId, dashboardId } = widget;
  const itemNameHeader = getItemNameHeaderForItemType(itemType);
  const columnHeaders = getColumnHeaders(columnKeys);

  const gridExportLink = getWidgetExportUrl(widget.widgetType, widgetListType)(
    { organizationId, widgetId, workspaceId, dashboardId },
    { filter, query: { columnHeaders, itemNameHeader } }
  );

  return <GridDownloadXLSRenderer gridExportLink={gridExportLink} />;
}

function processUserGridDownload(
  widget: Widget,
  columnKeys: ReadonlyArray<string | number>,
  widgetListType: ListWidgetType | null,
  filter: string | undefined
) {
  const { id: widgetId, organizationId, workspaceId, dashboardId } = widget;
  const headerName = 'Person';
  const columnHeaders = getColumnHeaders(columnKeys);

  const gridExportLink = getWidgetExportUrl(widget.widgetType, widgetListType)(
    { organizationId, widgetId, workspaceId, dashboardId },
    { filter, query: { columnHeaders, itemNameHeader: headerName } }
  );

  return <GridDownloadXLSRenderer gridExportLink={gridExportLink} />;
}

function getItemNameHeaderForItemType(itemType: ItemType) {
  switch (itemType) {
    case ItemType.ASSIGNMENTS:
      return 'Assignment';
    case ItemType.PROJECTS:
      return 'Project';
    case ItemType.TASKS:
      return 'Task';
    case ItemType.FOLDERS:
      return 'Sub-Folder';
    case ItemType.PACKAGES:
      return 'Package';
    default:
      return 'Item';
  }
}

function getColumnHeaders(columnKeys: ReadonlyArray<string | number>) {
  return columnKeys.join(',');
}
