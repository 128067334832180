import { LpSvgProps } from 'features/common/lp_svg/types';
import { trueBlack } from 'style/variables';

export const LpWavySvg = ({ color = trueBlack }: LpSvgProps) => (
  <svg viewBox="0 0 163 171">
    <g>
      <path
        d="M4.87021 140.677C-1.52308 146.489 -1.52308 156.369 4.28901 162.763C10.1011 169.156 19.9816 169.156 26.3749 163.344C31.0246 158.694 47.8796 145.907 72.2903 157.532C110.65 176.712 133.898 176.13 157.728 155.788C164.121 149.976 165.284 140.677 159.472 133.702C153.659 127.309 144.36 126.146 137.386 131.958C128.086 139.514 120.531 146.489 86.2393 129.052C52.5293 113.36 22.3065 124.403 4.87021 140.677Z"
        fill={color}
      />
      <path
        d="M4.87021 79.6498C-1.52308 85.4619 -1.52308 95.3424 4.28901 101.736C10.1011 108.129 19.9816 108.129 26.3749 102.317C31.0246 97.6673 47.8796 84.8807 72.2903 96.5049C110.65 115.685 133.898 115.104 157.728 94.7612C164.121 88.9492 165.284 79.6498 159.472 72.6753C153.659 66.282 144.36 65.1196 137.386 70.9317C128.086 79.0686 120.531 85.4619 85.6581 68.6069C52.5293 51.7518 22.3065 63.376 4.87021 79.6498Z"
        fill={color}
      />
      <path
        d="M4.87021 19.204C-1.52308 25.016 -1.52308 34.8966 4.28901 41.2899C10.1011 47.6831 19.9816 47.6831 26.3749 41.8711C31.0246 37.2214 47.8796 24.4348 72.2903 36.059C110.65 55.2389 133.898 54.6576 157.728 34.3154C164.121 28.5033 165.284 19.204 159.472 12.2295C153.659 5.83617 144.36 4.67375 137.386 10.4858C128.086 18.0415 120.531 25.016 86.2393 7.57979C52.5293 -8.11282 22.3065 2.93013 4.87021 19.204Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath>
        <rect width="163" height="171" />
      </clipPath>
    </defs>
  </svg>
);
