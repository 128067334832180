import { defineModel, reduceIncludedOptions } from 'daos/shared';
import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

interface FilesForItemParams {
  organizationId: number;
  workspaceId: number;
  itemId: number;
}

interface MoveFilesBody {
  fileId: number;
  workspaceId: number;
  sourceItemId: number;
  destinationItemId: number;
}
interface DeleteFilesBody {
  fileId: number;
  workspaceId: number;
  itemId: number;
}

const { resource } = defineModel({
  apiType: 'files',
  type: 'FILE',
});

const { FILE } = resource;

interface Includes {
  includeItemFiles?: boolean;
}

const includes = ({ includeItemFiles }: Includes = {}) => reduceIncludedOptions([includeItemFiles && 'itemFiles']);

interface FetchFilesForItemOptions {
  filter?: string;
  query?: { order?: ReadonlyArray<string> };
  include?: Includes;
}

const fetchFilesForItem = (params: FilesForItemParams, { filter, query, include }: FetchFilesForItemOptions = {}) => {
  return request(backend.itemFiles.url(params, { filter, query, include: includes(include) }), FILE, {
    method: HttpMethod.GET,
  });
};

const moveFiles = (params: FilesForItemParams, body: Array<MoveFilesBody>) => {
  return request(backend.itemFilesMove.url(params), FILE, {
    body: JSON.stringify({ data: body }),
    method: HttpMethod.POST,
  });
};

const deleteItemFiles = (params: FilesForItemParams, body: Array<DeleteFilesBody>) => {
  return request(backend.itemFilesDelete.url(params), FILE, {
    body: JSON.stringify({ data: body }),
    method: HttpMethod.DELETE,
  });
};

export const FileDao = {
  fetchFilesForItem,
  moveFiles,
  deleteItemFiles,
};
