import { LocationFilterAssigneeSettings } from 'daos/types';
import { AssignedFilterDropdown } from 'features/common/inputs/dropdowns/assigned_filter_dropdown';
import { useMapOrgUsersAndGroupsToDropdownOptions } from 'features/common/inputs/dropdowns/hooks/use_map_org_users_and_groups_to_options';

export const AssignedFilter = ({
  className,
  includeUnassigned,
  includeDisconnected,
  onChange,
  value,
}: {
  className?: string;
  includeUnassigned: boolean;
  includeDisconnected: boolean;
  onChange: (values: LocationFilterAssigneeSettings) => void;
  value: LocationFilterAssigneeSettings;
}) => {
  const options = useMapOrgUsersAndGroupsToDropdownOptions({ includeUnassigned, includeDisconnected });

  return <AssignedFilterDropdown className={className} onChange={onChange} options={options} value={value} />;
};
