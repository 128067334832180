import classnames from 'classnames';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import ItemIcon, { PackageStatusIcon } from 'containers/shared/item_icon';
import { ItemType } from 'daos/enums';
import { packageStatusDisplayNameByPackageStatus } from 'daos/pkg';
import { downRightSolid, LpIcon } from 'features/common/lp_icon';
import { LocationScope } from 'features/dashboards_v2/item_filter/types';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';

import './inherited_location.scss';

export const InheritedLocation = ({
  classname,
  name,
  locationScope,
  isFiltered,
  dateRange,
}: {
  name: string;
  classname: string;
  locationScope?: LocationScope;
  isFiltered: boolean;
  dateRange: string | null;
}) => {
  const workspace = useSelector(getCurrentWorkspace);
  const item = locationScope?.locationFilterItem;
  const packageStatus = locationScope?.locationFilterPackageStatus;

  let locationScopeContent: ReactNode = '(not set)';

  if (packageStatus && (!item || item.itemType === ItemType.WORKSPACE_ROOTS)) {
    locationScopeContent = (
      <>
        <PackageStatusIcon className="inherited-location__item-icon" packageStatus={packageStatus} />
        {packageStatusDisplayNameByPackageStatus[packageStatus]}
      </>
    );
  } else if (item) {
    locationScopeContent = (
      <>
        <ItemIcon className="inherited-location__item-icon" itemId={item.id} />
        {item.itemType === ItemType.WORKSPACE_ROOTS ? workspace?.name : item.name}
      </>
    );
  }

  return (
    <div className={classnames(classname, 'inherited-location')}>
      <LpIcon className="inherited-location__icon" icon={downRightSolid} /> {`${name} `}
      <div className="inherited-location__item">
        {locationScopeContent} {isFiltered && <>+ Filters</>}
      </div>
      {dateRange && <div className="inherited-location__item">Filter Dates: {dateRange}</div>}
    </div>
  );
};
