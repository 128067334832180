import { useSignal } from '@preact/signals-react';
import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { UserWorkspaceSettingFieldName } from 'daos/enums';
import { UserWorkspaceSettingsDao } from 'daos/user_workspace_settings';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import IncludeDoneButton from 'features/common/inputs/include_done_button';
import LpSearchInputUncontrolled from 'features/common/inputs/lp_search_input/lp_search_input_uncontrolled';
import { searchLight } from 'features/common/lp_icon';
import { useSearchPageContext } from 'features/common/search/context';
import { awaitRequestFinish } from 'lib/api';
import { HORIZONTAL_ELLIPSIS } from 'lib/constants';
import { getCurrentWorkspaceUserSettings } from 'redux/entities/selectors/user_workspace_settings';

interface SearchHeaderProps {
  setShowHelpInfo: Dispatch<SetStateAction<boolean>>;
}
export function SearchHeader({ setShowHelpInfo }: SearchHeaderProps) {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const currentWorkspaceUserSettings = useSelector(getCurrentWorkspaceUserSettings);
  const searchIncludeDone = currentWorkspaceUserSettings?.searchIncludeDone ?? true;
  const [hasSearchText, setHasSearchText] = useState(false);
  const { searchEvent } = useSearchPageContext();

  const searchTerm = useSignal('');

  const handleChange = (value: string) => {
    searchTerm.value = value;
    setHasSearchText(!!value?.trim()?.length);
  };

  const handleSearch = useCallback(
    (includeDone: boolean) => {
      setShowHelpInfo(false);
      searchEvent.value = {
        includeDone,
        searchTerm: searchTerm.value,
      };

      if (!hasSearchText) {
        setShowHelpInfo(true);
        searchEvent.value = null;
      }
    },
    [hasSearchText, searchEvent, searchTerm.value, setShowHelpInfo]
  );

  const handleSearchClear = () => {
    setShowHelpInfo(true);
    searchEvent.value = null;
  };

  const handleIncludeDoneClick = () => {
    searchEvent.value = {
      includeDone: !searchIncludeDone,
      searchTerm: searchTerm.value,
    };

    const { uuid } = dispatch(
      UserWorkspaceSettingsDao.update(
        {
          organizationId,
          workspaceId,
          settingFieldName: UserWorkspaceSettingFieldName.SearchIncludeDone,
        },
        !searchIncludeDone
      )
    );

    dispatch(awaitRequestFinish(uuid, {}));
  };

  const handleSearchSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    handleSearch(searchIncludeDone);
  };

  return (
    <form onSubmit={handleSearchSubmit} className="lp-search__top-controls">
      <LpSearchInputUncontrolled
        autoFocus={true}
        icon={searchLight}
        onChange={handleChange}
        placeholder={`Find this${HORIZONTAL_ELLIPSIS}`}
        onClear={handleSearchClear}
        className="lp-search__search-input"
      />

      <Button content="GO" primary />

      <IncludeDoneButton
        isChecked={searchIncludeDone}
        handleClick={handleIncludeDoneClick}
        className="lp-search__top-controls-include-done"
      />
    </form>
  );
}
