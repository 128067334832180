interface Props {
  page_title: string;
  page_location: string;
  ws_id: number | undefined;
  org_id: number | undefined;
  user_id: number | undefined;
}

export function startView(props: Props) {
  if (window.DD_RUM && window.datadogApplicationId) {
    if (props.user_id && window.DD_RUM.setUser) {
      window.DD_RUM?.setUser({ id: props.user_id?.toString() });
    }

    if (window.DD_RUM.setGlobalContext) {
      window.DD_RUM?.setGlobalContext(props);
    }

    if (window.DD_RUM.startView) {
      window.DD_RUM?.startView(props.page_location);
    }
  }
}
