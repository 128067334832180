import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { LpButtonAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { ballPileSolid, barsStaggeredSolid, LpIcon, squareKanbanSolid, tableListSolid } from 'features/common/lp_icon';
import { UrlBuilder } from 'lib/url_builder';
import { frontend } from 'lib/urls';
import { getItemForId } from 'redux/entities/selectors/item';

type PackageOrProject = ItemType.PACKAGES | ItemType.PROJECTS;

type UrlBuilderByItemTypeDictionary = {
  [key in PackageOrProject]: UrlBuilder<any>;
};

const workloadUrlBuildersByItemType: UrlBuilderByItemTypeDictionary = {
  [ItemType.PACKAGES]: frontend.packageWorkload,
  [ItemType.PROJECTS]: frontend.projectWorkload,
};

const scheduleUrlBuildersByItemType: UrlBuilderByItemTypeDictionary = {
  [ItemType.PACKAGES]: frontend.package,
  [ItemType.PROJECTS]: frontend.project,
};

const boardUrlBuildersByItemType: UrlBuilderByItemTypeDictionary = {
  [ItemType.PACKAGES]: frontend.packageTaskBoard,
  [ItemType.PROJECTS]: frontend.projectBoard,
};

const gridUrlBuildersByItemType: UrlBuilderByItemTypeDictionary = {
  [ItemType.PACKAGES]: frontend.packageGrid,
  [ItemType.PROJECTS]: frontend.projectGrid,
};

const makeUrl = ({
  itemId,
  itemType,
  organizationId,
  urlBuilderByItemType,
  workspaceId,
}: {
  urlBuilderByItemType: UrlBuilderByItemTypeDictionary;
  itemType: ItemType;
  itemId: number;
  organizationId: number;
  workspaceId: number;
}) => {
  switch (itemType) {
    case ItemType.PACKAGES:
      return urlBuilderByItemType[ItemType.PACKAGES]?.url({ organizationId, workspaceId, packageId: itemId });
    case ItemType.PROJECTS:
    case ItemType.FOLDERS:
      return urlBuilderByItemType[ItemType.PROJECTS]?.url({ organizationId, workspaceId, itemId: itemId });
    default:
      return frontend.workspace.url({ organizationId, workspaceId });
  }
};

export const Navigation = ({ itemId }: { itemId: number }) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  if (!item) {
    return null;
  }

  const navigationButtons = [
    {
      to: makeUrl({
        itemId: item.id,
        itemType: item.itemType,
        organizationId,
        urlBuilderByItemType: scheduleUrlBuildersByItemType,
        workspaceId,
      }),
      icon: barsStaggeredSolid,
      name: 'Schedule',
    },
    {
      to: makeUrl({
        urlBuilderByItemType: workloadUrlBuildersByItemType,
        itemType: item.itemType,
        organizationId,
        workspaceId,
        itemId: item.id,
      }),
      icon: ballPileSolid,
      name: 'Workload',
    },
    {
      to: makeUrl({
        urlBuilderByItemType: boardUrlBuildersByItemType,
        itemType: item.itemType,
        organizationId,
        workspaceId,
        itemId: item.id,
      }),
      icon: squareKanbanSolid,
      name: 'Board',
    },
    {
      to: makeUrl({
        urlBuilderByItemType: gridUrlBuildersByItemType,
        itemType: item.itemType,
        organizationId,
        workspaceId,
        itemId: item.id,
      }),
      icon: tableListSolid,
      name: 'Grid',
    },
  ];

  return (
    <div className="insights-widget__navigation">
      {navigationButtons.map((button) => {
        return (
          <div key={button.name}>
            <LpButtonAsLink className="insights-widget__navigation-button" to={button.to}>
              <div className="insights-widget__navigation-button-content">
                <div>
                  <LpIcon icon={button.icon} />
                </div>
                <div>{button.name}</div>
              </div>
            </LpButtonAsLink>
          </div>
        );
      })}
    </div>
  );
};
