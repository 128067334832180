import classNames from 'classnames';
import { Button } from 'semantic-ui-react';

import { IconDefinition, LpIcon, timesCircleLight } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';

export const renderDeleteIcon = ({
  enabled = true,
  onClick,
  className,
  icon = timesCircleLight,
  hoverText = 'Clear value',
}: {
  enabled?: boolean;
  onClick?: () => void;
  className?: string;
  icon?: IconDefinition;
  hoverText?: string;
}) => (
  <Button
    type="button"
    className={classNames('delete-button icon', className)}
    onClick={
      enabled
        ? (e) => {
            e.stopPropagation();
            onClick?.();
          }
        : undefined
    }
    disabled={!enabled}
  >
    <LpPopUp
      content={hoverText}
      placement="top"
      trigger={<LpIcon className={!enabled ? 'disabled' : ''} icon={icon} />}
    />
  </Button>
);
