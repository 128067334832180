import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { ItemType } from 'daos/enums';
import { createCustomFieldValuesPayload } from 'features/common/data_grid/add_edit_grid/custom_field_helpers';
import { getWorkLimitPayload } from 'features/common/data_grid/add_edit_grid/helpers/payload_helpers';
import { allRowsAreValid } from 'features/common/data_grid/add_edit_grid/modal_edit_grid/edit_item_grids/helpers';
import {
  useProjectAndFolderEditColumnDefinitions,
  useProjectAndFolderToEditGridRows,
} from 'features/common/data_grid/add_edit_grid/modal_edit_grid/hooks/use_project_edit_data_grid';
import LpEditGridModal from 'features/common/data_grid/add_edit_grid/modal_edit_grid/lp_edit_grid_modal';
import {
  MultiFieldEditorProps,
  MultiFieldValueModal,
} from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { AddEditGridRow } from 'features/common/data_grid/types';
import { itemTypeToPluralDisplayName, itemTypeToSingularDisplay } from 'lib/display_helpers/item_display_name';
import { getEditGridCustomFieldValuesByItemId, getFieldsById } from 'redux/entities/selectors/custom_field';
import { getItemsForIdsSortedByGlobalPriority } from 'redux/entities/selectors/item';

interface EditProjectAndFolderGridModalProps {
  itemType: ItemType.PROJECTS | ItemType.FOLDERS;
  fetchProjects: () => void;
  isTemplateGrid: boolean;
  selectedProjectIds: ReadonlyArray<number>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProjectAndFolderGridModal = ({
  itemType,
  fetchProjects,
  isTemplateGrid,
  setOpen,
  selectedProjectIds,
}: EditProjectAndFolderGridModalProps) => {
  const projectAndFolders = useSelector((state) => getItemsForIdsSortedByGlobalPriority(state, selectedProjectIds));
  const customFieldsKeyedById = useSelector(getFieldsById);
  const fieldValuesByItemId = useSelector(getEditGridCustomFieldValuesByItemId);

  const [multiFieldEditorProps, setMultiFieldEditorProps] = useState<MultiFieldEditorProps | undefined>();
  const closeMultiEditModal = () => setMultiFieldEditorProps(undefined);

  const columns = useProjectAndFolderEditColumnDefinitions(isTemplateGrid, setMultiFieldEditorProps, itemType);
  const rows = useProjectAndFolderToEditGridRows(projectAndFolders);

  const createPayload = useCallback(
    (rows: Array<AddEditGridRow>) => {
      const payload = rows.map((row) => {
        const initialRowFieldValues = fieldValuesByItemId[row.id] ?? [];
        const workLimit = getWorkLimitPayload(row.workLimit);
        const fieldValues = createCustomFieldValuesPayload({ row, customFieldsKeyedById, initialRowFieldValues });
        return {
          id: row.id,
          name: row.name,
          color: row.color,
          folderStatus: row.folderStatus,
          description: row.description,
          billingRateSheetId: row.billingRateSheet,
          payRateSheetId: row.payRateSheet,
          targetFinish: row.targetFinish,
          targetStart: row.targetStart,
          targetFinishType: row.targetFinishType,
          workLimit,
          fieldValues,
        };
      });
      return payload;
    },
    [customFieldsKeyedById, fieldValuesByItemId]
  );

  return (
    <>
      <LpEditGridModal
        columns={columns}
        createPayload={createPayload}
        fetchItems={fetchProjects}
        helpText={<strong>{`Bulk edit up to 50 ${itemTypeToPluralDisplayName[itemType]} at a time`}</strong>}
        initialRows={rows}
        itemTypeDisplay={itemTypeToSingularDisplay[itemType]}
        setOpen={setOpen}
        validateRows={allRowsAreValid}
      />

      {multiFieldEditorProps && <MultiFieldValueModal {...multiFieldEditorProps} onClose={closeMultiEditModal} />}
    </>
  );
};

export default EditProjectAndFolderGridModal;
