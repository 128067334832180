import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import ItemName from 'containers/shared/custom_column/item_name';
import { isMultipleValueField } from 'daos/custom_field';
import { CustomFieldType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { MultiFieldEditorProps } from 'features/common/data_grid/add_edit_grid/multi_field_value_modal';
import { FormatterAddEditGridProps } from 'features/common/data_grid/types';
import { toggleOffRegular, toggleOnRegular, LpIcon, squareSolid } from 'features/common/lp_icon';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getPicklistChoiceForId, getPicklistChoicesById } from 'redux/entities/selectors/custom_field';
import {
  getCurrentOrganizationUser,
  getCurrentOrganizationUserForWorkspaceUser,
  getCurrentWsUserToOrgUserMapping,
  getOrganizationUsersById,
  getWorkspaceUserById,
} from 'redux/entities/selectors/user';
import { gray300, gray600, green600 } from 'style/variables';

interface CustomFieldEditGridFormatterProps {
  customField: CustomField;
  props: FormatterAddEditGridProps;
}
const SingleCustomFieldEditGridFormatter = ({ customField, props }: CustomFieldEditGridFormatterProps) => {
  const fieldValue = props.row[customField.id]?.[0];
  const { formatLocalDate, formatCurrency } = useLocalizedFormats();
  const customFieldValueWorkspaceUser = useSelector((state) =>
    fieldValue?.workspaceUserValue ? getWorkspaceUserById(state, fieldValue.workspaceUserValue.id) : undefined
  );
  const customFieldValueOrgUser = useSelector((state) =>
    getCurrentOrganizationUserForWorkspaceUser(state, customFieldValueWorkspaceUser)
  );
  const orgUser = useSelector(getCurrentOrganizationUser);

  const picklistChoice = useSelector((state) =>
    fieldValue?.picklistChoice ? getPicklistChoiceForId(state, fieldValue.picklistChoice?.id) : undefined
  );

  switch (customField.fieldType) {
    case CustomFieldType.CURRENCY:
      return (
        <span>
          {fieldValue?.currency &&
            customField.currencyUnit &&
            orgUser &&
            formatCurrency(fieldValue.currency, customField.currencyUnit, orgUser.numberFormat)}
        </span>
      );
    case CustomFieldType.DATE:
      return <span>{fieldValue?.date && formatLocalDate(fieldValue.date)}</span>;
    case CustomFieldType.MULTILINE_TEXT:
    case CustomFieldType.TEXT:
      return <span>{fieldValue?.text}</span>;
    case CustomFieldType.ITEM:
      return <span>{fieldValue?.itemValue && <ItemName itemId={fieldValue.itemValue.id} />}</span>;
    case CustomFieldType.LINK:
      return <span>{fieldValue?.text ? fieldValue.text : fieldValue?.url}</span>;
    case CustomFieldType.NUMERIC:
      return <span>{fieldValue?.number}</span>;
    case CustomFieldType.CHECKBOX:
      return (
        <LpIcon
          className="lpicon"
          color={fieldValue?.checked ? green600 : gray600}
          icon={fieldValue?.checked ? toggleOnRegular : toggleOffRegular}
          size="lg"
        />
      );
    case CustomFieldType.PICKLIST:
      return (
        <span>
          {picklistChoice && (
            <>
              <LpIcon
                className="custom_field_formatter__picklist-icon"
                icon={squareSolid}
                size="1x"
                color={picklistChoice.color ? `#${picklistChoice.color}` : gray300}
              />{' '}
              {picklistChoice.name}
            </>
          )}
        </span>
      );
    case CustomFieldType.USER:
      return customFieldValueOrgUser ? (
        <span>
          <UserAvatar size={AvatarSize.ExtraSmall_1_2} orgUserId={customFieldValueOrgUser.id} />
          {customFieldValueOrgUser.username}
        </span>
      ) : (
        <span className="custom_field_formatter__empty">Select User</span>
      );
    default:
      return null;
  }
};

const MultiCustomFieldEditGridFormatter = ({
  customField,
  props,
  setMultiEditModalProps,
}: CustomFieldEditGridFormatterProps & {
  setMultiEditModalProps: Dispatch<SetStateAction<MultiFieldEditorProps | undefined>>;
}) => {
  const fieldValues = props.row[customField.id] ?? [];
  const fieldType = customField.fieldType;

  const picklistChoicesById = useSelector(getPicklistChoicesById);
  const orgUserIdsByWsUserId = useSelector(getCurrentWsUserToOrgUserMapping);
  const orgUsersById = useSelector(getOrganizationUsersById);

  const handleClick = () => {
    setMultiEditModalProps({ customField, rowProps: props });
  };

  switch (fieldType) {
    case CustomFieldType.MULTI_PICKLIST: {
      const values = fieldValues.map((fieldValue, index) => {
        const picklistChoiceId = fieldValue.picklistChoice?.id ?? 0;
        const picklistChoice = picklistChoicesById[picklistChoiceId];

        if (!picklistChoice) {
          return null;
        }

        return (
          <span key={`${fieldValue.id}-${picklistChoiceId}`}>
            {index > 0 && ', '}
            <span className="nowrap">
              <LpIcon
                icon={squareSolid}
                size="1x"
                color={picklistChoice.color ? `#${picklistChoice.color}` : gray300}
              />{' '}
              {picklistChoice.name}
            </span>
          </span>
        );
      });

      return (
        <div
          className={classNames('multi-value-field', !values.length && 'multi-value-field--empty')}
          onClick={handleClick}
        >
          {values}
        </div>
      );
    }
    case CustomFieldType.MULTI_USER: {
      const values = fieldValues.map((fieldValue, index) => {
        const wsUserId = fieldValue.workspaceUserValue?.id ?? 0;
        const orgUserId = orgUserIdsByWsUserId[wsUserId] ?? 0;
        const orgUser = orgUsersById[orgUserId];

        if (!orgUser) {
          return null;
        }

        return (
          <span key={`${fieldValue.id}-${wsUserId}`}>
            {index > 0 && ', '}
            <span className="nowrap">
              <UserAvatar size={AvatarSize.ExtraSmall_1_2} orgUserId={orgUser.id} />
              {orgUser.username}
            </span>
          </span>
        );
      });

      return (
        <div
          className={classNames('multi-value-field', !values.length && 'multi-value-field--empty')}
          onClick={handleClick}
        >
          {values}
        </div>
      );
    }
    default:
      return null;
  }
};

export const CustomFieldEditGridFormatter = (
  customField: CustomField,
  props: FormatterAddEditGridProps,
  setMultiEditModalProps: Dispatch<SetStateAction<MultiFieldEditorProps | undefined>>
) => {
  if (isMultipleValueField(customField)) {
    return (
      <MultiCustomFieldEditGridFormatter
        customField={customField}
        props={props}
        setMultiEditModalProps={setMultiEditModalProps}
      />
    );
  }

  return <SingleCustomFieldEditGridFormatter customField={customField} props={props} />;
};
