import { Button } from 'semantic-ui-react';

import { cloudCheckSolid, cloudExclamationSolid, IconDefinition, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { blue500, red500 } from 'style/variables';

import './information_modals.scss';

interface BaseModal {
  onClose: () => void;
  message: string;
}

export function ConfirmationModal({ onClose, message }: BaseModal) {
  return (
    <InformationMessageModal
      headerText="Success!"
      message={message}
      onClose={onClose}
      iconColor={blue500}
      icon={cloudCheckSolid}
      buttonText="ok"
    />
  );
}

export function ErrorModal({ onClose, message }: BaseModal) {
  return (
    <InformationMessageModal
      headerText="Unsuccessful"
      message={message}
      onClose={onClose}
      iconColor={red500}
      icon={cloudExclamationSolid}
      buttonText="Close"
    />
  );
}

function InformationMessageModal({
  onClose,
  headerText,
  message,
  icon,
  iconColor,
  buttonText,
}: {
  onClose: () => void;
  headerText: string;
  message: string;
  icon: IconDefinition;
  iconColor: string;
  buttonText: string;
}) {
  return (
    <LpModal
      className="intake__information_message_modal"
      onClose={onClose}
      size="mini"
      header={headerText}
      content={
        <div className="intake__information_message_modal-message">
          <LpIcon
            className="intake__information_message_modal-message-icon"
            icon={icon}
            size={'4x'}
            color={iconColor}
          />
          {message}
        </div>
      }
      actions={<Button content={buttonText} primary onClick={onClose} />}
    />
  );
}
