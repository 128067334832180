export enum FolderFilterStatus {
  All = 'all',
  ActiveOnHold = 'activeOnHold',
  Done = 'done',
  OnHold = 'onHold',
  Scheduled = 'scheduled',
  atRisk = 'atRisk',
  Custom = 'custom',
}

export enum FolderFilterStatusDisplay {
  All = 'All Projects',
  Scheduled = 'Active Projects',
  ActiveOnHold = 'Active + On Hold',
  OnHold = 'On Hold Projects',
  Done = 'Done Projects',
  atRisk = 'Schedule Risk',
  Custom = 'Custom Filter',
}
