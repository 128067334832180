import {
  bullseyePointerSolid,
  businessTimeSolid,
  calculatorSolid,
  flagCheckeredSolid,
  mapLocationDotSolid,
  sensorCloudSolid,
  sensorFireSolid,
  userClockSolid,
} from 'features/common/lp_icon';
import {
  formatDeltaWithName,
  formatRange,
  formatSecondsAsHours,
  getProperty,
  isValueInsightAtRisk,
  isWidgetDataPropertyNullOrZero,
  isWidgetDataRangeZero,
  renderDeltaInDaysWithName,
} from 'features/dashboards_v2/widget/widgets/insights/helpers';
import { DateProperties, InsightData, InsightsWidgetData } from 'features/dashboards_v2/widget/widgets/insights/types';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';
import { getDeltaDays } from 'lib/deltas';

export const useAllInsightsInOrder = (widgetData: InsightsWidgetData) => {
  const { formatLocalDate } = useLocalizedFormats();
  const getPropertyFromWidgetData = getProperty(widgetData);
  const isValueInsightDisabled = isWidgetDataPropertyNullOrZero(widgetData);
  const isRangeInsightDisabled = isWidgetDataRangeZero(widgetData);

  const formatDate = (propertyName: DateProperties) => {
    const date = getPropertyFromWidgetData(propertyName);

    if (date === null) {
      return 'N/A';
    }
    return formatLocalDate(date as string);
  };

  const expectedFinishDeltaInDays = getDeltaDays({
    expected: getPropertyFromWidgetData('expectedFinish') as string,
    target: getPropertyFromWidgetData('targetFinish') as string,
  });

  const totalHoursHasRisk = isValueInsightAtRisk(widgetData.workLimitDelta);
  const totalHoursDisplayName = widgetData.workLimit
    ? formatDeltaWithName('Total Hours', widgetData.workLimitDelta ?? 0)
    : 'Total Hours';

  const totalHours: InsightData = {
    displayName: totalHoursDisplayName,
    icon: calculatorSolid,
    value: formatRange(widgetData.totalWorkRange),
    disabled: !totalHoursHasRisk && isRangeInsightDisabled('totalWorkRange'),
    hasRisk: totalHoursHasRisk,
  };

  const logged: InsightData = {
    displayName: 'Logged Hours',
    icon: businessTimeSolid,
    value: formatSecondsAsHours(widgetData.logged ?? 0),
    disabled: isValueInsightDisabled('logged'),
    hasRisk: false,
  };
  const remainingHours = {
    displayName: 'Remaining Hours',
    icon: mapLocationDotSolid,
    value: formatRange(widgetData.remainingWorkRange),
    disabled: isRangeInsightDisabled('remainingWorkRange'),
    hasRisk: false,
  };
  const uncertainHours: InsightData = {
    displayName: 'Uncertain Hours',
    icon: sensorCloudSolid,
    value: formatSecondsAsHours(widgetData.uncertainWork ?? 0),
    disabled: isValueInsightDisabled('uncertainWork'),
    hasRisk: false,
  };

  const unusedHours: InsightData = {
    displayName: 'Unused Hours',
    icon: userClockSolid,
    value: formatSecondsAsHours(widgetData.unusedEffortExpected ?? 0),
    disabled: isValueInsightDisabled('unusedEffortExpected'),
    hasRisk: false,
  };

  const clippedHoursHasRisk = isValueInsightAtRisk(widgetData.clippedEffort);
  const clippedHours: InsightData = {
    displayName: 'Clipped Hours',
    icon: sensorFireSolid,
    value: formatSecondsAsHours(widgetData.clippedEffort ?? 0),
    disabled: !clippedHoursHasRisk && isValueInsightDisabled('clippedEffort'),
    hasRisk: clippedHoursHasRisk,
  };

  const targetFinish: InsightData = {
    displayName: 'Target Finish',
    icon: bullseyePointerSolid,
    value: formatDate('targetFinish'),
    disabled: isValueInsightDisabled('targetFinish'),
    hasRisk: false,
  };

  const expectedFinishLate = isValueInsightAtRisk(expectedFinishDeltaInDays);
  const expectedFinish: InsightData = {
    displayName: renderDeltaInDaysWithName('Expected Finish', expectedFinishDeltaInDays),
    icon: flagCheckeredSolid,
    value: formatDate('expectedFinish'),
    disabled: !expectedFinishLate && isValueInsightDisabled('expectedFinish'),
    hasRisk: expectedFinishLate,
  };

  // We need to have an even number of insights or one will not be rendered with
  // the current code
  const allInsightsInOrder = [
    totalHours,
    remainingHours,
    logged,
    uncertainHours,
    unusedHours,
    clippedHours,
    targetFinish,
    expectedFinish,
  ];

  return { allInsightsInOrder };
};
