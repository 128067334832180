import { LpIcon, plusCircleRegular } from 'features/common/lp_icon';
import { useOpenAddWidgetModal } from 'features/dashboards_v2/widget_group/use_open_add_widget_modal';

interface AddWidgetToWidgetGroupButton {
  widgetGroupId: string;
}
export function AddWidgetToWidgetGroupButton({ widgetGroupId }: AddWidgetToWidgetGroupButton) {
  const openAddWidgetModal = useOpenAddWidgetModal(widgetGroupId);

  return (
    <button className="widget-group-add-widget" onClick={openAddWidgetModal}>
      <LpIcon icon={plusCircleRegular} /> Widget
    </button>
  );
}
