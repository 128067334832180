import classNames from 'classnames';
import { Image } from 'semantic-ui-react';

import { LIQUIDPLANNER_URL } from 'lib/constants';
import NewWindowLink from 'lib/display_helpers/url_links';
import lpLogo from 'static/img/lpSvgLogoWhite.svg';
import theNewLiquidPlannerLogo from 'static/img/the_new_liquidplanner_logo.png';

interface LpLogoProps {
  className?: string;
  logoType: Logo;
}

type Logo = 'core' | 'wavy';

export const LpLogo = ({ className, logoType }: LpLogoProps) => {
  const logo = logoType === 'wavy' ? theNewLiquidPlannerLogo : lpLogo;

  return (
    <NewWindowLink to={LIQUIDPLANNER_URL}>
      {<Image draggable={false} className={classNames('lp-logo', 'clickable', className)} src={logo} />}
    </NewWindowLink>
  );
};
