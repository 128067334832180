import { PackageStatus, TimesheetGroupingType, UserType } from 'daos/enums';
import { EXPAND_ITEM_ID_QUERY_PARAM, TIMESHEET_GROUPING_QUERY_PARAM, WEEK_QUERY_PARAM } from 'lib/constants';
import { frontend } from 'lib/urls';

export interface PortableLink {
  name?: string | null;
  getUrl: () => string;
}

export class ItemPortableLink implements PortableLink {
  constructor(
    public itemId: number,
    public organizationId: number,
    public workspaceId: number,
    public name?: string | null
  ) {}

  getUrl() {
    return frontend.portable.url({
      itemId: this.itemId,
      organizationId: this.organizationId,
      workspaceId: this.workspaceId,
    });
  }
}

export class DashboardPortableLink implements PortableLink {
  constructor(
    public dashboardId: string,
    public organizationId: number,
    public workspaceId: number,
    public name?: string | null
  ) {}

  getUrl() {
    return frontend.portableDashboard.url({
      dashboardId: this.dashboardId,
      organizationId: this.organizationId,
      workspaceId: this.workspaceId,
    });
  }
}

export class ReportPortableLink implements PortableLink {
  constructor(
    public widgetId: number,
    public organizationId: number,
    public workspaceId: number,
    public name?: string | null
  ) {}

  getUrl() {
    return frontend.portableReport.url({
      widgetId: this.widgetId,
      organizationId: this.organizationId,
      workspaceId: this.workspaceId,
    });
  }
}

export class LibraryPortableLink implements PortableLink {
  constructor(
    public organizationId: number,
    public workspaceId: number,
    public itemId: number,
    public name?: string | null
  ) {}

  getUrl() {
    return frontend.portableLibraryItem.url({
      itemId: this.itemId,
      organizationId: this.organizationId,
      workspaceId: this.workspaceId,
    });
  }
}

export class LibraryCollectionPortableLink implements PortableLink {
  constructor(
    public organizationId: number,
    public workspaceId: number,
    public packageStatus: PackageStatus,
    public name?: string | null
  ) {}

  getUrl() {
    switch (this.packageStatus) {
      case PackageStatus.SCHEDULED:
        return frontend.scheduledCollectionLibrary.url({
          organizationId: this.organizationId,
          workspaceId: this.workspaceId,
        });
      case PackageStatus.BACKLOG:
        return frontend.pendingCollectionLibrary.url({
          organizationId: this.organizationId,
          workspaceId: this.workspaceId,
        });
      case PackageStatus.ARCHIVED:
        return frontend.archiveCollectionLibrary.url({
          organizationId: this.organizationId,
          workspaceId: this.workspaceId,
        });
      default:
        return frontend.workspaceLibrary.url({
          organizationId: this.organizationId,
          workspaceId: this.workspaceId,
        });
    }
  }
}

export class FilePortableLink implements PortableLink {
  constructor(
    public itemId: number,
    public organizationId: number,
    public workspaceId: number,
    public s3Id: string,
    public name?: string | null
  ) {}

  getUrl() {
    return frontend.itemFileDownload.url({
      itemId: this.itemId,
      organizationId: this.organizationId,
      workspaceId: this.workspaceId,
      s3id: this.s3Id,
    });
  }
}

export class TimesheetPortableLink implements PortableLink {
  constructor(
    public userId: number,
    public userType: UserType.Member | UserType.Resource,
    public week: string | undefined,
    public timesheetGrouping: TimesheetGroupingType,
    public itemId: number | undefined,
    public organizationId: number,
    public workspaceId: number,
    public name?: string | null
  ) {}

  private getUrlWithoutQuery() {
    const params = {
      organizationId: this.organizationId,
      workspaceId: this.workspaceId,
      userId: this.userId,
    };

    const baseUrl = this.userType === UserType.Member ? frontend.memberTimesheet : frontend.resourceTimesheet;
    return baseUrl.url(params);
  }

  getUrl() {
    const url = this.getUrlWithoutQuery();
    const urlParams = [];

    if (this.week) {
      urlParams.push(`${WEEK_QUERY_PARAM}=${this.week}`);
    }

    if (this.timesheetGrouping) {
      urlParams.push(`${TIMESHEET_GROUPING_QUERY_PARAM}=${this.timesheetGrouping}`);
    }

    if (this.itemId) {
      urlParams.push(`${EXPAND_ITEM_ID_QUERY_PARAM}=${this.itemId}`);
    }

    return `${url}?${urlParams.join('&')}`;
  }
}
