import classNames from 'classnames';
import { Loader } from 'semantic-ui-react';
import { LoaderProps } from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';

import { HORIZONTAL_ELLIPSIS } from 'lib/constants';

import './lp_overlay_loader.scss';

const LpOverlayLoader = ({ className, content, active = true, ...props }: LoaderProps) => (
  <Loader
    {...props}
    className={classNames(className, 'lp-loader__fade-in')}
    active={active}
    content={content ? content : `Loading${HORIZONTAL_ELLIPSIS}`}
    size="huge"
  />
);

export default LpOverlayLoader;
