import { isNil } from 'lodash';

import { formatSecondsAsTime } from 'lib/localization';

const formatTimeUnitToAmount = (durationInSeconds: string | undefined) => {
  if (isNil(durationInSeconds)) {
    return 'None';
  }

  const seconds = parseInt(durationInSeconds);

  if (isNaN(seconds)) {
    return 'None';
  }

  const durationInHours = formatSecondsAsTime(seconds);

  if (durationInHours.match(/[:]/)) {
    const durationSplitByColon = durationInHours.split(':');
    if (durationSplitByColon[1] === '00') {
      return `${durationSplitByColon[0]}H`;
    }
    return `${durationSplitByColon[0]}H${durationSplitByColon[1]}M`;
  }

  return `${durationInHours}M`;
};

export default formatTimeUnitToAmount;
