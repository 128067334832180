import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { ResetPasswordDao, ResetPassword as ResetPasswordModel } from 'daos/reset_password';
import { ClickHereToLogin } from 'features/authentication/unauthenticated/token/click_here_to_login';
import { ResetPasswordForm } from 'features/authentication/unauthenticated/token/reset_password/reset_password_form';
import { LpMotionFadeInAndUp } from 'features/common/animated_divs';
import { LpHeader } from 'features/common/as_components';
import RecordNotFound from 'features/common/errors/record_not_found';
import RequestFailed from 'features/common/errors/request_failed';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { awaitRequestFinish } from 'lib/api';
import { ErrorCodes } from 'lib/api/types';

import 'features/authentication/unauthenticated/token/reset_password/index.scss';

interface ResetPasswordProps {
  token: string;
}

interface ResetPasswordState {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly claimedAt: string | null;
  readonly expired: boolean;
  readonly id: number;
}

const resetPasswordInitialState = {
  email: '',
  firstName: '',
  lastName: '',
  claimedAt: null,
  expired: false,
  id: 0,
};

const ResetPassword = ({ token }: ResetPasswordProps) => {
  const dispatch = useDispatch();

  const [tokenNotFound, setTokenNotFound] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [resetPassword, setResetPassword] = useState<ResetPasswordState>(resetPasswordInitialState);

  useEffect(() => {
    const { uuid } = dispatch(ResetPasswordDao.get({ token }));

    dispatch(
      awaitRequestFinish<ResetPasswordModel>(uuid, {
        onError: ({ errors }) => {
          if (errors[0]) {
            if (errors[0].code === ErrorCodes.RecordNotFound) {
              setTokenNotFound(true);
              return;
            } else {
              setUnknownError(true);
            }
          }
        },
        onSuccess: ({ data, entities }) => {
          const user = entities.users?.[data.user.id];
          const { email: initialEmail, firstName: initialFirst, lastName: initialLast } = resetPasswordInitialState;

          const updatedState = {
            email: user?.email ?? initialEmail,
            firstName: user?.firstName ?? initialFirst,
            lastName: user?.lastName ?? initialLast,
            claimedAt: data.claimedAt,
            expired: data.expired,
            id: data.id,
          };

          setResetPassword(updatedState);
        },
      })
    );
  }, [dispatch, token]);

  if (tokenNotFound) {
    return (
      <>
        <LpMotionFadeInAndUp className="reset-password">
          <RecordNotFound className="reset-password__initial-fetch-errors" recordType="reset password link" />
          <ClickHereToLogin />
        </LpMotionFadeInAndUp>

        <LpLegalTermsFooter />
      </>
    );
  }

  if (unknownError) {
    return (
      <>
        <LpMotionFadeInAndUp className="reset-password">
          <RequestFailed />
          <ClickHereToLogin />
        </LpMotionFadeInAndUp>

        <LpLegalTermsFooter />
      </>
    );
  }

  return resetPassword.id === 0 ? (
    <LpOverlayLoader />
  ) : (
    <>
      <LpMotionFadeInAndUp className="reset-password">
        <div className="reset-password__header-block">
          <LpHeader
            headerType="h1"
            className="reset-password__header"
          >{`Enter your new password below, ${resetPassword.firstName}.`}</LpHeader>

          <div className="reset-password__sub-header-text">
            <strong>{"You're almost there!"}</strong>
          </div>
        </div>
        <ResetPasswordForm
          token={token}
          resetPasswordId={resetPassword.id}
          expired={resetPassword.expired}
          claimedAt={resetPassword.claimedAt}
        />
      </LpMotionFadeInAndUp>

      <LpLegalTermsFooter />
    </>
  );
};

export default ResetPassword;
