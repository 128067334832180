import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { SchedulingType, StatusFilterGroups } from 'daos/enums';
import { TaskStatus } from 'daos/model_types';
import {
  plusCircleSolid,
  circleSolid,
  checkCircleSolid,
  exclamationTriangleSolid,
  LpIcon,
  pauseCircleSolid,
  dotCircleSolid,
  SizeProp,
} from 'features/common/lp_icon';
import { getTaskStatusForId } from 'redux/entities/selectors/task_status';
import { lpRed, slate700 } from 'style/variables';

interface TaskStatusIconProps {
  className?: string;
  hasToolTip?: boolean;
  size?: SizeProp;
  color?: string;
  name?: string;
  schedulingType?: SchedulingType | StatusFilterGroups | null;
  showBasic?: boolean;
}

export const TaskStatusIcon = ({
  className,
  hasToolTip,
  size = '1x',
  color,
  schedulingType,
  name,
  showBasic,
}: TaskStatusIconProps) => {
  if (!color) {
    color = schedulingType === StatusFilterGroups.atRisk ? lpRed : slate700;
  }

  const getIconForTaskStatus = useMemo(() => {
    switch (schedulingType) {
      case SchedulingType.Done:
        return checkCircleSolid;
      case SchedulingType.Scheduled:
        return dotCircleSolid;
      case SchedulingType.Unscheduled:
        return pauseCircleSolid;
      case StatusFilterGroups.All:
        return circleSolid;
      case StatusFilterGroups.ActiveAndOnHold:
        return plusCircleSolid;
      case StatusFilterGroups.Active:
        return dotCircleSolid;
      case StatusFilterGroups.OnHold:
        return pauseCircleSolid;
      case StatusFilterGroups.Done:
        return checkCircleSolid;
      case StatusFilterGroups.atRisk:
        return exclamationTriangleSolid;
      default:
        return checkCircleSolid;
    }
  }, [schedulingType]);

  const taskIcon = useMemo(
    () => <LpIcon className={`icon ${className}`} size={size} icon={getIconForTaskStatus} color={color} />,
    [getIconForTaskStatus, size, className, color]
  );

  const basicTaskIcon = useMemo(
    () => <LpIcon className={`icon ${className}`} size={size} icon={circleSolid} color={color} />,
    [className, size, color]
  );

  const toolTip = useMemo(() => <p>{name}</p>, [name]);

  const taskStatusIcon = useMemo(() => {
    return showBasic ? basicTaskIcon : taskIcon;
  }, [showBasic, taskIcon, basicTaskIcon]);

  return (
    <>
      {hasToolTip ? <Popup trigger={taskStatusIcon} content={toolTip} basic size="tiny" hoverable /> : taskStatusIcon}
    </>
  );
};

interface TaskIconMakerProps {
  className?: string;
  taskStatusId?: number;
  taskStatus?: TaskStatus;
  hasToolTip?: boolean;
  size?: SizeProp;
  color?: string;
}

const TaskIconMaker = ({
  className = '',
  taskStatus,
  taskStatusId,
  hasToolTip = true,
  size,
  color,
}: TaskIconMakerProps) => {
  const iconTaskStatus = useSelector(
    (state) => taskStatus || (taskStatusId ? getTaskStatusForId(state, taskStatusId) : undefined)
  );
  const taskStatusColor = iconTaskStatus?.color ? `#${iconTaskStatus.color}` : undefined;

  return (
    <TaskStatusIcon
      className={className}
      schedulingType={iconTaskStatus?.schedulingType}
      showBasic={!iconTaskStatus}
      name={iconTaskStatus?.name}
      hasToolTip={hasToolTip}
      size={size}
      color={color ? color : taskStatusColor}
    />
  );
};

export default TaskIconMaker;
