import { LpIcon, rocketLaunchRegular } from 'features/common/lp_icon';
import { lpTeal } from 'style/variables';

const SummarySection = () => (
  <div className="jira-project-modal__content-summary">
    <LpIcon size="8x" icon={rocketLaunchRegular} color={lpTeal} />
    <div className="jira-project-modal__content-summary-text">Your Integration with Jira is ready to launch</div>
    <div className="jira-project-modal__content-summary-info">
      Launching closes the window and creates a new project instantly, with tasks created shortly after the sync
      completes
    </div>
  </div>
);

export default SummarySection;
