import { Columns, StandardColumns } from 'containers/shared/custom_column/enum';
import { CustomFieldWidgetConfigColumn } from 'containers/shared/custom_column/types';
import { readonlyArray } from 'lib/readonly_record';

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

interface WidgetColumn {
  // Investigate: Why are there FrontendColumnOptions in this list if these icons are only
  // used in the properties_summary widget? (...which supports only server-side columns)
  readonly column: Columns;
}
export interface WidgetConfigColumn extends WidgetColumn {
  readonly taskStatusId?: number | null;
  readonly customFieldId?: CustomFieldWidgetConfigColumn['customFieldId'];
  readonly customFieldName?: CustomFieldWidgetConfigColumn['customFieldName'];
  readonly customFieldSourceSystem?: CustomFieldWidgetConfigColumn['customFieldSourceSystem'];
  readonly customFieldType?: CustomFieldWidgetConfigColumn['customFieldType'];
  readonly customFieldArchived?: CustomFieldWidgetConfigColumn['customFieldArchived'];
  readonly direction?: SortDirection | null;
}
interface SortableWidgetConfigColumn extends WidgetColumn {
  readonly direction: SortDirection | null;
  readonly taskStatusId: number | null;
  readonly customFieldId: number | null;
}

export interface MetricWidgetColumn extends WidgetColumn {
  readonly taskStatusId: number | null;
}

export interface PropsWidgetColumn extends WidgetColumn {
  readonly customFieldId: number | null;
}

const placeholderCustomColumnOption = StandardColumns.Assigned;

export const defaultPropsWidgetColumn: PropsWidgetColumn = {
  column: placeholderCustomColumnOption,
  customFieldId: null,
};

export const defaultSortableWidgetConfigColumn: SortableWidgetConfigColumn = {
  column: placeholderCustomColumnOption,
  taskStatusId: null,
  customFieldId: null,
  direction: null,
};

export const mapColumnOptionsToWidgetColumnDefinitions = <T extends WidgetColumn>(
  prototype: T,

  // Intentionally using StandardColumnOptions for these because widget columns must correspond with the server-side enum
  columns: ReadonlyArray<StandardColumns>
) => readonlyArray<T>(columns.map((column) => ({ ...prototype, column })));
