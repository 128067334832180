import classNames from 'classnames';
import { ReactNode } from 'react';

import { DateRangeFilterSettings, LocationFilterSettings } from 'daos/types';
import { LocationFilter } from 'features/dashboards_v2/item_filter/location';
import { DateRangeSetting } from 'features/dashboards_v2/widget_settings/date_range';

import { AssignedFilter } from './assigned';
import { ProjectFilter } from './project';
import { TaskFilter } from './task';
import { LocationScope } from './types';

import './item_filter.scss';

export interface ItemFilterProps {
  className?: string;
  disableLocationFilter?: boolean;
  disableProjectFilter?: boolean;
  disableDateRangeFilter?: boolean;
  dataIsAllActive?: boolean;
  includeUnassigned: boolean;
  includeDisconnected: boolean;
  inheritedLocationScope?: LocationScope;
  onChange: (value: Partial<LocationFilterSettings>) => void;
  showLocationFilter?: boolean;
  values: LocationFilterSettings;
  placeholderText?: ReactNode;
  dateRangeError?: boolean;
}

export const ItemFilter = ({
  className,
  disableLocationFilter = false,
  disableProjectFilter = false,
  disableDateRangeFilter = true,
  dataIsAllActive = false,
  includeUnassigned,
  includeDisconnected,
  inheritedLocationScope,
  onChange,
  showLocationFilter = false,
  values,
  placeholderText,
  dateRangeError,
}: ItemFilterProps) => {
  const handleChange = (newValue: Partial<LocationFilterSettings>) => {
    onChange({ ...values, ...newValue });
  };
  const setDateRangeProperty = (key: keyof DateRangeFilterSettings, newValue: string | null) => {
    onChange({ [key]: newValue });
  };

  const getDateRangeProperty = (key: keyof DateRangeFilterSettings) => {
    return { value: (values[key] as string) ?? undefined };
  };

  return (
    <div className={classNames('v2-dashboard-filter', className)}>
      {/* TODO: LocationFilter should be a controlled component */}
      {showLocationFilter && (
        <DashboardFilterSection name="Scope">
          <LocationFilter
            disabled={disableLocationFilter}
            inheritedLocationScope={inheritedLocationScope}
            onChange={handleChange}
            value={values}
            placeholderText={placeholderText}
          />
        </DashboardFilterSection>
      )}

      <DashboardFilterSection name="Project Filter">
        <ProjectFilter
          disabled={disableProjectFilter}
          onChange={handleChange}
          value={values}
          dataIsAllActive={dataIsAllActive}
        />
      </DashboardFilterSection>

      <DashboardFilterSection name="Task Filter">
        <TaskFilter onChange={handleChange} value={values} dataIsAllActive={dataIsAllActive} />
      </DashboardFilterSection>

      <DashboardFilterSection name="Assigned">
        <AssignedFilter
          className="v2-dashboard-filter__assigned-filter"
          includeUnassigned={includeUnassigned}
          includeDisconnected={includeDisconnected}
          onChange={handleChange}
          value={values}
        />
      </DashboardFilterSection>
      {!disableDateRangeFilter && (
        <DashboardFilterSection name="Date Range">
          <DateRangeSetting
            setFieldValue={setDateRangeProperty}
            getFieldProps={getDateRangeProperty}
            dateRangeError={dateRangeError}
          />
        </DashboardFilterSection>
      )}
    </div>
  );
};

function DashboardFilterSection({ name, children }: { name: string; children: ReactNode | ReadonlyArray<ReactNode> }) {
  return (
    <div className="v2-dashboard-filter__section">
      <label className="v2-dashboard-filter__section-label">{name}</label>
      <div className="v2-dashboard-filter__section-content">{children}</div>
    </div>
  );
}
