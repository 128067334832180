import classNames from 'classnames';
import { Segment, Header } from 'semantic-ui-react';

import { LpIcon, rectangleLandscapeSolid, timelineSolid } from 'features/common/lp_icon';
import { GuestTaskCard } from 'features/common/task_board/guest/card';
import { GuestBoardTask, GuestTaskBoardTaskStatus } from 'features/common/task_board/guest/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

export const GuestTaskStatusColumn = ({
  tasks,
  taskStatus,
}: {
  tasks: ReadonlyArray<GuestBoardTask>;
  taskStatus: GuestTaskBoardTaskStatus;
}) => {
  const hasStoryPointsEnabled = useHasFeature(FeatureFlag.storyPoints);
  const totalStoryPoints = tasks
    .map((task) => task.storyPointsValue)
    .reduce((acc, storyPoints) => (acc ?? 0) + (storyPoints ?? 0), 0);

  return (
    <Segment
      className={classNames(
        'lp-task-board__column',
        !tasks.length && 'lp-task-board__column--empty',
        !tasks.length && 'lp-task-board__column--hide-empty'
      )}
      size="mini"
    >
      <Header className="lp-task-board__column-header">
        <span className="lp-task-board__column-header-task-status-name">{taskStatus.name}</span>
        <span className="lp-task-board__column-header-rollup">
          {!!tasks.length && (
            <>
              {hasStoryPointsEnabled && (
                <span>
                  <LpIcon icon={timelineSolid} color={`#${taskStatus.color}`} />
                  &nbsp;{totalStoryPoints}
                </span>
              )}
              <span>
                <LpIcon icon={rectangleLandscapeSolid} color={`#${taskStatus.color}`} />
                &nbsp;{tasks.length}
              </span>
            </>
          )}
        </span>
      </Header>

      <div className="lp-task-board__column-card-holder">
        {tasks.map((task) => (
          <GuestTaskCard key={task.id} task={task} taskStatus={taskStatus} />
        ))}
      </div>
    </Segment>
  );
};
