import { Redirect, Route, Switch } from 'react-router-dom';

import { useTrackPageViewedOnSegment } from 'features/common/segment/use_report_page_viewed_to_segment';
import { DashboardGuestRoutes } from 'features/guest/dashboard/routes';
import { DashboardPassports } from 'features/guest/dashboard_passports';
import { defaultGuestLandingPage, frontend } from 'lib/urls';

export const AuthenticatedGuestRoutes = () => {
  useTrackPageViewedOnSegment();
  return (
    <Switch>
      <Route path={frontend.dashboardGuest.pattern} component={DashboardGuestRoutes} />
      <Route path={frontend.dashboardPassports.pattern} component={DashboardPassports} />
      <Redirect to={defaultGuestLandingPage.url({})} />
    </Switch>
  );
};
