import { backend } from 'daos/urls';
import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Model } from './model_types';
import { defineModel } from './shared';

const { resource } = defineModel({
  apiType: 'uploadFiles',
  type: 'UPLOAD_FILE',
});

enum FileCategory {
  CustomFieldValueImages = 'customFieldValueImages',
  DiscussionPostImages = 'discussionPostImages',
  DashboardImages = 'dashboardImages',
}

export interface UploadFile extends Model<'uploadFiles'> {
  readonly category: FileCategory;
  readonly s3Id: string;
}

const { UPLOAD_FILE, resourceId } = resource;

interface UploadParams {
  organizationId: number;
  workspaceId: number;
}

const dashboardImage = (params: UploadParams, imageFile: File) => {
  const formData = new FormData();
  formData.append('file', imageFile, imageFile.name);

  return request(backend.dashboardImages.url(params), UPLOAD_FILE, {
    method: HttpMethod.POST,
    headers: { Accept: 'application/json' },
    body: formData,
    meta: { skipReduce: true },
  });
};

export const UploadFileDao = {
  dashboardImage,
  id: resourceId,
} as const;
