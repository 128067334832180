import classNames from 'classnames';
import { Fragment } from 'react';

import { FormattedAssignees } from 'containers/shared/custom_column/assigned';
import { StandardColumns } from 'containers/shared/custom_column/enum';
import { getCheckboxColorAndIcon } from 'containers/shared/custom_column/helpers';
import { ScheduleBarColumn } from 'containers/shared/custom_column/schedule_bar';
import { CustomFieldType } from 'daos/enums';
import { ComponentJoin } from 'features/common/component_join';
import { CUSTOM_FIELD_FORMATTER__INHERITED } from 'features/common/data_grid/formatters/custom_fields/custom_field';
import { LpIcon, squareSolid } from 'features/common/lp_icon';
import { ColumnDataMap, UserProps, WidgetColumnDataValue } from 'features/dashboards_v2/widget/widgets/reports/types';
import { Assignee } from 'lib/display_helpers/assignee/types';
import NewWindowLink from 'lib/display_helpers/url_links';
import { DisplayValueWithOptionalWarningIcon } from 'lib/display_helpers/warning_icon_with_value';
import { gray300 } from 'style/variables';

import 'features/common/data_grid/formatters/custom_fields/custom_field.scss';

export const getDisplayForFieldType = (widgetColumnDataValue: WidgetColumnDataValue) => {
  const { checked, customFieldData, itemData, linkData, picklistData, value, userData } = widgetColumnDataValue;

  if (customFieldData) {
    const { fieldType } = customFieldData;

    switch (fieldType) {
      case CustomFieldType.CHECKBOX: {
        const { color, icon } = getCheckboxColorAndIcon(!!checked);
        return <LpIcon key={fieldType} className="lpicon" color={color} icon={icon} size="lg" />;
      }

      case CustomFieldType.LINK:
      case CustomFieldType.MULTI_LINK: {
        if (linkData) {
          const displays = linkData.map((link, idx) => {
            const { url, text } = link;
            return (
              <NewWindowLink key={idx} to={url}>
                {text ?? url}
              </NewWindowLink>
            );
          });

          return <ComponentJoin key={fieldType}>{displays}</ComponentJoin>;
        }
        return null;
      }

      case CustomFieldType.PICKLIST:
      case CustomFieldType.MULTI_PICKLIST: {
        if (picklistData) {
          const displays = picklistData.map((picklist, idx) => {
            const { name, color } = picklist;
            return (
              <span className="nowrap" key={idx}>
                <LpIcon icon={squareSolid} size="1x" color={color ? `#${color}` : gray300} />
                &nbsp;
                {name}
              </span>
            );
          });

          return <ComponentJoin key={fieldType}>{displays}</ComponentJoin>;
        }
        return null;
      }

      case CustomFieldType.USER:
      case CustomFieldType.MULTI_USER: {
        if (userData) {
          const displays = userData.map((user, idx) => {
            const { username } = user;
            return <Fragment key={idx}>{username}</Fragment>;
          });

          return <ComponentJoin key={fieldType}>{displays}</ComponentJoin>;
        }
        return null;
      }

      case CustomFieldType.ITEM:
      case CustomFieldType.MULTI_ITEM: {
        if (itemData) {
          const displays = itemData.map((item, idx) => {
            return <Fragment key={idx}>{item.name}</Fragment>;
          });

          return <ComponentJoin key={fieldType}>{displays}</ComponentJoin>;
        }
        return null;
      }

      case CustomFieldType.CURRENCY:
      case CustomFieldType.DATE:
      case CustomFieldType.MULTI_TEXT:
      case CustomFieldType.MULTILINE_TEXT:
      case CustomFieldType.NUMERIC:
      case CustomFieldType.TEXT:
        return value;
      default:
        return null;
    }
  }
  return null;
};

const getAssignedColumnDisplay = (widgetColumnData: ReadonlyArray<WidgetColumnDataValue>) => {
  const users = widgetColumnData.reduce((acc: Array<UserProps>, datum) => {
    const userData = datum.userData;

    if (userData) {
      acc.push(...userData);
    }

    return acc;
  }, []);

  users.sort((a, b) => Number(a.done) - Number(b.done));

  const assigneeMap = new Map<number, Assignee>();
  users.forEach((user, idx) => {
    assigneeMap.set(user.orgUserId ?? idx, {
      disconnected: !!user.disconnected,
      done: !!user.done,
      orgUserId: user.orgUserId ?? idx,
      username: user.username,
    });
  });

  return <FormattedAssignees assigneeMap={assigneeMap} />;
};

export const getFormattedDisplay = (columnKey: string, columnDataMap: ColumnDataMap, isSummaryRow = false) => {
  const columnKeyNumber = Number(columnKey);
  const isCustomFieldColumn = !isNaN(columnKeyNumber);
  const columnData = columnDataMap[columnKey]?.data ?? [];

  if (isCustomFieldColumn) {
    return columnData.map((data, idx) => {
      const display = getDisplayForFieldType(data);
      return (
        <span className={classNames({ [CUSTOM_FIELD_FORMATTER__INHERITED]: data.inherited })} key={idx}>
          {display}
        </span>
      );
    });
  }

  if (columnKey === StandardColumns.Assigned) {
    return getAssignedColumnDisplay(columnData);
  }

  if (columnKey === StandardColumns.ScheduleBar) {
    if (isSummaryRow) {
      return null;
    }

    const barDisplays = columnData.map((datum, index) => {
      if (datum.scheduleData) {
        return <ScheduleBarColumn key={index} {...datum.scheduleData} />;
      }

      return (
        <span key={index} className="custom-column__schedule-bar custom-column__schedule-bar--unscheduled">
          Unscheduled
        </span>
      );
    });

    return <ComponentJoin key={columnKey}>{barDisplays}</ComponentJoin>;
  }

  const display = columnData.map((data) => data.value).join(', ');
  const alert = columnData.some((data) => data.alert);

  if (alert) {
    return <DisplayValueWithOptionalWarningIcon value={display} showWarningIcon={alert} />;
  }
  return display;
};
