import { ReactElement, ReactNode, RefObject, useRef } from 'react';
import { Portal, Ref } from 'semantic-ui-react';

import { usePopper } from 'features/common/inputs/dropdowns/portal_action_dropdown/use_popper';
import { useWindowResize } from 'hooks/use_window_resize';

import 'features/item_panel/sections/planning_section/limits/limit_dropdown_portal/index.scss';

interface LimitDropdownPortalProps {
  children: ReactElement;
  onClose: () => void;
  open: boolean;
  popperHeightOffset?: number;
  trigger: ReactNode;
  triggerRef: RefObject<HTMLButtonElement>;
}

export const LimitDropdownPortal = ({
  children,
  onClose,
  open,
  popperHeightOffset,
  trigger,
  triggerRef,
}: LimitDropdownPortalProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useWindowResize(onClose, 0);
  usePopper(dropdownRef, onClose, triggerRef, undefined, 'bottom-start', popperHeightOffset);

  return (
    <Portal
      mountNode={document.getElementById('limits-portal')}
      trigger={trigger}
      open={open}
      onClose={onClose}
      closeOnTriggerClick
    >
      <Ref innerRef={dropdownRef}>{children}</Ref>
    </Portal>
  );
};
