import { Statistic } from 'semantic-ui-react';

import LpLegalTermsFooter from 'containers/shared/footers/lp_legal_terms_footer';
import { ClickHereToLogin } from 'features/authentication/unauthenticated/token/click_here_to_login';
import { LpIcon, medkitSolid } from 'features/common/lp_icon';
import { ApiError, ErrorCodes } from 'lib/api/types';

export type InvitationError = Pick<ApiError, 'code' | 'detail' | 'status'>;

interface InvitationErrorProps {
  error: InvitationError;
}

function getMessageFromError(error: InvitationError) {
  switch (error.code) {
    case ErrorCodes.InvalidInvitationExistingLpAuth:
    case ErrorCodes.InvalidInvitationExistingSso:
      return (
        <>
          We encountered an error with this invitation.
          <br />
          You&apos;ll just need to be invited again to start planning!
        </>
      );
    default:
      return `Invitation ${error.detail}`;
  }
}

export const InvitationErrorMessage = ({ error }: InvitationErrorProps) => {
  const message = getMessageFromError(error);

  return (
    <div className="top-level-error__wrapper">
      <div className="top-level-error__content">
        <LpIcon icon={medkitSolid} size="10x" className="top-level-error__icon" />
        <div className="top-level-error">
          <Statistic>
            <Statistic.Label>{message}</Statistic.Label>
          </Statistic>

          <ClickHereToLogin />
        </div>
      </div>
      <LpLegalTermsFooter />
    </div>
  );
};
