import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { avoClient } from 'lib/avo/client';
import { mapLocationPathnameToLpPageName } from 'lib/url_helpers';

const { segmentKey } = window as any;
export const useTrackPageViewedOnSegment = () => {
  const location = useLocation();
  useEffect(() => {
    const { pageName, viewName } = mapLocationPathnameToLpPageName(location.pathname);

    if (segmentKey) {
      avoClient?.pageViewed({
        customDestinationPageName_: pageName,
        originView: viewName,
      });
    }
  }, [location]);
};
