import { ComponentType, useEffect, useRef } from 'react';
import { Ref, Tab } from 'semantic-ui-react';

import { JiraProjectModalError } from 'features/jira_project/modal/common/jira_project_modal_error';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';

interface SectionProps {
  name: string;
  TabComponent: ComponentType;
}

function Section({ name, TabComponent }: SectionProps) {
  const jiraProjectPanelContentRef = useRef<HTMLElement>(null);
  const { formError } = useJiraProjectModalContext();

  useEffect(() => {
    jiraProjectPanelContentRef.current?.scrollTo(0, 0);
  }, [name]);

  return (
    <Ref innerRef={jiraProjectPanelContentRef}>
      <Tab.Pane
        // render the pane as a div, otherwise semantic adds a border and margin/padding
        // eslint-disable-next-line react/forbid-component-props
        as="div"
        className="jira-project-modal__content"
      >
        {formError && <JiraProjectModalError error={formError} />}
        <TabComponent />
      </Tab.Pane>
    </Ref>
  );
}

export default Section;
