import { useSignal } from '@preact/signals-react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import maxSize from 'popper-max-size-modifier';
import { cloneElement, memo, ReactElement, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { areEqual } from 'react-window';
import { Dropdown, Portal, Ref, StrictDropdownProps } from 'semantic-ui-react';

import { getPopperFlipMutationObserver } from 'features/common/inputs/dropdowns/helpers';
import { createPopperOverTrigger } from 'features/common/inputs/dropdowns/portal_action_dropdown/use_popper';
import { applyFlip, applyMatchWidth, applyMaxSize } from 'features/common/inputs/dropdowns/portal_dropdown/helpers';
import { KeypressKeys } from 'lib/helpers/keypress_keys';

import './index.scss';

const dropdownMaxHeight = 30 * 14;
const dropdownHeightOffset = 24;
const portal = document.querySelector('#portal');

type DropdownProps = Omit<StrictDropdownProps, 'openOnFocus' | 'closeOnEscape' | 'selectOnBlur'>;

const selector = 'input, [tabindex], button';
function getChildFocusableElement(node: HTMLElement | null) {
  if (node?.matches(selector)) {
    return node;
  }
  return node?.querySelector(selector) as HTMLElement | null;
}

interface PortalDropdownProps {
  children: ReactElement<DropdownProps, typeof Dropdown>;
}
export const PortalDropdown = memo(({ children }: PortalDropdownProps) => {
  const [openDropdown, setOpenDropdown] = useState(children.props.open);
  const triggerRef = useRef<HTMLElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const positionCalculated = useSignal(false);

  useEffect(() => {
    if (!openDropdown || !dropdownRef.current || !triggerRef.current) {
      return;
    }
    const observer = getPopperFlipMutationObserver();
    createPopperOverTrigger(dropdownRef.current, triggerRef.current, 'bottom-start', 0, [
      maxSize,
      applyMaxSize('.visible.menu.transition', dropdownMaxHeight, dropdownHeightOffset),
      applyMatchWidth(),
      applyFlip(['top-start']),
    ]);
    positionCalculated.value = true;
    observer.observe(dropdownRef.current, { attributeFilter: ['data-popper-placement'] });

    return () => {
      positionCalculated.value = false;
      observer.disconnect();
    };
  }, [openDropdown, positionCalculated]);

  useEffect(() => {
    if (!openDropdown || !dropdownRef.current || !triggerRef.current) {
      return;
    }

    const dropdownKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case KeypressKeys.Tab:
        case KeypressKeys.Escape: {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          const event = e as unknown as SyntheticEvent<HTMLElement, KeyboardEvent>;
          children.props.onClose?.(event, {});
          getChildFocusableElement(triggerRef.current)?.focus();
        }
      }
    };

    const dropdownElFocusableChild = getChildFocusableElement(dropdownRef.current);
    dropdownElFocusableChild?.addEventListener('keydown', dropdownKeyDown);

    if (openDropdown) {
      dropdownElFocusableChild?.focus();
    }

    return () => {
      dropdownElFocusableChild?.removeEventListener('keydown', dropdownKeyDown);
    };
  }, [children.props, openDropdown]);

  const triggerClone = cloneElement<StrictDropdownProps>(children, {
    className: `${children.props.className} lp-portal-dropdown-trigger`,
    open: false,
    openOnFocus: false,

    onOpen: (e, data) => {
      setOpenDropdown(true);
      children.props.onOpen?.(e, data);
    },
    onClose: undefined,
  });

  const dropdownClone = openDropdown
    ? cloneElement<StrictDropdownProps>(children, {
        open: true,
        closeOnEscape: false,
        selectOnBlur: false,
        fluid: true,
        onClose: (e, data) => {
          setOpenDropdown(false);
          children.props.onClose?.(e, data);

          requestAnimationFrame(() => {
            getChildFocusableElement(triggerRef.current)?.focus();
          });
        },
      })
    : null;

  return (
    <>
      <Ref innerRef={triggerRef}>{triggerClone}</Ref>

      {openDropdown && (
        <Portal mountNode={portal} open={true}>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: positionCalculated.value ? 1 : 0,
            }}
            className={classNames('lp-portal-dropdown', {
              simple: !children.props.search,
            })}
            ref={dropdownRef}
          >
            {dropdownClone}
          </motion.div>
        </Portal>
      )}
    </>
  );
}, areEqual);
