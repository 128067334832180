import { keyBy } from 'lodash';

import { OrganizationDao } from 'daos/organization';
import { OrganizationSsoUser } from 'daos/organization_sso_users';
import { OrganizationUserDao } from 'daos/organization_user';
import { SelectionList } from 'features/common/selection_list/types';

export const getSsoUserListPayload = ({
  ssoUsers,
  selectionList,
  organizationId,
  ssoEnabled,
}: {
  ssoUsers: ReadonlyArray<OrganizationSsoUser>;
  selectionList: Array<SelectionList>;
  organizationId: number;
  ssoEnabled: boolean;
}) => {
  const ssoUserKeyByOrgUserId = keyBy(ssoUsers, (ssoUser) => ssoUser.organizationUser.id);

  return selectionList.reduce((acc: Array<Partial<OrganizationSsoUser>>, listItem) => {
    if (ssoUserKeyByOrgUserId[listItem.id]) {
      const ssoUser = ssoUserKeyByOrgUserId[listItem.id];
      acc.push({ ...ssoUser, ssoEnabled: ssoEnabled });
    } else {
      acc.push({
        organization: OrganizationDao.id(organizationId),
        organizationUser: OrganizationUserDao.id(Number(listItem.id)),
        ssoEnabled: ssoEnabled,
      });
    }

    return acc;
  }, []);
};
