import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { UserType } from 'daos/enums';
import { WorkspaceUser } from 'daos/model_types';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import {
  DisconnectModal,
  DisconnectModalProps,
} from 'features/organization_directory/users/profile/workspace_memberships/disconnect_modal';
import OrganizationAdminCheckBox from 'features/shared/user_profile/about_table/organization_admin_checkbox';
import TimesheetReviewerCheckbox from 'features/shared/user_profile/about_table/timesheet_reviewer_checkbox';
import WorkspaceManagerCheckbox from 'features/shared/user_profile/about_table/workspace_manager_checkbox';
import WorkspaceMemberCheckBox from 'features/shared/user_profile/about_table/workspace_member_checkbox';
import { frontend } from 'lib/urls';
import { getOrganizationUserForId, getUserForId, getWorkspaceUserById } from 'redux/entities/selectors/user';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';

import { TimesheetExporterCheckbox } from './timesheet_exporter_checkbox';

interface UserPermissionsRowProps {
  isAdmin: boolean;
  isCurrentUser: boolean;
  orgUserId: number;
  wsUserId: number;
  updateOrgUser: ({ admin }: { admin: boolean }) => void;
  updateWsUser: (updateProps: Partial<WorkspaceUser>) => void;
}

const UserPermissionsRow = ({
  isAdmin,
  isCurrentUser,
  orgUserId,
  updateOrgUser,
  updateWsUser,
  wsUserId,
}: UserPermissionsRowProps) => {
  const targetWsUser = useSelector((state) => getWorkspaceUserById(state, wsUserId));
  const targetOrgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const targetUser = useSelector((state) => targetWsUser && getUserForId(state, targetWsUser.user.id));
  const workspace = useSelector(getCurrentWorkspace);
  const organizationId = useSelector(getCurrentOrganizationId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disconnectModalProps, setDisconnectModalProps] = useState<DisconnectModalProps | undefined>(undefined);

  if (!targetWsUser || !targetUser || !workspace || !targetOrgUser) {
    return null;
  }

  const userId = targetUser.id;
  const workspaceId = workspace.id;

  return (
    <>
      {isModalOpen && disconnectModalProps && <DisconnectModal {...disconnectModalProps} />}
      <Table.Row>
        <Table.Cell collapsing content="Access" />
        <Table.Cell colSpan="2" className="user-profile__about-table-row">
          {targetWsUser.disconnectedAt && <span className="evicted-user">Disconnected from Workspace</span>}
          {!targetWsUser.disconnectedAt && targetUser?.userType === UserType.Member && (
            <>
              <div>
                <label>
                  <OrganizationAdminCheckBox
                    isAdmin={isAdmin}
                    isCurrentUser={isCurrentUser}
                    orgUserId={orgUserId}
                    wsUserId={wsUserId}
                    updateOrgUser={updateOrgUser}
                  />
                  Org Admin
                </label>
              </div>
              <div>
                <label>
                  <WorkspaceManagerCheckbox
                    isAdmin={isAdmin}
                    isCurrentWsUser={isCurrentUser}
                    isManager={targetWsUser.manager}
                    workspaceUserId={wsUserId}
                    updateWsUser={updateWsUser}
                  />
                  Workspace Manager
                </label>
              </div>
              <div>
                <label>
                  <TimesheetExporterCheckbox
                    isAdmin={isAdmin}
                    isCurrentWsUser={isCurrentUser}
                    isManager={targetWsUser.manager}
                    isExporter={targetWsUser.timesheetExporter}
                    workspaceUserId={wsUserId}
                    isWsUserDisconnected={!!targetWsUser.disconnectedAt}
                    updateWsUser={updateWsUser}
                  />
                  Timesheet Exporter & Cost Viewer{' '}
                  <LpIcon
                    icon={questionCircleSolid}
                    hoverText="Allow member to export Timesheets and see rate-based cost data on plan items they have access to."
                  />
                </label>
              </div>
              <div>
                <label>
                  <TimesheetReviewerCheckbox
                    isAdmin={isAdmin}
                    isCurrentWsUser={isCurrentUser}
                    isManager={targetWsUser.manager}
                    isExporter={targetWsUser.timesheetExporter}
                    isReviewer={targetWsUser.timesheetReviewer}
                    workspaceUserId={wsUserId}
                    isWsUserDisconnected={!!targetWsUser.disconnectedAt}
                    updateWsUser={updateWsUser}
                  />
                  Timesheet Reviewer
                </label>
              </div>
              <div>
                <label>
                  <WorkspaceMemberCheckBox
                    isAdmin={isAdmin}
                    isCurrentUser={isCurrentUser}
                    orgUserId={orgUserId}
                    wsUserId={wsUserId}
                    updateWsUser={updateWsUser}
                    setIsModalOpen={setIsModalOpen}
                    setDisconnectModalProps={setDisconnectModalProps}
                  />
                  Workspace Member ({' '}
                  {
                    <LpLink to={frontend.memberProjectAccess.url({ organizationId, workspaceId, userId })}>
                      Access Roles
                    </LpLink>
                  }{' '}
                  )
                </label>
              </div>
            </>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default UserPermissionsRow;
