import { filterLight } from 'features/common/lp_icon';
import { useDirtyInputUpdate } from 'hooks/use_dirty_input_update';

import './index.scss';

import LpSearchInput, { LpSearchOptionalProps } from '.';

interface LpSearchInputUncontrolledProps extends LpSearchOptionalProps {
  onChange: (value: string) => void;
  initialValue?: string;
  onClear?: () => void;
}

const LpSearchInputUncontrolled = ({
  initialValue: parentInitialValue,
  onChange: parentOnChange,
  onClear: parentOnClear,
  className,
  onKeyDown,
  autoFocus = false,
  disabled = false,
  icon = filterLight,
  placeholder = 'Filter',
}: LpSearchInputUncontrolledProps) => {
  const { isDirty, inputValue, onChange, clearInput } = useDirtyInputUpdate({
    onChangeCallback: parentOnChange,
    onClear: parentOnClear,
    initialValue: parentInitialValue,
  });

  const calcClearInputIfEnabled = () => !disabled && clearInput();

  return (
    <LpSearchInput
      onKeyDown={onKeyDown}
      className={className}
      onClear={calcClearInputIfEnabled}
      onChange={onChange}
      initialValue={inputValue}
      isDirty={isDirty}
      autoFocus={autoFocus}
      disabled={disabled}
      icon={icon}
      placeholder={placeholder}
    />
  );
};

export default LpSearchInputUncontrolled;
