import { useLocation } from 'react-router';

import { WidgetWithData } from 'daos/model_types';
import { isAncestorItemScheduleBar } from 'features/common/schedule_bar/helpers';
import { ScheduleBarRenderer } from 'features/common/schedule_bar/renderer';
import { calcItemDataForScheduleBar } from 'features/common/schedule_bar/use_item_data_for_schedule_bar';
import { RenderedWidget } from 'features/dashboards_v2/widget';
import { ScheduleSummaryData } from 'features/dashboards_v2/widget/widgets/schedule_summary/types';
import { ScheduleHoverStatus, calcItemDataForScheduleTooltip } from 'hooks/use_item_data_for_schedule_hover';
import { calcItemDataForStatus } from 'hooks/use_item_data_for_status';
import { ScheduleViewType, calcItemsDateRange } from 'hooks/use_items_date_range';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

import './index.scss';

export const ScheduleSummaryWidget = ({ widget }: { widget: WidgetWithData }) => {
  const { pathname } = useLocation();
  const { currentBrowserDateTime } = useLocalizedFormats();
  const { formatLocalDate } = useLocalizedFormats();

  const widgetData = widget.data as ScheduleSummaryData;

  const dateRange = calcItemsDateRange({
    allItemMetrics: widgetData.itemMetrics,
    localNow: currentBrowserDateTime(),
    viewType: ScheduleViewType.Widget,
    items: [widgetData.item],
  });

  const item = widgetData.item;
  const itemMetrics = widgetData.itemMetrics[item.id];

  const parentItem = widgetData.parentItem;
  const parentItemMetrics = widgetData.itemMetrics[parentItem?.id ?? 0];

  if (!item || !itemMetrics || !parentItem || !parentItemMetrics) {
    return <RenderedWidget noContent />;
  }

  const {
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isDoneLate,
    isEffectivelyOnHold,
    isExpectedStartAfterEffectiveTargetFinish,
    isExpectedStartBeforeEffectiveTargetStart,
    isExpectedStartEqualExpectedFinish,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
  } = calcItemDataForStatus({
    doneDate: item.doneDate,
    effectiveTargetFinish: itemMetrics.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics.effectiveTargetStart,
    expectedFinish: item.expectedFinish,
    expectedStart: item.expectedStart,
    folderStatus: item.folderStatus,
    isClipped: item.clippedEffort !== null,
    isLate: !!item.late,
    itemType: item.itemType,
    latestFinish: item.latestFinish,
    packageStatus: item.packageStatus,
    schedulingType: undefined,
    targetFinish: item.targetFinish,
  });

  const { isEffectivelyOnHold: isParentEffectivelyOnHold, isOnHold: isParentOnHold } = calcItemDataForStatus({
    doneDate: parentItem.doneDate,
    effectiveTargetFinish: parentItemMetrics.effectiveTargetFinish,
    effectiveTargetStart: parentItemMetrics.effectiveTargetStart,
    expectedFinish: parentItem.expectedFinish,
    expectedStart: parentItem.expectedStart,
    folderStatus: parentItem.folderStatus,
    isClipped: parentItem.clippedEffort !== null,
    isLate: !!parentItem.late,
    itemType: parentItem.itemType,
    latestFinish: parentItem.latestFinish,
    packageStatus: parentItem.packageStatus,
    schedulingType: undefined,
    targetFinish: parentItem.targetFinish,
  });

  const {
    scheduleBarDateOffsetPercents,
    scheduleBarStyles,
    scheduleBarLabelStyles,
    showRollupEarliestActiveTargetFinish,
    showRollupLatestTargetFinish,
  } = calcItemDataForScheduleBar({
    assignmentEffectiveTargetFinish: null,
    dateRange,
    doneDate: item.doneDate,
    effectiveTargetFinish: itemMetrics.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics.effectiveTargetStart,
    expectedFinish: item.expectedFinish,
    expectedStart: item.expectedStart,
    isAssignmentItem: false,
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isEffectivelyOnHold,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
    isTaskItem: false,
    itemDateRangeFinish: dateRange.finish,
    itemDateRangeStart: dateRange.start,
    latestFinish: item.latestFinish,
    rollupEarliestActiveTargetFinish: itemMetrics.rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish: itemMetrics.rollupLatestTargetFinish,
    targetFinish: item.targetFinish,
    targetStart: item.targetStart,
  });

  const isIncomplete =
    calcItemDataForScheduleTooltip({
      doneDate: item.doneDate,
      expectedFinish: item.expectedFinish,
      expectedStart: item.expectedStart,
      folderStatus: item.folderStatus,
      highEffort: item.highEffort,
      isLate,
      isLateRisk,
      itemType: item.itemType,
      latestFinish: item.latestFinish,
      needsAssignment: false,
      needsEstimate: false,
      hasAssignedOrgUser: false,
      scheduleDirective: null,
      taskStatusSchedulingType: undefined,
    }) === ScheduleHoverStatus.NotComplete;

  return (
    <RenderedWidget
      body={
        <ScheduleBarRenderer
          doneDate={item.doneDate}
          effectiveTargetFinish={itemMetrics.effectiveTargetFinish}
          effectiveTargetStart={itemMetrics.effectiveTargetStart}
          expectedFinish={item.expectedFinish}
          expectedStart={item.expectedStart}
          formatDateForDisplay={(day: string) => formatLocalDate(day)}
          highEffort={item.highEffort ?? 0}
          isAncestorItemScheduleBar={isAncestorItemScheduleBar({ itemType: item.itemType, pathname })}
          isAssignment={false}
          isAssignmentNeedsAttention={false}
          isAssignmentPlaceholder={false}
          isAssignmentTrackingOnly={false}
          isClipped={isClipped}
          isDebugMode={false}
          isDone={isDone}
          isDoneAfterEffectiveTargetFinish={isDoneAfterEffectiveTargetFinish}
          isDoneLate={isDoneLate}
          isEffectivelyOnHold={isEffectivelyOnHold}
          isExpectedStartAfterEffectiveTargetFinish={isExpectedStartAfterEffectiveTargetFinish}
          isExpectedStartBeforeEffectiveTargetStart={isExpectedStartBeforeEffectiveTargetStart}
          isExpectedStartEqualExpectedFinish={isExpectedStartEqualExpectedFinish}
          isIncomplete={isIncomplete}
          isLate={isLate}
          isLateRisk={isLateRisk}
          isOnHold={isOnHold}
          isParentEffectivelyOnHold={isParentEffectivelyOnHold}
          isParentOnHold={isParentOnHold}
          isScheduled={isScheduled}
          isTask={false}
          itemId={item.id}
          latestFinish={item.latestFinish}
          rollupEarliestActiveTargetFinish={itemMetrics.rollupEarliestActiveTargetFinish}
          rollupLatestTargetFinish={itemMetrics.rollupLatestTargetFinish}
          scheduleBarDateOffsetPercents={scheduleBarDateOffsetPercents}
          scheduleBarLabelStyles={scheduleBarLabelStyles}
          scheduleBarStyles={scheduleBarStyles}
          showDateLabels={true}
          showPredecessor={item.hasPredecessors}
          showRollupDates={false}
          showRollupEarliestActiveTargetFinish={showRollupEarliestActiveTargetFinish}
          showRollupLatestTargetFinish={showRollupLatestTargetFinish}
          showSuccessor={item.hasSuccessors}
          targetFinish={item.targetFinish}
          targetStart={item.targetStart}
          viewType={ScheduleViewType.Widget}
        />
      }
    />
  );
};
