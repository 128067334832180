import { useSelector } from 'react-redux';

import { PropertyChange } from 'daos/types';
import { getStoryPointForId } from 'redux/entities/selectors/story_points';

const ChangeCardStoryPoints = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const storyPointsId = propertyChangeData.value;

  const storyPoints = useSelector((state) => (storyPointsId ? getStoryPointForId(state, storyPointsId) : undefined));

  return <>{storyPoints?.value ?? 'None'}</>;
};

export default ChangeCardStoryPoints;
