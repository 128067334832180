import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { LpDropdownItemAsLink } from 'features/common/as_components';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { ellipsisVSolid as actionEllipsis, LpIcon } from 'features/common/lp_icon';
import { frontend } from 'lib/urls';

interface OrganizationContextButtonProps {
  organizationId: number;
  handleOrgSwitch: (e: SyntheticEvent, orgId: number) => void;
  onClose: () => void;
}

const OrganizationContextButton = ({ organizationId, handleOrgSwitch, onClose }: OrganizationContextButtonProps) => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);

  const [isOpen, setIsOpen] = useState(false);

  const handleEditClick = () => setIsOpen(!isOpen);
  const handleClickOrgSwitch = (e: SyntheticEvent) => handleOrgSwitch(e, organizationId);

  return (
    <Dropdown
      className="l_float-right"
      icon={<LpIcon className="task-status-edit-button" icon={actionEllipsis} color="black" />}
      onClose={handleEditClick}
      onOpen={handleEditClick}
    >
      <Dropdown.Menu direction="left" open={isOpen}>
        <LpDropdownItemAsLink to={frontend.organization.url({ organizationId })} onClick={handleClickOrgSwitch}>
          Switch
        </LpDropdownItemAsLink>

        {organizationId === currentOrganizationId && (
          <LpDropdownItemAsLink
            onClick={onClose}
            to={frontend.customizeWorkspaceSettings.url({ organizationId: currentOrganizationId, workspaceId })}
          >
            Settings
          </LpDropdownItemAsLink>
        )}

        <Dropdown.Item disabled={true} text={'Disconnect'} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default OrganizationContextButton;
