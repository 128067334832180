import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { FolderStatusFilter } from 'daos/enums';
import OtherPropertiesOptionsTable, {
  OtherPropertiesTableValue,
} from 'features/common/custom_item_filter/other_properties_table';
import { FolderFilterStatus } from 'features/common/inputs/dropdowns/folder_status_filter_dropdown/types';
import { filterLight, LpIcon } from 'features/common/lp_icon';
import LpModal from 'features/common/modals/lp_modal';
import { getActiveWorkspaceCustomFieldsInheritedOrUsedOnProjects } from 'redux/entities/selectors/custom_field';
import { getCurrentWorkspace } from 'redux/entities/selectors/workspace';
import { gray700 } from 'style/variables';

import ProjectStatusList from './item_status_lists/project_status_list';
import { CustomProjectFilters } from './types';

import './index.scss';

const folderDropdownResetValues: CustomProjectFilters = {
  projectCreatedByUserId: null,
  projectCustomFieldFilters: [],
  projectNameFilter: '',
  projectScheduleRiskOnly: false,
  projectWorkLimitRiskOnly: false,
  projectHasFileFilter: false,
  projectStatusFilter: FolderStatusFilter.ActiveOnHold,
};

export const CustomFolderFilterModal = ({
  onApply,
  onCancel,
  value,
}: {
  onApply: (value: CustomProjectFilters) => void;
  onCancel: () => void;
  value: CustomProjectFilters;
}) => {
  const currentWorkspaceHasFileUploads = useSelector(getCurrentWorkspace)?.fileUploads;
  const [state, setState] = useState(value);
  const customFields = useSelector(getActiveWorkspaceCustomFieldsInheritedOrUsedOnProjects);

  const onProjectStatusFilterChange = (newValue: FolderStatusFilter) => {
    setState((prev) => ({ ...prev, projectStatusFilter: newValue }));
  };

  const onOtherPropertiesChange = (newValue: OtherPropertiesTableValue) => {
    setState((prev) => ({
      ...prev,
      projectCreatedByUserId: newValue.createdByUserId ?? null,
      projectCustomFieldFilters: newValue.otherCustomFieldProperties,
      projectNameFilter: newValue.nameContainsValue,
      projectScheduleRiskOnly: newValue.selectedAtRisk,
      projectWorkLimitRiskOnly: newValue.selectedWorkLimitRisk,
      projectHasFileFilter: newValue.selectedHasFiles,
      statusFilterSelection: FolderFilterStatus.Custom,
    }));
  };

  const handleApplyFilter = () => {
    onApply(state);
  };

  const handleCloseModal = () => {
    onCancel();
  };

  const handleResetModal = () => {
    setState(folderDropdownResetValues);
  };

  return (
    <LpModal
      className="lp-custom-data-filter-modal"
      header={
        <span>
          <LpIcon className="icon" color={gray700} icon={filterLight} size="1x" /> Custom Project Filter
        </span>
      }
      content={
        <div className="lp-custom-data-filter-modal-content">
          <>
            <ProjectStatusList
              onChange={onProjectStatusFilterChange}
              value={state.projectStatusFilter ?? FolderStatusFilter.All}
            />
            <OtherPropertiesOptionsTable
              customFields={customFields}
              onChange={onOtherPropertiesChange}
              showHasFilesRow={!!currentWorkspaceHasFileUploads}
              title="Other Project Properties"
              value={{
                createdByUserId: state.projectCreatedByUserId ?? undefined,
                nameContainsValue: state.projectNameFilter ?? '',
                otherCustomFieldProperties: state.projectCustomFieldFilters ?? [],
                selectedAtRisk: state.projectScheduleRiskOnly ?? false,
                selectedWorkLimitRisk: state.projectWorkLimitRiskOnly ?? false,
                selectedAsap: false /* Does not apply to projects */,
                selectedHasFiles: state.projectHasFileFilter ?? false,
              }}
            />
          </>
        </div>
      }
      actions={
        <div className="lp-custom-data-filter-modal__actions-section">
          <Button
            className="lp-custom-data-filter-modal__actions-section-reset-button"
            content="Reset all"
            onClick={handleResetModal}
          />
          <span className="lp-custom-data-filter-modal__actions-section-apply-and-cancel-button">
            <Button
              className="lp-custom-data-filter-modal__actions-section-cancel-button"
              content="Cancel"
              onClick={handleCloseModal}
            />
            <Button
              className="lp-custom-data-filter-modal__actions-section-apply-button"
              content="Apply"
              primary
              onClick={handleApplyFilter}
            />
          </span>
        </div>
      }
    />
  );
};
