import classNames from 'classnames';

export enum AvatarMargin {
  XS = 'xs',
  Small = 'small',
}

export enum AvatarSize {
  ExtraSmall = 'xs',
  ExtraSmall_1_2 = 'xs-1-2',
  Small_1_5 = 'small-1-5',
  Small_2_0 = 'small-2',
  Small_2_4 = 'small-2-4',
  Medium = 'medium',
  Medium_4 = 'medium-4',
  Medium_4_5 = 'medium-4-5',
  Large_7_5 = 'large-7-5',
  Large_9 = 'large_9',
  XL = 'xl',
}

const customizeAvatarClassNames = ({
  size,
  className,
  round = false,
  marginRight = AvatarMargin.Small,
  withBorderRadius = true,
}: {
  className?: string;
  size?: AvatarSize | undefined;
  round?: boolean;
  marginRight?: AvatarMargin | boolean;
  withBorderRadius?: Boolean;
}) => {
  const avatarImageSize = () => {
    switch (size) {
      case AvatarSize.ExtraSmall:
        return 'avatar-size__xs';
      case AvatarSize.ExtraSmall_1_2:
        return 'avatar-size__xs-1-2';
      case AvatarSize.Small_1_5:
        return 'avatar-size__small-1-5';
      case AvatarSize.Small_2_0:
        return 'avatar-size__small-2';
      case AvatarSize.Small_2_4:
        return 'avatar-size__small-2-4';
      case AvatarSize.Medium:
        return 'avatar-size__med';
      case AvatarSize.Medium_4:
        return 'avatar-size__med-4';
      case AvatarSize.Medium_4_5:
        return 'avatar-size__med-4-5';
      case AvatarSize.Large_7_5:
        return 'avatar-size__large-7-5';
      case AvatarSize.Large_9:
        return 'avatar-size__large-9';
      case AvatarSize.XL:
        return 'avatar-size__xl';
      default:
        return 'avatar-size__med';
    }
  };

  const avatarImageMarginRight = () => {
    if (!marginRight) {
      return 'avatar__margin-none';
    }

    switch (marginRight) {
      case AvatarMargin.XS:
        return 'avatar__margin-right-xs';
      case AvatarMargin.Small:
        return 'avatar__margin-right-sm';
      default:
        return 'avatar__margin-right-sm';
    }
  };

  const classNamesForAvatar = classNames(className, 'avatar', avatarImageSize(), avatarImageMarginRight(), {
    avatar__round: round,
    'avatar__border-radius': withBorderRadius,
  });

  return classNamesForAvatar;
};

export default customizeAvatarClassNames;
