import { roundAndTruncateTrailing, truncation } from './rounding';

export const formatAsHours = (value: number): string => `${roundAndTruncateTrailing(value)}h`;

const round = (value: number, precision: number): string =>
  value > 100 ? Math.ceil(value).toString() : truncation(value, precision);

export const formatAsHoursWithoutTruncation = (value: number): string => `${round(value, 2)} h`;

export const formatAsHoursWithNoRounding = (value: number, precision = 2) => `${truncation(value, precision)}h`;
