import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const globalIdRegex = /\d+/g;

const GoogleAnalyticsTracker = ({ children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      // Remove ids to anonymize data and group similar page views together. /organizations/123/workspaces turns into
      // /organizations/:id/workspaces. We can't use react-router for this, as the full route hasn't been matched this
      // early in the render cycle.
      const currentUrl = location.pathname.replace(globalIdRegex, ':id');

      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: `${window.location.origin}${currentUrl}`,
        page_path: currentUrl,
      });
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default GoogleAnalyticsTracker;
