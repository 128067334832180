import { all, put, takeEvery, delay } from 'typed-redux-saga';

import { loadingAppComplete, setReloadOnNextNavigation } from 'features/common/current/slice';
import { MILLISECONDS_IN_HOUR } from 'lib/constants';

function* setReloadOnNextNavigationAfterTimeoutSaga() {
  yield* delay(MILLISECONDS_IN_HOUR);
  yield* put(setReloadOnNextNavigation());
}

export default function* rootSaga() {
  yield* all([takeEvery(loadingAppComplete, setReloadOnNextNavigationAfterTimeoutSaga)]);
}
