import { matchPath } from 'react-router';

import { ItemType } from 'daos/enums';
import { frontend } from 'lib/urls';

export const isAncestorItemScheduleBar = ({
  itemType,
  pathname,
}: {
  itemType: ItemType | undefined;
  pathname: string;
}) => {
  if (!itemType) {
    return false;
  }

  const isPortfolioRoute = !!matchPath(pathname, {
    path: [
      frontend.scheduledCollection.pattern,
      frontend.pendingCollection.pattern,
      frontend.archiveCollection.pattern,
    ],
  });

  return (
    itemType === ItemType.PACKAGES ||
    itemType === ItemType.FOLDERS ||
    (!isPortfolioRoute && itemType === ItemType.PROJECTS)
  );
};
