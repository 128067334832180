import EstimateMappingSection from 'features/jira_project/modal/sections/estimate_mapping_section';
import DateAndFieldMappingSection from 'features/jira_project/modal/sections/field_mapping_section';
import JiraInstanceAndCredentialsSection from 'features/jira_project/modal/sections/jira_instance_and_credentials_sections';
import ProjectAndIssues from 'features/jira_project/modal/sections/project_and_issues';
import SummarySection from 'features/jira_project/modal/sections/summary_section';
import SyncSettings from 'features/jira_project/modal/sections/sync_settings_section';
import WelcomeSection from 'features/jira_project/modal/sections/welcome_section';
import { JiraTab, JiraTabKey, TabNames } from 'features/jira_project/modal/types';

const welcomeTab: JiraTab = { component: WelcomeSection, name: TabNames.Welcome, key: JiraTabKey.Welcome };

const instanceAndCredentialsTab: JiraTab = {
  component: JiraInstanceAndCredentialsSection,
  name: TabNames.JiraInstanceAndCredentials,
  key: JiraTabKey.JiraInstance,
};

const projectAndIssuesTab: JiraTab = {
  component: ProjectAndIssues,
  name: TabNames.ProjectAndIssuesFilter,
  key: JiraTabKey.ProjectAndIssuesFilter,
};

const syncSettingsTab: JiraTab = {
  component: SyncSettings,
  name: TabNames.SyncSettings,
  key: JiraTabKey.SyncSettings,
};

const dateAndFieldMappingSectionTab: JiraTab = {
  component: DateAndFieldMappingSection,
  name: TabNames.FieldMapping,
  key: JiraTabKey.FieldMapping,
};

const estimateMappingSectionTab: JiraTab = {
  component: EstimateMappingSection,
  name: TabNames.RemainingEstimates,
  key: JiraTabKey.RemainingEstimates,
};

const summaryTab: JiraTab = { component: SummarySection, name: TabNames.Launch, key: JiraTabKey.Launch };

export const getJiraModalTabs = ({ isEditMode }: { isEditMode: boolean }): ReadonlyArray<JiraTab> => {
  if (isEditMode) {
    return [
      instanceAndCredentialsTab,
      projectAndIssuesTab,
      syncSettingsTab,
      dateAndFieldMappingSectionTab,
      estimateMappingSectionTab,
    ];
  }
  return [
    welcomeTab,
    instanceAndCredentialsTab,
    projectAndIssuesTab,
    syncSettingsTab,
    dateAndFieldMappingSectionTab,
    estimateMappingSectionTab,
    summaryTab,
  ];
};
