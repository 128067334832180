import classNames from 'classnames';
import { useCallback } from 'react';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';

import { StatusFilterGroups } from 'daos/enums';
import SelectionDropdownTriggerButton from 'features/common/inputs/dropdowns/selection_dropdown/dropdown_trigger_button';
import {
  plusCircleSolid,
  circleSolid,
  circleLight,
  checkCircleSolid,
  LpIcon,
  pauseCircleSolid,
  dotCircleSolid,
} from 'features/common/lp_icon';

import 'features/common/inputs/status_filter_menu/index.scss';

interface StatusFilterProps {
  className?: string;
  handleSelectByStatus: (taskStatus: StatusFilterGroups) => void;
  prependedMenuItems?: ReadonlyArray<JSX.Element>;
  selectedTaskStatus?: StatusFilterGroups;
  tabIndex?: number;
}

enum TaskStatusCheckboxFilterToggle {
  UNSELECT_ALL = 'Unselect All',
  ALL = 'All Tasks',
  SCHEDULED = 'Active Tasks',
  ACTIVE = 'Active + On Hold Tasks',
  ON_HOLD = 'On Hold Tasks',
  DONE = 'Done Tasks',
}

interface StatusFilterValues {
  icon: JSX.Element;
  value: StatusFilterGroups;
  text: TaskStatusCheckboxFilterToggle;
}

const itemSpecificValues: Array<StatusFilterValues> = [
  {
    icon: <LpIcon icon={circleLight} size="1x" />,
    value: StatusFilterGroups.None,
    text: TaskStatusCheckboxFilterToggle.UNSELECT_ALL,
  },
  {
    icon: <LpIcon icon={circleSolid} size="1x" />,
    value: StatusFilterGroups.All,
    text: TaskStatusCheckboxFilterToggle.ALL,
  },
  {
    icon: <LpIcon icon={dotCircleSolid} size="1x" />,
    value: StatusFilterGroups.Active,
    text: TaskStatusCheckboxFilterToggle.SCHEDULED,
  },
  {
    icon: <LpIcon icon={plusCircleSolid} size="1x" />,
    value: StatusFilterGroups.ActiveAndOnHold,
    text: TaskStatusCheckboxFilterToggle.ACTIVE,
  },
  {
    icon: <LpIcon icon={pauseCircleSolid} size="1x" />,
    value: StatusFilterGroups.OnHold,
    text: TaskStatusCheckboxFilterToggle.ON_HOLD,
  },
  {
    icon: <LpIcon icon={checkCircleSolid} size="1x" />,
    value: StatusFilterGroups.Done,
    text: TaskStatusCheckboxFilterToggle.DONE,
  },
];

const StatusFilter = ({
  className,
  handleSelectByStatus,
  prependedMenuItems = [],
  selectedTaskStatus,
  tabIndex,
}: StatusFilterProps) => {
  const filterItems = useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>, { value }: DropdownItemProps) => {
      handleSelectByStatus(value as StatusFilterGroups);
    },
    [handleSelectByStatus]
  );

  const filterItemsOnClose: DropdownProps['onClose'] = useCallback(
    (event: React.SyntheticEvent) => {
      const target: HTMLInputElement = event?.target as HTMLInputElement;
      if (target?.textContent === TaskStatusCheckboxFilterToggle.UNSELECT_ALL) {
        handleSelectByStatus(StatusFilterGroups.None);
      }
    },
    [handleSelectByStatus]
  );

  return (
    <Dropdown
      className={classNames('status-filter', className)}
      icon={null}
      onClose={filterItemsOnClose}
      selectOnNavigation={false}
      tabIndex={tabIndex}
      trigger={<SelectionDropdownTriggerButton className="status-filter__button" />}
      value={selectedTaskStatus}
    >
      <Dropdown.Menu>
        {prependedMenuItems}
        {itemSpecificValues.map((option) => {
          return (
            <Dropdown.Item
              key={option.text}
              text={
                <span>
                  {option.icon} {option.text}
                </span>
              }
              onClick={filterItems}
              value={option.value}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default StatusFilter;
