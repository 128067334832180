import { OnClickViewOption } from 'features/dashboards_v2/widget_settings/types';

export function serializeOnClickViewOption(onClickViewOption: OnClickViewOption): string {
  const { onClickView, onClickViewFieldId } = onClickViewOption;

  return JSON.stringify({ onClickView, onClickViewFieldId });
}

export function deserializeOnClickViewOption(str: string): OnClickViewOption | null {
  const obj = JSON.parse(str);
  const { onClickView, onClickViewFieldId } = obj;
  const isValidWidgetConfig = onClickView !== undefined && onClickViewFieldId !== undefined;

  return isValidWidgetConfig ? { onClickView, onClickViewFieldId } : null;
}
