import { FormatterProps } from 'react-data-grid';

import LpLink from 'containers/shared/lp_link';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { EntityRow } from 'features/common/data_grid/types';
import { LpIcon, slack } from 'features/common/lp_icon';
import { SlackIntegrationDetail } from 'features/integrations/slack/slack_integration_administration/use_slack_integrations_for_org';
import { useLocalizedFormats } from 'hooks/use_locale_from_user';

type RowProps = EntityRow<SlackIntegrationDetail, unknown>;

export const OrgUserNameFormatter = ({ row: { orgUserId, profileUrl, orgUserName } }: FormatterProps<RowProps>) => (
  <div className="slack-integration-administration__avatar-row">
    <UserAvatar orgUserId={orgUserId} size={AvatarSize.Medium} />
    <LpLink to={profileUrl}>{orgUserName}</LpLink>
  </div>
);
export const SlackWorkspaceNameFormatter = ({ row: { slackWorkspaceName } }: FormatterProps<RowProps>) => (
  <span>
    <LpIcon icon={slack} /> {slackWorkspaceName}
  </span>
);
export const ConnectedOnKeyFormatter = ({ row: { createdAt } }: FormatterProps<RowProps>) => {
  const { formatLocalDateWithTime } = useLocalizedFormats();

  return <span>{formatLocalDateWithTime(createdAt)}</span>;
};
