import classNames from 'classnames';
import { Button } from 'semantic-ui-react';

import { LpIcon, checkSquareSolid, squareRegular } from 'features/common/lp_icon';

import './board_hide_empty.scss';

interface BoardHideEmptyProps {
  disabled: boolean;
  handleHideEmptyClick: () => void;
  hideEmpty: boolean;
}

export const BoardHideEmpty = ({ disabled, handleHideEmptyClick, hideEmpty }: BoardHideEmptyProps) => (
  <Button
    disabled={disabled}
    onClick={handleHideEmptyClick}
    className={classNames('board-hide-empty', {
      'board-hide-empty--active': hideEmpty,
    })}
    size="small"
    content={
      <span>
        <LpIcon icon={hideEmpty ? checkSquareSolid : squareRegular} /> Hide Empty
      </span>
    }
  />
);
