import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrganizationUserFilter } from 'daos/filter_properties';
import { OrganizationUser } from 'daos/model_types';
import { OrganizationUserDao } from 'daos/organization_user';
import { OrganizationUserSlackIntegrationDao } from 'daos/organization_user_slack_integration';
import { filterAnd, filterEq, filterNull } from 'daos/shared';
import { awaitRequestFinish } from 'lib/api';
import { frontend } from 'lib/urls';
import { getOrganizationUserSlackIntegrationsByOrgUserId } from 'redux/entities/selectors/organization_user_slack_integration';

export interface SlackIntegrationDetail {
  id: number;
  name: string;
  orgUserName: string;
  orgUserId: number;
  profileUrl: string;
  slackWorkspaceName: string;
  createdAt: string;
}

const useSlackIntegrationsForOrg = (organizationId: number, organizationUserId: number) => {
  const dispatch = useDispatch();
  const orgUserSlackIntegrations = useSelector(getOrganizationUserSlackIntegrationsByOrgUserId);
  const [orgUsers, setOrgUsers] = useState<ReadonlyArray<OrganizationUser>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrgUsers = useCallback(() => {
    const { uuid } = dispatch(
      OrganizationUserDao.fetchAll(
        { organizationId },
        {
          filter: filterAnd(
            filterNull(OrganizationUserFilter.DisconnectedAt, true),
            filterEq(OrganizationUserFilter.HasSlackIntegration, true)
          ),
          include: { includeSlackIntegration: true },
        }
      )
    );
    dispatch(
      awaitRequestFinish<ReadonlyArray<OrganizationUser>>(uuid, {
        onSuccess: ({ data }) => setOrgUsers(data),
        onFinish: () => setIsLoading(false),
      })
    );
  }, [dispatch, organizationId]);

  const disconnect = (ids: Array<number>) => {
    const { uuid } = dispatch(
      OrganizationUserSlackIntegrationDao.destroyBulk({ organizationId, organizationUserId }, ids)
    );

    dispatch(awaitRequestFinish(uuid, { onSuccess: fetchOrgUsers }));
  };

  useEffect(() => {
    fetchOrgUsers();
  }, [fetchOrgUsers]);

  return {
    isLoading,
    orgUserSlackIntegrations: orgUsers.reduce((acc: Array<SlackIntegrationDetail>, orgUser) => {
      const slackIntegration = orgUserSlackIntegrations[orgUser.id];

      if (slackIntegration) {
        acc.push({
          id: slackIntegration.id,
          name: orgUser.username,
          orgUserName: orgUser.username,
          orgUserId: orgUser.id,
          profileUrl: frontend.organizationHubUser.url({ organizationId, organizationUserId: orgUser.id }),
          slackWorkspaceName: slackIntegration.slackTeamName,
          createdAt: slackIntegration.createdAt,
        });
      }

      return acc;
    }, []),
    disconnect,
  };
};

export default useSlackIntegrationsForOrg;
