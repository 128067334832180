import { Route, Switch } from 'react-router';

import { OrganizationSsoSettings } from 'daos/organization_sso_settings';
import PageNotFound from 'features/common/errors/page_not_found';
import { frontend } from 'lib/urls';

import { ManageSso } from './manage';
import { SetupSso } from './setup';

const SingleSignOnRoutes = ({
  fetchSSO,
  organizationSsoSettings,
}: {
  fetchSSO: () => void;
  organizationSsoSettings: OrganizationSsoSettings;
}) => {
  return (
    <Switch>
      <Route
        path={frontend.singleSignOnSetup.pattern}
        render={() => <SetupSso organizationSsoSettings={organizationSsoSettings} fetchSSO={fetchSSO} />}
      />

      <Route
        path={frontend.singleSignOnManage.pattern}
        render={() => <ManageSso organizationSsoSettings={organizationSsoSettings} fetchSSO={fetchSSO} />}
      />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default SingleSignOnRoutes;
