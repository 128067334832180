import classNames from 'classnames';

import { LpIcon, ellipsisVSolid } from 'features/common/lp_icon';

import './action_ellipsis.scss';

interface ActionEllipsesProps {
  spanClassNames?: string;
  iconClassNames?: string;
}

const ActionEllipsis = ({ spanClassNames, iconClassNames }: ActionEllipsesProps) => {
  return (
    <span className={classNames(spanClassNames, 'lp-action-ellipsis')}>
      <LpIcon icon={ellipsisVSolid} size="1x" className={classNames(iconClassNames, `lp-action-ellipsis__icon`)} />
    </span>
  );
};

export default ActionEllipsis;
