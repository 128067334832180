import { ReactNode } from 'react';

import 'features/jira_project/modal/jira_project_modal.scss';
import { LpIcon, questionCircleSolid } from 'features/common/lp_icon';
import LpPopUp from 'features/common/lp_popup';
import { gray600 } from 'style/variables';

export const JiraProjectModalInfo = ({
  title,
  helperText,
  testId,
  button,
  popUpText,
}: {
  title?: string;
  helperText?: string | ReactNode;
  testId?: string;
  button?: ReactNode;
  popUpText?: string;
}) => (
  <section className="jira-project-modal__content-info" data-testid={testId}>
    <div className="jira-project-modal__content-info-title-container">
      <span className="jira-project-modal__content-info-title">{title}</span>
      {popUpText && (
        <LpPopUp
          placement="top"
          className="jira-project-modal__content-info-title-popup-container"
          content={popUpText}
          trigger={<LpIcon color={gray600} icon={questionCircleSolid} />}
        />
      )}
    </div>
    {helperText && (
      <div className="jira-project-modal__content-info-helper-container">
        <div className="jira-project-modal__content-info-helper-text">{helperText}</div>
      </div>
    )}
    {button && <div className="jira-project-modal__content-info-helper-button-container">{button}</div>}
  </section>
);
