import classNames from 'classnames';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, ImageProps } from 'semantic-ui-react';

import FileUploaderScreen from 'containers/shared/file_upload_screen';
import { AvatarDao } from 'daos/avatar';
import { OrganizationUserDao } from 'daos/organization_user';
import { AvatarSize } from 'features/common/avatars/avatar_helpers';
import { UserAvatar } from 'features/common/avatars/user_avatar';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import { LpIcon, cameraRetroLight } from 'features/common/lp_icon';
import { awaitRequestFinish } from 'lib/api';
import { getOrganizationUserForId } from 'redux/entities/selectors/user';

import UserAvatarCrop from './user_avatar_crop';

import './editable_user_avatar.scss';

interface EditableUserAvatarProps {
  className?: string;
  edit?: boolean;
  orgUserId: number;
  size?: ImageProps['size'];
}

const EditableUserAvatar = ({ edit, orgUserId: organizationUserId, className }: EditableUserAvatarProps) => {
  const dispatch = useDispatch();

  const orgUser = useSelector((state) => getOrganizationUserForId(state, organizationUserId));
  const organizationId = useSelector(getCurrentOrganizationId);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [croppedImage, setCroppedImage] = useState<string | undefined>(undefined);
  const [fileTypeExtension, setFileExtension] = useState<string | undefined>(undefined);
  const [selectedSrc, setSelectedSrc] = useState<string | undefined>(undefined);
  const [hasUploadErrors, setHasUploadErrors] = useState(false);

  const avatarUrl = orgUser?.avatarUrl;

  const fetchOrgUser = useCallback(() => {
    const { uuid } = dispatch(OrganizationUserDao.fetch({ organizationId, organizationUserId }));
    dispatch(awaitRequestFinish(uuid, {}));
  }, [dispatch, organizationId, organizationUserId]);

  const handleReload = useCallback(() => {
    setIsLoading(false);
    fetchOrgUser();
  }, [fetchOrgUser]);

  const handleAvatarUpload = useCallback(
    async (croppedImageUrl: string) => {
      setHasUploadErrors(false);
      setIsLoading(true);

      const blob = await fetch(croppedImageUrl).then((r) => r.blob());

      const { uuid } = dispatch(AvatarDao.update({ organizationId, organizationUserId }, blob));

      dispatch(
        awaitRequestFinish<undefined>(uuid, {
          onError: () => setHasUploadErrors(true),
          onSuccess: () => handleReload(),
        })
      );

      setSelectedSrc(undefined);
      resetFileArray();
      URL.revokeObjectURL(croppedImageUrl);
    },
    [dispatch, handleReload, organizationId, organizationUserId]
  );

  useEffect(() => {
    if (croppedImage) {
      handleAvatarUpload(croppedImage);
    }
  }, [croppedImage, handleAvatarUpload]);

  const handleFormClose = () => setIsLoading(false);

  const handleDelete = () => {
    setIsLoading(false);

    const { uuid } = dispatch(AvatarDao.delete({ organizationId, organizationUserId }));

    dispatch(
      awaitRequestFinish(uuid, {
        onFinish: () => {
          fetchOrgUser();
        },
      })
    );
  };

  const resetFileArray = () => {
    const resetFile = document.getElementById('upload') as HTMLInputElement;
    resetFile.value = '';
  };

  const handleNewImageCrop = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      setFileExtension(files[0].type);
      setSelectedSrc(URL.createObjectURL(files[0]));
      setCroppedImage(selectedSrc);
    }
  };

  const handleCloseModal = () => {
    setSelectedSrc(undefined);
    setIsLoading(false);
    setFileExtension(undefined);
    setCroppedImage(undefined);
    resetFileArray();
  };

  const avatarEditOptions = () => {
    if (avatarUrl) {
      return (
        <Dropdown.Menu>
          <Dropdown.Item
            content={
              <label htmlFor="upload" className="avatar-edit-menu__dropdown">
                New Photo
                <input
                  hidden
                  id="upload"
                  name="file"
                  type="file"
                  accept="image/jpeg, image/gif, image/png"
                  onChange={handleNewImageCrop}
                />
              </label>
            }
          />

          <Dropdown.Item text="Remove Photo" onClick={handleDelete} />
        </Dropdown.Menu>
      );
    }

    // user on image click will automatically open the image select
    return (
      <label htmlFor="upload" className="avatar-edit-menu__upload-container">
        <LpIcon className="avatar-edit-menu__fa-icon" icon={cameraRetroLight} size="3x" />
        <input
          hidden
          id="upload"
          name="file"
          type="file"
          accept="image/jpeg, image/gif, image/png"
          onChange={handleNewImageCrop}
        />
      </label>
    );
  };

  return (
    <>
      {edit ? (
        <Dropdown
          className="avatar-edit-menu"
          icon={null}
          trigger={
            <div className="avatar-edit-menu__upload-container">
              <LpIcon className="avatar-edit-menu__fa-icon" icon={cameraRetroLight} size="3x" />
              <UserAvatar
                className={classNames('avatar-edit-menu__img', className)}
                orgUserId={organizationUserId}
                size={AvatarSize.XL}
                marginRight={false}
              />
            </div>
          }
        >
          {avatarEditOptions()}
        </Dropdown>
      ) : (
        <UserAvatar
          className={classNames('avatar-edit-menu__img', className)}
          orgUserId={organizationUserId}
          size={AvatarSize.XL}
          marginRight={false}
        />
      )}

      {selectedSrc && fileTypeExtension && (
        <UserAvatarCrop
          handleAvatarUpload={handleAvatarUpload}
          handleCloseModal={handleCloseModal}
          fileTypeExtension={fileTypeExtension}
          src={selectedSrc}
        />
      )}

      {isLoading && <FileUploaderScreen hasErrors={hasUploadErrors} onClose={handleFormClose} />}
    </>
  );
};

export default EditableUserAvatar;
