import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';

import { Permission } from 'daos/enums';
import { OrganizationUserDao } from 'daos/organization_user';
import LpInput from 'features/common/inputs/lp_input';
import { clearLocalValidationErrorForKey, setLocalValidationError } from 'features/common/local_validation/slice';
import { useHasAccess } from 'hooks/use_has_access';
import { getOrganizationUserForId, getWorkspaceUserForOrganizationUserId } from 'redux/entities/selectors/user';

interface UserNameRowProps {
  orgUserId: number;
  updateOrgUser: ({ username }: { username: string }) => void;
}

const UserNameRow = ({ orgUserId, updateOrgUser }: UserNameRowProps) => {
  const dispatch = useDispatch();

  const orgUser = useSelector((state) => getOrganizationUserForId(state, orgUserId));
  const wsUser = useSelector((state) => getWorkspaceUserForOrganizationUserId(state, orgUserId));

  const hasEditAccess = useHasAccess(Permission.EDIT, OrganizationUserDao.id(orgUserId));

  const handleUsernameChange = (username: string) => {
    if (username.trim() === '') {
      dispatch(setLocalValidationError({ key: 'username', message: 'Username is required' }));
      return;
    }

    dispatch(clearLocalValidationErrorForKey('username'));
    updateOrgUser({ username });
  };

  if (!orgUser || !wsUser) {
    return null;
  }

  return (
    <Table.Row>
      <Table.Cell>Username</Table.Cell>
      <Table.Cell className="user-profile__table-cell-input">
        <LpInput
          className="user-profile__table-input"
          transparent={true}
          disabled={!hasEditAccess}
          value={orgUser.username}
          onChange={handleUsernameChange}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default UserNameRow;
