import { createCachedSelector } from 're-reselect';

import { getIsNonNoteCustomFieldApplicableForItemType } from 'containers/shared/custom_column/helpers';
import { ItemType } from 'daos/enums';
import { CustomField } from 'daos/model_types';
import { ColumnsSelection } from 'daos/types';
import { readonlyArray } from 'lib/readonly_record';
import {
  getAllWorkspaceNonNoteCustomFields,
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
} from 'redux/entities/selectors/custom_field';
import { createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { getListWidgetsColumns, getPppsColumns } from 'redux/entities/selectors/system_settings';
import { RootState } from 'redux/root_reducer';

/** get all columns (static and custom) from system settings  */
const getItemColumns = (state: RootState, workspaceId: number, itemType: ItemType, forPPPGrid: boolean) => {
  return forPPPGrid
    ? getPppColumnsForItemType(state, workspaceId, itemType)
    : getListWidgetsColumnsForItemType(state, workspaceId, itemType);
};

export const getPackageItemColumns = (state: RootState, workspaceId: number, forPPPGrid = false) =>
  getItemColumns(state, workspaceId, ItemType.PACKAGES, forPPPGrid);

export const getProjectItemColumns = (state: RootState, workspaceId: number, forPPPGrid = false) =>
  getItemColumns(state, workspaceId, ItemType.PROJECTS, forPPPGrid);

export const getSubfolderItemColumns = (state: RootState, workspaceId: number, forPPPGrid = false) =>
  getItemColumns(state, workspaceId, ItemType.FOLDERS, forPPPGrid);

export const getTaskItemColumns = (state: RootState, workspaceId: number, forPPPGrid = false) =>
  getItemColumns(state, workspaceId, ItemType.TASKS, forPPPGrid);

export const getAssignmentItemColumns = (state: RootState, workspaceId: number, forPPPGrid = false) =>
  getItemColumns(state, workspaceId, ItemType.ASSIGNMENTS, forPPPGrid);

/** columns and ACTIVE custom fields */
export const getPackageItemAndActiveFieldColumns = (state: RootState) =>
  getListWidgetsAndActiveFields(state, ItemType.PACKAGES);
export const getProjectItemAndActiveFieldColumns = (state: RootState) =>
  getListWidgetsAndActiveFields(state, ItemType.PROJECTS);
export const getSubfolderItemAndActiveFieldColumns = (state: RootState) =>
  getListWidgetsAndActiveFields(state, ItemType.FOLDERS);
export const getTaskItemAndActiveFieldColumns = (state: RootState) =>
  getListWidgetsAndActiveFields(state, ItemType.TASKS);
export const getAssignmentItemAndActiveFieldColumns = (state: RootState) =>
  getListWidgetsAndActiveFields(state, ItemType.ASSIGNMENTS);

/** columns and ALL custom fields */
export const getPackageItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.PACKAGES);
export const getProjectItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.PROJECTS);
export const getSubfolderItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.FOLDERS);
export const getTaskItemAndAllFieldColumns = (state: RootState) =>
  getListWidgetsColumnsAndAllFields(state, ItemType.TASKS);

export const getListWidgetsColumnsForItemType = createCachedSelector(
  (state: RootState, workspaceId: number, key: keyof ColumnsSelection) =>
    getListWidgetsColumns(state, key, workspaceId),
  (columns) => columns.map((column) => column.toString())
)(createCacheByIdConfig());

export const getPppColumnsForItemType = createCachedSelector(
  (state: RootState, workspaceId: number, key: keyof ColumnsSelection) => getPppsColumns(state, key, workspaceId),
  (columns) => columns.map((column) => column.toString())
)(createCacheByIdConfig());

export const getListWidgetsAndActiveFields = createCachedSelector(
  (state: RootState, key: keyof ColumnsSelection) => getListWidgetsColumns(state, key),
  (state: RootState, key: keyof ColumnsSelection) => getActiveSupportedCustomFields(state, key),
  (columns, fields) => {
    const namesOfColumns = columns.map((column) => column.toString()) ?? [];
    const fieldIdStrings = fields.map((field) => field.id.toString());
    return readonlyArray(namesOfColumns.concat(fieldIdStrings));
  }
)(createCacheByIdConfig());

const getListWidgetsColumnsAndAllFields = createCachedSelector(
  (state: RootState, key: keyof ColumnsSelection) => getListWidgetsColumns(state, key),
  (state: RootState, key: keyof ColumnsSelection) => getAllSupportedCustomFields(state, key),
  (columns, fields) => {
    const namesOfColumns = columns.map((column) => column.toString()) ?? [];
    const fieldIdStrings = fields.map((field) => field.id.toString());
    return readonlyArray(namesOfColumns.concat(fieldIdStrings));
  }
)(createCacheByIdConfig());

const getAllSupportedCustomFields = createCachedSelector(
  getAllWorkspaceNonNoteCustomFields,
  (_: RootState, itemType: ItemType) => itemType,
  getItemTypeSupportedFields
)(createCacheByIdConfig());

const getActiveSupportedCustomFields = createCachedSelector(
  getNonSpecialActiveCustomFieldsForCurrentWorkspace,
  (_: RootState, itemType: ItemType) => itemType,
  getItemTypeSupportedFields
)(createCacheByIdConfig());

function getItemTypeSupportedFields(fields: ReadonlyArray<CustomField>, itemType: ItemType) {
  return readonlyArray(fields.filter((field) => getIsNonNoteCustomFieldApplicableForItemType(field, itemType)));
}
