import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import {
  ScheduleBarDateOffsetPercents,
  ScheduleBarLabelStyles,
  ScheduleBarStyles,
} from 'features/common/schedule_bar/types';
import { calcItemDataForStatus } from 'hooks/use_item_data_for_status';
import { ScheduleViewType, useItemsDateRange } from 'hooks/use_items_date_range';
import { dateDifference } from 'lib/localization';
import { DateRange, getItemForId, isAssignment, isTask } from 'redux/entities/selectors/item';
import { getItemMetricsForId } from 'redux/entities/selectors/item_metric';
import { getTaskStatusForId } from 'redux/entities/selectors/task_status';

const constrainToPercentageRange = (value: number) => Math.max(0, Math.min(100, value));

const calculateDateOffsetForDateRange = (dateRange: DateRange, day: string | null | undefined) => {
  if (!day) {
    return 0;
  }

  const startToFinishWidth = dateDifference(dateRange.start, dateRange.finish);
  const startToDateWidth = dateDifference(dateRange.start, moment(day));

  return constrainToPercentageRange(startToFinishWidth ? (startToDateWidth / startToFinishWidth) * 100 : 0);
};

export const calcItemDataForScheduleBar = ({
  assignmentEffectiveTargetFinish,
  dateRange,
  doneDate,
  effectiveTargetFinish,
  effectiveTargetStart,
  expectedFinish,
  expectedStart,
  isAssignmentItem,
  isClipped,
  isDone,
  isDoneAfterEffectiveTargetFinish,
  isEffectivelyOnHold,
  isLate,
  isLateRisk,
  isOnHold,
  isScheduled,
  isTaskItem,
  itemDateRangeFinish,
  itemDateRangeStart,
  latestFinish,
  rollupEarliestActiveTargetFinish,
  rollupLatestTargetFinish,
  targetFinish,
  targetStart,
}: {
  assignmentEffectiveTargetFinish: string | null | undefined;
  dateRange: DateRange;
  doneDate: string | null | undefined;
  effectiveTargetFinish: string | null | undefined;
  effectiveTargetStart: string | null | undefined;
  expectedFinish: string | null | undefined;
  expectedStart: string | null | undefined;
  isAssignmentItem: boolean;
  isClipped: boolean;
  isDone: boolean;
  isDoneAfterEffectiveTargetFinish: boolean;
  isEffectivelyOnHold: boolean;
  isLate: boolean;
  isLateRisk: boolean;
  isOnHold: boolean;
  isScheduled: boolean;
  isTaskItem: boolean;
  itemDateRangeFinish: moment.Moment;
  itemDateRangeStart: moment.Moment;
  latestFinish: string | null | undefined;
  rollupEarliestActiveTargetFinish: string | null | undefined;
  rollupLatestTargetFinish: string | null | undefined;
  targetFinish: string | null | undefined;
  targetStart: string | null | undefined;
}): {
  scheduleBarDateOffsetPercents: ScheduleBarDateOffsetPercents;
  scheduleBarStyles: ScheduleBarStyles;
  scheduleBarLabelStyles: ScheduleBarLabelStyles;
  showRollupEarliestActiveTargetFinish: boolean;
  showRollupLatestTargetFinish: boolean;
} => {
  const targetStartOffsetPercent = calculateDateOffsetForDateRange(dateRange, targetStart);
  const targetFinishOffsetPercent = calculateDateOffsetForDateRange(dateRange, targetFinish);

  const effectiveStartOffsetPercent = calculateDateOffsetForDateRange(dateRange, effectiveTargetStart);
  const effectiveFinishOffsetPercent = calculateDateOffsetForDateRange(dateRange, effectiveTargetFinish);

  const rollupEarliestActiveTargetFinishOffsetPercent = calculateDateOffsetForDateRange(
    dateRange,
    rollupEarliestActiveTargetFinish
  );
  const rollupLatestTargetFinishOffsetPercent = calculateDateOffsetForDateRange(dateRange, rollupLatestTargetFinish);

  const expectedStartOffsetPercent = calculateDateOffsetForDateRange(dateRange, expectedStart);
  const expectedFinishOffsetPercent = calculateDateOffsetForDateRange(dateRange, expectedFinish);
  const latestFinishOffsetPercent = calculateDateOffsetForDateRange(dateRange, latestFinish);

  const doneDateOffsetPercent = calculateDateOffsetForDateRange(dateRange, doneDate);
  const onHoldOffsetPercent = constrainToPercentageRange(
    isOnHold || isEffectivelyOnHold ? Math.max(effectiveStartOffsetPercent, effectiveFinishOffsetPercent) : 0
  );
  const assignmentEffectiveTargetFinishOffsetPercent = calculateDateOffsetForDateRange(
    dateRange,
    assignmentEffectiveTargetFinish
  );

  const showRollupEarliestActiveTargetFinish =
    isScheduled &&
    !!rollupEarliestActiveTargetFinish &&
    rollupEarliestActiveTargetFinish !== targetFinish &&
    moment(rollupEarliestActiveTargetFinish).isSameOrBefore(itemDateRangeStart);

  const showRollupLatestTargetFinish =
    isScheduled &&
    !!rollupLatestTargetFinish &&
    rollupLatestTargetFinish !== targetFinish &&
    moment(rollupLatestTargetFinish).isSameOrAfter(itemDateRangeFinish);

  const targetDatesLeft: Array<number> = [];
  const targetDatesRight: Array<number> = [
    showRollupLatestTargetFinish ? rollupLatestTargetFinishOffsetPercent : 0,
    targetFinishOffsetPercent,
    targetStart && targetStart !== effectiveTargetStart ? effectiveStartOffsetPercent : 0,
    expectedStartOffsetPercent,
    isDone ? doneDateOffsetPercent : 0,
  ];

  if (isScheduled) {
    targetDatesLeft.push(
      Math.max(
        showRollupEarliestActiveTargetFinish ? rollupEarliestActiveTargetFinishOffsetPercent : 100,
        targetFinish ? targetFinishOffsetPercent : 100
      )
    );
  }
  if (isDone) {
    if (isDoneAfterEffectiveTargetFinish) {
      targetDatesLeft.push(effectiveTargetFinish ? effectiveFinishOffsetPercent : 100);
    } else {
      targetDatesLeft.push(doneDateOffsetPercent);
    }
  } else if (targetStart) {
    targetDatesLeft.push(targetStartOffsetPercent, effectiveTargetFinish ? effectiveFinishOffsetPercent : 100);
  } else {
    targetDatesLeft.push(
      expectedStart ? expectedStartOffsetPercent : 100,
      effectiveTargetFinish ? effectiveFinishOffsetPercent : 100
    );
  }

  const targetDateRange = {
    left: Math.min(...targetDatesLeft),
    right: Math.max(...targetDatesRight),
  };

  const effectiveDatesLeft: Array<number> = [];
  if (targetStart && effectiveTargetStart) {
    effectiveDatesLeft.push(
      showRollupEarliestActiveTargetFinish ? rollupEarliestActiveTargetFinishOffsetPercent : 100,
      effectiveStartOffsetPercent,
      effectiveTargetFinish ? effectiveFinishOffsetPercent : 100
    );
  } else if (isDone) {
    if (isDoneAfterEffectiveTargetFinish) {
      effectiveDatesLeft.push(effectiveFinishOffsetPercent);
    } else {
      effectiveDatesLeft.push(doneDateOffsetPercent);
    }
  } else if (showRollupEarliestActiveTargetFinish) {
    effectiveDatesLeft.push(rollupEarliestActiveTargetFinishOffsetPercent);
  } else {
    effectiveDatesLeft.push(expectedStart ? expectedStartOffsetPercent : 100);
  }

  const effectiveDateRange = {
    left: Math.min(...effectiveDatesLeft),
    right: Math.max(effectiveTargetFinish ? effectiveFinishOffsetPercent : expectedStartOffsetPercent),
  };

  const scheduledRange = { left: expectedStartOffsetPercent, right: latestFinishOffsetPercent };
  const scheduledRiskRange = { left: expectedFinishOffsetPercent, right: latestFinishOffsetPercent };
  const scheduledAsapRange = { left: expectedStartOffsetPercent, right: effectiveStartOffsetPercent };
  const scheduledLateRange = {
    left: Math.max(
      assignmentEffectiveTargetFinishOffsetPercent,
      effectiveFinishOffsetPercent,
      expectedStartOffsetPercent
    ),
    right: effectiveTargetFinish || isAssignmentItem ? latestFinishOffsetPercent : 0,
  };

  const scheduleLateRangeWidthUnset =
    (constrainToPercentageRange(scheduledLateRange.right - scheduledLateRange.left) === 0 &&
      (isLate || isLateRisk || isClipped)) ||
    !(isLate || isLateRisk);

  const scheduleLateRangeWidthNotUnsetNotClipped = isClipped
    ? `${constrainToPercentageRange(scheduledLateRange.right - scheduledLateRange.left)}%`
    : `max(var(--min-scheduled-width), ${constrainToPercentageRange(
        scheduledLateRange.right - scheduledLateRange.left
      )}%)`;

  return {
    scheduleBarDateOffsetPercents: {
      targetStartOffsetPercent,
      targetFinishOffsetPercent,

      effectiveStartOffsetPercent,
      effectiveFinishOffsetPercent,

      expectedStartOffsetPercent,
      expectedFinishOffsetPercent,
      latestFinishOffsetPercent,

      doneDateOffsetPercent,
    },
    scheduleBarStyles: {
      targetDateRange: {
        marginLeft: `${targetDateRange.left}%`,
        width: `${constrainToPercentageRange(targetDateRange.right - targetDateRange.left)}%`,
      },
      effectiveDateRange: {
        marginLeft: `${effectiveDateRange.left}%`,
        width: `${constrainToPercentageRange(effectiveDateRange.right - effectiveDateRange.left)}%`,
      },
      scheduledRange: {
        marginLeft: `${scheduledRange.left}%`,
        width: `max(var(--min-scheduled-width), ${constrainToPercentageRange(
          scheduledRange.right - scheduledRange.left
        )}%)`,
      },
      scheduledAsapRange: {
        marginLeft: `${scheduledAsapRange.left}%`,
        width: `${isTaskItem ? '0' : constrainToPercentageRange(scheduledAsapRange.right - scheduledAsapRange.left)}%`,
      },

      scheduledLateRange: {
        marginLeft: `${scheduledLateRange.left}%`,
        width: scheduleLateRangeWidthUnset ? '0px' : scheduleLateRangeWidthNotUnsetNotClipped,
      },

      scheduledRiskRange: {
        marginLeft: `${scheduledRiskRange.left}%`,
        width: `${constrainToPercentageRange(scheduledRiskRange.right - scheduledRiskRange.left)}%`,
      },
      rollupEarliestActiveFinishTick: showRollupEarliestActiveTargetFinish
        ? {
            marginLeft: `${rollupEarliestActiveTargetFinishOffsetPercent}%`,
          }
        : { width: 0 },
      targetFinishTick: targetFinish ? { marginLeft: `${targetFinishOffsetPercent}%` } : { width: 0 },
      rollupLatestTargetFinishTick: showRollupLatestTargetFinish
        ? { marginLeft: `${rollupLatestTargetFinishOffsetPercent}%` }
        : { width: 0 },
      doneIcon: { marginLeft: `calc(${doneDateOffsetPercent}% - (0.5 * var(--done-icon-width)))` },
      onHoldIcon: { left: onHoldOffsetPercent ? `${onHoldOffsetPercent}%` : 0 },
      clippedEffortIcon: {
        marginLeft: `calc(${latestFinishOffsetPercent}% + ${
          targetFinish ? 'var(--tick-width)' : '0px'
        } + var(--icon-spacing))`,
      },
    },
    scheduleBarLabelStyles: {
      targetStart:
        targetStartOffsetPercent >= 50
          ? {
              right: `${100 - targetStartOffsetPercent}%`,
            }
          : { left: `${targetStartOffsetPercent}%` },
      targetFinish:
        targetFinishOffsetPercent >= 50
          ? {
              right: `calc(${100 - targetFinishOffsetPercent}% - var(--tick-width))`,
            }
          : { left: `${targetFinishOffsetPercent}%` },
      effectiveStart:
        effectiveStartOffsetPercent >= 50
          ? {
              right: `${100 - effectiveStartOffsetPercent}%`,
            }
          : { left: `${effectiveStartOffsetPercent}%` },
      effectiveFinish:
        effectiveFinishOffsetPercent >= 50
          ? {
              right: `${100 - effectiveFinishOffsetPercent}%`,
            }
          : { left: `${effectiveFinishOffsetPercent}%` },
      rollupEarliestActiveTargetFinish: showRollupEarliestActiveTargetFinish
        ? {
            left: `${rollupEarliestActiveTargetFinishOffsetPercent}%`,
          }
        : {},
      rollupLatestTargetFinish: showRollupLatestTargetFinish
        ? {
            right: `calc(${100 - rollupLatestTargetFinishOffsetPercent}% - var(--tick-width))`,
          }
        : {},
      expectedStart:
        expectedStartOffsetPercent >= 50
          ? { marginRight: `${100 - expectedStartOffsetPercent}%` }
          : { marginLeft: `${expectedStartOffsetPercent}%` },
      expectedFinish:
        expectedFinishOffsetPercent >= 50
          ? {
              marginRight: `${100 - expectedFinishOffsetPercent}%`,
            }
          : {
              marginLeft: `${expectedFinishOffsetPercent}%`,
            },
      latestFinish:
        latestFinishOffsetPercent >= 50
          ? {
              marginRight: `${100 - latestFinishOffsetPercent}%`,
            }
          : {
              marginLeft: `${latestFinishOffsetPercent}%`,
            },
      done:
        doneDateOffsetPercent >= 50
          ? {
              marginRight: `${100 - doneDateOffsetPercent}%`,
            }
          : {
              marginLeft: `${doneDateOffsetPercent}%`,
            },
    },
    showRollupEarliestActiveTargetFinish,
    showRollupLatestTargetFinish,
  };
};

export const useItemDataForScheduleBar = ({
  itemId,
  dateRange,
  viewType,
}: {
  itemId: number;
  dateRange: DateRange;
  viewType: ScheduleViewType;
}) => {
  const item = useSelector((state) => getItemForId(state, itemId));
  const itemMetrics = useSelector((state) => getItemMetricsForId(state, itemId));
  const taskStatus = useSelector((state) => item?.taskStatus && getTaskStatusForId(state, item.taskStatus.id));

  const isAssignmentItem = isAssignment(item);
  const assignmentParentItemId = isAssignmentItem ? item?.parent?.id : null;
  const assignmentEffectiveTargetFinish = useSelector((state) =>
    assignmentParentItemId ? getItemMetricsForId(state, assignmentParentItemId)?.effectiveTargetFinish : ''
  );

  const { start: itemDateRangeStart, finish: itemDateRangeFinish } = useItemsDateRange([itemId], { viewType });

  const {
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isEffectivelyOnHold,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
  } = calcItemDataForStatus({
    doneDate: item?.doneDate,
    effectiveTargetFinish: itemMetrics?.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics?.effectiveTargetStart,
    expectedFinish: item?.expectedFinish,
    expectedStart: item?.expectedStart,
    folderStatus: item?.folderStatus,
    isClipped: item?.clippedEffort !== null,
    isLate: !!item?.late,
    itemType: item?.itemType,
    latestFinish: item?.latestFinish,
    packageStatus: item?.packageStatus,
    schedulingType: taskStatus?.schedulingType,
    targetFinish: item?.targetFinish,
  });

  return calcItemDataForScheduleBar({
    assignmentEffectiveTargetFinish,
    dateRange,
    doneDate: item?.doneDate,
    effectiveTargetFinish: itemMetrics?.effectiveTargetFinish,
    effectiveTargetStart: itemMetrics?.effectiveTargetStart,
    expectedFinish: item?.expectedFinish,
    expectedStart: item?.expectedStart,
    isAssignmentItem: isAssignment(item),
    isClipped,
    isDone,
    isDoneAfterEffectiveTargetFinish,
    isEffectivelyOnHold,
    isLate,
    isLateRisk,
    isOnHold,
    isScheduled,
    isTaskItem: isTask(item),
    itemDateRangeFinish,
    itemDateRangeStart,
    latestFinish: item?.latestFinish,
    rollupEarliestActiveTargetFinish: itemMetrics?.rollupEarliestActiveTargetFinish,
    rollupLatestTargetFinish: itemMetrics?.rollupLatestTargetFinish,
    targetFinish: item?.targetFinish,
    targetStart: item?.targetStart,
  });
};
