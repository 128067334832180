import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { ItemFile, File } from 'daos/model_types';
import { groupRecordBy, readonlyArray } from 'lib/readonly_record';
import { createCacheByIdsConfig, createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

const getItemFilesById = (state: RootState) => state.entities.itemFiles;
export const getItemFileForId = (state: RootState, itemFileId: number) => getItemFilesById(state)[itemFileId];

export const getFilesById = (state: RootState) => state.entities.files;
export const getFileForId = (state: RootState, fileId: number) => getFilesById(state)[fileId];

const getItemFilesByItemId = createSelector(getItemFilesById, (itemFilesById) => {
  return groupRecordBy<number, ItemFile>(itemFilesById, (itemFile) => itemFile.item.id);
});

const emptyItemFilesArray = readonlyArray<ItemFile>([]);
export const getItemFilesForItemId = createCachedSelector(
  (_: RootState, itemId: number) => itemId,
  getItemFilesByItemId,
  (itemId, itemFilesByItemId) => itemFilesByItemId[itemId] ?? emptyItemFilesArray
)(createCacheByIdConfig());

export const getFilesForIds = createCachedSelector(
  (state: RootState) => state,
  (_: RootState, fileIds: ReadonlyArray<number>) => fileIds,
  (state: RootState, fileIds: ReadonlyArray<number>) => {
    const filesById = getFilesById(state);
    const output = fileIds
      .map((fileId) => filesById[fileId])
      .filter((fileOrUndefined): fileOrUndefined is File => !!fileOrUndefined);
    return output;
  }
)(createCacheByIdsConfig());
