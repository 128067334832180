import { useFormikContext } from 'formik';

import LpLink from 'containers/shared/lp_link';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import EstimatePercentageInput from 'features/jira_project/modal/sections/estimate_mapping_section/estimatePercentageInput';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { frontend } from 'lib/urls';

const initialHours = 100;

const getHoursEstimated = (value: number) => {
  const percentageInDecimal = value / 100;
  return initialHours * percentageInDecimal;
};

const getEstimateExample = (value: number) => {
  const estimateExample = initialHours + getHoursEstimated(value);
  return `${parseFloat(estimateExample.toFixed(2))}h`;
};

export const RemainingEstimates = () => {
  const rangedEstimatesUrl = frontend.portableAcademyLesson.url({ portableLinkName: 'ranged_estimates_video' });
  const { values } = useFormikContext<JiraProjectModalFormValues>();

  const highValue = values[JiraProjectModalFormFields.HighEstimate];
  const lowValue = values[JiraProjectModalFormFields.LowEstimate];

  return (
    <div>
      <JiraProjectModalInfo
        title="Calculate Remaining Estimates"
        helperText={
          <>
            Estimating in ranges accounts for uncertainty and variability. Click{' '}
            <LpLink target="_blank" to={rangedEstimatesUrl}>
              <b>here</b>
            </LpLink>{' '}
            to learn more.
            <br />
            Provide a percentage value to calculate ranged estimates (low - high) from the Jira{' '}
            <b>&quot;Time remaining&quot;</b> field.
          </>
        }
      />
      <div className="jira-project-modal__content-estimate-mapping-input">
        <EstimatePercentageInput highValue={highValue} lowValue={lowValue} />
      </div>
      <JiraProjectModalInfo
        helperText={
          <>
            Example: If &quot;Time remaining&quot; is <b>{initialHours}h</b>, the <b>{highValue}%</b> sets the range at{' '}
            <b data-testid="lowEstimateExample">{getEstimateExample(lowValue)}</b> -{' '}
            <b data-testid="highEstimateExample">{getEstimateExample(highValue)}</b>.
          </>
        }
      />
    </div>
  );
};
