import { forwardRef, memo, ReactElement, useMemo } from 'react';
import { areEqual } from 'react-window';

import { WorkloadGroup, WorkloadItem } from 'daos/model_types';
import { useWorkloadTableContext } from 'features/common/workload/workload_table/context';
import {
  ADDITIONAL_COLUMN_COUNT,
  AVAILABILITY_USED_COLUMN_WIDTH,
  BASE_NUBS_COLUMN_WIDTH,
  getColumnWidthAndSetNameColumnWidth,
  getRenderedRowRange,
  REMAINING_COLUMN_WIDTH,
  ROW_HEIGHT_PIXELS,
} from 'features/common/workload/workload_table/helpers';
import { OrgUserNameCell } from 'features/common/workload/workload_table/org_user_cells';
import { NameCell } from 'features/common/workload/workload_table/task_cells';
import { FlattenedWorkloadData } from 'features/common/workload/workload_table/types';
import WorkloadHeader from 'features/common/workload/workload_table/workload_header';

interface StickyWorkloadTableElementsProps {
  children: Array<ReactElement>;
}
export const StickyWorkloadTableElements = memo(
  forwardRef<HTMLDivElement, StickyWorkloadTableElementsProps>(({ children }, ref) => {
    const { workload, workloadFlattenedData, dayRange, boundingBox } = useWorkloadTableContext();
    const { minRow, maxRow } = getRenderedRowRange(children);

    const columnCount = dayRange + ADDITIONAL_COLUMN_COUNT;
    const cellWidth = getColumnWidthAndSetNameColumnWidth(0, dayRange, columnCount, boundingBox?.width ?? 0);

    if (!workload) {
      return null;
    }

    const height = workloadFlattenedData.length * ROW_HEIGHT_PIXELS;

    const dayRangeWidth = dayRange * BASE_NUBS_COLUMN_WIDTH;
    const width = cellWidth + dayRangeWidth + REMAINING_COLUMN_WIDTH + AVAILABILITY_USED_COLUMN_WIDTH;

    return (
      <div
        style={{
          height,
          width,
        }}
        ref={ref}
        className="workload-view__sticky-elements-container"
      >
        <WorkloadHeader />
        <StickyColumn minRow={minRow} maxRow={maxRow} cellWidth={cellWidth} />
        <div className="workload-view__sticky-elements-container-workload-grid">{children}</div>
      </div>
    );
  }),
  areEqual
);

function getOrgUserId(group?: WorkloadGroup, item?: WorkloadItem) {
  return group?.organizationUser?.id || item?.organizationUser?.id;
}

interface StickyCellRenderData extends FlattenedWorkloadData {
  rowIndex: number;
}

interface StickyColumnProps {
  minRow: number;
  maxRow: number;
  cellWidth: number;
}
const StickyColumn = memo(({ minRow, maxRow, cellWidth }: StickyColumnProps) => {
  const { workloadFlattenedData } = useWorkloadTableContext();
  const dataSlice: Array<StickyCellRenderData> = useMemo(() => {
    const newDataSlice: Array<StickyCellRenderData> = [];

    for (let i = minRow; i <= maxRow; i++) {
      const row = workloadFlattenedData[i];
      if (row) {
        newDataSlice.push({
          ...row,
          rowIndex: i,
        });
      }
    }

    return newDataSlice;
  }, [maxRow, minRow, workloadFlattenedData]);

  return (
    <div
      style={{
        width: cellWidth,
      }}
      className="workload-view__sticky-elements-container-sticky-column"
    >
      {dataSlice.map(({ group, item, rowIndex }) => (
        <StickyCell
          key={`${getOrgUserId(group, item)}-${group?.id}-${item?.id}`}
          rowIndex={rowIndex}
          group={group}
          orgUserId={getOrgUserId(group, item)}
          item={item}
          cellWidth={cellWidth}
        />
      ))}
    </div>
  );
}, areEqual);

interface StickyCellProps {
  group?: WorkloadGroup;
  item?: WorkloadItem;
  orgUserId?: number;
  rowIndex: number;
  cellWidth: number;
}
const StickyCell = memo<StickyCellProps>(({ item, orgUserId, rowIndex, cellWidth }) => {
  const { workloadFlattenedData, setMoveItemId, fetchWorkload, setDeletionData, handleToggleByOrgUserId } =
    useWorkloadTableContext();

  const style = {
    height: ROW_HEIGHT_PIXELS,
    top: ROW_HEIGHT_PIXELS * rowIndex,
    left: 0,
    width: cellWidth,
  };

  if (item) {
    return (
      <NameCell
        style={style}
        fetchWorkload={fetchWorkload}
        workloadItem={item}
        setMoveItemId={setMoveItemId}
        setDeletionData={setDeletionData}
      />
    );
  }

  const isExpand = !!workloadFlattenedData[rowIndex + 1]?.item;

  return (
    <OrgUserNameCell
      orgUserId={orgUserId}
      isExpand={isExpand}
      style={style}
      handleToggleByOrgUserId={handleToggleByOrgUserId}
    />
  );
}, areEqual);
