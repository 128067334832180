import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { WidgetGroup } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { WidgetGroupConfig } from './types';
import { backend } from './urls';

const { createBody, deleteBody, updateBody, resource } = defineModel({
  apiType: 'widgetGroups',
  type: 'WIDGET_GROUP',
});

const { WIDGET_GROUP } = resource;

interface WidgetGroupParams {
  dashboardId: string;
  organizationId: number;
  workspaceId: number;
}

interface Includes {
  includeWidgetGroupLocationFilterItem?: boolean;
  includeWidgets?: boolean;
}

interface WidgetGroupOptions {
  include?: Includes;
}

interface SingleWidgetGroupParams extends WidgetGroupParams {
  widgetGroupId: string;
}

const includes = ({ includeWidgetGroupLocationFilterItem, includeWidgets }: Includes = {}) =>
  reduceIncludedOptions([includeWidgetGroupLocationFilterItem && 'locationFilterItem', includeWidgets && 'widgets']);

const widgetGroupUpdate = (
  params: SingleWidgetGroupParams,
  widgetGroup: Partial<Pick<WidgetGroup, 'title' | 'relativePriority' | 'widgetGroupSize'>> & {
    config?: Partial<WidgetGroupConfig>;
  },
  { include }: WidgetGroupOptions = {}
) =>
  request(
    backend.widgetGroup.url(params, { include: includes(include) }),
    WIDGET_GROUP,
    updateBody(Number(params.widgetGroupId), widgetGroup)
  );

const fetchWidgetGroupForDashboard = (params: WidgetGroupParams) =>
  request(backend.widgetGroups.url(params), WIDGET_GROUP, { method: HttpMethod.GET });
const widgetGroupCreate = (params: WidgetGroupParams, widgetGroup: Partial<WidgetGroup>) =>
  request(backend.widgetGroups.url(params), WIDGET_GROUP, createBody(widgetGroup));

const widgetGroupDestroy = (params: SingleWidgetGroupParams) =>
  request(backend.widgetGroup.url(params), WIDGET_GROUP, deleteBody(Number(params.widgetGroupId)));

const widgetGroupDuplicate = (params: SingleWidgetGroupParams, { include }: WidgetGroupOptions = {}) =>
  request(backend.widgetGroupDuplicate.url(params, { include: includes(include) }), WIDGET_GROUP, {
    method: HttpMethod.POST,
  });

export const WidgetGroupDao = {
  fetch: fetchWidgetGroupForDashboard,
  create: widgetGroupCreate,
  update: widgetGroupUpdate,
  destroy: widgetGroupDestroy,
  duplicate: widgetGroupDuplicate,
} as const;
