import { RelativePriorityType } from 'daos/item_enums';
import { useDashboardContext } from 'features/dashboards_v2/context';

export const useOpenAddWidgetModal = (widgetGroupId: string, relativePriority?: RelativePriorityType) => {
  const { setShowAddWidgetModal, setAddWidgetModalProps } = useDashboardContext();

  const openAddWidgetModal = () => {
    setAddWidgetModalProps({ widgetGroupId, relativePriority });
    setShowAddWidgetModal(true);
  };

  return openAddWidgetModal;
};
