import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WidgetDao } from 'daos/widgets';
import { EventScopes, Events } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import { GuestTaskBoard } from 'features/common/task_board/guest';
import { GuestTasksByStatusId, GuestTaskBoardTaskStatus } from 'features/common/task_board/guest/types';
import { clearTaskBoard } from 'features/common/task_board/slice';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useDebouncedInput } from 'hooks/use_debounced_input';
import { awaitRequestFinish } from 'lib/api';
import { taskInputQuickFilter } from 'lib/quick_filter_and_search_helpers/quick_filter';

import { getGuestTaskBoardTasksByStatusId } from './helpers';
import { GuestTaskBoardWidgetData } from './types';

export const GuestWidgetTaskBoard = () => {
  const dispatch = useDispatch();
  const { widgetId, dashboardId = 0 } = useWidgetParams();
  const { organizationId = 0, workspaceId = 0 } = useSelector((state) => getWidgetForId(state, widgetId)) ?? {};

  const [tasksByStatusId, setTasksByStatusId] = useState<GuestTasksByStatusId>({});
  const [taskStatuses, setTaskStatuses] = useState<ReadonlyArray<GuestTaskBoardTaskStatus>>([]);

  const { inputValue, setDebouncedValue } = useDebouncedInput(widgetId);
  const [fetchComplete, setFetchComplete] = useState(false);

  const fetchWidgetItems = useCallback(() => {
    if (!dashboardId || !organizationId || !workspaceId) {
      return;
    }

    setFetchComplete(false);
    const { uuid } = dispatch(
      WidgetDao.fetchTaskBoard(
        { organizationId, workspaceId, dashboardId, widgetId },
        { filter: taskInputQuickFilter({ inputValue }) }
      )
    );

    dispatch(
      awaitRequestFinish<GuestTaskBoardWidgetData>(uuid, {
        onSuccess: ({ data }) => {
          setTasksByStatusId(getGuestTaskBoardTasksByStatusId(data));
          setTaskStatuses(data.taskStatuses);
        },
        onFinish: () => setFetchComplete(true),
      })
    );
  }, [dashboardId, dispatch, inputValue, organizationId, widgetId, workspaceId]);

  useEffect(() => {
    fetchWidgetItems();
  }, [fetchWidgetItems]);

  useEffect(() => {
    return () => {
      dispatch(clearTaskBoard());
    };
  }, [dispatch]);

  useEvents({
    event: Events.RESCHEDULE_COMPLETE,
    callback: fetchWidgetItems,
    scope: EventScopes.WidgetTaskBoard,
  });

  return (
    <div className="lp-task-board-page">
      <GuestTaskBoard
        inputValue={inputValue}
        fetchComplete={fetchComplete}
        setDebouncedValue={setDebouncedValue}
        taskStatuses={taskStatuses}
        tasksByStatusId={tasksByStatusId}
      />
    </div>
  );
};
