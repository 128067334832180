import ItemIcon from 'containers/shared/item_icon';
import { WidgetType, widgetTypeDisplay } from 'daos/enums';
import { IconDefinition, LpIcon } from 'features/common/lp_icon';

export const IconHeader = ({ itemId, title }: { itemId: number | undefined; title: string }) => {
  return (
    <>
      <ItemIcon itemId={itemId} /> {title}
    </>
  );
};

export const SettingsHeader = ({ icon, widgetType }: { icon: IconDefinition; widgetType: WidgetType }) => {
  return (
    <>
      <LpIcon icon={icon} /> {widgetTypeDisplay[widgetType]}
    </>
  );
};
