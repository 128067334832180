import classNames from 'classnames';
import { Label } from 'semantic-ui-react';

import { LpIcon, coffeeSolid as waitingIconSolid } from 'features/common/lp_icon';
import 'containers/shared/notice_icons/icons.scss';
import { lpBrandWhite } from 'style/variables';

interface WaitingLabelProps {
  className?: string;
  onClick?: (event: React.SyntheticEvent) => void;
}

export const WaitingLabel = ({ className, onClick }: WaitingLabelProps) => {
  return (
    <Label
      className={classNames(className, 'icon notice waiting', 'clickable')}
      content={<LpIcon hoverText={'Waiting (Not Ready)'} size="sm" icon={waitingIconSolid} color={lpBrandWhite} />}
      onClick={onClick}
    />
  );
};
