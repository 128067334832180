import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';

import { Model, ResourceId, User } from './model_types';
import { defineModel } from './shared';
import { backend } from './urls';

const { createBody, updateBody, resource } = defineModel({
  apiType: 'passwordResetTokens',
  relationships: ['workspaces'],
  type: 'RESET_PASSWORD',
});

export interface ResetPassword extends Model<'passwordResetTokens'> {
  readonly claimedAt: string | null;
  readonly expired: boolean;
  readonly sentAt: string;
  readonly user: ResourceId<User>;
}

const { RESET_PASSWORD } = resource;

interface ResetPasswordGetProps {
  token: string;
}

interface ResetPasswordPatchProps {
  password: string;
  resetPasswordId: number;
  token: string;
}

interface ForgotPasswordProps {
  email: string;
}

const forgotPassword = ({ email }: ForgotPasswordProps) =>
  request(backend.forgotPassword.url({}), 'FORGOT_PASSWORD', createBody({ email }));

const resetPasswordGet = ({ token }: ResetPasswordGetProps) =>
  request(backend.resetPassword.url({ token }), RESET_PASSWORD, {
    method: HttpMethod.GET,
  });

const resetPasswordPatch = ({ token, password, resetPasswordId }: ResetPasswordPatchProps) =>
  request(backend.resetPassword.url({ token }), RESET_PASSWORD, updateBody(resetPasswordId, { password }));

export const ResetPasswordDao = {
  forgotPassword,
  get: resetPasswordGet,
  patch: resetPasswordPatch,
} as const;
