import { Redirect } from 'react-router';

import { Widget } from 'daos/model_types';
import { useDashboardContext } from 'features/dashboards_v2/context';

import { getIntakeSettingsUrl } from './helpers';

export const IntakeSettings = ({ widget }: { widget: Widget }) => {
  const { dashboard, libraryLocation, packageStatus } = useDashboardContext();

  if (!dashboard || !libraryLocation) {
    return null;
  }

  const urlToIntakeSettings = getIntakeSettingsUrl(dashboard, widget, libraryLocation, packageStatus);

  return <Redirect to={urlToIntakeSettings} />;
};
