import { RefObject, useLayoutEffect } from 'react';

export function useDisableAutofill(ref: RefObject<HTMLElement>, applyLastPassIgnore?: boolean) {
  const autoCompleteAttribute = 'autocomplete';
  const autoCompleteOff = 'off';
  const lastPassIgnoreAttribute = 'data-lpignore';

  useLayoutEffect(() => {
    const formElement = ref.current;
    if (formElement) {
      if (formElement.getAttribute(autoCompleteAttribute) !== autoCompleteOff) {
        formElement.setAttribute(autoCompleteAttribute, autoCompleteOff);
      }

      const inputs = Array.from(formElement.getElementsByTagName('input'));

      for (const input of inputs) {
        if (input && input.getAttribute(lastPassIgnoreAttribute) !== 'false' && applyLastPassIgnore) {
          input.setAttribute(lastPassIgnoreAttribute, 'true');
        }
      }
    }
  });
}
