import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import { Widget } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import PageNotFound from 'features/common/errors/page_not_found';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { libraryResourceClickThruRoute } from 'features/library/helpers';
import { awaitRequestFinish } from 'lib/api';
import {
  getLibraryLocationForLibraryResourceId,
  getLibraryResourcesForReportId,
} from 'redux/entities/selectors/library_resources';

import 'features/common/portable_link_handler/index.scss';

interface PortableReportLinkHandlerProps {
  workspaceId: string;
  organizationId: string;
  itemId?: string;
  widgetId: string;
}

export const PortableReportLinkHandler = () => {
  const dispatch = useDispatch();

  const { workspaceId = '', organizationId = '', widgetId = '' } = useParams<PortableReportLinkHandlerProps>();

  const [loading, setLoading] = useState(true);
  const [libraryResourceReport, setLibraryResourceReport] = useState<Widget | undefined>(undefined);

  const libraryResource = useSelector((state) => getLibraryResourcesForReportId(state, Number(widgetId)));

  const libraryLocation = useSelector((state) => {
    return libraryResource ? getLibraryLocationForLibraryResourceId(state, libraryResource.id) : undefined;
  });

  const fetchLibraryResource = useCallback(() => {
    const { uuid } = dispatch(
      WidgetDao.fetchReportWidget(
        { organizationId, workspaceId, widgetId },
        { include: { includeLibraryResource: true, includeLibraryResourceItem: true } }
      )
    );

    dispatch(
      awaitRequestFinish<Widget>(uuid, {
        onSuccess: ({ data }) => setLibraryResourceReport(data),
        onFinish: () => setLoading(false),
      })
    );
  }, [dispatch, widgetId, organizationId, workspaceId]);

  useEffect(() => {
    fetchLibraryResource();
  }, [fetchLibraryResource, libraryResourceReport]);

  if (loading) {
    return (
      <div className="portable-link-handler__container">
        <LpOverlayLoader />
      </div>
    );
  }

  if (!libraryResource || !libraryLocation) {
    return <PageNotFound />;
  }

  const libraryResourceUrl = libraryResourceClickThruRoute({ libraryLocation, libraryResource });

  return <Redirect to={libraryResourceUrl} />;
};
