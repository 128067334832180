import { useDispatch, useSelector } from 'react-redux';

import { WidgetType } from 'daos/enums';
import { Widget } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useDashboardContext } from 'features/dashboards_v2/context';
import { camelToHyphenCase } from 'features/dashboards_v2/helpers';
import { ChangesWidgetSettings } from 'features/dashboards_v2/widget/widgets/changes/settings';
import { DashboardNoteWidgetSettings } from 'features/dashboards_v2/widget/widgets/dashboard_note/settings';
import { ImageWidgetSettings } from 'features/dashboards_v2/widget/widgets/image/settings';
import { InsightsSettings } from 'features/dashboards_v2/widget/widgets/insights/settings';
import { IntakeSettings } from 'features/dashboards_v2/widget/widgets/intake/settings';
import { LinkedNoteWidgetSettings } from 'features/dashboards_v2/widget/widgets/linked_note/settings';
import { ListWidgetSettings } from 'features/dashboards_v2/widget/widgets/list/settings';
import { MetricsTallySettings } from 'features/dashboards_v2/widget/widgets/metrics_tally/settings';
import { PropertiesSummarySettings } from 'features/dashboards_v2/widget/widgets/properties_summary/settings';
import { ScheduleSummarySettings } from 'features/dashboards_v2/widget/widgets/schedule_summary/settings';
import { TaskBoardSettings } from 'features/dashboards_v2/widget/widgets/task_board/settings';
import { WorkloadSettings } from 'features/dashboards_v2/widget/widgets/workload/settings';
import { WidgetSettingsModalContent, WidgetSettingsV2 } from 'features/dashboards_v2/widget_settings/index';
import {
  deserializeOnClickViewOption,
  serializeOnClickViewOption,
} from 'features/dashboards_v2/widget_settings/on_click_view_options_serialization';
import { WidgetSettingsForm } from 'features/dashboards_v2/widget_settings/types';
import { awaitRequestFinish } from 'lib/api';

interface WidgetSettingsProps {
  dashboardId: string;
  widgetId: string;
}
export function WidgetSettingsRenderer({ dashboardId, widgetId }: WidgetSettingsProps) {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const widget = useSelector((state) => getWidgetForId(state, widgetId));
  const { setShowSettingsForWidget } = useDashboardContext();

  if (!widget) {
    return null;
  }

  const originalSettings = widget.config;

  function submit({ onClickViewOption, ...newSettings }: WidgetSettingsForm) {
    const { statusFilterSelection, ...updatedSettings } = newSettings;

    const { uuid } = dispatch(
      WidgetDao.update(
        {
          organizationId,
          workspaceId,
          dashboardId,
          widgetId,
        },
        {
          config: {
            ...originalSettings,
            ...updatedSettings,
            ...deserializeOnClickViewOption(onClickViewOption),
            s3Id: updatedSettings.s3Id || null,
            dateRangeIncludeAllOverride: false,
          },
        }
      )
    );

    dispatch(
      awaitRequestFinish(uuid, {
        onFinish: () => {
          setShowSettingsForWidget(null);
        },
      })
    );
  }

  const widgetSettingsClassName = `v2-widget-settings-${camelToHyphenCase(widget.widgetType)}`;

  return (
    <WidgetSettingsV2
      className={widgetSettingsClassName}
      content={<WidgetSettingsComponent widget={widget} />}
      initialValues={{
        ...originalSettings,
        onClickViewOption: serializeOnClickViewOption(originalSettings),
        s3Id: originalSettings.s3Id ?? '',
        title: originalSettings.title ?? '',
      }}
      onSubmit={submit}
    />
  );
}

function WidgetSettingsComponent({ widget }: { widget: Widget | undefined }) {
  switch (widget?.widgetType) {
    case WidgetType.Changes:
      return <ChangesWidgetSettings widgetId={widget.id} />;
    case WidgetType.List:
      return <ListWidgetSettings widget={widget} />;
    case WidgetType.LinkedNote:
      return <LinkedNoteWidgetSettings widget={widget} />;
    case WidgetType.DashboardNote:
      return <DashboardNoteWidgetSettings widget={widget} />;
    case WidgetType.Image:
      return <ImageWidgetSettings widgetId={widget.id} />;
    case WidgetType.PropertiesSummary:
      return <PropertiesSummarySettings widget={widget} />;
    case WidgetType.ScheduleSummary:
      return <ScheduleSummarySettings widget={widget} />;
    case WidgetType.BoardSummary:
      return <TaskBoardSettings widgetId={widget.id} />;
    case WidgetType.Workload:
      return <WorkloadSettings widgetId={widget.id} />;
    case WidgetType.MetricsTally:
      return <MetricsTallySettings widget={widget} />;
    case WidgetType.Intake:
      return <IntakeSettings widget={widget} />;
    case WidgetType.Insights:
      return <InsightsSettings widget={widget} />;
    default:
      return <WidgetSettingsModalContent error />;
  }
}
