export const JiraSvg = () => (
  <svg viewBox="0 0 87 90" fill="none">
    <path
      d="M85.4233 42.8278L47.1803 4.59657L43.4733 0.889587L14.6855 29.6774L1.5351 42.8278C1.20701 43.1548 0.946686 43.5433 0.76906 43.9711C0.591434 44.3988 0.5 44.8575 0.5 45.3207C0.5 45.7839 0.591434 46.2425 0.76906 46.6703C0.946686 47.098 1.20701 47.4866 1.5351 47.8135L27.836 74.1143L43.4733 89.7517L72.2729 60.9639L72.7421 60.5768L85.4468 47.8722C85.7823 47.5426 86.0483 47.1493 86.2292 46.7152C86.4101 46.2812 86.5022 45.8153 86.5 45.345C86.4978 44.8748 86.4014 44.4098 86.2165 43.9775C86.0315 43.5451 85.7618 43.1542 85.4233 42.8278ZM43.4733 58.4652L30.3347 45.3265L43.4851 32.1761L56.6238 45.3265L43.4733 58.4652Z"
      fill="#77859B"
    />
    <path
      d="M43.4851 32.1761C39.3531 28.0417 37.0261 22.4395 37.013 16.5943C36.9998 10.749 39.3014 5.13637 43.4148 0.983383L14.6387 29.736L30.2878 45.3265L43.4851 32.1878V32.1761Z"
      fill="url(#paint0_linear_1123_1223)"
    />
    <path
      d="M56.659 45.3265L43.4851 58.4652C47.6307 62.6158 49.9592 68.2422 49.9592 74.1085C49.9592 79.9748 47.6307 85.6012 43.4851 89.7518L72.3081 60.9287L56.659 45.3265Z"
      fill="url(#paint1_linear_1123_1223)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1123_1223"
        x1="41.139"
        y1="18.8849"
        x2="22.78"
        y2="37.2321"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#304566" />
        <stop offset="1" stopColor="#77859B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1123_1223"
        x1="45.9955"
        y1="71.5922"
        x2="64.3076"
        y2="53.2801"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#304566" />
        <stop offset="1" stopColor="#77859B" />
      </linearGradient>
    </defs>
  </svg>
);
