import { groupBy } from 'lodash';

import { CustomFieldDao } from 'daos/custom_field';
import { CustomFieldType } from 'daos/enums';
import { CustomField, CustomFieldValue } from 'daos/model_types';
import { AddEditGridRow, AddEditGridRows } from 'features/common/data_grid/types';
import { EntityLookupById } from 'redux/entities/types';

export const getFieldTypeValue = (fieldValue: Partial<CustomFieldValue>) => {
  const { fieldType } = fieldValue;

  switch (fieldType) {
    case CustomFieldType.CHECKBOX:
      return fieldValue.checked || null;
    case CustomFieldType.CURRENCY:
      return fieldValue.currency;
    case CustomFieldType.DATE:
      return fieldValue.date;
    case CustomFieldType.ITEM:
    case CustomFieldType.MULTI_ITEM:
      return fieldValue.itemValue;
    case CustomFieldType.LINK:
    case CustomFieldType.MULTI_LINK:
      return fieldValue.url?.trim() || null;
    case CustomFieldType.MULTILINE_TEXT:
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.TEXT:
      return fieldValue.text?.trim() || null;
    case CustomFieldType.MULTI_PICKLIST:
    case CustomFieldType.PICKLIST:
      return fieldValue.picklistChoice || null;
    case CustomFieldType.MULTI_USER:
    case CustomFieldType.USER:
      return fieldValue.workspaceUserValue;
    case CustomFieldType.NOTE:
      return fieldValue.html;
    case CustomFieldType.NUMERIC:
      return fieldValue.number;
  }
};

export const getFieldValueKeyForFieldType = (fieldType: CustomFieldType): keyof CustomFieldValue => {
  switch (fieldType) {
    case CustomFieldType.CHECKBOX:
      return 'checked';
    case CustomFieldType.CURRENCY:
      return 'currency';
    case CustomFieldType.DATE:
      return 'date';
    case CustomFieldType.ITEM:
    case CustomFieldType.MULTI_ITEM:
      return 'itemValue';
    case CustomFieldType.LINK:
    case CustomFieldType.MULTI_LINK:
      return 'url';
    case CustomFieldType.MULTILINE_TEXT:
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.TEXT:
      return 'text';
    case CustomFieldType.MULTI_PICKLIST:
    case CustomFieldType.PICKLIST:
      return 'picklistChoice';
    case CustomFieldType.MULTI_USER:
    case CustomFieldType.USER:
      return 'workspaceUserValue';
    case CustomFieldType.NOTE:
      return 'html';
    case CustomFieldType.NUMERIC:
      return 'number';
  }
};

export const getFieldCellValuesByFieldId = (fieldValues: ReadonlyArray<CustomFieldValue>) => {
  return fieldValues.reduce((acc: Record<number, Array<CustomFieldValue>>, fieldValue) => {
    const fieldId = fieldValue.field.id;
    const values = acc[fieldId];

    if (values) {
      values.push(fieldValue);
    } else {
      acc[fieldId] = [fieldValue];
    }

    return acc;
  }, {});
};

export const isValidFieldValue = (fieldValue: Partial<CustomFieldValue>, fieldValueKey: keyof CustomFieldValue) =>
  !!fieldValue.id || !!fieldValue[fieldValueKey];

export const getUpdatedMultiFieldValues = (
  newFieldValues: ReadonlyArray<Partial<CustomFieldValue>>,
  initialFieldValues: ReadonlyArray<CustomFieldValue>,
  fieldType: CustomFieldType
) => {
  // Track all initial field value ids
  const initialFieldValueIds = new Set(initialFieldValues.map((value) => value.id));
  const fieldValueKey = getFieldValueKeyForFieldType(fieldType);
  const updatedValues: Array<Partial<CustomFieldValue>> = [];

  newFieldValues.forEach((fieldValue) => {
    if (isValidFieldValue(fieldValue, fieldValueKey)) {
      updatedValues.push(fieldValue);

      if (fieldValue.id) {
        initialFieldValueIds.delete(fieldValue.id);
      }
    }
  });

  // Any field value ids remaining in the initial set are to be deleted in the database
  initialFieldValueIds.forEach((fieldValueId) => {
    const deletedValue = { id: fieldValueId, [fieldValueKey]: null };
    updatedValues.push(deletedValue);
  });

  return updatedValues;
};

export const _removeCustomFieldValuesFromRow = (row: AddEditGridRow) => {
  const copiedRow = { ...row };
  Object.keys(row).forEach((rowKey) => {
    const rowNumberKey = Number(rowKey);
    const isFieldIdKey = !isNaN(rowNumberKey);

    if (isFieldIdKey) {
      delete copiedRow[rowNumberKey];
    }
  });
  return copiedRow;
};

export const _addCustomFieldValuesForRow = ({
  row,
  fieldValuesByFieldId,
}: {
  row: AddEditGridRow;
  fieldValuesByFieldId: Record<string, Array<CustomFieldValue>>;
}) => {
  return { ...row, ...fieldValuesByFieldId };
};

export const updateRowCustomFieldValuesOnSave = ({
  rows,
  fieldValuesById,
}: {
  rows: AddEditGridRows;
  fieldValuesById: EntityLookupById<CustomFieldValue> | undefined;
}) => {
  const fieldValuesByItemId = groupBy(fieldValuesById, (fieldValue) => fieldValue.item?.id);
  const updatedRows: Array<AddEditGridRow> = [];
  rows.forEach((row) => {
    const rowFieldValues = fieldValuesByItemId[row.id];
    const fieldValuesByFieldId = groupBy(rowFieldValues, (fieldValue) => fieldValue.field.id);

    const rowWithoutCustomFields = _removeCustomFieldValuesFromRow(row);
    const updatedRow = _addCustomFieldValuesForRow({ row: rowWithoutCustomFields, fieldValuesByFieldId });

    updatedRows.push(updatedRow);
  });
  return updatedRows;
};

export const createCustomFieldValuesPayload = ({
  row,
  customFieldsKeyedById,
  initialRowFieldValues,
}: {
  row: AddEditGridRow;
  customFieldsKeyedById: EntityLookupById<CustomField>;
  initialRowFieldValues: ReadonlyArray<CustomFieldValue>;
}) => {
  const fieldValues = Object.keys(row).reduce((acc: Array<Partial<CustomFieldValue>>, key) => {
    const fieldId = Number(key);
    const customField = customFieldsKeyedById[fieldId];

    if (customField) {
      const newFieldValues = row[fieldId] ?? [];
      const initialFieldValues = getFieldCellValuesByFieldId(initialRowFieldValues)[customField.id] ?? [];
      const updatedValues = getUpdatedMultiFieldValues(newFieldValues, initialFieldValues, customField.fieldType);

      acc.push(...updatedValues);
    }
    return acc;
  }, []);
  return fieldValues;
};

export const getDeletedFieldValueIds = (
  rows: ReadonlyArray<AddEditGridRow>,
  customFieldsById: EntityLookupById<CustomField>,
  fieldValuesByItemId: Record<number, ReadonlyArray<CustomFieldValue>>
) => {
  const deletedFieldValueIds: Array<number> = [];

  rows.forEach((row) => {
    const initialRowFieldValues = fieldValuesByItemId[row.id] ?? [];
    const initialRowFieldValueIds = new Set(initialRowFieldValues.map((value) => value.id));

    Object.keys(row).forEach((key) => {
      const fieldId = Number(key);
      const customField = customFieldsById[fieldId];

      if (customField) {
        const fieldValueKey = getFieldValueKeyForFieldType(customField.fieldType);
        const fieldValues = row[fieldId] ?? [];

        fieldValues.forEach((fieldValue) => {
          if (fieldValue.id && fieldValue[fieldValueKey] !== null) {
            initialRowFieldValueIds.delete(fieldValue.id);
          }
        });
      }
    });

    initialRowFieldValueIds.forEach((id) => deletedFieldValueIds.push(id));
  });

  return deletedFieldValueIds;
};

export const getIsCustomFieldColumn = (columnKey: string) => {
  const numberColumnKey = Number(columnKey);
  return !isNaN(numberColumnKey);
};

export const getCustomFieldPasteOrFillUpdatedTargetRow = (
  fieldId: number,
  sourceRow: AddEditGridRow,
  targetRow: AddEditGridRow
) => {
  const sourceFieldValues = sourceRow[fieldId] ?? [];

  if (sourceFieldValues.length > 0) {
    const targetFieldValues = targetRow[fieldId] ?? [];
    const updatedValues: Array<Partial<CustomFieldValue>> = [];

    sourceFieldValues.forEach((sourceFieldValue, index) => {
      if (sourceFieldValue.fieldType) {
        const targetFieldValueId = targetFieldValues[index]?.id;
        const fieldValueKey = getFieldValueKeyForFieldType(sourceFieldValue.fieldType);

        updatedValues.push({
          id: targetFieldValueId,
          field: CustomFieldDao.id(fieldId),
          [fieldValueKey]: getFieldTypeValue(sourceFieldValue),
          fieldType: sourceFieldValue.fieldType,
        });
      }
    });

    return { ...targetRow, [fieldId]: updatedValues };
  }

  return { ...targetRow, [fieldId]: undefined };
};
