import { useMemo } from 'react';

import { CustomFieldType } from 'daos/enums';

import CustomFieldCheckbox from './custom_field_checkbox';
import { CustomFieldCurrency } from './custom_field_currency';
import CustomFieldDate from './custom_field_date';
import CustomFieldItem from './custom_field_item';
import CustomFieldLink from './custom_field_link';
import CustomFieldMultilineText from './custom_field_multi_line_text';
import CustomFieldNote from './custom_field_note';
import CustomFieldNumeric from './custom_field_numeric';
import CustomFieldPicklist from './custom_field_picklist';
import CustomFieldText from './custom_field_text';
import { CustomFieldValueProps } from './custom_field_value_props';
import CustomFieldWsUser from './custom_field_ws_user';

const CustomFieldValue = ({
  fieldId,
  fieldType,
  resourceId,
  disabled = false,
}: CustomFieldValueProps & {
  fieldType: CustomFieldType;
}) => {
  const customFieldValueInput = useMemo(() => {
    switch (fieldType) {
      case CustomFieldType.CHECKBOX:
        return <CustomFieldCheckbox fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.CURRENCY:
        return <CustomFieldCurrency fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.DATE:
        return <CustomFieldDate fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.TEXT:
      case CustomFieldType.MULTI_TEXT:
        return <CustomFieldText fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.MULTILINE_TEXT:
        return <CustomFieldMultilineText fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.ITEM:
      case CustomFieldType.MULTI_ITEM:
        return <CustomFieldItem fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.LINK:
      case CustomFieldType.MULTI_LINK:
        return <CustomFieldLink fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.NUMERIC:
        return <CustomFieldNumeric fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.PICKLIST:
      case CustomFieldType.MULTI_PICKLIST:
        return <CustomFieldPicklist fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.USER:
      case CustomFieldType.MULTI_USER:
        return <CustomFieldWsUser fieldId={fieldId} resourceId={resourceId} disabled={disabled} />;
      case CustomFieldType.NOTE:
        return (
          <CustomFieldNote
            fieldId={fieldId}
            resourceId={resourceId}
            showsEditButton={true}
            height={'400px'}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  }, [fieldId, fieldType, disabled, resourceId]);

  return customFieldValueInput;
};

export default CustomFieldValue;
