import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItemProps, Table } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { StoryPointSchemeActions } from 'daos/external_integration_enums';
import { StoryPointSchemesWithStoryPoints } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  getSchemeModifiedName,
  getSchemeValue,
  isValidScheme,
  sortSchemesByDefault,
} from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import { SchemeNameInput } from 'features/jira_project/modal/sections/estimate_mapping_section/scheme_name_input';
import { SelectSchemeRow } from 'features/jira_project/modal/sections/estimate_mapping_section/select_scheme_row';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';
import { getInitialStoryPoints } from 'features/jira_project/modal/utils';
import { frontend } from 'lib/urls';

const popUpText =
  "When a Story Point is applied to a task that doesn’t have any assignments, low and high estimate fields are populated automatically to calculate the schedule. After the initial entry, Story Point and Remaining Estimate are independent of each other and changing one doesn't affect the other.";

export const StoryPointsSchemeChoice = ({
  selectedSchemeActionDisplayName,
  setSelectedSchemeActionDisplayName,
  schemesWithStoryPoints,
  setIsValidSelectedScheme,
}: {
  selectedSchemeActionDisplayName: SchemeDisplayNames | string | undefined;
  setSelectedSchemeActionDisplayName: (value: SchemeDisplayNames | undefined) => void;
  schemesWithStoryPoints: Array<StoryPointSchemesWithStoryPoints>;
  setIsValidSelectedScheme: (value: boolean) => void;
}) => {
  const organizationId = useSelector((state) => getCurrentOrganizationId(state));
  const workspaceId = useSelector((state) => getCurrentWorkspaceId(state));
  const { storyPointSchemeOwner } = useJiraProjectModalContext();
  const { values, setFieldError, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const storyPointSchemeActionValue = values[JiraProjectModalFormFields.StoryPointSchemeActions];
  const selectedExistingSchemeToCopyValue = values[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];
  const storyPointsIsDisabled = !!storyPointSchemeOwner;

  const sortedByDefaultScheme = sortSchemesByDefault(schemesWithStoryPoints);
  const showSchemeNameInput = selectedSchemeActionDisplayName === SchemeDisplayNames.CreateNewScheme;
  const showSelectSchemeDropdown = selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme;
  const showLinkToSchemes = storyPointSchemeActionValue !== StoryPointSchemeActions.CreateNew;
  const linkToSchemes = frontend.storyPointSchemes.url({ organizationId, workspaceId });

  const handleChangeSchemeActions = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownItemProps) => {
    setSelectedSchemeActionDisplayName(data.text as SchemeDisplayNames);
    setFieldError(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldError(JiraProjectModalFormFields.StoryPoints, undefined);

    setFieldValue(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, undefined);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeActions, data.value);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldValue(JiraProjectModalFormFields.ExistingSchemeId, undefined);
    setFieldValue(JiraProjectModalFormFields.StoryPoints, getInitialStoryPoints());
    setIsValidSelectedScheme(true);
  };

  const handleSelectedSchemeChange = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownItemProps) => {
    const selectedScheme = sortedByDefaultScheme.find((scheme) => getSchemeModifiedName(scheme) === data.text);

    if (selectedScheme) {
      setSelectedSchemeActionDisplayName(data.text as SchemeDisplayNames);
      setIsValidSelectedScheme(isValidScheme(selectedScheme));
      setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, '');
      setFieldError(JiraProjectModalFormFields.StoryPoints, undefined);

      setFieldValue(JiraProjectModalFormFields.SelectedExistingSchemeToCopy, undefined);
      setFieldValue(JiraProjectModalFormFields.ExistingSchemeId, selectedScheme.id);
      setFieldValue(JiraProjectModalFormFields.StoryPointSchemeActions, data.value);
      setFieldValue(JiraProjectModalFormFields.StoryPoints, selectedScheme.storyPoints);
    }
  };

  return (
    <>
      <JiraProjectModalInfo
        title="Map story points to hours for project planning"
        popUpText={popUpText}
        helperText="Provide the hourly range for your story points to create task estimates and forecast schedule dates."
      />
      <JiraProjectTable>
        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content="Choose a Story Point Scheme" />
          <Table.Cell>
            <div className="jira-project-modal__content-estimate-mapping-dropdown">
              <Dropdown
                className="selection"
                text={selectedSchemeActionDisplayName}
                defaultValue={storyPointSchemeActionValue}
                disabled={storyPointsIsDisabled}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={SchemeDisplayNames.CreateNewScheme}
                    value={StoryPointSchemeActions.CreateNew}
                    onClick={handleChangeSchemeActions}
                  />
                  <Dropdown.Item
                    text={SchemeDisplayNames.CopyExistingScheme}
                    value={StoryPointSchemeActions.CreateNew}
                    onClick={handleChangeSchemeActions}
                  />
                  <Dropdown.Divider />
                  {sortedByDefaultScheme.map((scheme) => (
                    <Dropdown.Item
                      key={scheme.id}
                      text={getSchemeModifiedName(scheme)}
                      value={getSchemeValue(scheme)}
                      onClick={handleSelectedSchemeChange}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {showLinkToSchemes && (
                <LpLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to={linkToSchemes}
                  className="jira-project-modal__content-estimate-mapping-dropdown-schemes-link"
                >
                  Story Point Scheme Settings
                </LpLink>
              )}
            </div>
          </Table.Cell>
        </JiraProjectModalTableRow>
        {showSchemeNameInput && <SchemeNameInput />}
        {showSelectSchemeDropdown && (
          <SelectSchemeRow
            schemes={sortedByDefaultScheme}
            selectedSchemeActionDisplayName={selectedSchemeActionDisplayName}
          />
        )}
        {selectedExistingSchemeToCopyValue && <SchemeNameInput />}
      </JiraProjectTable>
    </>
  );
};
