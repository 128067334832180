import { Fragment } from 'react';

import { copyElementHtmlToClipboard } from 'lib/clipboard/copy_element_html';
import { PortableLink } from 'lib/clipboard/portable_links';

export function writeUrlToClipboard(...itemPropsList: Array<PortableLink>): boolean {
  const urlJsxToCopy = formatItemLinks(itemPropsList);
  return copyElementHtmlToClipboard(urlJsxToCopy);
}

export function formatItemLinks(itemLinks: Array<PortableLink>) {
  const origin = window.location.origin;

  return (
    <>
      {itemLinks.map((portableLink, index) => {
        const url = `${origin}${portableLink.getUrl()}`;

        return (
          <Fragment key={url}>
            {index > 0 ? <br /> : null}
            {portableLink?.name ? (
              <>
                <strong>{portableLink.name}</strong>
                <br />
              </>
            ) : null}
            <a data-testid="copied-link" href={url}>
              {url}
            </a>
            <br />
          </Fragment>
        );
      })}
    </>
  );
}
