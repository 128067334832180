import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ASAPLabel } from 'containers/shared/notice_icons/asap_label';
import { DependencyLabel } from 'containers/shared/notice_icons/dependency_label';
import { IterationNoticeLabelRenderer } from 'containers/shared/notice_icons/iteration_label';
import { RiskLabel } from 'containers/shared/notice_icons/risk_label';
import { SchedulingLimitsLabel } from 'containers/shared/notice_icons/scheduling_limits_label';
import { WaitingLabel } from 'containers/shared/notice_icons/waiting_label';
import { ScheduleDirective } from 'daos/enums';
import { TabNames } from 'features/item_panel/sections/tab_names';
import { useHasFeature } from 'hooks/use_has_feature';
import { useIconsForItem } from 'hooks/use_icons_for_item';
import { FeatureFlag } from 'lib/feature_flags';
import { getIterationForItemId } from 'redux/entities/selectors/iterations';
import { getWorkLimitValuesForItemId } from 'redux/entities/selectors/work_limits_values';

interface SchedulingLabelProps {
  isItemPanel?: boolean;
  itemId: number;
  ignoreRisk?: boolean;
  ignoreWorkLimit?: boolean;
  showScheduleLimit?: boolean;
  waiting?: boolean;
}

const SchedulingLabels = ({
  isItemPanel,
  itemId,
  ignoreRisk = false,
  ignoreWorkLimit = false,
  waiting,
  showScheduleLimit = true,
}: SchedulingLabelProps) => {
  const history = useHistory();
  const hasIterationFeature = useHasFeature(FeatureFlag.iterationBucket);
  const { hasWorkLimitAlert } = useSelector((state) => getWorkLimitValuesForItemId(state, itemId));
  const { asapStatus, hasSuccessorOrPredecessor, isScheduleLate, inActiveIteration } = useIconsForItem(itemId);

  const iteration = useSelector((state) => (hasIterationFeature ? getIterationForItemId(state, itemId) : undefined));

  const showLate = !ignoreRisk && isScheduleLate;
  const showWorkLimitAlert = !ignoreWorkLimit && hasWorkLimitAlert;

  const openItemPanelSchedulingTab = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();

      history.push(`#panelId=${itemId}&panelSection=${TabNames.Scheduling}Tab`);
    },
    [history, itemId]
  );

  const schedulingLabels = useMemo(() => {
    const labels = [];

    if (showScheduleLimit) {
      labels.push(<SchedulingLimitsLabel key={'isScheduleLimitActive'} itemId={itemId} />);
    }

    if (hasSuccessorOrPredecessor) {
      labels.push(<DependencyLabel key={'hasSuccessorOrPredecessor'} onClick={openItemPanelSchedulingTab} />);
    }

    if (showLate || showWorkLimitAlert) {
      labels.push(<RiskLabel onClick={openItemPanelSchedulingTab} key={'isScheduleLate'} />);
    }

    if (waiting) {
      labels.push(<WaitingLabel key={'isWaiting'} onClick={openItemPanelSchedulingTab} />);
    }

    if (asapStatus !== ScheduleDirective.NORMAL) {
      labels.push(
        <ASAPLabel onSchedulingClick={openItemPanelSchedulingTab} scheduleDirective={asapStatus} key={'asapStatus'} />
      );
    }

    if (!!iteration && inActiveIteration) {
      labels.push(
        <IterationNoticeLabelRenderer
          onClick={openItemPanelSchedulingTab}
          iteration={iteration}
          key={'iteration'}
          isItemPanel={!!isItemPanel}
        />
      );
    }

    return labels;
  }, [
    asapStatus,
    hasSuccessorOrPredecessor,
    inActiveIteration,
    isItemPanel,
    itemId,
    iteration,
    openItemPanelSchedulingTab,
    showLate,
    showScheduleLimit,
    showWorkLimitAlert,
    waiting,
  ]);

  return <>{schedulingLabels}</>;
};

export default SchedulingLabels;
