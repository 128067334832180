import { ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import './track_bound_box.scss';

const FRAME_LENGTH = 16;

export function useTrackBoundingBox() {
  const [el, setEl] = useState<HTMLDivElement | null>(null);
  const [box, setBox] = useState<DOMRect | null>(null);

  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setEl(node);
    }
  }, []);

  useLayoutEffect(() => {
    if (!el) {
      return;
    }

    let timer: number | undefined;

    const ro = new ResizeObserver(([entry]) => {
      timer && clearTimeout(timer);
      timer = window.setTimeout(() => {
        if (entry) {
          setBox(entry.contentRect);
        }
      }, FRAME_LENGTH);
    });

    ro.observe(el);

    return () => ro.disconnect();
  }, [el]);

  return { box, ref, el };
}

interface TrackingBoundingBoxProps {
  innerRef: (node: HTMLDivElement) => void;
  children?: ReactNode;
}

export function TrackingBoundingBox({ innerRef, children }: TrackingBoundingBoxProps) {
  return (
    <div className="tracking-bounding-box" ref={innerRef}>
      {children}
    </div>
  );
}
