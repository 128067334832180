import { useParams } from 'react-router';

import { backend } from 'daos/urls';
import PageNotFound from 'features/common/errors/page_not_found';

interface ItemFileDownloadParams {
  organizationId: string;
  workspaceId: string;
  itemId: string;
  s3id: string;
}

export function itemFileDownloadUrl({
  organizationId,
  workspaceId,
  itemId,
  s3id,
}: {
  organizationId: string;
  workspaceId: string;
  itemId: string;
  s3id: string;
}) {
  const origin = window.location.origin;
  const downloadUrl = backend.itemFilesDownload.url({
    organizationId,
    workspaceId,
    itemId,
    s3id,
  });

  if (!downloadUrl || !origin) {
    return '';
  }
  const redirectUrl = `${origin}${downloadUrl}`;
  return redirectUrl;
}

export const ItemFileDownloadRedirect = () => {
  const { organizationId, workspaceId, itemId, s3id } = useParams<ItemFileDownloadParams>();
  const redirectUrl = itemFileDownloadUrl({ organizationId, workspaceId, itemId, s3id });

  if (!redirectUrl) {
    return <PageNotFound />;
  }
  window.location.href = redirectUrl;
  return null;
};
