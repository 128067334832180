import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoryPoints, StoryPointScheme } from 'daos/model_types';
import { StoryPointSchemesDao } from 'daos/story_point_schemes';
import { useStoryPointsSchemesRowData } from 'features/administration/settings/settings_page/story_point_schemes/schemes_sort';
import { StoryPointSchemesWithStoryPoints } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';
import { convertSecondsToHours } from 'lib/helpers';

export const useStoryPointSchemesWithDefaultAndStoryPointValues = (): Array<StoryPointSchemesWithStoryPoints> => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [storyPointSchemes, setStoryPointSchemes] = useState<ReadonlyArray<StoryPointScheme>>([]);
  const [schemeStoryPoints, setSchemeStoryPoints] = useState<ReadonlyArray<StoryPoints>>([]);
  const schemesWithDefaultData = useStoryPointsSchemesRowData(storyPointSchemes);

  const fetchStoryPointSchemes = useCallback(() => {
    const { uuid } = dispatch(
      StoryPointSchemesDao.fetchAll(
        { organizationId, workspaceId },
        { include: { includeStoryPoints: true, includeSchemeOwners: true } }
      )
    );

    dispatch(
      awaitRequestFinish<Array<StoryPointScheme>>(uuid, {
        onSuccess: ({ data, entities }) => {
          setStoryPointSchemes(data);
          setSchemeStoryPoints(Object.values(entities.storyPoints ?? {}));
        },
      })
    );
  }, [dispatch, organizationId, workspaceId]);

  useEffect(() => {
    fetchStoryPointSchemes();
  }, [fetchStoryPointSchemes]);

  const storyPointsMap = schemeStoryPoints.reduce((acc: Record<string, Array<StoryPoints>>, storyPoint) => {
    const schemeId = storyPoint.storyPointScheme.id;
    if (!acc[schemeId]) {
      acc[schemeId] = [];
    }

    const modifiedStoryPoint = {
      ...storyPoint,
      value: Number(storyPoint.value),
      lowEffort: storyPoint.lowEffort ? convertSecondsToHours(storyPoint.lowEffort) : null,
      highEffort: storyPoint.highEffort ? convertSecondsToHours(storyPoint.highEffort) : null,
    };

    acc[schemeId]?.push(modifiedStoryPoint);
    return acc;
  }, {});

  return schemesWithDefaultData.map((scheme) => ({
    ...scheme,
    storyPoints: storyPointsMap[scheme.id]?.filter((point) => point.archivedAt === null) || [],
  }));
};
