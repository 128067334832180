import classNames from 'classnames';
import { FieldInputProps, FieldMetaProps } from 'formik';
import { ChangeEvent, SyntheticEvent, useRef } from 'react';
import { Form, Ref } from 'semantic-ui-react';

import { useDisableAutofill } from 'hooks/use_disable_auto_fill';

import { formErrorLabel } from './form_field_helpers/form_error_label';

import './lp_form.scss';

const AGAINST_SAFARI_BROWSER_RENDERING_ISSUE = ' ';

const LpFormInput = ({
  fieldKey,
  type = 'text',
  label,
  className,
  disabled = false,
  disableLastPass = true,
  getFieldMeta,
  getFieldProps,
  customOnBlur,
  customOnChange,
  placeholder = AGAINST_SAFARI_BROWSER_RENDERING_ISSUE,
  icon,
  step,
  hideErrorMessage,
  min,
  max,
  onAnimationEnd,
  autoFocus,
  onFocus,
  spellCheck,
  onKeyDown,
  e2eTestId,
}: {
  fieldKey: string;
  type?: string;
  label?: string;
  disabled?: boolean;
  disableLastPass?: boolean;
  className?: string;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  customOnBlur?: (e: SyntheticEvent) => void;
  customOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  icon?: string;
  step?: string;
  hideErrorMessage?: boolean;
  min?: string;
  max?: string;
  onAnimationEnd?: (_: SyntheticEvent) => void;
  autoFocus?: boolean;
  onFocus?: (e: FocusEvent) => void;
  spellCheck?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  e2eTestId?: string;
}) => {
  const fieldMeta = getFieldMeta(fieldKey);
  const fieldProps = getFieldProps(fieldKey);

  const formRef = useRef<HTMLElement>(null);
  useDisableAutofill(formRef, disableLastPass);

  const handleOnBlur = (e: SyntheticEvent) => {
    customOnBlur?.(e);
    fieldProps.onBlur(e);
  };

  const handleOnHandle = (e: ChangeEvent<HTMLInputElement>) => {
    customOnChange?.(e);
    fieldProps.onChange(e);
  };

  return (
    <Ref innerRef={formRef}>
      <Form.Input
        autoComplete={'off'}
        autoFocus={autoFocus}
        onFocus={onFocus}
        spellCheck={spellCheck}
        onAnimationEnd={onAnimationEnd}
        id={fieldKey}
        name={fieldKey}
        className={classNames(className, 'lp-form__input')}
        disabled={disabled}
        error={formErrorLabel({ fieldMeta, hideErrorMessage })}
        label={label}
        type={type}
        step={step}
        min={min}
        max={max}
        onChange={handleOnHandle}
        onKeyDown={onKeyDown}
        onBlur={handleOnBlur}
        value={fieldProps.value}
        placeholder={placeholder}
        icon={icon}
        iconPosition={icon ? 'left' : undefined}
        data-e2e-test-id={e2eTestId}
      />
    </Ref>
  );
};

export default LpFormInput;
