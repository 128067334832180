import { ReadonlyRecord } from 'lib/readonly_record';

import { ItemType, Permission } from './enums';
import { Item } from './model_types';

export enum AccessRole {
  Admin = 'Workspace Manager',
  Editor = 'Project Editor',
  Manager = 'Project Manager',
  Observer = 'Project Observer',
  None = 'None',
}

export const accessRoleByPermission = {
  [Permission.ADMIN]: AccessRole.Admin,
  [Permission.MANAGE]: AccessRole.Manager,
  [Permission.EDIT]: AccessRole.Editor,
  [Permission.OBSERVE]: AccessRole.Observer,
  [Permission.NONE]: AccessRole.None,
};

export const permissionByAccessRole = {
  [AccessRole.Admin]: Permission.ADMIN,
  [AccessRole.Manager]: Permission.MANAGE,
  [AccessRole.Editor]: Permission.EDIT,
  [AccessRole.Observer]: Permission.OBSERVE,
  [AccessRole.None]: Permission.NONE,
};

export const permissionOrder: ReadonlyRecord<Permission, number> = {
  [Permission.NONE]: 0,
  [Permission.OBSERVE]: 1,
  [Permission.EDIT]: 3,
  [Permission.MANAGE]: 4,
  [Permission.ADMIN]: 5,
};

export const visibilityPermissionLevel = {
  admin: Permission.ADMIN,
  edit: Permission.EDIT,
  manage: Permission.MANAGE,
  observe: Permission.OBSERVE,
  none: Permission.NONE,
};

export const permissionCompare = (userPerm: Permission, mustHavePerm: Permission) =>
  permissionOrder[mustHavePerm] - permissionOrder[userPerm];

export const accessPermissionForItem = (item: Item | undefined) => itemAccessPermission(item?.itemType);

export const itemAccessPermission = (itemType: ItemType | undefined) => {
  switch (itemType) {
    case ItemType.PACKAGES:
    case ItemType.PROJECTS:
      return Permission.MANAGE;
    case ItemType.FOLDERS:
    case ItemType.TASKS:
    case ItemType.ASSIGNMENTS:
      return Permission.EDIT;
    default:
      return Permission.ADMIN;
  }
};

export const itemAccessMovePermission = (itemType: ItemType | undefined) => {
  switch (itemType) {
    case ItemType.PACKAGES:
      return Permission.MANAGE;
    case ItemType.PROJECTS:
    case ItemType.FOLDERS:
    case ItemType.TASKS:
    case ItemType.ASSIGNMENTS:
      return Permission.EDIT;
    default:
      return Permission.ADMIN;
  }
};
