import { ASAPLabelRenderer } from 'containers/shared/notice_icons/asap_label';
import { ScheduleDirective, ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon } from 'daos/enums';
import { PropertyChange } from 'daos/types';

const ChangeCardPriorityRushProperty = ({ propertyChangeData }: { propertyChangeData: PropertyChange }) => {
  const ASAPValue: ScheduleDirective = propertyChangeData.value;
  const asapIcon = (
    <ASAPLabelRenderer scheduleDirective={ASAPValue} className={'lp-change__card-change-property-priority-rush'} />
  );

  return (
    <>
      {asapIcon}
      {ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon[ASAPValue]}
    </>
  );
};

export default ChangeCardPriorityRushProperty;
