import { useSelector } from 'react-redux';

import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { FeatureFlag } from 'lib/feature_flags';
import { RootState } from 'redux/root_reducer';

import { useHasFeature } from './use_has_feature';

export const useItemWidgetRefactorColumnSelector = ({
  refactoredColumnSelector,
  oldColumnSelector,
  forPPPGrid = false,
}: {
  refactoredColumnSelector: (state: RootState, workspaceId: number, forGrid: boolean) => ReadonlyArray<string>;
  oldColumnSelector: (state: RootState) => ReadonlyArray<string>;
  forPPPGrid?: boolean;
}) => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const systemColumnsRefactorEnabled = useHasFeature(FeatureFlag.systemColumnsRefactor);
  const selector = systemColumnsRefactorEnabled
    ? (state: RootState) => refactoredColumnSelector(state, workspaceId, forPPPGrid)
    : oldColumnSelector;

  return useSelector(selector);
};
