import classnames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';
import { Button } from 'semantic-ui-react';

import './styles.scss';

interface CallToActionButtonProps {
  onClick: MouseEventHandler;
  className?: string;
  children?: ReactNode;
}

const CallToActionButton = ({ className, ...props }: CallToActionButtonProps) => {
  return <Button className={classnames(className, 'lp-call-to-action')} {...props} />;
};

export default CallToActionButton;
