import { Placement } from '@popperjs/core/lib/enums';
import React, { ReactNode, useRef, MouseEvent } from 'react';
import { Portal, Dropdown, Ref } from 'semantic-ui-react';

import ActionEllipsis from 'containers/shared/action_ellipsis';
import { ClickLocation } from 'hooks/use_click_location_for_context_menu';
import { useWindowResize } from 'hooks/use_window_resize';

import { usePopper } from './use_popper';

interface PortalActionDropdownProps {
  children: ReactNode;
  className?: string;
  clickLocation?: ClickLocation;
  disabled?: boolean;
  onClose: () => void;
  onOpen: (event: MouseEvent) => void;
  open?: boolean;
  popperHeightOffset?: number;
  showTrigger?: boolean;
  trigger?: ReactNode;
  triggerPopupPlacement?: Placement;
  triggerRef?: React.RefObject<HTMLSpanElement>;
}

const PortalActionDropdown = ({
  children,
  className,
  clickLocation,
  disabled = false,
  onClose,
  onOpen,
  open,
  popperHeightOffset,
  showTrigger = true,
  trigger = <ActionEllipsis />,
  triggerPopupPlacement = 'left-start',
  triggerRef,
}: PortalActionDropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useWindowResize(onClose, 0);
  usePopper(dropdownRef, onClose, triggerRef, clickLocation, triggerPopupPlacement, popperHeightOffset);

  return (
    <Portal
      trigger={<span ref={triggerRef}>{showTrigger && trigger}</span>}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      closeOnTriggerClick
    >
      <Ref innerRef={dropdownRef}>
        <Dropdown disabled={disabled} icon={null} className={className} style={{ opacity: 0 }} onClick={onClose}>
          <Dropdown.Menu className="ellipsis-action-dropdown">{children}</Dropdown.Menu>
        </Dropdown>
      </Ref>
    </Portal>
  );
};

export default PortalActionDropdown;
