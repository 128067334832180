import classNames from 'classnames';
import { Table } from 'semantic-ui-react';

import { cloudCheckSolid, LpIcon } from 'features/common/lp_icon';
import { useUserFirstLastOrUsername } from 'hooks/use_user_first_last_or_username';

interface UserNameCell {
  userId: number;
  isEvicted: boolean;
  isAdmin: boolean;
}
const OrganizationDirectoryUsersNameCell = ({ userId, isAdmin, isEvicted }: UserNameCell) => {
  const displayName = useUserFirstLastOrUsername(userId) + `${isAdmin ? ' ( Admin )' : ''}`;

  return (
    <Table.Cell>
      {isAdmin && <LpIcon className="admin-icon" icon={cloudCheckSolid} />}

      <span className={classNames({ 'evicted-user': !!isEvicted })}>{displayName} </span>
    </Table.Cell>
  );
};

export default OrganizationDirectoryUsersNameCell;
