import { Checkbox, CheckboxProps } from 'semantic-ui-react';

import { CustomFieldValueProps } from 'features/common/custom_fields/custom_field_value/custom_field_value_props';
import { useCustomFieldsApiActions } from 'hooks/use_custom_fields_api_actions';
import { useGetCustomFieldByFieldIdAndResourceId } from 'hooks/use_get_custom_field_by_field_id_and_resource_id';

const CustomFieldCheckbox = ({ fieldId, resourceId, disabled = false }: CustomFieldValueProps) => {
  const field = useGetCustomFieldByFieldIdAndResourceId(fieldId, resourceId);
  const { updateFieldValue, createFieldValue, removeFieldValue } = useCustomFieldsApiActions(resourceId.type);

  if (!field) {
    return null;
  }

  const { allowsMultipleValues, values } = field;

  const fieldValue = values?.[0];

  if (allowsMultipleValues) {
    throw new Error('multiple values not supported by checkbox field');
  }

  const handleChange = (_: React.FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    const payload = { checked: checked };
    if (checked && fieldValue) {
      updateFieldValue(resourceId.id, fieldValue.id, payload);
    } else if (checked) {
      createFieldValue(resourceId.id, fieldId, payload);
    } else {
      if (fieldValue) {
        removeFieldValue(resourceId.id, fieldValue.id);
      }
    }
  };

  return <Checkbox checked={!!fieldValue?.checked} onChange={handleChange} disabled={disabled} />;
};

export default CustomFieldCheckbox;
