import classNames from 'classnames';

import LpLink from 'containers/shared/lp_link';
import 'features/authentication/unauthenticated/token/click_here_to_login/index.scss';

export const ClickHereToLogin = () => {
  return (
    <div className={classNames('click-here-to-login')}>
      Click <LpLink to={'/'}>here</LpLink> to login
    </div>
  );
};
